import Auction from "./auction-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateAuctionAsync, DeleteAuctionAsync, LoadAuctionsAsync } from "./auction-thunk";
const initialState: auctionSliceState = {
  auction: [],
  selected_auction: "",
  is_loading_auction: false,
  is_creating_auction: false,
  is_deleting_auction: false
};
export interface auctionSliceState {
  auction: Auction[];
  selected_auction?: string;
  is_loading_auction: boolean;
  is_creating_auction: boolean;
  is_deleting_auction: boolean;
}
export const auctionSlice = createSlice({
  name: "auction",
  initialState,
  reducers: {
    setSelectedauction: (state, action) => {
      state.selected_auction = action.payload;
    },
    clearStateauction: (state, action) => {
      state.auction = [];
      state.is_loading_auction = false;
      state.is_creating_auction = false;
      state.is_deleting_auction = false;
    },
    mergeauction: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_auction = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.auction = merge(state.auction, payload) as any;
        }
      }
    },

    add_auction: (state, action) => {
      if (action.payload) {
        state.auction = [...state.auction, action.payload];
      }
    },
    set_auction: (state, action) => {
      let { key, value, id } = action.payload;
      state.auction = state.auction.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadAuctionsAsync.pending, (state) => {
      state.is_loading_auction = true;
    });
    builder.addCase(LoadAuctionsAsync.fulfilled, (state, action) => {
      state.is_loading_auction = false;
      if (action.payload) {
        state.auction = action.payload;
      }
    });
    builder.addCase(LoadAuctionsAsync.rejected, (state, action) => {
      state.is_loading_auction = false;
    });
    builder.addCase(CreateAuctionAsync.pending, (state) => {
      state.is_creating_auction = true;
    });
    builder.addCase(CreateAuctionAsync.fulfilled, (state) => {
      state.is_creating_auction = false;
    });
    builder.addCase(CreateAuctionAsync.rejected, (state) => {
      state.is_creating_auction = false;
    });
    builder.addCase(DeleteAuctionAsync.pending, (state) => {
      state.is_deleting_auction = true;
    });
    builder.addCase(DeleteAuctionAsync.fulfilled, (state, action) => {
      state.is_deleting_auction = false;
      if (state.auction) {
        state.auction = state.auction.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteAuctionAsync.rejected, (state, action) => {
      state.is_deleting_auction = false;
    });

    builder.addCase(autoThunks.get_customer_bank_auctions.pending, (state) => {
      state.is_loading_auction = true;
    });
    builder.addCase(autoThunks.get_customer_bank_auctions.fulfilled, (state, action) => {
      state.is_loading_auction = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_customer_bank_auctions.rejected, (state, action) => {
      state.is_loading_auction = false;
    });

    builder.addCase(autoThunks.get_auctions_as_agent.pending, (state) => {
      state.is_loading_auction = true;
    });
    builder.addCase(autoThunks.get_auctions_as_agent.fulfilled, (state, action) => {
      state.is_loading_auction = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_auctions_as_agent.rejected, (state, action) => {
      state.is_loading_auction = false;
    });

    builder.addCase(autoThunks.start_auction_for_bank_as_agent.pending, (state) => {
      state.is_loading_auction = true;
    });
    builder.addCase(autoThunks.start_auction_for_bank_as_agent.fulfilled, (state, action) => {
      state.is_loading_auction = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.start_auction_for_bank_as_agent.rejected, (state, action) => {
      state.is_loading_auction = false;
    });

    builder.addCase(autoThunks.changeAuctionState_as_agent.pending, (state) => {
      state.is_loading_auction = true;
    });
    builder.addCase(autoThunks.changeAuctionState_as_agent.fulfilled, (state, action) => {
      state.is_loading_auction = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.changeAuctionState_as_agent.rejected, (state, action) => {
      state.is_loading_auction = false;
    });

    builder.addCase(autoThunks.delete_auction_as_agent.pending, (state) => {
      state.is_loading_auction = true;
    });
    builder.addCase(autoThunks.delete_auction_as_agent.fulfilled, (state, action) => {
      state.is_loading_auction = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.delete_auction_as_agent.rejected, (state, action) => {
      state.is_loading_auction = false;
    });

    builder.addCase(autoThunks.get_auctions.pending, (state) => {
      state.is_loading_auction = true;
    });
    builder.addCase(autoThunks.get_auctions.fulfilled, (state, action) => {
      state.is_loading_auction = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_auctions.rejected, (state, action) => {
      state.is_loading_auction = false;
    });

    builder.addCase(autoThunks.start_auction_for_bank.pending, (state) => {
      state.is_loading_auction = true;
    });
    builder.addCase(autoThunks.start_auction_for_bank.fulfilled, (state, action) => {
      state.is_loading_auction = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.start_auction_for_bank.rejected, (state, action) => {
      state.is_loading_auction = false;
    });

    builder.addCase(autoThunks.changeAuctionState.pending, (state) => {
      state.is_loading_auction = true;
    });
    builder.addCase(autoThunks.changeAuctionState.fulfilled, (state, action) => {
      state.is_loading_auction = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.changeAuctionState.rejected, (state, action) => {
      state.is_loading_auction = false;
    });

    builder.addCase(autoThunks.delete_auction.pending, (state) => {
      state.is_loading_auction = true;
    });
    builder.addCase(autoThunks.delete_auction.fulfilled, (state, action) => {
      state.is_loading_auction = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.delete_auction.rejected, (state, action) => {
      state.is_loading_auction = false;
    });

    builder.addCase(autoThunks.create_digital_auction.pending, (state) => {
      state.is_loading_auction = true;
    });
    builder.addCase(autoThunks.create_digital_auction.fulfilled, (state, action) => {
      state.is_loading_auction = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.create_digital_auction.rejected, (state, action) => {
      state.is_loading_auction = false;
    });
  }
});

function handleResult(action: any, state: auctionSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_auction = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.auction = merge(state.auction, payload) as any;
    }
  }
}

export const { clearStateauction, setSelectedauction, set_auction, mergeauction, add_auction } = auctionSlice.actions;

export const get_auction =
  (id?: string) =>
  (state: {
    auction: {
      auction: Auction[];
    };
  }): Auction | null => {
    let temp = state?.auction?.auction?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.auction?.auction[0] || null;
  };
export const get_selected_auction = (state: { auction: auctionSliceState }): Auction | null => {
  let id = state?.auction?.selected_auction;
  let temp = state?.auction?.auction?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_auction = (state: {
  auction: {
    auction: Auction[];
  };
}): Auction[] => {
  let temp = state?.auction?.auction;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_auction_busy = (state: { auction: auctionSliceState }) => {
  return state?.auction.is_deleting_auction || state?.auction.is_loading_auction || state?.auction.is_creating_auction;
};
export const is_deleting_auction_busy = (state: { auction: auctionSliceState }) => {
  return state?.auction.is_deleting_auction;
};
export const is_loading_auction_busy = (state: { auction: auctionSliceState }) => {
  return state?.auction.is_loading_auction;
};
export const is_creating_auction_busy = (state: { auction: auctionSliceState }) => {
  return state?.auction.is_creating_auction;
};

export default auctionSlice.reducer;
