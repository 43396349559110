import { createAsyncThunk } from "@reduxjs/toolkit";
import Progress from "./progress-model";
import { delete_progress, load_progresss, store_progress } from "./progress-service";

export const LoadProgresssAsync = createAsyncThunk<any, any>("composer/load/progress", async (params: { uid: string }) => {
  return await load_progresss(params);
});

export const CreateProgressAsync = createAsyncThunk<any, any>("composer/create/progress", async (params: Progress) => {
  return await store_progress(params);
});

export const DeleteProgressAsync = createAsyncThunk<any, any>("composer/delete/progress", async (params) => {
  return await delete_progress(params);
});
