import CourseLoad from "./course-load-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateCourseLoadAsync, DeleteCourseLoadAsync, LoadCourseLoadsAsync } from "./courseLoad-thunk";
const initialState: courseLoadSliceState = {
  courseLoad: [],
  selected_courseLoad: "",
  is_loading_courseLoad: false,
  is_creating_courseLoad: false,
  is_deleting_courseLoad: false
};
export interface courseLoadSliceState {
  courseLoad: CourseLoad[];
  selected_courseLoad?: string;
  is_loading_courseLoad: boolean;
  is_creating_courseLoad: boolean;
  is_deleting_courseLoad: boolean;
}
export const courseLoadSlice = createSlice({
  name: "courseLoad",
  initialState,
  reducers: {
    setSelectedcourseLoad: (state, action) => {
      state.selected_courseLoad = action.payload;
    },
    clearStatecourseLoad: (state, action) => {
      state.courseLoad = [];
      state.is_loading_courseLoad = false;
      state.is_creating_courseLoad = false;
      state.is_deleting_courseLoad = false;
    },
    mergecourseLoad: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_courseLoad = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.courseLoad = merge(state.courseLoad, payload) as any;
        }
      }
    },

    add_courseLoad: (state, action) => {
      if (action.payload) {
        state.courseLoad = [...state.courseLoad, action.payload];
      }
    },
    set_courseLoad: (state, action) => {
      let { key, value, id } = action.payload;
      state.courseLoad = state.courseLoad.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadCourseLoadsAsync.pending, (state) => {
      state.is_loading_courseLoad = true;
    });
    builder.addCase(LoadCourseLoadsAsync.fulfilled, (state, action) => {
      state.is_loading_courseLoad = false;
      if (action.payload) {
        state.courseLoad = action.payload;
      }
    });
    builder.addCase(LoadCourseLoadsAsync.rejected, (state, action) => {
      state.is_loading_courseLoad = false;
    });
    builder.addCase(CreateCourseLoadAsync.pending, (state) => {
      state.is_creating_courseLoad = true;
    });
    builder.addCase(CreateCourseLoadAsync.fulfilled, (state) => {
      state.is_creating_courseLoad = false;
    });
    builder.addCase(CreateCourseLoadAsync.rejected, (state) => {
      state.is_creating_courseLoad = false;
    });
    builder.addCase(DeleteCourseLoadAsync.pending, (state) => {
      state.is_deleting_courseLoad = true;
    });
    builder.addCase(DeleteCourseLoadAsync.fulfilled, (state, action) => {
      state.is_deleting_courseLoad = false;
      if (state.courseLoad) {
        state.courseLoad = state.courseLoad.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteCourseLoadAsync.rejected, (state, action) => {
      state.is_deleting_courseLoad = false;
    });

    builder.addCase(autoThunks.load_course.pending, (state) => {
      state.is_loading_courseLoad = true;
    });
    builder.addCase(autoThunks.load_course.fulfilled, (state, action) => {
      state.is_loading_courseLoad = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.load_course.rejected, (state, action) => {
      state.is_loading_courseLoad = false;
    });
  }
});

function handleResult(action: any, state: courseLoadSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_courseLoad = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.courseLoad = merge(state.courseLoad, payload) as any;
    }
  }
}

export const { clearStatecourseLoad, setSelectedcourseLoad, set_courseLoad, mergecourseLoad, add_courseLoad } = courseLoadSlice.actions;

export const get_courseLoad =
  (id?: string) =>
  (state: {
    courseLoad: {
      courseLoad: CourseLoad[];
    };
  }): CourseLoad | null => {
    let temp = state?.courseLoad?.courseLoad?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.courseLoad?.courseLoad[0] || null;
  };
export const get_selected_courseLoad = (state: { courseLoad: courseLoadSliceState }): CourseLoad | null => {
  let id = state?.courseLoad?.selected_courseLoad;
  let temp = state?.courseLoad?.courseLoad?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_courseLoad = (state: {
  courseLoad: {
    courseLoad: CourseLoad[];
  };
}): CourseLoad[] => {
  let temp = state?.courseLoad?.courseLoad;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_courseLoad_busy = (state: { courseLoad: courseLoadSliceState }) => {
  return state?.courseLoad.is_deleting_courseLoad || state?.courseLoad.is_loading_courseLoad || state?.courseLoad.is_creating_courseLoad;
};
export const is_deleting_courseLoad_busy = (state: { courseLoad: courseLoadSliceState }) => {
  return state?.courseLoad.is_deleting_courseLoad;
};
export const is_loading_courseLoad_busy = (state: { courseLoad: courseLoadSliceState }) => {
  return state?.courseLoad.is_loading_courseLoad;
};
export const is_creating_courseLoad_busy = (state: { courseLoad: courseLoadSliceState }) => {
  return state?.courseLoad.is_creating_courseLoad;
};

export default courseLoadSlice.reducer;
