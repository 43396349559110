import { createAsyncThunk } from "@reduxjs/toolkit";
import VirtualEmploymentRecord from "./virtual-employment-record-model";
import { delete_virtualEmploymentRecord, load_virtualEmploymentRecords, store_virtualEmploymentRecord } from "./virtualEmploymentRecord-service";

export const LoadVirtualEmploymentRecordsAsync = createAsyncThunk<any, any>("composer/load/virtualEmploymentRecord", async (params: { uid: string }) => {
  return await load_virtualEmploymentRecords(params);
});

export const CreateVirtualEmploymentRecordAsync = createAsyncThunk<any, any>(
  "composer/create/virtualEmploymentRecord",
  async (params: VirtualEmploymentRecord) => {
    return await store_virtualEmploymentRecord(params);
  }
);

export const DeleteVirtualEmploymentRecordAsync = createAsyncThunk<any, any>("composer/delete/virtualEmploymentRecord", async (params) => {
  return await delete_virtualEmploymentRecord(params);
});
