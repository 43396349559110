import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import StockPrice from "./stock-price-model";
import { StockPriceProperties, StockPriceMetaProperties } from "./stockPrice-property-definitions";

export async function load_stockPrices(args: { uid: string }): Promise<StockPrice[]> {
  throw "Not implemented";
}

export async function delete_stockPrice(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_stockPrice(args: StockPrice): Promise<StockPrice> {
  throw "Not implemented";
}
