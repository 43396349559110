import { createAsyncThunk } from "@reduxjs/toolkit";
import QuestionAndAnswers from "./question-and-answers-model";
import { delete_questionAndAnswers, load_questionAndAnswerss, store_questionAndAnswers } from "./questionAndAnswers-service";

export const LoadQuestionAndAnswerssAsync = createAsyncThunk<any, any>("composer/load/questionAndAnswers", async (params: { uid: string }) => {
  return await load_questionAndAnswerss(params);
});

export const CreateQuestionAndAnswersAsync = createAsyncThunk<any, any>("composer/create/questionAndAnswers", async (params: QuestionAndAnswers) => {
  return await store_questionAndAnswers(params);
});

export const DeleteQuestionAndAnswersAsync = createAsyncThunk<any, any>("composer/delete/questionAndAnswers", async (params) => {
  return await delete_questionAndAnswers(params);
});
