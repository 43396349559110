import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import Course from "./course-model";
import { CourseProperties, CourseMetaProperties } from "./course-property-definitions";

export async function load_courses(args: { uid: string }): Promise<Course[]> {
  throw "Not implemented";
}

export async function delete_course(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_course(args: Course): Promise<Course> {
  throw "Not implemented";
}
