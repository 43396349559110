import Progress from "./progress-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateProgressAsync, DeleteProgressAsync, LoadProgresssAsync } from "./progress-thunk";
const initialState: progressSliceState = {
  progress: [],
  selected_progress: "",
  is_loading_progress: false,
  is_creating_progress: false,
  is_deleting_progress: false
};
export interface progressSliceState {
  progress: Progress[];
  selected_progress?: string;
  is_loading_progress: boolean;
  is_creating_progress: boolean;
  is_deleting_progress: boolean;
}
export const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    setSelectedprogress: (state, action) => {
      state.selected_progress = action.payload;
    },
    clearStateprogress: (state, action) => {
      state.progress = [];
      state.is_loading_progress = false;
      state.is_creating_progress = false;
      state.is_deleting_progress = false;
    },
    mergeprogress: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_progress = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.progress = merge(state.progress, payload) as any;
        }
      }
    },

    add_progress: (state, action) => {
      if (action.payload) {
        state.progress = [...state.progress, action.payload];
      }
    },
    set_progress: (state, action) => {
      let { key, value, id } = action.payload;
      state.progress = state.progress.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadProgresssAsync.pending, (state) => {
      state.is_loading_progress = true;
    });
    builder.addCase(LoadProgresssAsync.fulfilled, (state, action) => {
      state.is_loading_progress = false;
      if (action.payload) {
        state.progress = action.payload;
      }
    });
    builder.addCase(LoadProgresssAsync.rejected, (state, action) => {
      state.is_loading_progress = false;
    });
    builder.addCase(CreateProgressAsync.pending, (state) => {
      state.is_creating_progress = true;
    });
    builder.addCase(CreateProgressAsync.fulfilled, (state) => {
      state.is_creating_progress = false;
    });
    builder.addCase(CreateProgressAsync.rejected, (state) => {
      state.is_creating_progress = false;
    });
    builder.addCase(DeleteProgressAsync.pending, (state) => {
      state.is_deleting_progress = true;
    });
    builder.addCase(DeleteProgressAsync.fulfilled, (state, action) => {
      state.is_deleting_progress = false;
      if (state.progress) {
        state.progress = state.progress.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteProgressAsync.rejected, (state, action) => {
      state.is_deleting_progress = false;
    });
  }
});

function handleResult(action: any, state: progressSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_progress = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.progress = merge(state.progress, payload) as any;
    }
  }
}

export const { clearStateprogress, setSelectedprogress, set_progress, mergeprogress, add_progress } = progressSlice.actions;

export const get_progress =
  (id?: string) =>
  (state: {
    progress: {
      progress: Progress[];
    };
  }): Progress | null => {
    let temp = state?.progress?.progress?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.progress?.progress[0] || null;
  };
export const get_selected_progress = (state: { progress: progressSliceState }): Progress | null => {
  let id = state?.progress?.selected_progress;
  let temp = state?.progress?.progress?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_progress = (state: {
  progress: {
    progress: Progress[];
  };
}): Progress[] => {
  let temp = state?.progress?.progress;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_progress_busy = (state: { progress: progressSliceState }) => {
  return state?.progress.is_deleting_progress || state?.progress.is_loading_progress || state?.progress.is_creating_progress;
};
export const is_deleting_progress_busy = (state: { progress: progressSliceState }) => {
  return state?.progress.is_deleting_progress;
};
export const is_loading_progress_busy = (state: { progress: progressSliceState }) => {
  return state?.progress.is_loading_progress;
};
export const is_creating_progress_busy = (state: { progress: progressSliceState }) => {
  return state?.progress.is_creating_progress;
};

export default progressSlice.reducer;
