import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import VirtualShareEscroOffer from "./virtual-share-escro-offer-model";
import { VirtualShareEscroOfferProperties, VirtualShareEscroOfferMetaProperties } from "./virtualShareEscroOffer-property-definitions";

export async function load_virtualShareEscroOffers(args: { uid: string }): Promise<VirtualShareEscroOffer[]> {
  throw "Not implemented";
}

export async function delete_virtualShareEscroOffer(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_virtualShareEscroOffer(args: VirtualShareEscroOffer): Promise<VirtualShareEscroOffer> {
  throw "Not implemented";
}
