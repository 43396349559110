import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResolveUrl, UpdateClaims } from "features/firebase-app";
import {
  BusinessPlan,
  User,
  BusinessPlanProperties,
  AccountProperties,
  AuctionProperties,
  AuctionItemProperties,
  BusinessPlanEvaluation,
  BusinessPlanEvaluationProperties,
  VirtualBusiness,
  VirtualBusinessProperties,
  VirtualBusinessInvestorReferenceProperties,
  VirtualBusinessInvestorReference,
  BankSettings,
  BankProperties,
  BusinessPlanSubmission,
  BusinessPlanSubmissionProperties,
  MoneySupply,
  MoneySupplyProperties,
  VirtualInvestment,
  DigitalAssetInstanceProperties,
  TransactionProperties,
  VirtualShareProperties,
  VirtualInvestmentProperties,
  Transaction
} from "models";
import { getBearerToken } from "../../application/application-slice";

export const store_business_plan = createAsyncThunk<any, any, any>("auto/store/business/plan", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/store/business/plan"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }
      await UpdateClaims();
      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const publish_business_plan = createAsyncThunk<any, any, any>("auto/publish/business/plan", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/publish/business/plan"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }
      await UpdateClaims();
      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const store_business_plan_evaluation = createAsyncThunk<any, any, any>(
  "auto/store/business/plan/evaluation",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/store/business/plan/evaluation"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_my_business_plan_evaluation = createAsyncThunk<any, any, any>(
  "auto/get/my/business/plan/evaluation",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/get/my/business/plan/evaluation"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }
        await UpdateClaims();
        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_my_virtual_businesses = createAsyncThunk<any, any, any>("auto/get/my/virtual/businesses", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/my/virtual/businesses"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }
      await UpdateClaims();
      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_my_virtual_references = createAsyncThunk<any, any, any>("auto/get/my/virtual/references", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/my/virtual/references"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }
      await UpdateClaims();
      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_business_plans = createAsyncThunk<any, any, any>("auto/get/business/plans", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/business/plans"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }
      await UpdateClaims();
      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_published_business_plans = createAsyncThunk<any, any, any>(
  "auto/get/published/business/plans",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/get/published/business/plans"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }
        await UpdateClaims();
        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_my_bank_settings = createAsyncThunk<any, any, any>("auto/get/my/bank/settings", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/my/bank/settings"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }
      await UpdateClaims();
      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_business_plan = createAsyncThunk<any, any, any>("auto/get/business/plan", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/business/plan"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }
      await UpdateClaims();
      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_business_plan_evaluations = createAsyncThunk<any, any, any>(
  "auto/get/business/plan/evaluations",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/get/business/plan/evaluations"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }
        await UpdateClaims();
        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_business_plan_submissions = createAsyncThunk<any, any, any>(
  "auto/get/business/plan/submissions",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/get/business/plan/submissions"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }
        await UpdateClaims();
        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_virtual_businesses_for_account = createAsyncThunk<any, any, any>(
  "auto/get/virtual/business/for/account",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/get/virtual/business/for/account"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }
        await UpdateClaims();
        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_bank_money_supply = createAsyncThunk<any, any, any>("auto/get/bank/money/supply", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/bank/money/supply"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }
      await UpdateClaims();
      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const set_approval_of_business_plan = createAsyncThunk<any, any, any>(
  "auto/set/approval/of/business/plan",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/set/approval/of/business/plan"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }
        await UpdateClaims();
        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_virtual_business_for_account = createAsyncThunk<any, any, any>(
  "auto/get/virtual/single/business/for/account",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/get/virtual/single/business/for/account"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }
        await UpdateClaims();
        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const divest_virtual_business_for_account = createAsyncThunk<any, any, any>(
  "auto/divest/virtual/single/business/for/account",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/divest/virtual/single/business/for/account"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_virtual_business_investments_for_account = createAsyncThunk<any, any, any>(
  "auto/divest/virtual/business/investments/for/account",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/divest/virtual/business/investments/for/account"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_in_progress_divestments_for_virtual_business = createAsyncThunk<any, any, any>(
  "auto/get/in/progress/divestments/for/virtual/business",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/get/in/progress/divestments/for/virtual/business"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);
