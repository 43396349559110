import Professor from "./professor-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateProfessorAsync, DeleteProfessorAsync, LoadProfessorsAsync } from "./professor-thunk";
const initialState: professorSliceState = {
  professor: [],
  selected_professor: "",
  is_loading_professor: false,
  is_creating_professor: false,
  is_deleting_professor: false
};
export interface professorSliceState {
  professor: Professor[];
  selected_professor?: string;
  is_loading_professor: boolean;
  is_creating_professor: boolean;
  is_deleting_professor: boolean;
}
export const professorSlice = createSlice({
  name: "professor",
  initialState,
  reducers: {
    setSelectedprofessor: (state, action) => {
      state.selected_professor = action.payload;
    },
    clearStateprofessor: (state, action) => {
      state.professor = [];
      state.is_loading_professor = false;
      state.is_creating_professor = false;
      state.is_deleting_professor = false;
    },
    mergeprofessor: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_professor = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.professor = merge(state.professor, payload) as any;
        }
      }
    },

    add_professor: (state, action) => {
      if (action.payload) {
        state.professor = [...state.professor, action.payload];
      }
    },
    set_professor: (state, action) => {
      let { key, value, id } = action.payload;
      state.professor = state.professor.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadProfessorsAsync.pending, (state) => {
      state.is_loading_professor = true;
    });
    builder.addCase(LoadProfessorsAsync.fulfilled, (state, action) => {
      state.is_loading_professor = false;
      if (action.payload) {
        state.professor = action.payload;
      }
    });
    builder.addCase(LoadProfessorsAsync.rejected, (state, action) => {
      state.is_loading_professor = false;
    });
    builder.addCase(CreateProfessorAsync.pending, (state) => {
      state.is_creating_professor = true;
    });
    builder.addCase(CreateProfessorAsync.fulfilled, (state) => {
      state.is_creating_professor = false;
    });
    builder.addCase(CreateProfessorAsync.rejected, (state) => {
      state.is_creating_professor = false;
    });
    builder.addCase(DeleteProfessorAsync.pending, (state) => {
      state.is_deleting_professor = true;
    });
    builder.addCase(DeleteProfessorAsync.fulfilled, (state, action) => {
      state.is_deleting_professor = false;
      if (state.professor) {
        state.professor = state.professor.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteProfessorAsync.rejected, (state, action) => {
      state.is_deleting_professor = false;
    });

    builder.addCase(autoThunks.get_my_professorship.pending, (state) => {
      state.is_loading_professor = true;
    });
    builder.addCase(autoThunks.get_my_professorship.fulfilled, (state, action) => {
      state.is_loading_professor = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_my_professorship.rejected, (state, action) => {
      state.is_loading_professor = false;
    });

    builder.addCase(autoThunks.get_professors.pending, (state) => {
      state.is_loading_professor = true;
    });
    builder.addCase(autoThunks.get_professors.fulfilled, (state, action) => {
      state.is_loading_professor = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_professors.rejected, (state, action) => {
      state.is_loading_professor = false;
    });
  }
});

function handleResult(action: any, state: professorSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_professor = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.professor = merge(state.professor, payload) as any;
    }
  }
}

export const { clearStateprofessor, setSelectedprofessor, set_professor, mergeprofessor, add_professor } = professorSlice.actions;

export const get_professor =
  (id?: string) =>
  (state: {
    professor: {
      professor: Professor[];
    };
  }): Professor | null => {
    let temp = state?.professor?.professor?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.professor?.professor[0] || null;
  };
export const get_selected_professor = (state: { professor: professorSliceState }): Professor | null => {
  let id = state?.professor?.selected_professor;
  let temp = state?.professor?.professor?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_professor = (state: {
  professor: {
    professor: Professor[];
  };
}): Professor[] => {
  let temp = state?.professor?.professor;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_professor_busy = (state: { professor: professorSliceState }) => {
  return state?.professor.is_deleting_professor || state?.professor.is_loading_professor || state?.professor.is_creating_professor;
};
export const is_deleting_professor_busy = (state: { professor: professorSliceState }) => {
  return state?.professor.is_deleting_professor;
};
export const is_loading_professor_busy = (state: { professor: professorSliceState }) => {
  return state?.professor.is_loading_professor;
};
export const is_creating_professor_busy = (state: { professor: professorSliceState }) => {
  return state?.professor.is_creating_professor;
};

export default professorSlice.reducer;
