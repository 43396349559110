import ResourceImport from "./resource-import-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateResourceImportAsync, DeleteResourceImportAsync, LoadResourceImportsAsync } from "./resourceImport-thunk";
const initialState: resourceImportSliceState = {
  resourceImport: [],
  selected_resourceImport: "",
  is_loading_resourceImport: false,
  is_creating_resourceImport: false,
  is_deleting_resourceImport: false
};
export interface resourceImportSliceState {
  resourceImport: ResourceImport[];
  selected_resourceImport?: string;
  is_loading_resourceImport: boolean;
  is_creating_resourceImport: boolean;
  is_deleting_resourceImport: boolean;
}
export const resourceImportSlice = createSlice({
  name: "resourceImport",
  initialState,
  reducers: {
    setSelectedresourceImport: (state, action) => {
      state.selected_resourceImport = action.payload;
    },
    clearStateresourceImport: (state, action) => {
      state.resourceImport = [];
      state.is_loading_resourceImport = false;
      state.is_creating_resourceImport = false;
      state.is_deleting_resourceImport = false;
    },
    mergeresourceImport: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_resourceImport = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.resourceImport = merge(state.resourceImport, payload) as any;
        }
      }
    },

    add_resourceImport: (state, action) => {
      if (action.payload) {
        state.resourceImport = [...state.resourceImport, action.payload];
      }
    },
    set_resourceImport: (state, action) => {
      let { key, value, id } = action.payload;
      state.resourceImport = state.resourceImport.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadResourceImportsAsync.pending, (state) => {
      state.is_loading_resourceImport = true;
    });
    builder.addCase(LoadResourceImportsAsync.fulfilled, (state, action) => {
      state.is_loading_resourceImport = false;
      if (action.payload) {
        state.resourceImport = action.payload;
      }
    });
    builder.addCase(LoadResourceImportsAsync.rejected, (state, action) => {
      state.is_loading_resourceImport = false;
    });
    builder.addCase(CreateResourceImportAsync.pending, (state) => {
      state.is_creating_resourceImport = true;
    });
    builder.addCase(CreateResourceImportAsync.fulfilled, (state) => {
      state.is_creating_resourceImport = false;
    });
    builder.addCase(CreateResourceImportAsync.rejected, (state) => {
      state.is_creating_resourceImport = false;
    });
    builder.addCase(DeleteResourceImportAsync.pending, (state) => {
      state.is_deleting_resourceImport = true;
    });
    builder.addCase(DeleteResourceImportAsync.fulfilled, (state, action) => {
      state.is_deleting_resourceImport = false;
      if (state.resourceImport) {
        state.resourceImport = state.resourceImport.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteResourceImportAsync.rejected, (state, action) => {
      state.is_deleting_resourceImport = false;
    });
  }
});

function handleResult(action: any, state: resourceImportSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_resourceImport = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.resourceImport = merge(state.resourceImport, payload) as any;
    }
  }
}

export const { clearStateresourceImport, setSelectedresourceImport, set_resourceImport, mergeresourceImport, add_resourceImport } = resourceImportSlice.actions;

export const get_resourceImport =
  (id?: string) =>
  (state: {
    resourceImport: {
      resourceImport: ResourceImport[];
    };
  }): ResourceImport | null => {
    let temp = state?.resourceImport?.resourceImport?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.resourceImport?.resourceImport[0] || null;
  };
export const get_selected_resourceImport = (state: { resourceImport: resourceImportSliceState }): ResourceImport | null => {
  let id = state?.resourceImport?.selected_resourceImport;
  let temp = state?.resourceImport?.resourceImport?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_resourceImport = (state: {
  resourceImport: {
    resourceImport: ResourceImport[];
  };
}): ResourceImport[] => {
  let temp = state?.resourceImport?.resourceImport;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_resourceImport_busy = (state: { resourceImport: resourceImportSliceState }) => {
  return (
    state?.resourceImport.is_deleting_resourceImport || state?.resourceImport.is_loading_resourceImport || state?.resourceImport.is_creating_resourceImport
  );
};
export const is_deleting_resourceImport_busy = (state: { resourceImport: resourceImportSliceState }) => {
  return state?.resourceImport.is_deleting_resourceImport;
};
export const is_loading_resourceImport_busy = (state: { resourceImport: resourceImportSliceState }) => {
  return state?.resourceImport.is_loading_resourceImport;
};
export const is_creating_resourceImport_busy = (state: { resourceImport: resourceImportSliceState }) => {
  return state?.resourceImport.is_creating_resourceImport;
};

export default resourceImportSlice.reducer;
