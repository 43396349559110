import Customer from "./customer-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateCustomerAsync, DeleteCustomerAsync, LoadCustomersAsync } from "./customer-thunk";
const initialState: customerSliceState = {
  customer: [],
  selected_customer: "",
  is_loading_customer: false,
  is_creating_customer: false,
  is_deleting_customer: false
};
export interface customerSliceState {
  customer: Customer[];
  selected_customer?: string;
  is_loading_customer: boolean;
  is_creating_customer: boolean;
  is_deleting_customer: boolean;
}
export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setSelectedcustomer: (state, action) => {
      state.selected_customer = action.payload;
    },
    clearStatecustomer: (state, action) => {
      state.customer = [];
      state.is_loading_customer = false;
      state.is_creating_customer = false;
      state.is_deleting_customer = false;
    },
    mergecustomer: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_customer = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.customer = merge(state.customer, payload) as any;
        }
      }
    },

    add_customer: (state, action) => {
      if (action.payload) {
        state.customer = [...state.customer, action.payload];
      }
    },
    set_customer: (state, action) => {
      let { key, value, id } = action.payload;
      state.customer = state.customer.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadCustomersAsync.pending, (state) => {
      state.is_loading_customer = true;
    });
    builder.addCase(LoadCustomersAsync.fulfilled, (state, action) => {
      state.is_loading_customer = false;
      if (action.payload) {
        state.customer = action.payload;
      }
    });
    builder.addCase(LoadCustomersAsync.rejected, (state, action) => {
      state.is_loading_customer = false;
    });
    builder.addCase(CreateCustomerAsync.pending, (state) => {
      state.is_creating_customer = true;
    });
    builder.addCase(CreateCustomerAsync.fulfilled, (state) => {
      state.is_creating_customer = false;
    });
    builder.addCase(CreateCustomerAsync.rejected, (state) => {
      state.is_creating_customer = false;
    });
    builder.addCase(DeleteCustomerAsync.pending, (state) => {
      state.is_deleting_customer = true;
    });
    builder.addCase(DeleteCustomerAsync.fulfilled, (state, action) => {
      state.is_deleting_customer = false;
      if (state.customer) {
        state.customer = state.customer.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteCustomerAsync.rejected, (state, action) => {
      state.is_deleting_customer = false;
    });

    builder.addCase(autoThunks.create_customer_with_user.pending, (state) => {
      state.is_loading_customer = true;
    });
    builder.addCase(autoThunks.create_customer_with_user.fulfilled, (state, action) => {
      state.is_loading_customer = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.create_customer_with_user.rejected, (state, action) => {
      state.is_loading_customer = false;
    });

    builder.addCase(autoThunks.connect_customer_to_account.pending, (state) => {
      state.is_loading_customer = true;
    });
    builder.addCase(autoThunks.connect_customer_to_account.fulfilled, (state, action) => {
      state.is_loading_customer = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.connect_customer_to_account.rejected, (state, action) => {
      state.is_loading_customer = false;
    });

    builder.addCase(autoThunks.release_customer_from_account.pending, (state) => {
      state.is_loading_customer = true;
    });
    builder.addCase(autoThunks.release_customer_from_account.fulfilled, (state, action) => {
      state.is_loading_customer = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.release_customer_from_account.rejected, (state, action) => {
      state.is_loading_customer = false;
    });

    builder.addCase(autoThunks.reply_bank_account_join_request.pending, (state) => {
      state.is_loading_customer = true;
    });
    builder.addCase(autoThunks.reply_bank_account_join_request.fulfilled, (state, action) => {
      state.is_loading_customer = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.reply_bank_account_join_request.rejected, (state, action) => {
      state.is_loading_customer = false;
    });

    builder.addCase(autoThunks.get_customer.pending, (state) => {
      state.is_loading_customer = true;
    });
    builder.addCase(autoThunks.get_customer.fulfilled, (state, action) => {
      state.is_loading_customer = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_customer.rejected, (state, action) => {
      state.is_loading_customer = false;
    });

    builder.addCase(autoThunks.get_virtual_business_customers.pending, (state) => {
      state.is_loading_customer = true;
    });
    builder.addCase(autoThunks.get_virtual_business_customers.fulfilled, (state, action) => {
      state.is_loading_customer = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_virtual_business_customers.rejected, (state, action) => {
      state.is_loading_customer = false;
    });
  }
});

function handleResult(action: any, state: customerSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_customer = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.customer = merge(state.customer, payload) as any;
    }
  }
}

export const { clearStatecustomer, setSelectedcustomer, set_customer, mergecustomer, add_customer } = customerSlice.actions;

export const get_customer =
  (id?: string) =>
  (state: {
    customer: {
      customer: Customer[];
    };
  }): Customer | null => {
    let temp = state?.customer?.customer?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.customer?.customer[0] || null;
  };
export const get_selected_customer = (state: { customer: customerSliceState }): Customer | null => {
  let id = state?.customer?.selected_customer;
  let temp = state?.customer?.customer?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_customer = (state: {
  customer: {
    customer: Customer[];
  };
}): Customer[] => {
  let temp = state?.customer?.customer;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_customer_busy = (state: { customer: customerSliceState }) => {
  return state?.customer.is_deleting_customer || state?.customer.is_loading_customer || state?.customer.is_creating_customer;
};
export const is_deleting_customer_busy = (state: { customer: customerSliceState }) => {
  return state?.customer.is_deleting_customer;
};
export const is_loading_customer_busy = (state: { customer: customerSliceState }) => {
  return state?.customer.is_loading_customer;
};
export const is_creating_customer_busy = (state: { customer: customerSliceState }) => {
  return state?.customer.is_creating_customer;
};

export default customerSlice.reducer;
