import { createAsyncThunk } from "@reduxjs/toolkit";
import MarketingMaterialItem from "./marketing-material-item-model";
import { delete_marketingMaterialItem, load_marketingMaterialItems, store_marketingMaterialItem } from "./marketingMaterialItem-service";

export const LoadMarketingMaterialItemsAsync = createAsyncThunk<any, any>("composer/load/marketingMaterialItem", async (params: { uid: string }) => {
  return await load_marketingMaterialItems(params);
});

export const CreateMarketingMaterialItemAsync = createAsyncThunk<any, any>("composer/create/marketingMaterialItem", async (params: MarketingMaterialItem) => {
  return await store_marketingMaterialItem(params);
});

export const DeleteMarketingMaterialItemAsync = createAsyncThunk<any, any>("composer/delete/marketingMaterialItem", async (params) => {
  return await delete_marketingMaterialItem(params);
});
