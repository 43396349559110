import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import BusinessAccount from "./business-account-model";
import { BusinessAccountProperties, BusinessAccountMetaProperties } from "./businessAccount-property-definitions";

export async function load_businessAccounts(args: { uid: string }): Promise<BusinessAccount[]> {
  throw "Not implemented";
}

export async function delete_businessAccount(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_businessAccount(args: BusinessAccount): Promise<BusinessAccount> {
  throw "Not implemented";
}
