import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import Progress from "./progress-model";
import { ProgressProperties, ProgressMetaProperties } from "./progress-property-definitions";

export async function load_progresss(args: { uid: string }): Promise<Progress[]> {
  throw "Not implemented";
}

export async function delete_progress(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_progress(args: Progress): Promise<Progress> {
  throw "Not implemented";
}
