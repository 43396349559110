import CourseTaker from "./course-taker-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateCourseTakerAsync, DeleteCourseTakerAsync, LoadCourseTakersAsync } from "./courseTaker-thunk";
const initialState: courseTakerSliceState = {
  courseTaker: [],
  selected_courseTaker: "",
  is_loading_courseTaker: false,
  is_creating_courseTaker: false,
  is_deleting_courseTaker: false
};
export interface courseTakerSliceState {
  courseTaker: CourseTaker[];
  selected_courseTaker?: string;
  is_loading_courseTaker: boolean;
  is_creating_courseTaker: boolean;
  is_deleting_courseTaker: boolean;
}
export const courseTakerSlice = createSlice({
  name: "courseTaker",
  initialState,
  reducers: {
    setSelectedcourseTaker: (state, action) => {
      state.selected_courseTaker = action.payload;
    },
    clearStatecourseTaker: (state, action) => {
      state.courseTaker = [];
      state.is_loading_courseTaker = false;
      state.is_creating_courseTaker = false;
      state.is_deleting_courseTaker = false;
    },
    mergecourseTaker: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_courseTaker = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.courseTaker = merge(state.courseTaker, payload) as any;
        }
      }
    },

    add_courseTaker: (state, action) => {
      if (action.payload) {
        state.courseTaker = [...state.courseTaker, action.payload];
      }
    },
    set_courseTaker: (state, action) => {
      let { key, value, id } = action.payload;
      state.courseTaker = state.courseTaker.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadCourseTakersAsync.pending, (state) => {
      state.is_loading_courseTaker = true;
    });
    builder.addCase(LoadCourseTakersAsync.fulfilled, (state, action) => {
      state.is_loading_courseTaker = false;
      if (action.payload) {
        state.courseTaker = action.payload;
      }
    });
    builder.addCase(LoadCourseTakersAsync.rejected, (state, action) => {
      state.is_loading_courseTaker = false;
    });
    builder.addCase(CreateCourseTakerAsync.pending, (state) => {
      state.is_creating_courseTaker = true;
    });
    builder.addCase(CreateCourseTakerAsync.fulfilled, (state) => {
      state.is_creating_courseTaker = false;
    });
    builder.addCase(CreateCourseTakerAsync.rejected, (state) => {
      state.is_creating_courseTaker = false;
    });
    builder.addCase(DeleteCourseTakerAsync.pending, (state) => {
      state.is_deleting_courseTaker = true;
    });
    builder.addCase(DeleteCourseTakerAsync.fulfilled, (state, action) => {
      state.is_deleting_courseTaker = false;
      if (state.courseTaker) {
        state.courseTaker = state.courseTaker.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteCourseTakerAsync.rejected, (state, action) => {
      state.is_deleting_courseTaker = false;
    });

    builder.addCase(autoThunks.get_my_testTaker.pending, (state) => {
      state.is_loading_courseTaker = true;
    });
    builder.addCase(autoThunks.get_my_testTaker.fulfilled, (state, action) => {
      state.is_loading_courseTaker = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_my_testTaker.rejected, (state, action) => {
      state.is_loading_courseTaker = false;
    });

    builder.addCase(autoThunks.assign_my_testTaker.pending, (state) => {
      state.is_loading_courseTaker = true;
    });
    builder.addCase(autoThunks.assign_my_testTaker.fulfilled, (state, action) => {
      state.is_loading_courseTaker = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.assign_my_testTaker.rejected, (state, action) => {
      state.is_loading_courseTaker = false;
    });
  }
});

function handleResult(action: any, state: courseTakerSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_courseTaker = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.courseTaker = merge(state.courseTaker, payload) as any;
    }
  }
}

export const { clearStatecourseTaker, setSelectedcourseTaker, set_courseTaker, mergecourseTaker, add_courseTaker } = courseTakerSlice.actions;

export const get_courseTaker =
  (id?: string) =>
  (state: {
    courseTaker: {
      courseTaker: CourseTaker[];
    };
  }): CourseTaker | null => {
    let temp = state?.courseTaker?.courseTaker?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.courseTaker?.courseTaker[0] || null;
  };
export const get_selected_courseTaker = (state: { courseTaker: courseTakerSliceState }): CourseTaker | null => {
  let id = state?.courseTaker?.selected_courseTaker;
  let temp = state?.courseTaker?.courseTaker?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_courseTaker = (state: {
  courseTaker: {
    courseTaker: CourseTaker[];
  };
}): CourseTaker[] => {
  let temp = state?.courseTaker?.courseTaker;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_courseTaker_busy = (state: { courseTaker: courseTakerSliceState }) => {
  return state?.courseTaker.is_deleting_courseTaker || state?.courseTaker.is_loading_courseTaker || state?.courseTaker.is_creating_courseTaker;
};
export const is_deleting_courseTaker_busy = (state: { courseTaker: courseTakerSliceState }) => {
  return state?.courseTaker.is_deleting_courseTaker;
};
export const is_loading_courseTaker_busy = (state: { courseTaker: courseTakerSliceState }) => {
  return state?.courseTaker.is_loading_courseTaker;
};
export const is_creating_courseTaker_busy = (state: { courseTaker: courseTakerSliceState }) => {
  return state?.courseTaker.is_creating_courseTaker;
};

export default courseTakerSlice.reducer;
