import VirtualBusinessRequirement from "./virtual-business-requirement-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import {
  CreateVirtualBusinessRequirementAsync,
  DeleteVirtualBusinessRequirementAsync,
  LoadVirtualBusinessRequirementsAsync
} from "./virtualBusinessRequirement-thunk";
const initialState: virtualBusinessRequirementSliceState = {
  virtualBusinessRequirement: [],
  selected_virtualBusinessRequirement: "",
  is_loading_virtualBusinessRequirement: false,
  is_creating_virtualBusinessRequirement: false,
  is_deleting_virtualBusinessRequirement: false
};
export interface virtualBusinessRequirementSliceState {
  virtualBusinessRequirement: VirtualBusinessRequirement[];
  selected_virtualBusinessRequirement?: string;
  is_loading_virtualBusinessRequirement: boolean;
  is_creating_virtualBusinessRequirement: boolean;
  is_deleting_virtualBusinessRequirement: boolean;
}
export const virtualBusinessRequirementSlice = createSlice({
  name: "virtualBusinessRequirement",
  initialState,
  reducers: {
    setSelectedvirtualBusinessRequirement: (state, action) => {
      state.selected_virtualBusinessRequirement = action.payload;
    },
    clearStatevirtualBusinessRequirement: (state, action) => {
      state.virtualBusinessRequirement = [];
      state.is_loading_virtualBusinessRequirement = false;
      state.is_creating_virtualBusinessRequirement = false;
      state.is_deleting_virtualBusinessRequirement = false;
    },
    mergevirtualBusinessRequirement: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_virtualBusinessRequirement = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.virtualBusinessRequirement = merge(state.virtualBusinessRequirement, payload) as any;
        }
      }
    },

    add_virtualBusinessRequirement: (state, action) => {
      if (action.payload) {
        state.virtualBusinessRequirement = [...state.virtualBusinessRequirement, action.payload];
      }
    },
    set_virtualBusinessRequirement: (state, action) => {
      let { key, value, id } = action.payload;
      state.virtualBusinessRequirement = state.virtualBusinessRequirement.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadVirtualBusinessRequirementsAsync.pending, (state) => {
      state.is_loading_virtualBusinessRequirement = true;
    });
    builder.addCase(LoadVirtualBusinessRequirementsAsync.fulfilled, (state, action) => {
      state.is_loading_virtualBusinessRequirement = false;
      if (action.payload) {
        state.virtualBusinessRequirement = action.payload;
      }
    });
    builder.addCase(LoadVirtualBusinessRequirementsAsync.rejected, (state, action) => {
      state.is_loading_virtualBusinessRequirement = false;
    });
    builder.addCase(CreateVirtualBusinessRequirementAsync.pending, (state) => {
      state.is_creating_virtualBusinessRequirement = true;
    });
    builder.addCase(CreateVirtualBusinessRequirementAsync.fulfilled, (state) => {
      state.is_creating_virtualBusinessRequirement = false;
    });
    builder.addCase(CreateVirtualBusinessRequirementAsync.rejected, (state) => {
      state.is_creating_virtualBusinessRequirement = false;
    });
    builder.addCase(DeleteVirtualBusinessRequirementAsync.pending, (state) => {
      state.is_deleting_virtualBusinessRequirement = true;
    });
    builder.addCase(DeleteVirtualBusinessRequirementAsync.fulfilled, (state, action) => {
      state.is_deleting_virtualBusinessRequirement = false;
      if (state.virtualBusinessRequirement) {
        state.virtualBusinessRequirement = state.virtualBusinessRequirement.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteVirtualBusinessRequirementAsync.rejected, (state, action) => {
      state.is_deleting_virtualBusinessRequirement = false;
    });

    builder.addCase(autoThunks.get_virtual_businss_requirements.pending, (state) => {
      state.is_loading_virtualBusinessRequirement = true;
    });
    builder.addCase(autoThunks.get_virtual_businss_requirements.fulfilled, (state, action) => {
      state.is_loading_virtualBusinessRequirement = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_virtual_businss_requirements.rejected, (state, action) => {
      state.is_loading_virtualBusinessRequirement = false;
    });
  }
});

function handleResult(action: any, state: virtualBusinessRequirementSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_virtualBusinessRequirement = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.virtualBusinessRequirement = merge(state.virtualBusinessRequirement, payload) as any;
    }
  }
}

export const {
  clearStatevirtualBusinessRequirement,
  setSelectedvirtualBusinessRequirement,
  set_virtualBusinessRequirement,
  mergevirtualBusinessRequirement,
  add_virtualBusinessRequirement
} = virtualBusinessRequirementSlice.actions;

export const get_virtualBusinessRequirement =
  (id?: string) =>
  (state: {
    virtualBusinessRequirement: {
      virtualBusinessRequirement: VirtualBusinessRequirement[];
    };
  }): VirtualBusinessRequirement | null => {
    let temp = state?.virtualBusinessRequirement?.virtualBusinessRequirement?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.virtualBusinessRequirement?.virtualBusinessRequirement[0] || null;
  };
export const get_selected_virtualBusinessRequirement = (state: {
  virtualBusinessRequirement: virtualBusinessRequirementSliceState;
}): VirtualBusinessRequirement | null => {
  let id = state?.virtualBusinessRequirement?.selected_virtualBusinessRequirement;
  let temp = state?.virtualBusinessRequirement?.virtualBusinessRequirement?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_virtualBusinessRequirement = (state: {
  virtualBusinessRequirement: {
    virtualBusinessRequirement: VirtualBusinessRequirement[];
  };
}): VirtualBusinessRequirement[] => {
  let temp = state?.virtualBusinessRequirement?.virtualBusinessRequirement;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_virtualBusinessRequirement_busy = (state: { virtualBusinessRequirement: virtualBusinessRequirementSliceState }) => {
  return (
    state?.virtualBusinessRequirement.is_deleting_virtualBusinessRequirement ||
    state?.virtualBusinessRequirement.is_loading_virtualBusinessRequirement ||
    state?.virtualBusinessRequirement.is_creating_virtualBusinessRequirement
  );
};
export const is_deleting_virtualBusinessRequirement_busy = (state: { virtualBusinessRequirement: virtualBusinessRequirementSliceState }) => {
  return state?.virtualBusinessRequirement.is_deleting_virtualBusinessRequirement;
};
export const is_loading_virtualBusinessRequirement_busy = (state: { virtualBusinessRequirement: virtualBusinessRequirementSliceState }) => {
  return state?.virtualBusinessRequirement.is_loading_virtualBusinessRequirement;
};
export const is_creating_virtualBusinessRequirement_busy = (state: { virtualBusinessRequirement: virtualBusinessRequirementSliceState }) => {
  return state?.virtualBusinessRequirement.is_creating_virtualBusinessRequirement;
};

export default virtualBusinessRequirementSlice.reducer;
