import ResourceRegistration from "./resource-registration-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateResourceRegistrationAsync, DeleteResourceRegistrationAsync, LoadResourceRegistrationsAsync } from "./resourceRegistration-thunk";
const initialState: resourceRegistrationSliceState = {
  resourceRegistration: [],
  selected_resourceRegistration: "",
  is_loading_resourceRegistration: false,
  is_creating_resourceRegistration: false,
  is_deleting_resourceRegistration: false
};
export interface resourceRegistrationSliceState {
  resourceRegistration: ResourceRegistration[];
  selected_resourceRegistration?: string;
  is_loading_resourceRegistration: boolean;
  is_creating_resourceRegistration: boolean;
  is_deleting_resourceRegistration: boolean;
}
export const resourceRegistrationSlice = createSlice({
  name: "resourceRegistration",
  initialState,
  reducers: {
    setSelectedresourceRegistration: (state, action) => {
      state.selected_resourceRegistration = action.payload;
    },
    clearStateresourceRegistration: (state, action) => {
      state.resourceRegistration = [];
      state.is_loading_resourceRegistration = false;
      state.is_creating_resourceRegistration = false;
      state.is_deleting_resourceRegistration = false;
    },
    mergeresourceRegistration: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_resourceRegistration = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.resourceRegistration = merge(state.resourceRegistration, payload) as any;
        }
      }
    },

    add_resourceRegistration: (state, action) => {
      if (action.payload) {
        state.resourceRegistration = [...state.resourceRegistration, action.payload];
      }
    },
    set_resourceRegistration: (state, action) => {
      let { key, value, id } = action.payload;
      state.resourceRegistration = state.resourceRegistration.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadResourceRegistrationsAsync.pending, (state) => {
      state.is_loading_resourceRegistration = true;
    });
    builder.addCase(LoadResourceRegistrationsAsync.fulfilled, (state, action) => {
      state.is_loading_resourceRegistration = false;
      if (action.payload) {
        state.resourceRegistration = action.payload;
      }
    });
    builder.addCase(LoadResourceRegistrationsAsync.rejected, (state, action) => {
      state.is_loading_resourceRegistration = false;
    });
    builder.addCase(CreateResourceRegistrationAsync.pending, (state) => {
      state.is_creating_resourceRegistration = true;
    });
    builder.addCase(CreateResourceRegistrationAsync.fulfilled, (state) => {
      state.is_creating_resourceRegistration = false;
    });
    builder.addCase(CreateResourceRegistrationAsync.rejected, (state) => {
      state.is_creating_resourceRegistration = false;
    });
    builder.addCase(DeleteResourceRegistrationAsync.pending, (state) => {
      state.is_deleting_resourceRegistration = true;
    });
    builder.addCase(DeleteResourceRegistrationAsync.fulfilled, (state, action) => {
      state.is_deleting_resourceRegistration = false;
      if (state.resourceRegistration) {
        state.resourceRegistration = state.resourceRegistration.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteResourceRegistrationAsync.rejected, (state, action) => {
      state.is_deleting_resourceRegistration = false;
    });

    builder.addCase(autoThunks.fetch_resource_registrations.pending, (state) => {
      state.is_loading_resourceRegistration = true;
    });
    builder.addCase(autoThunks.fetch_resource_registrations.fulfilled, (state, action) => {
      state.is_loading_resourceRegistration = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.fetch_resource_registrations.rejected, (state, action) => {
      state.is_loading_resourceRegistration = false;
    });
  }
});

function handleResult(action: any, state: resourceRegistrationSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_resourceRegistration = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.resourceRegistration = merge(state.resourceRegistration, payload) as any;
    }
  }
}

export const {
  clearStateresourceRegistration,
  setSelectedresourceRegistration,
  set_resourceRegistration,
  mergeresourceRegistration,
  add_resourceRegistration
} = resourceRegistrationSlice.actions;

export const get_resourceRegistration =
  (id?: string) =>
  (state: {
    resourceRegistration: {
      resourceRegistration: ResourceRegistration[];
    };
  }): ResourceRegistration | null => {
    let temp = state?.resourceRegistration?.resourceRegistration?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.resourceRegistration?.resourceRegistration[0] || null;
  };
export const get_selected_resourceRegistration = (state: { resourceRegistration: resourceRegistrationSliceState }): ResourceRegistration | null => {
  let id = state?.resourceRegistration?.selected_resourceRegistration;
  let temp = state?.resourceRegistration?.resourceRegistration?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_resourceRegistration = (state: {
  resourceRegistration: {
    resourceRegistration: ResourceRegistration[];
  };
}): ResourceRegistration[] => {
  let temp = state?.resourceRegistration?.resourceRegistration;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_resourceRegistration_busy = (state: { resourceRegistration: resourceRegistrationSliceState }) => {
  return (
    state?.resourceRegistration.is_deleting_resourceRegistration ||
    state?.resourceRegistration.is_loading_resourceRegistration ||
    state?.resourceRegistration.is_creating_resourceRegistration
  );
};
export const is_deleting_resourceRegistration_busy = (state: { resourceRegistration: resourceRegistrationSliceState }) => {
  return state?.resourceRegistration.is_deleting_resourceRegistration;
};
export const is_loading_resourceRegistration_busy = (state: { resourceRegistration: resourceRegistrationSliceState }) => {
  return state?.resourceRegistration.is_loading_resourceRegistration;
};
export const is_creating_resourceRegistration_busy = (state: { resourceRegistration: resourceRegistrationSliceState }) => {
  return state?.resourceRegistration.is_creating_resourceRegistration;
};

export default resourceRegistrationSlice.reducer;
