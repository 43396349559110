import { createAsyncThunk } from "@reduxjs/toolkit";
import CityAsset from "./city-asset-model";
import { delete_cityAsset, load_cityAssets, store_cityAsset } from "./cityAsset-service";

export const LoadCityAssetsAsync = createAsyncThunk<any, any>("composer/load/cityAsset", async (params: { uid: string }) => {
  return await load_cityAssets(params);
});

export const CreateCityAssetAsync = createAsyncThunk<any, any>("composer/create/cityAsset", async (params: CityAsset) => {
  return await store_cityAsset(params);
});

export const DeleteCityAssetAsync = createAsyncThunk<any, any>("composer/delete/cityAsset", async (params) => {
  return await delete_cityAsset(params);
});
