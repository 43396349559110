import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import StockInformation from "./stock-information-model";
import { StockInformationProperties, StockInformationMetaProperties } from "./stockInformation-property-definitions";

export async function load_stockInformations(args: { uid: string }): Promise<StockInformation[]> {
  throw "Not implemented";
}

export async function delete_stockInformation(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_stockInformation(args: StockInformation): Promise<StockInformation> {
  throw "Not implemented";
}
