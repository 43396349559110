import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import ResourceBookPage from "./resource-book-page-model";
import { ResourceBookPageProperties, ResourceBookPageMetaProperties } from "./resourceBookPage-property-definitions";

export async function load_resourceBookPages(args: { uid: string }): Promise<ResourceBookPage[]> {
  throw "Not implemented";
}

export async function delete_resourceBookPage(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_resourceBookPage(args: ResourceBookPage): Promise<ResourceBookPage> {
  throw "Not implemented";
}
