import BankCity from "./bank-city-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateBankCityAsync, DeleteBankCityAsync, LoadBankCitysAsync } from "./bankCity-thunk";
const initialState: bankCitySliceState = {
  bankCity: [],
  selected_bankCity: "",
  is_loading_bankCity: false,
  is_creating_bankCity: false,
  is_deleting_bankCity: false
};
export interface bankCitySliceState {
  bankCity: BankCity[];
  selected_bankCity?: string;
  is_loading_bankCity: boolean;
  is_creating_bankCity: boolean;
  is_deleting_bankCity: boolean;
}
export const bankCitySlice = createSlice({
  name: "bankCity",
  initialState,
  reducers: {
    setSelectedbankCity: (state, action) => {
      state.selected_bankCity = action.payload;
    },
    clearStatebankCity: (state, action) => {
      state.bankCity = [];
      state.is_loading_bankCity = false;
      state.is_creating_bankCity = false;
      state.is_deleting_bankCity = false;
    },
    mergebankCity: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_bankCity = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.bankCity = merge(state.bankCity, payload) as any;
        }
      }
    },

    add_bankCity: (state, action) => {
      if (action.payload) {
        state.bankCity = [...state.bankCity, action.payload];
      }
    },
    set_bankCity: (state, action) => {
      let { key, value, id } = action.payload;
      state.bankCity = state.bankCity.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadBankCitysAsync.pending, (state) => {
      state.is_loading_bankCity = true;
    });
    builder.addCase(LoadBankCitysAsync.fulfilled, (state, action) => {
      state.is_loading_bankCity = false;
      if (action.payload) {
        state.bankCity = action.payload;
      }
    });
    builder.addCase(LoadBankCitysAsync.rejected, (state, action) => {
      state.is_loading_bankCity = false;
    });
    builder.addCase(CreateBankCityAsync.pending, (state) => {
      state.is_creating_bankCity = true;
    });
    builder.addCase(CreateBankCityAsync.fulfilled, (state) => {
      state.is_creating_bankCity = false;
    });
    builder.addCase(CreateBankCityAsync.rejected, (state) => {
      state.is_creating_bankCity = false;
    });
    builder.addCase(DeleteBankCityAsync.pending, (state) => {
      state.is_deleting_bankCity = true;
    });
    builder.addCase(DeleteBankCityAsync.fulfilled, (state, action) => {
      state.is_deleting_bankCity = false;
      if (state.bankCity) {
        state.bankCity = state.bankCity.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteBankCityAsync.rejected, (state, action) => {
      state.is_deleting_bankCity = false;
    });
  }
});

function handleResult(action: any, state: bankCitySliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_bankCity = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.bankCity = merge(state.bankCity, payload) as any;
    }
  }
}

export const { clearStatebankCity, setSelectedbankCity, set_bankCity, mergebankCity, add_bankCity } = bankCitySlice.actions;

export const get_bankCity =
  (id?: string) =>
  (state: {
    bankCity: {
      bankCity: BankCity[];
    };
  }): BankCity | null => {
    let temp = state?.bankCity?.bankCity?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.bankCity?.bankCity[0] || null;
  };
export const get_selected_bankCity = (state: { bankCity: bankCitySliceState }): BankCity | null => {
  let id = state?.bankCity?.selected_bankCity;
  let temp = state?.bankCity?.bankCity?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_bankCity = (state: {
  bankCity: {
    bankCity: BankCity[];
  };
}): BankCity[] => {
  let temp = state?.bankCity?.bankCity;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_bankCity_busy = (state: { bankCity: bankCitySliceState }) => {
  return state?.bankCity.is_deleting_bankCity || state?.bankCity.is_loading_bankCity || state?.bankCity.is_creating_bankCity;
};
export const is_deleting_bankCity_busy = (state: { bankCity: bankCitySliceState }) => {
  return state?.bankCity.is_deleting_bankCity;
};
export const is_loading_bankCity_busy = (state: { bankCity: bankCitySliceState }) => {
  return state?.bankCity.is_loading_bankCity;
};
export const is_creating_bankCity_busy = (state: { bankCity: bankCitySliceState }) => {
  return state?.bankCity.is_creating_bankCity;
};

export default bankCitySlice.reducer;
