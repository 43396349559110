import { createAsyncThunk } from "@reduxjs/toolkit";
import Account from "./account-model";
import { delete_account, load_accounts, store_account } from "./account-service";

export const LoadAccountsAsync = createAsyncThunk<any, any>("composer/load/account", async (params: { uid: string }) => {
  return await load_accounts(params);
});

export const CreateAccountAsync = createAsyncThunk<any, any>("composer/create/account", async (params: Account) => {
  return await store_account(params);
});

export const DeleteAccountAsync = createAsyncThunk<any, any>("composer/delete/account", async (params) => {
  return await delete_account(params);
});
