import AccountBalances from "./account-balances-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateAccountBalancesAsync, DeleteAccountBalancesAsync, LoadAccountBalancessAsync } from "./accountBalances-thunk";
const initialState: accountBalancesSliceState = {
  accountBalances: [],
  selected_accountBalances: "",
  is_loading_accountBalances: false,
  is_creating_accountBalances: false,
  is_deleting_accountBalances: false
};
export interface accountBalancesSliceState {
  accountBalances: AccountBalances[];
  selected_accountBalances?: string;
  is_loading_accountBalances: boolean;
  is_creating_accountBalances: boolean;
  is_deleting_accountBalances: boolean;
}
export const accountBalancesSlice = createSlice({
  name: "accountBalances",
  initialState,
  reducers: {
    setSelectedaccountBalances: (state, action) => {
      state.selected_accountBalances = action.payload;
    },
    clearStateaccountBalances: (state, action) => {
      state.accountBalances = [];
      state.is_loading_accountBalances = false;
      state.is_creating_accountBalances = false;
      state.is_deleting_accountBalances = false;
    },
    mergeaccountBalances: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_accountBalances = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.accountBalances = merge(state.accountBalances, payload) as any;
        }
      }
    },

    add_accountBalances: (state, action) => {
      if (action.payload) {
        state.accountBalances = [...state.accountBalances, action.payload];
      }
    },
    set_accountBalances: (state, action) => {
      let { key, value, id } = action.payload;
      state.accountBalances = state.accountBalances.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadAccountBalancessAsync.pending, (state) => {
      state.is_loading_accountBalances = true;
    });
    builder.addCase(LoadAccountBalancessAsync.fulfilled, (state, action) => {
      state.is_loading_accountBalances = false;
      if (action.payload) {
        state.accountBalances = action.payload;
      }
    });
    builder.addCase(LoadAccountBalancessAsync.rejected, (state, action) => {
      state.is_loading_accountBalances = false;
    });
    builder.addCase(CreateAccountBalancesAsync.pending, (state) => {
      state.is_creating_accountBalances = true;
    });
    builder.addCase(CreateAccountBalancesAsync.fulfilled, (state) => {
      state.is_creating_accountBalances = false;
    });
    builder.addCase(CreateAccountBalancesAsync.rejected, (state) => {
      state.is_creating_accountBalances = false;
    });
    builder.addCase(DeleteAccountBalancesAsync.pending, (state) => {
      state.is_deleting_accountBalances = true;
    });
    builder.addCase(DeleteAccountBalancesAsync.fulfilled, (state, action) => {
      state.is_deleting_accountBalances = false;
      if (state.accountBalances) {
        state.accountBalances = state.accountBalances.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteAccountBalancesAsync.rejected, (state, action) => {
      state.is_deleting_accountBalances = false;
    });

    builder.addCase(autoThunks.get_bank_Account_balances.pending, (state) => {
      state.is_loading_accountBalances = true;
    });
    builder.addCase(autoThunks.get_bank_Account_balances.fulfilled, (state, action) => {
      state.is_loading_accountBalances = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_bank_Account_balances.rejected, (state, action) => {
      state.is_loading_accountBalances = false;
    });
  }
});

function handleResult(action: any, state: accountBalancesSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_accountBalances = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.accountBalances = merge(state.accountBalances, payload) as any;
    }
  }
}

export const { clearStateaccountBalances, setSelectedaccountBalances, set_accountBalances, mergeaccountBalances, add_accountBalances } =
  accountBalancesSlice.actions;

export const get_accountBalances =
  (id?: string) =>
  (state: {
    accountBalances: {
      accountBalances: AccountBalances[];
    };
  }): AccountBalances | null => {
    let temp = state?.accountBalances?.accountBalances?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.accountBalances?.accountBalances[0] || null;
  };
export const get_selected_accountBalances = (state: { accountBalances: accountBalancesSliceState }): AccountBalances | null => {
  let id = state?.accountBalances?.selected_accountBalances;
  let temp = state?.accountBalances?.accountBalances?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_accountBalances = (state: {
  accountBalances: {
    accountBalances: AccountBalances[];
  };
}): AccountBalances[] => {
  let temp = state?.accountBalances?.accountBalances;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_accountBalances_busy = (state: { accountBalances: accountBalancesSliceState }) => {
  return (
    state?.accountBalances.is_deleting_accountBalances ||
    state?.accountBalances.is_loading_accountBalances ||
    state?.accountBalances.is_creating_accountBalances
  );
};
export const is_deleting_accountBalances_busy = (state: { accountBalances: accountBalancesSliceState }) => {
  return state?.accountBalances.is_deleting_accountBalances;
};
export const is_loading_accountBalances_busy = (state: { accountBalances: accountBalancesSliceState }) => {
  return state?.accountBalances.is_loading_accountBalances;
};
export const is_creating_accountBalances_busy = (state: { accountBalances: accountBalancesSliceState }) => {
  return state?.accountBalances.is_creating_accountBalances;
};

export default accountBalancesSlice.reducer;
