import VirtualBusinessDivestmentRequest from "./virtual-business-divestment-request-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import {
  CreateVirtualBusinessDivestmentRequestAsync,
  DeleteVirtualBusinessDivestmentRequestAsync,
  LoadVirtualBusinessDivestmentRequestsAsync
} from "./virtualBusinessDivestmentRequest-thunk";
const initialState: virtualBusinessDivestmentRequestSliceState = {
  virtualBusinessDivestmentRequest: [],
  selected_virtualBusinessDivestmentRequest: "",
  is_loading_virtualBusinessDivestmentRequest: false,
  is_creating_virtualBusinessDivestmentRequest: false,
  is_deleting_virtualBusinessDivestmentRequest: false
};
export interface virtualBusinessDivestmentRequestSliceState {
  virtualBusinessDivestmentRequest: VirtualBusinessDivestmentRequest[];
  selected_virtualBusinessDivestmentRequest?: string;
  is_loading_virtualBusinessDivestmentRequest: boolean;
  is_creating_virtualBusinessDivestmentRequest: boolean;
  is_deleting_virtualBusinessDivestmentRequest: boolean;
}
export const virtualBusinessDivestmentRequestSlice = createSlice({
  name: "virtualBusinessDivestmentRequest",
  initialState,
  reducers: {
    setSelectedvirtualBusinessDivestmentRequest: (state, action) => {
      state.selected_virtualBusinessDivestmentRequest = action.payload;
    },
    clearStatevirtualBusinessDivestmentRequest: (state, action) => {
      state.virtualBusinessDivestmentRequest = [];
      state.is_loading_virtualBusinessDivestmentRequest = false;
      state.is_creating_virtualBusinessDivestmentRequest = false;
      state.is_deleting_virtualBusinessDivestmentRequest = false;
    },
    mergevirtualBusinessDivestmentRequest: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_virtualBusinessDivestmentRequest = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.virtualBusinessDivestmentRequest = merge(state.virtualBusinessDivestmentRequest, payload) as any;
        }
      }
    },

    add_virtualBusinessDivestmentRequest: (state, action) => {
      if (action.payload) {
        state.virtualBusinessDivestmentRequest = [...state.virtualBusinessDivestmentRequest, action.payload];
      }
    },
    set_virtualBusinessDivestmentRequest: (state, action) => {
      let { key, value, id } = action.payload;
      state.virtualBusinessDivestmentRequest = state.virtualBusinessDivestmentRequest.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadVirtualBusinessDivestmentRequestsAsync.pending, (state) => {
      state.is_loading_virtualBusinessDivestmentRequest = true;
    });
    builder.addCase(LoadVirtualBusinessDivestmentRequestsAsync.fulfilled, (state, action) => {
      state.is_loading_virtualBusinessDivestmentRequest = false;
      if (action.payload) {
        state.virtualBusinessDivestmentRequest = action.payload;
      }
    });
    builder.addCase(LoadVirtualBusinessDivestmentRequestsAsync.rejected, (state, action) => {
      state.is_loading_virtualBusinessDivestmentRequest = false;
    });
    builder.addCase(CreateVirtualBusinessDivestmentRequestAsync.pending, (state) => {
      state.is_creating_virtualBusinessDivestmentRequest = true;
    });
    builder.addCase(CreateVirtualBusinessDivestmentRequestAsync.fulfilled, (state) => {
      state.is_creating_virtualBusinessDivestmentRequest = false;
    });
    builder.addCase(CreateVirtualBusinessDivestmentRequestAsync.rejected, (state) => {
      state.is_creating_virtualBusinessDivestmentRequest = false;
    });
    builder.addCase(DeleteVirtualBusinessDivestmentRequestAsync.pending, (state) => {
      state.is_deleting_virtualBusinessDivestmentRequest = true;
    });
    builder.addCase(DeleteVirtualBusinessDivestmentRequestAsync.fulfilled, (state, action) => {
      state.is_deleting_virtualBusinessDivestmentRequest = false;
      if (state.virtualBusinessDivestmentRequest) {
        state.virtualBusinessDivestmentRequest = state.virtualBusinessDivestmentRequest.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteVirtualBusinessDivestmentRequestAsync.rejected, (state, action) => {
      state.is_deleting_virtualBusinessDivestmentRequest = false;
    });

    builder.addCase(autoThunks.fetch_divestment_requests.pending, (state) => {
      state.is_loading_virtualBusinessDivestmentRequest = true;
    });
    builder.addCase(autoThunks.fetch_divestment_requests.fulfilled, (state, action) => {
      state.is_loading_virtualBusinessDivestmentRequest = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.fetch_divestment_requests.rejected, (state, action) => {
      state.is_loading_virtualBusinessDivestmentRequest = false;
    });

    builder.addCase(autoThunks.respond_to_divestment_requests.pending, (state) => {
      state.is_loading_virtualBusinessDivestmentRequest = true;
    });
    builder.addCase(autoThunks.respond_to_divestment_requests.fulfilled, (state, action) => {
      state.is_loading_virtualBusinessDivestmentRequest = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.respond_to_divestment_requests.rejected, (state, action) => {
      state.is_loading_virtualBusinessDivestmentRequest = false;
    });

    builder.addCase(autoThunks.request_partnership_divestment.pending, (state) => {
      state.is_loading_virtualBusinessDivestmentRequest = true;
    });
    builder.addCase(autoThunks.request_partnership_divestment.fulfilled, (state, action) => {
      state.is_loading_virtualBusinessDivestmentRequest = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.request_partnership_divestment.rejected, (state, action) => {
      state.is_loading_virtualBusinessDivestmentRequest = false;
    });
  }
});

function handleResult(action: any, state: virtualBusinessDivestmentRequestSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_virtualBusinessDivestmentRequest = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.virtualBusinessDivestmentRequest = merge(state.virtualBusinessDivestmentRequest, payload) as any;
    }
  }
}

export const {
  clearStatevirtualBusinessDivestmentRequest,
  setSelectedvirtualBusinessDivestmentRequest,
  set_virtualBusinessDivestmentRequest,
  mergevirtualBusinessDivestmentRequest,
  add_virtualBusinessDivestmentRequest
} = virtualBusinessDivestmentRequestSlice.actions;

export const get_virtualBusinessDivestmentRequest =
  (id?: string) =>
  (state: {
    virtualBusinessDivestmentRequest: {
      virtualBusinessDivestmentRequest: VirtualBusinessDivestmentRequest[];
    };
  }): VirtualBusinessDivestmentRequest | null => {
    let temp = state?.virtualBusinessDivestmentRequest?.virtualBusinessDivestmentRequest?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.virtualBusinessDivestmentRequest?.virtualBusinessDivestmentRequest[0] || null;
  };
export const get_selected_virtualBusinessDivestmentRequest = (state: {
  virtualBusinessDivestmentRequest: virtualBusinessDivestmentRequestSliceState;
}): VirtualBusinessDivestmentRequest | null => {
  let id = state?.virtualBusinessDivestmentRequest?.selected_virtualBusinessDivestmentRequest;
  let temp = state?.virtualBusinessDivestmentRequest?.virtualBusinessDivestmentRequest?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_virtualBusinessDivestmentRequest = (state: {
  virtualBusinessDivestmentRequest: {
    virtualBusinessDivestmentRequest: VirtualBusinessDivestmentRequest[];
  };
}): VirtualBusinessDivestmentRequest[] => {
  let temp = state?.virtualBusinessDivestmentRequest?.virtualBusinessDivestmentRequest;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_virtualBusinessDivestmentRequest_busy = (state: { virtualBusinessDivestmentRequest: virtualBusinessDivestmentRequestSliceState }) => {
  return (
    state?.virtualBusinessDivestmentRequest.is_deleting_virtualBusinessDivestmentRequest ||
    state?.virtualBusinessDivestmentRequest.is_loading_virtualBusinessDivestmentRequest ||
    state?.virtualBusinessDivestmentRequest.is_creating_virtualBusinessDivestmentRequest
  );
};
export const is_deleting_virtualBusinessDivestmentRequest_busy = (state: { virtualBusinessDivestmentRequest: virtualBusinessDivestmentRequestSliceState }) => {
  return state?.virtualBusinessDivestmentRequest.is_deleting_virtualBusinessDivestmentRequest;
};
export const is_loading_virtualBusinessDivestmentRequest_busy = (state: { virtualBusinessDivestmentRequest: virtualBusinessDivestmentRequestSliceState }) => {
  return state?.virtualBusinessDivestmentRequest.is_loading_virtualBusinessDivestmentRequest;
};
export const is_creating_virtualBusinessDivestmentRequest_busy = (state: { virtualBusinessDivestmentRequest: virtualBusinessDivestmentRequestSliceState }) => {
  return state?.virtualBusinessDivestmentRequest.is_creating_virtualBusinessDivestmentRequest;
};

export default virtualBusinessDivestmentRequestSlice.reducer;
