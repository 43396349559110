import ViewBlockSummary from "./view-block-summary-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateViewBlockSummaryAsync, DeleteViewBlockSummaryAsync, LoadViewBlockSummarysAsync } from "./viewBlockSummary-thunk";
const initialState: viewBlockSummarySliceState = {
  viewBlockSummary: [],
  selected_viewBlockSummary: "",
  is_loading_viewBlockSummary: false,
  is_creating_viewBlockSummary: false,
  is_deleting_viewBlockSummary: false
};
export interface viewBlockSummarySliceState {
  viewBlockSummary: ViewBlockSummary[];
  selected_viewBlockSummary?: string;
  is_loading_viewBlockSummary: boolean;
  is_creating_viewBlockSummary: boolean;
  is_deleting_viewBlockSummary: boolean;
}
export const viewBlockSummarySlice = createSlice({
  name: "viewBlockSummary",
  initialState,
  reducers: {
    setSelectedviewBlockSummary: (state, action) => {
      state.selected_viewBlockSummary = action.payload;
    },
    clearStateviewBlockSummary: (state, action) => {
      state.viewBlockSummary = [];
      state.is_loading_viewBlockSummary = false;
      state.is_creating_viewBlockSummary = false;
      state.is_deleting_viewBlockSummary = false;
    },
    mergeviewBlockSummary: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_viewBlockSummary = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.viewBlockSummary = merge(state.viewBlockSummary, payload) as any;
        }
      }
    },

    add_viewBlockSummary: (state, action) => {
      if (action.payload) {
        state.viewBlockSummary = [...state.viewBlockSummary, action.payload];
      }
    },
    set_viewBlockSummary: (state, action) => {
      let { key, value, id } = action.payload;
      state.viewBlockSummary = state.viewBlockSummary.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadViewBlockSummarysAsync.pending, (state) => {
      state.is_loading_viewBlockSummary = true;
    });
    builder.addCase(LoadViewBlockSummarysAsync.fulfilled, (state, action) => {
      state.is_loading_viewBlockSummary = false;
      if (action.payload) {
        state.viewBlockSummary = action.payload;
      }
    });
    builder.addCase(LoadViewBlockSummarysAsync.rejected, (state, action) => {
      state.is_loading_viewBlockSummary = false;
    });
    builder.addCase(CreateViewBlockSummaryAsync.pending, (state) => {
      state.is_creating_viewBlockSummary = true;
    });
    builder.addCase(CreateViewBlockSummaryAsync.fulfilled, (state) => {
      state.is_creating_viewBlockSummary = false;
    });
    builder.addCase(CreateViewBlockSummaryAsync.rejected, (state) => {
      state.is_creating_viewBlockSummary = false;
    });
    builder.addCase(DeleteViewBlockSummaryAsync.pending, (state) => {
      state.is_deleting_viewBlockSummary = true;
    });
    builder.addCase(DeleteViewBlockSummaryAsync.fulfilled, (state, action) => {
      state.is_deleting_viewBlockSummary = false;
      if (state.viewBlockSummary) {
        state.viewBlockSummary = state.viewBlockSummary.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteViewBlockSummaryAsync.rejected, (state, action) => {
      state.is_deleting_viewBlockSummary = false;
    });
  }
});

function handleResult(action: any, state: viewBlockSummarySliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_viewBlockSummary = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.viewBlockSummary = merge(state.viewBlockSummary, payload) as any;
    }
  }
}

export const { clearStateviewBlockSummary, setSelectedviewBlockSummary, set_viewBlockSummary, mergeviewBlockSummary, add_viewBlockSummary } =
  viewBlockSummarySlice.actions;

export const get_viewBlockSummary =
  (id?: string) =>
  (state: {
    viewBlockSummary: {
      viewBlockSummary: ViewBlockSummary[];
    };
  }): ViewBlockSummary | null => {
    let temp = state?.viewBlockSummary?.viewBlockSummary?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.viewBlockSummary?.viewBlockSummary[0] || null;
  };
export const get_selected_viewBlockSummary = (state: { viewBlockSummary: viewBlockSummarySliceState }): ViewBlockSummary | null => {
  let id = state?.viewBlockSummary?.selected_viewBlockSummary;
  let temp = state?.viewBlockSummary?.viewBlockSummary?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_viewBlockSummary = (state: {
  viewBlockSummary: {
    viewBlockSummary: ViewBlockSummary[];
  };
}): ViewBlockSummary[] => {
  let temp = state?.viewBlockSummary?.viewBlockSummary;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_viewBlockSummary_busy = (state: { viewBlockSummary: viewBlockSummarySliceState }) => {
  return (
    state?.viewBlockSummary.is_deleting_viewBlockSummary ||
    state?.viewBlockSummary.is_loading_viewBlockSummary ||
    state?.viewBlockSummary.is_creating_viewBlockSummary
  );
};
export const is_deleting_viewBlockSummary_busy = (state: { viewBlockSummary: viewBlockSummarySliceState }) => {
  return state?.viewBlockSummary.is_deleting_viewBlockSummary;
};
export const is_loading_viewBlockSummary_busy = (state: { viewBlockSummary: viewBlockSummarySliceState }) => {
  return state?.viewBlockSummary.is_loading_viewBlockSummary;
};
export const is_creating_viewBlockSummary_busy = (state: { viewBlockSummary: viewBlockSummarySliceState }) => {
  return state?.viewBlockSummary.is_creating_viewBlockSummary;
};

export default viewBlockSummarySlice.reducer;
