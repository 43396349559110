import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResolveUrl, UpdateClaims } from "features/firebase-app";
import {
  CourseSectionRecord,
  User,
  CourseSectionRecordProperties,
  CourseProperties,
  CourseTaker,
  DirectorProperties,
  ProfessorProperties,
  PublishedCourse,
  PublishedCourseProperties,
  CourseQuestionLogProperties,
  Course,
  EnrollmentProperties,
  CourseQuestionProperties,
  Enrollment,
  CourseTakerSettings,
  CourseTakerSettingsProperties,
  CourseLoad
} from "models";
import { getBearerToken } from "../../application/application-slice";

export const get_course_section_records = createAsyncThunk<any, any, any>("auto/get/courses/section/records", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/courses/section/records"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const assign_my_testTaker = createAsyncThunk<any, any, any>("auto/assign/my/courseTaker", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/assign/my/courseTaker"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }
      await UpdateClaims();
      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_published_courses = createAsyncThunk<any, any, any>("auto/get/published/courses", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/published/courses"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const set_course_section_records = createAsyncThunk<any, any, any>("auto/set/courses/section/records", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/set/courses/section/records"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_my_courses = createAsyncThunk<any, any, any>("auto/get/my/courses", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/my/courses"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_available_courses = createAsyncThunk<any, any, any>("auto/get/available/courses", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/available/courses"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_my_enrollments = createAsyncThunk<any, any, any>("auto/get/my/enrollments", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/my/enrollments"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const add_my_enrollments = createAsyncThunk<any, any, any>("auto/add/my/enrollment", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/add/my/enrollment"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const remove_my_enrollment = createAsyncThunk<any, any, any>("auto/remove/my/enrollment", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/remove/my/enrollment"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_my_settings = createAsyncThunk<any, any, any>("auto/get/my/settings", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/my/settings"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const update_my_settings = createAsyncThunk<any, any, any>("auto/update/my/settings", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/update/my/settings"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const load_course = createAsyncThunk<any, any, any>("auto/load/course", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/load/course"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const update_xp = createAsyncThunk<any, any, any>("auto/update/xp", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/update/xp"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});
