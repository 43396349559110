import { createAsyncThunk } from "@reduxjs/toolkit";
import CourseTopicSummary from "./course-topic-summary-model";
import { delete_courseTopicSummary, load_courseTopicSummarys, store_courseTopicSummary } from "./courseTopicSummary-service";

export const LoadCourseTopicSummarysAsync = createAsyncThunk<any, any>("composer/load/courseTopicSummary", async (params: { uid: string }) => {
  return await load_courseTopicSummarys(params);
});

export const CreateCourseTopicSummaryAsync = createAsyncThunk<any, any>("composer/create/courseTopicSummary", async (params: CourseTopicSummary) => {
  return await store_courseTopicSummary(params);
});

export const DeleteCourseTopicSummaryAsync = createAsyncThunk<any, any>("composer/delete/courseTopicSummary", async (params) => {
  return await delete_courseTopicSummary(params);
});
