import SponorAgent from "./sponor-agent-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateSponorAgentAsync, DeleteSponorAgentAsync, LoadSponorAgentsAsync } from "./sponorAgent-thunk";
const initialState: sponorAgentSliceState = {
  sponorAgent: [],
  selected_sponorAgent: "",
  is_loading_sponorAgent: false,
  is_creating_sponorAgent: false,
  is_deleting_sponorAgent: false
};
export interface sponorAgentSliceState {
  sponorAgent: SponorAgent[];
  selected_sponorAgent?: string;
  is_loading_sponorAgent: boolean;
  is_creating_sponorAgent: boolean;
  is_deleting_sponorAgent: boolean;
}
export const sponorAgentSlice = createSlice({
  name: "sponorAgent",
  initialState,
  reducers: {
    setSelectedsponorAgent: (state, action) => {
      state.selected_sponorAgent = action.payload;
    },
    clearStatesponorAgent: (state, action) => {
      state.sponorAgent = [];
      state.is_loading_sponorAgent = false;
      state.is_creating_sponorAgent = false;
      state.is_deleting_sponorAgent = false;
    },
    mergesponorAgent: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_sponorAgent = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.sponorAgent = merge(state.sponorAgent, payload) as any;
        }
      }
    },

    add_sponorAgent: (state, action) => {
      if (action.payload) {
        state.sponorAgent = [...state.sponorAgent, action.payload];
      }
    },
    set_sponorAgent: (state, action) => {
      let { key, value, id } = action.payload;
      state.sponorAgent = state.sponorAgent.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadSponorAgentsAsync.pending, (state) => {
      state.is_loading_sponorAgent = true;
    });
    builder.addCase(LoadSponorAgentsAsync.fulfilled, (state, action) => {
      state.is_loading_sponorAgent = false;
      if (action.payload) {
        state.sponorAgent = action.payload;
      }
    });
    builder.addCase(LoadSponorAgentsAsync.rejected, (state, action) => {
      state.is_loading_sponorAgent = false;
    });
    builder.addCase(CreateSponorAgentAsync.pending, (state) => {
      state.is_creating_sponorAgent = true;
    });
    builder.addCase(CreateSponorAgentAsync.fulfilled, (state) => {
      state.is_creating_sponorAgent = false;
    });
    builder.addCase(CreateSponorAgentAsync.rejected, (state) => {
      state.is_creating_sponorAgent = false;
    });
    builder.addCase(DeleteSponorAgentAsync.pending, (state) => {
      state.is_deleting_sponorAgent = true;
    });
    builder.addCase(DeleteSponorAgentAsync.fulfilled, (state, action) => {
      state.is_deleting_sponorAgent = false;
      if (state.sponorAgent) {
        state.sponorAgent = state.sponorAgent.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteSponorAgentAsync.rejected, (state, action) => {
      state.is_deleting_sponorAgent = false;
    });
  }
});

function handleResult(action: any, state: sponorAgentSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_sponorAgent = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.sponorAgent = merge(state.sponorAgent, payload) as any;
    }
  }
}

export const { clearStatesponorAgent, setSelectedsponorAgent, set_sponorAgent, mergesponorAgent, add_sponorAgent } = sponorAgentSlice.actions;

export const get_sponorAgent =
  (id?: string) =>
  (state: {
    sponorAgent: {
      sponorAgent: SponorAgent[];
    };
  }): SponorAgent | null => {
    let temp = state?.sponorAgent?.sponorAgent?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.sponorAgent?.sponorAgent[0] || null;
  };
export const get_selected_sponorAgent = (state: { sponorAgent: sponorAgentSliceState }): SponorAgent | null => {
  let id = state?.sponorAgent?.selected_sponorAgent;
  let temp = state?.sponorAgent?.sponorAgent?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_sponorAgent = (state: {
  sponorAgent: {
    sponorAgent: SponorAgent[];
  };
}): SponorAgent[] => {
  let temp = state?.sponorAgent?.sponorAgent;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_sponorAgent_busy = (state: { sponorAgent: sponorAgentSliceState }) => {
  return state?.sponorAgent.is_deleting_sponorAgent || state?.sponorAgent.is_loading_sponorAgent || state?.sponorAgent.is_creating_sponorAgent;
};
export const is_deleting_sponorAgent_busy = (state: { sponorAgent: sponorAgentSliceState }) => {
  return state?.sponorAgent.is_deleting_sponorAgent;
};
export const is_loading_sponorAgent_busy = (state: { sponorAgent: sponorAgentSliceState }) => {
  return state?.sponorAgent.is_loading_sponorAgent;
};
export const is_creating_sponorAgent_busy = (state: { sponorAgent: sponorAgentSliceState }) => {
  return state?.sponorAgent.is_creating_sponorAgent;
};

export default sponorAgentSlice.reducer;
