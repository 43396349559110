import { createAsyncThunk } from "@reduxjs/toolkit";
import CourseConnection from "./course-connection-model";
import { delete_courseConnection, load_courseConnections, store_courseConnection } from "./courseConnection-service";

export const LoadCourseConnectionsAsync = createAsyncThunk<any, any>("composer/load/courseConnection", async (params: { uid: string }) => {
  return await load_courseConnections(params);
});

export const CreateCourseConnectionAsync = createAsyncThunk<any, any>("composer/create/courseConnection", async (params: CourseConnection) => {
  return await store_courseConnection(params);
});

export const DeleteCourseConnectionAsync = createAsyncThunk<any, any>("composer/delete/courseConnection", async (params) => {
  return await delete_courseConnection(params);
});
