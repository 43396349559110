import BankRegistry from "./bank-registry-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateBankRegistryAsync, DeleteBankRegistryAsync, LoadBankRegistrysAsync } from "./bankRegistry-thunk";
const initialState: bankRegistrySliceState = {
  bankRegistry: [],
  selected_bankRegistry: "",
  is_loading_bankRegistry: false,
  is_creating_bankRegistry: false,
  is_deleting_bankRegistry: false
};
export interface bankRegistrySliceState {
  bankRegistry: BankRegistry[];
  selected_bankRegistry?: string;
  is_loading_bankRegistry: boolean;
  is_creating_bankRegistry: boolean;
  is_deleting_bankRegistry: boolean;
}
export const bankRegistrySlice = createSlice({
  name: "bankRegistry",
  initialState,
  reducers: {
    setSelectedbankRegistry: (state, action) => {
      state.selected_bankRegistry = action.payload;
    },
    clearStatebankRegistry: (state, action) => {
      state.bankRegistry = [];
      state.is_loading_bankRegistry = false;
      state.is_creating_bankRegistry = false;
      state.is_deleting_bankRegistry = false;
    },
    mergebankRegistry: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_bankRegistry = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.bankRegistry = merge(state.bankRegistry, payload) as any;
        }
      }
    },

    add_bankRegistry: (state, action) => {
      if (action.payload) {
        state.bankRegistry = [...state.bankRegistry, action.payload];
      }
    },
    set_bankRegistry: (state, action) => {
      let { key, value, id } = action.payload;
      state.bankRegistry = state.bankRegistry.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadBankRegistrysAsync.pending, (state) => {
      state.is_loading_bankRegistry = true;
    });
    builder.addCase(LoadBankRegistrysAsync.fulfilled, (state, action) => {
      state.is_loading_bankRegistry = false;
      if (action.payload) {
        state.bankRegistry = action.payload;
      }
    });
    builder.addCase(LoadBankRegistrysAsync.rejected, (state, action) => {
      state.is_loading_bankRegistry = false;
    });
    builder.addCase(CreateBankRegistryAsync.pending, (state) => {
      state.is_creating_bankRegistry = true;
    });
    builder.addCase(CreateBankRegistryAsync.fulfilled, (state) => {
      state.is_creating_bankRegistry = false;
    });
    builder.addCase(CreateBankRegistryAsync.rejected, (state) => {
      state.is_creating_bankRegistry = false;
    });
    builder.addCase(DeleteBankRegistryAsync.pending, (state) => {
      state.is_deleting_bankRegistry = true;
    });
    builder.addCase(DeleteBankRegistryAsync.fulfilled, (state, action) => {
      state.is_deleting_bankRegistry = false;
      if (state.bankRegistry) {
        state.bankRegistry = state.bankRegistry.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteBankRegistryAsync.rejected, (state, action) => {
      state.is_deleting_bankRegistry = false;
    });

    builder.addCase(autoThunks.get_bank_registry_for_teacher.pending, (state) => {
      state.is_loading_bankRegistry = true;
    });
    builder.addCase(autoThunks.get_bank_registry_for_teacher.fulfilled, (state, action) => {
      state.is_loading_bankRegistry = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_bank_registry_for_teacher.rejected, (state, action) => {
      state.is_loading_bankRegistry = false;
    });

    builder.addCase(autoThunks.get_bank_registry.pending, (state) => {
      state.is_loading_bankRegistry = true;
    });
    builder.addCase(autoThunks.get_bank_registry.fulfilled, (state, action) => {
      state.is_loading_bankRegistry = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_bank_registry.rejected, (state, action) => {
      state.is_loading_bankRegistry = false;
    });

    builder.addCase(autoThunks.enroll_customers.pending, (state) => {
      state.is_loading_bankRegistry = true;
    });
    builder.addCase(autoThunks.enroll_customers.fulfilled, (state, action) => {
      state.is_loading_bankRegistry = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.enroll_customers.rejected, (state, action) => {
      state.is_loading_bankRegistry = false;
    });

    builder.addCase(autoThunks.get_registries.pending, (state) => {
      state.is_loading_bankRegistry = true;
    });
    builder.addCase(autoThunks.get_registries.fulfilled, (state, action) => {
      state.is_loading_bankRegistry = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_registries.rejected, (state, action) => {
      state.is_loading_bankRegistry = false;
    });

    builder.addCase(autoThunks.remove_account_from_bank.pending, (state) => {
      state.is_loading_bankRegistry = true;
    });
    builder.addCase(autoThunks.remove_account_from_bank.fulfilled, (state, action) => {
      state.is_loading_bankRegistry = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.remove_account_from_bank.rejected, (state, action) => {
      state.is_loading_bankRegistry = false;
    });
  }
});

function handleResult(action: any, state: bankRegistrySliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_bankRegistry = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.bankRegistry = merge(state.bankRegistry, payload) as any;
    }
  }
}

export const { clearStatebankRegistry, setSelectedbankRegistry, set_bankRegistry, mergebankRegistry, add_bankRegistry } = bankRegistrySlice.actions;

export const get_bankRegistry =
  (id?: string) =>
  (state: {
    bankRegistry: {
      bankRegistry: BankRegistry[];
    };
  }): BankRegistry | null => {
    let temp = state?.bankRegistry?.bankRegistry?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.bankRegistry?.bankRegistry[0] || null;
  };
export const get_selected_bankRegistry = (state: { bankRegistry: bankRegistrySliceState }): BankRegistry | null => {
  let id = state?.bankRegistry?.selected_bankRegistry;
  let temp = state?.bankRegistry?.bankRegistry?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_bankRegistry = (state: {
  bankRegistry: {
    bankRegistry: BankRegistry[];
  };
}): BankRegistry[] => {
  let temp = state?.bankRegistry?.bankRegistry;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_bankRegistry_busy = (state: { bankRegistry: bankRegistrySliceState }) => {
  return state?.bankRegistry.is_deleting_bankRegistry || state?.bankRegistry.is_loading_bankRegistry || state?.bankRegistry.is_creating_bankRegistry;
};
export const is_deleting_bankRegistry_busy = (state: { bankRegistry: bankRegistrySliceState }) => {
  return state?.bankRegistry.is_deleting_bankRegistry;
};
export const is_loading_bankRegistry_busy = (state: { bankRegistry: bankRegistrySliceState }) => {
  return state?.bankRegistry.is_loading_bankRegistry;
};
export const is_creating_bankRegistry_busy = (state: { bankRegistry: bankRegistrySliceState }) => {
  return state?.bankRegistry.is_creating_bankRegistry;
};

export default bankRegistrySlice.reducer;
