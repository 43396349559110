import CourseQuestion from "./course-question-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateCourseQuestionAsync, DeleteCourseQuestionAsync, LoadCourseQuestionsAsync } from "./courseQuestion-thunk";
const initialState: courseQuestionSliceState = {
  courseQuestion: [],
  selected_courseQuestion: "",
  is_loading_courseQuestion: false,
  is_creating_courseQuestion: false,
  is_deleting_courseQuestion: false
};
export interface courseQuestionSliceState {
  courseQuestion: CourseQuestion[];
  selected_courseQuestion?: string;
  is_loading_courseQuestion: boolean;
  is_creating_courseQuestion: boolean;
  is_deleting_courseQuestion: boolean;
}
export const courseQuestionSlice = createSlice({
  name: "courseQuestion",
  initialState,
  reducers: {
    setSelectedcourseQuestion: (state, action) => {
      state.selected_courseQuestion = action.payload;
    },
    clearStatecourseQuestion: (state, action) => {
      state.courseQuestion = [];
      state.is_loading_courseQuestion = false;
      state.is_creating_courseQuestion = false;
      state.is_deleting_courseQuestion = false;
    },
    mergecourseQuestion: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_courseQuestion = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.courseQuestion = merge(state.courseQuestion, payload) as any;
        }
      }
    },

    add_courseQuestion: (state, action) => {
      if (action.payload) {
        state.courseQuestion = [...state.courseQuestion, action.payload];
      }
    },
    set_courseQuestion: (state, action) => {
      let { key, value, id } = action.payload;
      state.courseQuestion = state.courseQuestion.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadCourseQuestionsAsync.pending, (state) => {
      state.is_loading_courseQuestion = true;
    });
    builder.addCase(LoadCourseQuestionsAsync.fulfilled, (state, action) => {
      state.is_loading_courseQuestion = false;
      if (action.payload) {
        state.courseQuestion = action.payload;
      }
    });
    builder.addCase(LoadCourseQuestionsAsync.rejected, (state, action) => {
      state.is_loading_courseQuestion = false;
    });
    builder.addCase(CreateCourseQuestionAsync.pending, (state) => {
      state.is_creating_courseQuestion = true;
    });
    builder.addCase(CreateCourseQuestionAsync.fulfilled, (state) => {
      state.is_creating_courseQuestion = false;
    });
    builder.addCase(CreateCourseQuestionAsync.rejected, (state) => {
      state.is_creating_courseQuestion = false;
    });
    builder.addCase(DeleteCourseQuestionAsync.pending, (state) => {
      state.is_deleting_courseQuestion = true;
    });
    builder.addCase(DeleteCourseQuestionAsync.fulfilled, (state, action) => {
      state.is_deleting_courseQuestion = false;
      if (state.courseQuestion) {
        state.courseQuestion = state.courseQuestion.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteCourseQuestionAsync.rejected, (state, action) => {
      state.is_deleting_courseQuestion = false;
    });

    builder.addCase(autoThunks.add_course_question.pending, (state) => {
      state.is_loading_courseQuestion = true;
    });
    builder.addCase(autoThunks.add_course_question.fulfilled, (state, action) => {
      state.is_loading_courseQuestion = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.add_course_question.rejected, (state, action) => {
      state.is_loading_courseQuestion = false;
    });

    builder.addCase(autoThunks.get_course_questions.pending, (state) => {
      state.is_loading_courseQuestion = true;
    });
    builder.addCase(autoThunks.get_course_questions.fulfilled, (state, action) => {
      state.is_loading_courseQuestion = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_course_questions.rejected, (state, action) => {
      state.is_loading_courseQuestion = false;
    });

    builder.addCase(autoThunks.delete_course_question.pending, (state) => {
      state.is_loading_courseQuestion = true;
    });
    builder.addCase(autoThunks.delete_course_question.fulfilled, (state, action) => {
      state.is_loading_courseQuestion = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.delete_course_question.rejected, (state, action) => {
      state.is_loading_courseQuestion = false;
    });

    builder.addCase(autoThunks.update_course_question.pending, (state) => {
      state.is_loading_courseQuestion = true;
    });
    builder.addCase(autoThunks.update_course_question.fulfilled, (state, action) => {
      state.is_loading_courseQuestion = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.update_course_question.rejected, (state, action) => {
      state.is_loading_courseQuestion = false;
    });
  }
});

function handleResult(action: any, state: courseQuestionSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_courseQuestion = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.courseQuestion = merge(state.courseQuestion, payload) as any;
    }
  }
}

export const { clearStatecourseQuestion, setSelectedcourseQuestion, set_courseQuestion, mergecourseQuestion, add_courseQuestion } = courseQuestionSlice.actions;

export const get_courseQuestion =
  (id?: string) =>
  (state: {
    courseQuestion: {
      courseQuestion: CourseQuestion[];
    };
  }): CourseQuestion | null => {
    let temp = state?.courseQuestion?.courseQuestion?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.courseQuestion?.courseQuestion[0] || null;
  };
export const get_selected_courseQuestion = (state: { courseQuestion: courseQuestionSliceState }): CourseQuestion | null => {
  let id = state?.courseQuestion?.selected_courseQuestion;
  let temp = state?.courseQuestion?.courseQuestion?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_courseQuestion = (state: {
  courseQuestion: {
    courseQuestion: CourseQuestion[];
  };
}): CourseQuestion[] => {
  let temp = state?.courseQuestion?.courseQuestion;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_courseQuestion_busy = (state: { courseQuestion: courseQuestionSliceState }) => {
  return (
    state?.courseQuestion.is_deleting_courseQuestion || state?.courseQuestion.is_loading_courseQuestion || state?.courseQuestion.is_creating_courseQuestion
  );
};
export const is_deleting_courseQuestion_busy = (state: { courseQuestion: courseQuestionSliceState }) => {
  return state?.courseQuestion.is_deleting_courseQuestion;
};
export const is_loading_courseQuestion_busy = (state: { courseQuestion: courseQuestionSliceState }) => {
  return state?.courseQuestion.is_loading_courseQuestion;
};
export const is_creating_courseQuestion_busy = (state: { courseQuestion: courseQuestionSliceState }) => {
  return state?.courseQuestion.is_creating_courseQuestion;
};

export default courseQuestionSlice.reducer;
