import { createAsyncThunk } from "@reduxjs/toolkit";
import CourseQuestionSorting from "./course-question-sorting-model";
import { delete_courseQuestionSorting, load_courseQuestionSortings, store_courseQuestionSorting } from "./courseQuestionSorting-service";

export const LoadCourseQuestionSortingsAsync = createAsyncThunk<any, any>("composer/load/courseQuestionSorting", async (params: { uid: string }) => {
  return await load_courseQuestionSortings(params);
});

export const CreateCourseQuestionSortingAsync = createAsyncThunk<any, any>("composer/create/courseQuestionSorting", async (params: CourseQuestionSorting) => {
  return await store_courseQuestionSorting(params);
});

export const DeleteCourseQuestionSortingAsync = createAsyncThunk<any, any>("composer/delete/courseQuestionSorting", async (params) => {
  return await delete_courseQuestionSorting(params);
});
