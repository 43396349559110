import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import AccountCurriculumRecord from "./account-curriculum-record-model";
import { AccountCurriculumRecordProperties, AccountCurriculumRecordMetaProperties } from "./accountCurriculumRecord-property-definitions";

export async function load_accountCurriculumRecords(args: { uid: string }): Promise<AccountCurriculumRecord[]> {
  throw "Not implemented";
}

export async function delete_accountCurriculumRecord(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_accountCurriculumRecord(args: AccountCurriculumRecord): Promise<AccountCurriculumRecord> {
  throw "Not implemented";
}
