import StockInformation from "./stock-information-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateStockInformationAsync, DeleteStockInformationAsync, LoadStockInformationsAsync } from "./stockInformation-thunk";
const initialState: stockInformationSliceState = {
  stockInformation: [],
  selected_stockInformation: "",
  is_loading_stockInformation: false,
  is_creating_stockInformation: false,
  is_deleting_stockInformation: false
};
export interface stockInformationSliceState {
  stockInformation: StockInformation[];
  selected_stockInformation?: string;
  is_loading_stockInformation: boolean;
  is_creating_stockInformation: boolean;
  is_deleting_stockInformation: boolean;
}
export const stockInformationSlice = createSlice({
  name: "stockInformation",
  initialState,
  reducers: {
    setSelectedstockInformation: (state, action) => {
      state.selected_stockInformation = action.payload;
    },
    clearStatestockInformation: (state, action) => {
      state.stockInformation = [];
      state.is_loading_stockInformation = false;
      state.is_creating_stockInformation = false;
      state.is_deleting_stockInformation = false;
    },
    mergestockInformation: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_stockInformation = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.stockInformation = merge(state.stockInformation, payload) as any;
        }
      }
    },

    add_stockInformation: (state, action) => {
      if (action.payload) {
        state.stockInformation = [...state.stockInformation, action.payload];
      }
    },
    set_stockInformation: (state, action) => {
      let { key, value, id } = action.payload;
      state.stockInformation = state.stockInformation.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadStockInformationsAsync.pending, (state) => {
      state.is_loading_stockInformation = true;
    });
    builder.addCase(LoadStockInformationsAsync.fulfilled, (state, action) => {
      state.is_loading_stockInformation = false;
      if (action.payload) {
        state.stockInformation = action.payload;
      }
    });
    builder.addCase(LoadStockInformationsAsync.rejected, (state, action) => {
      state.is_loading_stockInformation = false;
    });
    builder.addCase(CreateStockInformationAsync.pending, (state) => {
      state.is_creating_stockInformation = true;
    });
    builder.addCase(CreateStockInformationAsync.fulfilled, (state) => {
      state.is_creating_stockInformation = false;
    });
    builder.addCase(CreateStockInformationAsync.rejected, (state) => {
      state.is_creating_stockInformation = false;
    });
    builder.addCase(DeleteStockInformationAsync.pending, (state) => {
      state.is_deleting_stockInformation = true;
    });
    builder.addCase(DeleteStockInformationAsync.fulfilled, (state, action) => {
      state.is_deleting_stockInformation = false;
      if (state.stockInformation) {
        state.stockInformation = state.stockInformation.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteStockInformationAsync.rejected, (state, action) => {
      state.is_deleting_stockInformation = false;
    });
  }
});

function handleResult(action: any, state: stockInformationSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_stockInformation = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.stockInformation = merge(state.stockInformation, payload) as any;
    }
  }
}

export const { clearStatestockInformation, setSelectedstockInformation, set_stockInformation, mergestockInformation, add_stockInformation } =
  stockInformationSlice.actions;

export const get_stockInformation =
  (id?: string) =>
  (state: {
    stockInformation: {
      stockInformation: StockInformation[];
    };
  }): StockInformation | null => {
    let temp = state?.stockInformation?.stockInformation?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.stockInformation?.stockInformation[0] || null;
  };
export const get_selected_stockInformation = (state: { stockInformation: stockInformationSliceState }): StockInformation | null => {
  let id = state?.stockInformation?.selected_stockInformation;
  let temp = state?.stockInformation?.stockInformation?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_stockInformation = (state: {
  stockInformation: {
    stockInformation: StockInformation[];
  };
}): StockInformation[] => {
  let temp = state?.stockInformation?.stockInformation;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_stockInformation_busy = (state: { stockInformation: stockInformationSliceState }) => {
  return (
    state?.stockInformation.is_deleting_stockInformation ||
    state?.stockInformation.is_loading_stockInformation ||
    state?.stockInformation.is_creating_stockInformation
  );
};
export const is_deleting_stockInformation_busy = (state: { stockInformation: stockInformationSliceState }) => {
  return state?.stockInformation.is_deleting_stockInformation;
};
export const is_loading_stockInformation_busy = (state: { stockInformation: stockInformationSliceState }) => {
  return state?.stockInformation.is_loading_stockInformation;
};
export const is_creating_stockInformation_busy = (state: { stockInformation: stockInformationSliceState }) => {
  return state?.stockInformation.is_creating_stockInformation;
};

export default stockInformationSlice.reducer;
