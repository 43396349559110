import { createAsyncThunk } from "@reduxjs/toolkit";
import Professor from "./professor-model";
import { delete_professor, load_professors, store_professor } from "./professor-service";

export const LoadProfessorsAsync = createAsyncThunk<any, any>("composer/load/professor", async (params: { uid: string }) => {
  return await load_professors(params);
});

export const CreateProfessorAsync = createAsyncThunk<any, any>("composer/create/professor", async (params: Professor) => {
  return await store_professor(params);
});

export const DeleteProfessorAsync = createAsyncThunk<any, any>("composer/delete/professor", async (params) => {
  return await delete_professor(params);
});
