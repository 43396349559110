import CityAsset from "./city-asset-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateCityAssetAsync, DeleteCityAssetAsync, LoadCityAssetsAsync } from "./cityAsset-thunk";
const initialState: cityAssetSliceState = {
  cityAsset: [],
  selected_cityAsset: "",
  is_loading_cityAsset: false,
  is_creating_cityAsset: false,
  is_deleting_cityAsset: false
};
export interface cityAssetSliceState {
  cityAsset: CityAsset[];
  selected_cityAsset?: string;
  is_loading_cityAsset: boolean;
  is_creating_cityAsset: boolean;
  is_deleting_cityAsset: boolean;
}
export const cityAssetSlice = createSlice({
  name: "cityAsset",
  initialState,
  reducers: {
    setSelectedcityAsset: (state, action) => {
      state.selected_cityAsset = action.payload;
    },
    clearStatecityAsset: (state, action) => {
      state.cityAsset = [];
      state.is_loading_cityAsset = false;
      state.is_creating_cityAsset = false;
      state.is_deleting_cityAsset = false;
    },
    mergecityAsset: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_cityAsset = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.cityAsset = merge(state.cityAsset, payload) as any;
        }
      }
    },

    add_cityAsset: (state, action) => {
      if (action.payload) {
        state.cityAsset = [...state.cityAsset, action.payload];
      }
    },
    set_cityAsset: (state, action) => {
      let { key, value, id } = action.payload;
      state.cityAsset = state.cityAsset.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadCityAssetsAsync.pending, (state) => {
      state.is_loading_cityAsset = true;
    });
    builder.addCase(LoadCityAssetsAsync.fulfilled, (state, action) => {
      state.is_loading_cityAsset = false;
      if (action.payload) {
        state.cityAsset = action.payload;
      }
    });
    builder.addCase(LoadCityAssetsAsync.rejected, (state, action) => {
      state.is_loading_cityAsset = false;
    });
    builder.addCase(CreateCityAssetAsync.pending, (state) => {
      state.is_creating_cityAsset = true;
    });
    builder.addCase(CreateCityAssetAsync.fulfilled, (state) => {
      state.is_creating_cityAsset = false;
    });
    builder.addCase(CreateCityAssetAsync.rejected, (state) => {
      state.is_creating_cityAsset = false;
    });
    builder.addCase(DeleteCityAssetAsync.pending, (state) => {
      state.is_deleting_cityAsset = true;
    });
    builder.addCase(DeleteCityAssetAsync.fulfilled, (state, action) => {
      state.is_deleting_cityAsset = false;
      if (state.cityAsset) {
        state.cityAsset = state.cityAsset.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteCityAssetAsync.rejected, (state, action) => {
      state.is_deleting_cityAsset = false;
    });
  }
});

function handleResult(action: any, state: cityAssetSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_cityAsset = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.cityAsset = merge(state.cityAsset, payload) as any;
    }
  }
}

export const { clearStatecityAsset, setSelectedcityAsset, set_cityAsset, mergecityAsset, add_cityAsset } = cityAssetSlice.actions;

export const get_cityAsset =
  (id?: string) =>
  (state: {
    cityAsset: {
      cityAsset: CityAsset[];
    };
  }): CityAsset | null => {
    let temp = state?.cityAsset?.cityAsset?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.cityAsset?.cityAsset[0] || null;
  };
export const get_selected_cityAsset = (state: { cityAsset: cityAssetSliceState }): CityAsset | null => {
  let id = state?.cityAsset?.selected_cityAsset;
  let temp = state?.cityAsset?.cityAsset?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_cityAsset = (state: {
  cityAsset: {
    cityAsset: CityAsset[];
  };
}): CityAsset[] => {
  let temp = state?.cityAsset?.cityAsset;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_cityAsset_busy = (state: { cityAsset: cityAssetSliceState }) => {
  return state?.cityAsset.is_deleting_cityAsset || state?.cityAsset.is_loading_cityAsset || state?.cityAsset.is_creating_cityAsset;
};
export const is_deleting_cityAsset_busy = (state: { cityAsset: cityAssetSliceState }) => {
  return state?.cityAsset.is_deleting_cityAsset;
};
export const is_loading_cityAsset_busy = (state: { cityAsset: cityAssetSliceState }) => {
  return state?.cityAsset.is_loading_cityAsset;
};
export const is_creating_cityAsset_busy = (state: { cityAsset: cityAssetSliceState }) => {
  return state?.cityAsset.is_creating_cityAsset;
};

export default cityAssetSlice.reducer;
