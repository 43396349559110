import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import CourseSorting from "./course-sorting-model";
import { CourseSortingProperties, CourseSortingMetaProperties } from "./courseSorting-property-definitions";

export async function load_courseSortings(args: { uid: string }): Promise<CourseSorting[]> {
  throw "Not implemented";
}

export async function delete_courseSorting(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_courseSorting(args: CourseSorting): Promise<CourseSorting> {
  throw "Not implemented";
}
