import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { GuardProps } from 'types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserClaims } from 'features/application/application-slice';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const DirectorGuard = ({ children }: GuardProps) => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const claims = useSelector(getUserClaims);

    useEffect(() => {
        if (claims)
            if (!isLoggedIn || !claims?.director) {
                navigate('/login', { replace: true });
            }
    }, [isLoggedIn, navigate]);

    return children;
};

export default DirectorGuard;
