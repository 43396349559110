import VirtualBusinessPetitionPartnershipDissolutionMessage from "./virtual-business-petition-partnership-dissolution-message-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import {
  CreateVirtualBusinessPetitionPartnershipDissolutionMessageAsync,
  DeleteVirtualBusinessPetitionPartnershipDissolutionMessageAsync,
  LoadVirtualBusinessPetitionPartnershipDissolutionMessagesAsync
} from "./virtualBusinessPetitionPartnershipDissolutionMessage-thunk";
const initialState: virtualBusinessPetitionPartnershipDissolutionMessageSliceState = {
  virtualBusinessPetitionPartnershipDissolutionMessage: [],
  selected_virtualBusinessPetitionPartnershipDissolutionMessage: "",
  is_loading_virtualBusinessPetitionPartnershipDissolutionMessage: false,
  is_creating_virtualBusinessPetitionPartnershipDissolutionMessage: false,
  is_deleting_virtualBusinessPetitionPartnershipDissolutionMessage: false
};
export interface virtualBusinessPetitionPartnershipDissolutionMessageSliceState {
  virtualBusinessPetitionPartnershipDissolutionMessage: VirtualBusinessPetitionPartnershipDissolutionMessage[];
  selected_virtualBusinessPetitionPartnershipDissolutionMessage?: string;
  is_loading_virtualBusinessPetitionPartnershipDissolutionMessage: boolean;
  is_creating_virtualBusinessPetitionPartnershipDissolutionMessage: boolean;
  is_deleting_virtualBusinessPetitionPartnershipDissolutionMessage: boolean;
}
export const virtualBusinessPetitionPartnershipDissolutionMessageSlice = createSlice({
  name: "virtualBusinessPetitionPartnershipDissolutionMessage",
  initialState,
  reducers: {
    setSelectedvirtualBusinessPetitionPartnershipDissolutionMessage: (state, action) => {
      state.selected_virtualBusinessPetitionPartnershipDissolutionMessage = action.payload;
    },
    clearStatevirtualBusinessPetitionPartnershipDissolutionMessage: (state, action) => {
      state.virtualBusinessPetitionPartnershipDissolutionMessage = [];
      state.is_loading_virtualBusinessPetitionPartnershipDissolutionMessage = false;
      state.is_creating_virtualBusinessPetitionPartnershipDissolutionMessage = false;
      state.is_deleting_virtualBusinessPetitionPartnershipDissolutionMessage = false;
    },
    mergevirtualBusinessPetitionPartnershipDissolutionMessage: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_virtualBusinessPetitionPartnershipDissolutionMessage = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.virtualBusinessPetitionPartnershipDissolutionMessage = merge(state.virtualBusinessPetitionPartnershipDissolutionMessage, payload) as any;
        }
      }
    },

    add_virtualBusinessPetitionPartnershipDissolutionMessage: (state, action) => {
      if (action.payload) {
        state.virtualBusinessPetitionPartnershipDissolutionMessage = [...state.virtualBusinessPetitionPartnershipDissolutionMessage, action.payload];
      }
    },
    set_virtualBusinessPetitionPartnershipDissolutionMessage: (state, action) => {
      let { key, value, id } = action.payload;
      state.virtualBusinessPetitionPartnershipDissolutionMessage = state.virtualBusinessPetitionPartnershipDissolutionMessage.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadVirtualBusinessPetitionPartnershipDissolutionMessagesAsync.pending, (state) => {
      state.is_loading_virtualBusinessPetitionPartnershipDissolutionMessage = true;
    });
    builder.addCase(LoadVirtualBusinessPetitionPartnershipDissolutionMessagesAsync.fulfilled, (state, action) => {
      state.is_loading_virtualBusinessPetitionPartnershipDissolutionMessage = false;
      if (action.payload) {
        state.virtualBusinessPetitionPartnershipDissolutionMessage = action.payload;
      }
    });
    builder.addCase(LoadVirtualBusinessPetitionPartnershipDissolutionMessagesAsync.rejected, (state, action) => {
      state.is_loading_virtualBusinessPetitionPartnershipDissolutionMessage = false;
    });
    builder.addCase(CreateVirtualBusinessPetitionPartnershipDissolutionMessageAsync.pending, (state) => {
      state.is_creating_virtualBusinessPetitionPartnershipDissolutionMessage = true;
    });
    builder.addCase(CreateVirtualBusinessPetitionPartnershipDissolutionMessageAsync.fulfilled, (state) => {
      state.is_creating_virtualBusinessPetitionPartnershipDissolutionMessage = false;
    });
    builder.addCase(CreateVirtualBusinessPetitionPartnershipDissolutionMessageAsync.rejected, (state) => {
      state.is_creating_virtualBusinessPetitionPartnershipDissolutionMessage = false;
    });
    builder.addCase(DeleteVirtualBusinessPetitionPartnershipDissolutionMessageAsync.pending, (state) => {
      state.is_deleting_virtualBusinessPetitionPartnershipDissolutionMessage = true;
    });
    builder.addCase(DeleteVirtualBusinessPetitionPartnershipDissolutionMessageAsync.fulfilled, (state, action) => {
      state.is_deleting_virtualBusinessPetitionPartnershipDissolutionMessage = false;
      if (state.virtualBusinessPetitionPartnershipDissolutionMessage) {
        state.virtualBusinessPetitionPartnershipDissolutionMessage = state.virtualBusinessPetitionPartnershipDissolutionMessage.filter(
          (x) => action.payload.id !== x.id
        );
      }
    });
    builder.addCase(DeleteVirtualBusinessPetitionPartnershipDissolutionMessageAsync.rejected, (state, action) => {
      state.is_deleting_virtualBusinessPetitionPartnershipDissolutionMessage = false;
    });
  }
});

function handleResult(action: any, state: virtualBusinessPetitionPartnershipDissolutionMessageSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_virtualBusinessPetitionPartnershipDissolutionMessage = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.virtualBusinessPetitionPartnershipDissolutionMessage = merge(state.virtualBusinessPetitionPartnershipDissolutionMessage, payload) as any;
    }
  }
}

export const {
  clearStatevirtualBusinessPetitionPartnershipDissolutionMessage,
  setSelectedvirtualBusinessPetitionPartnershipDissolutionMessage,
  set_virtualBusinessPetitionPartnershipDissolutionMessage,
  mergevirtualBusinessPetitionPartnershipDissolutionMessage,
  add_virtualBusinessPetitionPartnershipDissolutionMessage
} = virtualBusinessPetitionPartnershipDissolutionMessageSlice.actions;

export const get_virtualBusinessPetitionPartnershipDissolutionMessage =
  (id?: string) =>
  (state: {
    virtualBusinessPetitionPartnershipDissolutionMessage: {
      virtualBusinessPetitionPartnershipDissolutionMessage: VirtualBusinessPetitionPartnershipDissolutionMessage[];
    };
  }): VirtualBusinessPetitionPartnershipDissolutionMessage | null => {
    let temp = state?.virtualBusinessPetitionPartnershipDissolutionMessage?.virtualBusinessPetitionPartnershipDissolutionMessage?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.virtualBusinessPetitionPartnershipDissolutionMessage?.virtualBusinessPetitionPartnershipDissolutionMessage[0] || null;
  };
export const get_selected_virtualBusinessPetitionPartnershipDissolutionMessage = (state: {
  virtualBusinessPetitionPartnershipDissolutionMessage: virtualBusinessPetitionPartnershipDissolutionMessageSliceState;
}): VirtualBusinessPetitionPartnershipDissolutionMessage | null => {
  let id = state?.virtualBusinessPetitionPartnershipDissolutionMessage?.selected_virtualBusinessPetitionPartnershipDissolutionMessage;
  let temp = state?.virtualBusinessPetitionPartnershipDissolutionMessage?.virtualBusinessPetitionPartnershipDissolutionMessage?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_virtualBusinessPetitionPartnershipDissolutionMessage = (state: {
  virtualBusinessPetitionPartnershipDissolutionMessage: {
    virtualBusinessPetitionPartnershipDissolutionMessage: VirtualBusinessPetitionPartnershipDissolutionMessage[];
  };
}): VirtualBusinessPetitionPartnershipDissolutionMessage[] => {
  let temp = state?.virtualBusinessPetitionPartnershipDissolutionMessage?.virtualBusinessPetitionPartnershipDissolutionMessage;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_virtualBusinessPetitionPartnershipDissolutionMessage_busy = (state: {
  virtualBusinessPetitionPartnershipDissolutionMessage: virtualBusinessPetitionPartnershipDissolutionMessageSliceState;
}) => {
  return (
    state?.virtualBusinessPetitionPartnershipDissolutionMessage.is_deleting_virtualBusinessPetitionPartnershipDissolutionMessage ||
    state?.virtualBusinessPetitionPartnershipDissolutionMessage.is_loading_virtualBusinessPetitionPartnershipDissolutionMessage ||
    state?.virtualBusinessPetitionPartnershipDissolutionMessage.is_creating_virtualBusinessPetitionPartnershipDissolutionMessage
  );
};
export const is_deleting_virtualBusinessPetitionPartnershipDissolutionMessage_busy = (state: {
  virtualBusinessPetitionPartnershipDissolutionMessage: virtualBusinessPetitionPartnershipDissolutionMessageSliceState;
}) => {
  return state?.virtualBusinessPetitionPartnershipDissolutionMessage.is_deleting_virtualBusinessPetitionPartnershipDissolutionMessage;
};
export const is_loading_virtualBusinessPetitionPartnershipDissolutionMessage_busy = (state: {
  virtualBusinessPetitionPartnershipDissolutionMessage: virtualBusinessPetitionPartnershipDissolutionMessageSliceState;
}) => {
  return state?.virtualBusinessPetitionPartnershipDissolutionMessage.is_loading_virtualBusinessPetitionPartnershipDissolutionMessage;
};
export const is_creating_virtualBusinessPetitionPartnershipDissolutionMessage_busy = (state: {
  virtualBusinessPetitionPartnershipDissolutionMessage: virtualBusinessPetitionPartnershipDissolutionMessageSliceState;
}) => {
  return state?.virtualBusinessPetitionPartnershipDissolutionMessage.is_creating_virtualBusinessPetitionPartnershipDissolutionMessage;
};

export default virtualBusinessPetitionPartnershipDissolutionMessageSlice.reducer;
