import AuctionBid from "./auction-bid-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateAuctionBidAsync, DeleteAuctionBidAsync, LoadAuctionBidsAsync } from "./auctionBid-thunk";
const initialState: auctionBidSliceState = {
  auctionBid: [],
  selected_auctionBid: "",
  is_loading_auctionBid: false,
  is_creating_auctionBid: false,
  is_deleting_auctionBid: false
};
export interface auctionBidSliceState {
  auctionBid: AuctionBid[];
  selected_auctionBid?: string;
  is_loading_auctionBid: boolean;
  is_creating_auctionBid: boolean;
  is_deleting_auctionBid: boolean;
}
export const auctionBidSlice = createSlice({
  name: "auctionBid",
  initialState,
  reducers: {
    setSelectedauctionBid: (state, action) => {
      state.selected_auctionBid = action.payload;
    },
    clearStateauctionBid: (state, action) => {
      state.auctionBid = [];
      state.is_loading_auctionBid = false;
      state.is_creating_auctionBid = false;
      state.is_deleting_auctionBid = false;
    },
    mergeauctionBid: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_auctionBid = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.auctionBid = merge(state.auctionBid, payload) as any;
        }
      }
    },

    add_auctionBid: (state, action) => {
      if (action.payload) {
        state.auctionBid = [...state.auctionBid, action.payload];
      }
    },
    set_auctionBid: (state, action) => {
      let { key, value, id } = action.payload;
      state.auctionBid = state.auctionBid.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadAuctionBidsAsync.pending, (state) => {
      state.is_loading_auctionBid = true;
    });
    builder.addCase(LoadAuctionBidsAsync.fulfilled, (state, action) => {
      state.is_loading_auctionBid = false;
      if (action.payload) {
        state.auctionBid = action.payload;
      }
    });
    builder.addCase(LoadAuctionBidsAsync.rejected, (state, action) => {
      state.is_loading_auctionBid = false;
    });
    builder.addCase(CreateAuctionBidAsync.pending, (state) => {
      state.is_creating_auctionBid = true;
    });
    builder.addCase(CreateAuctionBidAsync.fulfilled, (state) => {
      state.is_creating_auctionBid = false;
    });
    builder.addCase(CreateAuctionBidAsync.rejected, (state) => {
      state.is_creating_auctionBid = false;
    });
    builder.addCase(DeleteAuctionBidAsync.pending, (state) => {
      state.is_deleting_auctionBid = true;
    });
    builder.addCase(DeleteAuctionBidAsync.fulfilled, (state, action) => {
      state.is_deleting_auctionBid = false;
      if (state.auctionBid) {
        state.auctionBid = state.auctionBid.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteAuctionBidAsync.rejected, (state, action) => {
      state.is_deleting_auctionBid = false;
    });

    builder.addCase(autoThunks.bid_on_item_for_auction.pending, (state) => {
      state.is_loading_auctionBid = true;
    });
    builder.addCase(autoThunks.bid_on_item_for_auction.fulfilled, (state, action) => {
      state.is_loading_auctionBid = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.bid_on_item_for_auction.rejected, (state, action) => {
      state.is_loading_auctionBid = false;
    });

    builder.addCase(autoThunks.delete_bid_on_item_for_auction.pending, (state) => {
      state.is_loading_auctionBid = true;
    });
    builder.addCase(autoThunks.delete_bid_on_item_for_auction.fulfilled, (state, action) => {
      state.is_loading_auctionBid = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.delete_bid_on_item_for_auction.rejected, (state, action) => {
      state.is_loading_auctionBid = false;
    });

    builder.addCase(autoThunks.get_auction_item_bids_for_auction_customer.pending, (state) => {
      state.is_loading_auctionBid = true;
    });
    builder.addCase(autoThunks.get_auction_item_bids_for_auction_customer.fulfilled, (state, action) => {
      state.is_loading_auctionBid = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_auction_item_bids_for_auction_customer.rejected, (state, action) => {
      state.is_loading_auctionBid = false;
    });

    builder.addCase(autoThunks.get_bids_for_auction_as_agent.pending, (state) => {
      state.is_loading_auctionBid = true;
    });
    builder.addCase(autoThunks.get_bids_for_auction_as_agent.fulfilled, (state, action) => {
      state.is_loading_auctionBid = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_bids_for_auction_as_agent.rejected, (state, action) => {
      state.is_loading_auctionBid = false;
    });

    builder.addCase(autoThunks.delete_auction_bid_as_agent.pending, (state) => {
      state.is_loading_auctionBid = true;
    });
    builder.addCase(autoThunks.delete_auction_bid_as_agent.fulfilled, (state, action) => {
      state.is_loading_auctionBid = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.delete_auction_bid_as_agent.rejected, (state, action) => {
      state.is_loading_auctionBid = false;
    });

    builder.addCase(autoThunks.get_bids_for_auction.pending, (state) => {
      state.is_loading_auctionBid = true;
    });
    builder.addCase(autoThunks.get_bids_for_auction.fulfilled, (state, action) => {
      state.is_loading_auctionBid = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_bids_for_auction.rejected, (state, action) => {
      state.is_loading_auctionBid = false;
    });

    builder.addCase(autoThunks.add_new_bid_for_auction.pending, (state) => {
      state.is_loading_auctionBid = true;
    });
    builder.addCase(autoThunks.add_new_bid_for_auction.fulfilled, (state, action) => {
      state.is_loading_auctionBid = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.add_new_bid_for_auction.rejected, (state, action) => {
      state.is_loading_auctionBid = false;
    });

    builder.addCase(autoThunks.delete_auction_bid.pending, (state) => {
      state.is_loading_auctionBid = true;
    });
    builder.addCase(autoThunks.delete_auction_bid.fulfilled, (state, action) => {
      state.is_loading_auctionBid = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.delete_auction_bid.rejected, (state, action) => {
      state.is_loading_auctionBid = false;
    });
  }
});

function handleResult(action: any, state: auctionBidSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_auctionBid = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.auctionBid = merge(state.auctionBid, payload) as any;
    }
  }
}

export const { clearStateauctionBid, setSelectedauctionBid, set_auctionBid, mergeauctionBid, add_auctionBid } = auctionBidSlice.actions;

export const get_auctionBid =
  (id?: string) =>
  (state: {
    auctionBid: {
      auctionBid: AuctionBid[];
    };
  }): AuctionBid | null => {
    let temp = state?.auctionBid?.auctionBid?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.auctionBid?.auctionBid[0] || null;
  };
export const get_selected_auctionBid = (state: { auctionBid: auctionBidSliceState }): AuctionBid | null => {
  let id = state?.auctionBid?.selected_auctionBid;
  let temp = state?.auctionBid?.auctionBid?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_auctionBid = (state: {
  auctionBid: {
    auctionBid: AuctionBid[];
  };
}): AuctionBid[] => {
  let temp = state?.auctionBid?.auctionBid;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_auctionBid_busy = (state: { auctionBid: auctionBidSliceState }) => {
  return state?.auctionBid.is_deleting_auctionBid || state?.auctionBid.is_loading_auctionBid || state?.auctionBid.is_creating_auctionBid;
};
export const is_deleting_auctionBid_busy = (state: { auctionBid: auctionBidSliceState }) => {
  return state?.auctionBid.is_deleting_auctionBid;
};
export const is_loading_auctionBid_busy = (state: { auctionBid: auctionBidSliceState }) => {
  return state?.auctionBid.is_loading_auctionBid;
};
export const is_creating_auctionBid_busy = (state: { auctionBid: auctionBidSliceState }) => {
  return state?.auctionBid.is_creating_auctionBid;
};

export default auctionBidSlice.reducer;
