import { createAsyncThunk } from "@reduxjs/toolkit";
import VirtualBusinessRequirement from "./virtual-business-requirement-model";
import { delete_virtualBusinessRequirement, load_virtualBusinessRequirements, store_virtualBusinessRequirement } from "./virtualBusinessRequirement-service";

export const LoadVirtualBusinessRequirementsAsync = createAsyncThunk<any, any>("composer/load/virtualBusinessRequirement", async (params: { uid: string }) => {
  return await load_virtualBusinessRequirements(params);
});

export const CreateVirtualBusinessRequirementAsync = createAsyncThunk<any, any>(
  "composer/create/virtualBusinessRequirement",
  async (params: VirtualBusinessRequirement) => {
    return await store_virtualBusinessRequirement(params);
  }
);

export const DeleteVirtualBusinessRequirementAsync = createAsyncThunk<any, any>("composer/delete/virtualBusinessRequirement", async (params) => {
  return await delete_virtualBusinessRequirement(params);
});
