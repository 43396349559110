import CurriculumRecordItem from "./curriculum-record-item-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateCurriculumRecordItemAsync, DeleteCurriculumRecordItemAsync, LoadCurriculumRecordItemsAsync } from "./curriculumRecordItem-thunk";
const initialState: curriculumRecordItemSliceState = {
  curriculumRecordItem: [],
  selected_curriculumRecordItem: "",
  is_loading_curriculumRecordItem: false,
  is_creating_curriculumRecordItem: false,
  is_deleting_curriculumRecordItem: false
};
export interface curriculumRecordItemSliceState {
  curriculumRecordItem: CurriculumRecordItem[];
  selected_curriculumRecordItem?: string;
  is_loading_curriculumRecordItem: boolean;
  is_creating_curriculumRecordItem: boolean;
  is_deleting_curriculumRecordItem: boolean;
}
export const curriculumRecordItemSlice = createSlice({
  name: "curriculumRecordItem",
  initialState,
  reducers: {
    setSelectedcurriculumRecordItem: (state, action) => {
      state.selected_curriculumRecordItem = action.payload;
    },
    clearStatecurriculumRecordItem: (state, action) => {
      state.curriculumRecordItem = [];
      state.is_loading_curriculumRecordItem = false;
      state.is_creating_curriculumRecordItem = false;
      state.is_deleting_curriculumRecordItem = false;
    },
    mergecurriculumRecordItem: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_curriculumRecordItem = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.curriculumRecordItem = merge(state.curriculumRecordItem, payload) as any;
        }
      }
    },

    add_curriculumRecordItem: (state, action) => {
      if (action.payload) {
        state.curriculumRecordItem = [...state.curriculumRecordItem, action.payload];
      }
    },
    set_curriculumRecordItem: (state, action) => {
      let { key, value, id } = action.payload;
      state.curriculumRecordItem = state.curriculumRecordItem.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadCurriculumRecordItemsAsync.pending, (state) => {
      state.is_loading_curriculumRecordItem = true;
    });
    builder.addCase(LoadCurriculumRecordItemsAsync.fulfilled, (state, action) => {
      state.is_loading_curriculumRecordItem = false;
      if (action.payload) {
        state.curriculumRecordItem = action.payload;
      }
    });
    builder.addCase(LoadCurriculumRecordItemsAsync.rejected, (state, action) => {
      state.is_loading_curriculumRecordItem = false;
    });
    builder.addCase(CreateCurriculumRecordItemAsync.pending, (state) => {
      state.is_creating_curriculumRecordItem = true;
    });
    builder.addCase(CreateCurriculumRecordItemAsync.fulfilled, (state) => {
      state.is_creating_curriculumRecordItem = false;
    });
    builder.addCase(CreateCurriculumRecordItemAsync.rejected, (state) => {
      state.is_creating_curriculumRecordItem = false;
    });
    builder.addCase(DeleteCurriculumRecordItemAsync.pending, (state) => {
      state.is_deleting_curriculumRecordItem = true;
    });
    builder.addCase(DeleteCurriculumRecordItemAsync.fulfilled, (state, action) => {
      state.is_deleting_curriculumRecordItem = false;
      if (state.curriculumRecordItem) {
        state.curriculumRecordItem = state.curriculumRecordItem.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteCurriculumRecordItemAsync.rejected, (state, action) => {
      state.is_deleting_curriculumRecordItem = false;
    });

    builder.addCase(autoThunks.answer_question_for_account.pending, (state) => {
      state.is_loading_curriculumRecordItem = true;
    });
    builder.addCase(autoThunks.answer_question_for_account.fulfilled, (state, action) => {
      state.is_loading_curriculumRecordItem = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.answer_question_for_account.rejected, (state, action) => {
      state.is_loading_curriculumRecordItem = false;
    });
  }
});

function handleResult(action: any, state: curriculumRecordItemSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_curriculumRecordItem = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.curriculumRecordItem = merge(state.curriculumRecordItem, payload) as any;
    }
  }
}

export const {
  clearStatecurriculumRecordItem,
  setSelectedcurriculumRecordItem,
  set_curriculumRecordItem,
  mergecurriculumRecordItem,
  add_curriculumRecordItem
} = curriculumRecordItemSlice.actions;

export const get_curriculumRecordItem =
  (id?: string) =>
  (state: {
    curriculumRecordItem: {
      curriculumRecordItem: CurriculumRecordItem[];
    };
  }): CurriculumRecordItem | null => {
    let temp = state?.curriculumRecordItem?.curriculumRecordItem?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.curriculumRecordItem?.curriculumRecordItem[0] || null;
  };
export const get_selected_curriculumRecordItem = (state: { curriculumRecordItem: curriculumRecordItemSliceState }): CurriculumRecordItem | null => {
  let id = state?.curriculumRecordItem?.selected_curriculumRecordItem;
  let temp = state?.curriculumRecordItem?.curriculumRecordItem?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_curriculumRecordItem = (state: {
  curriculumRecordItem: {
    curriculumRecordItem: CurriculumRecordItem[];
  };
}): CurriculumRecordItem[] => {
  let temp = state?.curriculumRecordItem?.curriculumRecordItem;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_curriculumRecordItem_busy = (state: { curriculumRecordItem: curriculumRecordItemSliceState }) => {
  return (
    state?.curriculumRecordItem.is_deleting_curriculumRecordItem ||
    state?.curriculumRecordItem.is_loading_curriculumRecordItem ||
    state?.curriculumRecordItem.is_creating_curriculumRecordItem
  );
};
export const is_deleting_curriculumRecordItem_busy = (state: { curriculumRecordItem: curriculumRecordItemSliceState }) => {
  return state?.curriculumRecordItem.is_deleting_curriculumRecordItem;
};
export const is_loading_curriculumRecordItem_busy = (state: { curriculumRecordItem: curriculumRecordItemSliceState }) => {
  return state?.curriculumRecordItem.is_loading_curriculumRecordItem;
};
export const is_creating_curriculumRecordItem_busy = (state: { curriculumRecordItem: curriculumRecordItemSliceState }) => {
  return state?.curriculumRecordItem.is_creating_curriculumRecordItem;
};

export default curriculumRecordItemSlice.reducer;
