import * as firebase from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, Auth, User, signInWithCredential, connectAuthEmulator } from "firebase/auth";
import { connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import * as firedatabase from "firebase/database";
import { signInWithEmailAndPassword, signInAnonymously, createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator, collection, Firestore } from "firebase/firestore";
// These imports load individual services into the firebase namespace.
import "firebase/auth";
import "firebase/storage";
import { getStorage, ref, getDownloadURL, FirebaseStorage } from "firebase/storage";
import "firebase/database";
import "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

export function debounce(func: any, wait: any, immediate?: any) {
  var timeout: any;
  return (a?: any, b?: any, c?: any) => {
    let args: any[] = [a, b, c];
    var later: any = () => {
      timeout = null;
      if (!immediate) func(...args);
    };
    var callNow: any = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func(...args);
  };
}
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDpFpvPMkIkPIufcPt9J1iC-m9VoJ0ItDQ",
  authDomain: "rbank-4b477.firebaseapp.com",
  databaseURL: "https://rbank-4b477-default-rtdb.firebaseio.com",
  projectId: "rbank-4b477",
  storageBucket: "rbank-4b477.appspot.com",
  messagingSenderId: "130486316177",
  appId: "1:130486316177:web:0bf87dac3ee92e6943a330",
  measurementId: "G-XSV7DZ4FWD"
};

const app_data_store = {};
export function setAppDataStore(key: string, value: any) {
  app_data_store[key] = value;
}
export function getAppDataStore(key: string) {
  return app_data_store[key];
}
// Initialize Firebase

export function setupFirebase() {
  if (!app) {
    app = firebase.initializeApp(firebaseConfig);
    auth = getAuth();
    db = getFirestore(app);
    database = firedatabase.getDatabase(app);
    storage = getStorage(app);
    let projectConfig: any = { localEmulator: false, masterEmail: "mephisto8335@gmail.com", environment: "Staging" };
    if (projectConfig.localEmulator) {
      connectAuthEmulator(auth, "http://localhost:9099");
      connectFirestoreEmulator(db, "localhost", 8080);
      firedatabase.connectDatabaseEmulator(database, "localhost", 9000);
      connectStorageEmulator(storage, "localhost", 9199);
      const functions = getFunctions(app);
      connectFunctionsEmulator(functions, "localhost", 5321);
    }
    authStateListener();
  }
}
let app: firebase.FirebaseApp | undefined;
let auth: Auth | undefined;
let db: Firestore | undefined;
let database: firedatabase.Database | undefined;
let storage: FirebaseStorage | undefined;

export function getApp() {
  return app;
}

export function getReqs(): { db: Firestore | undefined; app: firebase.FirebaseApp | undefined; auth: Auth | undefined } {
  return { db, app, auth }; //
}
export const PROVIDERS = {
  GOOGLE: "GOOGLE",
  FACEBOOK: "FACEBOOK",
  EMAIL_PASSWORD: "EMAIL_PASSWORD",
  ANONYMOUS: "ANONYMOUS"
};
export function listenTo(path: any, callback: any): any {
  try {
    if (database) {
      return firedatabase.onValue(firedatabase.ref(database, path), (snapshot) => {
        callback(snapshot.val());
      });
    } else {
      throw "database not defined";
    }
  } catch (e) {
    console.error(e);
  }
}
export function postTo(path: any, data: any) {
  if (!database) {
    throw "no database";
  }
  try {
    setUndefinedPropertyToNull(data);
    if (database) {
      return firedatabase.update(firedatabase.ref(database), { [path]: data });
    }
  } catch (e) {
    console.error(e);
  }
  return null;
}
export function ResolveUrl(uri: string) {
  let projectConfig = { localEmulator: false, masterEmail: "mephisto8335@gmail.com", environment: "Staging" };
  if (projectConfig.localEmulator) {
    return `http://localhost:5321/${firebaseConfig.projectId}/us-central1/app/api/${uri}`;
  } else if (location.host.indexOf("localhost") === -1) {
    return `https://rbank-4b477.firebaseapp.com/api/${uri}`;
  }
  return uri;
}
export function ResolveImageUrl(uri: string) {
  return { uri: `https://rbank-4b477.firebaseapp.com/${uri}` };
}

export function ResolvePath(uri: string) {
  return uri;
}
function setUndefinedPropertyToNull(data: any) {
  Object.keys(data).forEach((i) => {
    if (data[i] === undefined) {
      data[i] = null;
    }
  });
}
export async function signOut() {
  if (!auth) {
    throw "no auth";
  }
  return auth
    .signOut()
    .then((e: any) => {
      console.log(e);
    })
    .catch((error: any) => {
      return error;
    });
}
let _lastState: any;
let _authStateChangedHandler: any;
export function setAuthStateChangedHandler(func: any) {
  _authStateChangedHandler = func;
  let temp = _lastState;
  _lastState = null;
  return temp;
}
let _authContextHandler: any = null;
export function setAuthStateContexHandler(func: any) {
  _authContextHandler = func;
}

export function setupCreateUpdateClocks(temp: any) {
  temp.created = convertServerTime(temp.created);
  if (temp.updated) temp.updated = convertServerTime(temp.updated);
}
function convertServerTime(obj: any) {
  return {
    seconds: obj.seconds,
    nanoseconds: obj.nanoseconds
  };
}
let state: { refreshToken: any; establish: any } = {
  refreshToken: null,
  establish: null
};
const update_claims_context: { func: any } = {
  func: null
};

export async function UpdateClaims() {
  if (update_claims_context?.func) {
    await update_claims_context.func(true);
  }
}
function authStateListener() {
  if (!auth) {
    throw "no auth";
  }
  // [START auth_state_listener]
  auth.onAuthStateChanged(async (user) => {
    console.log(user);
    _lastState = user;
    if (user) {
      async function establishTokens(forceRefresh: boolean = false) {
        if (user) {
          let token = await user.getIdToken(forceRefresh);
          let claims = await user
            .getIdTokenResult()
            .then(async (idTokenResult: any) => {
              // Confirm the user is an Admin.
              if (!idTokenResult.claims?.user && auth?.currentUser) {
                token = await auth.currentUser.getIdToken(true);
                return await user.getIdTokenResult().then(async (idTokenResult: any) => {
                  return idTokenResult.claims;
                });
              }
              return idTokenResult.claims;
            })
            .catch((error: any) => {
              console.log(error);
            });
          state.refreshToken = user.refreshToken;
          if (!claims?.user) {
            setTimeout(() => {
              establishTokens(true);
            }, 1000);
          }
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          var uid = user.uid;
          if (_authStateChangedHandler) {
            _authStateChangedHandler({
              signedIn: true,
              user,
              claims,
              token
            });
          }
        }
      }
      update_claims_context.func = establishTokens;
      await establishTokens();
      if (state.establish) {
        clearInterval(state.establish);
      }
      state.establish = setInterval(establishTokens, 10 * 60 * 1000);
      // ...
    } else {
      // User is signed out
      // ...
      if (_authStateChangedHandler) {
        _authStateChangedHandler({
          signedIn: false,
          user
        });
      }
    }
    if (_authContextHandler) {
      _authContextHandler(user);
    }
  });
  // [END auth_state_listener]
}
let url_cache: any = {};
let url_fetching: any = {};
export function GetCachedUrls(uri: any) {
  if (url_cache[uri]) {
    return url_cache[uri];
  }
  return false;
}
export function GetAllCachedUrls() {
  return url_cache;
}
export function MergeCachedUrls(urls: any) {
  url_cache = {
    ...url_cache,
    ...(urls || {})
  };
}
let url_resolved_handler: any = {};
export function SetupUrlResolutionHandler(handler: any, id: any) {
  url_resolved_handler[id] = debounce(handler, 1000);
}
export function RemoveUrlResolutionHandler(id: any) {
  delete url_resolved_handler[id];
}
export async function ResolveStorageUrl(uri: any, key?: any) {
  if (!uri) {
    return false;
  }
  if (!storage) {
    throw "no storage";
  }
  let url = `gs://room-banking-dev.appspot.com/${uri}`;
  if (url_cache[key || uri]) {
    return url_cache[uri];
  }
  if (url_fetching[key || uri]) {
    return false;
  }
  url_fetching[key || uri] = true;
  return await getDownloadURL(ref(storage, uri))
    .then((url) => {
      url_fetching[key || uri] = false;
      url_cache[key || uri] = url;
      if (url_resolved_handler) {
        Object.values(url_resolved_handler).map((v: any) => v());
      }
      return url;
    })
    .catch((error) => {
      // Handle any errors
      url_fetching[key || uri] = false;
      return false;
    });
}
export async function passwordResetEmail(email: string) {
  if (!auth) {
    setupFirebase();
  }
  await sendPasswordResetEmail(auth, email);
}

export async function connectToEmailAndPassword(email: string, password: string, newUser: boolean, onError?: any) {
  if (!auth) {
    setupFirebase();
  }
  if (newUser && auth) {
    return await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
        return user;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(error);
        if (onError) {
          onError(error);
        }
        // ..
      });
  } else if (auth) {
    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
        return user;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(error);
        if (onError) {
          onError(error);
        }
      });
  }
}
export async function signinWith(providerId: any) {
  let provider;
  switch (providerId) {
    case PROVIDERS.GOOGLE:
      provider = new GoogleAuthProvider();
      break;
    case PROVIDERS.FACEBOOK:
      provider = new FacebookAuthProvider();
      break;
    case PROVIDERS.ANONYMOUS:
      if (auth) {
        return signInAnonymously(auth)
          .then((result) => {
            // Signed in..
            return result;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      break;
    default:
      throw "not supported provider : " + providerId;
  }
  if (auth && provider) {
    return signInWithPopup(auth, provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        console.log(result);
        return result;
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
        console.log(error);
      });
  } else {
    throw "missing auth or provider";
  }
}
