import Account from "./account-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateAccountAsync, DeleteAccountAsync, LoadAccountsAsync } from "./account-thunk";
const initialState: accountSliceState = {
  account: [],
  selected_account: "",
  is_loading_account: false,
  is_creating_account: false,
  is_deleting_account: false
};
export interface accountSliceState {
  account: Account[];
  selected_account?: string;
  is_loading_account: boolean;
  is_creating_account: boolean;
  is_deleting_account: boolean;
}
export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setSelectedaccount: (state, action) => {
      state.selected_account = action.payload;
    },
    clearStateaccount: (state, action) => {
      state.account = [];
      state.is_loading_account = false;
      state.is_creating_account = false;
      state.is_deleting_account = false;
    },
    mergeaccount: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_account = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.account = merge(state.account, payload) as any;
        }
      }
    },

    add_account: (state, action) => {
      if (action.payload) {
        state.account = [...state.account, action.payload];
      }
    },
    set_account: (state, action) => {
      let { key, value, id } = action.payload;
      state.account = state.account.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadAccountsAsync.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(LoadAccountsAsync.fulfilled, (state, action) => {
      state.is_loading_account = false;
      if (action.payload) {
        state.account = action.payload;
      }
    });
    builder.addCase(LoadAccountsAsync.rejected, (state, action) => {
      state.is_loading_account = false;
    });
    builder.addCase(CreateAccountAsync.pending, (state) => {
      state.is_creating_account = true;
    });
    builder.addCase(CreateAccountAsync.fulfilled, (state) => {
      state.is_creating_account = false;
    });
    builder.addCase(CreateAccountAsync.rejected, (state) => {
      state.is_creating_account = false;
    });
    builder.addCase(DeleteAccountAsync.pending, (state) => {
      state.is_deleting_account = true;
    });
    builder.addCase(DeleteAccountAsync.fulfilled, (state, action) => {
      state.is_deleting_account = false;
      if (state.account) {
        state.account = state.account.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteAccountAsync.rejected, (state, action) => {
      state.is_deleting_account = false;
    });

    builder.addCase(autoThunks.get_customer_accounts.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(autoThunks.get_customer_accounts.fulfilled, (state, action) => {
      state.is_loading_account = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_customer_accounts.rejected, (state, action) => {
      state.is_loading_account = false;
    });

    builder.addCase(autoThunks.get_bank_account_for_customer.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(autoThunks.get_bank_account_for_customer.fulfilled, (state, action) => {
      state.is_loading_account = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_bank_account_for_customer.rejected, (state, action) => {
      state.is_loading_account = false;
    });

    builder.addCase(autoThunks.get_bank_accounts_for_customer.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(autoThunks.get_bank_accounts_for_customer.fulfilled, (state, action) => {
      state.is_loading_account = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_bank_accounts_for_customer.rejected, (state, action) => {
      state.is_loading_account = false;
    });

    builder.addCase(autoThunks.get_accounts_connected_to_email.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(autoThunks.get_accounts_connected_to_email.fulfilled, (state, action) => {
      state.is_loading_account = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_accounts_connected_to_email.rejected, (state, action) => {
      state.is_loading_account = false;
    });

    builder.addCase(autoThunks.get_my_connected_accounts.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(autoThunks.get_my_connected_accounts.fulfilled, (state, action) => {
      state.is_loading_account = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_my_connected_accounts.rejected, (state, action) => {
      state.is_loading_account = false;
    });

    builder.addCase(autoThunks.get_accounts_connected_to_bank_as_agent.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(autoThunks.get_accounts_connected_to_bank_as_agent.fulfilled, (state, action) => {
      state.is_loading_account = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_accounts_connected_to_bank_as_agent.rejected, (state, action) => {
      state.is_loading_account = false;
    });

    builder.addCase(autoThunks.get_accounts_for_auction_as_agent.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(autoThunks.get_accounts_for_auction_as_agent.fulfilled, (state, action) => {
      state.is_loading_account = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_accounts_for_auction_as_agent.rejected, (state, action) => {
      state.is_loading_account = false;
    });

    builder.addCase(autoThunks.get_accounts_connected_to_bank.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(autoThunks.get_accounts_connected_to_bank.fulfilled, (state, action) => {
      state.is_loading_account = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_accounts_connected_to_bank.rejected, (state, action) => {
      state.is_loading_account = false;
    });

    builder.addCase(autoThunks.get_accounts_for_auction.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(autoThunks.get_accounts_for_auction.fulfilled, (state, action) => {
      state.is_loading_account = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_accounts_for_auction.rejected, (state, action) => {
      state.is_loading_account = false;
    });

    builder.addCase(autoThunks.get_purchaser_account.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(autoThunks.get_purchaser_account.fulfilled, (state, action) => {
      state.is_loading_account = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_purchaser_account.rejected, (state, action) => {
      state.is_loading_account = false;
    });

    builder.addCase(autoThunks.get_virtual_business_accounts.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(autoThunks.get_virtual_business_accounts.fulfilled, (state, action) => {
      state.is_loading_account = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_virtual_business_accounts.rejected, (state, action) => {
      state.is_loading_account = false;
    });

    builder.addCase(autoThunks.get_account_by_id.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(autoThunks.get_account_by_id.fulfilled, (state, action) => {
      state.is_loading_account = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_account_by_id.rejected, (state, action) => {
      state.is_loading_account = false;
    });

    builder.addCase(autoThunks.update_account_profile_image.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(autoThunks.update_account_profile_image.fulfilled, (state, action) => {
      state.is_loading_account = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.update_account_profile_image.rejected, (state, action) => {
      state.is_loading_account = false;
    });

    builder.addCase(autoThunks.set_account_email.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(autoThunks.set_account_email.fulfilled, (state, action) => {
      state.is_loading_account = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.set_account_email.rejected, (state, action) => {
      state.is_loading_account = false;
    });

    builder.addCase(autoThunks.set_bank_account_email.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(autoThunks.set_bank_account_email.fulfilled, (state, action) => {
      state.is_loading_account = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.set_bank_account_email.rejected, (state, action) => {
      state.is_loading_account = false;
    });

    builder.addCase(autoThunks.get_bank_accounts_for_teacher.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(autoThunks.get_bank_accounts_for_teacher.fulfilled, (state, action) => {
      state.is_loading_account = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_bank_accounts_for_teacher.rejected, (state, action) => {
      state.is_loading_account = false;
    });

    builder.addCase(autoThunks.test_live_channel.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(autoThunks.test_live_channel.fulfilled, (state, action) => {
      state.is_loading_account = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.test_live_channel.rejected, (state, action) => {
      state.is_loading_account = false;
    });

    builder.addCase(autoThunks.get_bank_accounts.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(autoThunks.get_bank_accounts.fulfilled, (state, action) => {
      state.is_loading_account = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_bank_accounts.rejected, (state, action) => {
      state.is_loading_account = false;
    });

    builder.addCase(autoThunks.clear_account.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(autoThunks.clear_account.fulfilled, (state, action) => {
      state.is_loading_account = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.clear_account.rejected, (state, action) => {
      state.is_loading_account = false;
    });

    builder.addCase(autoThunks.set_bank_account_balance.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(autoThunks.set_bank_account_balance.fulfilled, (state, action) => {
      state.is_loading_account = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.set_bank_account_balance.rejected, (state, action) => {
      state.is_loading_account = false;
    });

    builder.addCase(autoThunks.update_bank_for_user.pending, (state) => {
      state.is_loading_account = true;
    });
    builder.addCase(autoThunks.update_bank_for_user.fulfilled, (state, action) => {
      state.is_loading_account = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.update_bank_for_user.rejected, (state, action) => {
      state.is_loading_account = false;
    });
  }
});

function handleResult(action: any, state: accountSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_account = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.account = merge(state.account, payload) as any;
    }
  }
}

export const { clearStateaccount, setSelectedaccount, set_account, mergeaccount, add_account } = accountSlice.actions;

export const get_account =
  (id?: string) =>
  (state: {
    account: {
      account: Account[];
    };
  }): Account | null => {
    let temp = state?.account?.account?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.account?.account[0] || null;
  };
export const get_selected_account = (state: { account: accountSliceState }): Account | null => {
  let id = state?.account?.selected_account;
  let temp = state?.account?.account?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_account = (state: {
  account: {
    account: Account[];
  };
}): Account[] => {
  let temp = state?.account?.account;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_account_busy = (state: { account: accountSliceState }) => {
  return state?.account.is_deleting_account || state?.account.is_loading_account || state?.account.is_creating_account;
};
export const is_deleting_account_busy = (state: { account: accountSliceState }) => {
  return state?.account.is_deleting_account;
};
export const is_loading_account_busy = (state: { account: accountSliceState }) => {
  return state?.account.is_loading_account;
};
export const is_creating_account_busy = (state: { account: accountSliceState }) => {
  return state?.account.is_creating_account;
};

export default accountSlice.reducer;
