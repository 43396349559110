import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import ViewBlockSummary from "./view-block-summary-model";
import { ViewBlockSummaryProperties, ViewBlockSummaryMetaProperties } from "./viewBlockSummary-property-definitions";

export async function load_viewBlockSummarys(args: { uid: string }): Promise<ViewBlockSummary[]> {
  throw "Not implemented";
}

export async function delete_viewBlockSummary(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_viewBlockSummary(args: ViewBlockSummary): Promise<ViewBlockSummary> {
  throw "Not implemented";
}
