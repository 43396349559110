import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import EmployeeBenefits from "./employee-benefits-model";
import { EmployeeBenefitsProperties, EmployeeBenefitsMetaProperties } from "./employeeBenefits-property-definitions";

export async function load_employeeBenefitss(args: { uid: string }): Promise<EmployeeBenefits[]> {
  throw "Not implemented";
}

export async function delete_employeeBenefits(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_employeeBenefits(args: EmployeeBenefits): Promise<EmployeeBenefits> {
  throw "Not implemented";
}
