import Bank from "./bank-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateBankAsync, DeleteBankAsync, LoadBanksAsync } from "./bank-thunk";
const initialState: bankSliceState = {
  bank: [],
  selected_bank: "",
  is_loading_bank: false,
  is_creating_bank: false,
  is_deleting_bank: false
};
export interface bankSliceState {
  bank: Bank[];
  selected_bank?: string;
  is_loading_bank: boolean;
  is_creating_bank: boolean;
  is_deleting_bank: boolean;
}
export const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    setSelectedbank: (state, action) => {
      state.selected_bank = action.payload;
    },
    clearStatebank: (state, action) => {
      state.bank = [];
      state.is_loading_bank = false;
      state.is_creating_bank = false;
      state.is_deleting_bank = false;
    },
    mergebank: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_bank = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.bank = merge(state.bank, payload) as any;
        }
      }
    },

    add_bank: (state, action) => {
      if (action.payload) {
        state.bank = [...state.bank, action.payload];
      }
    },
    set_bank: (state, action) => {
      let { key, value, id } = action.payload;
      state.bank = state.bank.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadBanksAsync.pending, (state) => {
      state.is_loading_bank = true;
    });
    builder.addCase(LoadBanksAsync.fulfilled, (state, action) => {
      state.is_loading_bank = false;
      if (action.payload) {
        state.bank = action.payload;
      }
    });
    builder.addCase(LoadBanksAsync.rejected, (state, action) => {
      state.is_loading_bank = false;
    });
    builder.addCase(CreateBankAsync.pending, (state) => {
      state.is_creating_bank = true;
    });
    builder.addCase(CreateBankAsync.fulfilled, (state) => {
      state.is_creating_bank = false;
    });
    builder.addCase(CreateBankAsync.rejected, (state) => {
      state.is_creating_bank = false;
    });
    builder.addCase(DeleteBankAsync.pending, (state) => {
      state.is_deleting_bank = true;
    });
    builder.addCase(DeleteBankAsync.fulfilled, (state, action) => {
      state.is_deleting_bank = false;
      if (state.bank) {
        state.bank = state.bank.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteBankAsync.rejected, (state, action) => {
      state.is_deleting_bank = false;
    });

    builder.addCase(autoThunks.get_banks_for_customer_as_agent.pending, (state) => {
      state.is_loading_bank = true;
    });
    builder.addCase(autoThunks.get_banks_for_customer_as_agent.fulfilled, (state, action) => {
      state.is_loading_bank = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_banks_for_customer_as_agent.rejected, (state, action) => {
      state.is_loading_bank = false;
    });

    builder.addCase(autoThunks.get_bank_for_customer.pending, (state) => {
      state.is_loading_bank = true;
    });
    builder.addCase(autoThunks.get_bank_for_customer.fulfilled, (state, action) => {
      state.is_loading_bank = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_bank_for_customer.rejected, (state, action) => {
      state.is_loading_bank = false;
    });

    builder.addCase(autoThunks.get_banks_for_customer.pending, (state) => {
      state.is_loading_bank = true;
    });
    builder.addCase(autoThunks.get_banks_for_customer.fulfilled, (state, action) => {
      state.is_loading_bank = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_banks_for_customer.rejected, (state, action) => {
      state.is_loading_bank = false;
    });

    builder.addCase(autoThunks.get_my_connected_banks.pending, (state) => {
      state.is_loading_bank = true;
    });
    builder.addCase(autoThunks.get_my_connected_banks.fulfilled, (state, action) => {
      state.is_loading_bank = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_my_connected_banks.rejected, (state, action) => {
      state.is_loading_bank = false;
    });

    builder.addCase(autoThunks.get_banks_for_bank_owner.pending, (state) => {
      state.is_loading_bank = true;
    });
    builder.addCase(autoThunks.get_banks_for_bank_owner.fulfilled, (state, action) => {
      state.is_loading_bank = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_banks_for_bank_owner.rejected, (state, action) => {
      state.is_loading_bank = false;
    });

    builder.addCase(autoThunks.get_banks_for_auctions.pending, (state) => {
      state.is_loading_bank = true;
    });
    builder.addCase(autoThunks.get_banks_for_auctions.fulfilled, (state, action) => {
      state.is_loading_bank = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_banks_for_auctions.rejected, (state, action) => {
      state.is_loading_bank = false;
    });

    builder.addCase(autoThunks.get_banks_for_banks.pending, (state) => {
      state.is_loading_bank = true;
    });
    builder.addCase(autoThunks.get_banks_for_banks.fulfilled, (state, action) => {
      state.is_loading_bank = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_banks_for_banks.rejected, (state, action) => {
      state.is_loading_bank = false;
    });

    builder.addCase(autoThunks.save_bank.pending, (state) => {
      state.is_loading_bank = true;
    });
    builder.addCase(autoThunks.save_bank.fulfilled, (state, action) => {
      state.is_loading_bank = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.save_bank.rejected, (state, action) => {
      state.is_loading_bank = false;
    });

    builder.addCase(autoThunks.get_banks_for_add_banks.pending, (state) => {
      state.is_loading_bank = true;
    });
    builder.addCase(autoThunks.get_banks_for_add_banks.fulfilled, (state, action) => {
      state.is_loading_bank = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_banks_for_add_banks.rejected, (state, action) => {
      state.is_loading_bank = false;
    });

    builder.addCase(autoThunks.get_banks.pending, (state) => {
      state.is_loading_bank = true;
    });
    builder.addCase(autoThunks.get_banks.fulfilled, (state, action) => {
      state.is_loading_bank = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_banks.rejected, (state, action) => {
      state.is_loading_bank = false;
    });

    builder.addCase(autoThunks.get_banks_for_room_agents.pending, (state) => {
      state.is_loading_bank = true;
    });
    builder.addCase(autoThunks.get_banks_for_room_agents.fulfilled, (state, action) => {
      state.is_loading_bank = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_banks_for_room_agents.rejected, (state, action) => {
      state.is_loading_bank = false;
    });

    builder.addCase(autoThunks.update_bank.pending, (state) => {
      state.is_loading_bank = true;
    });
    builder.addCase(autoThunks.update_bank.fulfilled, (state, action) => {
      state.is_loading_bank = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.update_bank.rejected, (state, action) => {
      state.is_loading_bank = false;
    });

    builder.addCase(autoThunks.get_bank.pending, (state) => {
      state.is_loading_bank = true;
    });
    builder.addCase(autoThunks.get_bank.fulfilled, (state, action) => {
      state.is_loading_bank = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_bank.rejected, (state, action) => {
      state.is_loading_bank = false;
    });

    builder.addCase(autoThunks.get_banks_for_enroll.pending, (state) => {
      state.is_loading_bank = true;
    });
    builder.addCase(autoThunks.get_banks_for_enroll.fulfilled, (state, action) => {
      state.is_loading_bank = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_banks_for_enroll.rejected, (state, action) => {
      state.is_loading_bank = false;
    });

    builder.addCase(autoThunks.connct_bank_to_classroom.pending, (state) => {
      state.is_loading_bank = true;
    });
    builder.addCase(autoThunks.connct_bank_to_classroom.fulfilled, (state, action) => {
      state.is_loading_bank = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.connct_bank_to_classroom.rejected, (state, action) => {
      state.is_loading_bank = false;
    });

    builder.addCase(autoThunks.build_bank_for_user.pending, (state) => {
      state.is_loading_bank = true;
    });
    builder.addCase(autoThunks.build_bank_for_user.fulfilled, (state, action) => {
      state.is_loading_bank = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.build_bank_for_user.rejected, (state, action) => {
      state.is_loading_bank = false;
    });
  }
});

function handleResult(action: any, state: bankSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_bank = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.bank = merge(state.bank, payload) as any;
    }
  }
}

export const { clearStatebank, setSelectedbank, set_bank, mergebank, add_bank } = bankSlice.actions;

export const get_bank =
  (id?: string) =>
  (state: {
    bank: {
      bank: Bank[];
    };
  }): Bank | null => {
    let temp = state?.bank?.bank?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.bank?.bank[0] || null;
  };
export const get_selected_bank = (state: { bank: bankSliceState }): Bank | null => {
  let id = state?.bank?.selected_bank;
  let temp = state?.bank?.bank?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_bank = (state: {
  bank: {
    bank: Bank[];
  };
}): Bank[] => {
  let temp = state?.bank?.bank;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_bank_busy = (state: { bank: bankSliceState }) => {
  return state?.bank.is_deleting_bank || state?.bank.is_loading_bank || state?.bank.is_creating_bank;
};
export const is_deleting_bank_busy = (state: { bank: bankSliceState }) => {
  return state?.bank.is_deleting_bank;
};
export const is_loading_bank_busy = (state: { bank: bankSliceState }) => {
  return state?.bank.is_loading_bank;
};
export const is_creating_bank_busy = (state: { bank: bankSliceState }) => {
  return state?.bank.is_creating_bank;
};

export default bankSlice.reducer;
