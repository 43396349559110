import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import AccountLog from "./account-log-model";
import { AccountLogProperties, AccountLogMetaProperties } from "./accountLog-property-definitions";

export async function load_accountLogs(args: { uid: string }): Promise<AccountLog[]> {
  throw "Not implemented";
}

export async function delete_accountLog(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_accountLog(args: AccountLog): Promise<AccountLog> {
  throw "Not implemented";
}
