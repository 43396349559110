import { createAsyncThunk } from "@reduxjs/toolkit";
import MarketingCampaign from "./marketing-campaign-model";
import { delete_marketingCampaign, load_marketingCampaigns, store_marketingCampaign } from "./marketingCampaign-service";

export const LoadMarketingCampaignsAsync = createAsyncThunk<any, any>("composer/load/marketingCampaign", async (params: { uid: string }) => {
  return await load_marketingCampaigns(params);
});

export const CreateMarketingCampaignAsync = createAsyncThunk<any, any>("composer/create/marketingCampaign", async (params: MarketingCampaign) => {
  return await store_marketingCampaign(params);
});

export const DeleteMarketingCampaignAsync = createAsyncThunk<any, any>("composer/delete/marketingCampaign", async (params) => {
  return await delete_marketingCampaign(params);
});
