import { createAsyncThunk } from "@reduxjs/toolkit";
import VirtualInvestment from "./virtual-investment-model";
import { delete_virtualInvestment, load_virtualInvestments, store_virtualInvestment } from "./virtualInvestment-service";

export const LoadVirtualInvestmentsAsync = createAsyncThunk<any, any>("composer/load/virtualInvestment", async (params: { uid: string }) => {
  return await load_virtualInvestments(params);
});

export const CreateVirtualInvestmentAsync = createAsyncThunk<any, any>("composer/create/virtualInvestment", async (params: VirtualInvestment) => {
  return await store_virtualInvestment(params);
});

export const DeleteVirtualInvestmentAsync = createAsyncThunk<any, any>("composer/delete/virtualInvestment", async (params) => {
  return await delete_virtualInvestment(params);
});
