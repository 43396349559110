import TokenHouse from "./token-house-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateTokenHouseAsync, DeleteTokenHouseAsync, LoadTokenHousesAsync } from "./tokenHouse-thunk";
const initialState: tokenHouseSliceState = {
  tokenHouse: [],
  selected_tokenHouse: "",
  is_loading_tokenHouse: false,
  is_creating_tokenHouse: false,
  is_deleting_tokenHouse: false
};
export interface tokenHouseSliceState {
  tokenHouse: TokenHouse[];
  selected_tokenHouse?: string;
  is_loading_tokenHouse: boolean;
  is_creating_tokenHouse: boolean;
  is_deleting_tokenHouse: boolean;
}
export const tokenHouseSlice = createSlice({
  name: "tokenHouse",
  initialState,
  reducers: {
    setSelectedtokenHouse: (state, action) => {
      state.selected_tokenHouse = action.payload;
    },
    clearStatetokenHouse: (state, action) => {
      state.tokenHouse = [];
      state.is_loading_tokenHouse = false;
      state.is_creating_tokenHouse = false;
      state.is_deleting_tokenHouse = false;
    },
    mergetokenHouse: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_tokenHouse = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.tokenHouse = merge(state.tokenHouse, payload) as any;
        }
      }
    },

    add_tokenHouse: (state, action) => {
      if (action.payload) {
        state.tokenHouse = [...state.tokenHouse, action.payload];
      }
    },
    set_tokenHouse: (state, action) => {
      let { key, value, id } = action.payload;
      state.tokenHouse = state.tokenHouse.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadTokenHousesAsync.pending, (state) => {
      state.is_loading_tokenHouse = true;
    });
    builder.addCase(LoadTokenHousesAsync.fulfilled, (state, action) => {
      state.is_loading_tokenHouse = false;
      if (action.payload) {
        state.tokenHouse = action.payload;
      }
    });
    builder.addCase(LoadTokenHousesAsync.rejected, (state, action) => {
      state.is_loading_tokenHouse = false;
    });
    builder.addCase(CreateTokenHouseAsync.pending, (state) => {
      state.is_creating_tokenHouse = true;
    });
    builder.addCase(CreateTokenHouseAsync.fulfilled, (state) => {
      state.is_creating_tokenHouse = false;
    });
    builder.addCase(CreateTokenHouseAsync.rejected, (state) => {
      state.is_creating_tokenHouse = false;
    });
    builder.addCase(DeleteTokenHouseAsync.pending, (state) => {
      state.is_deleting_tokenHouse = true;
    });
    builder.addCase(DeleteTokenHouseAsync.fulfilled, (state, action) => {
      state.is_deleting_tokenHouse = false;
      if (state.tokenHouse) {
        state.tokenHouse = state.tokenHouse.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteTokenHouseAsync.rejected, (state, action) => {
      state.is_deleting_tokenHouse = false;
    });
  }
});

function handleResult(action: any, state: tokenHouseSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_tokenHouse = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.tokenHouse = merge(state.tokenHouse, payload) as any;
    }
  }
}

export const { clearStatetokenHouse, setSelectedtokenHouse, set_tokenHouse, mergetokenHouse, add_tokenHouse } = tokenHouseSlice.actions;

export const get_tokenHouse =
  (id?: string) =>
  (state: {
    tokenHouse: {
      tokenHouse: TokenHouse[];
    };
  }): TokenHouse | null => {
    let temp = state?.tokenHouse?.tokenHouse?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.tokenHouse?.tokenHouse[0] || null;
  };
export const get_selected_tokenHouse = (state: { tokenHouse: tokenHouseSliceState }): TokenHouse | null => {
  let id = state?.tokenHouse?.selected_tokenHouse;
  let temp = state?.tokenHouse?.tokenHouse?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_tokenHouse = (state: {
  tokenHouse: {
    tokenHouse: TokenHouse[];
  };
}): TokenHouse[] => {
  let temp = state?.tokenHouse?.tokenHouse;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_tokenHouse_busy = (state: { tokenHouse: tokenHouseSliceState }) => {
  return state?.tokenHouse.is_deleting_tokenHouse || state?.tokenHouse.is_loading_tokenHouse || state?.tokenHouse.is_creating_tokenHouse;
};
export const is_deleting_tokenHouse_busy = (state: { tokenHouse: tokenHouseSliceState }) => {
  return state?.tokenHouse.is_deleting_tokenHouse;
};
export const is_loading_tokenHouse_busy = (state: { tokenHouse: tokenHouseSliceState }) => {
  return state?.tokenHouse.is_loading_tokenHouse;
};
export const is_creating_tokenHouse_busy = (state: { tokenHouse: tokenHouseSliceState }) => {
  return state?.tokenHouse.is_creating_tokenHouse;
};

export default tokenHouseSlice.reducer;
