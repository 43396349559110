import Transaction from "./transaction-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateTransactionAsync, DeleteTransactionAsync, LoadTransactionsAsync } from "./transaction-thunk";
const initialState: transactionSliceState = {
  transaction: [],
  selected_transaction: "",
  is_loading_transaction: false,
  is_creating_transaction: false,
  is_deleting_transaction: false
};
export interface transactionSliceState {
  transaction: Transaction[];
  selected_transaction?: string;
  is_loading_transaction: boolean;
  is_creating_transaction: boolean;
  is_deleting_transaction: boolean;
}
export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setSelectedtransaction: (state, action) => {
      state.selected_transaction = action.payload;
    },
    clearStatetransaction: (state, action) => {
      state.transaction = [];
      state.is_loading_transaction = false;
      state.is_creating_transaction = false;
      state.is_deleting_transaction = false;
    },
    mergetransaction: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_transaction = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.transaction = merge(state.transaction, payload) as any;
        }
      }
    },

    add_transaction: (state, action) => {
      if (action.payload) {
        state.transaction = [...state.transaction, action.payload];
      }
    },
    set_transaction: (state, action) => {
      let { key, value, id } = action.payload;
      state.transaction = state.transaction.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadTransactionsAsync.pending, (state) => {
      state.is_loading_transaction = true;
    });
    builder.addCase(LoadTransactionsAsync.fulfilled, (state, action) => {
      state.is_loading_transaction = false;
      if (action.payload) {
        state.transaction = action.payload;
      }
    });
    builder.addCase(LoadTransactionsAsync.rejected, (state, action) => {
      state.is_loading_transaction = false;
    });
    builder.addCase(CreateTransactionAsync.pending, (state) => {
      state.is_creating_transaction = true;
    });
    builder.addCase(CreateTransactionAsync.fulfilled, (state) => {
      state.is_creating_transaction = false;
    });
    builder.addCase(CreateTransactionAsync.rejected, (state) => {
      state.is_creating_transaction = false;
    });
    builder.addCase(DeleteTransactionAsync.pending, (state) => {
      state.is_deleting_transaction = true;
    });
    builder.addCase(DeleteTransactionAsync.fulfilled, (state, action) => {
      state.is_deleting_transaction = false;
      if (state.transaction) {
        state.transaction = state.transaction.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteTransactionAsync.rejected, (state, action) => {
      state.is_deleting_transaction = false;
    });

    builder.addCase(autoThunks.transfer_funds_to_investment_account.pending, (state) => {
      state.is_loading_transaction = true;
    });
    builder.addCase(autoThunks.transfer_funds_to_investment_account.fulfilled, (state, action) => {
      state.is_loading_transaction = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.transfer_funds_to_investment_account.rejected, (state, action) => {
      state.is_loading_transaction = false;
    });

    builder.addCase(autoThunks.get_pending_fund_transfers.pending, (state) => {
      state.is_loading_transaction = true;
    });
    builder.addCase(autoThunks.get_pending_fund_transfers.fulfilled, (state, action) => {
      state.is_loading_transaction = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_pending_fund_transfers.rejected, (state, action) => {
      state.is_loading_transaction = false;
    });

    builder.addCase(autoThunks.get_in_progress_divestments_for_virtual_business.pending, (state) => {
      state.is_loading_transaction = true;
    });
    builder.addCase(autoThunks.get_in_progress_divestments_for_virtual_business.fulfilled, (state, action) => {
      state.is_loading_transaction = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_in_progress_divestments_for_virtual_business.rejected, (state, action) => {
      state.is_loading_transaction = false;
    });

    builder.addCase(autoThunks.get_virtual_business_transactions.pending, (state) => {
      state.is_loading_transaction = true;
    });
    builder.addCase(autoThunks.get_virtual_business_transactions.fulfilled, (state, action) => {
      state.is_loading_transaction = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_virtual_business_transactions.rejected, (state, action) => {
      state.is_loading_transaction = false;
    });

    builder.addCase(autoThunks.get_pending_bank_transactions.pending, (state) => {
      state.is_loading_transaction = true;
    });
    builder.addCase(autoThunks.get_pending_bank_transactions.fulfilled, (state, action) => {
      state.is_loading_transaction = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_pending_bank_transactions.rejected, (state, action) => {
      state.is_loading_transaction = false;
    });

    builder.addCase(autoThunks.add_assignment_transaction.pending, (state) => {
      state.is_loading_transaction = true;
    });
    builder.addCase(autoThunks.add_assignment_transaction.fulfilled, (state, action) => {
      state.is_loading_transaction = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.add_assignment_transaction.rejected, (state, action) => {
      state.is_loading_transaction = false;
    });

    builder.addCase(autoThunks.add_test_transction.pending, (state) => {
      state.is_loading_transaction = true;
    });
    builder.addCase(autoThunks.add_test_transction.fulfilled, (state, action) => {
      state.is_loading_transaction = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.add_test_transction.rejected, (state, action) => {
      state.is_loading_transaction = false;
    });

    builder.addCase(autoThunks.process_export.pending, (state) => {
      state.is_loading_transaction = true;
    });
    builder.addCase(autoThunks.process_export.fulfilled, (state, action) => {
      state.is_loading_transaction = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.process_export.rejected, (state, action) => {
      state.is_loading_transaction = false;
    });
  }
});

function handleResult(action: any, state: transactionSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_transaction = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.transaction = merge(state.transaction, payload) as any;
    }
  }
}

export const { clearStatetransaction, setSelectedtransaction, set_transaction, mergetransaction, add_transaction } = transactionSlice.actions;

export const get_transaction =
  (id?: string) =>
  (state: {
    transaction: {
      transaction: Transaction[];
    };
  }): Transaction | null => {
    let temp = state?.transaction?.transaction?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.transaction?.transaction[0] || null;
  };
export const get_selected_transaction = (state: { transaction: transactionSliceState }): Transaction | null => {
  let id = state?.transaction?.selected_transaction;
  let temp = state?.transaction?.transaction?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_transaction = (state: {
  transaction: {
    transaction: Transaction[];
  };
}): Transaction[] => {
  let temp = state?.transaction?.transaction;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_transaction_busy = (state: { transaction: transactionSliceState }) => {
  return state?.transaction.is_deleting_transaction || state?.transaction.is_loading_transaction || state?.transaction.is_creating_transaction;
};
export const is_deleting_transaction_busy = (state: { transaction: transactionSliceState }) => {
  return state?.transaction.is_deleting_transaction;
};
export const is_loading_transaction_busy = (state: { transaction: transactionSliceState }) => {
  return state?.transaction.is_loading_transaction;
};
export const is_creating_transaction_busy = (state: { transaction: transactionSliceState }) => {
  return state?.transaction.is_creating_transaction;
};

export default transactionSlice.reducer;
