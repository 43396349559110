import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import CourseTopicSummary from "./course-topic-summary-model";
import { CourseTopicSummaryProperties, CourseTopicSummaryMetaProperties } from "./courseTopicSummary-property-definitions";

export async function load_courseTopicSummarys(args: { uid: string }): Promise<CourseTopicSummary[]> {
  throw "Not implemented";
}

export async function delete_courseTopicSummary(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_courseTopicSummary(args: CourseTopicSummary): Promise<CourseTopicSummary> {
  throw "Not implemented";
}
