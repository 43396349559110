import CourseQuestionSorting from "./course-question-sorting-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateCourseQuestionSortingAsync, DeleteCourseQuestionSortingAsync, LoadCourseQuestionSortingsAsync } from "./courseQuestionSorting-thunk";
const initialState: courseQuestionSortingSliceState = {
  courseQuestionSorting: [],
  selected_courseQuestionSorting: "",
  is_loading_courseQuestionSorting: false,
  is_creating_courseQuestionSorting: false,
  is_deleting_courseQuestionSorting: false
};
export interface courseQuestionSortingSliceState {
  courseQuestionSorting: CourseQuestionSorting[];
  selected_courseQuestionSorting?: string;
  is_loading_courseQuestionSorting: boolean;
  is_creating_courseQuestionSorting: boolean;
  is_deleting_courseQuestionSorting: boolean;
}
export const courseQuestionSortingSlice = createSlice({
  name: "courseQuestionSorting",
  initialState,
  reducers: {
    setSelectedcourseQuestionSorting: (state, action) => {
      state.selected_courseQuestionSorting = action.payload;
    },
    clearStatecourseQuestionSorting: (state, action) => {
      state.courseQuestionSorting = [];
      state.is_loading_courseQuestionSorting = false;
      state.is_creating_courseQuestionSorting = false;
      state.is_deleting_courseQuestionSorting = false;
    },
    mergecourseQuestionSorting: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_courseQuestionSorting = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.courseQuestionSorting = merge(state.courseQuestionSorting, payload) as any;
        }
      }
    },

    add_courseQuestionSorting: (state, action) => {
      if (action.payload) {
        state.courseQuestionSorting = [...state.courseQuestionSorting, action.payload];
      }
    },
    set_courseQuestionSorting: (state, action) => {
      let { key, value, id } = action.payload;
      state.courseQuestionSorting = state.courseQuestionSorting.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadCourseQuestionSortingsAsync.pending, (state) => {
      state.is_loading_courseQuestionSorting = true;
    });
    builder.addCase(LoadCourseQuestionSortingsAsync.fulfilled, (state, action) => {
      state.is_loading_courseQuestionSorting = false;
      if (action.payload) {
        state.courseQuestionSorting = action.payload;
      }
    });
    builder.addCase(LoadCourseQuestionSortingsAsync.rejected, (state, action) => {
      state.is_loading_courseQuestionSorting = false;
    });
    builder.addCase(CreateCourseQuestionSortingAsync.pending, (state) => {
      state.is_creating_courseQuestionSorting = true;
    });
    builder.addCase(CreateCourseQuestionSortingAsync.fulfilled, (state) => {
      state.is_creating_courseQuestionSorting = false;
    });
    builder.addCase(CreateCourseQuestionSortingAsync.rejected, (state) => {
      state.is_creating_courseQuestionSorting = false;
    });
    builder.addCase(DeleteCourseQuestionSortingAsync.pending, (state) => {
      state.is_deleting_courseQuestionSorting = true;
    });
    builder.addCase(DeleteCourseQuestionSortingAsync.fulfilled, (state, action) => {
      state.is_deleting_courseQuestionSorting = false;
      if (state.courseQuestionSorting) {
        state.courseQuestionSorting = state.courseQuestionSorting.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteCourseQuestionSortingAsync.rejected, (state, action) => {
      state.is_deleting_courseQuestionSorting = false;
    });

    builder.addCase(autoThunks.get_course_section_sorting.pending, (state) => {
      state.is_loading_courseQuestionSorting = true;
    });
    builder.addCase(autoThunks.get_course_section_sorting.fulfilled, (state, action) => {
      state.is_loading_courseQuestionSorting = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_course_section_sorting.rejected, (state, action) => {
      state.is_loading_courseQuestionSorting = false;
    });

    builder.addCase(autoThunks.set_course_question_sorting.pending, (state) => {
      state.is_loading_courseQuestionSorting = true;
    });
    builder.addCase(autoThunks.set_course_question_sorting.fulfilled, (state, action) => {
      state.is_loading_courseQuestionSorting = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.set_course_question_sorting.rejected, (state, action) => {
      state.is_loading_courseQuestionSorting = false;
    });
  }
});

function handleResult(action: any, state: courseQuestionSortingSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_courseQuestionSorting = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.courseQuestionSorting = merge(state.courseQuestionSorting, payload) as any;
    }
  }
}

export const {
  clearStatecourseQuestionSorting,
  setSelectedcourseQuestionSorting,
  set_courseQuestionSorting,
  mergecourseQuestionSorting,
  add_courseQuestionSorting
} = courseQuestionSortingSlice.actions;

export const get_courseQuestionSorting =
  (id?: string) =>
  (state: {
    courseQuestionSorting: {
      courseQuestionSorting: CourseQuestionSorting[];
    };
  }): CourseQuestionSorting | null => {
    let temp = state?.courseQuestionSorting?.courseQuestionSorting?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.courseQuestionSorting?.courseQuestionSorting[0] || null;
  };
export const get_selected_courseQuestionSorting = (state: { courseQuestionSorting: courseQuestionSortingSliceState }): CourseQuestionSorting | null => {
  let id = state?.courseQuestionSorting?.selected_courseQuestionSorting;
  let temp = state?.courseQuestionSorting?.courseQuestionSorting?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_courseQuestionSorting = (state: {
  courseQuestionSorting: {
    courseQuestionSorting: CourseQuestionSorting[];
  };
}): CourseQuestionSorting[] => {
  let temp = state?.courseQuestionSorting?.courseQuestionSorting;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_courseQuestionSorting_busy = (state: { courseQuestionSorting: courseQuestionSortingSliceState }) => {
  return (
    state?.courseQuestionSorting.is_deleting_courseQuestionSorting ||
    state?.courseQuestionSorting.is_loading_courseQuestionSorting ||
    state?.courseQuestionSorting.is_creating_courseQuestionSorting
  );
};
export const is_deleting_courseQuestionSorting_busy = (state: { courseQuestionSorting: courseQuestionSortingSliceState }) => {
  return state?.courseQuestionSorting.is_deleting_courseQuestionSorting;
};
export const is_loading_courseQuestionSorting_busy = (state: { courseQuestionSorting: courseQuestionSortingSliceState }) => {
  return state?.courseQuestionSorting.is_loading_courseQuestionSorting;
};
export const is_creating_courseQuestionSorting_busy = (state: { courseQuestionSorting: courseQuestionSortingSliceState }) => {
  return state?.courseQuestionSorting.is_creating_courseQuestionSorting;
};

export default courseQuestionSortingSlice.reducer;
