import { createAsyncThunk } from "@reduxjs/toolkit";
import ResourceRegistration from "./resource-registration-model";
import { delete_resourceRegistration, load_resourceRegistrations, store_resourceRegistration } from "./resourceRegistration-service";

export const LoadResourceRegistrationsAsync = createAsyncThunk<any, any>("composer/load/resourceRegistration", async (params: { uid: string }) => {
  return await load_resourceRegistrations(params);
});

export const CreateResourceRegistrationAsync = createAsyncThunk<any, any>("composer/create/resourceRegistration", async (params: ResourceRegistration) => {
  return await store_resourceRegistration(params);
});

export const DeleteResourceRegistrationAsync = createAsyncThunk<any, any>("composer/delete/resourceRegistration", async (params) => {
  return await delete_resourceRegistration(params);
});
