import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import ClassRoom from "./class-room-model";
import { ClassRoomProperties, ClassRoomMetaProperties } from "./classRoom-property-definitions";

export async function load_classRooms(args: { uid: string }): Promise<ClassRoom[]> {
  throw "Not implemented";
}

export async function delete_classRoom(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_classRoom(args: ClassRoom): Promise<ClassRoom> {
  throw "Not implemented";
}
