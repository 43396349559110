import VirtualBusinessPetitionPartnershipDissolution from "./virtual-business-petition-partnership-dissolution-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import {
  CreateVirtualBusinessPetitionPartnershipDissolutionAsync,
  DeleteVirtualBusinessPetitionPartnershipDissolutionAsync,
  LoadVirtualBusinessPetitionPartnershipDissolutionsAsync
} from "./virtualBusinessPetitionPartnershipDissolution-thunk";
const initialState: virtualBusinessPetitionPartnershipDissolutionSliceState = {
  virtualBusinessPetitionPartnershipDissolution: [],
  selected_virtualBusinessPetitionPartnershipDissolution: "",
  is_loading_virtualBusinessPetitionPartnershipDissolution: false,
  is_creating_virtualBusinessPetitionPartnershipDissolution: false,
  is_deleting_virtualBusinessPetitionPartnershipDissolution: false
};
export interface virtualBusinessPetitionPartnershipDissolutionSliceState {
  virtualBusinessPetitionPartnershipDissolution: VirtualBusinessPetitionPartnershipDissolution[];
  selected_virtualBusinessPetitionPartnershipDissolution?: string;
  is_loading_virtualBusinessPetitionPartnershipDissolution: boolean;
  is_creating_virtualBusinessPetitionPartnershipDissolution: boolean;
  is_deleting_virtualBusinessPetitionPartnershipDissolution: boolean;
}
export const virtualBusinessPetitionPartnershipDissolutionSlice = createSlice({
  name: "virtualBusinessPetitionPartnershipDissolution",
  initialState,
  reducers: {
    setSelectedvirtualBusinessPetitionPartnershipDissolution: (state, action) => {
      state.selected_virtualBusinessPetitionPartnershipDissolution = action.payload;
    },
    clearStatevirtualBusinessPetitionPartnershipDissolution: (state, action) => {
      state.virtualBusinessPetitionPartnershipDissolution = [];
      state.is_loading_virtualBusinessPetitionPartnershipDissolution = false;
      state.is_creating_virtualBusinessPetitionPartnershipDissolution = false;
      state.is_deleting_virtualBusinessPetitionPartnershipDissolution = false;
    },
    mergevirtualBusinessPetitionPartnershipDissolution: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_virtualBusinessPetitionPartnershipDissolution = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.virtualBusinessPetitionPartnershipDissolution = merge(state.virtualBusinessPetitionPartnershipDissolution, payload) as any;
        }
      }
    },

    add_virtualBusinessPetitionPartnershipDissolution: (state, action) => {
      if (action.payload) {
        state.virtualBusinessPetitionPartnershipDissolution = [...state.virtualBusinessPetitionPartnershipDissolution, action.payload];
      }
    },
    set_virtualBusinessPetitionPartnershipDissolution: (state, action) => {
      let { key, value, id } = action.payload;
      state.virtualBusinessPetitionPartnershipDissolution = state.virtualBusinessPetitionPartnershipDissolution.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadVirtualBusinessPetitionPartnershipDissolutionsAsync.pending, (state) => {
      state.is_loading_virtualBusinessPetitionPartnershipDissolution = true;
    });
    builder.addCase(LoadVirtualBusinessPetitionPartnershipDissolutionsAsync.fulfilled, (state, action) => {
      state.is_loading_virtualBusinessPetitionPartnershipDissolution = false;
      if (action.payload) {
        state.virtualBusinessPetitionPartnershipDissolution = action.payload;
      }
    });
    builder.addCase(LoadVirtualBusinessPetitionPartnershipDissolutionsAsync.rejected, (state, action) => {
      state.is_loading_virtualBusinessPetitionPartnershipDissolution = false;
    });
    builder.addCase(CreateVirtualBusinessPetitionPartnershipDissolutionAsync.pending, (state) => {
      state.is_creating_virtualBusinessPetitionPartnershipDissolution = true;
    });
    builder.addCase(CreateVirtualBusinessPetitionPartnershipDissolutionAsync.fulfilled, (state) => {
      state.is_creating_virtualBusinessPetitionPartnershipDissolution = false;
    });
    builder.addCase(CreateVirtualBusinessPetitionPartnershipDissolutionAsync.rejected, (state) => {
      state.is_creating_virtualBusinessPetitionPartnershipDissolution = false;
    });
    builder.addCase(DeleteVirtualBusinessPetitionPartnershipDissolutionAsync.pending, (state) => {
      state.is_deleting_virtualBusinessPetitionPartnershipDissolution = true;
    });
    builder.addCase(DeleteVirtualBusinessPetitionPartnershipDissolutionAsync.fulfilled, (state, action) => {
      state.is_deleting_virtualBusinessPetitionPartnershipDissolution = false;
      if (state.virtualBusinessPetitionPartnershipDissolution) {
        state.virtualBusinessPetitionPartnershipDissolution = state.virtualBusinessPetitionPartnershipDissolution.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteVirtualBusinessPetitionPartnershipDissolutionAsync.rejected, (state, action) => {
      state.is_deleting_virtualBusinessPetitionPartnershipDissolution = false;
    });

    builder.addCase(autoThunks.petition_partnership_dissolution.pending, (state) => {
      state.is_loading_virtualBusinessPetitionPartnershipDissolution = true;
    });
    builder.addCase(autoThunks.petition_partnership_dissolution.fulfilled, (state, action) => {
      state.is_loading_virtualBusinessPetitionPartnershipDissolution = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.petition_partnership_dissolution.rejected, (state, action) => {
      state.is_loading_virtualBusinessPetitionPartnershipDissolution = false;
    });

    builder.addCase(autoThunks.fetch_partnership_dissolution_requests.pending, (state) => {
      state.is_loading_virtualBusinessPetitionPartnershipDissolution = true;
    });
    builder.addCase(autoThunks.fetch_partnership_dissolution_requests.fulfilled, (state, action) => {
      state.is_loading_virtualBusinessPetitionPartnershipDissolution = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.fetch_partnership_dissolution_requests.rejected, (state, action) => {
      state.is_loading_virtualBusinessPetitionPartnershipDissolution = false;
    });
  }
});

function handleResult(action: any, state: virtualBusinessPetitionPartnershipDissolutionSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_virtualBusinessPetitionPartnershipDissolution = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.virtualBusinessPetitionPartnershipDissolution = merge(state.virtualBusinessPetitionPartnershipDissolution, payload) as any;
    }
  }
}

export const {
  clearStatevirtualBusinessPetitionPartnershipDissolution,
  setSelectedvirtualBusinessPetitionPartnershipDissolution,
  set_virtualBusinessPetitionPartnershipDissolution,
  mergevirtualBusinessPetitionPartnershipDissolution,
  add_virtualBusinessPetitionPartnershipDissolution
} = virtualBusinessPetitionPartnershipDissolutionSlice.actions;

export const get_virtualBusinessPetitionPartnershipDissolution =
  (id?: string) =>
  (state: {
    virtualBusinessPetitionPartnershipDissolution: {
      virtualBusinessPetitionPartnershipDissolution: VirtualBusinessPetitionPartnershipDissolution[];
    };
  }): VirtualBusinessPetitionPartnershipDissolution | null => {
    let temp = state?.virtualBusinessPetitionPartnershipDissolution?.virtualBusinessPetitionPartnershipDissolution?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.virtualBusinessPetitionPartnershipDissolution?.virtualBusinessPetitionPartnershipDissolution[0] || null;
  };
export const get_selected_virtualBusinessPetitionPartnershipDissolution = (state: {
  virtualBusinessPetitionPartnershipDissolution: virtualBusinessPetitionPartnershipDissolutionSliceState;
}): VirtualBusinessPetitionPartnershipDissolution | null => {
  let id = state?.virtualBusinessPetitionPartnershipDissolution?.selected_virtualBusinessPetitionPartnershipDissolution;
  let temp = state?.virtualBusinessPetitionPartnershipDissolution?.virtualBusinessPetitionPartnershipDissolution?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_virtualBusinessPetitionPartnershipDissolution = (state: {
  virtualBusinessPetitionPartnershipDissolution: {
    virtualBusinessPetitionPartnershipDissolution: VirtualBusinessPetitionPartnershipDissolution[];
  };
}): VirtualBusinessPetitionPartnershipDissolution[] => {
  let temp = state?.virtualBusinessPetitionPartnershipDissolution?.virtualBusinessPetitionPartnershipDissolution;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_virtualBusinessPetitionPartnershipDissolution_busy = (state: {
  virtualBusinessPetitionPartnershipDissolution: virtualBusinessPetitionPartnershipDissolutionSliceState;
}) => {
  return (
    state?.virtualBusinessPetitionPartnershipDissolution.is_deleting_virtualBusinessPetitionPartnershipDissolution ||
    state?.virtualBusinessPetitionPartnershipDissolution.is_loading_virtualBusinessPetitionPartnershipDissolution ||
    state?.virtualBusinessPetitionPartnershipDissolution.is_creating_virtualBusinessPetitionPartnershipDissolution
  );
};
export const is_deleting_virtualBusinessPetitionPartnershipDissolution_busy = (state: {
  virtualBusinessPetitionPartnershipDissolution: virtualBusinessPetitionPartnershipDissolutionSliceState;
}) => {
  return state?.virtualBusinessPetitionPartnershipDissolution.is_deleting_virtualBusinessPetitionPartnershipDissolution;
};
export const is_loading_virtualBusinessPetitionPartnershipDissolution_busy = (state: {
  virtualBusinessPetitionPartnershipDissolution: virtualBusinessPetitionPartnershipDissolutionSliceState;
}) => {
  return state?.virtualBusinessPetitionPartnershipDissolution.is_loading_virtualBusinessPetitionPartnershipDissolution;
};
export const is_creating_virtualBusinessPetitionPartnershipDissolution_busy = (state: {
  virtualBusinessPetitionPartnershipDissolution: virtualBusinessPetitionPartnershipDissolutionSliceState;
}) => {
  return state?.virtualBusinessPetitionPartnershipDissolution.is_creating_virtualBusinessPetitionPartnershipDissolution;
};

export default virtualBusinessPetitionPartnershipDissolutionSlice.reducer;
