import { createAsyncThunk } from "@reduxjs/toolkit";
import CurriculumRecordItem from "./curriculum-record-item-model";
import { delete_curriculumRecordItem, load_curriculumRecordItems, store_curriculumRecordItem } from "./curriculumRecordItem-service";

export const LoadCurriculumRecordItemsAsync = createAsyncThunk<any, any>("composer/load/curriculumRecordItem", async (params: { uid: string }) => {
  return await load_curriculumRecordItems(params);
});

export const CreateCurriculumRecordItemAsync = createAsyncThunk<any, any>("composer/create/curriculumRecordItem", async (params: CurriculumRecordItem) => {
  return await store_curriculumRecordItem(params);
});

export const DeleteCurriculumRecordItemAsync = createAsyncThunk<any, any>("composer/delete/curriculumRecordItem", async (params) => {
  return await delete_curriculumRecordItem(params);
});
