import { createAsyncThunk } from "@reduxjs/toolkit";
import VotingIssue from "./voting-issue-model";
import { delete_votingIssue, load_votingIssues, store_votingIssue } from "./votingIssue-service";

export const LoadVotingIssuesAsync = createAsyncThunk<any, any>("composer/load/votingIssue", async (params: { uid: string }) => {
  return await load_votingIssues(params);
});

export const CreateVotingIssueAsync = createAsyncThunk<any, any>("composer/create/votingIssue", async (params: VotingIssue) => {
  return await store_votingIssue(params);
});

export const DeleteVotingIssueAsync = createAsyncThunk<any, any>("composer/delete/votingIssue", async (params) => {
  return await delete_votingIssue(params);
});
