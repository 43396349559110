import { createAsyncThunk } from "@reduxjs/toolkit";
import GeographicalRegion from "./geographical-region-model";
import { delete_geographicalRegion, load_geographicalRegions, store_geographicalRegion } from "./geographicalRegion-service";

export const LoadGeographicalRegionsAsync = createAsyncThunk<any, any>("composer/load/geographicalRegion", async (params: { uid: string }) => {
  return await load_geographicalRegions(params);
});

export const CreateGeographicalRegionAsync = createAsyncThunk<any, any>("composer/create/geographicalRegion", async (params: GeographicalRegion) => {
  return await store_geographicalRegion(params);
});

export const DeleteGeographicalRegionAsync = createAsyncThunk<any, any>("composer/delete/geographicalRegion", async (params) => {
  return await delete_geographicalRegion(params);
});
