import VirtualBusinessBalanceChange from "./virtual-business-balance-change-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import {
  CreateVirtualBusinessBalanceChangeAsync,
  DeleteVirtualBusinessBalanceChangeAsync,
  LoadVirtualBusinessBalanceChangesAsync
} from "./virtualBusinessBalanceChange-thunk";
const initialState: virtualBusinessBalanceChangeSliceState = {
  virtualBusinessBalanceChange: [],
  selected_virtualBusinessBalanceChange: "",
  is_loading_virtualBusinessBalanceChange: false,
  is_creating_virtualBusinessBalanceChange: false,
  is_deleting_virtualBusinessBalanceChange: false
};
export interface virtualBusinessBalanceChangeSliceState {
  virtualBusinessBalanceChange: VirtualBusinessBalanceChange[];
  selected_virtualBusinessBalanceChange?: string;
  is_loading_virtualBusinessBalanceChange: boolean;
  is_creating_virtualBusinessBalanceChange: boolean;
  is_deleting_virtualBusinessBalanceChange: boolean;
}
export const virtualBusinessBalanceChangeSlice = createSlice({
  name: "virtualBusinessBalanceChange",
  initialState,
  reducers: {
    setSelectedvirtualBusinessBalanceChange: (state, action) => {
      state.selected_virtualBusinessBalanceChange = action.payload;
    },
    clearStatevirtualBusinessBalanceChange: (state, action) => {
      state.virtualBusinessBalanceChange = [];
      state.is_loading_virtualBusinessBalanceChange = false;
      state.is_creating_virtualBusinessBalanceChange = false;
      state.is_deleting_virtualBusinessBalanceChange = false;
    },
    mergevirtualBusinessBalanceChange: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_virtualBusinessBalanceChange = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.virtualBusinessBalanceChange = merge(state.virtualBusinessBalanceChange, payload) as any;
        }
      }
    },

    add_virtualBusinessBalanceChange: (state, action) => {
      if (action.payload) {
        state.virtualBusinessBalanceChange = [...state.virtualBusinessBalanceChange, action.payload];
      }
    },
    set_virtualBusinessBalanceChange: (state, action) => {
      let { key, value, id } = action.payload;
      state.virtualBusinessBalanceChange = state.virtualBusinessBalanceChange.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadVirtualBusinessBalanceChangesAsync.pending, (state) => {
      state.is_loading_virtualBusinessBalanceChange = true;
    });
    builder.addCase(LoadVirtualBusinessBalanceChangesAsync.fulfilled, (state, action) => {
      state.is_loading_virtualBusinessBalanceChange = false;
      if (action.payload) {
        state.virtualBusinessBalanceChange = action.payload;
      }
    });
    builder.addCase(LoadVirtualBusinessBalanceChangesAsync.rejected, (state, action) => {
      state.is_loading_virtualBusinessBalanceChange = false;
    });
    builder.addCase(CreateVirtualBusinessBalanceChangeAsync.pending, (state) => {
      state.is_creating_virtualBusinessBalanceChange = true;
    });
    builder.addCase(CreateVirtualBusinessBalanceChangeAsync.fulfilled, (state) => {
      state.is_creating_virtualBusinessBalanceChange = false;
    });
    builder.addCase(CreateVirtualBusinessBalanceChangeAsync.rejected, (state) => {
      state.is_creating_virtualBusinessBalanceChange = false;
    });
    builder.addCase(DeleteVirtualBusinessBalanceChangeAsync.pending, (state) => {
      state.is_deleting_virtualBusinessBalanceChange = true;
    });
    builder.addCase(DeleteVirtualBusinessBalanceChangeAsync.fulfilled, (state, action) => {
      state.is_deleting_virtualBusinessBalanceChange = false;
      if (state.virtualBusinessBalanceChange) {
        state.virtualBusinessBalanceChange = state.virtualBusinessBalanceChange.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteVirtualBusinessBalanceChangeAsync.rejected, (state, action) => {
      state.is_deleting_virtualBusinessBalanceChange = false;
    });
  }
});

function handleResult(action: any, state: virtualBusinessBalanceChangeSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_virtualBusinessBalanceChange = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.virtualBusinessBalanceChange = merge(state.virtualBusinessBalanceChange, payload) as any;
    }
  }
}

export const {
  clearStatevirtualBusinessBalanceChange,
  setSelectedvirtualBusinessBalanceChange,
  set_virtualBusinessBalanceChange,
  mergevirtualBusinessBalanceChange,
  add_virtualBusinessBalanceChange
} = virtualBusinessBalanceChangeSlice.actions;

export const get_virtualBusinessBalanceChange =
  (id?: string) =>
  (state: {
    virtualBusinessBalanceChange: {
      virtualBusinessBalanceChange: VirtualBusinessBalanceChange[];
    };
  }): VirtualBusinessBalanceChange | null => {
    let temp = state?.virtualBusinessBalanceChange?.virtualBusinessBalanceChange?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.virtualBusinessBalanceChange?.virtualBusinessBalanceChange[0] || null;
  };
export const get_selected_virtualBusinessBalanceChange = (state: {
  virtualBusinessBalanceChange: virtualBusinessBalanceChangeSliceState;
}): VirtualBusinessBalanceChange | null => {
  let id = state?.virtualBusinessBalanceChange?.selected_virtualBusinessBalanceChange;
  let temp = state?.virtualBusinessBalanceChange?.virtualBusinessBalanceChange?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_virtualBusinessBalanceChange = (state: {
  virtualBusinessBalanceChange: {
    virtualBusinessBalanceChange: VirtualBusinessBalanceChange[];
  };
}): VirtualBusinessBalanceChange[] => {
  let temp = state?.virtualBusinessBalanceChange?.virtualBusinessBalanceChange;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_virtualBusinessBalanceChange_busy = (state: { virtualBusinessBalanceChange: virtualBusinessBalanceChangeSliceState }) => {
  return (
    state?.virtualBusinessBalanceChange.is_deleting_virtualBusinessBalanceChange ||
    state?.virtualBusinessBalanceChange.is_loading_virtualBusinessBalanceChange ||
    state?.virtualBusinessBalanceChange.is_creating_virtualBusinessBalanceChange
  );
};
export const is_deleting_virtualBusinessBalanceChange_busy = (state: { virtualBusinessBalanceChange: virtualBusinessBalanceChangeSliceState }) => {
  return state?.virtualBusinessBalanceChange.is_deleting_virtualBusinessBalanceChange;
};
export const is_loading_virtualBusinessBalanceChange_busy = (state: { virtualBusinessBalanceChange: virtualBusinessBalanceChangeSliceState }) => {
  return state?.virtualBusinessBalanceChange.is_loading_virtualBusinessBalanceChange;
};
export const is_creating_virtualBusinessBalanceChange_busy = (state: { virtualBusinessBalanceChange: virtualBusinessBalanceChangeSliceState }) => {
  return state?.virtualBusinessBalanceChange.is_creating_virtualBusinessBalanceChange;
};

export default virtualBusinessBalanceChangeSlice.reducer;
