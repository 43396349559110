import School from "./school-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateSchoolAsync, DeleteSchoolAsync, LoadSchoolsAsync } from "./school-thunk";
const initialState: schoolSliceState = {
  school: [],
  selected_school: "",
  is_loading_school: false,
  is_creating_school: false,
  is_deleting_school: false
};
export interface schoolSliceState {
  school: School[];
  selected_school?: string;
  is_loading_school: boolean;
  is_creating_school: boolean;
  is_deleting_school: boolean;
}
export const schoolSlice = createSlice({
  name: "school",
  initialState,
  reducers: {
    setSelectedschool: (state, action) => {
      state.selected_school = action.payload;
    },
    clearStateschool: (state, action) => {
      state.school = [];
      state.is_loading_school = false;
      state.is_creating_school = false;
      state.is_deleting_school = false;
    },
    mergeschool: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_school = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.school = merge(state.school, payload) as any;
        }
      }
    },

    add_school: (state, action) => {
      if (action.payload) {
        state.school = [...state.school, action.payload];
      }
    },
    set_school: (state, action) => {
      let { key, value, id } = action.payload;
      state.school = state.school.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadSchoolsAsync.pending, (state) => {
      state.is_loading_school = true;
    });
    builder.addCase(LoadSchoolsAsync.fulfilled, (state, action) => {
      state.is_loading_school = false;
      if (action.payload) {
        state.school = action.payload;
      }
    });
    builder.addCase(LoadSchoolsAsync.rejected, (state, action) => {
      state.is_loading_school = false;
    });
    builder.addCase(CreateSchoolAsync.pending, (state) => {
      state.is_creating_school = true;
    });
    builder.addCase(CreateSchoolAsync.fulfilled, (state) => {
      state.is_creating_school = false;
    });
    builder.addCase(CreateSchoolAsync.rejected, (state) => {
      state.is_creating_school = false;
    });
    builder.addCase(DeleteSchoolAsync.pending, (state) => {
      state.is_deleting_school = true;
    });
    builder.addCase(DeleteSchoolAsync.fulfilled, (state, action) => {
      state.is_deleting_school = false;
      if (state.school) {
        state.school = state.school.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteSchoolAsync.rejected, (state, action) => {
      state.is_deleting_school = false;
    });

    builder.addCase(autoThunks.get_schools_in_district.pending, (state) => {
      state.is_loading_school = true;
    });
    builder.addCase(autoThunks.get_schools_in_district.fulfilled, (state, action) => {
      state.is_loading_school = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_schools_in_district.rejected, (state, action) => {
      state.is_loading_school = false;
    });

    builder.addCase(autoThunks.store_school.pending, (state) => {
      state.is_loading_school = true;
    });
    builder.addCase(autoThunks.store_school.fulfilled, (state, action) => {
      state.is_loading_school = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.store_school.rejected, (state, action) => {
      state.is_loading_school = false;
    });

    builder.addCase(autoThunks.update_school_district.pending, (state) => {
      state.is_loading_school = true;
    });
    builder.addCase(autoThunks.update_school_district.fulfilled, (state, action) => {
      state.is_loading_school = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.update_school_district.rejected, (state, action) => {
      state.is_loading_school = false;
    });
  }
});

function handleResult(action: any, state: schoolSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_school = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.school = merge(state.school, payload) as any;
    }
  }
}

export const { clearStateschool, setSelectedschool, set_school, mergeschool, add_school } = schoolSlice.actions;

export const get_school =
  (id?: string) =>
  (state: {
    school: {
      school: School[];
    };
  }): School | null => {
    let temp = state?.school?.school?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.school?.school[0] || null;
  };
export const get_selected_school = (state: { school: schoolSliceState }): School | null => {
  let id = state?.school?.selected_school;
  let temp = state?.school?.school?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_school = (state: {
  school: {
    school: School[];
  };
}): School[] => {
  let temp = state?.school?.school;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_school_busy = (state: { school: schoolSliceState }) => {
  return state?.school.is_deleting_school || state?.school.is_loading_school || state?.school.is_creating_school;
};
export const is_deleting_school_busy = (state: { school: schoolSliceState }) => {
  return state?.school.is_deleting_school;
};
export const is_loading_school_busy = (state: { school: schoolSliceState }) => {
  return state?.school.is_loading_school;
};
export const is_creating_school_busy = (state: { school: schoolSliceState }) => {
  return state?.school.is_creating_school;
};

export default schoolSlice.reducer;
