import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import DigitalAsset from "./digital-asset-model";
import { DigitalAssetProperties, DigitalAssetMetaProperties } from "./digitalAsset-property-definitions";

export async function load_digitalAssets(args: { uid: string }): Promise<DigitalAsset[]> {
  throw "Not implemented";
}

export async function delete_digitalAsset(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_digitalAsset(args: DigitalAsset): Promise<DigitalAsset> {
  throw "Not implemented";
}
