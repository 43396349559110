import React, { createContext, useEffect, useReducer, useState } from 'react';

// third-party
// import firebase from 'firebase/app';
import 'firebase/auth';

// action - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
// import config from 'config';
import { initialLoginContextProps } from 'types';
import { FirebaseContextType } from 'types/auth';
import { connectToEmailAndPassword, getReqs, PROVIDERS, setAuthStateChangedHandler, setAuthStateContexHandler, setupFirebase, signinWith, signOut } from 'features/firebase-app';
import { setAuthenticationInfo } from 'features/application/application-slice';
import { useDispatch } from 'react-redux';

// firebase initialize
// if (!firebase.apps.length) {
//     firebase.initializeApp(config.firebase);
// }

// const
const initialState: initialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext<FirebaseContextType | null>(null);

export const FirebaseProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const [claims, setClaims] = useState(null)
    const applicationDispatch = useDispatch();
    useEffect(
        () => {
            setAuthStateChangedHandler((res) => {
                setClaims(res?.claims || null)
                applicationDispatch(setAuthenticationInfo(res));
            })
            setupFirebase();
            const { auth } = getReqs();
            if (auth) {
                auth.onAuthStateChanged((user) => {
                    if (user) {
                        dispatch({
                            type: LOGIN,
                            payload: {
                                isLoggedIn: true,
                                user: {
                                    id: user.uid,
                                    email: user.email!,
                                    name: user.displayName || 'Betty'
                                }
                            }
                        });
                    } else {
                        dispatch({
                            type: LOGOUT
                        });
                    }
                })
            }
        },
        [dispatch]
    );

    const firebaseEmailPasswordSignIn = (email: string, password: string) => {
        // firebase.auth().signInWithEmailAndPassword(email, password)
        return connectToEmailAndPassword(email, password, false);
    };

    const firebaseGoogleSignIn = () => {
        // const { auth } = getReqs();
        // const provider = new firebase.auth.GoogleAuthProvider();

        // return firebase.auth().signInWithPopup(provider);
        return signinWith(PROVIDERS.GOOGLE);
    };

    const firebaseRegister = async (email: string, password: string) => {
        // firebase.auth().createUserWithEmailAndPassword(email, password)
        return connectToEmailAndPassword(email, password, true);
    };

    const logout = () => {
        // firebase.auth().signOut()
        signOut()
        return Promise.resolve();
    };

    const resetPassword = async (email: string) => {
        // await firebase.auth().sendPasswordResetEmail(email);
        return Promise.resolve();
    };

    const updateProfile = () => { };
    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseRegister,
                firebaseEmailPasswordSignIn,
                login: () => { },
                claims,
                firebaseGoogleSignIn,
                logout,
                resetPassword,
                updateProfile
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

export default FirebaseContext;
