import { createAsyncThunk } from "@reduxjs/toolkit";
import District from "./district-model";
import { delete_district, load_districts, store_district } from "./district-service";

export const LoadDistrictsAsync = createAsyncThunk<any, any>("composer/load/district", async (params: { uid: string }) => {
  return await load_districts(params);
});

export const CreateDistrictAsync = createAsyncThunk<any, any>("composer/create/district", async (params: District) => {
  return await store_district(params);
});

export const DeleteDistrictAsync = createAsyncThunk<any, any>("composer/delete/district", async (params) => {
  return await delete_district(params);
});
