import { createAsyncThunk } from "@reduxjs/toolkit";
import ServiceContract from "./service-contract-model";
import { delete_serviceContract, load_serviceContracts, store_serviceContract } from "./serviceContract-service";

export const LoadServiceContractsAsync = createAsyncThunk<any, any>("composer/load/serviceContract", async (params: { uid: string }) => {
  return await load_serviceContracts(params);
});

export const CreateServiceContractAsync = createAsyncThunk<any, any>("composer/create/serviceContract", async (params: ServiceContract) => {
  return await store_serviceContract(params);
});

export const DeleteServiceContractAsync = createAsyncThunk<any, any>("composer/delete/serviceContract", async (params) => {
  return await delete_serviceContract(params);
});
