import BankOwner from "./bank-owner-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateBankOwnerAsync, DeleteBankOwnerAsync, LoadBankOwnersAsync } from "./bankOwner-thunk";
const initialState: bankOwnerSliceState = {
  bankOwner: [],
  selected_bankOwner: "",
  is_loading_bankOwner: false,
  is_creating_bankOwner: false,
  is_deleting_bankOwner: false
};
export interface bankOwnerSliceState {
  bankOwner: BankOwner[];
  selected_bankOwner?: string;
  is_loading_bankOwner: boolean;
  is_creating_bankOwner: boolean;
  is_deleting_bankOwner: boolean;
}
export const bankOwnerSlice = createSlice({
  name: "bankOwner",
  initialState,
  reducers: {
    setSelectedbankOwner: (state, action) => {
      state.selected_bankOwner = action.payload;
    },
    clearStatebankOwner: (state, action) => {
      state.bankOwner = [];
      state.is_loading_bankOwner = false;
      state.is_creating_bankOwner = false;
      state.is_deleting_bankOwner = false;
    },
    mergebankOwner: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_bankOwner = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.bankOwner = merge(state.bankOwner, payload) as any;
        }
      }
    },

    add_bankOwner: (state, action) => {
      if (action.payload) {
        state.bankOwner = [...state.bankOwner, action.payload];
      }
    },
    set_bankOwner: (state, action) => {
      let { key, value, id } = action.payload;
      state.bankOwner = state.bankOwner.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadBankOwnersAsync.pending, (state) => {
      state.is_loading_bankOwner = true;
    });
    builder.addCase(LoadBankOwnersAsync.fulfilled, (state, action) => {
      state.is_loading_bankOwner = false;
      if (action.payload) {
        state.bankOwner = action.payload;
      }
    });
    builder.addCase(LoadBankOwnersAsync.rejected, (state, action) => {
      state.is_loading_bankOwner = false;
    });
    builder.addCase(CreateBankOwnerAsync.pending, (state) => {
      state.is_creating_bankOwner = true;
    });
    builder.addCase(CreateBankOwnerAsync.fulfilled, (state) => {
      state.is_creating_bankOwner = false;
    });
    builder.addCase(CreateBankOwnerAsync.rejected, (state) => {
      state.is_creating_bankOwner = false;
    });
    builder.addCase(DeleteBankOwnerAsync.pending, (state) => {
      state.is_deleting_bankOwner = true;
    });
    builder.addCase(DeleteBankOwnerAsync.fulfilled, (state, action) => {
      state.is_deleting_bankOwner = false;
      if (state.bankOwner) {
        state.bankOwner = state.bankOwner.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteBankOwnerAsync.rejected, (state, action) => {
      state.is_deleting_bankOwner = false;
    });

    builder.addCase(autoThunks.get_bank_owners.pending, (state) => {
      state.is_loading_bankOwner = true;
    });
    builder.addCase(autoThunks.get_bank_owners.fulfilled, (state, action) => {
      state.is_loading_bankOwner = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_bank_owners.rejected, (state, action) => {
      state.is_loading_bankOwner = false;
    });

    builder.addCase(autoThunks.update_bank_owner.pending, (state) => {
      state.is_loading_bankOwner = true;
    });
    builder.addCase(autoThunks.update_bank_owner.fulfilled, (state, action) => {
      state.is_loading_bankOwner = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.update_bank_owner.rejected, (state, action) => {
      state.is_loading_bankOwner = false;
    });

    builder.addCase(autoThunks.get_bankowners_for_room_agents.pending, (state) => {
      state.is_loading_bankOwner = true;
    });
    builder.addCase(autoThunks.get_bankowners_for_room_agents.fulfilled, (state, action) => {
      state.is_loading_bankOwner = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_bankowners_for_room_agents.rejected, (state, action) => {
      state.is_loading_bankOwner = false;
    });

    builder.addCase(autoThunks.store_bankOwner.pending, (state) => {
      state.is_loading_bankOwner = true;
    });
    builder.addCase(autoThunks.store_bankOwner.fulfilled, (state, action) => {
      state.is_loading_bankOwner = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.store_bankOwner.rejected, (state, action) => {
      state.is_loading_bankOwner = false;
    });
  }
});

function handleResult(action: any, state: bankOwnerSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_bankOwner = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.bankOwner = merge(state.bankOwner, payload) as any;
    }
  }
}

export const { clearStatebankOwner, setSelectedbankOwner, set_bankOwner, mergebankOwner, add_bankOwner } = bankOwnerSlice.actions;

export const get_bankOwner =
  (id?: string) =>
  (state: {
    bankOwner: {
      bankOwner: BankOwner[];
    };
  }): BankOwner | null => {
    let temp = state?.bankOwner?.bankOwner?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.bankOwner?.bankOwner[0] || null;
  };
export const get_selected_bankOwner = (state: { bankOwner: bankOwnerSliceState }): BankOwner | null => {
  let id = state?.bankOwner?.selected_bankOwner;
  let temp = state?.bankOwner?.bankOwner?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_bankOwner = (state: {
  bankOwner: {
    bankOwner: BankOwner[];
  };
}): BankOwner[] => {
  let temp = state?.bankOwner?.bankOwner;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_bankOwner_busy = (state: { bankOwner: bankOwnerSliceState }) => {
  return state?.bankOwner.is_deleting_bankOwner || state?.bankOwner.is_loading_bankOwner || state?.bankOwner.is_creating_bankOwner;
};
export const is_deleting_bankOwner_busy = (state: { bankOwner: bankOwnerSliceState }) => {
  return state?.bankOwner.is_deleting_bankOwner;
};
export const is_loading_bankOwner_busy = (state: { bankOwner: bankOwnerSliceState }) => {
  return state?.bankOwner.is_loading_bankOwner;
};
export const is_creating_bankOwner_busy = (state: { bankOwner: bankOwnerSliceState }) => {
  return state?.bankOwner.is_creating_bankOwner;
};

export default bankOwnerSlice.reducer;
