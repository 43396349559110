import { createAsyncThunk } from "@reduxjs/toolkit";
import PublishedCourse from "./published-course-model";
import { delete_publishedCourse, load_publishedCourses, store_publishedCourse } from "./publishedCourse-service";

export const LoadPublishedCoursesAsync = createAsyncThunk<any, any>("composer/load/publishedCourse", async (params: { uid: string }) => {
  return await load_publishedCourses(params);
});

export const CreatePublishedCourseAsync = createAsyncThunk<any, any>("composer/create/publishedCourse", async (params: PublishedCourse) => {
  return await store_publishedCourse(params);
});

export const DeletePublishedCourseAsync = createAsyncThunk<any, any>("composer/delete/publishedCourse", async (params) => {
  return await delete_publishedCourse(params);
});
