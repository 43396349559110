import MarketingMaterialItem from "./marketing-material-item-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateMarketingMaterialItemAsync, DeleteMarketingMaterialItemAsync, LoadMarketingMaterialItemsAsync } from "./marketingMaterialItem-thunk";
const initialState: marketingMaterialItemSliceState = {
  marketingMaterialItem: [],
  selected_marketingMaterialItem: "",
  is_loading_marketingMaterialItem: false,
  is_creating_marketingMaterialItem: false,
  is_deleting_marketingMaterialItem: false
};
export interface marketingMaterialItemSliceState {
  marketingMaterialItem: MarketingMaterialItem[];
  selected_marketingMaterialItem?: string;
  is_loading_marketingMaterialItem: boolean;
  is_creating_marketingMaterialItem: boolean;
  is_deleting_marketingMaterialItem: boolean;
}
export const marketingMaterialItemSlice = createSlice({
  name: "marketingMaterialItem",
  initialState,
  reducers: {
    setSelectedmarketingMaterialItem: (state, action) => {
      state.selected_marketingMaterialItem = action.payload;
    },
    clearStatemarketingMaterialItem: (state, action) => {
      state.marketingMaterialItem = [];
      state.is_loading_marketingMaterialItem = false;
      state.is_creating_marketingMaterialItem = false;
      state.is_deleting_marketingMaterialItem = false;
    },
    mergemarketingMaterialItem: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_marketingMaterialItem = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.marketingMaterialItem = merge(state.marketingMaterialItem, payload) as any;
        }
      }
    },

    add_marketingMaterialItem: (state, action) => {
      if (action.payload) {
        state.marketingMaterialItem = [...state.marketingMaterialItem, action.payload];
      }
    },
    set_marketingMaterialItem: (state, action) => {
      let { key, value, id } = action.payload;
      state.marketingMaterialItem = state.marketingMaterialItem.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadMarketingMaterialItemsAsync.pending, (state) => {
      state.is_loading_marketingMaterialItem = true;
    });
    builder.addCase(LoadMarketingMaterialItemsAsync.fulfilled, (state, action) => {
      state.is_loading_marketingMaterialItem = false;
      if (action.payload) {
        state.marketingMaterialItem = action.payload;
      }
    });
    builder.addCase(LoadMarketingMaterialItemsAsync.rejected, (state, action) => {
      state.is_loading_marketingMaterialItem = false;
    });
    builder.addCase(CreateMarketingMaterialItemAsync.pending, (state) => {
      state.is_creating_marketingMaterialItem = true;
    });
    builder.addCase(CreateMarketingMaterialItemAsync.fulfilled, (state) => {
      state.is_creating_marketingMaterialItem = false;
    });
    builder.addCase(CreateMarketingMaterialItemAsync.rejected, (state) => {
      state.is_creating_marketingMaterialItem = false;
    });
    builder.addCase(DeleteMarketingMaterialItemAsync.pending, (state) => {
      state.is_deleting_marketingMaterialItem = true;
    });
    builder.addCase(DeleteMarketingMaterialItemAsync.fulfilled, (state, action) => {
      state.is_deleting_marketingMaterialItem = false;
      if (state.marketingMaterialItem) {
        state.marketingMaterialItem = state.marketingMaterialItem.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteMarketingMaterialItemAsync.rejected, (state, action) => {
      state.is_deleting_marketingMaterialItem = false;
    });
  }
});

function handleResult(action: any, state: marketingMaterialItemSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_marketingMaterialItem = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.marketingMaterialItem = merge(state.marketingMaterialItem, payload) as any;
    }
  }
}

export const {
  clearStatemarketingMaterialItem,
  setSelectedmarketingMaterialItem,
  set_marketingMaterialItem,
  mergemarketingMaterialItem,
  add_marketingMaterialItem
} = marketingMaterialItemSlice.actions;

export const get_marketingMaterialItem =
  (id?: string) =>
  (state: {
    marketingMaterialItem: {
      marketingMaterialItem: MarketingMaterialItem[];
    };
  }): MarketingMaterialItem | null => {
    let temp = state?.marketingMaterialItem?.marketingMaterialItem?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.marketingMaterialItem?.marketingMaterialItem[0] || null;
  };
export const get_selected_marketingMaterialItem = (state: { marketingMaterialItem: marketingMaterialItemSliceState }): MarketingMaterialItem | null => {
  let id = state?.marketingMaterialItem?.selected_marketingMaterialItem;
  let temp = state?.marketingMaterialItem?.marketingMaterialItem?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_marketingMaterialItem = (state: {
  marketingMaterialItem: {
    marketingMaterialItem: MarketingMaterialItem[];
  };
}): MarketingMaterialItem[] => {
  let temp = state?.marketingMaterialItem?.marketingMaterialItem;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_marketingMaterialItem_busy = (state: { marketingMaterialItem: marketingMaterialItemSliceState }) => {
  return (
    state?.marketingMaterialItem.is_deleting_marketingMaterialItem ||
    state?.marketingMaterialItem.is_loading_marketingMaterialItem ||
    state?.marketingMaterialItem.is_creating_marketingMaterialItem
  );
};
export const is_deleting_marketingMaterialItem_busy = (state: { marketingMaterialItem: marketingMaterialItemSliceState }) => {
  return state?.marketingMaterialItem.is_deleting_marketingMaterialItem;
};
export const is_loading_marketingMaterialItem_busy = (state: { marketingMaterialItem: marketingMaterialItemSliceState }) => {
  return state?.marketingMaterialItem.is_loading_marketingMaterialItem;
};
export const is_creating_marketingMaterialItem_busy = (state: { marketingMaterialItem: marketingMaterialItemSliceState }) => {
  return state?.marketingMaterialItem.is_creating_marketingMaterialItem;
};

export default marketingMaterialItemSlice.reducer;
