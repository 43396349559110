import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import PushNotification from "./push-notification-model";
import { PushNotificationProperties, PushNotificationMetaProperties } from "./pushNotification-property-definitions";

export async function load_pushNotifications(args: { uid: string }): Promise<PushNotification[]> {
  throw "Not implemented";
}

export async function delete_pushNotification(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_pushNotification(args: PushNotification): Promise<PushNotification> {
  throw "Not implemented";
}
