import FakeModel from "./fake-model-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateFakeModelAsync, DeleteFakeModelAsync, LoadFakeModelsAsync } from "./fakeModel-thunk";
const initialState: fakeModelSliceState = {
  fakeModel: [],
  selected_fakeModel: "",
  is_loading_fakeModel: false,
  is_creating_fakeModel: false,
  is_deleting_fakeModel: false
};
export interface fakeModelSliceState {
  fakeModel: FakeModel[];
  selected_fakeModel?: string;
  is_loading_fakeModel: boolean;
  is_creating_fakeModel: boolean;
  is_deleting_fakeModel: boolean;
}
export const fakeModelSlice = createSlice({
  name: "fakeModel",
  initialState,
  reducers: {
    setSelectedfakeModel: (state, action) => {
      state.selected_fakeModel = action.payload;
    },
    clearStatefakeModel: (state, action) => {
      state.fakeModel = [];
      state.is_loading_fakeModel = false;
      state.is_creating_fakeModel = false;
      state.is_deleting_fakeModel = false;
    },
    mergefakeModel: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_fakeModel = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.fakeModel = merge(state.fakeModel, payload) as any;
        }
      }
    },

    add_fakeModel: (state, action) => {
      if (action.payload) {
        state.fakeModel = [...state.fakeModel, action.payload];
      }
    },
    set_fakeModel: (state, action) => {
      let { key, value, id } = action.payload;
      state.fakeModel = state.fakeModel.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadFakeModelsAsync.pending, (state) => {
      state.is_loading_fakeModel = true;
    });
    builder.addCase(LoadFakeModelsAsync.fulfilled, (state, action) => {
      state.is_loading_fakeModel = false;
      if (action.payload) {
        state.fakeModel = action.payload;
      }
    });
    builder.addCase(LoadFakeModelsAsync.rejected, (state, action) => {
      state.is_loading_fakeModel = false;
    });
    builder.addCase(CreateFakeModelAsync.pending, (state) => {
      state.is_creating_fakeModel = true;
    });
    builder.addCase(CreateFakeModelAsync.fulfilled, (state) => {
      state.is_creating_fakeModel = false;
    });
    builder.addCase(CreateFakeModelAsync.rejected, (state) => {
      state.is_creating_fakeModel = false;
    });
    builder.addCase(DeleteFakeModelAsync.pending, (state) => {
      state.is_deleting_fakeModel = true;
    });
    builder.addCase(DeleteFakeModelAsync.fulfilled, (state, action) => {
      state.is_deleting_fakeModel = false;
      if (state.fakeModel) {
        state.fakeModel = state.fakeModel.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteFakeModelAsync.rejected, (state, action) => {
      state.is_deleting_fakeModel = false;
    });
  }
});

function handleResult(action: any, state: fakeModelSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_fakeModel = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.fakeModel = merge(state.fakeModel, payload) as any;
    }
  }
}

export const { clearStatefakeModel, setSelectedfakeModel, set_fakeModel, mergefakeModel, add_fakeModel } = fakeModelSlice.actions;

export const get_fakeModel =
  (id?: string) =>
  (state: {
    fakeModel: {
      fakeModel: FakeModel[];
    };
  }): FakeModel | null => {
    let temp = state?.fakeModel?.fakeModel?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.fakeModel?.fakeModel[0] || null;
  };
export const get_selected_fakeModel = (state: { fakeModel: fakeModelSliceState }): FakeModel | null => {
  let id = state?.fakeModel?.selected_fakeModel;
  let temp = state?.fakeModel?.fakeModel?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_fakeModel = (state: {
  fakeModel: {
    fakeModel: FakeModel[];
  };
}): FakeModel[] => {
  let temp = state?.fakeModel?.fakeModel;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_fakeModel_busy = (state: { fakeModel: fakeModelSliceState }) => {
  return state?.fakeModel.is_deleting_fakeModel || state?.fakeModel.is_loading_fakeModel || state?.fakeModel.is_creating_fakeModel;
};
export const is_deleting_fakeModel_busy = (state: { fakeModel: fakeModelSliceState }) => {
  return state?.fakeModel.is_deleting_fakeModel;
};
export const is_loading_fakeModel_busy = (state: { fakeModel: fakeModelSliceState }) => {
  return state?.fakeModel.is_loading_fakeModel;
};
export const is_creating_fakeModel_busy = (state: { fakeModel: fakeModelSliceState }) => {
  return state?.fakeModel.is_creating_fakeModel;
};

export default fakeModelSlice.reducer;
