import VirtualBusinessInvestorReference from "./virtual-business-investor-reference-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import {
  CreateVirtualBusinessInvestorReferenceAsync,
  DeleteVirtualBusinessInvestorReferenceAsync,
  LoadVirtualBusinessInvestorReferencesAsync
} from "./virtualBusinessInvestorReference-thunk";
const initialState: virtualBusinessInvestorReferenceSliceState = {
  virtualBusinessInvestorReference: [],
  selected_virtualBusinessInvestorReference: "",
  is_loading_virtualBusinessInvestorReference: false,
  is_creating_virtualBusinessInvestorReference: false,
  is_deleting_virtualBusinessInvestorReference: false
};
export interface virtualBusinessInvestorReferenceSliceState {
  virtualBusinessInvestorReference: VirtualBusinessInvestorReference[];
  selected_virtualBusinessInvestorReference?: string;
  is_loading_virtualBusinessInvestorReference: boolean;
  is_creating_virtualBusinessInvestorReference: boolean;
  is_deleting_virtualBusinessInvestorReference: boolean;
}
export const virtualBusinessInvestorReferenceSlice = createSlice({
  name: "virtualBusinessInvestorReference",
  initialState,
  reducers: {
    setSelectedvirtualBusinessInvestorReference: (state, action) => {
      state.selected_virtualBusinessInvestorReference = action.payload;
    },
    clearStatevirtualBusinessInvestorReference: (state, action) => {
      state.virtualBusinessInvestorReference = [];
      state.is_loading_virtualBusinessInvestorReference = false;
      state.is_creating_virtualBusinessInvestorReference = false;
      state.is_deleting_virtualBusinessInvestorReference = false;
    },
    mergevirtualBusinessInvestorReference: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_virtualBusinessInvestorReference = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.virtualBusinessInvestorReference = merge(state.virtualBusinessInvestorReference, payload) as any;
        }
      }
    },

    add_virtualBusinessInvestorReference: (state, action) => {
      if (action.payload) {
        state.virtualBusinessInvestorReference = [...state.virtualBusinessInvestorReference, action.payload];
      }
    },
    set_virtualBusinessInvestorReference: (state, action) => {
      let { key, value, id } = action.payload;
      state.virtualBusinessInvestorReference = state.virtualBusinessInvestorReference.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadVirtualBusinessInvestorReferencesAsync.pending, (state) => {
      state.is_loading_virtualBusinessInvestorReference = true;
    });
    builder.addCase(LoadVirtualBusinessInvestorReferencesAsync.fulfilled, (state, action) => {
      state.is_loading_virtualBusinessInvestorReference = false;
      if (action.payload) {
        state.virtualBusinessInvestorReference = action.payload;
      }
    });
    builder.addCase(LoadVirtualBusinessInvestorReferencesAsync.rejected, (state, action) => {
      state.is_loading_virtualBusinessInvestorReference = false;
    });
    builder.addCase(CreateVirtualBusinessInvestorReferenceAsync.pending, (state) => {
      state.is_creating_virtualBusinessInvestorReference = true;
    });
    builder.addCase(CreateVirtualBusinessInvestorReferenceAsync.fulfilled, (state) => {
      state.is_creating_virtualBusinessInvestorReference = false;
    });
    builder.addCase(CreateVirtualBusinessInvestorReferenceAsync.rejected, (state) => {
      state.is_creating_virtualBusinessInvestorReference = false;
    });
    builder.addCase(DeleteVirtualBusinessInvestorReferenceAsync.pending, (state) => {
      state.is_deleting_virtualBusinessInvestorReference = true;
    });
    builder.addCase(DeleteVirtualBusinessInvestorReferenceAsync.fulfilled, (state, action) => {
      state.is_deleting_virtualBusinessInvestorReference = false;
      if (state.virtualBusinessInvestorReference) {
        state.virtualBusinessInvestorReference = state.virtualBusinessInvestorReference.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteVirtualBusinessInvestorReferenceAsync.rejected, (state, action) => {
      state.is_deleting_virtualBusinessInvestorReference = false;
    });
  }
});

function handleResult(action: any, state: virtualBusinessInvestorReferenceSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_virtualBusinessInvestorReference = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.virtualBusinessInvestorReference = merge(state.virtualBusinessInvestorReference, payload) as any;
    }
  }
}

export const {
  clearStatevirtualBusinessInvestorReference,
  setSelectedvirtualBusinessInvestorReference,
  set_virtualBusinessInvestorReference,
  mergevirtualBusinessInvestorReference,
  add_virtualBusinessInvestorReference
} = virtualBusinessInvestorReferenceSlice.actions;

export const get_virtualBusinessInvestorReference =
  (id?: string) =>
  (state: {
    virtualBusinessInvestorReference: {
      virtualBusinessInvestorReference: VirtualBusinessInvestorReference[];
    };
  }): VirtualBusinessInvestorReference | null => {
    let temp = state?.virtualBusinessInvestorReference?.virtualBusinessInvestorReference?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.virtualBusinessInvestorReference?.virtualBusinessInvestorReference[0] || null;
  };
export const get_selected_virtualBusinessInvestorReference = (state: {
  virtualBusinessInvestorReference: virtualBusinessInvestorReferenceSliceState;
}): VirtualBusinessInvestorReference | null => {
  let id = state?.virtualBusinessInvestorReference?.selected_virtualBusinessInvestorReference;
  let temp = state?.virtualBusinessInvestorReference?.virtualBusinessInvestorReference?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_virtualBusinessInvestorReference = (state: {
  virtualBusinessInvestorReference: {
    virtualBusinessInvestorReference: VirtualBusinessInvestorReference[];
  };
}): VirtualBusinessInvestorReference[] => {
  let temp = state?.virtualBusinessInvestorReference?.virtualBusinessInvestorReference;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_virtualBusinessInvestorReference_busy = (state: { virtualBusinessInvestorReference: virtualBusinessInvestorReferenceSliceState }) => {
  return (
    state?.virtualBusinessInvestorReference.is_deleting_virtualBusinessInvestorReference ||
    state?.virtualBusinessInvestorReference.is_loading_virtualBusinessInvestorReference ||
    state?.virtualBusinessInvestorReference.is_creating_virtualBusinessInvestorReference
  );
};
export const is_deleting_virtualBusinessInvestorReference_busy = (state: { virtualBusinessInvestorReference: virtualBusinessInvestorReferenceSliceState }) => {
  return state?.virtualBusinessInvestorReference.is_deleting_virtualBusinessInvestorReference;
};
export const is_loading_virtualBusinessInvestorReference_busy = (state: { virtualBusinessInvestorReference: virtualBusinessInvestorReferenceSliceState }) => {
  return state?.virtualBusinessInvestorReference.is_loading_virtualBusinessInvestorReference;
};
export const is_creating_virtualBusinessInvestorReference_busy = (state: { virtualBusinessInvestorReference: virtualBusinessInvestorReferenceSliceState }) => {
  return state?.virtualBusinessInvestorReference.is_creating_virtualBusinessInvestorReference;
};

export default virtualBusinessInvestorReferenceSlice.reducer;
