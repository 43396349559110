import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import VirtualInvestment from "./virtual-investment-model";
import { VirtualInvestmentProperties, VirtualInvestmentMetaProperties } from "./virtualInvestment-property-definitions";

export async function load_virtualInvestments(args: { uid: string }): Promise<VirtualInvestment[]> {
  throw "Not implemented";
}

export async function delete_virtualInvestment(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_virtualInvestment(args: VirtualInvestment): Promise<VirtualInvestment> {
  throw "Not implemented";
}
