import Answer from "./answer-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateAnswerAsync, DeleteAnswerAsync, LoadAnswersAsync } from "./answer-thunk";
const initialState: answerSliceState = {
  answer: [],
  selected_answer: "",
  is_loading_answer: false,
  is_creating_answer: false,
  is_deleting_answer: false
};
export interface answerSliceState {
  answer: Answer[];
  selected_answer?: string;
  is_loading_answer: boolean;
  is_creating_answer: boolean;
  is_deleting_answer: boolean;
}
export const answerSlice = createSlice({
  name: "answer",
  initialState,
  reducers: {
    setSelectedanswer: (state, action) => {
      state.selected_answer = action.payload;
    },
    clearStateanswer: (state, action) => {
      state.answer = [];
      state.is_loading_answer = false;
      state.is_creating_answer = false;
      state.is_deleting_answer = false;
    },
    mergeanswer: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_answer = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.answer = merge(state.answer, payload) as any;
        }
      }
    },

    add_answer: (state, action) => {
      if (action.payload) {
        state.answer = [...state.answer, action.payload];
      }
    },
    set_answer: (state, action) => {
      let { key, value, id } = action.payload;
      state.answer = state.answer.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadAnswersAsync.pending, (state) => {
      state.is_loading_answer = true;
    });
    builder.addCase(LoadAnswersAsync.fulfilled, (state, action) => {
      state.is_loading_answer = false;
      if (action.payload) {
        state.answer = action.payload;
      }
    });
    builder.addCase(LoadAnswersAsync.rejected, (state, action) => {
      state.is_loading_answer = false;
    });
    builder.addCase(CreateAnswerAsync.pending, (state) => {
      state.is_creating_answer = true;
    });
    builder.addCase(CreateAnswerAsync.fulfilled, (state) => {
      state.is_creating_answer = false;
    });
    builder.addCase(CreateAnswerAsync.rejected, (state) => {
      state.is_creating_answer = false;
    });
    builder.addCase(DeleteAnswerAsync.pending, (state) => {
      state.is_deleting_answer = true;
    });
    builder.addCase(DeleteAnswerAsync.fulfilled, (state, action) => {
      state.is_deleting_answer = false;
      if (state.answer) {
        state.answer = state.answer.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteAnswerAsync.rejected, (state, action) => {
      state.is_deleting_answer = false;
    });
  }
});

function handleResult(action: any, state: answerSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_answer = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.answer = merge(state.answer, payload) as any;
    }
  }
}

export const { clearStateanswer, setSelectedanswer, set_answer, mergeanswer, add_answer } = answerSlice.actions;

export const get_answer =
  (id?: string) =>
  (state: {
    answer: {
      answer: Answer[];
    };
  }): Answer | null => {
    let temp = state?.answer?.answer?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.answer?.answer[0] || null;
  };
export const get_selected_answer = (state: { answer: answerSliceState }): Answer | null => {
  let id = state?.answer?.selected_answer;
  let temp = state?.answer?.answer?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_answer = (state: {
  answer: {
    answer: Answer[];
  };
}): Answer[] => {
  let temp = state?.answer?.answer;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_answer_busy = (state: { answer: answerSliceState }) => {
  return state?.answer.is_deleting_answer || state?.answer.is_loading_answer || state?.answer.is_creating_answer;
};
export const is_deleting_answer_busy = (state: { answer: answerSliceState }) => {
  return state?.answer.is_deleting_answer;
};
export const is_loading_answer_busy = (state: { answer: answerSliceState }) => {
  return state?.answer.is_loading_answer;
};
export const is_creating_answer_busy = (state: { answer: answerSliceState }) => {
  return state?.answer.is_creating_answer;
};

export default answerSlice.reducer;
