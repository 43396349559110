import { createAsyncThunk } from "@reduxjs/toolkit";
import ResourceBookPage from "./resource-book-page-model";
import { delete_resourceBookPage, load_resourceBookPages, store_resourceBookPage } from "./resourceBookPage-service";

export const LoadResourceBookPagesAsync = createAsyncThunk<any, any>("composer/load/resourceBookPage", async (params: { uid: string }) => {
  return await load_resourceBookPages(params);
});

export const CreateResourceBookPageAsync = createAsyncThunk<any, any>("composer/create/resourceBookPage", async (params: ResourceBookPage) => {
  return await store_resourceBookPage(params);
});

export const DeleteResourceBookPageAsync = createAsyncThunk<any, any>("composer/delete/resourceBookPage", async (params) => {
  return await delete_resourceBookPage(params);
});
