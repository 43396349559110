import { createAsyncThunk } from "@reduxjs/toolkit";
import Auction from "./auction-model";
import { delete_auction, load_auctions, store_auction } from "./auction-service";

export const LoadAuctionsAsync = createAsyncThunk<any, any>("composer/load/auction", async (params: { uid: string }) => {
  return await load_auctions(params);
});

export const CreateAuctionAsync = createAsyncThunk<any, any>("composer/create/auction", async (params: Auction) => {
  return await store_auction(params);
});

export const DeleteAuctionAsync = createAsyncThunk<any, any>("composer/delete/auction", async (params) => {
  return await delete_auction(params);
});
