import { createAsyncThunk } from "@reduxjs/toolkit";
import AuctionItem from "./auction-item-model";
import { delete_auctionItem, load_auctionItems, store_auctionItem } from "./auctionItem-service";

export const LoadAuctionItemsAsync = createAsyncThunk<any, any>("composer/load/auctionItem", async (params: { uid: string }) => {
  return await load_auctionItems(params);
});

export const CreateAuctionItemAsync = createAsyncThunk<any, any>("composer/create/auctionItem", async (params: AuctionItem) => {
  return await store_auctionItem(params);
});

export const DeleteAuctionItemAsync = createAsyncThunk<any, any>("composer/delete/auctionItem", async (params) => {
  return await delete_auctionItem(params);
});
