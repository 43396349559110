import RoomAgent from "./room-agent-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateRoomAgentAsync, DeleteRoomAgentAsync, LoadRoomAgentsAsync } from "./roomAgent-thunk";
const initialState: roomAgentSliceState = {
  roomAgent: [],
  selected_roomAgent: "",
  is_loading_roomAgent: false,
  is_creating_roomAgent: false,
  is_deleting_roomAgent: false
};
export interface roomAgentSliceState {
  roomAgent: RoomAgent[];
  selected_roomAgent?: string;
  is_loading_roomAgent: boolean;
  is_creating_roomAgent: boolean;
  is_deleting_roomAgent: boolean;
}
export const roomAgentSlice = createSlice({
  name: "roomAgent",
  initialState,
  reducers: {
    setSelectedroomAgent: (state, action) => {
      state.selected_roomAgent = action.payload;
    },
    clearStateroomAgent: (state, action) => {
      state.roomAgent = [];
      state.is_loading_roomAgent = false;
      state.is_creating_roomAgent = false;
      state.is_deleting_roomAgent = false;
    },
    mergeroomAgent: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_roomAgent = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.roomAgent = merge(state.roomAgent, payload) as any;
        }
      }
    },

    add_roomAgent: (state, action) => {
      if (action.payload) {
        state.roomAgent = [...state.roomAgent, action.payload];
      }
    },
    set_roomAgent: (state, action) => {
      let { key, value, id } = action.payload;
      state.roomAgent = state.roomAgent.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadRoomAgentsAsync.pending, (state) => {
      state.is_loading_roomAgent = true;
    });
    builder.addCase(LoadRoomAgentsAsync.fulfilled, (state, action) => {
      state.is_loading_roomAgent = false;
      if (action.payload) {
        state.roomAgent = action.payload;
      }
    });
    builder.addCase(LoadRoomAgentsAsync.rejected, (state, action) => {
      state.is_loading_roomAgent = false;
    });
    builder.addCase(CreateRoomAgentAsync.pending, (state) => {
      state.is_creating_roomAgent = true;
    });
    builder.addCase(CreateRoomAgentAsync.fulfilled, (state) => {
      state.is_creating_roomAgent = false;
    });
    builder.addCase(CreateRoomAgentAsync.rejected, (state) => {
      state.is_creating_roomAgent = false;
    });
    builder.addCase(DeleteRoomAgentAsync.pending, (state) => {
      state.is_deleting_roomAgent = true;
    });
    builder.addCase(DeleteRoomAgentAsync.fulfilled, (state, action) => {
      state.is_deleting_roomAgent = false;
      if (state.roomAgent) {
        state.roomAgent = state.roomAgent.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteRoomAgentAsync.rejected, (state, action) => {
      state.is_deleting_roomAgent = false;
    });
  }
});

function handleResult(action: any, state: roomAgentSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_roomAgent = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.roomAgent = merge(state.roomAgent, payload) as any;
    }
  }
}

export const { clearStateroomAgent, setSelectedroomAgent, set_roomAgent, mergeroomAgent, add_roomAgent } = roomAgentSlice.actions;

export const get_roomAgent =
  (id?: string) =>
  (state: {
    roomAgent: {
      roomAgent: RoomAgent[];
    };
  }): RoomAgent | null => {
    let temp = state?.roomAgent?.roomAgent?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.roomAgent?.roomAgent[0] || null;
  };
export const get_selected_roomAgent = (state: { roomAgent: roomAgentSliceState }): RoomAgent | null => {
  let id = state?.roomAgent?.selected_roomAgent;
  let temp = state?.roomAgent?.roomAgent?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_roomAgent = (state: {
  roomAgent: {
    roomAgent: RoomAgent[];
  };
}): RoomAgent[] => {
  let temp = state?.roomAgent?.roomAgent;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_roomAgent_busy = (state: { roomAgent: roomAgentSliceState }) => {
  return state?.roomAgent.is_deleting_roomAgent || state?.roomAgent.is_loading_roomAgent || state?.roomAgent.is_creating_roomAgent;
};
export const is_deleting_roomAgent_busy = (state: { roomAgent: roomAgentSliceState }) => {
  return state?.roomAgent.is_deleting_roomAgent;
};
export const is_loading_roomAgent_busy = (state: { roomAgent: roomAgentSliceState }) => {
  return state?.roomAgent.is_loading_roomAgent;
};
export const is_creating_roomAgent_busy = (state: { roomAgent: roomAgentSliceState }) => {
  return state?.roomAgent.is_creating_roomAgent;
};

export default roomAgentSlice.reducer;
