import { createAsyncThunk } from "@reduxjs/toolkit";
import BankOwner from "./bank-owner-model";
import { delete_bankOwner, load_bankOwners, store_bankOwner } from "./bankOwner-service";

export const LoadBankOwnersAsync = createAsyncThunk<any, any>("composer/load/bankOwner", async (params: { uid: string }) => {
  return await load_bankOwners(params);
});

export const CreateBankOwnerAsync = createAsyncThunk<any, any>("composer/create/bankOwner", async (params: BankOwner) => {
  return await store_bankOwner(params);
});

export const DeleteBankOwnerAsync = createAsyncThunk<any, any>("composer/delete/bankOwner", async (params) => {
  return await delete_bankOwner(params);
});
