export enum ResourceRegistrationProperties {
  ResourceType = "resourceType",
  Value = "value",
  Owner = "owner",
  Id = "id",
  Created = "created",
  Updated = "updated",
  Deleted = "deleted",
  Version = "version",
  DigitalAssetType = "digitalAssetType",
  Description = "description",
  Name = "name",
  BankId = "bankId",
  Reference = "reference",
  AccountId = "accountId"
}
export enum ResourceRegistrationMetaProperties {}
