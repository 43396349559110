import Director from "./director-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateDirectorAsync, DeleteDirectorAsync, LoadDirectorsAsync } from "./director-thunk";
const initialState: directorSliceState = {
  director: [],
  selected_director: "",
  is_loading_director: false,
  is_creating_director: false,
  is_deleting_director: false
};
export interface directorSliceState {
  director: Director[];
  selected_director?: string;
  is_loading_director: boolean;
  is_creating_director: boolean;
  is_deleting_director: boolean;
}
export const directorSlice = createSlice({
  name: "director",
  initialState,
  reducers: {
    setSelecteddirector: (state, action) => {
      state.selected_director = action.payload;
    },
    clearStatedirector: (state, action) => {
      state.director = [];
      state.is_loading_director = false;
      state.is_creating_director = false;
      state.is_deleting_director = false;
    },
    mergedirector: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_director = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.director = merge(state.director, payload) as any;
        }
      }
    },

    add_director: (state, action) => {
      if (action.payload) {
        state.director = [...state.director, action.payload];
      }
    },
    set_director: (state, action) => {
      let { key, value, id } = action.payload;
      state.director = state.director.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadDirectorsAsync.pending, (state) => {
      state.is_loading_director = true;
    });
    builder.addCase(LoadDirectorsAsync.fulfilled, (state, action) => {
      state.is_loading_director = false;
      if (action.payload) {
        state.director = action.payload;
      }
    });
    builder.addCase(LoadDirectorsAsync.rejected, (state, action) => {
      state.is_loading_director = false;
    });
    builder.addCase(CreateDirectorAsync.pending, (state) => {
      state.is_creating_director = true;
    });
    builder.addCase(CreateDirectorAsync.fulfilled, (state) => {
      state.is_creating_director = false;
    });
    builder.addCase(CreateDirectorAsync.rejected, (state) => {
      state.is_creating_director = false;
    });
    builder.addCase(DeleteDirectorAsync.pending, (state) => {
      state.is_deleting_director = true;
    });
    builder.addCase(DeleteDirectorAsync.fulfilled, (state, action) => {
      state.is_deleting_director = false;
      if (state.director) {
        state.director = state.director.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteDirectorAsync.rejected, (state, action) => {
      state.is_deleting_director = false;
    });
  }
});

function handleResult(action: any, state: directorSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_director = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.director = merge(state.director, payload) as any;
    }
  }
}

export const { clearStatedirector, setSelecteddirector, set_director, mergedirector, add_director } = directorSlice.actions;

export const get_director =
  (id?: string) =>
  (state: {
    director: {
      director: Director[];
    };
  }): Director | null => {
    let temp = state?.director?.director?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.director?.director[0] || null;
  };
export const get_selected_director = (state: { director: directorSliceState }): Director | null => {
  let id = state?.director?.selected_director;
  let temp = state?.director?.director?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_director = (state: {
  director: {
    director: Director[];
  };
}): Director[] => {
  let temp = state?.director?.director;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_director_busy = (state: { director: directorSliceState }) => {
  return state?.director.is_deleting_director || state?.director.is_loading_director || state?.director.is_creating_director;
};
export const is_deleting_director_busy = (state: { director: directorSliceState }) => {
  return state?.director.is_deleting_director;
};
export const is_loading_director_busy = (state: { director: directorSliceState }) => {
  return state?.director.is_loading_director;
};
export const is_creating_director_busy = (state: { director: directorSliceState }) => {
  return state?.director.is_creating_director;
};

export default directorSlice.reducer;
