import PublishCourseJob from "./publish-course-job-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreatePublishCourseJobAsync, DeletePublishCourseJobAsync, LoadPublishCourseJobsAsync } from "./publishCourseJob-thunk";
const initialState: publishCourseJobSliceState = {
  publishCourseJob: [],
  selected_publishCourseJob: "",
  is_loading_publishCourseJob: false,
  is_creating_publishCourseJob: false,
  is_deleting_publishCourseJob: false
};
export interface publishCourseJobSliceState {
  publishCourseJob: PublishCourseJob[];
  selected_publishCourseJob?: string;
  is_loading_publishCourseJob: boolean;
  is_creating_publishCourseJob: boolean;
  is_deleting_publishCourseJob: boolean;
}
export const publishCourseJobSlice = createSlice({
  name: "publishCourseJob",
  initialState,
  reducers: {
    setSelectedpublishCourseJob: (state, action) => {
      state.selected_publishCourseJob = action.payload;
    },
    clearStatepublishCourseJob: (state, action) => {
      state.publishCourseJob = [];
      state.is_loading_publishCourseJob = false;
      state.is_creating_publishCourseJob = false;
      state.is_deleting_publishCourseJob = false;
    },
    mergepublishCourseJob: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_publishCourseJob = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.publishCourseJob = merge(state.publishCourseJob, payload) as any;
        }
      }
    },

    add_publishCourseJob: (state, action) => {
      if (action.payload) {
        state.publishCourseJob = [...state.publishCourseJob, action.payload];
      }
    },
    set_publishCourseJob: (state, action) => {
      let { key, value, id } = action.payload;
      state.publishCourseJob = state.publishCourseJob.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadPublishCourseJobsAsync.pending, (state) => {
      state.is_loading_publishCourseJob = true;
    });
    builder.addCase(LoadPublishCourseJobsAsync.fulfilled, (state, action) => {
      state.is_loading_publishCourseJob = false;
      if (action.payload) {
        state.publishCourseJob = action.payload;
      }
    });
    builder.addCase(LoadPublishCourseJobsAsync.rejected, (state, action) => {
      state.is_loading_publishCourseJob = false;
    });
    builder.addCase(CreatePublishCourseJobAsync.pending, (state) => {
      state.is_creating_publishCourseJob = true;
    });
    builder.addCase(CreatePublishCourseJobAsync.fulfilled, (state) => {
      state.is_creating_publishCourseJob = false;
    });
    builder.addCase(CreatePublishCourseJobAsync.rejected, (state) => {
      state.is_creating_publishCourseJob = false;
    });
    builder.addCase(DeletePublishCourseJobAsync.pending, (state) => {
      state.is_deleting_publishCourseJob = true;
    });
    builder.addCase(DeletePublishCourseJobAsync.fulfilled, (state, action) => {
      state.is_deleting_publishCourseJob = false;
      if (state.publishCourseJob) {
        state.publishCourseJob = state.publishCourseJob.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeletePublishCourseJobAsync.rejected, (state, action) => {
      state.is_deleting_publishCourseJob = false;
    });

    builder.addCase(autoThunks.add_view_block_expansion_job.pending, (state) => {
      state.is_loading_publishCourseJob = true;
    });
    builder.addCase(autoThunks.add_view_block_expansion_job.fulfilled, (state, action) => {
      state.is_loading_publishCourseJob = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.add_view_block_expansion_job.rejected, (state, action) => {
      state.is_loading_publishCourseJob = false;
    });
  }
});

function handleResult(action: any, state: publishCourseJobSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_publishCourseJob = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.publishCourseJob = merge(state.publishCourseJob, payload) as any;
    }
  }
}

export const { clearStatepublishCourseJob, setSelectedpublishCourseJob, set_publishCourseJob, mergepublishCourseJob, add_publishCourseJob } =
  publishCourseJobSlice.actions;

export const get_publishCourseJob =
  (id?: string) =>
  (state: {
    publishCourseJob: {
      publishCourseJob: PublishCourseJob[];
    };
  }): PublishCourseJob | null => {
    let temp = state?.publishCourseJob?.publishCourseJob?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.publishCourseJob?.publishCourseJob[0] || null;
  };
export const get_selected_publishCourseJob = (state: { publishCourseJob: publishCourseJobSliceState }): PublishCourseJob | null => {
  let id = state?.publishCourseJob?.selected_publishCourseJob;
  let temp = state?.publishCourseJob?.publishCourseJob?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_publishCourseJob = (state: {
  publishCourseJob: {
    publishCourseJob: PublishCourseJob[];
  };
}): PublishCourseJob[] => {
  let temp = state?.publishCourseJob?.publishCourseJob;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_publishCourseJob_busy = (state: { publishCourseJob: publishCourseJobSliceState }) => {
  return (
    state?.publishCourseJob.is_deleting_publishCourseJob ||
    state?.publishCourseJob.is_loading_publishCourseJob ||
    state?.publishCourseJob.is_creating_publishCourseJob
  );
};
export const is_deleting_publishCourseJob_busy = (state: { publishCourseJob: publishCourseJobSliceState }) => {
  return state?.publishCourseJob.is_deleting_publishCourseJob;
};
export const is_loading_publishCourseJob_busy = (state: { publishCourseJob: publishCourseJobSliceState }) => {
  return state?.publishCourseJob.is_loading_publishCourseJob;
};
export const is_creating_publishCourseJob_busy = (state: { publishCourseJob: publishCourseJobSliceState }) => {
  return state?.publishCourseJob.is_creating_publishCourseJob;
};

export default publishCourseJobSlice.reducer;
