import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import BankCity from "./bank-city-model";
import { BankCityProperties, BankCityMetaProperties } from "./bankCity-property-definitions";

export async function load_bankCitys(args: { uid: string }): Promise<BankCity[]> {
  throw "Not implemented";
}

export async function delete_bankCity(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_bankCity(args: BankCity): Promise<BankCity> {
  throw "Not implemented";
}
