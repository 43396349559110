import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import VirtualShare from "./virtual-share-model";
import { VirtualShareProperties, VirtualShareMetaProperties } from "./virtualShare-property-definitions";

export async function load_virtualShares(args: { uid: string }): Promise<VirtualShare[]> {
  throw "Not implemented";
}

export async function delete_virtualShare(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_virtualShare(args: VirtualShare): Promise<VirtualShare> {
  throw "Not implemented";
}
