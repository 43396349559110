import { createAsyncThunk } from "@reduxjs/toolkit";
import TokenHouse from "./token-house-model";
import { delete_tokenHouse, load_tokenHouses, store_tokenHouse } from "./tokenHouse-service";

export const LoadTokenHousesAsync = createAsyncThunk<any, any>("composer/load/tokenHouse", async (params: { uid: string }) => {
  return await load_tokenHouses(params);
});

export const CreateTokenHouseAsync = createAsyncThunk<any, any>("composer/create/tokenHouse", async (params: TokenHouse) => {
  return await store_tokenHouse(params);
});

export const DeleteTokenHouseAsync = createAsyncThunk<any, any>("composer/delete/tokenHouse", async (params) => {
  return await delete_tokenHouse(params);
});
