import { createAsyncThunk } from "@reduxjs/toolkit";
import VirtualBusiness from "./virtual-business-model";
import { delete_virtualBusiness, load_virtualBusinesss, store_virtualBusiness } from "./virtualBusiness-service";

export const LoadVirtualBusinesssAsync = createAsyncThunk<any, any>("composer/load/virtualBusiness", async (params: { uid: string }) => {
  return await load_virtualBusinesss(params);
});

export const CreateVirtualBusinessAsync = createAsyncThunk<any, any>("composer/create/virtualBusiness", async (params: VirtualBusiness) => {
  return await store_virtualBusiness(params);
});

export const DeleteVirtualBusinessAsync = createAsyncThunk<any, any>("composer/delete/virtualBusiness", async (params) => {
  return await delete_virtualBusiness(params);
});
