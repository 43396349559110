import GeographicalRegion from "./geographical-region-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateGeographicalRegionAsync, DeleteGeographicalRegionAsync, LoadGeographicalRegionsAsync } from "./geographicalRegion-thunk";
const initialState: geographicalRegionSliceState = {
  geographicalRegion: [],
  selected_geographicalRegion: "",
  is_loading_geographicalRegion: false,
  is_creating_geographicalRegion: false,
  is_deleting_geographicalRegion: false
};
export interface geographicalRegionSliceState {
  geographicalRegion: GeographicalRegion[];
  selected_geographicalRegion?: string;
  is_loading_geographicalRegion: boolean;
  is_creating_geographicalRegion: boolean;
  is_deleting_geographicalRegion: boolean;
}
export const geographicalRegionSlice = createSlice({
  name: "geographicalRegion",
  initialState,
  reducers: {
    setSelectedgeographicalRegion: (state, action) => {
      state.selected_geographicalRegion = action.payload;
    },
    clearStategeographicalRegion: (state, action) => {
      state.geographicalRegion = [];
      state.is_loading_geographicalRegion = false;
      state.is_creating_geographicalRegion = false;
      state.is_deleting_geographicalRegion = false;
    },
    mergegeographicalRegion: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_geographicalRegion = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.geographicalRegion = merge(state.geographicalRegion, payload) as any;
        }
      }
    },

    add_geographicalRegion: (state, action) => {
      if (action.payload) {
        state.geographicalRegion = [...state.geographicalRegion, action.payload];
      }
    },
    set_geographicalRegion: (state, action) => {
      let { key, value, id } = action.payload;
      state.geographicalRegion = state.geographicalRegion.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadGeographicalRegionsAsync.pending, (state) => {
      state.is_loading_geographicalRegion = true;
    });
    builder.addCase(LoadGeographicalRegionsAsync.fulfilled, (state, action) => {
      state.is_loading_geographicalRegion = false;
      if (action.payload) {
        state.geographicalRegion = action.payload;
      }
    });
    builder.addCase(LoadGeographicalRegionsAsync.rejected, (state, action) => {
      state.is_loading_geographicalRegion = false;
    });
    builder.addCase(CreateGeographicalRegionAsync.pending, (state) => {
      state.is_creating_geographicalRegion = true;
    });
    builder.addCase(CreateGeographicalRegionAsync.fulfilled, (state) => {
      state.is_creating_geographicalRegion = false;
    });
    builder.addCase(CreateGeographicalRegionAsync.rejected, (state) => {
      state.is_creating_geographicalRegion = false;
    });
    builder.addCase(DeleteGeographicalRegionAsync.pending, (state) => {
      state.is_deleting_geographicalRegion = true;
    });
    builder.addCase(DeleteGeographicalRegionAsync.fulfilled, (state, action) => {
      state.is_deleting_geographicalRegion = false;
      if (state.geographicalRegion) {
        state.geographicalRegion = state.geographicalRegion.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteGeographicalRegionAsync.rejected, (state, action) => {
      state.is_deleting_geographicalRegion = false;
    });
  }
});

function handleResult(action: any, state: geographicalRegionSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_geographicalRegion = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.geographicalRegion = merge(state.geographicalRegion, payload) as any;
    }
  }
}

export const { clearStategeographicalRegion, setSelectedgeographicalRegion, set_geographicalRegion, mergegeographicalRegion, add_geographicalRegion } =
  geographicalRegionSlice.actions;

export const get_geographicalRegion =
  (id?: string) =>
  (state: {
    geographicalRegion: {
      geographicalRegion: GeographicalRegion[];
    };
  }): GeographicalRegion | null => {
    let temp = state?.geographicalRegion?.geographicalRegion?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.geographicalRegion?.geographicalRegion[0] || null;
  };
export const get_selected_geographicalRegion = (state: { geographicalRegion: geographicalRegionSliceState }): GeographicalRegion | null => {
  let id = state?.geographicalRegion?.selected_geographicalRegion;
  let temp = state?.geographicalRegion?.geographicalRegion?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_geographicalRegion = (state: {
  geographicalRegion: {
    geographicalRegion: GeographicalRegion[];
  };
}): GeographicalRegion[] => {
  let temp = state?.geographicalRegion?.geographicalRegion;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_geographicalRegion_busy = (state: { geographicalRegion: geographicalRegionSliceState }) => {
  return (
    state?.geographicalRegion.is_deleting_geographicalRegion ||
    state?.geographicalRegion.is_loading_geographicalRegion ||
    state?.geographicalRegion.is_creating_geographicalRegion
  );
};
export const is_deleting_geographicalRegion_busy = (state: { geographicalRegion: geographicalRegionSliceState }) => {
  return state?.geographicalRegion.is_deleting_geographicalRegion;
};
export const is_loading_geographicalRegion_busy = (state: { geographicalRegion: geographicalRegionSliceState }) => {
  return state?.geographicalRegion.is_loading_geographicalRegion;
};
export const is_creating_geographicalRegion_busy = (state: { geographicalRegion: geographicalRegionSliceState }) => {
  return state?.geographicalRegion.is_creating_geographicalRegion;
};

export default geographicalRegionSlice.reducer;
