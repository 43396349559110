import { useEffect, DependencyList } from 'react';

type AsyncEffectCallback = () => Promise<void>;

const useAsyncEffect = (effect: AsyncEffectCallback, deps?: DependencyList, delay = 0): void => {
    useEffect(() => {
        let isMounted = true;
        let isRunning = false;

        const cleanup = (): void => {
            isMounted = false;
        };

        const runEffect = async (): Promise<void> => {
            if (!isRunning) {
                isRunning = true;
                await effect();
                if (isMounted) {
                    isRunning = false;
                    cleanup();
                }
            }
        };

        const handleThrottledEffect = (): void => {
            if (delay > 0) {
                setTimeout(runEffect, delay);
            } else {
                runEffect();
            }
        };

        handleThrottledEffect();

        return cleanup;
    }, deps);
};

export default useAsyncEffect;
