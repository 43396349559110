import VirtualBusiness from "./virtual-business-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateVirtualBusinessAsync, DeleteVirtualBusinessAsync, LoadVirtualBusinesssAsync } from "./virtualBusiness-thunk";
const initialState: virtualBusinessSliceState = {
  virtualBusiness: [],
  selected_virtualBusiness: "",
  is_loading_virtualBusiness: false,
  is_creating_virtualBusiness: false,
  is_deleting_virtualBusiness: false
};
export interface virtualBusinessSliceState {
  virtualBusiness: VirtualBusiness[];
  selected_virtualBusiness?: string;
  is_loading_virtualBusiness: boolean;
  is_creating_virtualBusiness: boolean;
  is_deleting_virtualBusiness: boolean;
}
export const virtualBusinessSlice = createSlice({
  name: "virtualBusiness",
  initialState,
  reducers: {
    setSelectedvirtualBusiness: (state, action) => {
      state.selected_virtualBusiness = action.payload;
    },
    clearStatevirtualBusiness: (state, action) => {
      state.virtualBusiness = [];
      state.is_loading_virtualBusiness = false;
      state.is_creating_virtualBusiness = false;
      state.is_deleting_virtualBusiness = false;
    },
    mergevirtualBusiness: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_virtualBusiness = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.virtualBusiness = merge(state.virtualBusiness, payload) as any;
        }
      }
    },

    add_virtualBusiness: (state, action) => {
      if (action.payload) {
        state.virtualBusiness = [...state.virtualBusiness, action.payload];
      }
    },
    set_virtualBusiness: (state, action) => {
      let { key, value, id } = action.payload;
      state.virtualBusiness = state.virtualBusiness.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadVirtualBusinesssAsync.pending, (state) => {
      state.is_loading_virtualBusiness = true;
    });
    builder.addCase(LoadVirtualBusinesssAsync.fulfilled, (state, action) => {
      state.is_loading_virtualBusiness = false;
      if (action.payload) {
        state.virtualBusiness = action.payload;
      }
    });
    builder.addCase(LoadVirtualBusinesssAsync.rejected, (state, action) => {
      state.is_loading_virtualBusiness = false;
    });
    builder.addCase(CreateVirtualBusinessAsync.pending, (state) => {
      state.is_creating_virtualBusiness = true;
    });
    builder.addCase(CreateVirtualBusinessAsync.fulfilled, (state) => {
      state.is_creating_virtualBusiness = false;
    });
    builder.addCase(CreateVirtualBusinessAsync.rejected, (state) => {
      state.is_creating_virtualBusiness = false;
    });
    builder.addCase(DeleteVirtualBusinessAsync.pending, (state) => {
      state.is_deleting_virtualBusiness = true;
    });
    builder.addCase(DeleteVirtualBusinessAsync.fulfilled, (state, action) => {
      state.is_deleting_virtualBusiness = false;
      if (state.virtualBusiness) {
        state.virtualBusiness = state.virtualBusiness.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteVirtualBusinessAsync.rejected, (state, action) => {
      state.is_deleting_virtualBusiness = false;
    });

    builder.addCase(autoThunks.get_my_virtual_businesses.pending, (state) => {
      state.is_loading_virtualBusiness = true;
    });
    builder.addCase(autoThunks.get_my_virtual_businesses.fulfilled, (state, action) => {
      state.is_loading_virtualBusiness = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_my_virtual_businesses.rejected, (state, action) => {
      state.is_loading_virtualBusiness = false;
    });

    builder.addCase(autoThunks.get_my_virtual_references.pending, (state) => {
      state.is_loading_virtualBusiness = true;
    });
    builder.addCase(autoThunks.get_my_virtual_references.fulfilled, (state, action) => {
      state.is_loading_virtualBusiness = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_my_virtual_references.rejected, (state, action) => {
      state.is_loading_virtualBusiness = false;
    });

    builder.addCase(autoThunks.get_virtual_businesses_for_account.pending, (state) => {
      state.is_loading_virtualBusiness = true;
    });
    builder.addCase(autoThunks.get_virtual_businesses_for_account.fulfilled, (state, action) => {
      state.is_loading_virtualBusiness = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_virtual_businesses_for_account.rejected, (state, action) => {
      state.is_loading_virtualBusiness = false;
    });

    builder.addCase(autoThunks.get_virtual_business_for_account.pending, (state) => {
      state.is_loading_virtualBusiness = true;
    });
    builder.addCase(autoThunks.get_virtual_business_for_account.fulfilled, (state, action) => {
      state.is_loading_virtualBusiness = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_virtual_business_for_account.rejected, (state, action) => {
      state.is_loading_virtualBusiness = false;
    });

    builder.addCase(autoThunks.get_virtual_business_by_id.pending, (state) => {
      state.is_loading_virtualBusiness = true;
    });
    builder.addCase(autoThunks.get_virtual_business_by_id.fulfilled, (state, action) => {
      state.is_loading_virtualBusiness = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_virtual_business_by_id.rejected, (state, action) => {
      state.is_loading_virtualBusiness = false;
    });

    builder.addCase(autoThunks.fetch_my_virtual_business_interests.pending, (state) => {
      state.is_loading_virtualBusiness = true;
    });
    builder.addCase(autoThunks.fetch_my_virtual_business_interests.fulfilled, (state, action) => {
      state.is_loading_virtualBusiness = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.fetch_my_virtual_business_interests.rejected, (state, action) => {
      state.is_loading_virtualBusiness = false;
    });

    builder.addCase(autoThunks.get_virtual_businesses_for_banks.pending, (state) => {
      state.is_loading_virtualBusiness = true;
    });
    builder.addCase(autoThunks.get_virtual_businesses_for_banks.fulfilled, (state, action) => {
      state.is_loading_virtualBusiness = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_virtual_businesses_for_banks.rejected, (state, action) => {
      state.is_loading_virtualBusiness = false;
    });

    builder.addCase(autoThunks.get_virtual_businesses_for_bank.pending, (state) => {
      state.is_loading_virtualBusiness = true;
    });
    builder.addCase(autoThunks.get_virtual_businesses_for_bank.fulfilled, (state, action) => {
      state.is_loading_virtualBusiness = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_virtual_businesses_for_bank.rejected, (state, action) => {
      state.is_loading_virtualBusiness = false;
    });
  }
});

function handleResult(action: any, state: virtualBusinessSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_virtualBusiness = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.virtualBusiness = merge(state.virtualBusiness, payload) as any;
    }
  }
}

export const { clearStatevirtualBusiness, setSelectedvirtualBusiness, set_virtualBusiness, mergevirtualBusiness, add_virtualBusiness } =
  virtualBusinessSlice.actions;

export const get_virtualBusiness =
  (id?: string) =>
  (state: {
    virtualBusiness: {
      virtualBusiness: VirtualBusiness[];
    };
  }): VirtualBusiness | null => {
    let temp = state?.virtualBusiness?.virtualBusiness?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.virtualBusiness?.virtualBusiness[0] || null;
  };
export const get_selected_virtualBusiness = (state: { virtualBusiness: virtualBusinessSliceState }): VirtualBusiness | null => {
  let id = state?.virtualBusiness?.selected_virtualBusiness;
  let temp = state?.virtualBusiness?.virtualBusiness?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_virtualBusiness = (state: {
  virtualBusiness: {
    virtualBusiness: VirtualBusiness[];
  };
}): VirtualBusiness[] => {
  let temp = state?.virtualBusiness?.virtualBusiness;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_virtualBusiness_busy = (state: { virtualBusiness: virtualBusinessSliceState }) => {
  return (
    state?.virtualBusiness.is_deleting_virtualBusiness ||
    state?.virtualBusiness.is_loading_virtualBusiness ||
    state?.virtualBusiness.is_creating_virtualBusiness
  );
};
export const is_deleting_virtualBusiness_busy = (state: { virtualBusiness: virtualBusinessSliceState }) => {
  return state?.virtualBusiness.is_deleting_virtualBusiness;
};
export const is_loading_virtualBusiness_busy = (state: { virtualBusiness: virtualBusinessSliceState }) => {
  return state?.virtualBusiness.is_loading_virtualBusiness;
};
export const is_creating_virtualBusiness_busy = (state: { virtualBusiness: virtualBusinessSliceState }) => {
  return state?.virtualBusiness.is_creating_virtualBusiness;
};

export default virtualBusinessSlice.reducer;
