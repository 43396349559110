import { createAsyncThunk } from "@reduxjs/toolkit";
import Customer from "./customer-model";
import { delete_customer, load_customers, store_customer } from "./customer-service";

export const LoadCustomersAsync = createAsyncThunk<any, any>("composer/load/customer", async (params: { uid: string }) => {
  return await load_customers(params);
});

export const CreateCustomerAsync = createAsyncThunk<any, any>("composer/create/customer", async (params: Customer) => {
  return await store_customer(params);
});

export const DeleteCustomerAsync = createAsyncThunk<any, any>("composer/delete/customer", async (params) => {
  return await delete_customer(params);
});
