import { createAsyncThunk } from "@reduxjs/toolkit";
import ResourceImport from "./resource-import-model";
import { delete_resourceImport, load_resourceImports, store_resourceImport } from "./resourceImport-service";

export const LoadResourceImportsAsync = createAsyncThunk<any, any>("composer/load/resourceImport", async (params: { uid: string }) => {
  return await load_resourceImports(params);
});

export const CreateResourceImportAsync = createAsyncThunk<any, any>("composer/create/resourceImport", async (params: ResourceImport) => {
  return await store_resourceImport(params);
});

export const DeleteResourceImportAsync = createAsyncThunk<any, any>("composer/delete/resourceImport", async (params) => {
  return await delete_resourceImport(params);
});
