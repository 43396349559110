import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import VirtualBusinessRequirement from "./virtual-business-requirement-model";
import { VirtualBusinessRequirementProperties, VirtualBusinessRequirementMetaProperties } from "./virtualBusinessRequirement-property-definitions";

export async function load_virtualBusinessRequirements(args: { uid: string }): Promise<VirtualBusinessRequirement[]> {
  throw "Not implemented";
}

export async function delete_virtualBusinessRequirement(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_virtualBusinessRequirement(args: VirtualBusinessRequirement): Promise<VirtualBusinessRequirement> {
  throw "Not implemented";
}
