import { createAsyncThunk } from "@reduxjs/toolkit";
import VirtualShare from "./virtual-share-model";
import { delete_virtualShare, load_virtualShares, store_virtualShare } from "./virtualShare-service";

export const LoadVirtualSharesAsync = createAsyncThunk<any, any>("composer/load/virtualShare", async (params: { uid: string }) => {
  return await load_virtualShares(params);
});

export const CreateVirtualShareAsync = createAsyncThunk<any, any>("composer/create/virtualShare", async (params: VirtualShare) => {
  return await store_virtualShare(params);
});

export const DeleteVirtualShareAsync = createAsyncThunk<any, any>("composer/delete/virtualShare", async (params) => {
  return await delete_virtualShare(params);
});
