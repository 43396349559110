import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResolveUrl, UpdateClaims } from "features/firebase-app";
import {
  DigitalAssetInstance,
  User,
  DigitalAssetInstanceProperties,
  AccountProperties,
  AuctionProperties,
  AuctionItemProperties,
  DigitalAsset,
  SalesOrder,
  SalesOrderProperties,
  TransactionProperties,
  Account,
  Customer,
  VotingIssue,
  VotingIssueProperties,
  VotingBallot,
  VotingBallotProperties
} from "models";
import { getBearerToken } from "../../application/application-slice";

export const get_my_digital_assets = createAsyncThunk<any, any, any>("auto/get/my/digital/assets", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/my/digital/assets"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }
      await UpdateClaims();
      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_digital_asset_definition = createAsyncThunk<any, any, any>(
  "auto/get/digital/asset/definition",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/get/digital/asset/definition"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }
        await UpdateClaims();
        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_my_sales_orders = createAsyncThunk<any, any, any>("auto/get/my/sales/orders", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/my/sales/orders"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }
      await UpdateClaims();
      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const store_sales_order = createAsyncThunk<any, any, any>("auto/store/sales/orders", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/store/sales/orders"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const purchase_sales_order = createAsyncThunk<any, any, any>("auto/purchase/sales/orders", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/purchase/sales/orders"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }
      await UpdateClaims();
      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_sales_order = createAsyncThunk<any, any, any>("auto/get/sales/order", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/sales/order"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }
      await UpdateClaims();
      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const delete_sales_order = createAsyncThunk<any, any, any>("auto/delete/sales/orders", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/delete/sales/orders"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }
      await UpdateClaims();
      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_digital_asset = createAsyncThunk<any, any, any>("auto/get/digital/asset", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/digital/asset"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }
      await UpdateClaims();
      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_purchaser_account = createAsyncThunk<any, any, any>("auto/get/purchaser/account", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/purchaser/account"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }
      await UpdateClaims();
      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_customer = createAsyncThunk<any, any, any>("auto/get/customer", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/customer"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }
      await UpdateClaims();
      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_voting_issues = createAsyncThunk<any, any, any>("auto/get/voting/issues", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/voting/issues"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }
      await UpdateClaims();
      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_voting_issue_ballots = createAsyncThunk<any, any, any>("auto/get/voting/issue/ballots", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/voting/issue/ballots"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }
      await UpdateClaims();
      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const apply_vote_ballot_to_issue = createAsyncThunk<any, any, any>("auto/apply/vote/ballot/to/issue", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/apply/vote/ballot/to/issue"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }
      await UpdateClaims();
      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});
