import Enrollment from "./enrollment-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateEnrollmentAsync, DeleteEnrollmentAsync, LoadEnrollmentsAsync } from "./enrollment-thunk";
const initialState: enrollmentSliceState = {
  enrollment: [],
  selected_enrollment: "",
  is_loading_enrollment: false,
  is_creating_enrollment: false,
  is_deleting_enrollment: false
};
export interface enrollmentSliceState {
  enrollment: Enrollment[];
  selected_enrollment?: string;
  is_loading_enrollment: boolean;
  is_creating_enrollment: boolean;
  is_deleting_enrollment: boolean;
}
export const enrollmentSlice = createSlice({
  name: "enrollment",
  initialState,
  reducers: {
    setSelectedenrollment: (state, action) => {
      state.selected_enrollment = action.payload;
    },
    clearStateenrollment: (state, action) => {
      state.enrollment = [];
      state.is_loading_enrollment = false;
      state.is_creating_enrollment = false;
      state.is_deleting_enrollment = false;
    },
    mergeenrollment: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_enrollment = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.enrollment = merge(state.enrollment, payload) as any;
        }
      }
    },

    add_enrollment: (state, action) => {
      if (action.payload) {
        state.enrollment = [...state.enrollment, action.payload];
      }
    },
    set_enrollment: (state, action) => {
      let { key, value, id } = action.payload;
      state.enrollment = state.enrollment.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadEnrollmentsAsync.pending, (state) => {
      state.is_loading_enrollment = true;
    });
    builder.addCase(LoadEnrollmentsAsync.fulfilled, (state, action) => {
      state.is_loading_enrollment = false;
      if (action.payload) {
        state.enrollment = action.payload;
      }
    });
    builder.addCase(LoadEnrollmentsAsync.rejected, (state, action) => {
      state.is_loading_enrollment = false;
    });
    builder.addCase(CreateEnrollmentAsync.pending, (state) => {
      state.is_creating_enrollment = true;
    });
    builder.addCase(CreateEnrollmentAsync.fulfilled, (state) => {
      state.is_creating_enrollment = false;
    });
    builder.addCase(CreateEnrollmentAsync.rejected, (state) => {
      state.is_creating_enrollment = false;
    });
    builder.addCase(DeleteEnrollmentAsync.pending, (state) => {
      state.is_deleting_enrollment = true;
    });
    builder.addCase(DeleteEnrollmentAsync.fulfilled, (state, action) => {
      state.is_deleting_enrollment = false;
      if (state.enrollment) {
        state.enrollment = state.enrollment.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteEnrollmentAsync.rejected, (state, action) => {
      state.is_deleting_enrollment = false;
    });

    builder.addCase(autoThunks.get_my_enrollments.pending, (state) => {
      state.is_loading_enrollment = true;
    });
    builder.addCase(autoThunks.get_my_enrollments.fulfilled, (state, action) => {
      state.is_loading_enrollment = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_my_enrollments.rejected, (state, action) => {
      state.is_loading_enrollment = false;
    });

    builder.addCase(autoThunks.add_my_enrollments.pending, (state) => {
      state.is_loading_enrollment = true;
    });
    builder.addCase(autoThunks.add_my_enrollments.fulfilled, (state, action) => {
      state.is_loading_enrollment = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.add_my_enrollments.rejected, (state, action) => {
      state.is_loading_enrollment = false;
    });

    builder.addCase(autoThunks.remove_my_enrollment.pending, (state) => {
      state.is_loading_enrollment = true;
    });
    builder.addCase(autoThunks.remove_my_enrollment.fulfilled, (state, action) => {
      state.is_loading_enrollment = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.remove_my_enrollment.rejected, (state, action) => {
      state.is_loading_enrollment = false;
    });
  }
});

function handleResult(action: any, state: enrollmentSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_enrollment = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.enrollment = merge(state.enrollment, payload) as any;
    }
  }
}

export const { clearStateenrollment, setSelectedenrollment, set_enrollment, mergeenrollment, add_enrollment } = enrollmentSlice.actions;

export const get_enrollment =
  (id?: string) =>
  (state: {
    enrollment: {
      enrollment: Enrollment[];
    };
  }): Enrollment | null => {
    let temp = state?.enrollment?.enrollment?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.enrollment?.enrollment[0] || null;
  };
export const get_selected_enrollment = (state: { enrollment: enrollmentSliceState }): Enrollment | null => {
  let id = state?.enrollment?.selected_enrollment;
  let temp = state?.enrollment?.enrollment?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_enrollment = (state: {
  enrollment: {
    enrollment: Enrollment[];
  };
}): Enrollment[] => {
  let temp = state?.enrollment?.enrollment;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_enrollment_busy = (state: { enrollment: enrollmentSliceState }) => {
  return state?.enrollment.is_deleting_enrollment || state?.enrollment.is_loading_enrollment || state?.enrollment.is_creating_enrollment;
};
export const is_deleting_enrollment_busy = (state: { enrollment: enrollmentSliceState }) => {
  return state?.enrollment.is_deleting_enrollment;
};
export const is_loading_enrollment_busy = (state: { enrollment: enrollmentSliceState }) => {
  return state?.enrollment.is_loading_enrollment;
};
export const is_creating_enrollment_busy = (state: { enrollment: enrollmentSliceState }) => {
  return state?.enrollment.is_creating_enrollment;
};

export default enrollmentSlice.reducer;
