import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import VirtualBusinessPartners from "./virtual-business-partners-model";
import { VirtualBusinessPartnersProperties, VirtualBusinessPartnersMetaProperties } from "./virtualBusinessPartners-property-definitions";

export async function load_virtualBusinessPartnerss(args: { uid: string }): Promise<VirtualBusinessPartners[]> {
  throw "Not implemented";
}

export async function delete_virtualBusinessPartners(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_virtualBusinessPartners(args: VirtualBusinessPartners): Promise<VirtualBusinessPartners> {
  throw "Not implemented";
}
