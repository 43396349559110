import { useRoutes } from 'react-router-dom';
import router from 'router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { FirebaseProvider } from 'contexts/FirebaseContext';
import { ViewProvider } from 'hooks/view_context';

function App() {
  const content = useRoutes(router);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <FirebaseProvider>
          <ViewProvider>
            {content}
          </ViewProvider>
        </FirebaseProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
((obj: any) => {
  if (!obj.unique) {
    Object.defineProperty(Array.prototype, "unique", {
      enumerable: false,
      writable: true,
      configurable: true,
      value: function (func: any) {
        var result = [];
        var finalresult = [];
        func =
          func ||
          function (x: any) {
            return x;
          };
        var collection = this;
        for (var i = 0; i < collection.length; i++) {
          if (result.indexOf(func(collection[i])) === -1) {
            result.push(func(collection[i]));
            finalresult.push(collection[i]);
          }
        }
        return finalresult;
      }
    });
  }

  if (!obj.interpolate) {
    Object.defineProperty(obj, "interpolate", {
      enumerable: false,
      writable: true,
      configurable: true,
      value: function (start: any, stop: any, func: any) {
        var collection = this;
        func =
          func ||
          function (x: any) {
            return x;
          };
        for (var i = start; i < stop; i++) {
          if (collection instanceof Float32Array) {
            collection[i - start] = func(i);
          } else collection.push(func(i, i - start));
        }
        return collection;
      }
    });
  }
})(Array.prototype);