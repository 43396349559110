import { createAsyncThunk } from "@reduxjs/toolkit";
import VirtualBusinessBalanceChange from "./virtual-business-balance-change-model";
import {
  delete_virtualBusinessBalanceChange,
  load_virtualBusinessBalanceChanges,
  store_virtualBusinessBalanceChange
} from "./virtualBusinessBalanceChange-service";

export const LoadVirtualBusinessBalanceChangesAsync = createAsyncThunk<any, any>(
  "composer/load/virtualBusinessBalanceChange",
  async (params: { uid: string }) => {
    return await load_virtualBusinessBalanceChanges(params);
  }
);

export const CreateVirtualBusinessBalanceChangeAsync = createAsyncThunk<any, any>(
  "composer/create/virtualBusinessBalanceChange",
  async (params: VirtualBusinessBalanceChange) => {
    return await store_virtualBusinessBalanceChange(params);
  }
);

export const DeleteVirtualBusinessBalanceChangeAsync = createAsyncThunk<any, any>("composer/delete/virtualBusinessBalanceChange", async (params) => {
  return await delete_virtualBusinessBalanceChange(params);
});
