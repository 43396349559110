import { createAsyncThunk } from "@reduxjs/toolkit";
import Holding from "./holding-model";
import { delete_holding, load_holdings, store_holding } from "./holding-service";

export const LoadHoldingsAsync = createAsyncThunk<any, any>("composer/load/holding", async (params: { uid: string }) => {
  return await load_holdings(params);
});

export const CreateHoldingAsync = createAsyncThunk<any, any>("composer/create/holding", async (params: Holding) => {
  return await store_holding(params);
});

export const DeleteHoldingAsync = createAsyncThunk<any, any>("composer/delete/holding", async (params) => {
  return await delete_holding(params);
});
