import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import IndividualInvestmentHolding from "./individual-investment-holding-model";
import { IndividualInvestmentHoldingProperties, IndividualInvestmentHoldingMetaProperties } from "./individualInvestmentHolding-property-definitions";

export async function load_individualInvestmentHoldings(args: { uid: string }): Promise<IndividualInvestmentHolding[]> {
  throw "Not implemented";
}

export async function delete_individualInvestmentHolding(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_individualInvestmentHolding(args: IndividualInvestmentHolding): Promise<IndividualInvestmentHolding> {
  throw "Not implemented";
}
