import { createAsyncThunk } from "@reduxjs/toolkit";
import CourseSorting from "./course-sorting-model";
import { delete_courseSorting, load_courseSortings, store_courseSorting } from "./courseSorting-service";

export const LoadCourseSortingsAsync = createAsyncThunk<any, any>("composer/load/courseSorting", async (params: { uid: string }) => {
  return await load_courseSortings(params);
});

export const CreateCourseSortingAsync = createAsyncThunk<any, any>("composer/create/courseSorting", async (params: CourseSorting) => {
  return await store_courseSorting(params);
});

export const DeleteCourseSortingAsync = createAsyncThunk<any, any>("composer/delete/courseSorting", async (params) => {
  return await delete_courseSorting(params);
});
