import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResolveUrl, UpdateClaims } from "features/firebase-app";
import { CoursePay, User, CourseProperties } from "models";
import { getBearerToken } from "../../application/application-slice";

export const get_course_paid = createAsyncThunk<any, any, any>("auto/get/course/paid", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/course/paid"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_course_paids = createAsyncThunk<any, any, any>("auto/get/course/paids", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/course/paids"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});
