import { createAsyncThunk } from "@reduxjs/toolkit";
import ViewBlockSummary from "./view-block-summary-model";
import { delete_viewBlockSummary, load_viewBlockSummarys, store_viewBlockSummary } from "./viewBlockSummary-service";

export const LoadViewBlockSummarysAsync = createAsyncThunk<any, any>("composer/load/viewBlockSummary", async (params: { uid: string }) => {
  return await load_viewBlockSummarys(params);
});

export const CreateViewBlockSummaryAsync = createAsyncThunk<any, any>("composer/create/viewBlockSummary", async (params: ViewBlockSummary) => {
  return await store_viewBlockSummary(params);
});

export const DeleteViewBlockSummaryAsync = createAsyncThunk<any, any>("composer/delete/viewBlockSummary", async (params) => {
  return await delete_viewBlockSummary(params);
});
