import BusinessAccount from "./business-account-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateBusinessAccountAsync, DeleteBusinessAccountAsync, LoadBusinessAccountsAsync } from "./businessAccount-thunk";
const initialState: businessAccountSliceState = {
  businessAccount: [],
  selected_businessAccount: "",
  is_loading_businessAccount: false,
  is_creating_businessAccount: false,
  is_deleting_businessAccount: false
};
export interface businessAccountSliceState {
  businessAccount: BusinessAccount[];
  selected_businessAccount?: string;
  is_loading_businessAccount: boolean;
  is_creating_businessAccount: boolean;
  is_deleting_businessAccount: boolean;
}
export const businessAccountSlice = createSlice({
  name: "businessAccount",
  initialState,
  reducers: {
    setSelectedbusinessAccount: (state, action) => {
      state.selected_businessAccount = action.payload;
    },
    clearStatebusinessAccount: (state, action) => {
      state.businessAccount = [];
      state.is_loading_businessAccount = false;
      state.is_creating_businessAccount = false;
      state.is_deleting_businessAccount = false;
    },
    mergebusinessAccount: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_businessAccount = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.businessAccount = merge(state.businessAccount, payload) as any;
        }
      }
    },

    add_businessAccount: (state, action) => {
      if (action.payload) {
        state.businessAccount = [...state.businessAccount, action.payload];
      }
    },
    set_businessAccount: (state, action) => {
      let { key, value, id } = action.payload;
      state.businessAccount = state.businessAccount.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadBusinessAccountsAsync.pending, (state) => {
      state.is_loading_businessAccount = true;
    });
    builder.addCase(LoadBusinessAccountsAsync.fulfilled, (state, action) => {
      state.is_loading_businessAccount = false;
      if (action.payload) {
        state.businessAccount = action.payload;
      }
    });
    builder.addCase(LoadBusinessAccountsAsync.rejected, (state, action) => {
      state.is_loading_businessAccount = false;
    });
    builder.addCase(CreateBusinessAccountAsync.pending, (state) => {
      state.is_creating_businessAccount = true;
    });
    builder.addCase(CreateBusinessAccountAsync.fulfilled, (state) => {
      state.is_creating_businessAccount = false;
    });
    builder.addCase(CreateBusinessAccountAsync.rejected, (state) => {
      state.is_creating_businessAccount = false;
    });
    builder.addCase(DeleteBusinessAccountAsync.pending, (state) => {
      state.is_deleting_businessAccount = true;
    });
    builder.addCase(DeleteBusinessAccountAsync.fulfilled, (state, action) => {
      state.is_deleting_businessAccount = false;
      if (state.businessAccount) {
        state.businessAccount = state.businessAccount.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteBusinessAccountAsync.rejected, (state, action) => {
      state.is_deleting_businessAccount = false;
    });
  }
});

function handleResult(action: any, state: businessAccountSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_businessAccount = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.businessAccount = merge(state.businessAccount, payload) as any;
    }
  }
}

export const { clearStatebusinessAccount, setSelectedbusinessAccount, set_businessAccount, mergebusinessAccount, add_businessAccount } =
  businessAccountSlice.actions;

export const get_businessAccount =
  (id?: string) =>
  (state: {
    businessAccount: {
      businessAccount: BusinessAccount[];
    };
  }): BusinessAccount | null => {
    let temp = state?.businessAccount?.businessAccount?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.businessAccount?.businessAccount[0] || null;
  };
export const get_selected_businessAccount = (state: { businessAccount: businessAccountSliceState }): BusinessAccount | null => {
  let id = state?.businessAccount?.selected_businessAccount;
  let temp = state?.businessAccount?.businessAccount?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_businessAccount = (state: {
  businessAccount: {
    businessAccount: BusinessAccount[];
  };
}): BusinessAccount[] => {
  let temp = state?.businessAccount?.businessAccount;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_businessAccount_busy = (state: { businessAccount: businessAccountSliceState }) => {
  return (
    state?.businessAccount.is_deleting_businessAccount ||
    state?.businessAccount.is_loading_businessAccount ||
    state?.businessAccount.is_creating_businessAccount
  );
};
export const is_deleting_businessAccount_busy = (state: { businessAccount: businessAccountSliceState }) => {
  return state?.businessAccount.is_deleting_businessAccount;
};
export const is_loading_businessAccount_busy = (state: { businessAccount: businessAccountSliceState }) => {
  return state?.businessAccount.is_loading_businessAccount;
};
export const is_creating_businessAccount_busy = (state: { businessAccount: businessAccountSliceState }) => {
  return state?.businessAccount.is_creating_businessAccount;
};

export default businessAccountSlice.reducer;
