import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import InvestmentOrder from "./investment-order-model";
import { InvestmentOrderProperties, InvestmentOrderMetaProperties } from "./investmentOrder-property-definitions";

export async function load_investmentOrders(args: { uid: string }): Promise<InvestmentOrder[]> {
  throw "Not implemented";
}

export async function delete_investmentOrder(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_investmentOrder(args: InvestmentOrder): Promise<InvestmentOrder> {
  throw "Not implemented";
}
