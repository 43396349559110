import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import Sponsor from "./sponsor-model";
import { SponsorProperties, SponsorMetaProperties } from "./sponsor-property-definitions";

export async function load_sponsors(args: { uid: string }): Promise<Sponsor[]> {
  throw "Not implemented";
}

export async function delete_sponsor(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_sponsor(args: Sponsor): Promise<Sponsor> {
  throw "Not implemented";
}
