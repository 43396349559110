import { createAsyncThunk } from "@reduxjs/toolkit";
import SponorAgent from "./sponor-agent-model";
import { delete_sponorAgent, load_sponorAgents, store_sponorAgent } from "./sponorAgent-service";

export const LoadSponorAgentsAsync = createAsyncThunk<any, any>("composer/load/sponorAgent", async (params: { uid: string }) => {
  return await load_sponorAgents(params);
});

export const CreateSponorAgentAsync = createAsyncThunk<any, any>("composer/create/sponorAgent", async (params: SponorAgent) => {
  return await store_sponorAgent(params);
});

export const DeleteSponorAgentAsync = createAsyncThunk<any, any>("composer/delete/sponorAgent", async (params) => {
  return await delete_sponorAgent(params);
});
