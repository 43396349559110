import VirtualShareEscro from "./virtual-share-escro-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateVirtualShareEscroAsync, DeleteVirtualShareEscroAsync, LoadVirtualShareEscrosAsync } from "./virtualShareEscro-thunk";
const initialState: virtualShareEscroSliceState = {
  virtualShareEscro: [],
  selected_virtualShareEscro: "",
  is_loading_virtualShareEscro: false,
  is_creating_virtualShareEscro: false,
  is_deleting_virtualShareEscro: false
};
export interface virtualShareEscroSliceState {
  virtualShareEscro: VirtualShareEscro[];
  selected_virtualShareEscro?: string;
  is_loading_virtualShareEscro: boolean;
  is_creating_virtualShareEscro: boolean;
  is_deleting_virtualShareEscro: boolean;
}
export const virtualShareEscroSlice = createSlice({
  name: "virtualShareEscro",
  initialState,
  reducers: {
    setSelectedvirtualShareEscro: (state, action) => {
      state.selected_virtualShareEscro = action.payload;
    },
    clearStatevirtualShareEscro: (state, action) => {
      state.virtualShareEscro = [];
      state.is_loading_virtualShareEscro = false;
      state.is_creating_virtualShareEscro = false;
      state.is_deleting_virtualShareEscro = false;
    },
    mergevirtualShareEscro: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_virtualShareEscro = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.virtualShareEscro = merge(state.virtualShareEscro, payload) as any;
        }
      }
    },

    add_virtualShareEscro: (state, action) => {
      if (action.payload) {
        state.virtualShareEscro = [...state.virtualShareEscro, action.payload];
      }
    },
    set_virtualShareEscro: (state, action) => {
      let { key, value, id } = action.payload;
      state.virtualShareEscro = state.virtualShareEscro.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadVirtualShareEscrosAsync.pending, (state) => {
      state.is_loading_virtualShareEscro = true;
    });
    builder.addCase(LoadVirtualShareEscrosAsync.fulfilled, (state, action) => {
      state.is_loading_virtualShareEscro = false;
      if (action.payload) {
        state.virtualShareEscro = action.payload;
      }
    });
    builder.addCase(LoadVirtualShareEscrosAsync.rejected, (state, action) => {
      state.is_loading_virtualShareEscro = false;
    });
    builder.addCase(CreateVirtualShareEscroAsync.pending, (state) => {
      state.is_creating_virtualShareEscro = true;
    });
    builder.addCase(CreateVirtualShareEscroAsync.fulfilled, (state) => {
      state.is_creating_virtualShareEscro = false;
    });
    builder.addCase(CreateVirtualShareEscroAsync.rejected, (state) => {
      state.is_creating_virtualShareEscro = false;
    });
    builder.addCase(DeleteVirtualShareEscroAsync.pending, (state) => {
      state.is_deleting_virtualShareEscro = true;
    });
    builder.addCase(DeleteVirtualShareEscroAsync.fulfilled, (state, action) => {
      state.is_deleting_virtualShareEscro = false;
      if (state.virtualShareEscro) {
        state.virtualShareEscro = state.virtualShareEscro.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteVirtualShareEscroAsync.rejected, (state, action) => {
      state.is_deleting_virtualShareEscro = false;
    });

    builder.addCase(autoThunks.put_virtual_business_shares_on_sale.pending, (state) => {
      state.is_loading_virtualShareEscro = true;
    });
    builder.addCase(autoThunks.put_virtual_business_shares_on_sale.fulfilled, (state, action) => {
      state.is_loading_virtualShareEscro = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.put_virtual_business_shares_on_sale.rejected, (state, action) => {
      state.is_loading_virtualShareEscro = false;
    });

    builder.addCase(autoThunks.get_virtual_business_shares_on_sale.pending, (state) => {
      state.is_loading_virtualShareEscro = true;
    });
    builder.addCase(autoThunks.get_virtual_business_shares_on_sale.fulfilled, (state, action) => {
      state.is_loading_virtualShareEscro = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_virtual_business_shares_on_sale.rejected, (state, action) => {
      state.is_loading_virtualShareEscro = false;
    });

    builder.addCase(autoThunks.cancel_offer_on_virtual_business_shares_on_sale.pending, (state) => {
      state.is_loading_virtualShareEscro = true;
    });
    builder.addCase(autoThunks.cancel_offer_on_virtual_business_shares_on_sale.fulfilled, (state, action) => {
      state.is_loading_virtualShareEscro = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.cancel_offer_on_virtual_business_shares_on_sale.rejected, (state, action) => {
      state.is_loading_virtualShareEscro = false;
    });
  }
});

function handleResult(action: any, state: virtualShareEscroSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_virtualShareEscro = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.virtualShareEscro = merge(state.virtualShareEscro, payload) as any;
    }
  }
}

export const { clearStatevirtualShareEscro, setSelectedvirtualShareEscro, set_virtualShareEscro, mergevirtualShareEscro, add_virtualShareEscro } =
  virtualShareEscroSlice.actions;

export const get_virtualShareEscro =
  (id?: string) =>
  (state: {
    virtualShareEscro: {
      virtualShareEscro: VirtualShareEscro[];
    };
  }): VirtualShareEscro | null => {
    let temp = state?.virtualShareEscro?.virtualShareEscro?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.virtualShareEscro?.virtualShareEscro[0] || null;
  };
export const get_selected_virtualShareEscro = (state: { virtualShareEscro: virtualShareEscroSliceState }): VirtualShareEscro | null => {
  let id = state?.virtualShareEscro?.selected_virtualShareEscro;
  let temp = state?.virtualShareEscro?.virtualShareEscro?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_virtualShareEscro = (state: {
  virtualShareEscro: {
    virtualShareEscro: VirtualShareEscro[];
  };
}): VirtualShareEscro[] => {
  let temp = state?.virtualShareEscro?.virtualShareEscro;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_virtualShareEscro_busy = (state: { virtualShareEscro: virtualShareEscroSliceState }) => {
  return (
    state?.virtualShareEscro.is_deleting_virtualShareEscro ||
    state?.virtualShareEscro.is_loading_virtualShareEscro ||
    state?.virtualShareEscro.is_creating_virtualShareEscro
  );
};
export const is_deleting_virtualShareEscro_busy = (state: { virtualShareEscro: virtualShareEscroSliceState }) => {
  return state?.virtualShareEscro.is_deleting_virtualShareEscro;
};
export const is_loading_virtualShareEscro_busy = (state: { virtualShareEscro: virtualShareEscroSliceState }) => {
  return state?.virtualShareEscro.is_loading_virtualShareEscro;
};
export const is_creating_virtualShareEscro_busy = (state: { virtualShareEscro: virtualShareEscroSliceState }) => {
  return state?.virtualShareEscro.is_creating_virtualShareEscro;
};

export default virtualShareEscroSlice.reducer;
