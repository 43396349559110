import BusinessLoanRequest from "./business-loan-request-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateBusinessLoanRequestAsync, DeleteBusinessLoanRequestAsync, LoadBusinessLoanRequestsAsync } from "./businessLoanRequest-thunk";
const initialState: businessLoanRequestSliceState = {
  businessLoanRequest: [],
  selected_businessLoanRequest: "",
  is_loading_businessLoanRequest: false,
  is_creating_businessLoanRequest: false,
  is_deleting_businessLoanRequest: false
};
export interface businessLoanRequestSliceState {
  businessLoanRequest: BusinessLoanRequest[];
  selected_businessLoanRequest?: string;
  is_loading_businessLoanRequest: boolean;
  is_creating_businessLoanRequest: boolean;
  is_deleting_businessLoanRequest: boolean;
}
export const businessLoanRequestSlice = createSlice({
  name: "businessLoanRequest",
  initialState,
  reducers: {
    setSelectedbusinessLoanRequest: (state, action) => {
      state.selected_businessLoanRequest = action.payload;
    },
    clearStatebusinessLoanRequest: (state, action) => {
      state.businessLoanRequest = [];
      state.is_loading_businessLoanRequest = false;
      state.is_creating_businessLoanRequest = false;
      state.is_deleting_businessLoanRequest = false;
    },
    mergebusinessLoanRequest: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_businessLoanRequest = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.businessLoanRequest = merge(state.businessLoanRequest, payload) as any;
        }
      }
    },

    add_businessLoanRequest: (state, action) => {
      if (action.payload) {
        state.businessLoanRequest = [...state.businessLoanRequest, action.payload];
      }
    },
    set_businessLoanRequest: (state, action) => {
      let { key, value, id } = action.payload;
      state.businessLoanRequest = state.businessLoanRequest.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadBusinessLoanRequestsAsync.pending, (state) => {
      state.is_loading_businessLoanRequest = true;
    });
    builder.addCase(LoadBusinessLoanRequestsAsync.fulfilled, (state, action) => {
      state.is_loading_businessLoanRequest = false;
      if (action.payload) {
        state.businessLoanRequest = action.payload;
      }
    });
    builder.addCase(LoadBusinessLoanRequestsAsync.rejected, (state, action) => {
      state.is_loading_businessLoanRequest = false;
    });
    builder.addCase(CreateBusinessLoanRequestAsync.pending, (state) => {
      state.is_creating_businessLoanRequest = true;
    });
    builder.addCase(CreateBusinessLoanRequestAsync.fulfilled, (state) => {
      state.is_creating_businessLoanRequest = false;
    });
    builder.addCase(CreateBusinessLoanRequestAsync.rejected, (state) => {
      state.is_creating_businessLoanRequest = false;
    });
    builder.addCase(DeleteBusinessLoanRequestAsync.pending, (state) => {
      state.is_deleting_businessLoanRequest = true;
    });
    builder.addCase(DeleteBusinessLoanRequestAsync.fulfilled, (state, action) => {
      state.is_deleting_businessLoanRequest = false;
      if (state.businessLoanRequest) {
        state.businessLoanRequest = state.businessLoanRequest.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteBusinessLoanRequestAsync.rejected, (state, action) => {
      state.is_deleting_businessLoanRequest = false;
    });
  }
});

function handleResult(action: any, state: businessLoanRequestSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_businessLoanRequest = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.businessLoanRequest = merge(state.businessLoanRequest, payload) as any;
    }
  }
}

export const { clearStatebusinessLoanRequest, setSelectedbusinessLoanRequest, set_businessLoanRequest, mergebusinessLoanRequest, add_businessLoanRequest } =
  businessLoanRequestSlice.actions;

export const get_businessLoanRequest =
  (id?: string) =>
  (state: {
    businessLoanRequest: {
      businessLoanRequest: BusinessLoanRequest[];
    };
  }): BusinessLoanRequest | null => {
    let temp = state?.businessLoanRequest?.businessLoanRequest?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.businessLoanRequest?.businessLoanRequest[0] || null;
  };
export const get_selected_businessLoanRequest = (state: { businessLoanRequest: businessLoanRequestSliceState }): BusinessLoanRequest | null => {
  let id = state?.businessLoanRequest?.selected_businessLoanRequest;
  let temp = state?.businessLoanRequest?.businessLoanRequest?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_businessLoanRequest = (state: {
  businessLoanRequest: {
    businessLoanRequest: BusinessLoanRequest[];
  };
}): BusinessLoanRequest[] => {
  let temp = state?.businessLoanRequest?.businessLoanRequest;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_businessLoanRequest_busy = (state: { businessLoanRequest: businessLoanRequestSliceState }) => {
  return (
    state?.businessLoanRequest.is_deleting_businessLoanRequest ||
    state?.businessLoanRequest.is_loading_businessLoanRequest ||
    state?.businessLoanRequest.is_creating_businessLoanRequest
  );
};
export const is_deleting_businessLoanRequest_busy = (state: { businessLoanRequest: businessLoanRequestSliceState }) => {
  return state?.businessLoanRequest.is_deleting_businessLoanRequest;
};
export const is_loading_businessLoanRequest_busy = (state: { businessLoanRequest: businessLoanRequestSliceState }) => {
  return state?.businessLoanRequest.is_loading_businessLoanRequest;
};
export const is_creating_businessLoanRequest_busy = (state: { businessLoanRequest: businessLoanRequestSliceState }) => {
  return state?.businessLoanRequest.is_creating_businessLoanRequest;
};

export default businessLoanRequestSlice.reducer;
