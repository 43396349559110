import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import AuctionItem from "./auction-item-model";
import { AuctionItemProperties, AuctionItemMetaProperties } from "./auctionItem-property-definitions";

export async function load_auctionItems(args: { uid: string }): Promise<AuctionItem[]> {
  throw "Not implemented";
}

export async function delete_auctionItem(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_auctionItem(args: AuctionItem): Promise<AuctionItem> {
  throw "Not implemented";
}
