import { createAsyncThunk } from "@reduxjs/toolkit";
import CourseQuestion from "./course-question-model";
import { delete_courseQuestion, load_courseQuestions, store_courseQuestion } from "./courseQuestion-service";

export const LoadCourseQuestionsAsync = createAsyncThunk<any, any>("composer/load/courseQuestion", async (params: { uid: string }) => {
  return await load_courseQuestions(params);
});

export const CreateCourseQuestionAsync = createAsyncThunk<any, any>("composer/create/courseQuestion", async (params: CourseQuestion) => {
  return await store_courseQuestion(params);
});

export const DeleteCourseQuestionAsync = createAsyncThunk<any, any>("composer/delete/courseQuestion", async (params) => {
  return await delete_courseQuestion(params);
});
