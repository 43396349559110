import {
  alpha,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { styled } from '@mui/material/styles';
import SchoolIcon from '@mui/icons-material/School';
import { formatDistance, subDays } from 'date-fns';
import { useSelector } from 'react-redux';
import { getUserClaims } from 'features/application/application-slice';
import { dispatch } from 'store';
import { get_available_courses, get_my_courses, get_my_enrollments, get_my_testTaker, update_my_settings } from 'features/auto-thunks';
import TitleService from 'title-service';
import { get_all_enrollment } from 'features/enrollment/enrollment-slice';
import { get_all_course } from 'features/course/course-slice';
import CourseListItem from 'components/CourseListItem';
import AddCourseDialog from 'components/AddCourseDialog';
import { CourseState, CourseTakerSettingKeys } from 'features/enumerations';
import { useView } from 'hooks/view_context';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

function HeaderNotifications() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const claims = useSelector(getUserClaims);
  const enrollments = useSelector(get_all_enrollment);
  const [courseDialogOpen, setCourseDialogOpen] = useState<boolean>(false);
  const all_courses = useSelector(get_all_course);
  const courses = all_courses.filter(x => x.courseState === CourseState.Published);
  const my_enrollments = enrollments.filter(x => x.courseTaker === claims?.courseTaker && courses.find(t => t.id === x.course));
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  useEffect(() => {
    if (claims?.courseTaker) {
      dispatch(get_my_enrollments({
        courseTaker: claims?.courseTaker,
        userId: claims?.user
      }));
      dispatch(get_my_courses({
        courseTaker: claims?.courseTaker,
        userId: claims?.user
      }));
      dispatch(get_available_courses({
        courseTaker: claims?.courseTaker,
        userId: claims?.user
      }));
    }
    else if (claims && !claims?.courseTaker) {
      dispatch(get_my_testTaker({
        userId: claims?.user
      }));
    }
  }, [claims?.user, claims?.courseTaker])
  useEffect(() => {
    if (my_enrollments?.length === 1 && courses?.length) {
      let enrollment = my_enrollments[0];
      let course = courses.find(x => x.id === enrollment.course);
      if (course) {
        dispatch(update_my_settings({
          userId: claims?.user,
          courseTaker: claims?.courseTaker,
          key: CourseTakerSettingKeys.CurrentCourse,
          value: course.id
        }))
      }
    }
  }, [my_enrollments?.length, courses?.length])
  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            badgeContent={my_enrollments?.length || 0}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <SchoolIcon />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          sx={{ p: 2 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">{TitleService(`My Courses`)}</Typography>
        </Box>
        <Divider />
        <List sx={{ p: 0 }}>
          {my_enrollments.map(enrollment => {
            let course = courses.find(x => x.id === enrollment.course);
            return <CourseListItem hideprice course={course} onClick={async () => {
              await dispatch(update_my_settings({
                userId: claims?.user,
                courseTaker: claims?.courseTaker,
                key: CourseTakerSettingKeys.CurrentCourse,
                value: course.id
              }))
            }} />
          })}
          <ListItem
            sx={{
              cursor: 'pointer',
              p: 2,
              minWidth: 350,
              display: { xs: 'block', sm: 'flex' }
            }}
            onClick={() => {
              setCourseDialogOpen(true);
            }}
          >
            <Box flex="1">
              <Box display="flex" justifyContent="start" alignItems={'center'}>
                <AddBoxIcon />
                <Typography sx={{ marginLeft: `10px`, fontWeight: 'bold' }}>
                  {('Add a new course')}
                </Typography>
              </Box>
            </Box>
          </ListItem>
        </List>
      </Popover>
      <AddCourseDialog open={courseDialogOpen} onClose={() => {
        setCourseDialogOpen(false);
      }} />
    </>
  );
}

export default HeaderNotifications;
