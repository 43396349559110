import IndividualInvestmentHolding from "./individual-investment-holding-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import {
  CreateIndividualInvestmentHoldingAsync,
  DeleteIndividualInvestmentHoldingAsync,
  LoadIndividualInvestmentHoldingsAsync
} from "./individualInvestmentHolding-thunk";
const initialState: individualInvestmentHoldingSliceState = {
  individualInvestmentHolding: [],
  selected_individualInvestmentHolding: "",
  is_loading_individualInvestmentHolding: false,
  is_creating_individualInvestmentHolding: false,
  is_deleting_individualInvestmentHolding: false
};
export interface individualInvestmentHoldingSliceState {
  individualInvestmentHolding: IndividualInvestmentHolding[];
  selected_individualInvestmentHolding?: string;
  is_loading_individualInvestmentHolding: boolean;
  is_creating_individualInvestmentHolding: boolean;
  is_deleting_individualInvestmentHolding: boolean;
}
export const individualInvestmentHoldingSlice = createSlice({
  name: "individualInvestmentHolding",
  initialState,
  reducers: {
    setSelectedindividualInvestmentHolding: (state, action) => {
      state.selected_individualInvestmentHolding = action.payload;
    },
    clearStateindividualInvestmentHolding: (state, action) => {
      state.individualInvestmentHolding = [];
      state.is_loading_individualInvestmentHolding = false;
      state.is_creating_individualInvestmentHolding = false;
      state.is_deleting_individualInvestmentHolding = false;
    },
    mergeindividualInvestmentHolding: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_individualInvestmentHolding = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.individualInvestmentHolding = merge(state.individualInvestmentHolding, payload) as any;
        }
      }
    },

    add_individualInvestmentHolding: (state, action) => {
      if (action.payload) {
        state.individualInvestmentHolding = [...state.individualInvestmentHolding, action.payload];
      }
    },
    set_individualInvestmentHolding: (state, action) => {
      let { key, value, id } = action.payload;
      state.individualInvestmentHolding = state.individualInvestmentHolding.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadIndividualInvestmentHoldingsAsync.pending, (state) => {
      state.is_loading_individualInvestmentHolding = true;
    });
    builder.addCase(LoadIndividualInvestmentHoldingsAsync.fulfilled, (state, action) => {
      state.is_loading_individualInvestmentHolding = false;
      if (action.payload) {
        state.individualInvestmentHolding = action.payload;
      }
    });
    builder.addCase(LoadIndividualInvestmentHoldingsAsync.rejected, (state, action) => {
      state.is_loading_individualInvestmentHolding = false;
    });
    builder.addCase(CreateIndividualInvestmentHoldingAsync.pending, (state) => {
      state.is_creating_individualInvestmentHolding = true;
    });
    builder.addCase(CreateIndividualInvestmentHoldingAsync.fulfilled, (state) => {
      state.is_creating_individualInvestmentHolding = false;
    });
    builder.addCase(CreateIndividualInvestmentHoldingAsync.rejected, (state) => {
      state.is_creating_individualInvestmentHolding = false;
    });
    builder.addCase(DeleteIndividualInvestmentHoldingAsync.pending, (state) => {
      state.is_deleting_individualInvestmentHolding = true;
    });
    builder.addCase(DeleteIndividualInvestmentHoldingAsync.fulfilled, (state, action) => {
      state.is_deleting_individualInvestmentHolding = false;
      if (state.individualInvestmentHolding) {
        state.individualInvestmentHolding = state.individualInvestmentHolding.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteIndividualInvestmentHoldingAsync.rejected, (state, action) => {
      state.is_deleting_individualInvestmentHolding = false;
    });
  }
});

function handleResult(action: any, state: individualInvestmentHoldingSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_individualInvestmentHolding = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.individualInvestmentHolding = merge(state.individualInvestmentHolding, payload) as any;
    }
  }
}

export const {
  clearStateindividualInvestmentHolding,
  setSelectedindividualInvestmentHolding,
  set_individualInvestmentHolding,
  mergeindividualInvestmentHolding,
  add_individualInvestmentHolding
} = individualInvestmentHoldingSlice.actions;

export const get_individualInvestmentHolding =
  (id?: string) =>
  (state: {
    individualInvestmentHolding: {
      individualInvestmentHolding: IndividualInvestmentHolding[];
    };
  }): IndividualInvestmentHolding | null => {
    let temp = state?.individualInvestmentHolding?.individualInvestmentHolding?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.individualInvestmentHolding?.individualInvestmentHolding[0] || null;
  };
export const get_selected_individualInvestmentHolding = (state: {
  individualInvestmentHolding: individualInvestmentHoldingSliceState;
}): IndividualInvestmentHolding | null => {
  let id = state?.individualInvestmentHolding?.selected_individualInvestmentHolding;
  let temp = state?.individualInvestmentHolding?.individualInvestmentHolding?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_individualInvestmentHolding = (state: {
  individualInvestmentHolding: {
    individualInvestmentHolding: IndividualInvestmentHolding[];
  };
}): IndividualInvestmentHolding[] => {
  let temp = state?.individualInvestmentHolding?.individualInvestmentHolding;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_individualInvestmentHolding_busy = (state: { individualInvestmentHolding: individualInvestmentHoldingSliceState }) => {
  return (
    state?.individualInvestmentHolding.is_deleting_individualInvestmentHolding ||
    state?.individualInvestmentHolding.is_loading_individualInvestmentHolding ||
    state?.individualInvestmentHolding.is_creating_individualInvestmentHolding
  );
};
export const is_deleting_individualInvestmentHolding_busy = (state: { individualInvestmentHolding: individualInvestmentHoldingSliceState }) => {
  return state?.individualInvestmentHolding.is_deleting_individualInvestmentHolding;
};
export const is_loading_individualInvestmentHolding_busy = (state: { individualInvestmentHolding: individualInvestmentHoldingSliceState }) => {
  return state?.individualInvestmentHolding.is_loading_individualInvestmentHolding;
};
export const is_creating_individualInvestmentHolding_busy = (state: { individualInvestmentHolding: individualInvestmentHoldingSliceState }) => {
  return state?.individualInvestmentHolding.is_creating_individualInvestmentHolding;
};

export default individualInvestmentHoldingSlice.reducer;
