import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import CourseRecord from "./course-record-model";
import { CourseRecordProperties, CourseRecordMetaProperties } from "./courseRecord-property-definitions";

export async function load_courseRecords(args: { uid: string }): Promise<CourseRecord[]> {
  throw "Not implemented";
}

export async function delete_courseRecord(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_courseRecord(args: CourseRecord): Promise<CourseRecord> {
  throw "Not implemented";
}
