import ClassRoom from "./class-room-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateClassRoomAsync, DeleteClassRoomAsync, LoadClassRoomsAsync } from "./classRoom-thunk";
const initialState: classRoomSliceState = {
  classRoom: [],
  selected_classRoom: "",
  is_loading_classRoom: false,
  is_creating_classRoom: false,
  is_deleting_classRoom: false
};
export interface classRoomSliceState {
  classRoom: ClassRoom[];
  selected_classRoom?: string;
  is_loading_classRoom: boolean;
  is_creating_classRoom: boolean;
  is_deleting_classRoom: boolean;
}
export const classRoomSlice = createSlice({
  name: "classRoom",
  initialState,
  reducers: {
    setSelectedclassRoom: (state, action) => {
      state.selected_classRoom = action.payload;
    },
    clearStateclassRoom: (state, action) => {
      state.classRoom = [];
      state.is_loading_classRoom = false;
      state.is_creating_classRoom = false;
      state.is_deleting_classRoom = false;
    },
    mergeclassRoom: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_classRoom = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.classRoom = merge(state.classRoom, payload) as any;
        }
      }
    },

    add_classRoom: (state, action) => {
      if (action.payload) {
        state.classRoom = [...state.classRoom, action.payload];
      }
    },
    set_classRoom: (state, action) => {
      let { key, value, id } = action.payload;
      state.classRoom = state.classRoom.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadClassRoomsAsync.pending, (state) => {
      state.is_loading_classRoom = true;
    });
    builder.addCase(LoadClassRoomsAsync.fulfilled, (state, action) => {
      state.is_loading_classRoom = false;
      if (action.payload) {
        state.classRoom = action.payload;
      }
    });
    builder.addCase(LoadClassRoomsAsync.rejected, (state, action) => {
      state.is_loading_classRoom = false;
    });
    builder.addCase(CreateClassRoomAsync.pending, (state) => {
      state.is_creating_classRoom = true;
    });
    builder.addCase(CreateClassRoomAsync.fulfilled, (state) => {
      state.is_creating_classRoom = false;
    });
    builder.addCase(CreateClassRoomAsync.rejected, (state) => {
      state.is_creating_classRoom = false;
    });
    builder.addCase(DeleteClassRoomAsync.pending, (state) => {
      state.is_deleting_classRoom = true;
    });
    builder.addCase(DeleteClassRoomAsync.fulfilled, (state, action) => {
      state.is_deleting_classRoom = false;
      if (state.classRoom) {
        state.classRoom = state.classRoom.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteClassRoomAsync.rejected, (state, action) => {
      state.is_deleting_classRoom = false;
    });

    builder.addCase(autoThunks.set_class_school.pending, (state) => {
      state.is_loading_classRoom = true;
    });
    builder.addCase(autoThunks.set_class_school.fulfilled, (state, action) => {
      state.is_loading_classRoom = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.set_class_school.rejected, (state, action) => {
      state.is_loading_classRoom = false;
    });

    builder.addCase(autoThunks.get_classRooms_in_school.pending, (state) => {
      state.is_loading_classRoom = true;
    });
    builder.addCase(autoThunks.get_classRooms_in_school.fulfilled, (state, action) => {
      state.is_loading_classRoom = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_classRooms_in_school.rejected, (state, action) => {
      state.is_loading_classRoom = false;
    });

    builder.addCase(autoThunks.store_classRoom.pending, (state) => {
      state.is_loading_classRoom = true;
    });
    builder.addCase(autoThunks.store_classRoom.fulfilled, (state, action) => {
      state.is_loading_classRoom = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.store_classRoom.rejected, (state, action) => {
      state.is_loading_classRoom = false;
    });
  }
});

function handleResult(action: any, state: classRoomSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_classRoom = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.classRoom = merge(state.classRoom, payload) as any;
    }
  }
}

export const { clearStateclassRoom, setSelectedclassRoom, set_classRoom, mergeclassRoom, add_classRoom } = classRoomSlice.actions;

export const get_classRoom =
  (id?: string) =>
  (state: {
    classRoom: {
      classRoom: ClassRoom[];
    };
  }): ClassRoom | null => {
    let temp = state?.classRoom?.classRoom?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.classRoom?.classRoom[0] || null;
  };
export const get_selected_classRoom = (state: { classRoom: classRoomSliceState }): ClassRoom | null => {
  let id = state?.classRoom?.selected_classRoom;
  let temp = state?.classRoom?.classRoom?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_classRoom = (state: {
  classRoom: {
    classRoom: ClassRoom[];
  };
}): ClassRoom[] => {
  let temp = state?.classRoom?.classRoom;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_classRoom_busy = (state: { classRoom: classRoomSliceState }) => {
  return state?.classRoom.is_deleting_classRoom || state?.classRoom.is_loading_classRoom || state?.classRoom.is_creating_classRoom;
};
export const is_deleting_classRoom_busy = (state: { classRoom: classRoomSliceState }) => {
  return state?.classRoom.is_deleting_classRoom;
};
export const is_loading_classRoom_busy = (state: { classRoom: classRoomSliceState }) => {
  return state?.classRoom.is_loading_classRoom;
};
export const is_creating_classRoom_busy = (state: { classRoom: classRoomSliceState }) => {
  return state?.classRoom.is_creating_classRoom;
};

export default classRoomSlice.reducer;
