import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import Holding from "./holding-model";
import { HoldingProperties, HoldingMetaProperties } from "./holding-property-definitions";

export async function load_holdings(args: { uid: string }): Promise<Holding[]> {
  throw "Not implemented";
}

export async function delete_holding(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_holding(args: Holding): Promise<Holding> {
  throw "Not implemented";
}
