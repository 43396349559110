import { createAsyncThunk } from "@reduxjs/toolkit";
import Answer from "./answer-model";
import { delete_answer, load_answers, store_answer } from "./answer-service";

export const LoadAnswersAsync = createAsyncThunk<any, any>("composer/load/answer", async (params: { uid: string }) => {
  return await load_answers(params);
});

export const CreateAnswerAsync = createAsyncThunk<any, any>("composer/create/answer", async (params: Answer) => {
  return await store_answer(params);
});

export const DeleteAnswerAsync = createAsyncThunk<any, any>("composer/delete/answer", async (params) => {
  return await delete_answer(params);
});
