import BankAccountJoinRequest from "./bank-account-join-request-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateBankAccountJoinRequestAsync, DeleteBankAccountJoinRequestAsync, LoadBankAccountJoinRequestsAsync } from "./bankAccountJoinRequest-thunk";
const initialState: bankAccountJoinRequestSliceState = {
  bankAccountJoinRequest: [],
  selected_bankAccountJoinRequest: "",
  is_loading_bankAccountJoinRequest: false,
  is_creating_bankAccountJoinRequest: false,
  is_deleting_bankAccountJoinRequest: false
};
export interface bankAccountJoinRequestSliceState {
  bankAccountJoinRequest: BankAccountJoinRequest[];
  selected_bankAccountJoinRequest?: string;
  is_loading_bankAccountJoinRequest: boolean;
  is_creating_bankAccountJoinRequest: boolean;
  is_deleting_bankAccountJoinRequest: boolean;
}
export const bankAccountJoinRequestSlice = createSlice({
  name: "bankAccountJoinRequest",
  initialState,
  reducers: {
    setSelectedbankAccountJoinRequest: (state, action) => {
      state.selected_bankAccountJoinRequest = action.payload;
    },
    clearStatebankAccountJoinRequest: (state, action) => {
      state.bankAccountJoinRequest = [];
      state.is_loading_bankAccountJoinRequest = false;
      state.is_creating_bankAccountJoinRequest = false;
      state.is_deleting_bankAccountJoinRequest = false;
    },
    mergebankAccountJoinRequest: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_bankAccountJoinRequest = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.bankAccountJoinRequest = merge(state.bankAccountJoinRequest, payload) as any;
        }
      }
    },

    add_bankAccountJoinRequest: (state, action) => {
      if (action.payload) {
        state.bankAccountJoinRequest = [...state.bankAccountJoinRequest, action.payload];
      }
    },
    set_bankAccountJoinRequest: (state, action) => {
      let { key, value, id } = action.payload;
      state.bankAccountJoinRequest = state.bankAccountJoinRequest.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadBankAccountJoinRequestsAsync.pending, (state) => {
      state.is_loading_bankAccountJoinRequest = true;
    });
    builder.addCase(LoadBankAccountJoinRequestsAsync.fulfilled, (state, action) => {
      state.is_loading_bankAccountJoinRequest = false;
      if (action.payload) {
        state.bankAccountJoinRequest = action.payload;
      }
    });
    builder.addCase(LoadBankAccountJoinRequestsAsync.rejected, (state, action) => {
      state.is_loading_bankAccountJoinRequest = false;
    });
    builder.addCase(CreateBankAccountJoinRequestAsync.pending, (state) => {
      state.is_creating_bankAccountJoinRequest = true;
    });
    builder.addCase(CreateBankAccountJoinRequestAsync.fulfilled, (state) => {
      state.is_creating_bankAccountJoinRequest = false;
    });
    builder.addCase(CreateBankAccountJoinRequestAsync.rejected, (state) => {
      state.is_creating_bankAccountJoinRequest = false;
    });
    builder.addCase(DeleteBankAccountJoinRequestAsync.pending, (state) => {
      state.is_deleting_bankAccountJoinRequest = true;
    });
    builder.addCase(DeleteBankAccountJoinRequestAsync.fulfilled, (state, action) => {
      state.is_deleting_bankAccountJoinRequest = false;
      if (state.bankAccountJoinRequest) {
        state.bankAccountJoinRequest = state.bankAccountJoinRequest.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteBankAccountJoinRequestAsync.rejected, (state, action) => {
      state.is_deleting_bankAccountJoinRequest = false;
    });

    builder.addCase(autoThunks.make_bank_account_join_request.pending, (state) => {
      state.is_loading_bankAccountJoinRequest = true;
    });
    builder.addCase(autoThunks.make_bank_account_join_request.fulfilled, (state, action) => {
      state.is_loading_bankAccountJoinRequest = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.make_bank_account_join_request.rejected, (state, action) => {
      state.is_loading_bankAccountJoinRequest = false;
    });

    builder.addCase(autoThunks.get_bank_account_join_requests.pending, (state) => {
      state.is_loading_bankAccountJoinRequest = true;
    });
    builder.addCase(autoThunks.get_bank_account_join_requests.fulfilled, (state, action) => {
      state.is_loading_bankAccountJoinRequest = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_bank_account_join_requests.rejected, (state, action) => {
      state.is_loading_bankAccountJoinRequest = false;
    });
  }
});

function handleResult(action: any, state: bankAccountJoinRequestSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_bankAccountJoinRequest = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.bankAccountJoinRequest = merge(state.bankAccountJoinRequest, payload) as any;
    }
  }
}

export const {
  clearStatebankAccountJoinRequest,
  setSelectedbankAccountJoinRequest,
  set_bankAccountJoinRequest,
  mergebankAccountJoinRequest,
  add_bankAccountJoinRequest
} = bankAccountJoinRequestSlice.actions;

export const get_bankAccountJoinRequest =
  (id?: string) =>
  (state: {
    bankAccountJoinRequest: {
      bankAccountJoinRequest: BankAccountJoinRequest[];
    };
  }): BankAccountJoinRequest | null => {
    let temp = state?.bankAccountJoinRequest?.bankAccountJoinRequest?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.bankAccountJoinRequest?.bankAccountJoinRequest[0] || null;
  };
export const get_selected_bankAccountJoinRequest = (state: { bankAccountJoinRequest: bankAccountJoinRequestSliceState }): BankAccountJoinRequest | null => {
  let id = state?.bankAccountJoinRequest?.selected_bankAccountJoinRequest;
  let temp = state?.bankAccountJoinRequest?.bankAccountJoinRequest?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_bankAccountJoinRequest = (state: {
  bankAccountJoinRequest: {
    bankAccountJoinRequest: BankAccountJoinRequest[];
  };
}): BankAccountJoinRequest[] => {
  let temp = state?.bankAccountJoinRequest?.bankAccountJoinRequest;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_bankAccountJoinRequest_busy = (state: { bankAccountJoinRequest: bankAccountJoinRequestSliceState }) => {
  return (
    state?.bankAccountJoinRequest.is_deleting_bankAccountJoinRequest ||
    state?.bankAccountJoinRequest.is_loading_bankAccountJoinRequest ||
    state?.bankAccountJoinRequest.is_creating_bankAccountJoinRequest
  );
};
export const is_deleting_bankAccountJoinRequest_busy = (state: { bankAccountJoinRequest: bankAccountJoinRequestSliceState }) => {
  return state?.bankAccountJoinRequest.is_deleting_bankAccountJoinRequest;
};
export const is_loading_bankAccountJoinRequest_busy = (state: { bankAccountJoinRequest: bankAccountJoinRequestSliceState }) => {
  return state?.bankAccountJoinRequest.is_loading_bankAccountJoinRequest;
};
export const is_creating_bankAccountJoinRequest_busy = (state: { bankAccountJoinRequest: bankAccountJoinRequestSliceState }) => {
  return state?.bankAccountJoinRequest.is_creating_bankAccountJoinRequest;
};

export default bankAccountJoinRequestSlice.reducer;
