export enum VirtualEmployeeProperties {
  FirstName = "firstName",
  LastName = "lastName",
  VirtualBusinessId = "virtualBusinessId",
  ProfileImage = "profileImage",
  Attributes = "attributes",
  Owner = "owner",
  Id = "id",
  Created = "created",
  Updated = "updated",
  Deleted = "deleted",
  Version = "version",
  Registation = "registation",
  BankId = "bankId"
}
export enum VirtualEmployeeMetaProperties {}
