import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import BankMetrics from "./bank-metrics-model";
import { BankMetricsProperties, BankMetricsMetaProperties } from "./bankMetrics-property-definitions";

export async function load_bankMetricss(args: { uid: string }): Promise<BankMetrics[]> {
  throw "Not implemented";
}

export async function delete_bankMetrics(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_bankMetrics(args: BankMetrics): Promise<BankMetrics> {
  throw "Not implemented";
}
