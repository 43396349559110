import { createAsyncThunk } from "@reduxjs/toolkit";
import BankCity from "./bank-city-model";
import { delete_bankCity, load_bankCitys, store_bankCity } from "./bankCity-service";

export const LoadBankCitysAsync = createAsyncThunk<any, any>("composer/load/bankCity", async (params: { uid: string }) => {
  return await load_bankCitys(params);
});

export const CreateBankCityAsync = createAsyncThunk<any, any>("composer/create/bankCity", async (params: BankCity) => {
  return await store_bankCity(params);
});

export const DeleteBankCityAsync = createAsyncThunk<any, any>("composer/delete/bankCity", async (params) => {
  return await delete_bankCity(params);
});
