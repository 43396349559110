import District from "./district-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateDistrictAsync, DeleteDistrictAsync, LoadDistrictsAsync } from "./district-thunk";
const initialState: districtSliceState = {
  district: [],
  selected_district: "",
  is_loading_district: false,
  is_creating_district: false,
  is_deleting_district: false
};
export interface districtSliceState {
  district: District[];
  selected_district?: string;
  is_loading_district: boolean;
  is_creating_district: boolean;
  is_deleting_district: boolean;
}
export const districtSlice = createSlice({
  name: "district",
  initialState,
  reducers: {
    setSelecteddistrict: (state, action) => {
      state.selected_district = action.payload;
    },
    clearStatedistrict: (state, action) => {
      state.district = [];
      state.is_loading_district = false;
      state.is_creating_district = false;
      state.is_deleting_district = false;
    },
    mergedistrict: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_district = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.district = merge(state.district, payload) as any;
        }
      }
    },

    add_district: (state, action) => {
      if (action.payload) {
        state.district = [...state.district, action.payload];
      }
    },
    set_district: (state, action) => {
      let { key, value, id } = action.payload;
      state.district = state.district.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadDistrictsAsync.pending, (state) => {
      state.is_loading_district = true;
    });
    builder.addCase(LoadDistrictsAsync.fulfilled, (state, action) => {
      state.is_loading_district = false;
      if (action.payload) {
        state.district = action.payload;
      }
    });
    builder.addCase(LoadDistrictsAsync.rejected, (state, action) => {
      state.is_loading_district = false;
    });
    builder.addCase(CreateDistrictAsync.pending, (state) => {
      state.is_creating_district = true;
    });
    builder.addCase(CreateDistrictAsync.fulfilled, (state) => {
      state.is_creating_district = false;
    });
    builder.addCase(CreateDistrictAsync.rejected, (state) => {
      state.is_creating_district = false;
    });
    builder.addCase(DeleteDistrictAsync.pending, (state) => {
      state.is_deleting_district = true;
    });
    builder.addCase(DeleteDistrictAsync.fulfilled, (state, action) => {
      state.is_deleting_district = false;
      if (state.district) {
        state.district = state.district.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteDistrictAsync.rejected, (state, action) => {
      state.is_deleting_district = false;
    });

    builder.addCase(autoThunks.get_districts.pending, (state) => {
      state.is_loading_district = true;
    });
    builder.addCase(autoThunks.get_districts.fulfilled, (state, action) => {
      state.is_loading_district = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_districts.rejected, (state, action) => {
      state.is_loading_district = false;
    });

    builder.addCase(autoThunks.store_district.pending, (state) => {
      state.is_loading_district = true;
    });
    builder.addCase(autoThunks.store_district.fulfilled, (state, action) => {
      state.is_loading_district = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.store_district.rejected, (state, action) => {
      state.is_loading_district = false;
    });
  }
});

function handleResult(action: any, state: districtSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_district = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.district = merge(state.district, payload) as any;
    }
  }
}

export const { clearStatedistrict, setSelecteddistrict, set_district, mergedistrict, add_district } = districtSlice.actions;

export const get_district =
  (id?: string) =>
  (state: {
    district: {
      district: District[];
    };
  }): District | null => {
    let temp = state?.district?.district?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.district?.district[0] || null;
  };
export const get_selected_district = (state: { district: districtSliceState }): District | null => {
  let id = state?.district?.selected_district;
  let temp = state?.district?.district?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_district = (state: {
  district: {
    district: District[];
  };
}): District[] => {
  let temp = state?.district?.district;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_district_busy = (state: { district: districtSliceState }) => {
  return state?.district.is_deleting_district || state?.district.is_loading_district || state?.district.is_creating_district;
};
export const is_deleting_district_busy = (state: { district: districtSliceState }) => {
  return state?.district.is_deleting_district;
};
export const is_loading_district_busy = (state: { district: districtSliceState }) => {
  return state?.district.is_loading_district;
};
export const is_creating_district_busy = (state: { district: districtSliceState }) => {
  return state?.district.is_creating_district;
};

export default districtSlice.reducer;
