import { createAsyncThunk } from "@reduxjs/toolkit";
import VirtualBusinessPartnerInvitation from "./virtual-business-partner-invitation-model";
import {
  delete_virtualBusinessPartnerInvitation,
  load_virtualBusinessPartnerInvitations,
  store_virtualBusinessPartnerInvitation
} from "./virtualBusinessPartnerInvitation-service";

export const LoadVirtualBusinessPartnerInvitationsAsync = createAsyncThunk<any, any>(
  "composer/load/virtualBusinessPartnerInvitation",
  async (params: { uid: string }) => {
    return await load_virtualBusinessPartnerInvitations(params);
  }
);

export const CreateVirtualBusinessPartnerInvitationAsync = createAsyncThunk<any, any>(
  "composer/create/virtualBusinessPartnerInvitation",
  async (params: VirtualBusinessPartnerInvitation) => {
    return await store_virtualBusinessPartnerInvitation(params);
  }
);

export const DeleteVirtualBusinessPartnerInvitationAsync = createAsyncThunk<any, any>("composer/delete/virtualBusinessPartnerInvitation", async (params) => {
  return await delete_virtualBusinessPartnerInvitation(params);
});
