import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import AuctionBid from "./auction-bid-model";
import { AuctionBidProperties, AuctionBidMetaProperties } from "./auctionBid-property-definitions";

export async function load_auctionBids(args: { uid: string }): Promise<AuctionBid[]> {
  throw "Not implemented";
}

export async function delete_auctionBid(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_auctionBid(args: AuctionBid): Promise<AuctionBid> {
  throw "Not implemented";
}
