import { createAsyncThunk } from "@reduxjs/toolkit";
import CourseSection from "./course-section-model";
import { delete_courseSection, load_courseSections, store_courseSection } from "./courseSection-service";

export const LoadCourseSectionsAsync = createAsyncThunk<any, any>("composer/load/courseSection", async (params: { uid: string }) => {
  return await load_courseSections(params);
});

export const CreateCourseSectionAsync = createAsyncThunk<any, any>("composer/create/courseSection", async (params: CourseSection) => {
  return await store_courseSection(params);
});

export const DeleteCourseSectionAsync = createAsyncThunk<any, any>("composer/delete/courseSection", async (params) => {
  return await delete_courseSection(params);
});
