import { createAsyncThunk } from "@reduxjs/toolkit";
import DigitalAsset from "./digital-asset-model";
import { delete_digitalAsset, load_digitalAssets, store_digitalAsset } from "./digitalAsset-service";

export const LoadDigitalAssetsAsync = createAsyncThunk<any, any>("composer/load/digitalAsset", async (params: { uid: string }) => {
  return await load_digitalAssets(params);
});

export const CreateDigitalAssetAsync = createAsyncThunk<any, any>("composer/create/digitalAsset", async (params: DigitalAsset) => {
  return await store_digitalAsset(params);
});

export const DeleteDigitalAssetAsync = createAsyncThunk<any, any>("composer/delete/digitalAsset", async (params) => {
  return await delete_digitalAsset(params);
});
