import AccountCurriculumRecord from "./account-curriculum-record-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateAccountCurriculumRecordAsync, DeleteAccountCurriculumRecordAsync, LoadAccountCurriculumRecordsAsync } from "./accountCurriculumRecord-thunk";
const initialState: accountCurriculumRecordSliceState = {
  accountCurriculumRecord: [],
  selected_accountCurriculumRecord: "",
  is_loading_accountCurriculumRecord: false,
  is_creating_accountCurriculumRecord: false,
  is_deleting_accountCurriculumRecord: false
};
export interface accountCurriculumRecordSliceState {
  accountCurriculumRecord: AccountCurriculumRecord[];
  selected_accountCurriculumRecord?: string;
  is_loading_accountCurriculumRecord: boolean;
  is_creating_accountCurriculumRecord: boolean;
  is_deleting_accountCurriculumRecord: boolean;
}
export const accountCurriculumRecordSlice = createSlice({
  name: "accountCurriculumRecord",
  initialState,
  reducers: {
    setSelectedaccountCurriculumRecord: (state, action) => {
      state.selected_accountCurriculumRecord = action.payload;
    },
    clearStateaccountCurriculumRecord: (state, action) => {
      state.accountCurriculumRecord = [];
      state.is_loading_accountCurriculumRecord = false;
      state.is_creating_accountCurriculumRecord = false;
      state.is_deleting_accountCurriculumRecord = false;
    },
    mergeaccountCurriculumRecord: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_accountCurriculumRecord = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.accountCurriculumRecord = merge(state.accountCurriculumRecord, payload) as any;
        }
      }
    },

    add_accountCurriculumRecord: (state, action) => {
      if (action.payload) {
        state.accountCurriculumRecord = [...state.accountCurriculumRecord, action.payload];
      }
    },
    set_accountCurriculumRecord: (state, action) => {
      let { key, value, id } = action.payload;
      state.accountCurriculumRecord = state.accountCurriculumRecord.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadAccountCurriculumRecordsAsync.pending, (state) => {
      state.is_loading_accountCurriculumRecord = true;
    });
    builder.addCase(LoadAccountCurriculumRecordsAsync.fulfilled, (state, action) => {
      state.is_loading_accountCurriculumRecord = false;
      if (action.payload) {
        state.accountCurriculumRecord = action.payload;
      }
    });
    builder.addCase(LoadAccountCurriculumRecordsAsync.rejected, (state, action) => {
      state.is_loading_accountCurriculumRecord = false;
    });
    builder.addCase(CreateAccountCurriculumRecordAsync.pending, (state) => {
      state.is_creating_accountCurriculumRecord = true;
    });
    builder.addCase(CreateAccountCurriculumRecordAsync.fulfilled, (state) => {
      state.is_creating_accountCurriculumRecord = false;
    });
    builder.addCase(CreateAccountCurriculumRecordAsync.rejected, (state) => {
      state.is_creating_accountCurriculumRecord = false;
    });
    builder.addCase(DeleteAccountCurriculumRecordAsync.pending, (state) => {
      state.is_deleting_accountCurriculumRecord = true;
    });
    builder.addCase(DeleteAccountCurriculumRecordAsync.fulfilled, (state, action) => {
      state.is_deleting_accountCurriculumRecord = false;
      if (state.accountCurriculumRecord) {
        state.accountCurriculumRecord = state.accountCurriculumRecord.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteAccountCurriculumRecordAsync.rejected, (state, action) => {
      state.is_deleting_accountCurriculumRecord = false;
    });
  }
});

function handleResult(action: any, state: accountCurriculumRecordSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_accountCurriculumRecord = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.accountCurriculumRecord = merge(state.accountCurriculumRecord, payload) as any;
    }
  }
}

export const {
  clearStateaccountCurriculumRecord,
  setSelectedaccountCurriculumRecord,
  set_accountCurriculumRecord,
  mergeaccountCurriculumRecord,
  add_accountCurriculumRecord
} = accountCurriculumRecordSlice.actions;

export const get_accountCurriculumRecord =
  (id?: string) =>
  (state: {
    accountCurriculumRecord: {
      accountCurriculumRecord: AccountCurriculumRecord[];
    };
  }): AccountCurriculumRecord | null => {
    let temp = state?.accountCurriculumRecord?.accountCurriculumRecord?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.accountCurriculumRecord?.accountCurriculumRecord[0] || null;
  };
export const get_selected_accountCurriculumRecord = (state: { accountCurriculumRecord: accountCurriculumRecordSliceState }): AccountCurriculumRecord | null => {
  let id = state?.accountCurriculumRecord?.selected_accountCurriculumRecord;
  let temp = state?.accountCurriculumRecord?.accountCurriculumRecord?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_accountCurriculumRecord = (state: {
  accountCurriculumRecord: {
    accountCurriculumRecord: AccountCurriculumRecord[];
  };
}): AccountCurriculumRecord[] => {
  let temp = state?.accountCurriculumRecord?.accountCurriculumRecord;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_accountCurriculumRecord_busy = (state: { accountCurriculumRecord: accountCurriculumRecordSliceState }) => {
  return (
    state?.accountCurriculumRecord.is_deleting_accountCurriculumRecord ||
    state?.accountCurriculumRecord.is_loading_accountCurriculumRecord ||
    state?.accountCurriculumRecord.is_creating_accountCurriculumRecord
  );
};
export const is_deleting_accountCurriculumRecord_busy = (state: { accountCurriculumRecord: accountCurriculumRecordSliceState }) => {
  return state?.accountCurriculumRecord.is_deleting_accountCurriculumRecord;
};
export const is_loading_accountCurriculumRecord_busy = (state: { accountCurriculumRecord: accountCurriculumRecordSliceState }) => {
  return state?.accountCurriculumRecord.is_loading_accountCurriculumRecord;
};
export const is_creating_accountCurriculumRecord_busy = (state: { accountCurriculumRecord: accountCurriculumRecordSliceState }) => {
  return state?.accountCurriculumRecord.is_creating_accountCurriculumRecord;
};

export default accountCurriculumRecordSlice.reducer;
