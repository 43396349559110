import { createAsyncThunk } from "@reduxjs/toolkit";
import Loan from "./loan-model";
import { delete_loan, load_loans, store_loan } from "./loan-service";

export const LoadLoansAsync = createAsyncThunk<any, any>("composer/load/loan", async (params: { uid: string }) => {
  return await load_loans(params);
});

export const CreateLoanAsync = createAsyncThunk<any, any>("composer/create/loan", async (params: Loan) => {
  return await store_loan(params);
});

export const DeleteLoanAsync = createAsyncThunk<any, any>("composer/delete/loan", async (params) => {
  return await delete_loan(params);
});
