import CheckoutSession from "./checkout-session-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateCheckoutSessionAsync, DeleteCheckoutSessionAsync, LoadCheckoutSessionsAsync } from "./checkoutSession-thunk";
const initialState: checkoutSessionSliceState = {
  checkoutSession: [],
  selected_checkoutSession: "",
  is_loading_checkoutSession: false,
  is_creating_checkoutSession: false,
  is_deleting_checkoutSession: false
};
export interface checkoutSessionSliceState {
  checkoutSession: CheckoutSession[];
  selected_checkoutSession?: string;
  is_loading_checkoutSession: boolean;
  is_creating_checkoutSession: boolean;
  is_deleting_checkoutSession: boolean;
}
export const checkoutSessionSlice = createSlice({
  name: "checkoutSession",
  initialState,
  reducers: {
    setSelectedcheckoutSession: (state, action) => {
      state.selected_checkoutSession = action.payload;
    },
    clearStatecheckoutSession: (state, action) => {
      state.checkoutSession = [];
      state.is_loading_checkoutSession = false;
      state.is_creating_checkoutSession = false;
      state.is_deleting_checkoutSession = false;
    },
    mergecheckoutSession: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_checkoutSession = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.checkoutSession = merge(state.checkoutSession, payload) as any;
        }
      }
    },

    add_checkoutSession: (state, action) => {
      if (action.payload) {
        state.checkoutSession = [...state.checkoutSession, action.payload];
      }
    },
    set_checkoutSession: (state, action) => {
      let { key, value, id } = action.payload;
      state.checkoutSession = state.checkoutSession.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadCheckoutSessionsAsync.pending, (state) => {
      state.is_loading_checkoutSession = true;
    });
    builder.addCase(LoadCheckoutSessionsAsync.fulfilled, (state, action) => {
      state.is_loading_checkoutSession = false;
      if (action.payload) {
        state.checkoutSession = action.payload;
      }
    });
    builder.addCase(LoadCheckoutSessionsAsync.rejected, (state, action) => {
      state.is_loading_checkoutSession = false;
    });
    builder.addCase(CreateCheckoutSessionAsync.pending, (state) => {
      state.is_creating_checkoutSession = true;
    });
    builder.addCase(CreateCheckoutSessionAsync.fulfilled, (state) => {
      state.is_creating_checkoutSession = false;
    });
    builder.addCase(CreateCheckoutSessionAsync.rejected, (state) => {
      state.is_creating_checkoutSession = false;
    });
    builder.addCase(DeleteCheckoutSessionAsync.pending, (state) => {
      state.is_deleting_checkoutSession = true;
    });
    builder.addCase(DeleteCheckoutSessionAsync.fulfilled, (state, action) => {
      state.is_deleting_checkoutSession = false;
      if (state.checkoutSession) {
        state.checkoutSession = state.checkoutSession.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteCheckoutSessionAsync.rejected, (state, action) => {
      state.is_deleting_checkoutSession = false;
    });
  }
});

function handleResult(action: any, state: checkoutSessionSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_checkoutSession = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.checkoutSession = merge(state.checkoutSession, payload) as any;
    }
  }
}

export const { clearStatecheckoutSession, setSelectedcheckoutSession, set_checkoutSession, mergecheckoutSession, add_checkoutSession } =
  checkoutSessionSlice.actions;

export const get_checkoutSession =
  (id?: string) =>
  (state: {
    checkoutSession: {
      checkoutSession: CheckoutSession[];
    };
  }): CheckoutSession | null => {
    let temp = state?.checkoutSession?.checkoutSession?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.checkoutSession?.checkoutSession[0] || null;
  };
export const get_selected_checkoutSession = (state: { checkoutSession: checkoutSessionSliceState }): CheckoutSession | null => {
  let id = state?.checkoutSession?.selected_checkoutSession;
  let temp = state?.checkoutSession?.checkoutSession?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_checkoutSession = (state: {
  checkoutSession: {
    checkoutSession: CheckoutSession[];
  };
}): CheckoutSession[] => {
  let temp = state?.checkoutSession?.checkoutSession;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_checkoutSession_busy = (state: { checkoutSession: checkoutSessionSliceState }) => {
  return (
    state?.checkoutSession.is_deleting_checkoutSession ||
    state?.checkoutSession.is_loading_checkoutSession ||
    state?.checkoutSession.is_creating_checkoutSession
  );
};
export const is_deleting_checkoutSession_busy = (state: { checkoutSession: checkoutSessionSliceState }) => {
  return state?.checkoutSession.is_deleting_checkoutSession;
};
export const is_loading_checkoutSession_busy = (state: { checkoutSession: checkoutSessionSliceState }) => {
  return state?.checkoutSession.is_loading_checkoutSession;
};
export const is_creating_checkoutSession_busy = (state: { checkoutSession: checkoutSessionSliceState }) => {
  return state?.checkoutSession.is_creating_checkoutSession;
};

export default checkoutSessionSlice.reducer;
