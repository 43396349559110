import { createAsyncThunk } from "@reduxjs/toolkit";
import School from "./school-model";
import { delete_school, load_schools, store_school } from "./school-service";

export const LoadSchoolsAsync = createAsyncThunk<any, any>("composer/load/school", async (params: { uid: string }) => {
  return await load_schools(params);
});

export const CreateSchoolAsync = createAsyncThunk<any, any>("composer/create/school", async (params: School) => {
  return await store_school(params);
});

export const DeleteSchoolAsync = createAsyncThunk<any, any>("composer/delete/school", async (params) => {
  return await delete_school(params);
});
