import { createAsyncThunk } from "@reduxjs/toolkit";
import BankAccountJoinRequest from "./bank-account-join-request-model";
import { delete_bankAccountJoinRequest, load_bankAccountJoinRequests, store_bankAccountJoinRequest } from "./bankAccountJoinRequest-service";

export const LoadBankAccountJoinRequestsAsync = createAsyncThunk<any, any>("composer/load/bankAccountJoinRequest", async (params: { uid: string }) => {
  return await load_bankAccountJoinRequests(params);
});

export const CreateBankAccountJoinRequestAsync = createAsyncThunk<any, any>(
  "composer/create/bankAccountJoinRequest",
  async (params: BankAccountJoinRequest) => {
    return await store_bankAccountJoinRequest(params);
  }
);

export const DeleteBankAccountJoinRequestAsync = createAsyncThunk<any, any>("composer/delete/bankAccountJoinRequest", async (params) => {
  return await delete_bankAccountJoinRequest(params);
});
