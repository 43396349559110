import Holding from "./holding-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateHoldingAsync, DeleteHoldingAsync, LoadHoldingsAsync } from "./holding-thunk";
const initialState: holdingSliceState = {
  holding: [],
  selected_holding: "",
  is_loading_holding: false,
  is_creating_holding: false,
  is_deleting_holding: false
};
export interface holdingSliceState {
  holding: Holding[];
  selected_holding?: string;
  is_loading_holding: boolean;
  is_creating_holding: boolean;
  is_deleting_holding: boolean;
}
export const holdingSlice = createSlice({
  name: "holding",
  initialState,
  reducers: {
    setSelectedholding: (state, action) => {
      state.selected_holding = action.payload;
    },
    clearStateholding: (state, action) => {
      state.holding = [];
      state.is_loading_holding = false;
      state.is_creating_holding = false;
      state.is_deleting_holding = false;
    },
    mergeholding: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_holding = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.holding = merge(state.holding, payload) as any;
        }
      }
    },

    add_holding: (state, action) => {
      if (action.payload) {
        state.holding = [...state.holding, action.payload];
      }
    },
    set_holding: (state, action) => {
      let { key, value, id } = action.payload;
      state.holding = state.holding.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadHoldingsAsync.pending, (state) => {
      state.is_loading_holding = true;
    });
    builder.addCase(LoadHoldingsAsync.fulfilled, (state, action) => {
      state.is_loading_holding = false;
      if (action.payload) {
        state.holding = action.payload;
      }
    });
    builder.addCase(LoadHoldingsAsync.rejected, (state, action) => {
      state.is_loading_holding = false;
    });
    builder.addCase(CreateHoldingAsync.pending, (state) => {
      state.is_creating_holding = true;
    });
    builder.addCase(CreateHoldingAsync.fulfilled, (state) => {
      state.is_creating_holding = false;
    });
    builder.addCase(CreateHoldingAsync.rejected, (state) => {
      state.is_creating_holding = false;
    });
    builder.addCase(DeleteHoldingAsync.pending, (state) => {
      state.is_deleting_holding = true;
    });
    builder.addCase(DeleteHoldingAsync.fulfilled, (state, action) => {
      state.is_deleting_holding = false;
      if (state.holding) {
        state.holding = state.holding.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteHoldingAsync.rejected, (state, action) => {
      state.is_deleting_holding = false;
    });
  }
});

function handleResult(action: any, state: holdingSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_holding = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.holding = merge(state.holding, payload) as any;
    }
  }
}

export const { clearStateholding, setSelectedholding, set_holding, mergeholding, add_holding } = holdingSlice.actions;

export const get_holding =
  (id?: string) =>
  (state: {
    holding: {
      holding: Holding[];
    };
  }): Holding | null => {
    let temp = state?.holding?.holding?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.holding?.holding[0] || null;
  };
export const get_selected_holding = (state: { holding: holdingSliceState }): Holding | null => {
  let id = state?.holding?.selected_holding;
  let temp = state?.holding?.holding?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_holding = (state: {
  holding: {
    holding: Holding[];
  };
}): Holding[] => {
  let temp = state?.holding?.holding;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_holding_busy = (state: { holding: holdingSliceState }) => {
  return state?.holding.is_deleting_holding || state?.holding.is_loading_holding || state?.holding.is_creating_holding;
};
export const is_deleting_holding_busy = (state: { holding: holdingSliceState }) => {
  return state?.holding.is_deleting_holding;
};
export const is_loading_holding_busy = (state: { holding: holdingSliceState }) => {
  return state?.holding.is_loading_holding;
};
export const is_creating_holding_busy = (state: { holding: holdingSliceState }) => {
  return state?.holding.is_creating_holding;
};

export default holdingSlice.reducer;
