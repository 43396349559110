import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import Bank from "./bank-model";
import { BankProperties, BankMetaProperties } from "./bank-property-definitions";

export async function load_banks(args: { uid: string }): Promise<Bank[]> {
  throw "Not implemented";
}

export async function delete_bank(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_bank(args: Bank): Promise<Bank> {
  throw "Not implemented";
}
