import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import VirtualEmployee from "./virtual-employee-model";
import { VirtualEmployeeProperties, VirtualEmployeeMetaProperties } from "./virtualEmployee-property-definitions";

export async function load_virtualEmployees($args: { uid: string }): Promise<VirtualEmployee[]> {
  let { db } = getReqs();
  let { uid } = $args;
  let args = { ...$args };
  let collection = firestore.collection(db, `virtualEmployees`, `${uid}`, "virtualEmployee");
  let result: VirtualEmployee[] = [];
  await firestore
    .getDocs(firestore.query(collection))
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        result.push(convertTovirtualEmployee(doc));
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });
  return result.filter((x) => x);
}

function convertTovirtualEmployee(doc: any) {
  let temp: any = {
    id: doc.id,
    ...doc.data()
  };
  temp = {
    ...temp,
    fire_id: doc.id,
    id: doc.id
  };
  setupCreateUpdateClocks(temp);

  return temp;
}

export async function delete_virtualEmployee(args: { uid: string; id: string }): Promise<boolean> {
  let { db } = getReqs();
  if (args.uid) {
    let obj = firestore.doc(db, `virtualEmployees`, `${args.uid}`, "virtualEmployee", args.id);
    await deleteDoc(obj);
    return true;
  }
  return false;
}

export async function store_virtualEmployee(args: VirtualEmployee): Promise<VirtualEmployee> {
  let { db } = getReqs();
  let { owner, fire_id } = args;
  if (fire_id) {
    // update
    let virtualEmployee_collection = firestore.doc(db, `virtualEmployees`, `${owner}`, "virtualEmployee", fire_id);
    let temp: any = {
      version: (args.version || 0) + 1,
      owner
    };
    [...Object.values(VirtualEmployeeProperties), ...Object.values(VirtualEmployeeMetaProperties)].forEach((prop) => {
      temp[prop] = (args as any)[prop];

      if (temp[prop] === undefined) {
        temp[prop] = null;
      }
    });
    await updateDoc(virtualEmployee_collection, {
      ...temp,
      updated: serverTimestamp()
    });

    return temp;
  } else {
    // add
    let collection = firestore.collection(db, `virtualEmployees`, `${owner}`, "virtualEmployee");
    let temp: any = {
      version: (args.version || 0) + 1,
      owner
    };
    [...Object.values(VirtualEmployeeProperties), ...Object.values(VirtualEmployeeMetaProperties)].forEach((prop) => {
      temp[prop] = (args as any)[prop];

      if (temp[prop] === undefined) {
        temp[prop] = null;
      }
    });
    const docRef = await addDoc(collection, {
      ...temp,
      created: serverTimestamp()
    });

    console.log("Document written with ID: ", docRef.id);
    temp.id = docRef.id;
    temp.fire_id = docRef.id;
    return temp;
  }
}
