import { createAsyncThunk } from "@reduxjs/toolkit";
import PushNotification from "./push-notification-model";
import { delete_pushNotification, load_pushNotifications, store_pushNotification } from "./pushNotification-service";

export const LoadPushNotificationsAsync = createAsyncThunk<any, any>("composer/load/pushNotification", async (params: { uid: string }) => {
  return await load_pushNotifications(params);
});

export const CreatePushNotificationAsync = createAsyncThunk<any, any>("composer/create/pushNotification", async (params: PushNotification) => {
  return await store_pushNotification(params);
});

export const DeletePushNotificationAsync = createAsyncThunk<any, any>("composer/delete/pushNotification", async (params) => {
  return await delete_pushNotification(params);
});
