import { createAsyncThunk } from "@reduxjs/toolkit";
import VirtualEmployee from "./virtual-employee-model";
import { delete_virtualEmployee, load_virtualEmployees, store_virtualEmployee } from "./virtualEmployee-service";

export const LoadVirtualEmployeesAsync = createAsyncThunk<any, any>("composer/load/virtualEmployee", async (params: { uid: string }) => {
  return await load_virtualEmployees(params);
});

export const CreateVirtualEmployeeAsync = createAsyncThunk<any, any>("composer/create/virtualEmployee", async (params: VirtualEmployee) => {
  return await store_virtualEmployee(params);
});

export const DeleteVirtualEmployeeAsync = createAsyncThunk<any, any>("composer/delete/virtualEmployee", async (params) => {
  return await delete_virtualEmployee(params);
});
