import VirtualEmploymentRecord from "./virtual-employment-record-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateVirtualEmploymentRecordAsync, DeleteVirtualEmploymentRecordAsync, LoadVirtualEmploymentRecordsAsync } from "./virtualEmploymentRecord-thunk";
const initialState: virtualEmploymentRecordSliceState = {
  virtualEmploymentRecord: [],
  selected_virtualEmploymentRecord: "",
  is_loading_virtualEmploymentRecord: false,
  is_creating_virtualEmploymentRecord: false,
  is_deleting_virtualEmploymentRecord: false
};
export interface virtualEmploymentRecordSliceState {
  virtualEmploymentRecord: VirtualEmploymentRecord[];
  selected_virtualEmploymentRecord?: string;
  is_loading_virtualEmploymentRecord: boolean;
  is_creating_virtualEmploymentRecord: boolean;
  is_deleting_virtualEmploymentRecord: boolean;
}
export const virtualEmploymentRecordSlice = createSlice({
  name: "virtualEmploymentRecord",
  initialState,
  reducers: {
    setSelectedvirtualEmploymentRecord: (state, action) => {
      state.selected_virtualEmploymentRecord = action.payload;
    },
    clearStatevirtualEmploymentRecord: (state, action) => {
      state.virtualEmploymentRecord = [];
      state.is_loading_virtualEmploymentRecord = false;
      state.is_creating_virtualEmploymentRecord = false;
      state.is_deleting_virtualEmploymentRecord = false;
    },
    mergevirtualEmploymentRecord: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_virtualEmploymentRecord = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.virtualEmploymentRecord = merge(state.virtualEmploymentRecord, payload) as any;
        }
      }
    },

    add_virtualEmploymentRecord: (state, action) => {
      if (action.payload) {
        state.virtualEmploymentRecord = [...state.virtualEmploymentRecord, action.payload];
      }
    },
    set_virtualEmploymentRecord: (state, action) => {
      let { key, value, id } = action.payload;
      state.virtualEmploymentRecord = state.virtualEmploymentRecord.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadVirtualEmploymentRecordsAsync.pending, (state) => {
      state.is_loading_virtualEmploymentRecord = true;
    });
    builder.addCase(LoadVirtualEmploymentRecordsAsync.fulfilled, (state, action) => {
      state.is_loading_virtualEmploymentRecord = false;
      if (action.payload) {
        state.virtualEmploymentRecord = action.payload;
      }
    });
    builder.addCase(LoadVirtualEmploymentRecordsAsync.rejected, (state, action) => {
      state.is_loading_virtualEmploymentRecord = false;
    });
    builder.addCase(CreateVirtualEmploymentRecordAsync.pending, (state) => {
      state.is_creating_virtualEmploymentRecord = true;
    });
    builder.addCase(CreateVirtualEmploymentRecordAsync.fulfilled, (state) => {
      state.is_creating_virtualEmploymentRecord = false;
    });
    builder.addCase(CreateVirtualEmploymentRecordAsync.rejected, (state) => {
      state.is_creating_virtualEmploymentRecord = false;
    });
    builder.addCase(DeleteVirtualEmploymentRecordAsync.pending, (state) => {
      state.is_deleting_virtualEmploymentRecord = true;
    });
    builder.addCase(DeleteVirtualEmploymentRecordAsync.fulfilled, (state, action) => {
      state.is_deleting_virtualEmploymentRecord = false;
      if (state.virtualEmploymentRecord) {
        state.virtualEmploymentRecord = state.virtualEmploymentRecord.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteVirtualEmploymentRecordAsync.rejected, (state, action) => {
      state.is_deleting_virtualEmploymentRecord = false;
    });

    builder.addCase(autoThunks.get_virtual_employee_record.pending, (state) => {
      state.is_loading_virtualEmploymentRecord = true;
    });
    builder.addCase(autoThunks.get_virtual_employee_record.fulfilled, (state, action) => {
      state.is_loading_virtualEmploymentRecord = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_virtual_employee_record.rejected, (state, action) => {
      state.is_loading_virtualEmploymentRecord = false;
    });

    builder.addCase(autoThunks.get_virtual_employee_records.pending, (state) => {
      state.is_loading_virtualEmploymentRecord = true;
    });
    builder.addCase(autoThunks.get_virtual_employee_records.fulfilled, (state, action) => {
      state.is_loading_virtualEmploymentRecord = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_virtual_employee_records.rejected, (state, action) => {
      state.is_loading_virtualEmploymentRecord = false;
    });
  }
});

function handleResult(action: any, state: virtualEmploymentRecordSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_virtualEmploymentRecord = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.virtualEmploymentRecord = merge(state.virtualEmploymentRecord, payload) as any;
    }
  }
}

export const {
  clearStatevirtualEmploymentRecord,
  setSelectedvirtualEmploymentRecord,
  set_virtualEmploymentRecord,
  mergevirtualEmploymentRecord,
  add_virtualEmploymentRecord
} = virtualEmploymentRecordSlice.actions;

export const get_virtualEmploymentRecord =
  (id?: string) =>
  (state: {
    virtualEmploymentRecord: {
      virtualEmploymentRecord: VirtualEmploymentRecord[];
    };
  }): VirtualEmploymentRecord | null => {
    let temp = state?.virtualEmploymentRecord?.virtualEmploymentRecord?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.virtualEmploymentRecord?.virtualEmploymentRecord[0] || null;
  };
export const get_selected_virtualEmploymentRecord = (state: { virtualEmploymentRecord: virtualEmploymentRecordSliceState }): VirtualEmploymentRecord | null => {
  let id = state?.virtualEmploymentRecord?.selected_virtualEmploymentRecord;
  let temp = state?.virtualEmploymentRecord?.virtualEmploymentRecord?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_virtualEmploymentRecord = (state: {
  virtualEmploymentRecord: {
    virtualEmploymentRecord: VirtualEmploymentRecord[];
  };
}): VirtualEmploymentRecord[] => {
  let temp = state?.virtualEmploymentRecord?.virtualEmploymentRecord;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_virtualEmploymentRecord_busy = (state: { virtualEmploymentRecord: virtualEmploymentRecordSliceState }) => {
  return (
    state?.virtualEmploymentRecord.is_deleting_virtualEmploymentRecord ||
    state?.virtualEmploymentRecord.is_loading_virtualEmploymentRecord ||
    state?.virtualEmploymentRecord.is_creating_virtualEmploymentRecord
  );
};
export const is_deleting_virtualEmploymentRecord_busy = (state: { virtualEmploymentRecord: virtualEmploymentRecordSliceState }) => {
  return state?.virtualEmploymentRecord.is_deleting_virtualEmploymentRecord;
};
export const is_loading_virtualEmploymentRecord_busy = (state: { virtualEmploymentRecord: virtualEmploymentRecordSliceState }) => {
  return state?.virtualEmploymentRecord.is_loading_virtualEmploymentRecord;
};
export const is_creating_virtualEmploymentRecord_busy = (state: { virtualEmploymentRecord: virtualEmploymentRecordSliceState }) => {
  return state?.virtualEmploymentRecord.is_creating_virtualEmploymentRecord;
};

export default virtualEmploymentRecordSlice.reducer;
