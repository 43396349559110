import Stock from "./stock-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateStockAsync, DeleteStockAsync, LoadStocksAsync } from "./stock-thunk";
const initialState: stockSliceState = {
  stock: [],
  selected_stock: "",
  is_loading_stock: false,
  is_creating_stock: false,
  is_deleting_stock: false
};
export interface stockSliceState {
  stock: Stock[];
  selected_stock?: string;
  is_loading_stock: boolean;
  is_creating_stock: boolean;
  is_deleting_stock: boolean;
}
export const stockSlice = createSlice({
  name: "stock",
  initialState,
  reducers: {
    setSelectedstock: (state, action) => {
      state.selected_stock = action.payload;
    },
    clearStatestock: (state, action) => {
      state.stock = [];
      state.is_loading_stock = false;
      state.is_creating_stock = false;
      state.is_deleting_stock = false;
    },
    mergestock: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_stock = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.stock = merge(state.stock, payload) as any;
        }
      }
    },

    add_stock: (state, action) => {
      if (action.payload) {
        state.stock = [...state.stock, action.payload];
      }
    },
    set_stock: (state, action) => {
      let { key, value, id } = action.payload;
      state.stock = state.stock.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadStocksAsync.pending, (state) => {
      state.is_loading_stock = true;
    });
    builder.addCase(LoadStocksAsync.fulfilled, (state, action) => {
      state.is_loading_stock = false;
      if (action.payload) {
        state.stock = action.payload;
      }
    });
    builder.addCase(LoadStocksAsync.rejected, (state, action) => {
      state.is_loading_stock = false;
    });
    builder.addCase(CreateStockAsync.pending, (state) => {
      state.is_creating_stock = true;
    });
    builder.addCase(CreateStockAsync.fulfilled, (state) => {
      state.is_creating_stock = false;
    });
    builder.addCase(CreateStockAsync.rejected, (state) => {
      state.is_creating_stock = false;
    });
    builder.addCase(DeleteStockAsync.pending, (state) => {
      state.is_deleting_stock = true;
    });
    builder.addCase(DeleteStockAsync.fulfilled, (state, action) => {
      state.is_deleting_stock = false;
      if (state.stock) {
        state.stock = state.stock.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteStockAsync.rejected, (state, action) => {
      state.is_deleting_stock = false;
    });

    builder.addCase(autoThunks.request_stock_information_on.pending, (state) => {
      state.is_loading_stock = true;
    });
    builder.addCase(autoThunks.request_stock_information_on.fulfilled, (state, action) => {
      state.is_loading_stock = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.request_stock_information_on.rejected, (state, action) => {
      state.is_loading_stock = false;
    });

    builder.addCase(autoThunks.load_stocks.pending, (state) => {
      state.is_loading_stock = true;
    });
    builder.addCase(autoThunks.load_stocks.fulfilled, (state, action) => {
      state.is_loading_stock = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.load_stocks.rejected, (state, action) => {
      state.is_loading_stock = false;
    });
  }
});

function handleResult(action: any, state: stockSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_stock = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.stock = merge(state.stock, payload) as any;
    }
  }
}

export const { clearStatestock, setSelectedstock, set_stock, mergestock, add_stock } = stockSlice.actions;

export const get_stock =
  (id?: string) =>
  (state: {
    stock: {
      stock: Stock[];
    };
  }): Stock | null => {
    let temp = state?.stock?.stock?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.stock?.stock[0] || null;
  };
export const get_selected_stock = (state: { stock: stockSliceState }): Stock | null => {
  let id = state?.stock?.selected_stock;
  let temp = state?.stock?.stock?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_stock = (state: {
  stock: {
    stock: Stock[];
  };
}): Stock[] => {
  let temp = state?.stock?.stock;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_stock_busy = (state: { stock: stockSliceState }) => {
  return state?.stock.is_deleting_stock || state?.stock.is_loading_stock || state?.stock.is_creating_stock;
};
export const is_deleting_stock_busy = (state: { stock: stockSliceState }) => {
  return state?.stock.is_deleting_stock;
};
export const is_loading_stock_busy = (state: { stock: stockSliceState }) => {
  return state?.stock.is_loading_stock;
};
export const is_creating_stock_busy = (state: { stock: stockSliceState }) => {
  return state?.stock.is_creating_stock;
};

export default stockSlice.reducer;
