import { createAsyncThunk } from "@reduxjs/toolkit";
import QuickConnect from "./quick-connect-model";
import { delete_quickConnect, load_quickConnects, store_quickConnect } from "./quickConnect-service";

export const LoadQuickConnectsAsync = createAsyncThunk<any, any>("composer/load/quickConnect", async (params: { uid: string }) => {
  return await load_quickConnects(params);
});

export const CreateQuickConnectAsync = createAsyncThunk<any, any>("composer/create/quickConnect", async (params: QuickConnect) => {
  return await store_quickConnect(params);
});

export const DeleteQuickConnectAsync = createAsyncThunk<any, any>("composer/delete/quickConnect", async (params) => {
  return await delete_quickConnect(params);
});
