import { createAsyncThunk } from "@reduxjs/toolkit";
import BusinessLoan from "./business-loan-model";
import { delete_businessLoan, load_businessLoans, store_businessLoan } from "./businessLoan-service";

export const LoadBusinessLoansAsync = createAsyncThunk<any, any>("composer/load/businessLoan", async (params: { uid: string }) => {
  return await load_businessLoans(params);
});

export const CreateBusinessLoanAsync = createAsyncThunk<any, any>("composer/create/businessLoan", async (params: BusinessLoan) => {
  return await store_businessLoan(params);
});

export const DeleteBusinessLoanAsync = createAsyncThunk<any, any>("composer/delete/businessLoan", async (params) => {
  return await delete_businessLoan(params);
});
