import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import VotingIssue from "./voting-issue-model";
import { VotingIssueProperties, VotingIssueMetaProperties } from "./votingIssue-property-definitions";

export async function load_votingIssues(args: { uid: string }): Promise<VotingIssue[]> {
  throw "Not implemented";
}

export async function delete_votingIssue(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_votingIssue(args: VotingIssue): Promise<VotingIssue> {
  throw "Not implemented";
}
