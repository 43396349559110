import ResourceBookPage from "./resource-book-page-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateResourceBookPageAsync, DeleteResourceBookPageAsync, LoadResourceBookPagesAsync } from "./resourceBookPage-thunk";
const initialState: resourceBookPageSliceState = {
  resourceBookPage: [],
  selected_resourceBookPage: "",
  is_loading_resourceBookPage: false,
  is_creating_resourceBookPage: false,
  is_deleting_resourceBookPage: false
};
export interface resourceBookPageSliceState {
  resourceBookPage: ResourceBookPage[];
  selected_resourceBookPage?: string;
  is_loading_resourceBookPage: boolean;
  is_creating_resourceBookPage: boolean;
  is_deleting_resourceBookPage: boolean;
}
export const resourceBookPageSlice = createSlice({
  name: "resourceBookPage",
  initialState,
  reducers: {
    setSelectedresourceBookPage: (state, action) => {
      state.selected_resourceBookPage = action.payload;
    },
    clearStateresourceBookPage: (state, action) => {
      state.resourceBookPage = [];
      state.is_loading_resourceBookPage = false;
      state.is_creating_resourceBookPage = false;
      state.is_deleting_resourceBookPage = false;
    },
    mergeresourceBookPage: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_resourceBookPage = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.resourceBookPage = merge(state.resourceBookPage, payload) as any;
        }
      }
    },

    add_resourceBookPage: (state, action) => {
      if (action.payload) {
        state.resourceBookPage = [...state.resourceBookPage, action.payload];
      }
    },
    set_resourceBookPage: (state, action) => {
      let { key, value, id } = action.payload;
      state.resourceBookPage = state.resourceBookPage.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadResourceBookPagesAsync.pending, (state) => {
      state.is_loading_resourceBookPage = true;
    });
    builder.addCase(LoadResourceBookPagesAsync.fulfilled, (state, action) => {
      state.is_loading_resourceBookPage = false;
      if (action.payload) {
        state.resourceBookPage = action.payload;
      }
    });
    builder.addCase(LoadResourceBookPagesAsync.rejected, (state, action) => {
      state.is_loading_resourceBookPage = false;
    });
    builder.addCase(CreateResourceBookPageAsync.pending, (state) => {
      state.is_creating_resourceBookPage = true;
    });
    builder.addCase(CreateResourceBookPageAsync.fulfilled, (state) => {
      state.is_creating_resourceBookPage = false;
    });
    builder.addCase(CreateResourceBookPageAsync.rejected, (state) => {
      state.is_creating_resourceBookPage = false;
    });
    builder.addCase(DeleteResourceBookPageAsync.pending, (state) => {
      state.is_deleting_resourceBookPage = true;
    });
    builder.addCase(DeleteResourceBookPageAsync.fulfilled, (state, action) => {
      state.is_deleting_resourceBookPage = false;
      if (state.resourceBookPage) {
        state.resourceBookPage = state.resourceBookPage.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteResourceBookPageAsync.rejected, (state, action) => {
      state.is_deleting_resourceBookPage = false;
    });

    builder.addCase(autoThunks.get_resource_book_pages.pending, (state) => {
      state.is_loading_resourceBookPage = true;
    });
    builder.addCase(autoThunks.get_resource_book_pages.fulfilled, (state, action) => {
      state.is_loading_resourceBookPage = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_resource_book_pages.rejected, (state, action) => {
      state.is_loading_resourceBookPage = false;
    });
  }
});

function handleResult(action: any, state: resourceBookPageSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_resourceBookPage = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.resourceBookPage = merge(state.resourceBookPage, payload) as any;
    }
  }
}

export const { clearStateresourceBookPage, setSelectedresourceBookPage, set_resourceBookPage, mergeresourceBookPage, add_resourceBookPage } =
  resourceBookPageSlice.actions;

export const get_resourceBookPage =
  (id?: string) =>
  (state: {
    resourceBookPage: {
      resourceBookPage: ResourceBookPage[];
    };
  }): ResourceBookPage | null => {
    let temp = state?.resourceBookPage?.resourceBookPage?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.resourceBookPage?.resourceBookPage[0] || null;
  };
export const get_selected_resourceBookPage = (state: { resourceBookPage: resourceBookPageSliceState }): ResourceBookPage | null => {
  let id = state?.resourceBookPage?.selected_resourceBookPage;
  let temp = state?.resourceBookPage?.resourceBookPage?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_resourceBookPage = (state: {
  resourceBookPage: {
    resourceBookPage: ResourceBookPage[];
  };
}): ResourceBookPage[] => {
  let temp = state?.resourceBookPage?.resourceBookPage;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_resourceBookPage_busy = (state: { resourceBookPage: resourceBookPageSliceState }) => {
  return (
    state?.resourceBookPage.is_deleting_resourceBookPage ||
    state?.resourceBookPage.is_loading_resourceBookPage ||
    state?.resourceBookPage.is_creating_resourceBookPage
  );
};
export const is_deleting_resourceBookPage_busy = (state: { resourceBookPage: resourceBookPageSliceState }) => {
  return state?.resourceBookPage.is_deleting_resourceBookPage;
};
export const is_loading_resourceBookPage_busy = (state: { resourceBookPage: resourceBookPageSliceState }) => {
  return state?.resourceBookPage.is_loading_resourceBookPage;
};
export const is_creating_resourceBookPage_busy = (state: { resourceBookPage: resourceBookPageSliceState }) => {
  return state?.resourceBookPage.is_creating_resourceBookPage;
};

export default resourceBookPageSlice.reducer;
