import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import BankAccountJoinRequest from "./bank-account-join-request-model";
import { BankAccountJoinRequestProperties, BankAccountJoinRequestMetaProperties } from "./bankAccountJoinRequest-property-definitions";

export async function load_bankAccountJoinRequests(args: { uid: string }): Promise<BankAccountJoinRequest[]> {
  throw "Not implemented";
}

export async function delete_bankAccountJoinRequest(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_bankAccountJoinRequest(args: BankAccountJoinRequest): Promise<BankAccountJoinRequest> {
  throw "Not implemented";
}
