import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import CourseResourceBlock from "./course-resource-block-model";
import { CourseResourceBlockProperties, CourseResourceBlockMetaProperties } from "./courseResourceBlock-property-definitions";

export async function load_courseResourceBlocks(args: { uid: string }): Promise<CourseResourceBlock[]> {
  throw "Not implemented";
}

export async function delete_courseResourceBlock(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_courseResourceBlock(args: CourseResourceBlock): Promise<CourseResourceBlock> {
  throw "Not implemented";
}
