import { createAsyncThunk } from "@reduxjs/toolkit";
import BankerRole from "./banker-role-model";
import { delete_bankerRole, load_bankerRoles, store_bankerRole } from "./bankerRole-service";

export const LoadBankerRolesAsync = createAsyncThunk<any, any>("composer/load/bankerRole", async (params: { uid: string }) => {
  return await load_bankerRoles(params);
});

export const CreateBankerRoleAsync = createAsyncThunk<any, any>("composer/create/bankerRole", async (params: BankerRole) => {
  return await store_bankerRole(params);
});

export const DeleteBankerRoleAsync = createAsyncThunk<any, any>("composer/delete/bankerRole", async (params) => {
  return await delete_bankerRole(params);
});
