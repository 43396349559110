import VirtualBusinessInvestorChange from "./virtual-business-investor-change-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import {
  CreateVirtualBusinessInvestorChangeAsync,
  DeleteVirtualBusinessInvestorChangeAsync,
  LoadVirtualBusinessInvestorChangesAsync
} from "./virtualBusinessInvestorChange-thunk";
const initialState: virtualBusinessInvestorChangeSliceState = {
  virtualBusinessInvestorChange: [],
  selected_virtualBusinessInvestorChange: "",
  is_loading_virtualBusinessInvestorChange: false,
  is_creating_virtualBusinessInvestorChange: false,
  is_deleting_virtualBusinessInvestorChange: false
};
export interface virtualBusinessInvestorChangeSliceState {
  virtualBusinessInvestorChange: VirtualBusinessInvestorChange[];
  selected_virtualBusinessInvestorChange?: string;
  is_loading_virtualBusinessInvestorChange: boolean;
  is_creating_virtualBusinessInvestorChange: boolean;
  is_deleting_virtualBusinessInvestorChange: boolean;
}
export const virtualBusinessInvestorChangeSlice = createSlice({
  name: "virtualBusinessInvestorChange",
  initialState,
  reducers: {
    setSelectedvirtualBusinessInvestorChange: (state, action) => {
      state.selected_virtualBusinessInvestorChange = action.payload;
    },
    clearStatevirtualBusinessInvestorChange: (state, action) => {
      state.virtualBusinessInvestorChange = [];
      state.is_loading_virtualBusinessInvestorChange = false;
      state.is_creating_virtualBusinessInvestorChange = false;
      state.is_deleting_virtualBusinessInvestorChange = false;
    },
    mergevirtualBusinessInvestorChange: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_virtualBusinessInvestorChange = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.virtualBusinessInvestorChange = merge(state.virtualBusinessInvestorChange, payload) as any;
        }
      }
    },

    add_virtualBusinessInvestorChange: (state, action) => {
      if (action.payload) {
        state.virtualBusinessInvestorChange = [...state.virtualBusinessInvestorChange, action.payload];
      }
    },
    set_virtualBusinessInvestorChange: (state, action) => {
      let { key, value, id } = action.payload;
      state.virtualBusinessInvestorChange = state.virtualBusinessInvestorChange.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadVirtualBusinessInvestorChangesAsync.pending, (state) => {
      state.is_loading_virtualBusinessInvestorChange = true;
    });
    builder.addCase(LoadVirtualBusinessInvestorChangesAsync.fulfilled, (state, action) => {
      state.is_loading_virtualBusinessInvestorChange = false;
      if (action.payload) {
        state.virtualBusinessInvestorChange = action.payload;
      }
    });
    builder.addCase(LoadVirtualBusinessInvestorChangesAsync.rejected, (state, action) => {
      state.is_loading_virtualBusinessInvestorChange = false;
    });
    builder.addCase(CreateVirtualBusinessInvestorChangeAsync.pending, (state) => {
      state.is_creating_virtualBusinessInvestorChange = true;
    });
    builder.addCase(CreateVirtualBusinessInvestorChangeAsync.fulfilled, (state) => {
      state.is_creating_virtualBusinessInvestorChange = false;
    });
    builder.addCase(CreateVirtualBusinessInvestorChangeAsync.rejected, (state) => {
      state.is_creating_virtualBusinessInvestorChange = false;
    });
    builder.addCase(DeleteVirtualBusinessInvestorChangeAsync.pending, (state) => {
      state.is_deleting_virtualBusinessInvestorChange = true;
    });
    builder.addCase(DeleteVirtualBusinessInvestorChangeAsync.fulfilled, (state, action) => {
      state.is_deleting_virtualBusinessInvestorChange = false;
      if (state.virtualBusinessInvestorChange) {
        state.virtualBusinessInvestorChange = state.virtualBusinessInvestorChange.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteVirtualBusinessInvestorChangeAsync.rejected, (state, action) => {
      state.is_deleting_virtualBusinessInvestorChange = false;
    });
  }
});

function handleResult(action: any, state: virtualBusinessInvestorChangeSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_virtualBusinessInvestorChange = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.virtualBusinessInvestorChange = merge(state.virtualBusinessInvestorChange, payload) as any;
    }
  }
}

export const {
  clearStatevirtualBusinessInvestorChange,
  setSelectedvirtualBusinessInvestorChange,
  set_virtualBusinessInvestorChange,
  mergevirtualBusinessInvestorChange,
  add_virtualBusinessInvestorChange
} = virtualBusinessInvestorChangeSlice.actions;

export const get_virtualBusinessInvestorChange =
  (id?: string) =>
  (state: {
    virtualBusinessInvestorChange: {
      virtualBusinessInvestorChange: VirtualBusinessInvestorChange[];
    };
  }): VirtualBusinessInvestorChange | null => {
    let temp = state?.virtualBusinessInvestorChange?.virtualBusinessInvestorChange?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.virtualBusinessInvestorChange?.virtualBusinessInvestorChange[0] || null;
  };
export const get_selected_virtualBusinessInvestorChange = (state: {
  virtualBusinessInvestorChange: virtualBusinessInvestorChangeSliceState;
}): VirtualBusinessInvestorChange | null => {
  let id = state?.virtualBusinessInvestorChange?.selected_virtualBusinessInvestorChange;
  let temp = state?.virtualBusinessInvestorChange?.virtualBusinessInvestorChange?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_virtualBusinessInvestorChange = (state: {
  virtualBusinessInvestorChange: {
    virtualBusinessInvestorChange: VirtualBusinessInvestorChange[];
  };
}): VirtualBusinessInvestorChange[] => {
  let temp = state?.virtualBusinessInvestorChange?.virtualBusinessInvestorChange;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_virtualBusinessInvestorChange_busy = (state: { virtualBusinessInvestorChange: virtualBusinessInvestorChangeSliceState }) => {
  return (
    state?.virtualBusinessInvestorChange.is_deleting_virtualBusinessInvestorChange ||
    state?.virtualBusinessInvestorChange.is_loading_virtualBusinessInvestorChange ||
    state?.virtualBusinessInvestorChange.is_creating_virtualBusinessInvestorChange
  );
};
export const is_deleting_virtualBusinessInvestorChange_busy = (state: { virtualBusinessInvestorChange: virtualBusinessInvestorChangeSliceState }) => {
  return state?.virtualBusinessInvestorChange.is_deleting_virtualBusinessInvestorChange;
};
export const is_loading_virtualBusinessInvestorChange_busy = (state: { virtualBusinessInvestorChange: virtualBusinessInvestorChangeSliceState }) => {
  return state?.virtualBusinessInvestorChange.is_loading_virtualBusinessInvestorChange;
};
export const is_creating_virtualBusinessInvestorChange_busy = (state: { virtualBusinessInvestorChange: virtualBusinessInvestorChangeSliceState }) => {
  return state?.virtualBusinessInvestorChange.is_creating_virtualBusinessInvestorChange;
};

export default virtualBusinessInvestorChangeSlice.reducer;
