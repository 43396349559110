import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import CityAsset from "./city-asset-model";
import { CityAssetProperties, CityAssetMetaProperties } from "./cityAsset-property-definitions";

export async function load_cityAssets(args: { uid: string }): Promise<CityAsset[]> {
  throw "Not implemented";
}

export async function delete_cityAsset(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_cityAsset(args: CityAsset): Promise<CityAsset> {
  throw "Not implemented";
}
