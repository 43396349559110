import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import GeographicalRegion from "./geographical-region-model";
import { GeographicalRegionProperties, GeographicalRegionMetaProperties } from "./geographicalRegion-property-definitions";

export async function load_geographicalRegions(args: { uid: string }): Promise<GeographicalRegion[]> {
  throw "Not implemented";
}

export async function delete_geographicalRegion(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_geographicalRegion(args: GeographicalRegion): Promise<GeographicalRegion> {
  throw "Not implemented";
}
