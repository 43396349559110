import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import VirtualBusinessPartnerInvitation from "./virtual-business-partner-invitation-model";
import {
  VirtualBusinessPartnerInvitationProperties,
  VirtualBusinessPartnerInvitationMetaProperties
} from "./virtualBusinessPartnerInvitation-property-definitions";

export async function load_virtualBusinessPartnerInvitations(args: { uid: string }): Promise<VirtualBusinessPartnerInvitation[]> {
  throw "Not implemented";
}

export async function delete_virtualBusinessPartnerInvitation(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_virtualBusinessPartnerInvitation(args: VirtualBusinessPartnerInvitation): Promise<VirtualBusinessPartnerInvitation> {
  throw "Not implemented";
}
