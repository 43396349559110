import { createAsyncThunk } from "@reduxjs/toolkit";
import ResourceUserAvatarList from "./resource-user-avatar-list-model";
import { delete_resourceUserAvatarList, load_resourceUserAvatarLists, store_resourceUserAvatarList } from "./resourceUserAvatarList-service";

export const LoadResourceUserAvatarListsAsync = createAsyncThunk<any, any>("composer/load/resourceUserAvatarList", async (params: { uid: string }) => {
  return await load_resourceUserAvatarLists(params);
});

export const CreateResourceUserAvatarListAsync = createAsyncThunk<any, any>(
  "composer/create/resourceUserAvatarList",
  async (params: ResourceUserAvatarList) => {
    return await store_resourceUserAvatarList(params);
  }
);

export const DeleteResourceUserAvatarListAsync = createAsyncThunk<any, any>("composer/delete/resourceUserAvatarList", async (params) => {
  return await delete_resourceUserAvatarList(params);
});
