import { createAsyncThunk } from "@reduxjs/toolkit";
import AccountLogCommand from "./account-log-command-model";
import { delete_accountLogCommand, load_accountLogCommands, store_accountLogCommand } from "./accountLogCommand-service";

export const LoadAccountLogCommandsAsync = createAsyncThunk<any, any>("composer/load/accountLogCommand", async (params: { uid: string }) => {
  return await load_accountLogCommands(params);
});

export const CreateAccountLogCommandAsync = createAsyncThunk<any, any>("composer/create/accountLogCommand", async (params: AccountLogCommand) => {
  return await store_accountLogCommand(params);
});

export const DeleteAccountLogCommandAsync = createAsyncThunk<any, any>("composer/delete/accountLogCommand", async (params) => {
  return await delete_accountLogCommand(params);
});
