import VirtualShareEscroOffer from "./virtual-share-escro-offer-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateVirtualShareEscroOfferAsync, DeleteVirtualShareEscroOfferAsync, LoadVirtualShareEscroOffersAsync } from "./virtualShareEscroOffer-thunk";
const initialState: virtualShareEscroOfferSliceState = {
  virtualShareEscroOffer: [],
  selected_virtualShareEscroOffer: "",
  is_loading_virtualShareEscroOffer: false,
  is_creating_virtualShareEscroOffer: false,
  is_deleting_virtualShareEscroOffer: false
};
export interface virtualShareEscroOfferSliceState {
  virtualShareEscroOffer: VirtualShareEscroOffer[];
  selected_virtualShareEscroOffer?: string;
  is_loading_virtualShareEscroOffer: boolean;
  is_creating_virtualShareEscroOffer: boolean;
  is_deleting_virtualShareEscroOffer: boolean;
}
export const virtualShareEscroOfferSlice = createSlice({
  name: "virtualShareEscroOffer",
  initialState,
  reducers: {
    setSelectedvirtualShareEscroOffer: (state, action) => {
      state.selected_virtualShareEscroOffer = action.payload;
    },
    clearStatevirtualShareEscroOffer: (state, action) => {
      state.virtualShareEscroOffer = [];
      state.is_loading_virtualShareEscroOffer = false;
      state.is_creating_virtualShareEscroOffer = false;
      state.is_deleting_virtualShareEscroOffer = false;
    },
    mergevirtualShareEscroOffer: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_virtualShareEscroOffer = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.virtualShareEscroOffer = merge(state.virtualShareEscroOffer, payload) as any;
        }
      }
    },

    add_virtualShareEscroOffer: (state, action) => {
      if (action.payload) {
        state.virtualShareEscroOffer = [...state.virtualShareEscroOffer, action.payload];
      }
    },
    set_virtualShareEscroOffer: (state, action) => {
      let { key, value, id } = action.payload;
      state.virtualShareEscroOffer = state.virtualShareEscroOffer.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadVirtualShareEscroOffersAsync.pending, (state) => {
      state.is_loading_virtualShareEscroOffer = true;
    });
    builder.addCase(LoadVirtualShareEscroOffersAsync.fulfilled, (state, action) => {
      state.is_loading_virtualShareEscroOffer = false;
      if (action.payload) {
        state.virtualShareEscroOffer = action.payload;
      }
    });
    builder.addCase(LoadVirtualShareEscroOffersAsync.rejected, (state, action) => {
      state.is_loading_virtualShareEscroOffer = false;
    });
    builder.addCase(CreateVirtualShareEscroOfferAsync.pending, (state) => {
      state.is_creating_virtualShareEscroOffer = true;
    });
    builder.addCase(CreateVirtualShareEscroOfferAsync.fulfilled, (state) => {
      state.is_creating_virtualShareEscroOffer = false;
    });
    builder.addCase(CreateVirtualShareEscroOfferAsync.rejected, (state) => {
      state.is_creating_virtualShareEscroOffer = false;
    });
    builder.addCase(DeleteVirtualShareEscroOfferAsync.pending, (state) => {
      state.is_deleting_virtualShareEscroOffer = true;
    });
    builder.addCase(DeleteVirtualShareEscroOfferAsync.fulfilled, (state, action) => {
      state.is_deleting_virtualShareEscroOffer = false;
      if (state.virtualShareEscroOffer) {
        state.virtualShareEscroOffer = state.virtualShareEscroOffer.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteVirtualShareEscroOfferAsync.rejected, (state, action) => {
      state.is_deleting_virtualShareEscroOffer = false;
    });

    builder.addCase(autoThunks.make_offer_on_virtual_business_shares_on_sale.pending, (state) => {
      state.is_loading_virtualShareEscroOffer = true;
    });
    builder.addCase(autoThunks.make_offer_on_virtual_business_shares_on_sale.fulfilled, (state, action) => {
      state.is_loading_virtualShareEscroOffer = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.make_offer_on_virtual_business_shares_on_sale.rejected, (state, action) => {
      state.is_loading_virtualShareEscroOffer = false;
    });

    builder.addCase(autoThunks.get_offers_on_virtual_business_shares_on_sale.pending, (state) => {
      state.is_loading_virtualShareEscroOffer = true;
    });
    builder.addCase(autoThunks.get_offers_on_virtual_business_shares_on_sale.fulfilled, (state, action) => {
      state.is_loading_virtualShareEscroOffer = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_offers_on_virtual_business_shares_on_sale.rejected, (state, action) => {
      state.is_loading_virtualShareEscroOffer = false;
    });

    builder.addCase(autoThunks.accept_offer_on_virtual_business_shares_on_sale.pending, (state) => {
      state.is_loading_virtualShareEscroOffer = true;
    });
    builder.addCase(autoThunks.accept_offer_on_virtual_business_shares_on_sale.fulfilled, (state, action) => {
      state.is_loading_virtualShareEscroOffer = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.accept_offer_on_virtual_business_shares_on_sale.rejected, (state, action) => {
      state.is_loading_virtualShareEscroOffer = false;
    });

    builder.addCase(autoThunks.get_virtual_sharesinescro_for_bank.pending, (state) => {
      state.is_loading_virtualShareEscroOffer = true;
    });
    builder.addCase(autoThunks.get_virtual_sharesinescro_for_bank.fulfilled, (state, action) => {
      state.is_loading_virtualShareEscroOffer = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_virtual_sharesinescro_for_bank.rejected, (state, action) => {
      state.is_loading_virtualShareEscroOffer = false;
    });
  }
});

function handleResult(action: any, state: virtualShareEscroOfferSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_virtualShareEscroOffer = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.virtualShareEscroOffer = merge(state.virtualShareEscroOffer, payload) as any;
    }
  }
}

export const {
  clearStatevirtualShareEscroOffer,
  setSelectedvirtualShareEscroOffer,
  set_virtualShareEscroOffer,
  mergevirtualShareEscroOffer,
  add_virtualShareEscroOffer
} = virtualShareEscroOfferSlice.actions;

export const get_virtualShareEscroOffer =
  (id?: string) =>
  (state: {
    virtualShareEscroOffer: {
      virtualShareEscroOffer: VirtualShareEscroOffer[];
    };
  }): VirtualShareEscroOffer | null => {
    let temp = state?.virtualShareEscroOffer?.virtualShareEscroOffer?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.virtualShareEscroOffer?.virtualShareEscroOffer[0] || null;
  };
export const get_selected_virtualShareEscroOffer = (state: { virtualShareEscroOffer: virtualShareEscroOfferSliceState }): VirtualShareEscroOffer | null => {
  let id = state?.virtualShareEscroOffer?.selected_virtualShareEscroOffer;
  let temp = state?.virtualShareEscroOffer?.virtualShareEscroOffer?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_virtualShareEscroOffer = (state: {
  virtualShareEscroOffer: {
    virtualShareEscroOffer: VirtualShareEscroOffer[];
  };
}): VirtualShareEscroOffer[] => {
  let temp = state?.virtualShareEscroOffer?.virtualShareEscroOffer;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_virtualShareEscroOffer_busy = (state: { virtualShareEscroOffer: virtualShareEscroOfferSliceState }) => {
  return (
    state?.virtualShareEscroOffer.is_deleting_virtualShareEscroOffer ||
    state?.virtualShareEscroOffer.is_loading_virtualShareEscroOffer ||
    state?.virtualShareEscroOffer.is_creating_virtualShareEscroOffer
  );
};
export const is_deleting_virtualShareEscroOffer_busy = (state: { virtualShareEscroOffer: virtualShareEscroOfferSliceState }) => {
  return state?.virtualShareEscroOffer.is_deleting_virtualShareEscroOffer;
};
export const is_loading_virtualShareEscroOffer_busy = (state: { virtualShareEscroOffer: virtualShareEscroOfferSliceState }) => {
  return state?.virtualShareEscroOffer.is_loading_virtualShareEscroOffer;
};
export const is_creating_virtualShareEscroOffer_busy = (state: { virtualShareEscroOffer: virtualShareEscroOfferSliceState }) => {
  return state?.virtualShareEscroOffer.is_creating_virtualShareEscroOffer;
};

export default virtualShareEscroOfferSlice.reducer;
