import { createAsyncThunk } from "@reduxjs/toolkit";
import VirtualBusinessPetitionPartnershipDissolution from "./virtual-business-petition-partnership-dissolution-model";
import {
  delete_virtualBusinessPetitionPartnershipDissolution,
  load_virtualBusinessPetitionPartnershipDissolutions,
  store_virtualBusinessPetitionPartnershipDissolution
} from "./virtualBusinessPetitionPartnershipDissolution-service";

export const LoadVirtualBusinessPetitionPartnershipDissolutionsAsync = createAsyncThunk<any, any>(
  "composer/load/virtualBusinessPetitionPartnershipDissolution",
  async (params: { uid: string }) => {
    return await load_virtualBusinessPetitionPartnershipDissolutions(params);
  }
);

export const CreateVirtualBusinessPetitionPartnershipDissolutionAsync = createAsyncThunk<any, any>(
  "composer/create/virtualBusinessPetitionPartnershipDissolution",
  async (params: VirtualBusinessPetitionPartnershipDissolution) => {
    return await store_virtualBusinessPetitionPartnershipDissolution(params);
  }
);

export const DeleteVirtualBusinessPetitionPartnershipDissolutionAsync = createAsyncThunk<any, any>(
  "composer/delete/virtualBusinessPetitionPartnershipDissolution",
  async (params) => {
    return await delete_virtualBusinessPetitionPartnershipDissolution(params);
  }
);
