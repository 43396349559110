import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import District from "./district-model";
import { DistrictProperties, DistrictMetaProperties } from "./district-property-definitions";

export async function load_districts(args: { uid: string }): Promise<District[]> {
  throw "Not implemented";
}

export async function delete_district(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_district(args: District): Promise<District> {
  throw "Not implemented";
}
