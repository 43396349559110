import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import VirtualBusinessPetitionPartnershipDissolution from "./virtual-business-petition-partnership-dissolution-model";
import {
  VirtualBusinessPetitionPartnershipDissolutionProperties,
  VirtualBusinessPetitionPartnershipDissolutionMetaProperties
} from "./virtualBusinessPetitionPartnershipDissolution-property-definitions";

export async function load_virtualBusinessPetitionPartnershipDissolutions(args: { uid: string }): Promise<VirtualBusinessPetitionPartnershipDissolution[]> {
  throw "Not implemented";
}

export async function delete_virtualBusinessPetitionPartnershipDissolution(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_virtualBusinessPetitionPartnershipDissolution(
  args: VirtualBusinessPetitionPartnershipDissolution
): Promise<VirtualBusinessPetitionPartnershipDissolution> {
  throw "Not implemented";
}
