import MarketingMaterial from "./marketing-material-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateMarketingMaterialAsync, DeleteMarketingMaterialAsync, LoadMarketingMaterialsAsync } from "./marketingMaterial-thunk";
const initialState: marketingMaterialSliceState = {
  marketingMaterial: [],
  selected_marketingMaterial: "",
  is_loading_marketingMaterial: false,
  is_creating_marketingMaterial: false,
  is_deleting_marketingMaterial: false
};
export interface marketingMaterialSliceState {
  marketingMaterial: MarketingMaterial[];
  selected_marketingMaterial?: string;
  is_loading_marketingMaterial: boolean;
  is_creating_marketingMaterial: boolean;
  is_deleting_marketingMaterial: boolean;
}
export const marketingMaterialSlice = createSlice({
  name: "marketingMaterial",
  initialState,
  reducers: {
    setSelectedmarketingMaterial: (state, action) => {
      state.selected_marketingMaterial = action.payload;
    },
    clearStatemarketingMaterial: (state, action) => {
      state.marketingMaterial = [];
      state.is_loading_marketingMaterial = false;
      state.is_creating_marketingMaterial = false;
      state.is_deleting_marketingMaterial = false;
    },
    mergemarketingMaterial: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_marketingMaterial = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.marketingMaterial = merge(state.marketingMaterial, payload) as any;
        }
      }
    },

    add_marketingMaterial: (state, action) => {
      if (action.payload) {
        state.marketingMaterial = [...state.marketingMaterial, action.payload];
      }
    },
    set_marketingMaterial: (state, action) => {
      let { key, value, id } = action.payload;
      state.marketingMaterial = state.marketingMaterial.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadMarketingMaterialsAsync.pending, (state) => {
      state.is_loading_marketingMaterial = true;
    });
    builder.addCase(LoadMarketingMaterialsAsync.fulfilled, (state, action) => {
      state.is_loading_marketingMaterial = false;
      if (action.payload) {
        state.marketingMaterial = action.payload;
      }
    });
    builder.addCase(LoadMarketingMaterialsAsync.rejected, (state, action) => {
      state.is_loading_marketingMaterial = false;
    });
    builder.addCase(CreateMarketingMaterialAsync.pending, (state) => {
      state.is_creating_marketingMaterial = true;
    });
    builder.addCase(CreateMarketingMaterialAsync.fulfilled, (state) => {
      state.is_creating_marketingMaterial = false;
    });
    builder.addCase(CreateMarketingMaterialAsync.rejected, (state) => {
      state.is_creating_marketingMaterial = false;
    });
    builder.addCase(DeleteMarketingMaterialAsync.pending, (state) => {
      state.is_deleting_marketingMaterial = true;
    });
    builder.addCase(DeleteMarketingMaterialAsync.fulfilled, (state, action) => {
      state.is_deleting_marketingMaterial = false;
      if (state.marketingMaterial) {
        state.marketingMaterial = state.marketingMaterial.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteMarketingMaterialAsync.rejected, (state, action) => {
      state.is_deleting_marketingMaterial = false;
    });
  }
});

function handleResult(action: any, state: marketingMaterialSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_marketingMaterial = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.marketingMaterial = merge(state.marketingMaterial, payload) as any;
    }
  }
}

export const { clearStatemarketingMaterial, setSelectedmarketingMaterial, set_marketingMaterial, mergemarketingMaterial, add_marketingMaterial } =
  marketingMaterialSlice.actions;

export const get_marketingMaterial =
  (id?: string) =>
  (state: {
    marketingMaterial: {
      marketingMaterial: MarketingMaterial[];
    };
  }): MarketingMaterial | null => {
    let temp = state?.marketingMaterial?.marketingMaterial?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.marketingMaterial?.marketingMaterial[0] || null;
  };
export const get_selected_marketingMaterial = (state: { marketingMaterial: marketingMaterialSliceState }): MarketingMaterial | null => {
  let id = state?.marketingMaterial?.selected_marketingMaterial;
  let temp = state?.marketingMaterial?.marketingMaterial?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_marketingMaterial = (state: {
  marketingMaterial: {
    marketingMaterial: MarketingMaterial[];
  };
}): MarketingMaterial[] => {
  let temp = state?.marketingMaterial?.marketingMaterial;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_marketingMaterial_busy = (state: { marketingMaterial: marketingMaterialSliceState }) => {
  return (
    state?.marketingMaterial.is_deleting_marketingMaterial ||
    state?.marketingMaterial.is_loading_marketingMaterial ||
    state?.marketingMaterial.is_creating_marketingMaterial
  );
};
export const is_deleting_marketingMaterial_busy = (state: { marketingMaterial: marketingMaterialSliceState }) => {
  return state?.marketingMaterial.is_deleting_marketingMaterial;
};
export const is_loading_marketingMaterial_busy = (state: { marketingMaterial: marketingMaterialSliceState }) => {
  return state?.marketingMaterial.is_loading_marketingMaterial;
};
export const is_creating_marketingMaterial_busy = (state: { marketingMaterial: marketingMaterialSliceState }) => {
  return state?.marketingMaterial.is_creating_marketingMaterial;
};

export default marketingMaterialSlice.reducer;
