import { createAsyncThunk } from "@reduxjs/toolkit";
import BankSettings from "./bank-settings-model";
import { delete_bankSettings, load_bankSettingss, store_bankSettings } from "./bankSettings-service";

export const LoadBankSettingssAsync = createAsyncThunk<any, any>("composer/load/bankSettings", async (params: { uid: string }) => {
  return await load_bankSettingss(params);
});

export const CreateBankSettingsAsync = createAsyncThunk<any, any>("composer/create/bankSettings", async (params: BankSettings) => {
  return await store_bankSettings(params);
});

export const DeleteBankSettingsAsync = createAsyncThunk<any, any>("composer/delete/bankSettings", async (params) => {
  return await delete_bankSettings(params);
});
