import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import InvestmentRequest from "./investment-request-model";
import { InvestmentRequestProperties, InvestmentRequestMetaProperties } from "./investmentRequest-property-definitions";

export async function load_investmentRequests(args: { uid: string }): Promise<InvestmentRequest[]> {
  throw "Not implemented";
}

export async function delete_investmentRequest(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_investmentRequest(args: InvestmentRequest): Promise<InvestmentRequest> {
  throw "Not implemented";
}
