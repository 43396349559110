import { createAsyncThunk } from "@reduxjs/toolkit";
import Stock from "./stock-model";
import { delete_stock, load_stocks, store_stock } from "./stock-service";

export const LoadStocksAsync = createAsyncThunk<any, any>("composer/load/stock", async (params: { uid: string }) => {
  return await load_stocks(params);
});

export const CreateStockAsync = createAsyncThunk<any, any>("composer/create/stock", async (params: Stock) => {
  return await store_stock(params);
});

export const DeleteStockAsync = createAsyncThunk<any, any>("composer/delete/stock", async (params) => {
  return await delete_stock(params);
});
