import { createAsyncThunk } from "@reduxjs/toolkit";
import Bank from "./bank-model";
import { delete_bank, load_banks, store_bank } from "./bank-service";

export const LoadBanksAsync = createAsyncThunk<any, any>("composer/load/bank", async (params: { uid: string }) => {
  return await load_banks(params);
});

export const CreateBankAsync = createAsyncThunk<any, any>("composer/create/bank", async (params: Bank) => {
  return await store_bank(params);
});

export const DeleteBankAsync = createAsyncThunk<any, any>("composer/delete/bank", async (params) => {
  return await delete_bank(params);
});
