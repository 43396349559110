import CourseResourceBlock from "./course-resource-block-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateCourseResourceBlockAsync, DeleteCourseResourceBlockAsync, LoadCourseResourceBlocksAsync } from "./courseResourceBlock-thunk";
const initialState: courseResourceBlockSliceState = {
  courseResourceBlock: [],
  selected_courseResourceBlock: "",
  is_loading_courseResourceBlock: false,
  is_creating_courseResourceBlock: false,
  is_deleting_courseResourceBlock: false
};
export interface courseResourceBlockSliceState {
  courseResourceBlock: CourseResourceBlock[];
  selected_courseResourceBlock?: string;
  is_loading_courseResourceBlock: boolean;
  is_creating_courseResourceBlock: boolean;
  is_deleting_courseResourceBlock: boolean;
}
export const courseResourceBlockSlice = createSlice({
  name: "courseResourceBlock",
  initialState,
  reducers: {
    setSelectedcourseResourceBlock: (state, action) => {
      state.selected_courseResourceBlock = action.payload;
    },
    clearStatecourseResourceBlock: (state, action) => {
      state.courseResourceBlock = [];
      state.is_loading_courseResourceBlock = false;
      state.is_creating_courseResourceBlock = false;
      state.is_deleting_courseResourceBlock = false;
    },
    mergecourseResourceBlock: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_courseResourceBlock = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.courseResourceBlock = merge(state.courseResourceBlock, payload) as any;
        }
      }
    },

    add_courseResourceBlock: (state, action) => {
      if (action.payload) {
        state.courseResourceBlock = [...state.courseResourceBlock, action.payload];
      }
    },
    set_courseResourceBlock: (state, action) => {
      let { key, value, id } = action.payload;
      state.courseResourceBlock = state.courseResourceBlock.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadCourseResourceBlocksAsync.pending, (state) => {
      state.is_loading_courseResourceBlock = true;
    });
    builder.addCase(LoadCourseResourceBlocksAsync.fulfilled, (state, action) => {
      state.is_loading_courseResourceBlock = false;
      if (action.payload) {
        state.courseResourceBlock = action.payload;
      }
    });
    builder.addCase(LoadCourseResourceBlocksAsync.rejected, (state, action) => {
      state.is_loading_courseResourceBlock = false;
    });
    builder.addCase(CreateCourseResourceBlockAsync.pending, (state) => {
      state.is_creating_courseResourceBlock = true;
    });
    builder.addCase(CreateCourseResourceBlockAsync.fulfilled, (state) => {
      state.is_creating_courseResourceBlock = false;
    });
    builder.addCase(CreateCourseResourceBlockAsync.rejected, (state) => {
      state.is_creating_courseResourceBlock = false;
    });
    builder.addCase(DeleteCourseResourceBlockAsync.pending, (state) => {
      state.is_deleting_courseResourceBlock = true;
    });
    builder.addCase(DeleteCourseResourceBlockAsync.fulfilled, (state, action) => {
      state.is_deleting_courseResourceBlock = false;
      if (state.courseResourceBlock) {
        state.courseResourceBlock = state.courseResourceBlock.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteCourseResourceBlockAsync.rejected, (state, action) => {
      state.is_deleting_courseResourceBlock = false;
    });

    builder.addCase(autoThunks.add_course_resource_block.pending, (state) => {
      state.is_loading_courseResourceBlock = true;
    });
    builder.addCase(autoThunks.add_course_resource_block.fulfilled, (state, action) => {
      state.is_loading_courseResourceBlock = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.add_course_resource_block.rejected, (state, action) => {
      state.is_loading_courseResourceBlock = false;
    });

    builder.addCase(autoThunks.update_course_resource_block.pending, (state) => {
      state.is_loading_courseResourceBlock = true;
    });
    builder.addCase(autoThunks.update_course_resource_block.fulfilled, (state, action) => {
      state.is_loading_courseResourceBlock = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.update_course_resource_block.rejected, (state, action) => {
      state.is_loading_courseResourceBlock = false;
    });

    builder.addCase(autoThunks.get_course_resource_blocks.pending, (state) => {
      state.is_loading_courseResourceBlock = true;
    });
    builder.addCase(autoThunks.get_course_resource_blocks.fulfilled, (state, action) => {
      state.is_loading_courseResourceBlock = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_course_resource_blocks.rejected, (state, action) => {
      state.is_loading_courseResourceBlock = false;
    });

    builder.addCase(autoThunks.get_professors_course_resource_blocks.pending, (state) => {
      state.is_loading_courseResourceBlock = true;
    });
    builder.addCase(autoThunks.get_professors_course_resource_blocks.fulfilled, (state, action) => {
      state.is_loading_courseResourceBlock = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_professors_course_resource_blocks.rejected, (state, action) => {
      state.is_loading_courseResourceBlock = false;
    });
  }
});

function handleResult(action: any, state: courseResourceBlockSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_courseResourceBlock = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.courseResourceBlock = merge(state.courseResourceBlock, payload) as any;
    }
  }
}

export const { clearStatecourseResourceBlock, setSelectedcourseResourceBlock, set_courseResourceBlock, mergecourseResourceBlock, add_courseResourceBlock } =
  courseResourceBlockSlice.actions;

export const get_courseResourceBlock =
  (id?: string) =>
  (state: {
    courseResourceBlock: {
      courseResourceBlock: CourseResourceBlock[];
    };
  }): CourseResourceBlock | null => {
    let temp = state?.courseResourceBlock?.courseResourceBlock?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.courseResourceBlock?.courseResourceBlock[0] || null;
  };
export const get_selected_courseResourceBlock = (state: { courseResourceBlock: courseResourceBlockSliceState }): CourseResourceBlock | null => {
  let id = state?.courseResourceBlock?.selected_courseResourceBlock;
  let temp = state?.courseResourceBlock?.courseResourceBlock?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_courseResourceBlock = (state: {
  courseResourceBlock: {
    courseResourceBlock: CourseResourceBlock[];
  };
}): CourseResourceBlock[] => {
  let temp = state?.courseResourceBlock?.courseResourceBlock;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_courseResourceBlock_busy = (state: { courseResourceBlock: courseResourceBlockSliceState }) => {
  return (
    state?.courseResourceBlock.is_deleting_courseResourceBlock ||
    state?.courseResourceBlock.is_loading_courseResourceBlock ||
    state?.courseResourceBlock.is_creating_courseResourceBlock
  );
};
export const is_deleting_courseResourceBlock_busy = (state: { courseResourceBlock: courseResourceBlockSliceState }) => {
  return state?.courseResourceBlock.is_deleting_courseResourceBlock;
};
export const is_loading_courseResourceBlock_busy = (state: { courseResourceBlock: courseResourceBlockSliceState }) => {
  return state?.courseResourceBlock.is_loading_courseResourceBlock;
};
export const is_creating_courseResourceBlock_busy = (state: { courseResourceBlock: courseResourceBlockSliceState }) => {
  return state?.courseResourceBlock.is_creating_courseResourceBlock;
};

export default courseResourceBlockSlice.reducer;
