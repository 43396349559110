import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import Transaction from "./transaction-model";
import { TransactionProperties, TransactionMetaProperties } from "./transaction-property-definitions";

export async function load_transactions(args: { uid: string }): Promise<Transaction[]> {
  throw "Not implemented";
}

export async function delete_transaction(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_transaction(args: Transaction): Promise<Transaction> {
  throw "Not implemented";
}
