import { createAsyncThunk } from "@reduxjs/toolkit";
import AccountCurriculumRecord from "./account-curriculum-record-model";
import { delete_accountCurriculumRecord, load_accountCurriculumRecords, store_accountCurriculumRecord } from "./accountCurriculumRecord-service";

export const LoadAccountCurriculumRecordsAsync = createAsyncThunk<any, any>("composer/load/accountCurriculumRecord", async (params: { uid: string }) => {
  return await load_accountCurriculumRecords(params);
});

export const CreateAccountCurriculumRecordAsync = createAsyncThunk<any, any>(
  "composer/create/accountCurriculumRecord",
  async (params: AccountCurriculumRecord) => {
    return await store_accountCurriculumRecord(params);
  }
);

export const DeleteAccountCurriculumRecordAsync = createAsyncThunk<any, any>("composer/delete/accountCurriculumRecord", async (params) => {
  return await delete_accountCurriculumRecord(params);
});
