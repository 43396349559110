import BankBusiness from "./bank-business-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateBankBusinessAsync, DeleteBankBusinessAsync, LoadBankBusinesssAsync } from "./bankBusiness-thunk";
const initialState: bankBusinessSliceState = {
  bankBusiness: [],
  selected_bankBusiness: "",
  is_loading_bankBusiness: false,
  is_creating_bankBusiness: false,
  is_deleting_bankBusiness: false
};
export interface bankBusinessSliceState {
  bankBusiness: BankBusiness[];
  selected_bankBusiness?: string;
  is_loading_bankBusiness: boolean;
  is_creating_bankBusiness: boolean;
  is_deleting_bankBusiness: boolean;
}
export const bankBusinessSlice = createSlice({
  name: "bankBusiness",
  initialState,
  reducers: {
    setSelectedbankBusiness: (state, action) => {
      state.selected_bankBusiness = action.payload;
    },
    clearStatebankBusiness: (state, action) => {
      state.bankBusiness = [];
      state.is_loading_bankBusiness = false;
      state.is_creating_bankBusiness = false;
      state.is_deleting_bankBusiness = false;
    },
    mergebankBusiness: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_bankBusiness = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.bankBusiness = merge(state.bankBusiness, payload) as any;
        }
      }
    },

    add_bankBusiness: (state, action) => {
      if (action.payload) {
        state.bankBusiness = [...state.bankBusiness, action.payload];
      }
    },
    set_bankBusiness: (state, action) => {
      let { key, value, id } = action.payload;
      state.bankBusiness = state.bankBusiness.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadBankBusinesssAsync.pending, (state) => {
      state.is_loading_bankBusiness = true;
    });
    builder.addCase(LoadBankBusinesssAsync.fulfilled, (state, action) => {
      state.is_loading_bankBusiness = false;
      if (action.payload) {
        state.bankBusiness = action.payload;
      }
    });
    builder.addCase(LoadBankBusinesssAsync.rejected, (state, action) => {
      state.is_loading_bankBusiness = false;
    });
    builder.addCase(CreateBankBusinessAsync.pending, (state) => {
      state.is_creating_bankBusiness = true;
    });
    builder.addCase(CreateBankBusinessAsync.fulfilled, (state) => {
      state.is_creating_bankBusiness = false;
    });
    builder.addCase(CreateBankBusinessAsync.rejected, (state) => {
      state.is_creating_bankBusiness = false;
    });
    builder.addCase(DeleteBankBusinessAsync.pending, (state) => {
      state.is_deleting_bankBusiness = true;
    });
    builder.addCase(DeleteBankBusinessAsync.fulfilled, (state, action) => {
      state.is_deleting_bankBusiness = false;
      if (state.bankBusiness) {
        state.bankBusiness = state.bankBusiness.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteBankBusinessAsync.rejected, (state, action) => {
      state.is_deleting_bankBusiness = false;
    });

    builder.addCase(autoThunks.get_bank_business.pending, (state) => {
      state.is_loading_bankBusiness = true;
    });
    builder.addCase(autoThunks.get_bank_business.fulfilled, (state, action) => {
      state.is_loading_bankBusiness = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_bank_business.rejected, (state, action) => {
      state.is_loading_bankBusiness = false;
    });
  }
});

function handleResult(action: any, state: bankBusinessSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_bankBusiness = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.bankBusiness = merge(state.bankBusiness, payload) as any;
    }
  }
}

export const { clearStatebankBusiness, setSelectedbankBusiness, set_bankBusiness, mergebankBusiness, add_bankBusiness } = bankBusinessSlice.actions;

export const get_bankBusiness =
  (id?: string) =>
  (state: {
    bankBusiness: {
      bankBusiness: BankBusiness[];
    };
  }): BankBusiness | null => {
    let temp = state?.bankBusiness?.bankBusiness?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.bankBusiness?.bankBusiness[0] || null;
  };
export const get_selected_bankBusiness = (state: { bankBusiness: bankBusinessSliceState }): BankBusiness | null => {
  let id = state?.bankBusiness?.selected_bankBusiness;
  let temp = state?.bankBusiness?.bankBusiness?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_bankBusiness = (state: {
  bankBusiness: {
    bankBusiness: BankBusiness[];
  };
}): BankBusiness[] => {
  let temp = state?.bankBusiness?.bankBusiness;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_bankBusiness_busy = (state: { bankBusiness: bankBusinessSliceState }) => {
  return state?.bankBusiness.is_deleting_bankBusiness || state?.bankBusiness.is_loading_bankBusiness || state?.bankBusiness.is_creating_bankBusiness;
};
export const is_deleting_bankBusiness_busy = (state: { bankBusiness: bankBusinessSliceState }) => {
  return state?.bankBusiness.is_deleting_bankBusiness;
};
export const is_loading_bankBusiness_busy = (state: { bankBusiness: bankBusinessSliceState }) => {
  return state?.bankBusiness.is_loading_bankBusiness;
};
export const is_creating_bankBusiness_busy = (state: { bankBusiness: bankBusinessSliceState }) => {
  return state?.bankBusiness.is_creating_bankBusiness;
};

export default bankBusinessSlice.reducer;
