import CourseTakerSettings from "./course-taker-settings-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateCourseTakerSettingsAsync, DeleteCourseTakerSettingsAsync, LoadCourseTakerSettingssAsync } from "./courseTakerSettings-thunk";
const initialState: courseTakerSettingsSliceState = {
  courseTakerSettings: [],
  selected_courseTakerSettings: "",
  is_loading_courseTakerSettings: false,
  is_creating_courseTakerSettings: false,
  is_deleting_courseTakerSettings: false
};
export interface courseTakerSettingsSliceState {
  courseTakerSettings: CourseTakerSettings[];
  selected_courseTakerSettings?: string;
  is_loading_courseTakerSettings: boolean;
  is_creating_courseTakerSettings: boolean;
  is_deleting_courseTakerSettings: boolean;
}
export const courseTakerSettingsSlice = createSlice({
  name: "courseTakerSettings",
  initialState,
  reducers: {
    setSelectedcourseTakerSettings: (state, action) => {
      state.selected_courseTakerSettings = action.payload;
    },
    clearStatecourseTakerSettings: (state, action) => {
      state.courseTakerSettings = [];
      state.is_loading_courseTakerSettings = false;
      state.is_creating_courseTakerSettings = false;
      state.is_deleting_courseTakerSettings = false;
    },
    mergecourseTakerSettings: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_courseTakerSettings = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.courseTakerSettings = merge(state.courseTakerSettings, payload) as any;
        }
      }
    },

    add_courseTakerSettings: (state, action) => {
      if (action.payload) {
        state.courseTakerSettings = [...state.courseTakerSettings, action.payload];
      }
    },
    set_courseTakerSettings: (state, action) => {
      let { key, value, id } = action.payload;
      state.courseTakerSettings = state.courseTakerSettings.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadCourseTakerSettingssAsync.pending, (state) => {
      state.is_loading_courseTakerSettings = true;
    });
    builder.addCase(LoadCourseTakerSettingssAsync.fulfilled, (state, action) => {
      state.is_loading_courseTakerSettings = false;
      if (action.payload) {
        state.courseTakerSettings = action.payload;
      }
    });
    builder.addCase(LoadCourseTakerSettingssAsync.rejected, (state, action) => {
      state.is_loading_courseTakerSettings = false;
    });
    builder.addCase(CreateCourseTakerSettingsAsync.pending, (state) => {
      state.is_creating_courseTakerSettings = true;
    });
    builder.addCase(CreateCourseTakerSettingsAsync.fulfilled, (state) => {
      state.is_creating_courseTakerSettings = false;
    });
    builder.addCase(CreateCourseTakerSettingsAsync.rejected, (state) => {
      state.is_creating_courseTakerSettings = false;
    });
    builder.addCase(DeleteCourseTakerSettingsAsync.pending, (state) => {
      state.is_deleting_courseTakerSettings = true;
    });
    builder.addCase(DeleteCourseTakerSettingsAsync.fulfilled, (state, action) => {
      state.is_deleting_courseTakerSettings = false;
      if (state.courseTakerSettings) {
        state.courseTakerSettings = state.courseTakerSettings.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteCourseTakerSettingsAsync.rejected, (state, action) => {
      state.is_deleting_courseTakerSettings = false;
    });

    builder.addCase(autoThunks.get_my_settings.pending, (state) => {
      state.is_loading_courseTakerSettings = true;
    });
    builder.addCase(autoThunks.get_my_settings.fulfilled, (state, action) => {
      state.is_loading_courseTakerSettings = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_my_settings.rejected, (state, action) => {
      state.is_loading_courseTakerSettings = false;
    });

    builder.addCase(autoThunks.update_my_settings.pending, (state) => {
      state.is_loading_courseTakerSettings = true;
    });
    builder.addCase(autoThunks.update_my_settings.fulfilled, (state, action) => {
      state.is_loading_courseTakerSettings = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.update_my_settings.rejected, (state, action) => {
      state.is_loading_courseTakerSettings = false;
    });

    builder.addCase(autoThunks.update_xp.pending, (state) => {
      state.is_loading_courseTakerSettings = true;
    });
    builder.addCase(autoThunks.update_xp.fulfilled, (state, action) => {
      state.is_loading_courseTakerSettings = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.update_xp.rejected, (state, action) => {
      state.is_loading_courseTakerSettings = false;
    });
  }
});

function handleResult(action: any, state: courseTakerSettingsSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_courseTakerSettings = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.courseTakerSettings = merge(state.courseTakerSettings, payload) as any;
    }
  }
}

export const { clearStatecourseTakerSettings, setSelectedcourseTakerSettings, set_courseTakerSettings, mergecourseTakerSettings, add_courseTakerSettings } =
  courseTakerSettingsSlice.actions;

export const get_courseTakerSettings =
  (id?: string) =>
  (state: {
    courseTakerSettings: {
      courseTakerSettings: CourseTakerSettings[];
    };
  }): CourseTakerSettings | null => {
    let temp = state?.courseTakerSettings?.courseTakerSettings?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.courseTakerSettings?.courseTakerSettings[0] || null;
  };
export const get_selected_courseTakerSettings = (state: { courseTakerSettings: courseTakerSettingsSliceState }): CourseTakerSettings | null => {
  let id = state?.courseTakerSettings?.selected_courseTakerSettings;
  let temp = state?.courseTakerSettings?.courseTakerSettings?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_courseTakerSettings = (state: {
  courseTakerSettings: {
    courseTakerSettings: CourseTakerSettings[];
  };
}): CourseTakerSettings[] => {
  let temp = state?.courseTakerSettings?.courseTakerSettings;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_courseTakerSettings_busy = (state: { courseTakerSettings: courseTakerSettingsSliceState }) => {
  return (
    state?.courseTakerSettings.is_deleting_courseTakerSettings ||
    state?.courseTakerSettings.is_loading_courseTakerSettings ||
    state?.courseTakerSettings.is_creating_courseTakerSettings
  );
};
export const is_deleting_courseTakerSettings_busy = (state: { courseTakerSettings: courseTakerSettingsSliceState }) => {
  return state?.courseTakerSettings.is_deleting_courseTakerSettings;
};
export const is_loading_courseTakerSettings_busy = (state: { courseTakerSettings: courseTakerSettingsSliceState }) => {
  return state?.courseTakerSettings.is_loading_courseTakerSettings;
};
export const is_creating_courseTakerSettings_busy = (state: { courseTakerSettings: courseTakerSettingsSliceState }) => {
  return state?.courseTakerSettings.is_creating_courseTakerSettings;
};

export default courseTakerSettingsSlice.reducer;
