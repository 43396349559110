import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import AccountBalances from "./account-balances-model";
import { AccountBalancesProperties, AccountBalancesMetaProperties } from "./accountBalances-property-definitions";

export async function load_accountBalancess(args: { uid: string }): Promise<AccountBalances[]> {
  throw "Not implemented";
}

export async function delete_accountBalances(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_accountBalances(args: AccountBalances): Promise<AccountBalances> {
  throw "Not implemented";
}
