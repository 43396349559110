import { createAsyncThunk } from "@reduxjs/toolkit";
import VirtualShareEscro from "./virtual-share-escro-model";
import { delete_virtualShareEscro, load_virtualShareEscros, store_virtualShareEscro } from "./virtualShareEscro-service";

export const LoadVirtualShareEscrosAsync = createAsyncThunk<any, any>("composer/load/virtualShareEscro", async (params: { uid: string }) => {
  return await load_virtualShareEscros(params);
});

export const CreateVirtualShareEscroAsync = createAsyncThunk<any, any>("composer/create/virtualShareEscro", async (params: VirtualShareEscro) => {
  return await store_virtualShareEscro(params);
});

export const DeleteVirtualShareEscroAsync = createAsyncThunk<any, any>("composer/delete/virtualShareEscro", async (params) => {
  return await delete_virtualShareEscro(params);
});
