import { useEffect, useState } from 'react';

const useURLParameters = () => {
    const [params, setParams] = useState({});

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const paramsObject = {};

        // Iterate over each parameter and store it in the params object
        for (const [key, value] of urlParams.entries()) {
            paramsObject[key] = value;
        }

        // Update the state with the params object
        setParams(paramsObject);

        // Clean up the effect
        return () => {
            // Perform any necessary cleanup (if needed)
        };
    }, []);

    return params; // Return the params state
};

export default useURLParameters;
