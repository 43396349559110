import CourseQuestionLog from "./course-question-log-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateCourseQuestionLogAsync, DeleteCourseQuestionLogAsync, LoadCourseQuestionLogsAsync } from "./courseQuestionLog-thunk";
const initialState: courseQuestionLogSliceState = {
  courseQuestionLog: [],
  selected_courseQuestionLog: "",
  is_loading_courseQuestionLog: false,
  is_creating_courseQuestionLog: false,
  is_deleting_courseQuestionLog: false
};
export interface courseQuestionLogSliceState {
  courseQuestionLog: CourseQuestionLog[];
  selected_courseQuestionLog?: string;
  is_loading_courseQuestionLog: boolean;
  is_creating_courseQuestionLog: boolean;
  is_deleting_courseQuestionLog: boolean;
}
export const courseQuestionLogSlice = createSlice({
  name: "courseQuestionLog",
  initialState,
  reducers: {
    setSelectedcourseQuestionLog: (state, action) => {
      state.selected_courseQuestionLog = action.payload;
    },
    clearStatecourseQuestionLog: (state, action) => {
      state.courseQuestionLog = [];
      state.is_loading_courseQuestionLog = false;
      state.is_creating_courseQuestionLog = false;
      state.is_deleting_courseQuestionLog = false;
    },
    mergecourseQuestionLog: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_courseQuestionLog = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.courseQuestionLog = merge(state.courseQuestionLog, payload) as any;
        }
      }
    },

    add_courseQuestionLog: (state, action) => {
      if (action.payload) {
        state.courseQuestionLog = [...state.courseQuestionLog, action.payload];
      }
    },
    set_courseQuestionLog: (state, action) => {
      let { key, value, id } = action.payload;
      state.courseQuestionLog = state.courseQuestionLog.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadCourseQuestionLogsAsync.pending, (state) => {
      state.is_loading_courseQuestionLog = true;
    });
    builder.addCase(LoadCourseQuestionLogsAsync.fulfilled, (state, action) => {
      state.is_loading_courseQuestionLog = false;
      if (action.payload) {
        state.courseQuestionLog = action.payload;
      }
    });
    builder.addCase(LoadCourseQuestionLogsAsync.rejected, (state, action) => {
      state.is_loading_courseQuestionLog = false;
    });
    builder.addCase(CreateCourseQuestionLogAsync.pending, (state) => {
      state.is_creating_courseQuestionLog = true;
    });
    builder.addCase(CreateCourseQuestionLogAsync.fulfilled, (state) => {
      state.is_creating_courseQuestionLog = false;
    });
    builder.addCase(CreateCourseQuestionLogAsync.rejected, (state) => {
      state.is_creating_courseQuestionLog = false;
    });
    builder.addCase(DeleteCourseQuestionLogAsync.pending, (state) => {
      state.is_deleting_courseQuestionLog = true;
    });
    builder.addCase(DeleteCourseQuestionLogAsync.fulfilled, (state, action) => {
      state.is_deleting_courseQuestionLog = false;
      if (state.courseQuestionLog) {
        state.courseQuestionLog = state.courseQuestionLog.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteCourseQuestionLogAsync.rejected, (state, action) => {
      state.is_deleting_courseQuestionLog = false;
    });
  }
});

function handleResult(action: any, state: courseQuestionLogSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_courseQuestionLog = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.courseQuestionLog = merge(state.courseQuestionLog, payload) as any;
    }
  }
}

export const { clearStatecourseQuestionLog, setSelectedcourseQuestionLog, set_courseQuestionLog, mergecourseQuestionLog, add_courseQuestionLog } =
  courseQuestionLogSlice.actions;

export const get_courseQuestionLog =
  (id?: string) =>
  (state: {
    courseQuestionLog: {
      courseQuestionLog: CourseQuestionLog[];
    };
  }): CourseQuestionLog | null => {
    let temp = state?.courseQuestionLog?.courseQuestionLog?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.courseQuestionLog?.courseQuestionLog[0] || null;
  };
export const get_selected_courseQuestionLog = (state: { courseQuestionLog: courseQuestionLogSliceState }): CourseQuestionLog | null => {
  let id = state?.courseQuestionLog?.selected_courseQuestionLog;
  let temp = state?.courseQuestionLog?.courseQuestionLog?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_courseQuestionLog = (state: {
  courseQuestionLog: {
    courseQuestionLog: CourseQuestionLog[];
  };
}): CourseQuestionLog[] => {
  let temp = state?.courseQuestionLog?.courseQuestionLog;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_courseQuestionLog_busy = (state: { courseQuestionLog: courseQuestionLogSliceState }) => {
  return (
    state?.courseQuestionLog.is_deleting_courseQuestionLog ||
    state?.courseQuestionLog.is_loading_courseQuestionLog ||
    state?.courseQuestionLog.is_creating_courseQuestionLog
  );
};
export const is_deleting_courseQuestionLog_busy = (state: { courseQuestionLog: courseQuestionLogSliceState }) => {
  return state?.courseQuestionLog.is_deleting_courseQuestionLog;
};
export const is_loading_courseQuestionLog_busy = (state: { courseQuestionLog: courseQuestionLogSliceState }) => {
  return state?.courseQuestionLog.is_loading_courseQuestionLog;
};
export const is_creating_courseQuestionLog_busy = (state: { courseQuestionLog: courseQuestionLogSliceState }) => {
  return state?.courseQuestionLog.is_creating_courseQuestionLog;
};

export default courseQuestionLogSlice.reducer;
