import EductionAssetOutputConfiguration from "./eduction-asset-output-configuration-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import {
  CreateEductionAssetOutputConfigurationAsync,
  DeleteEductionAssetOutputConfigurationAsync,
  LoadEductionAssetOutputConfigurationsAsync
} from "./eductionAssetOutputConfiguration-thunk";
const initialState: eductionAssetOutputConfigurationSliceState = {
  eductionAssetOutputConfiguration: [],
  selected_eductionAssetOutputConfiguration: "",
  is_loading_eductionAssetOutputConfiguration: false,
  is_creating_eductionAssetOutputConfiguration: false,
  is_deleting_eductionAssetOutputConfiguration: false
};
export interface eductionAssetOutputConfigurationSliceState {
  eductionAssetOutputConfiguration: EductionAssetOutputConfiguration[];
  selected_eductionAssetOutputConfiguration?: string;
  is_loading_eductionAssetOutputConfiguration: boolean;
  is_creating_eductionAssetOutputConfiguration: boolean;
  is_deleting_eductionAssetOutputConfiguration: boolean;
}
export const eductionAssetOutputConfigurationSlice = createSlice({
  name: "eductionAssetOutputConfiguration",
  initialState,
  reducers: {
    setSelectedeductionAssetOutputConfiguration: (state, action) => {
      state.selected_eductionAssetOutputConfiguration = action.payload;
    },
    clearStateeductionAssetOutputConfiguration: (state, action) => {
      state.eductionAssetOutputConfiguration = [];
      state.is_loading_eductionAssetOutputConfiguration = false;
      state.is_creating_eductionAssetOutputConfiguration = false;
      state.is_deleting_eductionAssetOutputConfiguration = false;
    },
    mergeeductionAssetOutputConfiguration: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_eductionAssetOutputConfiguration = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.eductionAssetOutputConfiguration = merge(state.eductionAssetOutputConfiguration, payload) as any;
        }
      }
    },

    add_eductionAssetOutputConfiguration: (state, action) => {
      if (action.payload) {
        state.eductionAssetOutputConfiguration = [...state.eductionAssetOutputConfiguration, action.payload];
      }
    },
    set_eductionAssetOutputConfiguration: (state, action) => {
      let { key, value, id } = action.payload;
      state.eductionAssetOutputConfiguration = state.eductionAssetOutputConfiguration.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadEductionAssetOutputConfigurationsAsync.pending, (state) => {
      state.is_loading_eductionAssetOutputConfiguration = true;
    });
    builder.addCase(LoadEductionAssetOutputConfigurationsAsync.fulfilled, (state, action) => {
      state.is_loading_eductionAssetOutputConfiguration = false;
      if (action.payload) {
        state.eductionAssetOutputConfiguration = action.payload;
      }
    });
    builder.addCase(LoadEductionAssetOutputConfigurationsAsync.rejected, (state, action) => {
      state.is_loading_eductionAssetOutputConfiguration = false;
    });
    builder.addCase(CreateEductionAssetOutputConfigurationAsync.pending, (state) => {
      state.is_creating_eductionAssetOutputConfiguration = true;
    });
    builder.addCase(CreateEductionAssetOutputConfigurationAsync.fulfilled, (state) => {
      state.is_creating_eductionAssetOutputConfiguration = false;
    });
    builder.addCase(CreateEductionAssetOutputConfigurationAsync.rejected, (state) => {
      state.is_creating_eductionAssetOutputConfiguration = false;
    });
    builder.addCase(DeleteEductionAssetOutputConfigurationAsync.pending, (state) => {
      state.is_deleting_eductionAssetOutputConfiguration = true;
    });
    builder.addCase(DeleteEductionAssetOutputConfigurationAsync.fulfilled, (state, action) => {
      state.is_deleting_eductionAssetOutputConfiguration = false;
      if (state.eductionAssetOutputConfiguration) {
        state.eductionAssetOutputConfiguration = state.eductionAssetOutputConfiguration.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteEductionAssetOutputConfigurationAsync.rejected, (state, action) => {
      state.is_deleting_eductionAssetOutputConfiguration = false;
    });
  }
});

function handleResult(action: any, state: eductionAssetOutputConfigurationSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_eductionAssetOutputConfiguration = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.eductionAssetOutputConfiguration = merge(state.eductionAssetOutputConfiguration, payload) as any;
    }
  }
}

export const {
  clearStateeductionAssetOutputConfiguration,
  setSelectedeductionAssetOutputConfiguration,
  set_eductionAssetOutputConfiguration,
  mergeeductionAssetOutputConfiguration,
  add_eductionAssetOutputConfiguration
} = eductionAssetOutputConfigurationSlice.actions;

export const get_eductionAssetOutputConfiguration =
  (id?: string) =>
  (state: {
    eductionAssetOutputConfiguration: {
      eductionAssetOutputConfiguration: EductionAssetOutputConfiguration[];
    };
  }): EductionAssetOutputConfiguration | null => {
    let temp = state?.eductionAssetOutputConfiguration?.eductionAssetOutputConfiguration?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.eductionAssetOutputConfiguration?.eductionAssetOutputConfiguration[0] || null;
  };
export const get_selected_eductionAssetOutputConfiguration = (state: {
  eductionAssetOutputConfiguration: eductionAssetOutputConfigurationSliceState;
}): EductionAssetOutputConfiguration | null => {
  let id = state?.eductionAssetOutputConfiguration?.selected_eductionAssetOutputConfiguration;
  let temp = state?.eductionAssetOutputConfiguration?.eductionAssetOutputConfiguration?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_eductionAssetOutputConfiguration = (state: {
  eductionAssetOutputConfiguration: {
    eductionAssetOutputConfiguration: EductionAssetOutputConfiguration[];
  };
}): EductionAssetOutputConfiguration[] => {
  let temp = state?.eductionAssetOutputConfiguration?.eductionAssetOutputConfiguration;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_eductionAssetOutputConfiguration_busy = (state: { eductionAssetOutputConfiguration: eductionAssetOutputConfigurationSliceState }) => {
  return (
    state?.eductionAssetOutputConfiguration.is_deleting_eductionAssetOutputConfiguration ||
    state?.eductionAssetOutputConfiguration.is_loading_eductionAssetOutputConfiguration ||
    state?.eductionAssetOutputConfiguration.is_creating_eductionAssetOutputConfiguration
  );
};
export const is_deleting_eductionAssetOutputConfiguration_busy = (state: { eductionAssetOutputConfiguration: eductionAssetOutputConfigurationSliceState }) => {
  return state?.eductionAssetOutputConfiguration.is_deleting_eductionAssetOutputConfiguration;
};
export const is_loading_eductionAssetOutputConfiguration_busy = (state: { eductionAssetOutputConfiguration: eductionAssetOutputConfigurationSliceState }) => {
  return state?.eductionAssetOutputConfiguration.is_loading_eductionAssetOutputConfiguration;
};
export const is_creating_eductionAssetOutputConfiguration_busy = (state: { eductionAssetOutputConfiguration: eductionAssetOutputConfigurationSliceState }) => {
  return state?.eductionAssetOutputConfiguration.is_creating_eductionAssetOutputConfiguration;
};

export default eductionAssetOutputConfigurationSlice.reducer;
