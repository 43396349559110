import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import CurriculumRecordItem from "./curriculum-record-item-model";
import { CurriculumRecordItemProperties, CurriculumRecordItemMetaProperties } from "./curriculumRecordItem-property-definitions";

export async function load_curriculumRecordItems(args: { uid: string }): Promise<CurriculumRecordItem[]> {
  throw "Not implemented";
}

export async function delete_curriculumRecordItem(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_curriculumRecordItem(args: CurriculumRecordItem): Promise<CurriculumRecordItem> {
  throw "Not implemented";
}
