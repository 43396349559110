import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import User from "./user-model";
import { UserProperties, UserMetaProperties } from "./user-property-definitions";

export async function load_users(args: { uid: string }): Promise<User[]> {
  throw "Not implemented";
}

export async function delete_user(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_user(args: User): Promise<User> {
  throw "Not implemented";
}
