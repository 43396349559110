import BankerRole from "./banker-role-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateBankerRoleAsync, DeleteBankerRoleAsync, LoadBankerRolesAsync } from "./bankerRole-thunk";
const initialState: bankerRoleSliceState = {
  bankerRole: [],
  selected_bankerRole: "",
  is_loading_bankerRole: false,
  is_creating_bankerRole: false,
  is_deleting_bankerRole: false
};
export interface bankerRoleSliceState {
  bankerRole: BankerRole[];
  selected_bankerRole?: string;
  is_loading_bankerRole: boolean;
  is_creating_bankerRole: boolean;
  is_deleting_bankerRole: boolean;
}
export const bankerRoleSlice = createSlice({
  name: "bankerRole",
  initialState,
  reducers: {
    setSelectedbankerRole: (state, action) => {
      state.selected_bankerRole = action.payload;
    },
    clearStatebankerRole: (state, action) => {
      state.bankerRole = [];
      state.is_loading_bankerRole = false;
      state.is_creating_bankerRole = false;
      state.is_deleting_bankerRole = false;
    },
    mergebankerRole: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_bankerRole = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.bankerRole = merge(state.bankerRole, payload) as any;
        }
      }
    },

    add_bankerRole: (state, action) => {
      if (action.payload) {
        state.bankerRole = [...state.bankerRole, action.payload];
      }
    },
    set_bankerRole: (state, action) => {
      let { key, value, id } = action.payload;
      state.bankerRole = state.bankerRole.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadBankerRolesAsync.pending, (state) => {
      state.is_loading_bankerRole = true;
    });
    builder.addCase(LoadBankerRolesAsync.fulfilled, (state, action) => {
      state.is_loading_bankerRole = false;
      if (action.payload) {
        state.bankerRole = action.payload;
      }
    });
    builder.addCase(LoadBankerRolesAsync.rejected, (state, action) => {
      state.is_loading_bankerRole = false;
    });
    builder.addCase(CreateBankerRoleAsync.pending, (state) => {
      state.is_creating_bankerRole = true;
    });
    builder.addCase(CreateBankerRoleAsync.fulfilled, (state) => {
      state.is_creating_bankerRole = false;
    });
    builder.addCase(CreateBankerRoleAsync.rejected, (state) => {
      state.is_creating_bankerRole = false;
    });
    builder.addCase(DeleteBankerRoleAsync.pending, (state) => {
      state.is_deleting_bankerRole = true;
    });
    builder.addCase(DeleteBankerRoleAsync.fulfilled, (state, action) => {
      state.is_deleting_bankerRole = false;
      if (state.bankerRole) {
        state.bankerRole = state.bankerRole.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteBankerRoleAsync.rejected, (state, action) => {
      state.is_deleting_bankerRole = false;
    });
  }
});

function handleResult(action: any, state: bankerRoleSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_bankerRole = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.bankerRole = merge(state.bankerRole, payload) as any;
    }
  }
}

export const { clearStatebankerRole, setSelectedbankerRole, set_bankerRole, mergebankerRole, add_bankerRole } = bankerRoleSlice.actions;

export const get_bankerRole =
  (id?: string) =>
  (state: {
    bankerRole: {
      bankerRole: BankerRole[];
    };
  }): BankerRole | null => {
    let temp = state?.bankerRole?.bankerRole?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.bankerRole?.bankerRole[0] || null;
  };
export const get_selected_bankerRole = (state: { bankerRole: bankerRoleSliceState }): BankerRole | null => {
  let id = state?.bankerRole?.selected_bankerRole;
  let temp = state?.bankerRole?.bankerRole?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_bankerRole = (state: {
  bankerRole: {
    bankerRole: BankerRole[];
  };
}): BankerRole[] => {
  let temp = state?.bankerRole?.bankerRole;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_bankerRole_busy = (state: { bankerRole: bankerRoleSliceState }) => {
  return state?.bankerRole.is_deleting_bankerRole || state?.bankerRole.is_loading_bankerRole || state?.bankerRole.is_creating_bankerRole;
};
export const is_deleting_bankerRole_busy = (state: { bankerRole: bankerRoleSliceState }) => {
  return state?.bankerRole.is_deleting_bankerRole;
};
export const is_loading_bankerRole_busy = (state: { bankerRole: bankerRoleSliceState }) => {
  return state?.bankerRole.is_loading_bankerRole;
};
export const is_creating_bankerRole_busy = (state: { bankerRole: bankerRoleSliceState }) => {
  return state?.bankerRole.is_creating_bankerRole;
};

export default bankerRoleSlice.reducer;
