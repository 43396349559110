import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import BusinessLoanRequest from "./business-loan-request-model";
import { BusinessLoanRequestProperties, BusinessLoanRequestMetaProperties } from "./businessLoanRequest-property-definitions";

export async function load_businessLoanRequests(args: { uid: string }): Promise<BusinessLoanRequest[]> {
  throw "Not implemented";
}

export async function delete_businessLoanRequest(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_businessLoanRequest(args: BusinessLoanRequest): Promise<BusinessLoanRequest> {
  throw "Not implemented";
}
