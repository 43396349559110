import BusinessPlan from "./business-plan-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateBusinessPlanAsync, DeleteBusinessPlanAsync, LoadBusinessPlansAsync } from "./businessPlan-thunk";
const initialState: businessPlanSliceState = {
  businessPlan: [],
  selected_businessPlan: "",
  is_loading_businessPlan: false,
  is_creating_businessPlan: false,
  is_deleting_businessPlan: false
};
export interface businessPlanSliceState {
  businessPlan: BusinessPlan[];
  selected_businessPlan?: string;
  is_loading_businessPlan: boolean;
  is_creating_businessPlan: boolean;
  is_deleting_businessPlan: boolean;
}
export const businessPlanSlice = createSlice({
  name: "businessPlan",
  initialState,
  reducers: {
    setSelectedbusinessPlan: (state, action) => {
      state.selected_businessPlan = action.payload;
    },
    clearStatebusinessPlan: (state, action) => {
      state.businessPlan = [];
      state.is_loading_businessPlan = false;
      state.is_creating_businessPlan = false;
      state.is_deleting_businessPlan = false;
    },
    mergebusinessPlan: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_businessPlan = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.businessPlan = merge(state.businessPlan, payload) as any;
        }
      }
    },

    add_businessPlan: (state, action) => {
      if (action.payload) {
        state.businessPlan = [...state.businessPlan, action.payload];
      }
    },
    set_businessPlan: (state, action) => {
      let { key, value, id } = action.payload;
      state.businessPlan = state.businessPlan.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadBusinessPlansAsync.pending, (state) => {
      state.is_loading_businessPlan = true;
    });
    builder.addCase(LoadBusinessPlansAsync.fulfilled, (state, action) => {
      state.is_loading_businessPlan = false;
      if (action.payload) {
        state.businessPlan = action.payload;
      }
    });
    builder.addCase(LoadBusinessPlansAsync.rejected, (state, action) => {
      state.is_loading_businessPlan = false;
    });
    builder.addCase(CreateBusinessPlanAsync.pending, (state) => {
      state.is_creating_businessPlan = true;
    });
    builder.addCase(CreateBusinessPlanAsync.fulfilled, (state) => {
      state.is_creating_businessPlan = false;
    });
    builder.addCase(CreateBusinessPlanAsync.rejected, (state) => {
      state.is_creating_businessPlan = false;
    });
    builder.addCase(DeleteBusinessPlanAsync.pending, (state) => {
      state.is_deleting_businessPlan = true;
    });
    builder.addCase(DeleteBusinessPlanAsync.fulfilled, (state, action) => {
      state.is_deleting_businessPlan = false;
      if (state.businessPlan) {
        state.businessPlan = state.businessPlan.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteBusinessPlanAsync.rejected, (state, action) => {
      state.is_deleting_businessPlan = false;
    });

    builder.addCase(autoThunks.store_business_plan.pending, (state) => {
      state.is_loading_businessPlan = true;
    });
    builder.addCase(autoThunks.store_business_plan.fulfilled, (state, action) => {
      state.is_loading_businessPlan = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.store_business_plan.rejected, (state, action) => {
      state.is_loading_businessPlan = false;
    });

    builder.addCase(autoThunks.publish_business_plan.pending, (state) => {
      state.is_loading_businessPlan = true;
    });
    builder.addCase(autoThunks.publish_business_plan.fulfilled, (state, action) => {
      state.is_loading_businessPlan = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.publish_business_plan.rejected, (state, action) => {
      state.is_loading_businessPlan = false;
    });

    builder.addCase(autoThunks.get_business_plans.pending, (state) => {
      state.is_loading_businessPlan = true;
    });
    builder.addCase(autoThunks.get_business_plans.fulfilled, (state, action) => {
      state.is_loading_businessPlan = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_business_plans.rejected, (state, action) => {
      state.is_loading_businessPlan = false;
    });

    builder.addCase(autoThunks.get_published_business_plans.pending, (state) => {
      state.is_loading_businessPlan = true;
    });
    builder.addCase(autoThunks.get_published_business_plans.fulfilled, (state, action) => {
      state.is_loading_businessPlan = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_published_business_plans.rejected, (state, action) => {
      state.is_loading_businessPlan = false;
    });

    builder.addCase(autoThunks.get_business_plan.pending, (state) => {
      state.is_loading_businessPlan = true;
    });
    builder.addCase(autoThunks.get_business_plan.fulfilled, (state, action) => {
      state.is_loading_businessPlan = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_business_plan.rejected, (state, action) => {
      state.is_loading_businessPlan = false;
    });

    builder.addCase(autoThunks.set_approval_of_business_plan.pending, (state) => {
      state.is_loading_businessPlan = true;
    });
    builder.addCase(autoThunks.set_approval_of_business_plan.fulfilled, (state, action) => {
      state.is_loading_businessPlan = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.set_approval_of_business_plan.rejected, (state, action) => {
      state.is_loading_businessPlan = false;
    });

    builder.addCase(autoThunks.get_virtual_businessplan_for_banks.pending, (state) => {
      state.is_loading_businessPlan = true;
    });
    builder.addCase(autoThunks.get_virtual_businessplan_for_banks.fulfilled, (state, action) => {
      state.is_loading_businessPlan = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_virtual_businessplan_for_banks.rejected, (state, action) => {
      state.is_loading_businessPlan = false;
    });
  }
});

function handleResult(action: any, state: businessPlanSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_businessPlan = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.businessPlan = merge(state.businessPlan, payload) as any;
    }
  }
}

export const { clearStatebusinessPlan, setSelectedbusinessPlan, set_businessPlan, mergebusinessPlan, add_businessPlan } = businessPlanSlice.actions;

export const get_businessPlan =
  (id?: string) =>
  (state: {
    businessPlan: {
      businessPlan: BusinessPlan[];
    };
  }): BusinessPlan | null => {
    let temp = state?.businessPlan?.businessPlan?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.businessPlan?.businessPlan[0] || null;
  };
export const get_selected_businessPlan = (state: { businessPlan: businessPlanSliceState }): BusinessPlan | null => {
  let id = state?.businessPlan?.selected_businessPlan;
  let temp = state?.businessPlan?.businessPlan?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_businessPlan = (state: {
  businessPlan: {
    businessPlan: BusinessPlan[];
  };
}): BusinessPlan[] => {
  let temp = state?.businessPlan?.businessPlan;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_businessPlan_busy = (state: { businessPlan: businessPlanSliceState }) => {
  return state?.businessPlan.is_deleting_businessPlan || state?.businessPlan.is_loading_businessPlan || state?.businessPlan.is_creating_businessPlan;
};
export const is_deleting_businessPlan_busy = (state: { businessPlan: businessPlanSliceState }) => {
  return state?.businessPlan.is_deleting_businessPlan;
};
export const is_loading_businessPlan_busy = (state: { businessPlan: businessPlanSliceState }) => {
  return state?.businessPlan.is_loading_businessPlan;
};
export const is_creating_businessPlan_busy = (state: { businessPlan: businessPlanSliceState }) => {
  return state?.businessPlan.is_creating_businessPlan;
};

export default businessPlanSlice.reducer;
