import { createAsyncThunk } from "@reduxjs/toolkit";
import DigitalAssetInstance from "./digital-asset-instance-model";
import { delete_digitalAssetInstance, load_digitalAssetInstances, store_digitalAssetInstance } from "./digitalAssetInstance-service";

export const LoadDigitalAssetInstancesAsync = createAsyncThunk<any, any>("composer/load/digitalAssetInstance", async (params: { uid: string }) => {
  return await load_digitalAssetInstances(params);
});

export const CreateDigitalAssetInstanceAsync = createAsyncThunk<any, any>("composer/create/digitalAssetInstance", async (params: DigitalAssetInstance) => {
  return await store_digitalAssetInstance(params);
});

export const DeleteDigitalAssetInstanceAsync = createAsyncThunk<any, any>("composer/delete/digitalAssetInstance", async (params) => {
  return await delete_digitalAssetInstance(params);
});
