import AccountLogCommand from "./account-log-command-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateAccountLogCommandAsync, DeleteAccountLogCommandAsync, LoadAccountLogCommandsAsync } from "./accountLogCommand-thunk";
const initialState: accountLogCommandSliceState = {
  accountLogCommand: [],
  selected_accountLogCommand: "",
  is_loading_accountLogCommand: false,
  is_creating_accountLogCommand: false,
  is_deleting_accountLogCommand: false
};
export interface accountLogCommandSliceState {
  accountLogCommand: AccountLogCommand[];
  selected_accountLogCommand?: string;
  is_loading_accountLogCommand: boolean;
  is_creating_accountLogCommand: boolean;
  is_deleting_accountLogCommand: boolean;
}
export const accountLogCommandSlice = createSlice({
  name: "accountLogCommand",
  initialState,
  reducers: {
    setSelectedaccountLogCommand: (state, action) => {
      state.selected_accountLogCommand = action.payload;
    },
    clearStateaccountLogCommand: (state, action) => {
      state.accountLogCommand = [];
      state.is_loading_accountLogCommand = false;
      state.is_creating_accountLogCommand = false;
      state.is_deleting_accountLogCommand = false;
    },
    mergeaccountLogCommand: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_accountLogCommand = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.accountLogCommand = merge(state.accountLogCommand, payload) as any;
        }
      }
    },

    add_accountLogCommand: (state, action) => {
      if (action.payload) {
        state.accountLogCommand = [...state.accountLogCommand, action.payload];
      }
    },
    set_accountLogCommand: (state, action) => {
      let { key, value, id } = action.payload;
      state.accountLogCommand = state.accountLogCommand.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadAccountLogCommandsAsync.pending, (state) => {
      state.is_loading_accountLogCommand = true;
    });
    builder.addCase(LoadAccountLogCommandsAsync.fulfilled, (state, action) => {
      state.is_loading_accountLogCommand = false;
      if (action.payload) {
        state.accountLogCommand = action.payload;
      }
    });
    builder.addCase(LoadAccountLogCommandsAsync.rejected, (state, action) => {
      state.is_loading_accountLogCommand = false;
    });
    builder.addCase(CreateAccountLogCommandAsync.pending, (state) => {
      state.is_creating_accountLogCommand = true;
    });
    builder.addCase(CreateAccountLogCommandAsync.fulfilled, (state) => {
      state.is_creating_accountLogCommand = false;
    });
    builder.addCase(CreateAccountLogCommandAsync.rejected, (state) => {
      state.is_creating_accountLogCommand = false;
    });
    builder.addCase(DeleteAccountLogCommandAsync.pending, (state) => {
      state.is_deleting_accountLogCommand = true;
    });
    builder.addCase(DeleteAccountLogCommandAsync.fulfilled, (state, action) => {
      state.is_deleting_accountLogCommand = false;
      if (state.accountLogCommand) {
        state.accountLogCommand = state.accountLogCommand.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteAccountLogCommandAsync.rejected, (state, action) => {
      state.is_deleting_accountLogCommand = false;
    });

    builder.addCase(autoThunks.get_account_log_clearing_for_account_id.pending, (state) => {
      state.is_loading_accountLogCommand = true;
    });
    builder.addCase(autoThunks.get_account_log_clearing_for_account_id.fulfilled, (state, action) => {
      state.is_loading_accountLogCommand = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_account_log_clearing_for_account_id.rejected, (state, action) => {
      state.is_loading_accountLogCommand = false;
    });

    builder.addCase(autoThunks.set_account_log_clearing_for_account_id.pending, (state) => {
      state.is_loading_accountLogCommand = true;
    });
    builder.addCase(autoThunks.set_account_log_clearing_for_account_id.fulfilled, (state, action) => {
      state.is_loading_accountLogCommand = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.set_account_log_clearing_for_account_id.rejected, (state, action) => {
      state.is_loading_accountLogCommand = false;
    });
  }
});

function handleResult(action: any, state: accountLogCommandSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_accountLogCommand = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.accountLogCommand = merge(state.accountLogCommand, payload) as any;
    }
  }
}

export const { clearStateaccountLogCommand, setSelectedaccountLogCommand, set_accountLogCommand, mergeaccountLogCommand, add_accountLogCommand } =
  accountLogCommandSlice.actions;

export const get_accountLogCommand =
  (id?: string) =>
  (state: {
    accountLogCommand: {
      accountLogCommand: AccountLogCommand[];
    };
  }): AccountLogCommand | null => {
    let temp = state?.accountLogCommand?.accountLogCommand?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.accountLogCommand?.accountLogCommand[0] || null;
  };
export const get_selected_accountLogCommand = (state: { accountLogCommand: accountLogCommandSliceState }): AccountLogCommand | null => {
  let id = state?.accountLogCommand?.selected_accountLogCommand;
  let temp = state?.accountLogCommand?.accountLogCommand?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_accountLogCommand = (state: {
  accountLogCommand: {
    accountLogCommand: AccountLogCommand[];
  };
}): AccountLogCommand[] => {
  let temp = state?.accountLogCommand?.accountLogCommand;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_accountLogCommand_busy = (state: { accountLogCommand: accountLogCommandSliceState }) => {
  return (
    state?.accountLogCommand.is_deleting_accountLogCommand ||
    state?.accountLogCommand.is_loading_accountLogCommand ||
    state?.accountLogCommand.is_creating_accountLogCommand
  );
};
export const is_deleting_accountLogCommand_busy = (state: { accountLogCommand: accountLogCommandSliceState }) => {
  return state?.accountLogCommand.is_deleting_accountLogCommand;
};
export const is_loading_accountLogCommand_busy = (state: { accountLogCommand: accountLogCommandSliceState }) => {
  return state?.accountLogCommand.is_loading_accountLogCommand;
};
export const is_creating_accountLogCommand_busy = (state: { accountLogCommand: accountLogCommandSliceState }) => {
  return state?.accountLogCommand.is_creating_accountLogCommand;
};

export default accountLogCommandSlice.reducer;
