import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import CourseQuestion from "./course-question-model";
import { CourseQuestionProperties, CourseQuestionMetaProperties } from "./courseQuestion-property-definitions";

export async function load_courseQuestions(args: { uid: string }): Promise<CourseQuestion[]> {
  throw "Not implemented";
}

export async function delete_courseQuestion(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_courseQuestion(args: CourseQuestion): Promise<CourseQuestion> {
  throw "Not implemented";
}
