import SalesOrder from "./sales-order-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateSalesOrderAsync, DeleteSalesOrderAsync, LoadSalesOrdersAsync } from "./salesOrder-thunk";
const initialState: salesOrderSliceState = {
  salesOrder: [],
  selected_salesOrder: "",
  is_loading_salesOrder: false,
  is_creating_salesOrder: false,
  is_deleting_salesOrder: false
};
export interface salesOrderSliceState {
  salesOrder: SalesOrder[];
  selected_salesOrder?: string;
  is_loading_salesOrder: boolean;
  is_creating_salesOrder: boolean;
  is_deleting_salesOrder: boolean;
}
export const salesOrderSlice = createSlice({
  name: "salesOrder",
  initialState,
  reducers: {
    setSelectedsalesOrder: (state, action) => {
      state.selected_salesOrder = action.payload;
    },
    clearStatesalesOrder: (state, action) => {
      state.salesOrder = [];
      state.is_loading_salesOrder = false;
      state.is_creating_salesOrder = false;
      state.is_deleting_salesOrder = false;
    },
    mergesalesOrder: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_salesOrder = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.salesOrder = merge(state.salesOrder, payload) as any;
        }
      }
    },

    add_salesOrder: (state, action) => {
      if (action.payload) {
        state.salesOrder = [...state.salesOrder, action.payload];
      }
    },
    set_salesOrder: (state, action) => {
      let { key, value, id } = action.payload;
      state.salesOrder = state.salesOrder.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadSalesOrdersAsync.pending, (state) => {
      state.is_loading_salesOrder = true;
    });
    builder.addCase(LoadSalesOrdersAsync.fulfilled, (state, action) => {
      state.is_loading_salesOrder = false;
      if (action.payload) {
        state.salesOrder = action.payload;
      }
    });
    builder.addCase(LoadSalesOrdersAsync.rejected, (state, action) => {
      state.is_loading_salesOrder = false;
    });
    builder.addCase(CreateSalesOrderAsync.pending, (state) => {
      state.is_creating_salesOrder = true;
    });
    builder.addCase(CreateSalesOrderAsync.fulfilled, (state) => {
      state.is_creating_salesOrder = false;
    });
    builder.addCase(CreateSalesOrderAsync.rejected, (state) => {
      state.is_creating_salesOrder = false;
    });
    builder.addCase(DeleteSalesOrderAsync.pending, (state) => {
      state.is_deleting_salesOrder = true;
    });
    builder.addCase(DeleteSalesOrderAsync.fulfilled, (state, action) => {
      state.is_deleting_salesOrder = false;
      if (state.salesOrder) {
        state.salesOrder = state.salesOrder.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteSalesOrderAsync.rejected, (state, action) => {
      state.is_deleting_salesOrder = false;
    });

    builder.addCase(autoThunks.get_my_sales_orders.pending, (state) => {
      state.is_loading_salesOrder = true;
    });
    builder.addCase(autoThunks.get_my_sales_orders.fulfilled, (state, action) => {
      state.is_loading_salesOrder = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_my_sales_orders.rejected, (state, action) => {
      state.is_loading_salesOrder = false;
    });

    builder.addCase(autoThunks.store_sales_order.pending, (state) => {
      state.is_loading_salesOrder = true;
    });
    builder.addCase(autoThunks.store_sales_order.fulfilled, (state, action) => {
      state.is_loading_salesOrder = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.store_sales_order.rejected, (state, action) => {
      state.is_loading_salesOrder = false;
    });

    builder.addCase(autoThunks.purchase_sales_order.pending, (state) => {
      state.is_loading_salesOrder = true;
    });
    builder.addCase(autoThunks.purchase_sales_order.fulfilled, (state, action) => {
      state.is_loading_salesOrder = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.purchase_sales_order.rejected, (state, action) => {
      state.is_loading_salesOrder = false;
    });

    builder.addCase(autoThunks.get_sales_order.pending, (state) => {
      state.is_loading_salesOrder = true;
    });
    builder.addCase(autoThunks.get_sales_order.fulfilled, (state, action) => {
      state.is_loading_salesOrder = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_sales_order.rejected, (state, action) => {
      state.is_loading_salesOrder = false;
    });

    builder.addCase(autoThunks.delete_sales_order.pending, (state) => {
      state.is_loading_salesOrder = true;
    });
    builder.addCase(autoThunks.delete_sales_order.fulfilled, (state, action) => {
      state.is_loading_salesOrder = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.delete_sales_order.rejected, (state, action) => {
      state.is_loading_salesOrder = false;
    });
  }
});

function handleResult(action: any, state: salesOrderSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_salesOrder = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.salesOrder = merge(state.salesOrder, payload) as any;
    }
  }
}

export const { clearStatesalesOrder, setSelectedsalesOrder, set_salesOrder, mergesalesOrder, add_salesOrder } = salesOrderSlice.actions;

export const get_salesOrder =
  (id?: string) =>
  (state: {
    salesOrder: {
      salesOrder: SalesOrder[];
    };
  }): SalesOrder | null => {
    let temp = state?.salesOrder?.salesOrder?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.salesOrder?.salesOrder[0] || null;
  };
export const get_selected_salesOrder = (state: { salesOrder: salesOrderSliceState }): SalesOrder | null => {
  let id = state?.salesOrder?.selected_salesOrder;
  let temp = state?.salesOrder?.salesOrder?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_salesOrder = (state: {
  salesOrder: {
    salesOrder: SalesOrder[];
  };
}): SalesOrder[] => {
  let temp = state?.salesOrder?.salesOrder;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_salesOrder_busy = (state: { salesOrder: salesOrderSliceState }) => {
  return state?.salesOrder.is_deleting_salesOrder || state?.salesOrder.is_loading_salesOrder || state?.salesOrder.is_creating_salesOrder;
};
export const is_deleting_salesOrder_busy = (state: { salesOrder: salesOrderSliceState }) => {
  return state?.salesOrder.is_deleting_salesOrder;
};
export const is_loading_salesOrder_busy = (state: { salesOrder: salesOrderSliceState }) => {
  return state?.salesOrder.is_loading_salesOrder;
};
export const is_creating_salesOrder_busy = (state: { salesOrder: salesOrderSliceState }) => {
  return state?.salesOrder.is_creating_salesOrder;
};

export default salesOrderSlice.reducer;
