import { createAsyncThunk } from "@reduxjs/toolkit";
import AuctionBid from "./auction-bid-model";
import { delete_auctionBid, load_auctionBids, store_auctionBid } from "./auctionBid-service";

export const LoadAuctionBidsAsync = createAsyncThunk<any, any>("composer/load/auctionBid", async (params: { uid: string }) => {
  return await load_auctionBids(params);
});

export const CreateAuctionBidAsync = createAsyncThunk<any, any>("composer/create/auctionBid", async (params: AuctionBid) => {
  return await store_auctionBid(params);
});

export const DeleteAuctionBidAsync = createAsyncThunk<any, any>("composer/delete/auctionBid", async (params) => {
  return await delete_auctionBid(params);
});
