import { createAsyncThunk } from "@reduxjs/toolkit";
import InvestmentOrder from "./investment-order-model";
import { delete_investmentOrder, load_investmentOrders, store_investmentOrder } from "./investmentOrder-service";

export const LoadInvestmentOrdersAsync = createAsyncThunk<any, any>("composer/load/investmentOrder", async (params: { uid: string }) => {
  return await load_investmentOrders(params);
});

export const CreateInvestmentOrderAsync = createAsyncThunk<any, any>("composer/create/investmentOrder", async (params: InvestmentOrder) => {
  return await store_investmentOrder(params);
});

export const DeleteInvestmentOrderAsync = createAsyncThunk<any, any>("composer/delete/investmentOrder", async (params) => {
  return await delete_investmentOrder(params);
});
