import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import ClaimsCache from "./claims-cache-model";
import { ClaimsCacheProperties, ClaimsCacheMetaProperties } from "./claimsCache-property-definitions";

export async function load_claimsCaches(args: { uid: string }): Promise<ClaimsCache[]> {
  throw "Not implemented";
}

export async function delete_claimsCache(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_claimsCache(args: ClaimsCache): Promise<ClaimsCache> {
  throw "Not implemented";
}
