import { createAsyncThunk } from "@reduxjs/toolkit";
import ClassRoom from "./class-room-model";
import { delete_classRoom, load_classRooms, store_classRoom } from "./classRoom-service";

export const LoadClassRoomsAsync = createAsyncThunk<any, any>("composer/load/classRoom", async (params: { uid: string }) => {
  return await load_classRooms(params);
});

export const CreateClassRoomAsync = createAsyncThunk<any, any>("composer/create/classRoom", async (params: ClassRoom) => {
  return await store_classRoom(params);
});

export const DeleteClassRoomAsync = createAsyncThunk<any, any>("composer/delete/classRoom", async (params) => {
  return await delete_classRoom(params);
});
