import InvestmentAccount from "./investment-account-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateInvestmentAccountAsync, DeleteInvestmentAccountAsync, LoadInvestmentAccountsAsync } from "./investmentAccount-thunk";
const initialState: investmentAccountSliceState = {
  investmentAccount: [],
  selected_investmentAccount: "",
  is_loading_investmentAccount: false,
  is_creating_investmentAccount: false,
  is_deleting_investmentAccount: false
};
export interface investmentAccountSliceState {
  investmentAccount: InvestmentAccount[];
  selected_investmentAccount?: string;
  is_loading_investmentAccount: boolean;
  is_creating_investmentAccount: boolean;
  is_deleting_investmentAccount: boolean;
}
export const investmentAccountSlice = createSlice({
  name: "investmentAccount",
  initialState,
  reducers: {
    setSelectedinvestmentAccount: (state, action) => {
      state.selected_investmentAccount = action.payload;
    },
    clearStateinvestmentAccount: (state, action) => {
      state.investmentAccount = [];
      state.is_loading_investmentAccount = false;
      state.is_creating_investmentAccount = false;
      state.is_deleting_investmentAccount = false;
    },
    mergeinvestmentAccount: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_investmentAccount = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.investmentAccount = merge(state.investmentAccount, payload) as any;
        }
      }
    },

    add_investmentAccount: (state, action) => {
      if (action.payload) {
        state.investmentAccount = [...state.investmentAccount, action.payload];
      }
    },
    set_investmentAccount: (state, action) => {
      let { key, value, id } = action.payload;
      state.investmentAccount = state.investmentAccount.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadInvestmentAccountsAsync.pending, (state) => {
      state.is_loading_investmentAccount = true;
    });
    builder.addCase(LoadInvestmentAccountsAsync.fulfilled, (state, action) => {
      state.is_loading_investmentAccount = false;
      if (action.payload) {
        state.investmentAccount = action.payload;
      }
    });
    builder.addCase(LoadInvestmentAccountsAsync.rejected, (state, action) => {
      state.is_loading_investmentAccount = false;
    });
    builder.addCase(CreateInvestmentAccountAsync.pending, (state) => {
      state.is_creating_investmentAccount = true;
    });
    builder.addCase(CreateInvestmentAccountAsync.fulfilled, (state) => {
      state.is_creating_investmentAccount = false;
    });
    builder.addCase(CreateInvestmentAccountAsync.rejected, (state) => {
      state.is_creating_investmentAccount = false;
    });
    builder.addCase(DeleteInvestmentAccountAsync.pending, (state) => {
      state.is_deleting_investmentAccount = true;
    });
    builder.addCase(DeleteInvestmentAccountAsync.fulfilled, (state, action) => {
      state.is_deleting_investmentAccount = false;
      if (state.investmentAccount) {
        state.investmentAccount = state.investmentAccount.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteInvestmentAccountAsync.rejected, (state, action) => {
      state.is_deleting_investmentAccount = false;
    });

    builder.addCase(autoThunks.get_customer_investment_accounts.pending, (state) => {
      state.is_loading_investmentAccount = true;
    });
    builder.addCase(autoThunks.get_customer_investment_accounts.fulfilled, (state, action) => {
      state.is_loading_investmentAccount = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_customer_investment_accounts.rejected, (state, action) => {
      state.is_loading_investmentAccount = false;
    });
  }
});

function handleResult(action: any, state: investmentAccountSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_investmentAccount = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.investmentAccount = merge(state.investmentAccount, payload) as any;
    }
  }
}

export const { clearStateinvestmentAccount, setSelectedinvestmentAccount, set_investmentAccount, mergeinvestmentAccount, add_investmentAccount } =
  investmentAccountSlice.actions;

export const get_investmentAccount =
  (id?: string) =>
  (state: {
    investmentAccount: {
      investmentAccount: InvestmentAccount[];
    };
  }): InvestmentAccount | null => {
    let temp = state?.investmentAccount?.investmentAccount?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.investmentAccount?.investmentAccount[0] || null;
  };
export const get_selected_investmentAccount = (state: { investmentAccount: investmentAccountSliceState }): InvestmentAccount | null => {
  let id = state?.investmentAccount?.selected_investmentAccount;
  let temp = state?.investmentAccount?.investmentAccount?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_investmentAccount = (state: {
  investmentAccount: {
    investmentAccount: InvestmentAccount[];
  };
}): InvestmentAccount[] => {
  let temp = state?.investmentAccount?.investmentAccount;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_investmentAccount_busy = (state: { investmentAccount: investmentAccountSliceState }) => {
  return (
    state?.investmentAccount.is_deleting_investmentAccount ||
    state?.investmentAccount.is_loading_investmentAccount ||
    state?.investmentAccount.is_creating_investmentAccount
  );
};
export const is_deleting_investmentAccount_busy = (state: { investmentAccount: investmentAccountSliceState }) => {
  return state?.investmentAccount.is_deleting_investmentAccount;
};
export const is_loading_investmentAccount_busy = (state: { investmentAccount: investmentAccountSliceState }) => {
  return state?.investmentAccount.is_loading_investmentAccount;
};
export const is_creating_investmentAccount_busy = (state: { investmentAccount: investmentAccountSliceState }) => {
  return state?.investmentAccount.is_creating_investmentAccount;
};

export default investmentAccountSlice.reducer;
