import { createAsyncThunk } from "@reduxjs/toolkit";
import EmployeeBenefits from "./employee-benefits-model";
import { delete_employeeBenefits, load_employeeBenefitss, store_employeeBenefits } from "./employeeBenefits-service";

export const LoadEmployeeBenefitssAsync = createAsyncThunk<any, any>("composer/load/employeeBenefits", async (params: { uid: string }) => {
  return await load_employeeBenefitss(params);
});

export const CreateEmployeeBenefitsAsync = createAsyncThunk<any, any>("composer/create/employeeBenefits", async (params: EmployeeBenefits) => {
  return await store_employeeBenefits(params);
});

export const DeleteEmployeeBenefitsAsync = createAsyncThunk<any, any>("composer/delete/employeeBenefits", async (params) => {
  return await delete_employeeBenefits(params);
});
