import { createAsyncThunk } from "@reduxjs/toolkit";
import VirtualShareEscroOffer from "./virtual-share-escro-offer-model";
import { delete_virtualShareEscroOffer, load_virtualShareEscroOffers, store_virtualShareEscroOffer } from "./virtualShareEscroOffer-service";

export const LoadVirtualShareEscroOffersAsync = createAsyncThunk<any, any>("composer/load/virtualShareEscroOffer", async (params: { uid: string }) => {
  return await load_virtualShareEscroOffers(params);
});

export const CreateVirtualShareEscroOfferAsync = createAsyncThunk<any, any>(
  "composer/create/virtualShareEscroOffer",
  async (params: VirtualShareEscroOffer) => {
    return await store_virtualShareEscroOffer(params);
  }
);

export const DeleteVirtualShareEscroOfferAsync = createAsyncThunk<any, any>("composer/delete/virtualShareEscroOffer", async (params) => {
  return await delete_virtualShareEscroOffer(params);
});
