import { FC, useState, createContext } from 'react';
type SidebarContext = {
  sidebarToggle: any;
  setKeepClosed: (keep: boolean) => void,
  toggleSidebar: () => void;
  closeSidebar: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

export const SidebarProvider: FC = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [keepClosed, setKeepClosed] = useState(false);
  const toggleSidebar = () => {
    if (keepClosed) {
      closeSidebar();
    }
    else
      setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  return (
    <SidebarContext.Provider
      value={{
        sidebarToggle, setKeepClosed: (keep) => {
          setKeepClosed(keep);
        }, toggleSidebar, closeSidebar
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
