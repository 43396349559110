import EmployeeBenefits from "./employee-benefits-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateEmployeeBenefitsAsync, DeleteEmployeeBenefitsAsync, LoadEmployeeBenefitssAsync } from "./employeeBenefits-thunk";
const initialState: employeeBenefitsSliceState = {
  employeeBenefits: [],
  selected_employeeBenefits: "",
  is_loading_employeeBenefits: false,
  is_creating_employeeBenefits: false,
  is_deleting_employeeBenefits: false
};
export interface employeeBenefitsSliceState {
  employeeBenefits: EmployeeBenefits[];
  selected_employeeBenefits?: string;
  is_loading_employeeBenefits: boolean;
  is_creating_employeeBenefits: boolean;
  is_deleting_employeeBenefits: boolean;
}
export const employeeBenefitsSlice = createSlice({
  name: "employeeBenefits",
  initialState,
  reducers: {
    setSelectedemployeeBenefits: (state, action) => {
      state.selected_employeeBenefits = action.payload;
    },
    clearStateemployeeBenefits: (state, action) => {
      state.employeeBenefits = [];
      state.is_loading_employeeBenefits = false;
      state.is_creating_employeeBenefits = false;
      state.is_deleting_employeeBenefits = false;
    },
    mergeemployeeBenefits: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_employeeBenefits = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.employeeBenefits = merge(state.employeeBenefits, payload) as any;
        }
      }
    },

    add_employeeBenefits: (state, action) => {
      if (action.payload) {
        state.employeeBenefits = [...state.employeeBenefits, action.payload];
      }
    },
    set_employeeBenefits: (state, action) => {
      let { key, value, id } = action.payload;
      state.employeeBenefits = state.employeeBenefits.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadEmployeeBenefitssAsync.pending, (state) => {
      state.is_loading_employeeBenefits = true;
    });
    builder.addCase(LoadEmployeeBenefitssAsync.fulfilled, (state, action) => {
      state.is_loading_employeeBenefits = false;
      if (action.payload) {
        state.employeeBenefits = action.payload;
      }
    });
    builder.addCase(LoadEmployeeBenefitssAsync.rejected, (state, action) => {
      state.is_loading_employeeBenefits = false;
    });
    builder.addCase(CreateEmployeeBenefitsAsync.pending, (state) => {
      state.is_creating_employeeBenefits = true;
    });
    builder.addCase(CreateEmployeeBenefitsAsync.fulfilled, (state) => {
      state.is_creating_employeeBenefits = false;
    });
    builder.addCase(CreateEmployeeBenefitsAsync.rejected, (state) => {
      state.is_creating_employeeBenefits = false;
    });
    builder.addCase(DeleteEmployeeBenefitsAsync.pending, (state) => {
      state.is_deleting_employeeBenefits = true;
    });
    builder.addCase(DeleteEmployeeBenefitsAsync.fulfilled, (state, action) => {
      state.is_deleting_employeeBenefits = false;
      if (state.employeeBenefits) {
        state.employeeBenefits = state.employeeBenefits.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteEmployeeBenefitsAsync.rejected, (state, action) => {
      state.is_deleting_employeeBenefits = false;
    });
  }
});

function handleResult(action: any, state: employeeBenefitsSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_employeeBenefits = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.employeeBenefits = merge(state.employeeBenefits, payload) as any;
    }
  }
}

export const { clearStateemployeeBenefits, setSelectedemployeeBenefits, set_employeeBenefits, mergeemployeeBenefits, add_employeeBenefits } =
  employeeBenefitsSlice.actions;

export const get_employeeBenefits =
  (id?: string) =>
  (state: {
    employeeBenefits: {
      employeeBenefits: EmployeeBenefits[];
    };
  }): EmployeeBenefits | null => {
    let temp = state?.employeeBenefits?.employeeBenefits?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.employeeBenefits?.employeeBenefits[0] || null;
  };
export const get_selected_employeeBenefits = (state: { employeeBenefits: employeeBenefitsSliceState }): EmployeeBenefits | null => {
  let id = state?.employeeBenefits?.selected_employeeBenefits;
  let temp = state?.employeeBenefits?.employeeBenefits?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_employeeBenefits = (state: {
  employeeBenefits: {
    employeeBenefits: EmployeeBenefits[];
  };
}): EmployeeBenefits[] => {
  let temp = state?.employeeBenefits?.employeeBenefits;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_employeeBenefits_busy = (state: { employeeBenefits: employeeBenefitsSliceState }) => {
  return (
    state?.employeeBenefits.is_deleting_employeeBenefits ||
    state?.employeeBenefits.is_loading_employeeBenefits ||
    state?.employeeBenefits.is_creating_employeeBenefits
  );
};
export const is_deleting_employeeBenefits_busy = (state: { employeeBenefits: employeeBenefitsSliceState }) => {
  return state?.employeeBenefits.is_deleting_employeeBenefits;
};
export const is_loading_employeeBenefits_busy = (state: { employeeBenefits: employeeBenefitsSliceState }) => {
  return state?.employeeBenefits.is_loading_employeeBenefits;
};
export const is_creating_employeeBenefits_busy = (state: { employeeBenefits: employeeBenefitsSliceState }) => {
  return state?.employeeBenefits.is_creating_employeeBenefits;
};

export default employeeBenefitsSlice.reducer;
