import Device from "./device-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateDeviceAsync, DeleteDeviceAsync, LoadDevicesAsync } from "./device-thunk";
const initialState: deviceSliceState = {
  device: [],
  selected_device: "",
  is_loading_device: false,
  is_creating_device: false,
  is_deleting_device: false
};
export interface deviceSliceState {
  device: Device[];
  selected_device?: string;
  is_loading_device: boolean;
  is_creating_device: boolean;
  is_deleting_device: boolean;
}
export const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    setSelecteddevice: (state, action) => {
      state.selected_device = action.payload;
    },
    clearStatedevice: (state, action) => {
      state.device = [];
      state.is_loading_device = false;
      state.is_creating_device = false;
      state.is_deleting_device = false;
    },
    mergedevice: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_device = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.device = merge(state.device, payload) as any;
        }
      }
    },

    add_device: (state, action) => {
      if (action.payload) {
        state.device = [...state.device, action.payload];
      }
    },
    set_device: (state, action) => {
      let { key, value, id } = action.payload;
      state.device = state.device.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadDevicesAsync.pending, (state) => {
      state.is_loading_device = true;
    });
    builder.addCase(LoadDevicesAsync.fulfilled, (state, action) => {
      state.is_loading_device = false;
      if (action.payload) {
        state.device = action.payload;
      }
    });
    builder.addCase(LoadDevicesAsync.rejected, (state, action) => {
      state.is_loading_device = false;
    });
    builder.addCase(CreateDeviceAsync.pending, (state) => {
      state.is_creating_device = true;
    });
    builder.addCase(CreateDeviceAsync.fulfilled, (state) => {
      state.is_creating_device = false;
    });
    builder.addCase(CreateDeviceAsync.rejected, (state) => {
      state.is_creating_device = false;
    });
    builder.addCase(DeleteDeviceAsync.pending, (state) => {
      state.is_deleting_device = true;
    });
    builder.addCase(DeleteDeviceAsync.fulfilled, (state, action) => {
      state.is_deleting_device = false;
      if (state.device) {
        state.device = state.device.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteDeviceAsync.rejected, (state, action) => {
      state.is_deleting_device = false;
    });

    builder.addCase(autoThunks.send_test_push_notification.pending, (state) => {
      state.is_loading_device = true;
    });
    builder.addCase(autoThunks.send_test_push_notification.fulfilled, (state, action) => {
      state.is_loading_device = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.send_test_push_notification.rejected, (state, action) => {
      state.is_loading_device = false;
    });

    builder.addCase(autoThunks.upsert_device_info.pending, (state) => {
      state.is_loading_device = true;
    });
    builder.addCase(autoThunks.upsert_device_info.fulfilled, (state, action) => {
      state.is_loading_device = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.upsert_device_info.rejected, (state, action) => {
      state.is_loading_device = false;
    });
  }
});

function handleResult(action: any, state: deviceSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_device = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.device = merge(state.device, payload) as any;
    }
  }
}

export const { clearStatedevice, setSelecteddevice, set_device, mergedevice, add_device } = deviceSlice.actions;

export const get_device =
  (id?: string) =>
  (state: {
    device: {
      device: Device[];
    };
  }): Device | null => {
    let temp = state?.device?.device?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.device?.device[0] || null;
  };
export const get_selected_device = (state: { device: deviceSliceState }): Device | null => {
  let id = state?.device?.selected_device;
  let temp = state?.device?.device?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_device = (state: {
  device: {
    device: Device[];
  };
}): Device[] => {
  let temp = state?.device?.device;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_device_busy = (state: { device: deviceSliceState }) => {
  return state?.device.is_deleting_device || state?.device.is_loading_device || state?.device.is_creating_device;
};
export const is_deleting_device_busy = (state: { device: deviceSliceState }) => {
  return state?.device.is_deleting_device;
};
export const is_loading_device_busy = (state: { device: deviceSliceState }) => {
  return state?.device.is_loading_device;
};
export const is_creating_device_busy = (state: { device: deviceSliceState }) => {
  return state?.device.is_creating_device;
};

export default deviceSlice.reducer;
