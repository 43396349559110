import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import PageTitle from 'components/PageTitle';
import PageTitleWrapper from 'components/PageTitleWrapper';
import {
    Container,
    Grid,
    Card,
    CardHeader,
    CardContent,
    Divider,
    DialogContent,
    Box,
    TextField,
    DialogActions,
    Button,
    MenuItem,
    CardActionArea,
    Tooltip,
    LinearProgress
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import Footer from 'components/Footer';
import TitleService from 'title-service';
import Course from 'features/course/course-model';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import RedQuickFileDrop from 'components/RedQuickFileDrop';
import { useDispatch, useSelector } from 'react-redux';
import { getUserClaims } from 'features/application/application-slice';
import { uuid } from 'components/util';
import { UploadThunkAsync } from 'storage-service';
import { add_course_section, add_my_enrollments, add_my_professor_connected_course, add_publish_course_job, create_checkout_session, get_available_courses, get_connected_courses, get_course_paids, get_course_sections, get_my_courses, get_my_professor_courses, set_course_sorting, update_course_as_director, update_course_as_professor, update_professor_course } from 'features/auto-thunks';
import { CourseState } from 'features/enumerations';
import { CourseSection, CourseSorting } from 'models';
import CourseSectionList from 'components/CourseSectionList';
import { get_all_courseSection, is_courseSection_busy } from 'features/courseSection/courseSection-slice';
import useLogos from 'hooks/useLogos';
import { CardAddAction } from 'content/applications/Users/profile/CardAddAction';
import { AvatarAddWrapper } from 'content/applications/Users/profile/AvatarAddWrapper';
import { get_all_courseSorting, is_courseSorting_busy } from 'features/courseSorting/courseSorting-slice';
import { is_courseQuestionSorting_busy } from 'features/courseQuestionSorting/courseQuestionSorting-slice';
import { is_courseQuestion_busy } from 'features/courseQuestion/courseQuestion-slice';
import { get_all_course } from 'features/course/course-slice';
import CourseListItem from './CourseListItem';
import { get_all_coursePay, is_coursePay_busy } from 'features/coursePay/coursePay-slice';

const emails = ['username@gmail.com', 'user02@gmail.com'];

export default function AddCourseDialog(props: any) {
    const { onClose, selectedValue } = props;
    const claims = useSelector(getUserClaims);
    const all_courses = useSelector(get_all_course);
    const course_busy = useSelector(is_courseSection_busy);
    const course_pay_busy = useSelector(is_coursePay_busy);
    const course_sorting_busy = useSelector(is_courseSorting_busy);
    const course_question_busy = useSelector(is_courseQuestion_busy);
    const course_question_sorting_busy = useSelector(is_courseQuestionSorting_busy);
    const dispatch = useDispatch()
    const handleClose = () => {
        onClose(selectedValue);
    };
    const logos = useLogos();
    const busy = course_pay_busy || course_busy || course_sorting_busy || course_question_sorting_busy || course_question_busy;
    useEffect(() => {
        if (claims?.courseTaker && props.open) {
            dispatch(get_available_courses({
                userId: claims?.user,
                courseTaker: claims?.courseTaker
            }));
            dispatch(get_course_paids({
                userId: claims?.user
            }))
        }
    }, [claims?.courseTaker, props.open]);

    return (
        <Dialog onClose={handleClose} fullWidth maxWidth={"xl"} sx={{
            MuiPaperRoot: {
                width: '70vw', alignSelf: 'center'
            }
        }} open={!!props.open} >
            <DialogTitle>{TitleService(`Add Course`)}</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    direction="row"
                    justifyContent="start"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        {busy && <LinearProgress />}
                    </Grid>
                </Grid>
                <Box
                    sx={{ p: 2 }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography variant="h5">{TitleService(`Courses`)}</Typography>
                </Box>
                <Divider />
                <List sx={{ p: 0 }}>
                    {all_courses.filter(x => (x.coursePay && claims?.director) || (!x.coursePay)).filter(x => x.courseState === CourseState.Published).map(course => {
                        return <CourseListItem
                            onClick={async () => {
                                if (course.coursePay) {
                                    if (claims?.courseTaker) {
                                        await dispatch(create_checkout_session({
                                            itemId: course.coursePay,
                                            courseTakerId: claims?.courseTaker,
                                            callback: (success, res) => {
                                                console.log(res);
                                                if (success && res?.data?.url) {
                                                    openStripeCheckout(res.data.url)
                                                }
                                            }
                                        }));
                                    }
                                }
                                else {
                                    await dispatch(add_my_enrollments({
                                        courseTaker: claims?.courseTaker,
                                        courseId: course?.id,
                                        userId: claims?.user
                                    }));
                                    await dispatch(get_my_courses({
                                        courseTaker: claims?.courseTaker,
                                        userId: claims?.user
                                    }));
                                    await dispatch(get_available_courses({
                                        courseTaker: claims?.courseTaker,
                                        userId: claims?.user
                                    }));
                                    onClose();
                                }
                            }}
                            key={course?.id}
                            course={course} />
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    onClose();
                }}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
function openStripeCheckout(url: string): void {
    (window as any).location = url;
    // // Define the window features
    // const features = 'width=800,height=600,scrollbars=yes';

    // // Open the URL in a new window
    // const newWindow = window.open(url, '_blank', features);

    // // Handle the case when the new window fails to open
    // if (!newWindow) {
    //     console.error('Failed to open the Stripe Checkout window. Please make sure your browser allows pop-ups.');
    // }
}

AddCourseDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};