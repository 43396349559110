import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import Answer from "./answer-model";
import { AnswerProperties, AnswerMetaProperties } from "./answer-property-definitions";

export async function load_answers(args: { uid: string }): Promise<Answer[]> {
  throw "Not implemented";
}

export async function delete_answer(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_answer(args: Answer): Promise<Answer> {
  throw "Not implemented";
}
