import CourseTopicSummary from "./course-topic-summary-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateCourseTopicSummaryAsync, DeleteCourseTopicSummaryAsync, LoadCourseTopicSummarysAsync } from "./courseTopicSummary-thunk";
const initialState: courseTopicSummarySliceState = {
  courseTopicSummary: [],
  selected_courseTopicSummary: "",
  is_loading_courseTopicSummary: false,
  is_creating_courseTopicSummary: false,
  is_deleting_courseTopicSummary: false
};
export interface courseTopicSummarySliceState {
  courseTopicSummary: CourseTopicSummary[];
  selected_courseTopicSummary?: string;
  is_loading_courseTopicSummary: boolean;
  is_creating_courseTopicSummary: boolean;
  is_deleting_courseTopicSummary: boolean;
}
export const courseTopicSummarySlice = createSlice({
  name: "courseTopicSummary",
  initialState,
  reducers: {
    setSelectedcourseTopicSummary: (state, action) => {
      state.selected_courseTopicSummary = action.payload;
    },
    clearStatecourseTopicSummary: (state, action) => {
      state.courseTopicSummary = [];
      state.is_loading_courseTopicSummary = false;
      state.is_creating_courseTopicSummary = false;
      state.is_deleting_courseTopicSummary = false;
    },
    mergecourseTopicSummary: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_courseTopicSummary = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.courseTopicSummary = merge(state.courseTopicSummary, payload) as any;
        }
      }
    },

    add_courseTopicSummary: (state, action) => {
      if (action.payload) {
        state.courseTopicSummary = [...state.courseTopicSummary, action.payload];
      }
    },
    set_courseTopicSummary: (state, action) => {
      let { key, value, id } = action.payload;
      state.courseTopicSummary = state.courseTopicSummary.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadCourseTopicSummarysAsync.pending, (state) => {
      state.is_loading_courseTopicSummary = true;
    });
    builder.addCase(LoadCourseTopicSummarysAsync.fulfilled, (state, action) => {
      state.is_loading_courseTopicSummary = false;
      if (action.payload) {
        state.courseTopicSummary = action.payload;
      }
    });
    builder.addCase(LoadCourseTopicSummarysAsync.rejected, (state, action) => {
      state.is_loading_courseTopicSummary = false;
    });
    builder.addCase(CreateCourseTopicSummaryAsync.pending, (state) => {
      state.is_creating_courseTopicSummary = true;
    });
    builder.addCase(CreateCourseTopicSummaryAsync.fulfilled, (state) => {
      state.is_creating_courseTopicSummary = false;
    });
    builder.addCase(CreateCourseTopicSummaryAsync.rejected, (state) => {
      state.is_creating_courseTopicSummary = false;
    });
    builder.addCase(DeleteCourseTopicSummaryAsync.pending, (state) => {
      state.is_deleting_courseTopicSummary = true;
    });
    builder.addCase(DeleteCourseTopicSummaryAsync.fulfilled, (state, action) => {
      state.is_deleting_courseTopicSummary = false;
      if (state.courseTopicSummary) {
        state.courseTopicSummary = state.courseTopicSummary.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteCourseTopicSummaryAsync.rejected, (state, action) => {
      state.is_deleting_courseTopicSummary = false;
    });

    builder.addCase(autoThunks.get_course_topic_summaries.pending, (state) => {
      state.is_loading_courseTopicSummary = true;
    });
    builder.addCase(autoThunks.get_course_topic_summaries.fulfilled, (state, action) => {
      state.is_loading_courseTopicSummary = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_course_topic_summaries.rejected, (state, action) => {
      state.is_loading_courseTopicSummary = false;
    });
  }
});

function handleResult(action: any, state: courseTopicSummarySliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_courseTopicSummary = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.courseTopicSummary = merge(state.courseTopicSummary, payload) as any;
    }
  }
}

export const { clearStatecourseTopicSummary, setSelectedcourseTopicSummary, set_courseTopicSummary, mergecourseTopicSummary, add_courseTopicSummary } =
  courseTopicSummarySlice.actions;

export const get_courseTopicSummary =
  (id?: string) =>
  (state: {
    courseTopicSummary: {
      courseTopicSummary: CourseTopicSummary[];
    };
  }): CourseTopicSummary | null => {
    let temp = state?.courseTopicSummary?.courseTopicSummary?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.courseTopicSummary?.courseTopicSummary[0] || null;
  };
export const get_selected_courseTopicSummary = (state: { courseTopicSummary: courseTopicSummarySliceState }): CourseTopicSummary | null => {
  let id = state?.courseTopicSummary?.selected_courseTopicSummary;
  let temp = state?.courseTopicSummary?.courseTopicSummary?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_courseTopicSummary = (state: {
  courseTopicSummary: {
    courseTopicSummary: CourseTopicSummary[];
  };
}): CourseTopicSummary[] => {
  let temp = state?.courseTopicSummary?.courseTopicSummary;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_courseTopicSummary_busy = (state: { courseTopicSummary: courseTopicSummarySliceState }) => {
  return (
    state?.courseTopicSummary.is_deleting_courseTopicSummary ||
    state?.courseTopicSummary.is_loading_courseTopicSummary ||
    state?.courseTopicSummary.is_creating_courseTopicSummary
  );
};
export const is_deleting_courseTopicSummary_busy = (state: { courseTopicSummary: courseTopicSummarySliceState }) => {
  return state?.courseTopicSummary.is_deleting_courseTopicSummary;
};
export const is_loading_courseTopicSummary_busy = (state: { courseTopicSummary: courseTopicSummarySliceState }) => {
  return state?.courseTopicSummary.is_loading_courseTopicSummary;
};
export const is_creating_courseTopicSummary_busy = (state: { courseTopicSummary: courseTopicSummarySliceState }) => {
  return state?.courseTopicSummary.is_creating_courseTopicSummary;
};

export default courseTopicSummarySlice.reducer;
