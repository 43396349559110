import CourseSection from "./course-section-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateCourseSectionAsync, DeleteCourseSectionAsync, LoadCourseSectionsAsync } from "./courseSection-thunk";
const initialState: courseSectionSliceState = {
  courseSection: [],
  selected_courseSection: "",
  is_loading_courseSection: false,
  is_creating_courseSection: false,
  is_deleting_courseSection: false
};
export interface courseSectionSliceState {
  courseSection: CourseSection[];
  selected_courseSection?: string;
  is_loading_courseSection: boolean;
  is_creating_courseSection: boolean;
  is_deleting_courseSection: boolean;
}
export const courseSectionSlice = createSlice({
  name: "courseSection",
  initialState,
  reducers: {
    setSelectedcourseSection: (state, action) => {
      state.selected_courseSection = action.payload;
    },
    clearStatecourseSection: (state, action) => {
      state.courseSection = [];
      state.is_loading_courseSection = false;
      state.is_creating_courseSection = false;
      state.is_deleting_courseSection = false;
    },
    mergecourseSection: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_courseSection = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.courseSection = merge(state.courseSection, payload) as any;
        }
      }
    },

    add_courseSection: (state, action) => {
      if (action.payload) {
        state.courseSection = [...state.courseSection, action.payload];
      }
    },
    set_courseSection: (state, action) => {
      let { key, value, id } = action.payload;
      state.courseSection = state.courseSection.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadCourseSectionsAsync.pending, (state) => {
      state.is_loading_courseSection = true;
    });
    builder.addCase(LoadCourseSectionsAsync.fulfilled, (state, action) => {
      state.is_loading_courseSection = false;
      if (action.payload) {
        state.courseSection = action.payload;
      }
    });
    builder.addCase(LoadCourseSectionsAsync.rejected, (state, action) => {
      state.is_loading_courseSection = false;
    });
    builder.addCase(CreateCourseSectionAsync.pending, (state) => {
      state.is_creating_courseSection = true;
    });
    builder.addCase(CreateCourseSectionAsync.fulfilled, (state) => {
      state.is_creating_courseSection = false;
    });
    builder.addCase(CreateCourseSectionAsync.rejected, (state) => {
      state.is_creating_courseSection = false;
    });
    builder.addCase(DeleteCourseSectionAsync.pending, (state) => {
      state.is_deleting_courseSection = true;
    });
    builder.addCase(DeleteCourseSectionAsync.fulfilled, (state, action) => {
      state.is_deleting_courseSection = false;
      if (state.courseSection) {
        state.courseSection = state.courseSection.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteCourseSectionAsync.rejected, (state, action) => {
      state.is_deleting_courseSection = false;
    });

    builder.addCase(autoThunks.add_course_section.pending, (state) => {
      state.is_loading_courseSection = true;
    });
    builder.addCase(autoThunks.add_course_section.fulfilled, (state, action) => {
      state.is_loading_courseSection = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.add_course_section.rejected, (state, action) => {
      state.is_loading_courseSection = false;
    });

    builder.addCase(autoThunks.get_course_sections.pending, (state) => {
      state.is_loading_courseSection = true;
    });
    builder.addCase(autoThunks.get_course_sections.fulfilled, (state, action) => {
      state.is_loading_courseSection = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_course_sections.rejected, (state, action) => {
      state.is_loading_courseSection = false;
    });

    builder.addCase(autoThunks.delete_course_sections.pending, (state) => {
      state.is_loading_courseSection = true;
    });
    builder.addCase(autoThunks.delete_course_sections.fulfilled, (state, action) => {
      state.is_loading_courseSection = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.delete_course_sections.rejected, (state, action) => {
      state.is_loading_courseSection = false;
    });

    builder.addCase(autoThunks.delete_course_section.pending, (state) => {
      state.is_loading_courseSection = true;
    });
    builder.addCase(autoThunks.delete_course_section.fulfilled, (state, action) => {
      state.is_loading_courseSection = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.delete_course_section.rejected, (state, action) => {
      state.is_loading_courseSection = false;
    });

    builder.addCase(autoThunks.create_random_section.pending, (state) => {
      state.is_loading_courseSection = true;
    });
    builder.addCase(autoThunks.create_random_section.fulfilled, (state, action) => {
      state.is_loading_courseSection = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.create_random_section.rejected, (state, action) => {
      state.is_loading_courseSection = false;
    });
  }
});

function handleResult(action: any, state: courseSectionSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_courseSection = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.courseSection = merge(state.courseSection, payload) as any;
    }
  }
}

export const { clearStatecourseSection, setSelectedcourseSection, set_courseSection, mergecourseSection, add_courseSection } = courseSectionSlice.actions;

export const get_courseSection =
  (id?: string) =>
  (state: {
    courseSection: {
      courseSection: CourseSection[];
    };
  }): CourseSection | null => {
    let temp = state?.courseSection?.courseSection?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.courseSection?.courseSection[0] || null;
  };
export const get_selected_courseSection = (state: { courseSection: courseSectionSliceState }): CourseSection | null => {
  let id = state?.courseSection?.selected_courseSection;
  let temp = state?.courseSection?.courseSection?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_courseSection = (state: {
  courseSection: {
    courseSection: CourseSection[];
  };
}): CourseSection[] => {
  let temp = state?.courseSection?.courseSection;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_courseSection_busy = (state: { courseSection: courseSectionSliceState }) => {
  return state?.courseSection.is_deleting_courseSection || state?.courseSection.is_loading_courseSection || state?.courseSection.is_creating_courseSection;
};
export const is_deleting_courseSection_busy = (state: { courseSection: courseSectionSliceState }) => {
  return state?.courseSection.is_deleting_courseSection;
};
export const is_loading_courseSection_busy = (state: { courseSection: courseSectionSliceState }) => {
  return state?.courseSection.is_loading_courseSection;
};
export const is_creating_courseSection_busy = (state: { courseSection: courseSectionSliceState }) => {
  return state?.courseSection.is_creating_courseSection;
};

export default courseSectionSlice.reducer;
