import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import VirtualBusinessInvestorReference from "./virtual-business-investor-reference-model";
import {
  VirtualBusinessInvestorReferenceProperties,
  VirtualBusinessInvestorReferenceMetaProperties
} from "./virtualBusinessInvestorReference-property-definitions";

export async function load_virtualBusinessInvestorReferences(args: { uid: string }): Promise<VirtualBusinessInvestorReference[]> {
  throw "Not implemented";
}

export async function delete_virtualBusinessInvestorReference(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_virtualBusinessInvestorReference(args: VirtualBusinessInvestorReference): Promise<VirtualBusinessInvestorReference> {
  throw "Not implemented";
}
