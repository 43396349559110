import CoursePay from "./course-pay-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateCoursePayAsync, DeleteCoursePayAsync, LoadCoursePaysAsync } from "./coursePay-thunk";
const initialState: coursePaySliceState = {
  coursePay: [],
  selected_coursePay: "",
  is_loading_coursePay: false,
  is_creating_coursePay: false,
  is_deleting_coursePay: false
};
export interface coursePaySliceState {
  coursePay: CoursePay[];
  selected_coursePay?: string;
  is_loading_coursePay: boolean;
  is_creating_coursePay: boolean;
  is_deleting_coursePay: boolean;
}
export const coursePaySlice = createSlice({
  name: "coursePay",
  initialState,
  reducers: {
    setSelectedcoursePay: (state, action) => {
      state.selected_coursePay = action.payload;
    },
    clearStatecoursePay: (state, action) => {
      state.coursePay = [];
      state.is_loading_coursePay = false;
      state.is_creating_coursePay = false;
      state.is_deleting_coursePay = false;
    },
    mergecoursePay: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_coursePay = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.coursePay = merge(state.coursePay, payload) as any;
        }
      }
    },

    add_coursePay: (state, action) => {
      if (action.payload) {
        state.coursePay = [...state.coursePay, action.payload];
      }
    },
    set_coursePay: (state, action) => {
      let { key, value, id } = action.payload;
      state.coursePay = state.coursePay.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadCoursePaysAsync.pending, (state) => {
      state.is_loading_coursePay = true;
    });
    builder.addCase(LoadCoursePaysAsync.fulfilled, (state, action) => {
      state.is_loading_coursePay = false;
      if (action.payload) {
        state.coursePay = action.payload;
      }
    });
    builder.addCase(LoadCoursePaysAsync.rejected, (state, action) => {
      state.is_loading_coursePay = false;
    });
    builder.addCase(CreateCoursePayAsync.pending, (state) => {
      state.is_creating_coursePay = true;
    });
    builder.addCase(CreateCoursePayAsync.fulfilled, (state) => {
      state.is_creating_coursePay = false;
    });
    builder.addCase(CreateCoursePayAsync.rejected, (state) => {
      state.is_creating_coursePay = false;
    });
    builder.addCase(DeleteCoursePayAsync.pending, (state) => {
      state.is_deleting_coursePay = true;
    });
    builder.addCase(DeleteCoursePayAsync.fulfilled, (state, action) => {
      state.is_deleting_coursePay = false;
      if (state.coursePay) {
        state.coursePay = state.coursePay.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteCoursePayAsync.rejected, (state, action) => {
      state.is_deleting_coursePay = false;
    });

    builder.addCase(autoThunks.set_course_pay.pending, (state) => {
      state.is_loading_coursePay = true;
    });
    builder.addCase(autoThunks.set_course_pay.fulfilled, (state, action) => {
      state.is_loading_coursePay = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.set_course_pay.rejected, (state, action) => {
      state.is_loading_coursePay = false;
    });

    builder.addCase(autoThunks.get_course_paid.pending, (state) => {
      state.is_loading_coursePay = true;
    });
    builder.addCase(autoThunks.get_course_paid.fulfilled, (state, action) => {
      state.is_loading_coursePay = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_course_paid.rejected, (state, action) => {
      state.is_loading_coursePay = false;
    });

    builder.addCase(autoThunks.get_course_paids.pending, (state) => {
      state.is_loading_coursePay = true;
    });
    builder.addCase(autoThunks.get_course_paids.fulfilled, (state, action) => {
      state.is_loading_coursePay = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_course_paids.rejected, (state, action) => {
      state.is_loading_coursePay = false;
    });
  }
});

function handleResult(action: any, state: coursePaySliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_coursePay = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.coursePay = merge(state.coursePay, payload) as any;
    }
  }
}

export const { clearStatecoursePay, setSelectedcoursePay, set_coursePay, mergecoursePay, add_coursePay } = coursePaySlice.actions;

export const get_coursePay =
  (id?: string) =>
  (state: {
    coursePay: {
      coursePay: CoursePay[];
    };
  }): CoursePay | null => {
    let temp = state?.coursePay?.coursePay?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.coursePay?.coursePay[0] || null;
  };
export const get_selected_coursePay = (state: { coursePay: coursePaySliceState }): CoursePay | null => {
  let id = state?.coursePay?.selected_coursePay;
  let temp = state?.coursePay?.coursePay?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_coursePay = (state: {
  coursePay: {
    coursePay: CoursePay[];
  };
}): CoursePay[] => {
  let temp = state?.coursePay?.coursePay;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_coursePay_busy = (state: { coursePay: coursePaySliceState }) => {
  return state?.coursePay.is_deleting_coursePay || state?.coursePay.is_loading_coursePay || state?.coursePay.is_creating_coursePay;
};
export const is_deleting_coursePay_busy = (state: { coursePay: coursePaySliceState }) => {
  return state?.coursePay.is_deleting_coursePay;
};
export const is_loading_coursePay_busy = (state: { coursePay: coursePaySliceState }) => {
  return state?.coursePay.is_loading_coursePay;
};
export const is_creating_coursePay_busy = (state: { coursePay: coursePaySliceState }) => {
  return state?.coursePay.is_creating_coursePay;
};

export default coursePaySlice.reducer;
