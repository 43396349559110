import ResourceUserAvatarList from "./resource-user-avatar-list-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateResourceUserAvatarListAsync, DeleteResourceUserAvatarListAsync, LoadResourceUserAvatarListsAsync } from "./resourceUserAvatarList-thunk";
const initialState: resourceUserAvatarListSliceState = {
  resourceUserAvatarList: [],
  selected_resourceUserAvatarList: "",
  is_loading_resourceUserAvatarList: false,
  is_creating_resourceUserAvatarList: false,
  is_deleting_resourceUserAvatarList: false
};
export interface resourceUserAvatarListSliceState {
  resourceUserAvatarList: ResourceUserAvatarList[];
  selected_resourceUserAvatarList?: string;
  is_loading_resourceUserAvatarList: boolean;
  is_creating_resourceUserAvatarList: boolean;
  is_deleting_resourceUserAvatarList: boolean;
}
export const resourceUserAvatarListSlice = createSlice({
  name: "resourceUserAvatarList",
  initialState,
  reducers: {
    setSelectedresourceUserAvatarList: (state, action) => {
      state.selected_resourceUserAvatarList = action.payload;
    },
    clearStateresourceUserAvatarList: (state, action) => {
      state.resourceUserAvatarList = [];
      state.is_loading_resourceUserAvatarList = false;
      state.is_creating_resourceUserAvatarList = false;
      state.is_deleting_resourceUserAvatarList = false;
    },
    mergeresourceUserAvatarList: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_resourceUserAvatarList = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.resourceUserAvatarList = merge(state.resourceUserAvatarList, payload) as any;
        }
      }
    },

    add_resourceUserAvatarList: (state, action) => {
      if (action.payload) {
        state.resourceUserAvatarList = [...state.resourceUserAvatarList, action.payload];
      }
    },
    set_resourceUserAvatarList: (state, action) => {
      let { key, value, id } = action.payload;
      state.resourceUserAvatarList = state.resourceUserAvatarList.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadResourceUserAvatarListsAsync.pending, (state) => {
      state.is_loading_resourceUserAvatarList = true;
    });
    builder.addCase(LoadResourceUserAvatarListsAsync.fulfilled, (state, action) => {
      state.is_loading_resourceUserAvatarList = false;
      if (action.payload) {
        state.resourceUserAvatarList = action.payload;
      }
    });
    builder.addCase(LoadResourceUserAvatarListsAsync.rejected, (state, action) => {
      state.is_loading_resourceUserAvatarList = false;
    });
    builder.addCase(CreateResourceUserAvatarListAsync.pending, (state) => {
      state.is_creating_resourceUserAvatarList = true;
    });
    builder.addCase(CreateResourceUserAvatarListAsync.fulfilled, (state) => {
      state.is_creating_resourceUserAvatarList = false;
    });
    builder.addCase(CreateResourceUserAvatarListAsync.rejected, (state) => {
      state.is_creating_resourceUserAvatarList = false;
    });
    builder.addCase(DeleteResourceUserAvatarListAsync.pending, (state) => {
      state.is_deleting_resourceUserAvatarList = true;
    });
    builder.addCase(DeleteResourceUserAvatarListAsync.fulfilled, (state, action) => {
      state.is_deleting_resourceUserAvatarList = false;
      if (state.resourceUserAvatarList) {
        state.resourceUserAvatarList = state.resourceUserAvatarList.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteResourceUserAvatarListAsync.rejected, (state, action) => {
      state.is_deleting_resourceUserAvatarList = false;
    });
  }
});

function handleResult(action: any, state: resourceUserAvatarListSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_resourceUserAvatarList = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.resourceUserAvatarList = merge(state.resourceUserAvatarList, payload) as any;
    }
  }
}

export const {
  clearStateresourceUserAvatarList,
  setSelectedresourceUserAvatarList,
  set_resourceUserAvatarList,
  mergeresourceUserAvatarList,
  add_resourceUserAvatarList
} = resourceUserAvatarListSlice.actions;

export const get_resourceUserAvatarList =
  (id?: string) =>
  (state: {
    resourceUserAvatarList: {
      resourceUserAvatarList: ResourceUserAvatarList[];
    };
  }): ResourceUserAvatarList | null => {
    let temp = state?.resourceUserAvatarList?.resourceUserAvatarList?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.resourceUserAvatarList?.resourceUserAvatarList[0] || null;
  };
export const get_selected_resourceUserAvatarList = (state: { resourceUserAvatarList: resourceUserAvatarListSliceState }): ResourceUserAvatarList | null => {
  let id = state?.resourceUserAvatarList?.selected_resourceUserAvatarList;
  let temp = state?.resourceUserAvatarList?.resourceUserAvatarList?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_resourceUserAvatarList = (state: {
  resourceUserAvatarList: {
    resourceUserAvatarList: ResourceUserAvatarList[];
  };
}): ResourceUserAvatarList[] => {
  let temp = state?.resourceUserAvatarList?.resourceUserAvatarList;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_resourceUserAvatarList_busy = (state: { resourceUserAvatarList: resourceUserAvatarListSliceState }) => {
  return (
    state?.resourceUserAvatarList.is_deleting_resourceUserAvatarList ||
    state?.resourceUserAvatarList.is_loading_resourceUserAvatarList ||
    state?.resourceUserAvatarList.is_creating_resourceUserAvatarList
  );
};
export const is_deleting_resourceUserAvatarList_busy = (state: { resourceUserAvatarList: resourceUserAvatarListSliceState }) => {
  return state?.resourceUserAvatarList.is_deleting_resourceUserAvatarList;
};
export const is_loading_resourceUserAvatarList_busy = (state: { resourceUserAvatarList: resourceUserAvatarListSliceState }) => {
  return state?.resourceUserAvatarList.is_loading_resourceUserAvatarList;
};
export const is_creating_resourceUserAvatarList_busy = (state: { resourceUserAvatarList: resourceUserAvatarListSliceState }) => {
  return state?.resourceUserAvatarList.is_creating_resourceUserAvatarList;
};

export default resourceUserAvatarListSlice.reducer;
