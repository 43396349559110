import { createAsyncThunk } from "@reduxjs/toolkit";
import CourseContract from "./course-contract-model";
import { delete_courseContract, load_courseContracts, store_courseContract } from "./courseContract-service";

export const LoadCourseContractsAsync = createAsyncThunk<any, any>("composer/load/courseContract", async (params: { uid: string }) => {
  return await load_courseContracts(params);
});

export const CreateCourseContractAsync = createAsyncThunk<any, any>("composer/create/courseContract", async (params: CourseContract) => {
  return await store_courseContract(params);
});

export const DeleteCourseContractAsync = createAsyncThunk<any, any>("composer/delete/courseContract", async (params) => {
  return await delete_courseContract(params);
});
