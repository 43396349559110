import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import DigitalAssetInstance from "./digital-asset-instance-model";
import { DigitalAssetInstanceProperties, DigitalAssetInstanceMetaProperties } from "./digitalAssetInstance-property-definitions";

export async function load_digitalAssetInstances(args: { uid: string }): Promise<DigitalAssetInstance[]> {
  throw "Not implemented";
}

export async function delete_digitalAssetInstance(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_digitalAssetInstance(args: DigitalAssetInstance): Promise<DigitalAssetInstance> {
  throw "Not implemented";
}
