import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import VirtualEmploymentRecord from "./virtual-employment-record-model";
import { VirtualEmploymentRecordProperties, VirtualEmploymentRecordMetaProperties } from "./virtualEmploymentRecord-property-definitions";

export async function load_virtualEmploymentRecords(args: { uid: string }): Promise<VirtualEmploymentRecord[]> {
  throw "Not implemented";
}

export async function delete_virtualEmploymentRecord(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_virtualEmploymentRecord(args: VirtualEmploymentRecord): Promise<VirtualEmploymentRecord> {
  throw "Not implemented";
}
