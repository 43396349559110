import { createAsyncThunk } from "@reduxjs/toolkit";
import CourseSectionRecord from "./course-section-record-model";
import { delete_courseSectionRecord, load_courseSectionRecords, store_courseSectionRecord } from "./courseSectionRecord-service";

export const LoadCourseSectionRecordsAsync = createAsyncThunk<any, any>("composer/load/courseSectionRecord", async (params: { uid: string }) => {
  return await load_courseSectionRecords(params);
});

export const CreateCourseSectionRecordAsync = createAsyncThunk<any, any>("composer/create/courseSectionRecord", async (params: CourseSectionRecord) => {
  return await store_courseSectionRecord(params);
});

export const DeleteCourseSectionRecordAsync = createAsyncThunk<any, any>("composer/delete/courseSectionRecord", async (params) => {
  return await delete_courseSectionRecord(params);
});
