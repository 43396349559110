import { createAsyncThunk } from "@reduxjs/toolkit";
import CityLand from "./city-land-model";
import { delete_cityLand, load_cityLands, store_cityLand } from "./cityLand-service";

export const LoadCityLandsAsync = createAsyncThunk<any, any>("composer/load/cityLand", async (params: { uid: string }) => {
  return await load_cityLands(params);
});

export const CreateCityLandAsync = createAsyncThunk<any, any>("composer/create/cityLand", async (params: CityLand) => {
  return await store_cityLand(params);
});

export const DeleteCityLandAsync = createAsyncThunk<any, any>("composer/delete/cityLand", async (params) => {
  return await delete_cityLand(params);
});
