import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import Enrollment from "./enrollment-model";
import { EnrollmentProperties, EnrollmentMetaProperties } from "./enrollment-property-definitions";

export async function load_enrollments(args: { uid: string }): Promise<Enrollment[]> {
  throw "Not implemented";
}

export async function delete_enrollment(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_enrollment(args: Enrollment): Promise<Enrollment> {
  throw "Not implemented";
}
