import ClaimsCache from "./claims-cache-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateClaimsCacheAsync, DeleteClaimsCacheAsync, LoadClaimsCachesAsync } from "./claimsCache-thunk";
const initialState: claimsCacheSliceState = {
  claimsCache: [],
  selected_claimsCache: "",
  is_loading_claimsCache: false,
  is_creating_claimsCache: false,
  is_deleting_claimsCache: false
};
export interface claimsCacheSliceState {
  claimsCache: ClaimsCache[];
  selected_claimsCache?: string;
  is_loading_claimsCache: boolean;
  is_creating_claimsCache: boolean;
  is_deleting_claimsCache: boolean;
}
export const claimsCacheSlice = createSlice({
  name: "claimsCache",
  initialState,
  reducers: {
    setSelectedclaimsCache: (state, action) => {
      state.selected_claimsCache = action.payload;
    },
    clearStateclaimsCache: (state, action) => {
      state.claimsCache = [];
      state.is_loading_claimsCache = false;
      state.is_creating_claimsCache = false;
      state.is_deleting_claimsCache = false;
    },
    mergeclaimsCache: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_claimsCache = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.claimsCache = merge(state.claimsCache, payload) as any;
        }
      }
    },

    add_claimsCache: (state, action) => {
      if (action.payload) {
        state.claimsCache = [...state.claimsCache, action.payload];
      }
    },
    set_claimsCache: (state, action) => {
      let { key, value, id } = action.payload;
      state.claimsCache = state.claimsCache.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadClaimsCachesAsync.pending, (state) => {
      state.is_loading_claimsCache = true;
    });
    builder.addCase(LoadClaimsCachesAsync.fulfilled, (state, action) => {
      state.is_loading_claimsCache = false;
      if (action.payload) {
        state.claimsCache = action.payload;
      }
    });
    builder.addCase(LoadClaimsCachesAsync.rejected, (state, action) => {
      state.is_loading_claimsCache = false;
    });
    builder.addCase(CreateClaimsCacheAsync.pending, (state) => {
      state.is_creating_claimsCache = true;
    });
    builder.addCase(CreateClaimsCacheAsync.fulfilled, (state) => {
      state.is_creating_claimsCache = false;
    });
    builder.addCase(CreateClaimsCacheAsync.rejected, (state) => {
      state.is_creating_claimsCache = false;
    });
    builder.addCase(DeleteClaimsCacheAsync.pending, (state) => {
      state.is_deleting_claimsCache = true;
    });
    builder.addCase(DeleteClaimsCacheAsync.fulfilled, (state, action) => {
      state.is_deleting_claimsCache = false;
      if (state.claimsCache) {
        state.claimsCache = state.claimsCache.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteClaimsCacheAsync.rejected, (state, action) => {
      state.is_deleting_claimsCache = false;
    });

    builder.addCase(autoThunks.update_claims.pending, (state) => {
      state.is_loading_claimsCache = true;
    });
    builder.addCase(autoThunks.update_claims.fulfilled, (state, action) => {
      state.is_loading_claimsCache = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.update_claims.rejected, (state, action) => {
      state.is_loading_claimsCache = false;
    });
  }
});

function handleResult(action: any, state: claimsCacheSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_claimsCache = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.claimsCache = merge(state.claimsCache, payload) as any;
    }
  }
}

export const { clearStateclaimsCache, setSelectedclaimsCache, set_claimsCache, mergeclaimsCache, add_claimsCache } = claimsCacheSlice.actions;

export const get_claimsCache =
  (id?: string) =>
  (state: {
    claimsCache: {
      claimsCache: ClaimsCache[];
    };
  }): ClaimsCache | null => {
    let temp = state?.claimsCache?.claimsCache?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.claimsCache?.claimsCache[0] || null;
  };
export const get_selected_claimsCache = (state: { claimsCache: claimsCacheSliceState }): ClaimsCache | null => {
  let id = state?.claimsCache?.selected_claimsCache;
  let temp = state?.claimsCache?.claimsCache?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_claimsCache = (state: {
  claimsCache: {
    claimsCache: ClaimsCache[];
  };
}): ClaimsCache[] => {
  let temp = state?.claimsCache?.claimsCache;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_claimsCache_busy = (state: { claimsCache: claimsCacheSliceState }) => {
  return state?.claimsCache.is_deleting_claimsCache || state?.claimsCache.is_loading_claimsCache || state?.claimsCache.is_creating_claimsCache;
};
export const is_deleting_claimsCache_busy = (state: { claimsCache: claimsCacheSliceState }) => {
  return state?.claimsCache.is_deleting_claimsCache;
};
export const is_loading_claimsCache_busy = (state: { claimsCache: claimsCacheSliceState }) => {
  return state?.claimsCache.is_loading_claimsCache;
};
export const is_creating_claimsCache_busy = (state: { claimsCache: claimsCacheSliceState }) => {
  return state?.claimsCache.is_creating_claimsCache;
};

export default claimsCacheSlice.reducer;
