import { createAsyncThunk } from "@reduxjs/toolkit";
import MarketingMaterial from "./marketing-material-model";
import { delete_marketingMaterial, load_marketingMaterials, store_marketingMaterial } from "./marketingMaterial-service";

export const LoadMarketingMaterialsAsync = createAsyncThunk<any, any>("composer/load/marketingMaterial", async (params: { uid: string }) => {
  return await load_marketingMaterials(params);
});

export const CreateMarketingMaterialAsync = createAsyncThunk<any, any>("composer/create/marketingMaterial", async (params: MarketingMaterial) => {
  return await store_marketingMaterial(params);
});

export const DeleteMarketingMaterialAsync = createAsyncThunk<any, any>("composer/delete/marketingMaterial", async (params) => {
  return await delete_marketingMaterial(params);
});
