import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import CourseSectionRecord from "./course-section-record-model";
import { CourseSectionRecordProperties, CourseSectionRecordMetaProperties } from "./courseSectionRecord-property-definitions";

export async function load_courseSectionRecords(args: { uid: string }): Promise<CourseSectionRecord[]> {
  throw "Not implemented";
}

export async function delete_courseSectionRecord(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_courseSectionRecord(args: CourseSectionRecord): Promise<CourseSectionRecord> {
  throw "Not implemented";
}
