import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import QuestionAndAnswers from "./question-and-answers-model";
import { QuestionAndAnswersProperties, QuestionAndAnswersMetaProperties } from "./questionAndAnswers-property-definitions";

export async function load_questionAndAnswerss(args: { uid: string }): Promise<QuestionAndAnswers[]> {
  throw "Not implemented";
}

export async function delete_questionAndAnswers(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_questionAndAnswers(args: QuestionAndAnswers): Promise<QuestionAndAnswers> {
  throw "Not implemented";
}
