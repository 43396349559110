import { createAsyncThunk } from "@reduxjs/toolkit";
import Sponsor from "./sponsor-model";
import { delete_sponsor, load_sponsors, store_sponsor } from "./sponsor-service";

export const LoadSponsorsAsync = createAsyncThunk<any, any>("composer/load/sponsor", async (params: { uid: string }) => {
  return await load_sponsors(params);
});

export const CreateSponsorAsync = createAsyncThunk<any, any>("composer/create/sponsor", async (params: Sponsor) => {
  return await store_sponsor(params);
});

export const DeleteSponsorAsync = createAsyncThunk<any, any>("composer/delete/sponsor", async (params) => {
  return await delete_sponsor(params);
});
