import { createAsyncThunk } from "@reduxjs/toolkit";
import CourseLoad from "./course-load-model";
import { delete_courseLoad, load_courseLoads, store_courseLoad } from "./courseLoad-service";

export const LoadCourseLoadsAsync = createAsyncThunk<any, any>("composer/load/courseLoad", async (params: { uid: string }) => {
  return await load_courseLoads(params);
});

export const CreateCourseLoadAsync = createAsyncThunk<any, any>("composer/create/courseLoad", async (params: CourseLoad) => {
  return await store_courseLoad(params);
});

export const DeleteCourseLoadAsync = createAsyncThunk<any, any>("composer/delete/courseLoad", async (params) => {
  return await delete_courseLoad(params);
});
