import { createAsyncThunk } from "@reduxjs/toolkit";
import Course from "./course-model";
import { delete_course, load_courses, store_course } from "./course-service";

export const LoadCoursesAsync = createAsyncThunk<any, any>("composer/load/course", async (params: { uid: string }) => {
  return await load_courses(params);
});

export const CreateCourseAsync = createAsyncThunk<any, any>("composer/create/course", async (params: Course) => {
  return await store_course(params);
});

export const DeleteCourseAsync = createAsyncThunk<any, any>("composer/delete/course", async (params) => {
  return await delete_course(params);
});
