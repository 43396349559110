import { createAsyncThunk } from "@reduxjs/toolkit";
import BusinessAccount from "./business-account-model";
import { delete_businessAccount, load_businessAccounts, store_businessAccount } from "./businessAccount-service";

export const LoadBusinessAccountsAsync = createAsyncThunk<any, any>("composer/load/businessAccount", async (params: { uid: string }) => {
  return await load_businessAccounts(params);
});

export const CreateBusinessAccountAsync = createAsyncThunk<any, any>("composer/create/businessAccount", async (params: BusinessAccount) => {
  return await store_businessAccount(params);
});

export const DeleteBusinessAccountAsync = createAsyncThunk<any, any>("composer/delete/businessAccount", async (params) => {
  return await delete_businessAccount(params);
});
