import VirtualEmployee from "./virtual-employee-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateVirtualEmployeeAsync, DeleteVirtualEmployeeAsync, LoadVirtualEmployeesAsync } from "./virtualEmployee-thunk";
const initialState: virtualEmployeeSliceState = {
  virtualEmployee: [],
  selected_virtualEmployee: "",
  is_loading_virtualEmployee: false,
  is_creating_virtualEmployee: false,
  is_deleting_virtualEmployee: false
};
export interface virtualEmployeeSliceState {
  virtualEmployee: VirtualEmployee[];
  selected_virtualEmployee?: string;
  is_loading_virtualEmployee: boolean;
  is_creating_virtualEmployee: boolean;
  is_deleting_virtualEmployee: boolean;
}
export const virtualEmployeeSlice = createSlice({
  name: "virtualEmployee",
  initialState,
  reducers: {
    setSelectedvirtualEmployee: (state, action) => {
      state.selected_virtualEmployee = action.payload;
    },
    clearStatevirtualEmployee: (state, action) => {
      state.virtualEmployee = [];
      state.is_loading_virtualEmployee = false;
      state.is_creating_virtualEmployee = false;
      state.is_deleting_virtualEmployee = false;
    },
    mergevirtualEmployee: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_virtualEmployee = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.virtualEmployee = merge(state.virtualEmployee, payload) as any;
        }
      }
    },

    add_virtualEmployee: (state, action) => {
      if (action.payload) {
        state.virtualEmployee = [...state.virtualEmployee, action.payload];
      }
    },
    set_virtualEmployee: (state, action) => {
      let { key, value, id } = action.payload;
      state.virtualEmployee = state.virtualEmployee.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadVirtualEmployeesAsync.pending, (state) => {
      state.is_loading_virtualEmployee = true;
    });
    builder.addCase(LoadVirtualEmployeesAsync.fulfilled, (state, action) => {
      state.is_loading_virtualEmployee = false;
      if (action.payload) {
        state.virtualEmployee = action.payload;
      }
    });
    builder.addCase(LoadVirtualEmployeesAsync.rejected, (state, action) => {
      state.is_loading_virtualEmployee = false;
    });
    builder.addCase(CreateVirtualEmployeeAsync.pending, (state) => {
      state.is_creating_virtualEmployee = true;
    });
    builder.addCase(CreateVirtualEmployeeAsync.fulfilled, (state) => {
      state.is_creating_virtualEmployee = false;
    });
    builder.addCase(CreateVirtualEmployeeAsync.rejected, (state) => {
      state.is_creating_virtualEmployee = false;
    });
    builder.addCase(DeleteVirtualEmployeeAsync.pending, (state) => {
      state.is_deleting_virtualEmployee = true;
    });
    builder.addCase(DeleteVirtualEmployeeAsync.fulfilled, (state, action) => {
      state.is_deleting_virtualEmployee = false;
      if (state.virtualEmployee) {
        state.virtualEmployee = state.virtualEmployee.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteVirtualEmployeeAsync.rejected, (state, action) => {
      state.is_deleting_virtualEmployee = false;
    });

    builder.addCase(autoThunks.get_virtual_employees.pending, (state) => {
      state.is_loading_virtualEmployee = true;
    });
    builder.addCase(autoThunks.get_virtual_employees.fulfilled, (state, action) => {
      state.is_loading_virtualEmployee = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_virtual_employees.rejected, (state, action) => {
      state.is_loading_virtualEmployee = false;
    });
  }
});

function handleResult(action: any, state: virtualEmployeeSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_virtualEmployee = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.virtualEmployee = merge(state.virtualEmployee, payload) as any;
    }
  }
}

export const { clearStatevirtualEmployee, setSelectedvirtualEmployee, set_virtualEmployee, mergevirtualEmployee, add_virtualEmployee } =
  virtualEmployeeSlice.actions;

export const get_virtualEmployee =
  (id?: string) =>
  (state: {
    virtualEmployee: {
      virtualEmployee: VirtualEmployee[];
    };
  }): VirtualEmployee | null => {
    let temp = state?.virtualEmployee?.virtualEmployee?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.virtualEmployee?.virtualEmployee[0] || null;
  };
export const get_selected_virtualEmployee = (state: { virtualEmployee: virtualEmployeeSliceState }): VirtualEmployee | null => {
  let id = state?.virtualEmployee?.selected_virtualEmployee;
  let temp = state?.virtualEmployee?.virtualEmployee?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_virtualEmployee = (state: {
  virtualEmployee: {
    virtualEmployee: VirtualEmployee[];
  };
}): VirtualEmployee[] => {
  let temp = state?.virtualEmployee?.virtualEmployee;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_virtualEmployee_busy = (state: { virtualEmployee: virtualEmployeeSliceState }) => {
  return (
    state?.virtualEmployee.is_deleting_virtualEmployee ||
    state?.virtualEmployee.is_loading_virtualEmployee ||
    state?.virtualEmployee.is_creating_virtualEmployee
  );
};
export const is_deleting_virtualEmployee_busy = (state: { virtualEmployee: virtualEmployeeSliceState }) => {
  return state?.virtualEmployee.is_deleting_virtualEmployee;
};
export const is_loading_virtualEmployee_busy = (state: { virtualEmployee: virtualEmployeeSliceState }) => {
  return state?.virtualEmployee.is_loading_virtualEmployee;
};
export const is_creating_virtualEmployee_busy = (state: { virtualEmployee: virtualEmployeeSliceState }) => {
  return state?.virtualEmployee.is_creating_virtualEmployee;
};

export default virtualEmployeeSlice.reducer;
