import { createAsyncThunk } from "@reduxjs/toolkit";
import CourseTaker from "./course-taker-model";
import { delete_courseTaker, load_courseTakers, store_courseTaker } from "./courseTaker-service";

export const LoadCourseTakersAsync = createAsyncThunk<any, any>("composer/load/courseTaker", async (params: { uid: string }) => {
  return await load_courseTakers(params);
});

export const CreateCourseTakerAsync = createAsyncThunk<any, any>("composer/create/courseTaker", async (params: CourseTaker) => {
  return await store_courseTaker(params);
});

export const DeleteCourseTakerAsync = createAsyncThunk<any, any>("composer/delete/courseTaker", async (params) => {
  return await delete_courseTaker(params);
});
