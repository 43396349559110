import { createAsyncThunk } from "@reduxjs/toolkit";
import StockPrice from "./stock-price-model";
import { delete_stockPrice, load_stockPrices, store_stockPrice } from "./stockPrice-service";

export const LoadStockPricesAsync = createAsyncThunk<any, any>("composer/load/stockPrice", async (params: { uid: string }) => {
  return await load_stockPrices(params);
});

export const CreateStockPriceAsync = createAsyncThunk<any, any>("composer/create/stockPrice", async (params: StockPrice) => {
  return await store_stockPrice(params);
});

export const DeleteStockPriceAsync = createAsyncThunk<any, any>("composer/delete/stockPrice", async (params) => {
  return await delete_stockPrice(params);
});
