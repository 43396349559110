import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import Professor from "./professor-model";
import { ProfessorProperties, ProfessorMetaProperties } from "./professor-property-definitions";

export async function load_professors(args: { uid: string }): Promise<Professor[]> {
  throw "Not implemented";
}

export async function delete_professor(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_professor(args: Professor): Promise<Professor> {
  throw "Not implemented";
}
