import { createAsyncThunk } from "@reduxjs/toolkit";
import BusinessPlanSubmission from "./business-plan-submission-model";
import { delete_businessPlanSubmission, load_businessPlanSubmissions, store_businessPlanSubmission } from "./businessPlanSubmission-service";

export const LoadBusinessPlanSubmissionsAsync = createAsyncThunk<any, any>("composer/load/businessPlanSubmission", async (params: { uid: string }) => {
  return await load_businessPlanSubmissions(params);
});

export const CreateBusinessPlanSubmissionAsync = createAsyncThunk<any, any>(
  "composer/create/businessPlanSubmission",
  async (params: BusinessPlanSubmission) => {
    return await store_businessPlanSubmission(params);
  }
);

export const DeleteBusinessPlanSubmissionAsync = createAsyncThunk<any, any>("composer/delete/businessPlanSubmission", async (params) => {
  return await delete_businessPlanSubmission(params);
});
