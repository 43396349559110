import { createAsyncThunk } from "@reduxjs/toolkit";
import CheckoutSession from "./checkout-session-model";
import { delete_checkoutSession, load_checkoutSessions, store_checkoutSession } from "./checkoutSession-service";

export const LoadCheckoutSessionsAsync = createAsyncThunk<any, any>("composer/load/checkoutSession", async (params: { uid: string }) => {
  return await load_checkoutSessions(params);
});

export const CreateCheckoutSessionAsync = createAsyncThunk<any, any>("composer/create/checkoutSession", async (params: CheckoutSession) => {
  return await store_checkoutSession(params);
});

export const DeleteCheckoutSessionAsync = createAsyncThunk<any, any>("composer/delete/checkoutSession", async (params) => {
  return await delete_checkoutSession(params);
});
