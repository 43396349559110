import { createAsyncThunk } from "@reduxjs/toolkit";
import InvestmentAccount from "./investment-account-model";
import { delete_investmentAccount, load_investmentAccounts, store_investmentAccount } from "./investmentAccount-service";

export const LoadInvestmentAccountsAsync = createAsyncThunk<any, any>("composer/load/investmentAccount", async (params: { uid: string }) => {
  return await load_investmentAccounts(params);
});

export const CreateInvestmentAccountAsync = createAsyncThunk<any, any>("composer/create/investmentAccount", async (params: InvestmentAccount) => {
  return await store_investmentAccount(params);
});

export const DeleteInvestmentAccountAsync = createAsyncThunk<any, any>("composer/delete/investmentAccount", async (params) => {
  return await delete_investmentAccount(params);
});
