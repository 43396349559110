import { createAsyncThunk } from "@reduxjs/toolkit";
import BankRegistry from "./bank-registry-model";
import { delete_bankRegistry, load_bankRegistrys, store_bankRegistry } from "./bankRegistry-service";

export const LoadBankRegistrysAsync = createAsyncThunk<any, any>("composer/load/bankRegistry", async (params: { uid: string }) => {
  return await load_bankRegistrys(params);
});

export const CreateBankRegistryAsync = createAsyncThunk<any, any>("composer/create/bankRegistry", async (params: BankRegistry) => {
  return await store_bankRegistry(params);
});

export const DeleteBankRegistryAsync = createAsyncThunk<any, any>("composer/delete/bankRegistry", async (params) => {
  return await delete_bankRegistry(params);
});
