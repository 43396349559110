import AuctionItem from "./auction-item-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateAuctionItemAsync, DeleteAuctionItemAsync, LoadAuctionItemsAsync } from "./auctionItem-thunk";
const initialState: auctionItemSliceState = {
  auctionItem: [],
  selected_auctionItem: "",
  is_loading_auctionItem: false,
  is_creating_auctionItem: false,
  is_deleting_auctionItem: false
};
export interface auctionItemSliceState {
  auctionItem: AuctionItem[];
  selected_auctionItem?: string;
  is_loading_auctionItem: boolean;
  is_creating_auctionItem: boolean;
  is_deleting_auctionItem: boolean;
}
export const auctionItemSlice = createSlice({
  name: "auctionItem",
  initialState,
  reducers: {
    setSelectedauctionItem: (state, action) => {
      state.selected_auctionItem = action.payload;
    },
    clearStateauctionItem: (state, action) => {
      state.auctionItem = [];
      state.is_loading_auctionItem = false;
      state.is_creating_auctionItem = false;
      state.is_deleting_auctionItem = false;
    },
    mergeauctionItem: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_auctionItem = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.auctionItem = merge(state.auctionItem, payload) as any;
        }
      }
    },

    add_auctionItem: (state, action) => {
      if (action.payload) {
        state.auctionItem = [...state.auctionItem, action.payload];
      }
    },
    set_auctionItem: (state, action) => {
      let { key, value, id } = action.payload;
      state.auctionItem = state.auctionItem.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadAuctionItemsAsync.pending, (state) => {
      state.is_loading_auctionItem = true;
    });
    builder.addCase(LoadAuctionItemsAsync.fulfilled, (state, action) => {
      state.is_loading_auctionItem = false;
      if (action.payload) {
        state.auctionItem = action.payload;
      }
    });
    builder.addCase(LoadAuctionItemsAsync.rejected, (state, action) => {
      state.is_loading_auctionItem = false;
    });
    builder.addCase(CreateAuctionItemAsync.pending, (state) => {
      state.is_creating_auctionItem = true;
    });
    builder.addCase(CreateAuctionItemAsync.fulfilled, (state) => {
      state.is_creating_auctionItem = false;
    });
    builder.addCase(CreateAuctionItemAsync.rejected, (state) => {
      state.is_creating_auctionItem = false;
    });
    builder.addCase(DeleteAuctionItemAsync.pending, (state) => {
      state.is_deleting_auctionItem = true;
    });
    builder.addCase(DeleteAuctionItemAsync.fulfilled, (state, action) => {
      state.is_deleting_auctionItem = false;
      if (state.auctionItem) {
        state.auctionItem = state.auctionItem.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteAuctionItemAsync.rejected, (state, action) => {
      state.is_deleting_auctionItem = false;
    });

    builder.addCase(autoThunks.get_auction_items_for_auction_customer.pending, (state) => {
      state.is_loading_auctionItem = true;
    });
    builder.addCase(autoThunks.get_auction_items_for_auction_customer.fulfilled, (state, action) => {
      state.is_loading_auctionItem = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_auction_items_for_auction_customer.rejected, (state, action) => {
      state.is_loading_auctionItem = false;
    });

    builder.addCase(autoThunks.add_auction_items_to_auction_as_agent.pending, (state) => {
      state.is_loading_auctionItem = true;
    });
    builder.addCase(autoThunks.add_auction_items_to_auction_as_agent.fulfilled, (state, action) => {
      state.is_loading_auctionItem = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.add_auction_items_to_auction_as_agent.rejected, (state, action) => {
      state.is_loading_auctionItem = false;
    });

    builder.addCase(autoThunks.get_auction_items_for_auction_as_agent.pending, (state) => {
      state.is_loading_auctionItem = true;
    });
    builder.addCase(autoThunks.get_auction_items_for_auction_as_agent.fulfilled, (state, action) => {
      state.is_loading_auctionItem = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_auction_items_for_auction_as_agent.rejected, (state, action) => {
      state.is_loading_auctionItem = false;
    });

    builder.addCase(autoThunks.edit_auction_items_for_auction_as_agent.pending, (state) => {
      state.is_loading_auctionItem = true;
    });
    builder.addCase(autoThunks.edit_auction_items_for_auction_as_agent.fulfilled, (state, action) => {
      state.is_loading_auctionItem = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.edit_auction_items_for_auction_as_agent.rejected, (state, action) => {
      state.is_loading_auctionItem = false;
    });

    builder.addCase(autoThunks.delete_auction_item_as_agent.pending, (state) => {
      state.is_loading_auctionItem = true;
    });
    builder.addCase(autoThunks.delete_auction_item_as_agent.fulfilled, (state, action) => {
      state.is_loading_auctionItem = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.delete_auction_item_as_agent.rejected, (state, action) => {
      state.is_loading_auctionItem = false;
    });

    builder.addCase(autoThunks.add_auction_items_to_auction.pending, (state) => {
      state.is_loading_auctionItem = true;
    });
    builder.addCase(autoThunks.add_auction_items_to_auction.fulfilled, (state, action) => {
      state.is_loading_auctionItem = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.add_auction_items_to_auction.rejected, (state, action) => {
      state.is_loading_auctionItem = false;
    });

    builder.addCase(autoThunks.get_auction_items_for_auction.pending, (state) => {
      state.is_loading_auctionItem = true;
    });
    builder.addCase(autoThunks.get_auction_items_for_auction.fulfilled, (state, action) => {
      state.is_loading_auctionItem = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_auction_items_for_auction.rejected, (state, action) => {
      state.is_loading_auctionItem = false;
    });

    builder.addCase(autoThunks.edit_auction_items_for_auction.pending, (state) => {
      state.is_loading_auctionItem = true;
    });
    builder.addCase(autoThunks.edit_auction_items_for_auction.fulfilled, (state, action) => {
      state.is_loading_auctionItem = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.edit_auction_items_for_auction.rejected, (state, action) => {
      state.is_loading_auctionItem = false;
    });

    builder.addCase(autoThunks.delete_auction_item.pending, (state) => {
      state.is_loading_auctionItem = true;
    });
    builder.addCase(autoThunks.delete_auction_item.fulfilled, (state, action) => {
      state.is_loading_auctionItem = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.delete_auction_item.rejected, (state, action) => {
      state.is_loading_auctionItem = false;
    });
  }
});

function handleResult(action: any, state: auctionItemSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_auctionItem = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.auctionItem = merge(state.auctionItem, payload) as any;
    }
  }
}

export const { clearStateauctionItem, setSelectedauctionItem, set_auctionItem, mergeauctionItem, add_auctionItem } = auctionItemSlice.actions;

export const get_auctionItem =
  (id?: string) =>
  (state: {
    auctionItem: {
      auctionItem: AuctionItem[];
    };
  }): AuctionItem | null => {
    let temp = state?.auctionItem?.auctionItem?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.auctionItem?.auctionItem[0] || null;
  };
export const get_selected_auctionItem = (state: { auctionItem: auctionItemSliceState }): AuctionItem | null => {
  let id = state?.auctionItem?.selected_auctionItem;
  let temp = state?.auctionItem?.auctionItem?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_auctionItem = (state: {
  auctionItem: {
    auctionItem: AuctionItem[];
  };
}): AuctionItem[] => {
  let temp = state?.auctionItem?.auctionItem;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_auctionItem_busy = (state: { auctionItem: auctionItemSliceState }) => {
  return state?.auctionItem.is_deleting_auctionItem || state?.auctionItem.is_loading_auctionItem || state?.auctionItem.is_creating_auctionItem;
};
export const is_deleting_auctionItem_busy = (state: { auctionItem: auctionItemSliceState }) => {
  return state?.auctionItem.is_deleting_auctionItem;
};
export const is_loading_auctionItem_busy = (state: { auctionItem: auctionItemSliceState }) => {
  return state?.auctionItem.is_loading_auctionItem;
};
export const is_creating_auctionItem_busy = (state: { auctionItem: auctionItemSliceState }) => {
  return state?.auctionItem.is_creating_auctionItem;
};

export default auctionItemSlice.reducer;
