import { createAsyncThunk } from "@reduxjs/toolkit";
import VirtualBusinessPetitionPartnershipDissolutionMessage from "./virtual-business-petition-partnership-dissolution-message-model";
import {
  delete_virtualBusinessPetitionPartnershipDissolutionMessage,
  load_virtualBusinessPetitionPartnershipDissolutionMessages,
  store_virtualBusinessPetitionPartnershipDissolutionMessage
} from "./virtualBusinessPetitionPartnershipDissolutionMessage-service";

export const LoadVirtualBusinessPetitionPartnershipDissolutionMessagesAsync = createAsyncThunk<any, any>(
  "composer/load/virtualBusinessPetitionPartnershipDissolutionMessage",
  async (params: { uid: string }) => {
    return await load_virtualBusinessPetitionPartnershipDissolutionMessages(params);
  }
);

export const CreateVirtualBusinessPetitionPartnershipDissolutionMessageAsync = createAsyncThunk<any, any>(
  "composer/create/virtualBusinessPetitionPartnershipDissolutionMessage",
  async (params: VirtualBusinessPetitionPartnershipDissolutionMessage) => {
    return await store_virtualBusinessPetitionPartnershipDissolutionMessage(params);
  }
);

export const DeleteVirtualBusinessPetitionPartnershipDissolutionMessageAsync = createAsyncThunk<any, any>(
  "composer/delete/virtualBusinessPetitionPartnershipDissolutionMessage",
  async (params) => {
    return await delete_virtualBusinessPetitionPartnershipDissolutionMessage(params);
  }
);
