import { createAsyncThunk } from "@reduxjs/toolkit";
import EductionAssetOutputConfiguration from "./eduction-asset-output-configuration-model";
import {
  delete_eductionAssetOutputConfiguration,
  load_eductionAssetOutputConfigurations,
  store_eductionAssetOutputConfiguration
} from "./eductionAssetOutputConfiguration-service";

export const LoadEductionAssetOutputConfigurationsAsync = createAsyncThunk<any, any>(
  "composer/load/eductionAssetOutputConfiguration",
  async (params: { uid: string }) => {
    return await load_eductionAssetOutputConfigurations(params);
  }
);

export const CreateEductionAssetOutputConfigurationAsync = createAsyncThunk<any, any>(
  "composer/create/eductionAssetOutputConfiguration",
  async (params: EductionAssetOutputConfiguration) => {
    return await store_eductionAssetOutputConfiguration(params);
  }
);

export const DeleteEductionAssetOutputConfigurationAsync = createAsyncThunk<any, any>("composer/delete/eductionAssetOutputConfiguration", async (params) => {
  return await delete_eductionAssetOutputConfiguration(params);
});
