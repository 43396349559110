import { createAsyncThunk } from "@reduxjs/toolkit";
import MoneySuployHistory from "./money-suploy-history-model";
import { delete_moneySuployHistory, load_moneySuployHistorys, store_moneySuployHistory } from "./moneySuployHistory-service";

export const LoadMoneySuployHistorysAsync = createAsyncThunk<any, any>("composer/load/moneySuployHistory", async (params: { uid: string }) => {
  return await load_moneySuployHistorys(params);
});

export const CreateMoneySuployHistoryAsync = createAsyncThunk<any, any>("composer/create/moneySuployHistory", async (params: MoneySuployHistory) => {
  return await store_moneySuployHistory(params);
});

export const DeleteMoneySuployHistoryAsync = createAsyncThunk<any, any>("composer/delete/moneySuployHistory", async (params) => {
  return await delete_moneySuployHistory(params);
});
