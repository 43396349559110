import { createAsyncThunk } from "@reduxjs/toolkit";
import CourseQuestionLog from "./course-question-log-model";
import { delete_courseQuestionLog, load_courseQuestionLogs, store_courseQuestionLog } from "./courseQuestionLog-service";

export const LoadCourseQuestionLogsAsync = createAsyncThunk<any, any>("composer/load/courseQuestionLog", async (params: { uid: string }) => {
  return await load_courseQuestionLogs(params);
});

export const CreateCourseQuestionLogAsync = createAsyncThunk<any, any>("composer/create/courseQuestionLog", async (params: CourseQuestionLog) => {
  return await store_courseQuestionLog(params);
});

export const DeleteCourseQuestionLogAsync = createAsyncThunk<any, any>("composer/delete/courseQuestionLog", async (params) => {
  return await delete_courseQuestionLog(params);
});
