import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import SalesOrder from "./sales-order-model";
import { SalesOrderProperties, SalesOrderMetaProperties } from "./salesOrder-property-definitions";

export async function load_salesOrders(args: { uid: string }): Promise<SalesOrder[]> {
  throw "Not implemented";
}

export async function delete_salesOrder(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_salesOrder(args: SalesOrder): Promise<SalesOrder> {
  throw "Not implemented";
}
