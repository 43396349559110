import { createAsyncThunk } from "@reduxjs/toolkit";
import PublishCourseJob from "./publish-course-job-model";
import { delete_publishCourseJob, load_publishCourseJobs, store_publishCourseJob } from "./publishCourseJob-service";

export const LoadPublishCourseJobsAsync = createAsyncThunk<any, any>("composer/load/publishCourseJob", async (params: { uid: string }) => {
  return await load_publishCourseJobs(params);
});

export const CreatePublishCourseJobAsync = createAsyncThunk<any, any>("composer/create/publishCourseJob", async (params: PublishCourseJob) => {
  return await store_publishCourseJob(params);
});

export const DeletePublishCourseJobAsync = createAsyncThunk<any, any>("composer/delete/publishCourseJob", async (params) => {
  return await delete_publishCourseJob(params);
});
