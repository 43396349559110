import QuickConnect from "./quick-connect-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateQuickConnectAsync, DeleteQuickConnectAsync, LoadQuickConnectsAsync } from "./quickConnect-thunk";
const initialState: quickConnectSliceState = {
  quickConnect: [],
  selected_quickConnect: "",
  is_loading_quickConnect: false,
  is_creating_quickConnect: false,
  is_deleting_quickConnect: false
};
export interface quickConnectSliceState {
  quickConnect: QuickConnect[];
  selected_quickConnect?: string;
  is_loading_quickConnect: boolean;
  is_creating_quickConnect: boolean;
  is_deleting_quickConnect: boolean;
}
export const quickConnectSlice = createSlice({
  name: "quickConnect",
  initialState,
  reducers: {
    setSelectedquickConnect: (state, action) => {
      state.selected_quickConnect = action.payload;
    },
    clearStatequickConnect: (state, action) => {
      state.quickConnect = [];
      state.is_loading_quickConnect = false;
      state.is_creating_quickConnect = false;
      state.is_deleting_quickConnect = false;
    },
    mergequickConnect: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_quickConnect = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.quickConnect = merge(state.quickConnect, payload) as any;
        }
      }
    },

    add_quickConnect: (state, action) => {
      if (action.payload) {
        state.quickConnect = [...state.quickConnect, action.payload];
      }
    },
    set_quickConnect: (state, action) => {
      let { key, value, id } = action.payload;
      state.quickConnect = state.quickConnect.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadQuickConnectsAsync.pending, (state) => {
      state.is_loading_quickConnect = true;
    });
    builder.addCase(LoadQuickConnectsAsync.fulfilled, (state, action) => {
      state.is_loading_quickConnect = false;
      if (action.payload) {
        state.quickConnect = action.payload;
      }
    });
    builder.addCase(LoadQuickConnectsAsync.rejected, (state, action) => {
      state.is_loading_quickConnect = false;
    });
    builder.addCase(CreateQuickConnectAsync.pending, (state) => {
      state.is_creating_quickConnect = true;
    });
    builder.addCase(CreateQuickConnectAsync.fulfilled, (state) => {
      state.is_creating_quickConnect = false;
    });
    builder.addCase(CreateQuickConnectAsync.rejected, (state) => {
      state.is_creating_quickConnect = false;
    });
    builder.addCase(DeleteQuickConnectAsync.pending, (state) => {
      state.is_deleting_quickConnect = true;
    });
    builder.addCase(DeleteQuickConnectAsync.fulfilled, (state, action) => {
      state.is_deleting_quickConnect = false;
      if (state.quickConnect) {
        state.quickConnect = state.quickConnect.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteQuickConnectAsync.rejected, (state, action) => {
      state.is_deleting_quickConnect = false;
    });

    builder.addCase(autoThunks.get_connect_to_account.pending, (state) => {
      state.is_loading_quickConnect = true;
    });
    builder.addCase(autoThunks.get_connect_to_account.fulfilled, (state, action) => {
      state.is_loading_quickConnect = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_connect_to_account.rejected, (state, action) => {
      state.is_loading_quickConnect = false;
    });

    builder.addCase(autoThunks.get_my_quick_connects.pending, (state) => {
      state.is_loading_quickConnect = true;
    });
    builder.addCase(autoThunks.get_my_quick_connects.fulfilled, (state, action) => {
      state.is_loading_quickConnect = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_my_quick_connects.rejected, (state, action) => {
      state.is_loading_quickConnect = false;
    });

    builder.addCase(autoThunks.generate_quick_connects.pending, (state) => {
      state.is_loading_quickConnect = true;
    });
    builder.addCase(autoThunks.generate_quick_connects.fulfilled, (state, action) => {
      state.is_loading_quickConnect = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.generate_quick_connects.rejected, (state, action) => {
      state.is_loading_quickConnect = false;
    });
  }
});

function handleResult(action: any, state: quickConnectSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_quickConnect = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.quickConnect = merge(state.quickConnect, payload) as any;
    }
  }
}

export const { clearStatequickConnect, setSelectedquickConnect, set_quickConnect, mergequickConnect, add_quickConnect } = quickConnectSlice.actions;

export const get_quickConnect =
  (id?: string) =>
  (state: {
    quickConnect: {
      quickConnect: QuickConnect[];
    };
  }): QuickConnect | null => {
    let temp = state?.quickConnect?.quickConnect?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.quickConnect?.quickConnect[0] || null;
  };
export const get_selected_quickConnect = (state: { quickConnect: quickConnectSliceState }): QuickConnect | null => {
  let id = state?.quickConnect?.selected_quickConnect;
  let temp = state?.quickConnect?.quickConnect?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_quickConnect = (state: {
  quickConnect: {
    quickConnect: QuickConnect[];
  };
}): QuickConnect[] => {
  let temp = state?.quickConnect?.quickConnect;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_quickConnect_busy = (state: { quickConnect: quickConnectSliceState }) => {
  return state?.quickConnect.is_deleting_quickConnect || state?.quickConnect.is_loading_quickConnect || state?.quickConnect.is_creating_quickConnect;
};
export const is_deleting_quickConnect_busy = (state: { quickConnect: quickConnectSliceState }) => {
  return state?.quickConnect.is_deleting_quickConnect;
};
export const is_loading_quickConnect_busy = (state: { quickConnect: quickConnectSliceState }) => {
  return state?.quickConnect.is_loading_quickConnect;
};
export const is_creating_quickConnect_busy = (state: { quickConnect: quickConnectSliceState }) => {
  return state?.quickConnect.is_creating_quickConnect;
};

export default quickConnectSlice.reducer;
