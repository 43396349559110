import CourseContract from "./course-contract-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateCourseContractAsync, DeleteCourseContractAsync, LoadCourseContractsAsync } from "./courseContract-thunk";
const initialState: courseContractSliceState = {
  courseContract: [],
  selected_courseContract: "",
  is_loading_courseContract: false,
  is_creating_courseContract: false,
  is_deleting_courseContract: false
};
export interface courseContractSliceState {
  courseContract: CourseContract[];
  selected_courseContract?: string;
  is_loading_courseContract: boolean;
  is_creating_courseContract: boolean;
  is_deleting_courseContract: boolean;
}
export const courseContractSlice = createSlice({
  name: "courseContract",
  initialState,
  reducers: {
    setSelectedcourseContract: (state, action) => {
      state.selected_courseContract = action.payload;
    },
    clearStatecourseContract: (state, action) => {
      state.courseContract = [];
      state.is_loading_courseContract = false;
      state.is_creating_courseContract = false;
      state.is_deleting_courseContract = false;
    },
    mergecourseContract: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_courseContract = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.courseContract = merge(state.courseContract, payload) as any;
        }
      }
    },

    add_courseContract: (state, action) => {
      if (action.payload) {
        state.courseContract = [...state.courseContract, action.payload];
      }
    },
    set_courseContract: (state, action) => {
      let { key, value, id } = action.payload;
      state.courseContract = state.courseContract.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadCourseContractsAsync.pending, (state) => {
      state.is_loading_courseContract = true;
    });
    builder.addCase(LoadCourseContractsAsync.fulfilled, (state, action) => {
      state.is_loading_courseContract = false;
      if (action.payload) {
        state.courseContract = action.payload;
      }
    });
    builder.addCase(LoadCourseContractsAsync.rejected, (state, action) => {
      state.is_loading_courseContract = false;
    });
    builder.addCase(CreateCourseContractAsync.pending, (state) => {
      state.is_creating_courseContract = true;
    });
    builder.addCase(CreateCourseContractAsync.fulfilled, (state) => {
      state.is_creating_courseContract = false;
    });
    builder.addCase(CreateCourseContractAsync.rejected, (state) => {
      state.is_creating_courseContract = false;
    });
    builder.addCase(DeleteCourseContractAsync.pending, (state) => {
      state.is_deleting_courseContract = true;
    });
    builder.addCase(DeleteCourseContractAsync.fulfilled, (state, action) => {
      state.is_deleting_courseContract = false;
      if (state.courseContract) {
        state.courseContract = state.courseContract.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteCourseContractAsync.rejected, (state, action) => {
      state.is_deleting_courseContract = false;
    });
  }
});

function handleResult(action: any, state: courseContractSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_courseContract = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.courseContract = merge(state.courseContract, payload) as any;
    }
  }
}

export const { clearStatecourseContract, setSelectedcourseContract, set_courseContract, mergecourseContract, add_courseContract } = courseContractSlice.actions;

export const get_courseContract =
  (id?: string) =>
  (state: {
    courseContract: {
      courseContract: CourseContract[];
    };
  }): CourseContract | null => {
    let temp = state?.courseContract?.courseContract?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.courseContract?.courseContract[0] || null;
  };
export const get_selected_courseContract = (state: { courseContract: courseContractSliceState }): CourseContract | null => {
  let id = state?.courseContract?.selected_courseContract;
  let temp = state?.courseContract?.courseContract?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_courseContract = (state: {
  courseContract: {
    courseContract: CourseContract[];
  };
}): CourseContract[] => {
  let temp = state?.courseContract?.courseContract;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_courseContract_busy = (state: { courseContract: courseContractSliceState }) => {
  return (
    state?.courseContract.is_deleting_courseContract || state?.courseContract.is_loading_courseContract || state?.courseContract.is_creating_courseContract
  );
};
export const is_deleting_courseContract_busy = (state: { courseContract: courseContractSliceState }) => {
  return state?.courseContract.is_deleting_courseContract;
};
export const is_loading_courseContract_busy = (state: { courseContract: courseContractSliceState }) => {
  return state?.courseContract.is_loading_courseContract;
};
export const is_creating_courseContract_busy = (state: { courseContract: courseContractSliceState }) => {
  return state?.courseContract.is_creating_courseContract;
};

export default courseContractSlice.reducer;
