import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import CourseQuestionSorting from "./course-question-sorting-model";
import { CourseQuestionSortingProperties, CourseQuestionSortingMetaProperties } from "./courseQuestionSorting-property-definitions";

export async function load_courseQuestionSortings(args: { uid: string }): Promise<CourseQuestionSorting[]> {
  throw "Not implemented";
}

export async function delete_courseQuestionSorting(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_courseQuestionSorting(args: CourseQuestionSorting): Promise<CourseQuestionSorting> {
  throw "Not implemented";
}
