import User from "./user-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateUserAsync, DeleteUserAsync, LoadUsersAsync } from "./user-thunk";
const initialState: userSliceState = {
  user: [],
  selected_user: "",
  is_loading_user: false,
  is_creating_user: false,
  is_deleting_user: false
};
export interface userSliceState {
  user: User[];
  selected_user?: string;
  is_loading_user: boolean;
  is_creating_user: boolean;
  is_deleting_user: boolean;
}
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSelecteduser: (state, action) => {
      state.selected_user = action.payload;
    },
    clearStateuser: (state, action) => {
      state.user = [];
      state.is_loading_user = false;
      state.is_creating_user = false;
      state.is_deleting_user = false;
    },
    mergeuser: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_user = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.user = merge(state.user, payload) as any;
        }
      }
    },

    add_user: (state, action) => {
      if (action.payload) {
        state.user = [...state.user, action.payload];
      }
    },
    set_user: (state, action) => {
      let { key, value, id } = action.payload;
      state.user = state.user.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadUsersAsync.pending, (state) => {
      state.is_loading_user = true;
    });
    builder.addCase(LoadUsersAsync.fulfilled, (state, action) => {
      state.is_loading_user = false;
      if (action.payload) {
        state.user = action.payload;
      }
    });
    builder.addCase(LoadUsersAsync.rejected, (state, action) => {
      state.is_loading_user = false;
    });
    builder.addCase(CreateUserAsync.pending, (state) => {
      state.is_creating_user = true;
    });
    builder.addCase(CreateUserAsync.fulfilled, (state) => {
      state.is_creating_user = false;
    });
    builder.addCase(CreateUserAsync.rejected, (state) => {
      state.is_creating_user = false;
    });
    builder.addCase(DeleteUserAsync.pending, (state) => {
      state.is_deleting_user = true;
    });
    builder.addCase(DeleteUserAsync.fulfilled, (state, action) => {
      state.is_deleting_user = false;
      if (state.user) {
        state.user = state.user.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteUserAsync.rejected, (state, action) => {
      state.is_deleting_user = false;
    });

    builder.addCase(autoThunks.get_bank_owner_users.pending, (state) => {
      state.is_loading_user = true;
    });
    builder.addCase(autoThunks.get_bank_owner_users.fulfilled, (state, action) => {
      state.is_loading_user = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_bank_owner_users.rejected, (state, action) => {
      state.is_loading_user = false;
    });
  }
});

function handleResult(action: any, state: userSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_user = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.user = merge(state.user, payload) as any;
    }
  }
}

export const { clearStateuser, setSelecteduser, set_user, mergeuser, add_user } = userSlice.actions;

export const get_user =
  (id?: string) =>
  (state: {
    user: {
      user: User[];
    };
  }): User | null => {
    let temp = state?.user?.user?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.user?.user[0] || null;
  };
export const get_selected_user = (state: { user: userSliceState }): User | null => {
  let id = state?.user?.selected_user;
  let temp = state?.user?.user?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_user = (state: {
  user: {
    user: User[];
  };
}): User[] => {
  let temp = state?.user?.user;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_user_busy = (state: { user: userSliceState }) => {
  return state?.user.is_deleting_user || state?.user.is_loading_user || state?.user.is_creating_user;
};
export const is_deleting_user_busy = (state: { user: userSliceState }) => {
  return state?.user.is_deleting_user;
};
export const is_loading_user_busy = (state: { user: userSliceState }) => {
  return state?.user.is_loading_user;
};
export const is_creating_user_busy = (state: { user: userSliceState }) => {
  return state?.user.is_creating_user;
};

export default userSlice.reducer;
