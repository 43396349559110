import ServiceContract from "./service-contract-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateServiceContractAsync, DeleteServiceContractAsync, LoadServiceContractsAsync } from "./serviceContract-thunk";
const initialState: serviceContractSliceState = {
  serviceContract: [],
  selected_serviceContract: "",
  is_loading_serviceContract: false,
  is_creating_serviceContract: false,
  is_deleting_serviceContract: false
};
export interface serviceContractSliceState {
  serviceContract: ServiceContract[];
  selected_serviceContract?: string;
  is_loading_serviceContract: boolean;
  is_creating_serviceContract: boolean;
  is_deleting_serviceContract: boolean;
}
export const serviceContractSlice = createSlice({
  name: "serviceContract",
  initialState,
  reducers: {
    setSelectedserviceContract: (state, action) => {
      state.selected_serviceContract = action.payload;
    },
    clearStateserviceContract: (state, action) => {
      state.serviceContract = [];
      state.is_loading_serviceContract = false;
      state.is_creating_serviceContract = false;
      state.is_deleting_serviceContract = false;
    },
    mergeserviceContract: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_serviceContract = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.serviceContract = merge(state.serviceContract, payload) as any;
        }
      }
    },

    add_serviceContract: (state, action) => {
      if (action.payload) {
        state.serviceContract = [...state.serviceContract, action.payload];
      }
    },
    set_serviceContract: (state, action) => {
      let { key, value, id } = action.payload;
      state.serviceContract = state.serviceContract.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadServiceContractsAsync.pending, (state) => {
      state.is_loading_serviceContract = true;
    });
    builder.addCase(LoadServiceContractsAsync.fulfilled, (state, action) => {
      state.is_loading_serviceContract = false;
      if (action.payload) {
        state.serviceContract = action.payload;
      }
    });
    builder.addCase(LoadServiceContractsAsync.rejected, (state, action) => {
      state.is_loading_serviceContract = false;
    });
    builder.addCase(CreateServiceContractAsync.pending, (state) => {
      state.is_creating_serviceContract = true;
    });
    builder.addCase(CreateServiceContractAsync.fulfilled, (state) => {
      state.is_creating_serviceContract = false;
    });
    builder.addCase(CreateServiceContractAsync.rejected, (state) => {
      state.is_creating_serviceContract = false;
    });
    builder.addCase(DeleteServiceContractAsync.pending, (state) => {
      state.is_deleting_serviceContract = true;
    });
    builder.addCase(DeleteServiceContractAsync.fulfilled, (state, action) => {
      state.is_deleting_serviceContract = false;
      if (state.serviceContract) {
        state.serviceContract = state.serviceContract.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteServiceContractAsync.rejected, (state, action) => {
      state.is_deleting_serviceContract = false;
    });
  }
});

function handleResult(action: any, state: serviceContractSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_serviceContract = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.serviceContract = merge(state.serviceContract, payload) as any;
    }
  }
}

export const { clearStateserviceContract, setSelectedserviceContract, set_serviceContract, mergeserviceContract, add_serviceContract } =
  serviceContractSlice.actions;

export const get_serviceContract =
  (id?: string) =>
  (state: {
    serviceContract: {
      serviceContract: ServiceContract[];
    };
  }): ServiceContract | null => {
    let temp = state?.serviceContract?.serviceContract?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.serviceContract?.serviceContract[0] || null;
  };
export const get_selected_serviceContract = (state: { serviceContract: serviceContractSliceState }): ServiceContract | null => {
  let id = state?.serviceContract?.selected_serviceContract;
  let temp = state?.serviceContract?.serviceContract?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_serviceContract = (state: {
  serviceContract: {
    serviceContract: ServiceContract[];
  };
}): ServiceContract[] => {
  let temp = state?.serviceContract?.serviceContract;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_serviceContract_busy = (state: { serviceContract: serviceContractSliceState }) => {
  return (
    state?.serviceContract.is_deleting_serviceContract ||
    state?.serviceContract.is_loading_serviceContract ||
    state?.serviceContract.is_creating_serviceContract
  );
};
export const is_deleting_serviceContract_busy = (state: { serviceContract: serviceContractSliceState }) => {
  return state?.serviceContract.is_deleting_serviceContract;
};
export const is_loading_serviceContract_busy = (state: { serviceContract: serviceContractSliceState }) => {
  return state?.serviceContract.is_loading_serviceContract;
};
export const is_creating_serviceContract_busy = (state: { serviceContract: serviceContractSliceState }) => {
  return state?.serviceContract.is_creating_serviceContract;
};

export default serviceContractSlice.reducer;
