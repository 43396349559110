import QuestionAndAnswers from "./question-and-answers-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateQuestionAndAnswersAsync, DeleteQuestionAndAnswersAsync, LoadQuestionAndAnswerssAsync } from "./questionAndAnswers-thunk";
const initialState: questionAndAnswersSliceState = {
  questionAndAnswers: [],
  selected_questionAndAnswers: "",
  is_loading_questionAndAnswers: false,
  is_creating_questionAndAnswers: false,
  is_deleting_questionAndAnswers: false
};
export interface questionAndAnswersSliceState {
  questionAndAnswers: QuestionAndAnswers[];
  selected_questionAndAnswers?: string;
  is_loading_questionAndAnswers: boolean;
  is_creating_questionAndAnswers: boolean;
  is_deleting_questionAndAnswers: boolean;
}
export const questionAndAnswersSlice = createSlice({
  name: "questionAndAnswers",
  initialState,
  reducers: {
    setSelectedquestionAndAnswers: (state, action) => {
      state.selected_questionAndAnswers = action.payload;
    },
    clearStatequestionAndAnswers: (state, action) => {
      state.questionAndAnswers = [];
      state.is_loading_questionAndAnswers = false;
      state.is_creating_questionAndAnswers = false;
      state.is_deleting_questionAndAnswers = false;
    },
    mergequestionAndAnswers: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_questionAndAnswers = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.questionAndAnswers = merge(state.questionAndAnswers, payload) as any;
        }
      }
    },

    add_questionAndAnswers: (state, action) => {
      if (action.payload) {
        state.questionAndAnswers = [...state.questionAndAnswers, action.payload];
      }
    },
    set_questionAndAnswers: (state, action) => {
      let { key, value, id } = action.payload;
      state.questionAndAnswers = state.questionAndAnswers.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadQuestionAndAnswerssAsync.pending, (state) => {
      state.is_loading_questionAndAnswers = true;
    });
    builder.addCase(LoadQuestionAndAnswerssAsync.fulfilled, (state, action) => {
      state.is_loading_questionAndAnswers = false;
      if (action.payload) {
        state.questionAndAnswers = action.payload;
      }
    });
    builder.addCase(LoadQuestionAndAnswerssAsync.rejected, (state, action) => {
      state.is_loading_questionAndAnswers = false;
    });
    builder.addCase(CreateQuestionAndAnswersAsync.pending, (state) => {
      state.is_creating_questionAndAnswers = true;
    });
    builder.addCase(CreateQuestionAndAnswersAsync.fulfilled, (state) => {
      state.is_creating_questionAndAnswers = false;
    });
    builder.addCase(CreateQuestionAndAnswersAsync.rejected, (state) => {
      state.is_creating_questionAndAnswers = false;
    });
    builder.addCase(DeleteQuestionAndAnswersAsync.pending, (state) => {
      state.is_deleting_questionAndAnswers = true;
    });
    builder.addCase(DeleteQuestionAndAnswersAsync.fulfilled, (state, action) => {
      state.is_deleting_questionAndAnswers = false;
      if (state.questionAndAnswers) {
        state.questionAndAnswers = state.questionAndAnswers.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteQuestionAndAnswersAsync.rejected, (state, action) => {
      state.is_deleting_questionAndAnswers = false;
    });

    builder.addCase(autoThunks.get_question_for_account.pending, (state) => {
      state.is_loading_questionAndAnswers = true;
    });
    builder.addCase(autoThunks.get_question_for_account.fulfilled, (state, action) => {
      state.is_loading_questionAndAnswers = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_question_for_account.rejected, (state, action) => {
      state.is_loading_questionAndAnswers = false;
    });
  }
});

function handleResult(action: any, state: questionAndAnswersSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_questionAndAnswers = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.questionAndAnswers = merge(state.questionAndAnswers, payload) as any;
    }
  }
}

export const { clearStatequestionAndAnswers, setSelectedquestionAndAnswers, set_questionAndAnswers, mergequestionAndAnswers, add_questionAndAnswers } =
  questionAndAnswersSlice.actions;

export const get_questionAndAnswers =
  (id?: string) =>
  (state: {
    questionAndAnswers: {
      questionAndAnswers: QuestionAndAnswers[];
    };
  }): QuestionAndAnswers | null => {
    let temp = state?.questionAndAnswers?.questionAndAnswers?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.questionAndAnswers?.questionAndAnswers[0] || null;
  };
export const get_selected_questionAndAnswers = (state: { questionAndAnswers: questionAndAnswersSliceState }): QuestionAndAnswers | null => {
  let id = state?.questionAndAnswers?.selected_questionAndAnswers;
  let temp = state?.questionAndAnswers?.questionAndAnswers?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_questionAndAnswers = (state: {
  questionAndAnswers: {
    questionAndAnswers: QuestionAndAnswers[];
  };
}): QuestionAndAnswers[] => {
  let temp = state?.questionAndAnswers?.questionAndAnswers;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_questionAndAnswers_busy = (state: { questionAndAnswers: questionAndAnswersSliceState }) => {
  return (
    state?.questionAndAnswers.is_deleting_questionAndAnswers ||
    state?.questionAndAnswers.is_loading_questionAndAnswers ||
    state?.questionAndAnswers.is_creating_questionAndAnswers
  );
};
export const is_deleting_questionAndAnswers_busy = (state: { questionAndAnswers: questionAndAnswersSliceState }) => {
  return state?.questionAndAnswers.is_deleting_questionAndAnswers;
};
export const is_loading_questionAndAnswers_busy = (state: { questionAndAnswers: questionAndAnswersSliceState }) => {
  return state?.questionAndAnswers.is_loading_questionAndAnswers;
};
export const is_creating_questionAndAnswers_busy = (state: { questionAndAnswers: questionAndAnswersSliceState }) => {
  return state?.questionAndAnswers.is_creating_questionAndAnswers;
};

export default questionAndAnswersSlice.reducer;
