import { createAsyncThunk } from "@reduxjs/toolkit";
import VirtualBusinessInvestorChange from "./virtual-business-investor-change-model";
import {
  delete_virtualBusinessInvestorChange,
  load_virtualBusinessInvestorChanges,
  store_virtualBusinessInvestorChange
} from "./virtualBusinessInvestorChange-service";

export const LoadVirtualBusinessInvestorChangesAsync = createAsyncThunk<any, any>(
  "composer/load/virtualBusinessInvestorChange",
  async (params: { uid: string }) => {
    return await load_virtualBusinessInvestorChanges(params);
  }
);

export const CreateVirtualBusinessInvestorChangeAsync = createAsyncThunk<any, any>(
  "composer/create/virtualBusinessInvestorChange",
  async (params: VirtualBusinessInvestorChange) => {
    return await store_virtualBusinessInvestorChange(params);
  }
);

export const DeleteVirtualBusinessInvestorChangeAsync = createAsyncThunk<any, any>("composer/delete/virtualBusinessInvestorChange", async (params) => {
  return await delete_virtualBusinessInvestorChange(params);
});
