import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import BusinessPlanEvaluation from "./business-plan-evaluation-model";
import { BusinessPlanEvaluationProperties, BusinessPlanEvaluationMetaProperties } from "./businessPlanEvaluation-property-definitions";

export async function load_businessPlanEvaluations(args: { uid: string }): Promise<BusinessPlanEvaluation[]> {
  throw "Not implemented";
}

export async function delete_businessPlanEvaluation(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_businessPlanEvaluation(args: BusinessPlanEvaluation): Promise<BusinessPlanEvaluation> {
  throw "Not implemented";
}
