import CourseRecord from "./course-record-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateCourseRecordAsync, DeleteCourseRecordAsync, LoadCourseRecordsAsync } from "./courseRecord-thunk";
const initialState: courseRecordSliceState = {
  courseRecord: [],
  selected_courseRecord: "",
  is_loading_courseRecord: false,
  is_creating_courseRecord: false,
  is_deleting_courseRecord: false
};
export interface courseRecordSliceState {
  courseRecord: CourseRecord[];
  selected_courseRecord?: string;
  is_loading_courseRecord: boolean;
  is_creating_courseRecord: boolean;
  is_deleting_courseRecord: boolean;
}
export const courseRecordSlice = createSlice({
  name: "courseRecord",
  initialState,
  reducers: {
    setSelectedcourseRecord: (state, action) => {
      state.selected_courseRecord = action.payload;
    },
    clearStatecourseRecord: (state, action) => {
      state.courseRecord = [];
      state.is_loading_courseRecord = false;
      state.is_creating_courseRecord = false;
      state.is_deleting_courseRecord = false;
    },
    mergecourseRecord: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_courseRecord = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.courseRecord = merge(state.courseRecord, payload) as any;
        }
      }
    },

    add_courseRecord: (state, action) => {
      if (action.payload) {
        state.courseRecord = [...state.courseRecord, action.payload];
      }
    },
    set_courseRecord: (state, action) => {
      let { key, value, id } = action.payload;
      state.courseRecord = state.courseRecord.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadCourseRecordsAsync.pending, (state) => {
      state.is_loading_courseRecord = true;
    });
    builder.addCase(LoadCourseRecordsAsync.fulfilled, (state, action) => {
      state.is_loading_courseRecord = false;
      if (action.payload) {
        state.courseRecord = action.payload;
      }
    });
    builder.addCase(LoadCourseRecordsAsync.rejected, (state, action) => {
      state.is_loading_courseRecord = false;
    });
    builder.addCase(CreateCourseRecordAsync.pending, (state) => {
      state.is_creating_courseRecord = true;
    });
    builder.addCase(CreateCourseRecordAsync.fulfilled, (state) => {
      state.is_creating_courseRecord = false;
    });
    builder.addCase(CreateCourseRecordAsync.rejected, (state) => {
      state.is_creating_courseRecord = false;
    });
    builder.addCase(DeleteCourseRecordAsync.pending, (state) => {
      state.is_deleting_courseRecord = true;
    });
    builder.addCase(DeleteCourseRecordAsync.fulfilled, (state, action) => {
      state.is_deleting_courseRecord = false;
      if (state.courseRecord) {
        state.courseRecord = state.courseRecord.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteCourseRecordAsync.rejected, (state, action) => {
      state.is_deleting_courseRecord = false;
    });
  }
});

function handleResult(action: any, state: courseRecordSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_courseRecord = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.courseRecord = merge(state.courseRecord, payload) as any;
    }
  }
}

export const { clearStatecourseRecord, setSelectedcourseRecord, set_courseRecord, mergecourseRecord, add_courseRecord } = courseRecordSlice.actions;

export const get_courseRecord =
  (id?: string) =>
  (state: {
    courseRecord: {
      courseRecord: CourseRecord[];
    };
  }): CourseRecord | null => {
    let temp = state?.courseRecord?.courseRecord?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.courseRecord?.courseRecord[0] || null;
  };
export const get_selected_courseRecord = (state: { courseRecord: courseRecordSliceState }): CourseRecord | null => {
  let id = state?.courseRecord?.selected_courseRecord;
  let temp = state?.courseRecord?.courseRecord?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_courseRecord = (state: {
  courseRecord: {
    courseRecord: CourseRecord[];
  };
}): CourseRecord[] => {
  let temp = state?.courseRecord?.courseRecord;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_courseRecord_busy = (state: { courseRecord: courseRecordSliceState }) => {
  return state?.courseRecord.is_deleting_courseRecord || state?.courseRecord.is_loading_courseRecord || state?.courseRecord.is_creating_courseRecord;
};
export const is_deleting_courseRecord_busy = (state: { courseRecord: courseRecordSliceState }) => {
  return state?.courseRecord.is_deleting_courseRecord;
};
export const is_loading_courseRecord_busy = (state: { courseRecord: courseRecordSliceState }) => {
  return state?.courseRecord.is_loading_courseRecord;
};
export const is_creating_courseRecord_busy = (state: { courseRecord: courseRecordSliceState }) => {
  return state?.courseRecord.is_creating_courseRecord;
};

export default courseRecordSlice.reducer;
