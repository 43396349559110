import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import SponorAgent from "./sponor-agent-model";
import { SponorAgentProperties, SponorAgentMetaProperties } from "./sponorAgent-property-definitions";

export async function load_sponorAgents(args: { uid: string }): Promise<SponorAgent[]> {
  throw "Not implemented";
}

export async function delete_sponorAgent(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_sponorAgent(args: SponorAgent): Promise<SponorAgent> {
  throw "Not implemented";
}
