export default function TitleServiceTitles(text: string, category: string = "default", language: string) {
  switch (category) {
    case `BusinessReviewPlan`:
      switch (text) {
        case `Review the business plan`:
          switch (language) {
            case "en":
              return `Review the business plan`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `Review the business plan`;
          }
          break;
        case `OneToTen`:
          switch (language) {
            case "en":
              return `1 to 10`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `OneToTen`;
          }
          break;
      }
      break;

    case `BusinessProfitPotentialGraph`:
      switch (text) {
        case `OneToTen`:
          switch (language) {
            case "en":
              return `1 to 10`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `OneToTen`;
          }
          break;
        case `ThousandPlus`:
          switch (language) {
            case "en":
              return `+1000`;
            case "fr":
              return `+1000`;
            case "no":
              return `+1000`;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `ThousandPlus`;
          }
          break;
        case `TenToHundred`:
          switch (language) {
            case "en":
              return `10 to 100`;
            case "fr":
              return `10 a 100`;
            case "no":
              return `10 til 100`;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `TenToHundred`;
          }
          break;
      }
      break;

    case `Default`:
      switch (text) {
        case `InterestRaiser`:
          switch (language) {
            case "en":
              return `Interest Raiser`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `InterestRaiser`;
          }
          break;
        case `AttendanceBonus`:
          switch (language) {
            case "en":
              return `Attendance Bonus`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `AttendanceBonus`;
          }
          break;
        case `ProductionDescription`:
          switch (language) {
            case "en":
              return `Product Description`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `ProductionDescription`;
          }
          break;
        case `TimeToMarket`:
          switch (language) {
            case "en":
              return `Time to market`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `TimeToMarket`;
          }
          break;
        case `SoleProprietorship`:
          switch (language) {
            case "en":
              return `Sole Proprietorship`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `SoleProprietorship`;
          }
          break;
        case `CCorporation`:
          switch (language) {
            case "en":
              return `C Corporation`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `CCorporation`;
          }
          break;
        case `SCorporation`:
          switch (language) {
            case "en":
              return `S Corporation`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `SCorporation`;
          }
          break;
        case `LimitedLiabilityCorporation`:
          switch (language) {
            case "en":
              return `Limited Liability Corporation`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `LimitedLiabilityCorporation`;
          }
          break;
        case `LimitedLiabilityPartnership`:
          switch (language) {
            case "en":
              return `Limited Liability Partnership`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `LimitedLiabilityPartnership`;
          }
          break;
        case `SeriesLLC`:
          switch (language) {
            case "en":
              return `Series LLC`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `SeriesLLC`;
          }
          break;
        case `NonprofitCorporation`:
          switch (language) {
            case "en":
              return `Nonprofit Corporation`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `NonprofitCorporation`;
          }
          break;
        case `BenefitCorporation`:
          switch (language) {
            case "en":
              return `Benefit Corporation`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `BenefitCorporation`;
          }
          break;
        case `LowProfitLimitedLiabilityCorporation`:
          switch (language) {
            case "en":
              return `Low Profit Limited Liability Corporation`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `LowProfitLimitedLiabilityCorporation`;
          }
          break;
        case `LowIncome`:
          switch (language) {
            case "en":
              return `Low Income`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `LowIncome`;
          }
          break;
        case `MiddleIncome`:
          switch (language) {
            case "en":
              return `Middle Income`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `MiddleIncome`;
          }
          break;
        case `UpperIncome`:
          switch (language) {
            case "en":
              return `Upper Income`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `UpperIncome`;
          }
          break;
        case `UltraIncome`:
          switch (language) {
            case "en":
              return `Ultra Income`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `UltraIncome`;
          }
          break;
        case `EastCoast`:
          switch (language) {
            case "en":
              return `East Coast`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `EastCoast`;
          }
          break;
        case `MidWest`:
          switch (language) {
            case "en":
              return `Midwest`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `MidWest`;
          }
          break;
        case `OneMonth`:
          switch (language) {
            case "en":
              return `1 month`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `OneMonth`;
          }
          break;
        case `ThreeMonths`:
          switch (language) {
            case "en":
              return `3 months`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `ThreeMonths`;
          }
          break;
        case `OverAYear`:
          switch (language) {
            case "en":
              return `more 1 year`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `OverAYear`;
          }
          break;
        case `YoungAdults`:
          switch (language) {
            case "en":
              return `Young adults`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `YoungAdults`;
          }
          break;
        case `SeniorCitizens`:
          switch (language) {
            case "en":
              return `Senior Citizens`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `SeniorCitizens`;
          }
          break;
        case `Zero`:
          switch (language) {
            case "en":
              return `0`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `Zero`;
          }
          break;
        case `OneToTen`:
          switch (language) {
            case "en":
              return `1 - 10`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `OneToTen`;
          }
          break;
        case `TenToHundred`:
          switch (language) {
            case "en":
              return `10 - 100`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `TenToHundred`;
          }
          break;
        case `HundredToThousand`:
          switch (language) {
            case "en":
              return `100 - 1000`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `HundredToThousand`;
          }
          break;
        case `ThousandPlus`:
          switch (language) {
            case "en":
              return `1000+`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `ThousandPlus`;
          }
          break;
        case `WestCoast`:
          switch (language) {
            case "en":
              return `West Coast`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `WestCoast`;
          }
          break;
        case `SixMonths`:
          switch (language) {
            case "en":
              return `6 months`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `SixMonths`;
          }
          break;
        case `ChiefExecutiveOfficer`:
          switch (language) {
            case "en":
              return `Chief Executive Officer`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `ChiefExecutiveOfficer`;
          }
          break;
        case `ChiefFinancialOfficer`:
          switch (language) {
            case "en":
              return `Chief Financial Officer`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `ChiefFinancialOfficer`;
          }
          break;
        case `ChiefAnalyticsOfficer`:
          switch (language) {
            case "en":
              return `Chief Analytics Officer`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `ChiefAnalyticsOfficer`;
          }
          break;
        case `ChiefMarketingOfficer`:
          switch (language) {
            case "en":
              return `Chief Marketing Officer`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `ChiefMarketingOfficer`;
          }
          break;
        case `ChiefDataOfficer`:
          switch (language) {
            case "en":
              return `Chief Data Officer`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `ChiefDataOfficer`;
          }
          break;
        case `ChiefInformationOfficer`:
          switch (language) {
            case "en":
              return `Chief Information Officer`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `ChiefInformationOfficer`;
          }
          break;
        case `ChiefOperatingOfficer`:
          switch (language) {
            case "en":
              return `Chief Operating Officer`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `ChiefOperatingOfficer`;
          }
          break;
        case `President`:
          switch (language) {
            case "en":
              return `President`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `President`;
          }
          break;
        case `VicePresident`:
          switch (language) {
            case "en":
              return `Vice President`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `VicePresident`;
          }
          break;
        case `Treasurer`:
          switch (language) {
            case "en":
              return `Treasurer`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `Treasurer`;
          }
          break;
        case `Secretary`:
          switch (language) {
            case "en":
              return `Secretary`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `Secretary`;
          }
          break;
        case `GeneralPartner`:
          switch (language) {
            case "en":
              return `General Partner`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `GeneralPartner`;
          }
          break;
        case `Partner`:
          switch (language) {
            case "en":
              return `Partner`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `Partner`;
          }
          break;
        case `ChiefHumanResourceManager`:
          switch (language) {
            case "en":
              return `Chief Human Resource Manager`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `ChiefHumanResourceManager`;
          }
          break;
        case `ChiefComplianceOfficer`:
          switch (language) {
            case "en":
              return `Chief Compliance Officer`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `ChiefComplianceOfficer`;
          }
          break;
        case `ChiefGreenOfficer`:
          switch (language) {
            case "en":
              return `Chief Green Officer`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `ChiefGreenOfficer`;
          }
          break;
        case `ChiefSecurityOfficer`:
          switch (language) {
            case "en":
              return `Chief Security Officer`;
            case "fr":
              return ``;
            case "no":
              return ``;
            case "es":
              return `undefined`;
            case "de":
              return `undefined`;
            default:
              return `ChiefSecurityOfficer`;
          }
          break;
      }
      break;

    default:
      return undefined;
  }
}
