import { createAsyncThunk } from "@reduxjs/toolkit";
import BusinessPlanEvaluation from "./business-plan-evaluation-model";
import { delete_businessPlanEvaluation, load_businessPlanEvaluations, store_businessPlanEvaluation } from "./businessPlanEvaluation-service";

export const LoadBusinessPlanEvaluationsAsync = createAsyncThunk<any, any>("composer/load/businessPlanEvaluation", async (params: { uid: string }) => {
  return await load_businessPlanEvaluations(params);
});

export const CreateBusinessPlanEvaluationAsync = createAsyncThunk<any, any>(
  "composer/create/businessPlanEvaluation",
  async (params: BusinessPlanEvaluation) => {
    return await store_businessPlanEvaluation(params);
  }
);

export const DeleteBusinessPlanEvaluationAsync = createAsyncThunk<any, any>("composer/delete/businessPlanEvaluation", async (params) => {
  return await delete_businessPlanEvaluation(params);
});
