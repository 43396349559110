import Loan from "./loan-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateLoanAsync, DeleteLoanAsync, LoadLoansAsync } from "./loan-thunk";
const initialState: loanSliceState = {
  loan: [],
  selected_loan: "",
  is_loading_loan: false,
  is_creating_loan: false,
  is_deleting_loan: false
};
export interface loanSliceState {
  loan: Loan[];
  selected_loan?: string;
  is_loading_loan: boolean;
  is_creating_loan: boolean;
  is_deleting_loan: boolean;
}
export const loanSlice = createSlice({
  name: "loan",
  initialState,
  reducers: {
    setSelectedloan: (state, action) => {
      state.selected_loan = action.payload;
    },
    clearStateloan: (state, action) => {
      state.loan = [];
      state.is_loading_loan = false;
      state.is_creating_loan = false;
      state.is_deleting_loan = false;
    },
    mergeloan: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_loan = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.loan = merge(state.loan, payload) as any;
        }
      }
    },

    add_loan: (state, action) => {
      if (action.payload) {
        state.loan = [...state.loan, action.payload];
      }
    },
    set_loan: (state, action) => {
      let { key, value, id } = action.payload;
      state.loan = state.loan.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadLoansAsync.pending, (state) => {
      state.is_loading_loan = true;
    });
    builder.addCase(LoadLoansAsync.fulfilled, (state, action) => {
      state.is_loading_loan = false;
      if (action.payload) {
        state.loan = action.payload;
      }
    });
    builder.addCase(LoadLoansAsync.rejected, (state, action) => {
      state.is_loading_loan = false;
    });
    builder.addCase(CreateLoanAsync.pending, (state) => {
      state.is_creating_loan = true;
    });
    builder.addCase(CreateLoanAsync.fulfilled, (state) => {
      state.is_creating_loan = false;
    });
    builder.addCase(CreateLoanAsync.rejected, (state) => {
      state.is_creating_loan = false;
    });
    builder.addCase(DeleteLoanAsync.pending, (state) => {
      state.is_deleting_loan = true;
    });
    builder.addCase(DeleteLoanAsync.fulfilled, (state, action) => {
      state.is_deleting_loan = false;
      if (state.loan) {
        state.loan = state.loan.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteLoanAsync.rejected, (state, action) => {
      state.is_deleting_loan = false;
    });

    builder.addCase(autoThunks.get_personal_loans.pending, (state) => {
      state.is_loading_loan = true;
    });
    builder.addCase(autoThunks.get_personal_loans.fulfilled, (state, action) => {
      state.is_loading_loan = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_personal_loans.rejected, (state, action) => {
      state.is_loading_loan = false;
    });

    builder.addCase(autoThunks.get_business_loans.pending, (state) => {
      state.is_loading_loan = true;
    });
    builder.addCase(autoThunks.get_business_loans.fulfilled, (state, action) => {
      state.is_loading_loan = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_business_loans.rejected, (state, action) => {
      state.is_loading_loan = false;
    });

    builder.addCase(autoThunks.request_business_loans.pending, (state) => {
      state.is_loading_loan = true;
    });
    builder.addCase(autoThunks.request_business_loans.fulfilled, (state, action) => {
      state.is_loading_loan = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.request_business_loans.rejected, (state, action) => {
      state.is_loading_loan = false;
    });

    builder.addCase(autoThunks.reply_business_loan_offer.pending, (state) => {
      state.is_loading_loan = true;
    });
    builder.addCase(autoThunks.reply_business_loan_offer.fulfilled, (state, action) => {
      state.is_loading_loan = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.reply_business_loan_offer.rejected, (state, action) => {
      state.is_loading_loan = false;
    });
  }
});

function handleResult(action: any, state: loanSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_loan = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.loan = merge(state.loan, payload) as any;
    }
  }
}

export const { clearStateloan, setSelectedloan, set_loan, mergeloan, add_loan } = loanSlice.actions;

export const get_loan =
  (id?: string) =>
  (state: {
    loan: {
      loan: Loan[];
    };
  }): Loan | null => {
    let temp = state?.loan?.loan?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.loan?.loan[0] || null;
  };
export const get_selected_loan = (state: { loan: loanSliceState }): Loan | null => {
  let id = state?.loan?.selected_loan;
  let temp = state?.loan?.loan?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_loan = (state: {
  loan: {
    loan: Loan[];
  };
}): Loan[] => {
  let temp = state?.loan?.loan;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_loan_busy = (state: { loan: loanSliceState }) => {
  return state?.loan.is_deleting_loan || state?.loan.is_loading_loan || state?.loan.is_creating_loan;
};
export const is_deleting_loan_busy = (state: { loan: loanSliceState }) => {
  return state?.loan.is_deleting_loan;
};
export const is_loading_loan_busy = (state: { loan: loanSliceState }) => {
  return state?.loan.is_loading_loan;
};
export const is_creating_loan_busy = (state: { loan: loanSliceState }) => {
  return state?.loan.is_creating_loan;
};

export default loanSlice.reducer;
