import PublishedCourse from "./published-course-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreatePublishedCourseAsync, DeletePublishedCourseAsync, LoadPublishedCoursesAsync } from "./publishedCourse-thunk";
const initialState: publishedCourseSliceState = {
  publishedCourse: [],
  selected_publishedCourse: "",
  is_loading_publishedCourse: false,
  is_creating_publishedCourse: false,
  is_deleting_publishedCourse: false
};
export interface publishedCourseSliceState {
  publishedCourse: PublishedCourse[];
  selected_publishedCourse?: string;
  is_loading_publishedCourse: boolean;
  is_creating_publishedCourse: boolean;
  is_deleting_publishedCourse: boolean;
}
export const publishedCourseSlice = createSlice({
  name: "publishedCourse",
  initialState,
  reducers: {
    setSelectedpublishedCourse: (state, action) => {
      state.selected_publishedCourse = action.payload;
    },
    clearStatepublishedCourse: (state, action) => {
      state.publishedCourse = [];
      state.is_loading_publishedCourse = false;
      state.is_creating_publishedCourse = false;
      state.is_deleting_publishedCourse = false;
    },
    mergepublishedCourse: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_publishedCourse = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.publishedCourse = merge(state.publishedCourse, payload) as any;
        }
      }
    },

    add_publishedCourse: (state, action) => {
      if (action.payload) {
        state.publishedCourse = [...state.publishedCourse, action.payload];
      }
    },
    set_publishedCourse: (state, action) => {
      let { key, value, id } = action.payload;
      state.publishedCourse = state.publishedCourse.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadPublishedCoursesAsync.pending, (state) => {
      state.is_loading_publishedCourse = true;
    });
    builder.addCase(LoadPublishedCoursesAsync.fulfilled, (state, action) => {
      state.is_loading_publishedCourse = false;
      if (action.payload) {
        state.publishedCourse = action.payload;
      }
    });
    builder.addCase(LoadPublishedCoursesAsync.rejected, (state, action) => {
      state.is_loading_publishedCourse = false;
    });
    builder.addCase(CreatePublishedCourseAsync.pending, (state) => {
      state.is_creating_publishedCourse = true;
    });
    builder.addCase(CreatePublishedCourseAsync.fulfilled, (state) => {
      state.is_creating_publishedCourse = false;
    });
    builder.addCase(CreatePublishedCourseAsync.rejected, (state) => {
      state.is_creating_publishedCourse = false;
    });
    builder.addCase(DeletePublishedCourseAsync.pending, (state) => {
      state.is_deleting_publishedCourse = true;
    });
    builder.addCase(DeletePublishedCourseAsync.fulfilled, (state, action) => {
      state.is_deleting_publishedCourse = false;
      if (state.publishedCourse) {
        state.publishedCourse = state.publishedCourse.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeletePublishedCourseAsync.rejected, (state, action) => {
      state.is_deleting_publishedCourse = false;
    });

    builder.addCase(autoThunks.get_published_courses.pending, (state) => {
      state.is_loading_publishedCourse = true;
    });
    builder.addCase(autoThunks.get_published_courses.fulfilled, (state, action) => {
      state.is_loading_publishedCourse = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_published_courses.rejected, (state, action) => {
      state.is_loading_publishedCourse = false;
    });
  }
});

function handleResult(action: any, state: publishedCourseSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_publishedCourse = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.publishedCourse = merge(state.publishedCourse, payload) as any;
    }
  }
}

export const { clearStatepublishedCourse, setSelectedpublishedCourse, set_publishedCourse, mergepublishedCourse, add_publishedCourse } =
  publishedCourseSlice.actions;

export const get_publishedCourse =
  (id?: string) =>
  (state: {
    publishedCourse: {
      publishedCourse: PublishedCourse[];
    };
  }): PublishedCourse | null => {
    let temp = state?.publishedCourse?.publishedCourse?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.publishedCourse?.publishedCourse[0] || null;
  };
export const get_selected_publishedCourse = (state: { publishedCourse: publishedCourseSliceState }): PublishedCourse | null => {
  let id = state?.publishedCourse?.selected_publishedCourse;
  let temp = state?.publishedCourse?.publishedCourse?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_publishedCourse = (state: {
  publishedCourse: {
    publishedCourse: PublishedCourse[];
  };
}): PublishedCourse[] => {
  let temp = state?.publishedCourse?.publishedCourse;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_publishedCourse_busy = (state: { publishedCourse: publishedCourseSliceState }) => {
  return (
    state?.publishedCourse.is_deleting_publishedCourse ||
    state?.publishedCourse.is_loading_publishedCourse ||
    state?.publishedCourse.is_creating_publishedCourse
  );
};
export const is_deleting_publishedCourse_busy = (state: { publishedCourse: publishedCourseSliceState }) => {
  return state?.publishedCourse.is_deleting_publishedCourse;
};
export const is_loading_publishedCourse_busy = (state: { publishedCourse: publishedCourseSliceState }) => {
  return state?.publishedCourse.is_loading_publishedCourse;
};
export const is_creating_publishedCourse_busy = (state: { publishedCourse: publishedCourseSliceState }) => {
  return state?.publishedCourse.is_creating_publishedCourse;
};

export default publishedCourseSlice.reducer;
