import BankMetrics from "./bank-metrics-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateBankMetricsAsync, DeleteBankMetricsAsync, LoadBankMetricssAsync } from "./bankMetrics-thunk";
const initialState: bankMetricsSliceState = {
  bankMetrics: [],
  selected_bankMetrics: "",
  is_loading_bankMetrics: false,
  is_creating_bankMetrics: false,
  is_deleting_bankMetrics: false
};
export interface bankMetricsSliceState {
  bankMetrics: BankMetrics[];
  selected_bankMetrics?: string;
  is_loading_bankMetrics: boolean;
  is_creating_bankMetrics: boolean;
  is_deleting_bankMetrics: boolean;
}
export const bankMetricsSlice = createSlice({
  name: "bankMetrics",
  initialState,
  reducers: {
    setSelectedbankMetrics: (state, action) => {
      state.selected_bankMetrics = action.payload;
    },
    clearStatebankMetrics: (state, action) => {
      state.bankMetrics = [];
      state.is_loading_bankMetrics = false;
      state.is_creating_bankMetrics = false;
      state.is_deleting_bankMetrics = false;
    },
    mergebankMetrics: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_bankMetrics = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.bankMetrics = merge(state.bankMetrics, payload) as any;
        }
      }
    },

    add_bankMetrics: (state, action) => {
      if (action.payload) {
        state.bankMetrics = [...state.bankMetrics, action.payload];
      }
    },
    set_bankMetrics: (state, action) => {
      let { key, value, id } = action.payload;
      state.bankMetrics = state.bankMetrics.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadBankMetricssAsync.pending, (state) => {
      state.is_loading_bankMetrics = true;
    });
    builder.addCase(LoadBankMetricssAsync.fulfilled, (state, action) => {
      state.is_loading_bankMetrics = false;
      if (action.payload) {
        state.bankMetrics = action.payload;
      }
    });
    builder.addCase(LoadBankMetricssAsync.rejected, (state, action) => {
      state.is_loading_bankMetrics = false;
    });
    builder.addCase(CreateBankMetricsAsync.pending, (state) => {
      state.is_creating_bankMetrics = true;
    });
    builder.addCase(CreateBankMetricsAsync.fulfilled, (state) => {
      state.is_creating_bankMetrics = false;
    });
    builder.addCase(CreateBankMetricsAsync.rejected, (state) => {
      state.is_creating_bankMetrics = false;
    });
    builder.addCase(DeleteBankMetricsAsync.pending, (state) => {
      state.is_deleting_bankMetrics = true;
    });
    builder.addCase(DeleteBankMetricsAsync.fulfilled, (state, action) => {
      state.is_deleting_bankMetrics = false;
      if (state.bankMetrics) {
        state.bankMetrics = state.bankMetrics.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteBankMetricsAsync.rejected, (state, action) => {
      state.is_deleting_bankMetrics = false;
    });
  }
});

function handleResult(action: any, state: bankMetricsSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_bankMetrics = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.bankMetrics = merge(state.bankMetrics, payload) as any;
    }
  }
}

export const { clearStatebankMetrics, setSelectedbankMetrics, set_bankMetrics, mergebankMetrics, add_bankMetrics } = bankMetricsSlice.actions;

export const get_bankMetrics =
  (id?: string) =>
  (state: {
    bankMetrics: {
      bankMetrics: BankMetrics[];
    };
  }): BankMetrics | null => {
    let temp = state?.bankMetrics?.bankMetrics?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.bankMetrics?.bankMetrics[0] || null;
  };
export const get_selected_bankMetrics = (state: { bankMetrics: bankMetricsSliceState }): BankMetrics | null => {
  let id = state?.bankMetrics?.selected_bankMetrics;
  let temp = state?.bankMetrics?.bankMetrics?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_bankMetrics = (state: {
  bankMetrics: {
    bankMetrics: BankMetrics[];
  };
}): BankMetrics[] => {
  let temp = state?.bankMetrics?.bankMetrics;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_bankMetrics_busy = (state: { bankMetrics: bankMetricsSliceState }) => {
  return state?.bankMetrics.is_deleting_bankMetrics || state?.bankMetrics.is_loading_bankMetrics || state?.bankMetrics.is_creating_bankMetrics;
};
export const is_deleting_bankMetrics_busy = (state: { bankMetrics: bankMetricsSliceState }) => {
  return state?.bankMetrics.is_deleting_bankMetrics;
};
export const is_loading_bankMetrics_busy = (state: { bankMetrics: bankMetricsSliceState }) => {
  return state?.bankMetrics.is_loading_bankMetrics;
};
export const is_creating_bankMetrics_busy = (state: { bankMetrics: bankMetricsSliceState }) => {
  return state?.bankMetrics.is_creating_bankMetrics;
};

export default bankMetricsSlice.reducer;
