import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import MarketingMaterial from "./marketing-material-model";
import { MarketingMaterialProperties, MarketingMaterialMetaProperties } from "./marketingMaterial-property-definitions";

export async function load_marketingMaterials(args: { uid: string }): Promise<MarketingMaterial[]> {
  throw "Not implemented";
}

export async function delete_marketingMaterial(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_marketingMaterial(args: MarketingMaterial): Promise<MarketingMaterial> {
  throw "Not implemented";
}
