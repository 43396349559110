import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import ServiceContract from "./service-contract-model";
import { ServiceContractProperties, ServiceContractMetaProperties } from "./serviceContract-property-definitions";

export async function load_serviceContracts(args: { uid: string }): Promise<ServiceContract[]> {
  throw "Not implemented";
}

export async function delete_serviceContract(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_serviceContract(args: ServiceContract): Promise<ServiceContract> {
  throw "Not implemented";
}
