import { combineReducers, createStore } from "redux";
import { devToolsEnhancer } from "redux-devtools-extension";
import { persistStore } from "redux-persist";
import { setupFirebase } from "features/firebase-app";

import bank from "features/bank/bank-slice";
import currency from "features/currency/currency-slice";
import account from "features/account/account-slice";
import bankRegistry from "features/bankRegistry/bankRegistry-slice";
import customer from "features/customer/customer-slice";
import user from "features/user/user-slice";
import bankOwner from "features/bankOwner/bankOwner-slice";
import bankerRole from "features/bankerRole/bankerRole-slice";
import roomAgent from "features/roomAgent/roomAgent-slice";
import bankSettings from "features/bankSettings/bankSettings-slice";
import transaction from "features/transaction/transaction-slice";
import auction from "features/auction/auction-slice";
import auctionBid from "features/auctionBid/auctionBid-slice";
import auctionItem from "features/auctionItem/auctionItem-slice";
import progress from "features/progress/progress-slice";
import accountLog from "features/accountLog/accountLog-slice";
import school from "features/school/school-slice";
import district from "features/district/district-slice";
import classRoom from "features/classRoom/classRoom-slice";
import sponsor from "features/sponsor/sponsor-slice";
import sponorAgent from "features/sponorAgent/sponorAgent-slice";
import marketingMaterialItem from "features/marketingMaterialItem/marketingMaterialItem-slice";
import marketingMaterial from "features/marketingMaterial/marketingMaterial-slice";
import marketingCampaign from "features/marketingCampaign/marketingCampaign-slice";
import geographicalRegion from "features/geographicalRegion/geographicalRegion-slice";
import claimsCache from "features/claimsCache/claimsCache-slice";
import stock from "features/stock/stock-slice";
import stockInformation from "features/stockInformation/stockInformation-slice";
import stockPrice from "features/stockPrice/stockPrice-slice";
import digitalAsset from "features/digitalAsset/digitalAsset-slice";
import investmentAccount from "features/investmentAccount/investmentAccount-slice";
import holding from "features/holding/holding-slice";
import investmentOrder from "features/investmentOrder/investmentOrder-slice";
import individualInvestmentHolding from "features/individualInvestmentHolding/individualInvestmentHolding-slice";
import digitalAssetInstance from "features/digitalAssetInstance/digitalAssetInstance-slice";
import businessPlan from "features/businessPlan/businessPlan-slice";
import businessPlanEvaluation from "features/businessPlanEvaluation/businessPlanEvaluation-slice";
import virtualBusiness from "features/virtualBusiness/virtualBusiness-slice";
import businessAccount from "features/businessAccount/businessAccount-slice";
import businessLoan from "features/businessLoan/businessLoan-slice";
import investmentRequest from "features/investmentRequest/investmentRequest-slice";
import virtualBusinessInvestorReference from "features/virtualBusinessInvestorReference/virtualBusinessInvestorReference-slice";
import businessLoanRequest from "features/businessLoanRequest/businessLoanRequest-slice";
import salesOrder from "features/salesOrder/salesOrder-slice";
import imageResource from "features/imageResource/imageResource-slice";
import businessPlanSubmission from "features/businessPlanSubmission/businessPlanSubmission-slice";
import moneySupply from "features/moneySupply/moneySupply-slice";
import moneySuployHistory from "features/moneySuployHistory/moneySuployHistory-slice";
import virtualInvestment from "features/virtualInvestment/virtualInvestment-slice";
import virtualShare from "features/virtualShare/virtualShare-slice";
import virtualBusinessRequirement from "features/virtualBusinessRequirement/virtualBusinessRequirement-slice";
import resourceRegistration from "features/resourceRegistration/resourceRegistration-slice";
import quickConnect from "features/quickConnect/quickConnect-slice";
import accountBalances from "features/accountBalances/accountBalances-slice";
import resourceImport from "features/resourceImport/resourceImport-slice";
import virtualBusinessPartners from "features/virtualBusinessPartners/virtualBusinessPartners-slice";
import virtualBusinessPartnerInvitation from "features/virtualBusinessPartnerInvitation/virtualBusinessPartnerInvitation-slice";
import virtualBusinessDivestmentRequest from "features/virtualBusinessDivestmentRequest/virtualBusinessDivestmentRequest-slice";
import virtualShareEscro from "features/virtualShareEscro/virtualShareEscro-slice";
import virtualShareEscroOffer from "features/virtualShareEscroOffer/virtualShareEscroOffer-slice";
import virtualBusinessPetitionPartnershipDissolution from "features/virtualBusinessPetitionPartnershipDissolution/virtualBusinessPetitionPartnershipDissolution-slice";
import virtualBusinessPetitionPartnershipDissolutionMessage from "features/virtualBusinessPetitionPartnershipDissolutionMessage/virtualBusinessPetitionPartnershipDissolutionMessage-slice";
import virtualEmployee from "features/virtualEmployee/virtualEmployee-slice";
import virtualEmploymentRecord from "features/virtualEmploymentRecord/virtualEmploymentRecord-slice";
import questionAndAnswers from "features/questionAndAnswers/questionAndAnswers-slice";
import answer from "features/answer/answer-slice";
import accountCurriculumRecord from "features/accountCurriculumRecord/accountCurriculumRecord-slice";
import curriculumRecordItem from "features/curriculumRecordItem/curriculumRecordItem-slice";
import bankBusiness from "features/bankBusiness/bankBusiness-slice";
import imageResourceStats from "features/imageResourceStats/imageResourceStats-slice";
import bankMetrics from "features/bankMetrics/bankMetrics-slice";
import resourceUserAvatarList from "features/resourceUserAvatarList/resourceUserAvatarList-slice";
import sendEmailRequest from "features/sendEmailRequest/sendEmailRequest-slice";
import virtualEmployeeCache from "features/virtualEmployeeCache/virtualEmployeeCache-slice";
import virtualBusinessBalanceChange from "features/virtualBusinessBalanceChange/virtualBusinessBalanceChange-slice";
import virtualBusinessInvestorChange from "features/virtualBusinessInvestorChange/virtualBusinessInvestorChange-slice";
import loan from "features/loan/loan-slice";
import accountLogCommand from "features/accountLogCommand/accountLogCommand-slice";
import bankAccountJoinRequest from "features/bankAccountJoinRequest/bankAccountJoinRequest-slice";
import bankCity from "features/bankCity/bankCity-slice";
import cityLand from "features/cityLand/cityLand-slice";
import cityAsset from "features/cityAsset/cityAsset-slice";
import votingIssue from "features/votingIssue/votingIssue-slice";
import votingBallot from "features/votingBallot/votingBallot-slice";
import eductionAssetOutputConfiguration from "features/eductionAssetOutputConfiguration/eductionAssetOutputConfiguration-slice";
import employeeBenefits from "features/employeeBenefits/employeeBenefits-slice";
import serviceContract from "features/serviceContract/serviceContract-slice";
import device from "features/device/device-slice";
import pushNotification from "features/pushNotification/pushNotification-slice";
import tokenHouse from "features/tokenHouse/tokenHouse-slice";
import fakeModel from "features/fakeModel/fakeModel-slice";
import professor from "features/professor/professor-slice";
import course from "features/course/course-slice";
import courseConnection from "features/courseConnection/courseConnection-slice";
import director from "features/director/director-slice";
import courseSection from "features/courseSection/courseSection-slice";
import courseQuestion from "features/courseQuestion/courseQuestion-slice";
import courseRecord from "features/courseRecord/courseRecord-slice";
import courseSectionRecord from "features/courseSectionRecord/courseSectionRecord-slice";
import courseTaker from "features/courseTaker/courseTaker-slice";
import publishCourseJob from "features/publishCourseJob/publishCourseJob-slice";
import publishedCourse from "features/publishedCourse/publishedCourse-slice";
import courseQuestionLog from "features/courseQuestionLog/courseQuestionLog-slice";
import courseSorting from "features/courseSorting/courseSorting-slice";
import courseQuestionSorting from "features/courseQuestionSorting/courseQuestionSorting-slice";
import enrollment from "features/enrollment/enrollment-slice";
import courseTakerSettings from "features/courseTakerSettings/courseTakerSettings-slice";
import courseLoad from "features/courseLoad/courseLoad-slice";
import courseResourceBlock from "features/courseResourceBlock/courseResourceBlock-slice";
import courseTopicSummary from "features/courseTopicSummary/courseTopicSummary-slice";
import viewBlockSummary from "features/viewBlockSummary/viewBlockSummary-slice";
import resourceBookPage from "features/resourceBookPage/resourceBookPage-slice";
import coursePay from "features/coursePay/coursePay-slice";
import courseContract from "features/courseContract/courseContract-slice";
import checkoutSession from "features/checkoutSession/checkoutSession-slice";

import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
// reducer import

import customizationReducer from "./store/customizationReducer";
import snackbarReducer from "./store/snackbarReducer";
import cartReducer from "./store/cartReducer";
import kanbanReducer from "./store/kanbanReducer";
import application from "./features/application/application-slice";

/* Create root reducer, containing all features of the application */
const rootReducer = combineReducers({
  application,
  customization: customizationReducer,
  snackbar: snackbarReducer,
  cart: persistReducer(
    {
      key: "cart",
      storage,
      keyPrefix: "berry-"
    },
    cartReducer
  ),
  kanban: kanbanReducer,
  bank,
  currency,
  account,
  bankRegistry,
  customer,
  user,
  bankOwner,
  bankerRole,
  roomAgent,
  bankSettings,
  transaction,
  auction,
  auctionBid,
  auctionItem,
  progress,
  accountLog,
  school,
  district,
  classRoom,
  sponsor,
  sponorAgent,
  marketingMaterialItem,
  marketingMaterial,
  marketingCampaign,
  geographicalRegion,
  claimsCache,
  stock,
  stockInformation,
  stockPrice,
  digitalAsset,
  investmentAccount,
  holding,
  investmentOrder,
  individualInvestmentHolding,
  digitalAssetInstance,
  businessPlan,
  businessPlanEvaluation,
  virtualBusiness,
  businessAccount,
  businessLoan,
  investmentRequest,
  virtualBusinessInvestorReference,
  businessLoanRequest,
  salesOrder,
  imageResource,
  businessPlanSubmission,
  moneySupply,
  moneySuployHistory,
  virtualInvestment,
  virtualShare,
  virtualBusinessRequirement,
  resourceRegistration,
  quickConnect,
  accountBalances,
  resourceImport,
  virtualBusinessPartners,
  virtualBusinessPartnerInvitation,
  virtualBusinessDivestmentRequest,
  virtualShareEscro,
  virtualShareEscroOffer,
  virtualBusinessPetitionPartnershipDissolution,
  virtualBusinessPetitionPartnershipDissolutionMessage,
  virtualEmployee,
  virtualEmploymentRecord,
  questionAndAnswers,
  answer,
  accountCurriculumRecord,
  curriculumRecordItem,
  bankBusiness,
  imageResourceStats,
  bankMetrics,
  resourceUserAvatarList,
  sendEmailRequest,
  virtualEmployeeCache,
  virtualBusinessBalanceChange,
  virtualBusinessInvestorChange,
  loan,
  accountLogCommand,
  bankAccountJoinRequest,
  bankCity,
  cityLand,
  cityAsset,
  votingIssue,
  votingBallot,
  eductionAssetOutputConfiguration,
  employeeBenefits,
  serviceContract,
  device,
  pushNotification,
  tokenHouse,
  fakeModel,
  professor,
  course,
  courseConnection,
  director,
  courseSection,
  courseQuestion,
  courseRecord,
  courseSectionRecord,
  courseTaker,
  publishCourseJob,
  publishedCourse,
  courseQuestionLog,
  courseSorting,
  courseQuestionSorting,
  enrollment,
  courseTakerSettings,
  courseLoad,
  courseResourceBlock,
  courseTopicSummary,
  viewBlockSummary,
  resourceBookPage,
  coursePay,
  courseContract,
  checkoutSession
});
const middleWare = [thunkMiddleware];
const composeEnhancers = ((window as any)["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] as any) || compose;
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleWare)));
// createStore(rootReducer, /* preloadedState, */ devToolsEnhancer({}));
export const persister = persistStore(store);
export function dispatch(action: any) {
  return store.dispatch(action);
}
