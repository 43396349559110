import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import VirtualBusinessBalanceChange from "./virtual-business-balance-change-model";
import { VirtualBusinessBalanceChangeProperties, VirtualBusinessBalanceChangeMetaProperties } from "./virtualBusinessBalanceChange-property-definitions";

export async function load_virtualBusinessBalanceChanges(args: { uid: string }): Promise<VirtualBusinessBalanceChange[]> {
  throw "Not implemented";
}

export async function delete_virtualBusinessBalanceChange(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_virtualBusinessBalanceChange(args: VirtualBusinessBalanceChange): Promise<VirtualBusinessBalanceChange> {
  throw "Not implemented";
}
