import { createAsyncThunk } from "@reduxjs/toolkit";
import AccountBalances from "./account-balances-model";
import { delete_accountBalances, load_accountBalancess, store_accountBalances } from "./accountBalances-service";

export const LoadAccountBalancessAsync = createAsyncThunk<any, any>("composer/load/accountBalances", async (params: { uid: string }) => {
  return await load_accountBalancess(params);
});

export const CreateAccountBalancesAsync = createAsyncThunk<any, any>("composer/create/accountBalances", async (params: AccountBalances) => {
  return await store_accountBalances(params);
});

export const DeleteAccountBalancesAsync = createAsyncThunk<any, any>("composer/delete/accountBalances", async (params) => {
  return await delete_accountBalances(params);
});
