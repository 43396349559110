import { createAsyncThunk } from "@reduxjs/toolkit";
import VirtualBusinessDivestmentRequest from "./virtual-business-divestment-request-model";
import {
  delete_virtualBusinessDivestmentRequest,
  load_virtualBusinessDivestmentRequests,
  store_virtualBusinessDivestmentRequest
} from "./virtualBusinessDivestmentRequest-service";

export const LoadVirtualBusinessDivestmentRequestsAsync = createAsyncThunk<any, any>(
  "composer/load/virtualBusinessDivestmentRequest",
  async (params: { uid: string }) => {
    return await load_virtualBusinessDivestmentRequests(params);
  }
);

export const CreateVirtualBusinessDivestmentRequestAsync = createAsyncThunk<any, any>(
  "composer/create/virtualBusinessDivestmentRequest",
  async (params: VirtualBusinessDivestmentRequest) => {
    return await store_virtualBusinessDivestmentRequest(params);
  }
);

export const DeleteVirtualBusinessDivestmentRequestAsync = createAsyncThunk<any, any>("composer/delete/virtualBusinessDivestmentRequest", async (params) => {
  return await delete_virtualBusinessDivestmentRequest(params);
});
