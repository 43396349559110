import Course from "./course-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateCourseAsync, DeleteCourseAsync, LoadCoursesAsync } from "./course-thunk";
const initialState: courseSliceState = {
  course: [],
  selected_course: "",
  is_loading_course: false,
  is_creating_course: false,
  is_deleting_course: false
};
export interface courseSliceState {
  course: Course[];
  selected_course?: string;
  is_loading_course: boolean;
  is_creating_course: boolean;
  is_deleting_course: boolean;
}
export const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    setSelectedcourse: (state, action) => {
      state.selected_course = action.payload;
    },
    clearStatecourse: (state, action) => {
      state.course = [];
      state.is_loading_course = false;
      state.is_creating_course = false;
      state.is_deleting_course = false;
    },
    mergecourse: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_course = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.course = merge(state.course, payload) as any;
        }
      }
    },

    add_course: (state, action) => {
      if (action.payload) {
        state.course = [...state.course, action.payload];
      }
    },
    set_course: (state, action) => {
      let { key, value, id } = action.payload;
      state.course = state.course.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadCoursesAsync.pending, (state) => {
      state.is_loading_course = true;
    });
    builder.addCase(LoadCoursesAsync.fulfilled, (state, action) => {
      state.is_loading_course = false;
      if (action.payload) {
        state.course = action.payload;
      }
    });
    builder.addCase(LoadCoursesAsync.rejected, (state, action) => {
      state.is_loading_course = false;
    });
    builder.addCase(CreateCourseAsync.pending, (state) => {
      state.is_creating_course = true;
    });
    builder.addCase(CreateCourseAsync.fulfilled, (state) => {
      state.is_creating_course = false;
    });
    builder.addCase(CreateCourseAsync.rejected, (state) => {
      state.is_creating_course = false;
    });
    builder.addCase(DeleteCourseAsync.pending, (state) => {
      state.is_deleting_course = true;
    });
    builder.addCase(DeleteCourseAsync.fulfilled, (state, action) => {
      state.is_deleting_course = false;
      if (state.course) {
        state.course = state.course.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteCourseAsync.rejected, (state, action) => {
      state.is_deleting_course = false;
    });

    builder.addCase(autoThunks.get_connected_courses.pending, (state) => {
      state.is_loading_course = true;
    });
    builder.addCase(autoThunks.get_connected_courses.fulfilled, (state, action) => {
      state.is_loading_course = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_connected_courses.rejected, (state, action) => {
      state.is_loading_course = false;
    });

    builder.addCase(autoThunks.add_my_professor_connected_course.pending, (state) => {
      state.is_loading_course = true;
    });
    builder.addCase(autoThunks.add_my_professor_connected_course.fulfilled, (state, action) => {
      state.is_loading_course = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.add_my_professor_connected_course.rejected, (state, action) => {
      state.is_loading_course = false;
    });

    builder.addCase(autoThunks.update_professor_course.pending, (state) => {
      state.is_loading_course = true;
    });
    builder.addCase(autoThunks.update_professor_course.fulfilled, (state, action) => {
      state.is_loading_course = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.update_professor_course.rejected, (state, action) => {
      state.is_loading_course = false;
    });

    builder.addCase(autoThunks.add_publish_course_job.pending, (state) => {
      state.is_loading_course = true;
    });
    builder.addCase(autoThunks.add_publish_course_job.fulfilled, (state, action) => {
      state.is_loading_course = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.add_publish_course_job.rejected, (state, action) => {
      state.is_loading_course = false;
    });

    builder.addCase(autoThunks.update_course_as_professor.pending, (state) => {
      state.is_loading_course = true;
    });
    builder.addCase(autoThunks.update_course_as_professor.fulfilled, (state, action) => {
      state.is_loading_course = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.update_course_as_professor.rejected, (state, action) => {
      state.is_loading_course = false;
    });

    builder.addCase(autoThunks.get_partner_professor_courses.pending, (state) => {
      state.is_loading_course = true;
    });
    builder.addCase(autoThunks.get_partner_professor_courses.fulfilled, (state, action) => {
      state.is_loading_course = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_partner_professor_courses.rejected, (state, action) => {
      state.is_loading_course = false;
    });

    builder.addCase(autoThunks.make_course_paid.pending, (state) => {
      state.is_loading_course = true;
    });
    builder.addCase(autoThunks.make_course_paid.fulfilled, (state, action) => {
      state.is_loading_course = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.make_course_paid.rejected, (state, action) => {
      state.is_loading_course = false;
    });

    builder.addCase(autoThunks.connect_course_taker_to_paid.pending, (state) => {
      state.is_loading_course = true;
    });
    builder.addCase(autoThunks.connect_course_taker_to_paid.fulfilled, (state, action) => {
      state.is_loading_course = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.connect_course_taker_to_paid.rejected, (state, action) => {
      state.is_loading_course = false;
    });

    builder.addCase(autoThunks.get_professor_courses.pending, (state) => {
      state.is_loading_course = true;
    });
    builder.addCase(autoThunks.get_professor_courses.fulfilled, (state, action) => {
      state.is_loading_course = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_professor_courses.rejected, (state, action) => {
      state.is_loading_course = false;
    });

    builder.addCase(autoThunks.get_my_courses.pending, (state) => {
      state.is_loading_course = true;
    });
    builder.addCase(autoThunks.get_my_courses.fulfilled, (state, action) => {
      state.is_loading_course = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_my_courses.rejected, (state, action) => {
      state.is_loading_course = false;
    });

    builder.addCase(autoThunks.get_available_courses.pending, (state) => {
      state.is_loading_course = true;
    });
    builder.addCase(autoThunks.get_available_courses.fulfilled, (state, action) => {
      state.is_loading_course = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_available_courses.rejected, (state, action) => {
      state.is_loading_course = false;
    });

    builder.addCase(autoThunks.update_course_as_director.pending, (state) => {
      state.is_loading_course = true;
    });
    builder.addCase(autoThunks.update_course_as_director.fulfilled, (state, action) => {
      state.is_loading_course = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.update_course_as_director.rejected, (state, action) => {
      state.is_loading_course = false;
    });
  }
});

function handleResult(action: any, state: courseSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_course = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.course = merge(state.course, payload) as any;
    }
  }
}

export const { clearStatecourse, setSelectedcourse, set_course, mergecourse, add_course } = courseSlice.actions;

export const get_course =
  (id?: string) =>
  (state: {
    course: {
      course: Course[];
    };
  }): Course | null => {
    let temp = state?.course?.course?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.course?.course[0] || null;
  };
export const get_selected_course = (state: { course: courseSliceState }): Course | null => {
  let id = state?.course?.selected_course;
  let temp = state?.course?.course?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_course = (state: {
  course: {
    course: Course[];
  };
}): Course[] => {
  let temp = state?.course?.course;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_course_busy = (state: { course: courseSliceState }) => {
  return state?.course.is_deleting_course || state?.course.is_loading_course || state?.course.is_creating_course;
};
export const is_deleting_course_busy = (state: { course: courseSliceState }) => {
  return state?.course.is_deleting_course;
};
export const is_loading_course_busy = (state: { course: courseSliceState }) => {
  return state?.course.is_loading_course;
};
export const is_creating_course_busy = (state: { course: courseSliceState }) => {
  return state?.course.is_creating_course;
};

export default courseSlice.reducer;
