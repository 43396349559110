import { createAsyncThunk } from "@reduxjs/toolkit";
import Director from "./director-model";
import { delete_director, load_directors, store_director } from "./director-service";

export const LoadDirectorsAsync = createAsyncThunk<any, any>("composer/load/director", async (params: { uid: string }) => {
  return await load_directors(params);
});

export const CreateDirectorAsync = createAsyncThunk<any, any>("composer/create/director", async (params: Director) => {
  return await store_director(params);
});

export const DeleteDirectorAsync = createAsyncThunk<any, any>("composer/delete/director", async (params) => {
  return await delete_director(params);
});
