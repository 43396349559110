import MoneySupply from "./money-supply-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateMoneySupplyAsync, DeleteMoneySupplyAsync, LoadMoneySupplysAsync } from "./moneySupply-thunk";
const initialState: moneySupplySliceState = {
  moneySupply: [],
  selected_moneySupply: "",
  is_loading_moneySupply: false,
  is_creating_moneySupply: false,
  is_deleting_moneySupply: false
};
export interface moneySupplySliceState {
  moneySupply: MoneySupply[];
  selected_moneySupply?: string;
  is_loading_moneySupply: boolean;
  is_creating_moneySupply: boolean;
  is_deleting_moneySupply: boolean;
}
export const moneySupplySlice = createSlice({
  name: "moneySupply",
  initialState,
  reducers: {
    setSelectedmoneySupply: (state, action) => {
      state.selected_moneySupply = action.payload;
    },
    clearStatemoneySupply: (state, action) => {
      state.moneySupply = [];
      state.is_loading_moneySupply = false;
      state.is_creating_moneySupply = false;
      state.is_deleting_moneySupply = false;
    },
    mergemoneySupply: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_moneySupply = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.moneySupply = merge(state.moneySupply, payload) as any;
        }
      }
    },

    add_moneySupply: (state, action) => {
      if (action.payload) {
        state.moneySupply = [...state.moneySupply, action.payload];
      }
    },
    set_moneySupply: (state, action) => {
      let { key, value, id } = action.payload;
      state.moneySupply = state.moneySupply.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadMoneySupplysAsync.pending, (state) => {
      state.is_loading_moneySupply = true;
    });
    builder.addCase(LoadMoneySupplysAsync.fulfilled, (state, action) => {
      state.is_loading_moneySupply = false;
      if (action.payload) {
        state.moneySupply = action.payload;
      }
    });
    builder.addCase(LoadMoneySupplysAsync.rejected, (state, action) => {
      state.is_loading_moneySupply = false;
    });
    builder.addCase(CreateMoneySupplyAsync.pending, (state) => {
      state.is_creating_moneySupply = true;
    });
    builder.addCase(CreateMoneySupplyAsync.fulfilled, (state) => {
      state.is_creating_moneySupply = false;
    });
    builder.addCase(CreateMoneySupplyAsync.rejected, (state) => {
      state.is_creating_moneySupply = false;
    });
    builder.addCase(DeleteMoneySupplyAsync.pending, (state) => {
      state.is_deleting_moneySupply = true;
    });
    builder.addCase(DeleteMoneySupplyAsync.fulfilled, (state, action) => {
      state.is_deleting_moneySupply = false;
      if (state.moneySupply) {
        state.moneySupply = state.moneySupply.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteMoneySupplyAsync.rejected, (state, action) => {
      state.is_deleting_moneySupply = false;
    });

    builder.addCase(autoThunks.get_bank_money_supply.pending, (state) => {
      state.is_loading_moneySupply = true;
    });
    builder.addCase(autoThunks.get_bank_money_supply.fulfilled, (state, action) => {
      state.is_loading_moneySupply = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_bank_money_supply.rejected, (state, action) => {
      state.is_loading_moneySupply = false;
    });
  }
});

function handleResult(action: any, state: moneySupplySliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_moneySupply = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.moneySupply = merge(state.moneySupply, payload) as any;
    }
  }
}

export const { clearStatemoneySupply, setSelectedmoneySupply, set_moneySupply, mergemoneySupply, add_moneySupply } = moneySupplySlice.actions;

export const get_moneySupply =
  (id?: string) =>
  (state: {
    moneySupply: {
      moneySupply: MoneySupply[];
    };
  }): MoneySupply | null => {
    let temp = state?.moneySupply?.moneySupply?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.moneySupply?.moneySupply[0] || null;
  };
export const get_selected_moneySupply = (state: { moneySupply: moneySupplySliceState }): MoneySupply | null => {
  let id = state?.moneySupply?.selected_moneySupply;
  let temp = state?.moneySupply?.moneySupply?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_moneySupply = (state: {
  moneySupply: {
    moneySupply: MoneySupply[];
  };
}): MoneySupply[] => {
  let temp = state?.moneySupply?.moneySupply;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_moneySupply_busy = (state: { moneySupply: moneySupplySliceState }) => {
  return state?.moneySupply.is_deleting_moneySupply || state?.moneySupply.is_loading_moneySupply || state?.moneySupply.is_creating_moneySupply;
};
export const is_deleting_moneySupply_busy = (state: { moneySupply: moneySupplySliceState }) => {
  return state?.moneySupply.is_deleting_moneySupply;
};
export const is_loading_moneySupply_busy = (state: { moneySupply: moneySupplySliceState }) => {
  return state?.moneySupply.is_loading_moneySupply;
};
export const is_creating_moneySupply_busy = (state: { moneySupply: moneySupplySliceState }) => {
  return state?.moneySupply.is_creating_moneySupply;
};

export default moneySupplySlice.reducer;
