import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import Stock from "./stock-model";
import { StockProperties, StockMetaProperties } from "./stock-property-definitions";

export async function load_stocks(args: { uid: string }): Promise<Stock[]> {
  throw "Not implemented";
}

export async function delete_stock(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_stock(args: Stock): Promise<Stock> {
  throw "Not implemented";
}
