import BusinessLoan from "./business-loan-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateBusinessLoanAsync, DeleteBusinessLoanAsync, LoadBusinessLoansAsync } from "./businessLoan-thunk";
const initialState: businessLoanSliceState = {
  businessLoan: [],
  selected_businessLoan: "",
  is_loading_businessLoan: false,
  is_creating_businessLoan: false,
  is_deleting_businessLoan: false
};
export interface businessLoanSliceState {
  businessLoan: BusinessLoan[];
  selected_businessLoan?: string;
  is_loading_businessLoan: boolean;
  is_creating_businessLoan: boolean;
  is_deleting_businessLoan: boolean;
}
export const businessLoanSlice = createSlice({
  name: "businessLoan",
  initialState,
  reducers: {
    setSelectedbusinessLoan: (state, action) => {
      state.selected_businessLoan = action.payload;
    },
    clearStatebusinessLoan: (state, action) => {
      state.businessLoan = [];
      state.is_loading_businessLoan = false;
      state.is_creating_businessLoan = false;
      state.is_deleting_businessLoan = false;
    },
    mergebusinessLoan: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_businessLoan = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.businessLoan = merge(state.businessLoan, payload) as any;
        }
      }
    },

    add_businessLoan: (state, action) => {
      if (action.payload) {
        state.businessLoan = [...state.businessLoan, action.payload];
      }
    },
    set_businessLoan: (state, action) => {
      let { key, value, id } = action.payload;
      state.businessLoan = state.businessLoan.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadBusinessLoansAsync.pending, (state) => {
      state.is_loading_businessLoan = true;
    });
    builder.addCase(LoadBusinessLoansAsync.fulfilled, (state, action) => {
      state.is_loading_businessLoan = false;
      if (action.payload) {
        state.businessLoan = action.payload;
      }
    });
    builder.addCase(LoadBusinessLoansAsync.rejected, (state, action) => {
      state.is_loading_businessLoan = false;
    });
    builder.addCase(CreateBusinessLoanAsync.pending, (state) => {
      state.is_creating_businessLoan = true;
    });
    builder.addCase(CreateBusinessLoanAsync.fulfilled, (state) => {
      state.is_creating_businessLoan = false;
    });
    builder.addCase(CreateBusinessLoanAsync.rejected, (state) => {
      state.is_creating_businessLoan = false;
    });
    builder.addCase(DeleteBusinessLoanAsync.pending, (state) => {
      state.is_deleting_businessLoan = true;
    });
    builder.addCase(DeleteBusinessLoanAsync.fulfilled, (state, action) => {
      state.is_deleting_businessLoan = false;
      if (state.businessLoan) {
        state.businessLoan = state.businessLoan.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteBusinessLoanAsync.rejected, (state, action) => {
      state.is_deleting_businessLoan = false;
    });
  }
});

function handleResult(action: any, state: businessLoanSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_businessLoan = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.businessLoan = merge(state.businessLoan, payload) as any;
    }
  }
}

export const { clearStatebusinessLoan, setSelectedbusinessLoan, set_businessLoan, mergebusinessLoan, add_businessLoan } = businessLoanSlice.actions;

export const get_businessLoan =
  (id?: string) =>
  (state: {
    businessLoan: {
      businessLoan: BusinessLoan[];
    };
  }): BusinessLoan | null => {
    let temp = state?.businessLoan?.businessLoan?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.businessLoan?.businessLoan[0] || null;
  };
export const get_selected_businessLoan = (state: { businessLoan: businessLoanSliceState }): BusinessLoan | null => {
  let id = state?.businessLoan?.selected_businessLoan;
  let temp = state?.businessLoan?.businessLoan?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_businessLoan = (state: {
  businessLoan: {
    businessLoan: BusinessLoan[];
  };
}): BusinessLoan[] => {
  let temp = state?.businessLoan?.businessLoan;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_businessLoan_busy = (state: { businessLoan: businessLoanSliceState }) => {
  return state?.businessLoan.is_deleting_businessLoan || state?.businessLoan.is_loading_businessLoan || state?.businessLoan.is_creating_businessLoan;
};
export const is_deleting_businessLoan_busy = (state: { businessLoan: businessLoanSliceState }) => {
  return state?.businessLoan.is_deleting_businessLoan;
};
export const is_loading_businessLoan_busy = (state: { businessLoan: businessLoanSliceState }) => {
  return state?.businessLoan.is_loading_businessLoan;
};
export const is_creating_businessLoan_busy = (state: { businessLoan: businessLoanSliceState }) => {
  return state?.businessLoan.is_creating_businessLoan;
};

export default businessLoanSlice.reducer;
