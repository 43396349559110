import Sponsor from "./sponsor-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateSponsorAsync, DeleteSponsorAsync, LoadSponsorsAsync } from "./sponsor-thunk";
const initialState: sponsorSliceState = {
  sponsor: [],
  selected_sponsor: "",
  is_loading_sponsor: false,
  is_creating_sponsor: false,
  is_deleting_sponsor: false
};
export interface sponsorSliceState {
  sponsor: Sponsor[];
  selected_sponsor?: string;
  is_loading_sponsor: boolean;
  is_creating_sponsor: boolean;
  is_deleting_sponsor: boolean;
}
export const sponsorSlice = createSlice({
  name: "sponsor",
  initialState,
  reducers: {
    setSelectedsponsor: (state, action) => {
      state.selected_sponsor = action.payload;
    },
    clearStatesponsor: (state, action) => {
      state.sponsor = [];
      state.is_loading_sponsor = false;
      state.is_creating_sponsor = false;
      state.is_deleting_sponsor = false;
    },
    mergesponsor: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_sponsor = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.sponsor = merge(state.sponsor, payload) as any;
        }
      }
    },

    add_sponsor: (state, action) => {
      if (action.payload) {
        state.sponsor = [...state.sponsor, action.payload];
      }
    },
    set_sponsor: (state, action) => {
      let { key, value, id } = action.payload;
      state.sponsor = state.sponsor.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadSponsorsAsync.pending, (state) => {
      state.is_loading_sponsor = true;
    });
    builder.addCase(LoadSponsorsAsync.fulfilled, (state, action) => {
      state.is_loading_sponsor = false;
      if (action.payload) {
        state.sponsor = action.payload;
      }
    });
    builder.addCase(LoadSponsorsAsync.rejected, (state, action) => {
      state.is_loading_sponsor = false;
    });
    builder.addCase(CreateSponsorAsync.pending, (state) => {
      state.is_creating_sponsor = true;
    });
    builder.addCase(CreateSponsorAsync.fulfilled, (state) => {
      state.is_creating_sponsor = false;
    });
    builder.addCase(CreateSponsorAsync.rejected, (state) => {
      state.is_creating_sponsor = false;
    });
    builder.addCase(DeleteSponsorAsync.pending, (state) => {
      state.is_deleting_sponsor = true;
    });
    builder.addCase(DeleteSponsorAsync.fulfilled, (state, action) => {
      state.is_deleting_sponsor = false;
      if (state.sponsor) {
        state.sponsor = state.sponsor.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteSponsorAsync.rejected, (state, action) => {
      state.is_deleting_sponsor = false;
    });
  }
});

function handleResult(action: any, state: sponsorSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_sponsor = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.sponsor = merge(state.sponsor, payload) as any;
    }
  }
}

export const { clearStatesponsor, setSelectedsponsor, set_sponsor, mergesponsor, add_sponsor } = sponsorSlice.actions;

export const get_sponsor =
  (id?: string) =>
  (state: {
    sponsor: {
      sponsor: Sponsor[];
    };
  }): Sponsor | null => {
    let temp = state?.sponsor?.sponsor?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.sponsor?.sponsor[0] || null;
  };
export const get_selected_sponsor = (state: { sponsor: sponsorSliceState }): Sponsor | null => {
  let id = state?.sponsor?.selected_sponsor;
  let temp = state?.sponsor?.sponsor?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_sponsor = (state: {
  sponsor: {
    sponsor: Sponsor[];
  };
}): Sponsor[] => {
  let temp = state?.sponsor?.sponsor;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_sponsor_busy = (state: { sponsor: sponsorSliceState }) => {
  return state?.sponsor.is_deleting_sponsor || state?.sponsor.is_loading_sponsor || state?.sponsor.is_creating_sponsor;
};
export const is_deleting_sponsor_busy = (state: { sponsor: sponsorSliceState }) => {
  return state?.sponsor.is_deleting_sponsor;
};
export const is_loading_sponsor_busy = (state: { sponsor: sponsorSliceState }) => {
  return state?.sponsor.is_loading_sponsor;
};
export const is_creating_sponsor_busy = (state: { sponsor: sponsorSliceState }) => {
  return state?.sponsor.is_creating_sponsor;
};

export default sponsorSlice.reducer;
