import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResolveUrl, UpdateClaims } from "features/firebase-app";
import {
  SendEmailRequest,
  User,
  AccountLogProperties,
  SendEmailRequestProperties,
  BankAccountJoinRequestProperties,
  QuickConnectProperties,
  InvestmentAccountProperties,
  ClaimsCacheProperties,
  UserProperties,
  AccountProperties,
  BankSettings,
  BankSettingsProperties,
  BankProperties,
  QuickConnect,
  Account,
  BankOwner,
  Bank,
  BankRegistry,
  BankRegistryProperties,
  VirtualBusiness,
  VirtualBusinessProperties,
  BusinessPlan,
  BusinessPlanProperties,
  BusinessPlanEvaluation,
  BusinessPlanEvaluationProperties,
  AccountLogCommand,
  AccountLogCommandProperties
} from "models";
import { getBearerToken } from "../../application/application-slice";

export const send_connect_email_to_email = createAsyncThunk<any, any, any>("auto/send/connect/email/to/email", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/send/connect/email/to/email"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const retrieve_connecting_email = createAsyncThunk<any, any, any>("auto/retrieve/connecting/email", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/retrieve/connecting/email"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const update_bank_interest_rate = createAsyncThunk<any, any, any>("auto/update/bank/interest/rate", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/update/bank/interest/rate"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const update_bank_attendance_wage = createAsyncThunk<any, any, any>("auto/update/bank/attendance/wage", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/update/bank/attendance/wage"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const update_bank_test_wage = createAsyncThunk<any, any, any>("auto/update/bank/test/wage", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/update/bank/test/wage"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const update_bank_assignment_wage = createAsyncThunk<any, any, any>("auto/update/bank/assignment/wage", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/update/bank/assignment/wage"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_bank_settings = createAsyncThunk<any, any, any>("auto/get/bank/settings", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/bank/settings"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const update_bank_property_value = createAsyncThunk<any, any, any>("auto/update/bank/property/value", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/update/bank/property/value"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const generate_quick_connects = createAsyncThunk<any, any, any>("auto/generate/quick/connects", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/generate/quick/connects"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const send_welcome_email = createAsyncThunk<any, any, any>("auto/send/welcome/email", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/send/welcome/email"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const send_connect_email = createAsyncThunk<any, any, any>("auto/send/connect/email", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/send/connect/email"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const set_account_email = createAsyncThunk<any, any, any>("auto/set/account/email", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/set/account/email"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const set_bank_account_email = createAsyncThunk<any, any, any>("auto/set/bank/account/email", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/set/bank/account/email"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_bank_settings_for_teacher = createAsyncThunk<any, any, any>(
  "auto/get/bank/settings/for/teacher",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/get/bank/settings/for/teacher"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_bank_owners = createAsyncThunk<any, any, any>("auto/get/bank/owners", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/bank/owners"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_banks_for_bank_owner = createAsyncThunk<any, any, any>("auto/get/banks/for/bank/owners", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/banks/for/bank/owners"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_bank_registry_for_teacher = createAsyncThunk<any, any, any>(
  "auto/get/bank/registry/for/teacher",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/get/bank/registry/for/teacher"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_bank_accounts_for_teacher = createAsyncThunk<any, any, any>(
  "auto/get/bank/accounts/for/teacher",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/get/bank/accounts/for/teacher"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_bank_owner_users = createAsyncThunk<any, any, any>("auto/get/bank/owner/users", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/bank/owner/users"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_virtual_businesses_for_banks = createAsyncThunk<any, any, any>(
  "auto/get/virtual/businesses/for/banks",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/get/virtual/businesses/for/banks"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_virtual_businessplan_for_banks = createAsyncThunk<any, any, any>(
  "auto/get/virtual/businessplan/for/banks",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/get/virtual/businessplan/for/banks"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_virtual_businessplan_evaluations_for_banks = createAsyncThunk<any, any, any>(
  "auto/get/virtual/businessplan/evaluations/for/banks",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/get/virtual/businessplan/evaluations/for/banks"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_account_log_clearing_for_account_id = createAsyncThunk<any, any, any>(
  "auto/get/account/log/clearing/for/account",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/get/account/log/clearing/for/account"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const set_account_log_clearing_for_account_id = createAsyncThunk<any, any, any>(
  "auto/set/account/log/clearing/for/account",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/set/account/log/clearing/for/account"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const send_connect_email_to_bank_accounts = createAsyncThunk<any, any, any>(
  "auto/send/connect/email/to/bank/accounts",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/send/connect/email/to/bank/accounts"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const send_welcome_email_to_bank_accounts = createAsyncThunk<any, any, any>(
  "auto/send/welcome/email/to/bank/account",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/send/welcome/email/to/bank/account"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const update_bank_owner = createAsyncThunk<any, any, any>("auto/update/bank/owner", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/update/bank/owner"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const test_live_channel = createAsyncThunk<any, any, any>("auto/test/live/channel", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/test/live/channel"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});
