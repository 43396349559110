import TitleServiceTitles from "./title-service-titles";
const titleContext = {
  language: "en"
};
export function setApplicationTitleLanguage(lang: string) {
  titleContext.language = lang;
}
export function getApplicationTitleLangue() {
  return titleContext.language;
}

const titleServiceContext = {
  texts: []
};
try {
  let temp = localStorage.getItem("title-service-context");
  if (temp) {
    titleServiceContext.texts = JSON.parse(temp);
  }
} catch (e) {
  console.error(e);
}
export function getInternationTitleLanguage() {
  let lang = getApplicationTitleLangue();
  let temp = ["hi-IN", "id-ID", "it-IT", "ja-JP", "ko-KR", "nl-NL", "pl-PL", "pt-BR", "ru-RU", "zh-CN", "zh-HK", "zh-TW"];
  switch (lang) {
    case "en":
      return "en-US";
    case "de":
      return "de-DE";
    case "fr":
      return "fr-FR";
    case "es":
      return "es-ES";
  }
  return null;
}
export default function TitleService(text: string, category: string = "default"): string {
  let language = titleContext.language || "en";
  if (titleServiceContext.texts.indexOf(text) === -1) {
    titleServiceContext.texts.push(text);
    (window as any).titleServiceContext = titleServiceContext;
    localStorage.setItem("title-service-context", JSON.stringify(titleServiceContext.texts));
  }
  let title = TitleServiceTitles(text, category, language);
  if (!title) {
    return text;
  }
  return title;

  // switch(category) {
  //     default:
  //         switch (text) {
  //         default:
  //             return text;
  //         }
  //     break;
  // }
}
export interface FireTime {
  nanoseconds: number;
  seconds: number;
}

export function getDate(firstDate: FireTime | string): Date {
  let date = typeof firstDate === "string" ? new Date(firstDate) : new Date((firstDate.seconds || (firstDate as any)._seconds) * 1000);
  return date;
}
export function getTimeSince(firstDate: FireTime | string): string {
  return timeSince(getDate(firstDate));
}
export function timeSince(date: Date) {
  var seconds = Math.floor(((new Date(Date.now()) as any) - (date as any)) / 1000);
  if (isNaN(seconds)) {
    seconds = 0;
    return TitleService("just now");
  }
  seconds = Math.abs(seconds);
  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " " + TitleService("years");
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}
export function GetTimeSince(obj) {
  let temp = obj.created ? new Date((obj.updated ? obj.updated?._seconds : obj.created ? obj.created?._seconds : Date.now() / 1000) * 1000) : false;
  if (temp) return timeSince(temp);
}
