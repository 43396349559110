import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import PublishCourseJob from "./publish-course-job-model";
import { PublishCourseJobProperties, PublishCourseJobMetaProperties } from "./publishCourseJob-property-definitions";

export async function load_publishCourseJobs(args: { uid: string }): Promise<PublishCourseJob[]> {
  throw "Not implemented";
}

export async function delete_publishCourseJob(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_publishCourseJob(args: PublishCourseJob): Promise<PublishCourseJob> {
  throw "Not implemented";
}
