import { createAsyncThunk } from "@reduxjs/toolkit";
import CourseTakerSettings from "./course-taker-settings-model";
import { delete_courseTakerSettings, load_courseTakerSettingss, store_courseTakerSettings } from "./courseTakerSettings-service";

export const LoadCourseTakerSettingssAsync = createAsyncThunk<any, any>("composer/load/courseTakerSettings", async (params: { uid: string }) => {
  return await load_courseTakerSettingss(params);
});

export const CreateCourseTakerSettingsAsync = createAsyncThunk<any, any>("composer/create/courseTakerSettings", async (params: CourseTakerSettings) => {
  return await store_courseTakerSettings(params);
});

export const DeleteCourseTakerSettingsAsync = createAsyncThunk<any, any>("composer/delete/courseTakerSettings", async (params) => {
  return await delete_courseTakerSettings(params);
});
