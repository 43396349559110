import { createAsyncThunk } from "@reduxjs/toolkit";
import CourseRecord from "./course-record-model";
import { delete_courseRecord, load_courseRecords, store_courseRecord } from "./courseRecord-service";

export const LoadCourseRecordsAsync = createAsyncThunk<any, any>("composer/load/courseRecord", async (params: { uid: string }) => {
  return await load_courseRecords(params);
});

export const CreateCourseRecordAsync = createAsyncThunk<any, any>("composer/create/courseRecord", async (params: CourseRecord) => {
  return await store_courseRecord(params);
});

export const DeleteCourseRecordAsync = createAsyncThunk<any, any>("composer/delete/courseRecord", async (params) => {
  return await delete_courseRecord(params);
});
