import ImageResource from "./image-resource-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateImageResourceAsync, DeleteImageResourceAsync, LoadImageResourcesAsync } from "./imageResource-thunk";
const initialState: imageResourceSliceState = {
  imageResource: [],
  selected_imageResource: "",
  is_loading_imageResource: false,
  is_creating_imageResource: false,
  is_deleting_imageResource: false
};
export interface imageResourceSliceState {
  imageResource: ImageResource[];
  selected_imageResource?: string;
  is_loading_imageResource: boolean;
  is_creating_imageResource: boolean;
  is_deleting_imageResource: boolean;
}
export const imageResourceSlice = createSlice({
  name: "imageResource",
  initialState,
  reducers: {
    setSelectedimageResource: (state, action) => {
      state.selected_imageResource = action.payload;
    },
    clearStateimageResource: (state, action) => {
      state.imageResource = [];
      state.is_loading_imageResource = false;
      state.is_creating_imageResource = false;
      state.is_deleting_imageResource = false;
    },
    mergeimageResource: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_imageResource = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.imageResource = merge(state.imageResource, payload) as any;
        }
      }
    },

    add_imageResource: (state, action) => {
      if (action.payload) {
        state.imageResource = [...state.imageResource, action.payload];
      }
    },
    set_imageResource: (state, action) => {
      let { key, value, id } = action.payload;
      state.imageResource = state.imageResource.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadImageResourcesAsync.pending, (state) => {
      state.is_loading_imageResource = true;
    });
    builder.addCase(LoadImageResourcesAsync.fulfilled, (state, action) => {
      state.is_loading_imageResource = false;
      if (action.payload) {
        state.imageResource = action.payload;
      }
    });
    builder.addCase(LoadImageResourcesAsync.rejected, (state, action) => {
      state.is_loading_imageResource = false;
    });
    builder.addCase(CreateImageResourceAsync.pending, (state) => {
      state.is_creating_imageResource = true;
    });
    builder.addCase(CreateImageResourceAsync.fulfilled, (state) => {
      state.is_creating_imageResource = false;
    });
    builder.addCase(CreateImageResourceAsync.rejected, (state) => {
      state.is_creating_imageResource = false;
    });
    builder.addCase(DeleteImageResourceAsync.pending, (state) => {
      state.is_deleting_imageResource = true;
    });
    builder.addCase(DeleteImageResourceAsync.fulfilled, (state, action) => {
      state.is_deleting_imageResource = false;
      if (state.imageResource) {
        state.imageResource = state.imageResource.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteImageResourceAsync.rejected, (state, action) => {
      state.is_deleting_imageResource = false;
    });

    builder.addCase(autoThunks.get_image_resources_for_mobile.pending, (state) => {
      state.is_loading_imageResource = true;
    });
    builder.addCase(autoThunks.get_image_resources_for_mobile.fulfilled, (state, action) => {
      state.is_loading_imageResource = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_image_resources_for_mobile.rejected, (state, action) => {
      state.is_loading_imageResource = false;
    });

    builder.addCase(autoThunks.get_image_resources_for_bank.pending, (state) => {
      state.is_loading_imageResource = true;
    });
    builder.addCase(autoThunks.get_image_resources_for_bank.fulfilled, (state, action) => {
      state.is_loading_imageResource = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_image_resources_for_bank.rejected, (state, action) => {
      state.is_loading_imageResource = false;
    });

    builder.addCase(autoThunks.load_image_assets.pending, (state) => {
      state.is_loading_imageResource = true;
    });
    builder.addCase(autoThunks.load_image_assets.fulfilled, (state, action) => {
      state.is_loading_imageResource = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.load_image_assets.rejected, (state, action) => {
      state.is_loading_imageResource = false;
    });

    builder.addCase(autoThunks.save_image_asset.pending, (state) => {
      state.is_loading_imageResource = true;
    });
    builder.addCase(autoThunks.save_image_asset.fulfilled, (state, action) => {
      state.is_loading_imageResource = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.save_image_asset.rejected, (state, action) => {
      state.is_loading_imageResource = false;
    });
  }
});

function handleResult(action: any, state: imageResourceSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_imageResource = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.imageResource = merge(state.imageResource, payload) as any;
    }
  }
}

export const { clearStateimageResource, setSelectedimageResource, set_imageResource, mergeimageResource, add_imageResource } = imageResourceSlice.actions;

export const get_imageResource =
  (id?: string) =>
  (state: {
    imageResource: {
      imageResource: ImageResource[];
    };
  }): ImageResource | null => {
    let temp = state?.imageResource?.imageResource?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.imageResource?.imageResource[0] || null;
  };
export const get_selected_imageResource = (state: { imageResource: imageResourceSliceState }): ImageResource | null => {
  let id = state?.imageResource?.selected_imageResource;
  let temp = state?.imageResource?.imageResource?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_imageResource = (state: {
  imageResource: {
    imageResource: ImageResource[];
  };
}): ImageResource[] => {
  let temp = state?.imageResource?.imageResource;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_imageResource_busy = (state: { imageResource: imageResourceSliceState }) => {
  return state?.imageResource.is_deleting_imageResource || state?.imageResource.is_loading_imageResource || state?.imageResource.is_creating_imageResource;
};
export const is_deleting_imageResource_busy = (state: { imageResource: imageResourceSliceState }) => {
  return state?.imageResource.is_deleting_imageResource;
};
export const is_loading_imageResource_busy = (state: { imageResource: imageResourceSliceState }) => {
  return state?.imageResource.is_loading_imageResource;
};
export const is_creating_imageResource_busy = (state: { imageResource: imageResourceSliceState }) => {
  return state?.imageResource.is_creating_imageResource;
};

export default imageResourceSlice.reducer;
