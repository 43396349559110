import { createAsyncThunk, createNextState, createSlice } from "@reduxjs/toolkit";
const initialState = {
  signedIn: false,
  checkedSignIn: false,
  uid: null,
  profileImage: null,
  profileImageName: null,
  profileName: null,
  token: null,
  initialized: false
};

function getProfileImage(data) {
  return data?.user?.photoURL || "";
}

function getAuthToken(data) {
  return data?.token;
}
function getClaims(data) {
  return data?.claims;
}
function getUid(data) {
  return data?.user?.uid;
}

function getDisplayName(data) {
  return data?.user?.displayName || "";
}

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setAuthenticationInfo: (state, action) => {
      state.signedIn = action.payload.signedIn;
      state.userProfileImage = getProfileImage(action.payload);
      state.uid = getUid(action.payload);
      state.token = getAuthToken(action.payload);
      state.claims = getClaims(action.payload);
      state.displayName = getDisplayName(action.payload);
      state.initialized = true;
    },
    setProfileImage: (state, action) => {
      state.profileImage = action.payload;
    },
    setProfileImageName: (state, action) => {
      state.profileImageName = action.payload;
    },
    setProfileName: (state, action) => {
      state.profileName = action.payload;
    },
    setLogin: (state, action) => {},
    setCheckedSignedIn: (state, action) => {
      state.checkedSignIn = true;
    }
  },
  extraReducers: (builder) => {
    console.log("extra reducers");
  }
});

export const { setProfileImage, setProfileImageName, setProfileName, setAuthenticationInfo, setCheckedSignedIn, setLogin } = applicationSlice.actions;

export const getUserSignedIn = (state) => {
  return state?.application?.signedIn;
};
export const getBearerToken = (state) => {
  return state?.application?.token;
};
export const getToken = (state) => {
  return state?.application?.token;
};
export const getUserClaims = (state) => {
  return state?.application?.claims;
};
export const getUserSignedInId = (state) => {
  return state?.application?.uid;
};
export const getUserProfileImage = (state) => {
  return state?.application?.profileImage || state?.application?.userProfileImage;
};
export const getUserProfileImageName = (state) => {
  return state?.application?.profileImageName || state?.application?.userProfileImageName;
};
export const getUserName = (state) => {
  return state?.application?.profileName || state?.application?.displayName;
};
export const hasCheckedSignIn = (state) => {
  return state?.application?.checkedSignIn;
};
export const getIsInitialized = (state) => {
  return state?.application?.initialized;
};
export default applicationSlice.reducer;
