// ViewContext.tsx
import React, { createContext, useState, useContext, Dispatch, useEffect } from 'react';
import { dispatch } from 'store';
import { add_my_enrollments, get_course_section_records, get_my_courses, get_my_settings, get_my_testTaker, get_published_courses, set_course_section_records, update_my_settings, update_xp } from 'features/auto-thunks';
import { useSelector } from 'react-redux';
import { get_all_publishedCourse } from 'features/publishedCourse/publishedCourse-slice';
import { Course, CourseQuestionLog, CourseSectionRecord, CourseTakerSettings, PublishedCourse } from 'models';
import { get_all_courseSectionRecord } from 'features/courseSectionRecord/courseSectionRecord-slice';
import { Logger, QuestionData, QuestionResult, QuestionResults, groupBy } from 'interfaces';
import { push } from 'firebase/database';
import { result } from 'lodash';
import { getUserClaims } from 'features/application/application-slice';
import { get_all_courseTakerSettings } from 'features/courseTakerSettings/courseTakerSettings-slice';
import useCourseDocument from './useCourseDocument';
import { get_all_course } from 'features/course/course-slice';
import { CourseTakerSettingKeys } from 'features/enumerations';
import { useNavigate } from 'react-router';
import useURLParameters from './useURLParameters';
import useAsyncEffect from './useAsyncEffect';
export enum Views {
    Questions = "Questions",
    Home = "Home"
}
interface ViewContextValue {
    setQuestions(arg0: { subTopics: string, questions: QuestionData[]; }): unknown;
    currentView: Views;
    setCourse: (args: string) => void,
    points: number;
    loading: boolean;
    questions: QuestionData[],
    course: string,
    courseQualities: CourseQualities | null,
    eduResults: QuestionResult[],
    courseTakerSettings: CourseTakerSettings,
    sectionRecords: CourseSectionRecord[],
    setCurrentView: Dispatch<Views>;
    push: (view: Views) => void,
    pop: () => void,
    addCourse: (course: string) => void,
    removeCourse: (course: string) => void,
    setNextContext: (context: NextContext) => void,
    topics: string[],
    context: NextContext,
    categorized: { [topic: string]: { [subTopics: string]: QuestionData[] } },
    storeResults: (args: QuestionResults) => Promise<void>
}

const ViewContext = React.createContext<ViewContextValue | any>(undefined);

interface ViewProviderProps {
    children: React.ReactNode;
}
export interface CourseQualities {
    name: string;
    logo: string
}
export interface NextContext {
    view: Views,
    subTopics: string,
    courseSection: string,
    questions: QuestionData[]
}

export const ViewProvider: React.FC<ViewProviderProps> = ({ children }) => {
    const [currentView, setCurrentView] = useState<Views>(Views.Home);
    const [course, setCourse] = useState<string | null>(null);
    const [courseQualities, setCourseQualities] = useState<CourseQualities | null>(null);
    const [courses, setCourses] = useState<string[]>([]);
    const [stack, setState] = useState<Views[]>([]);
    const [eduResults, setEduResults] = useState<QuestionResult[]>([]);
    const all_section_records = useSelector(get_all_courseSectionRecord);
    const [questions, setQuestions] = useState<QuestionData[]>([]);
    const [points, setPoints] = useState(0);
    const [subTopic, setSubtopic] = useState('');
    const all_published_courses = useSelector(get_all_publishedCourse);
    const claims = useSelector(getUserClaims);
    const [loading, setLoading] = useState(true);
    const [pollForCourse, setPollForCourse] = useState(null);
    const [updatePollingForCourse, setUpdatePolloingForCourse] = useState(0);
    const course_all_section_records = all_section_records.filter(x => x.course === course);
    const navigate = useNavigate();
    const all_settings = useSelector(get_all_courseTakerSettings);
    const my_setting = all_settings.find((setting) => setting?.courseTaker === claims?.courseTaker);
    const all_courses = useSelector(get_all_course);
    const current_course = all_courses.find(x => x.id === my_setting?.data[CourseTakerSettingKeys.CurrentCourse]);
    const [topics, categorized, loadingCourse] = useCourseDocument(current_course);
    const [nextContext, setNextContext] = useState<NextContext>(null);
    const params: any = useURLParameters();
    console.log(params);
    useEffect(() => {
        dispatch(get_my_settings({
            userId: claims?.user,
            courseTaker: claims?.courseTaker
        }));
        dispatch(get_my_courses({
            userId: claims?.user,
            courseTaker: claims?.courseTaker
        }))
    }, [claims?.courseTaker]);
    useAsyncEffect(async () => {
        if (pollForCourse) {
            await dispatch(get_my_courses({
                userId: claims?.user,
                courseTaker: claims?.courseTaker,
                callback: async (success, res) => {
                    let course = (res?.data || []).find((x: Course) => x.coursePay === params.i);
                    if (course) {
                        setPollForCourse(null);
                        await dispatch(update_my_settings({
                            userId: claims?.user,
                            courseTaker: claims?.courseTaker,
                            key: CourseTakerSettingKeys.CurrentCourse,
                            value: course.id
                        }))
                        navigate('/start', { replace: true });
                    }
                    else {
                        setTimeout(() => {
                            setUpdatePolloingForCourse(Date.now())
                        }, 10000)
                    }
                }
            }))
        }
    }, [pollForCourse, claims?.courseTaker, updatePollingForCourse])
    useEffect(() => {
        setCourse(current_course?.id || null);
    }, [current_course?.id])
    useEffect(() => {
        if (nextContext) {
            navigate('/learn');
        }
        else {
            if (location.pathname.includes('/success')) {
                console.log(params);
                if (params.i && params.c) {
                    setPollForCourse(params.i);

                }
            }
            else if (location.pathname.includes('/cancel')) {

            }
            else {
                navigate('/start', { replace: true });
            }
        }
    }, [nextContext, params?.i, params?.c])

    useEffect(() => {
        if (my_setting?.version && claims?.courseTaker) {
        }
    }, [my_setting?.version, claims?.courseTaker]);

    useEffect(() => {
        if (claims && claims?.user && !claims?.courseTaker) {
            // Logger.log('getting course taker')
            // Logger.log(JSON.stringify(claims, null, 3))
            dispatch(get_my_testTaker({
                userId: claims?.user
            }));
        }
        else if (claims && claims?.user && claims?.courseTaker) {
            // Logger.log('getting published courses')
            dispatch(get_published_courses({
                userId: claims?.user,
                courseTaker: claims?.courseTaker,
                callback: async (success: boolean, data: any) => {
                    if (success && data?.data) {
                        //  await  AsyncStorage.setItem(PUBLISHED_COURSES, JSON.stringify(data.data));
                        setLoading(false);
                    }
                }
            }));
        }
        else {
            // Logger.log(JSON.stringify(claims, null, 3))
        }
    }, [claims?.courseTaker]);
    useEffect(() => {
        all_published_courses.forEach((course) => {
        });
    }, [JSON.stringify(all_published_courses)]);
    useEffect(() => {
        if (claims?.courseTaker) {
            try {
                Promise.resolve().then(async () => {

                    if (course) {
                        try {

                            console.log('get_course_section_records: ' + claims?.courseTaker);
                            await dispatch(get_course_section_records({
                                courseTaker: claims?.courseTaker,
                                courseId: course,
                                userId: claims?.user
                            }));
                        } catch (e) {
                            console.error('failed to get results');
                        }
                    }
                });
            } catch (e) {
            }
        }
        return () => {
        };
    }, [course, claims?.courseTaker]);
    useEffect(() => {
        if (stack.length && currentView !== stack[stack.length - 1]) {
            setState([...stack, currentView]);
        }
        else {
            setState([currentView]);
        }
    }, [currentView]);


    return (
        <ViewContext.Provider value={{
            currentView,
            questions,
            eduResults,
            subTopic,
            course: course || '',
            points,
            courseTakerSettings: my_setting,
            topics,
            loading: loading || loadingCourse,
            context: nextContext,
            setNextContext: (context: NextContext) => {
                setNextContext(context);
            },
            sectionRecords: course_all_section_records,
            courses,
            courseQualities,
            categorized,
            setCurrentView,
            addCourse: async (course: Course) => {
                await dispatch(add_my_enrollments({
                    courseTaker: claims?.courseTaker,
                    courseId: course?.id,
                    userId: claims?.user
                }));
            },
            removeCourse: async (course: Course) => {

            },
            setCourse: async (args) => {
                setCourse(args);
            },
            setQuestions: (args) => {
                setQuestions(args.questions);
                setSubtopic(args.subTopics)
            },
            storeResults: async (args) => {
                Logger.log('storing results')
                let eduResults: QuestionResult[] = [];
                let courseSection = '';
                let flaggedQuestions = [];
                let logs = args.results.map((qResult) => {
                    courseSection = qResult?.sectionId || courseSection;
                    if (qResult.flagged) {
                        flaggedQuestions.push(qResult.id);
                    }
                    let previousResult = eduResults.find(x => x.id === qResult.id && x.difficulty === qResult.difficulty);
                    if (previousResult) {
                        previousResult.attempts = previousResult.attempts || 0;
                        previousResult.attempts++;
                        previousResult.correct = previousResult.correct || 0;
                        previousResult.correct += qResult.correct || 0;
                        previousResult.date = Date.now();
                    }
                    else {
                        eduResults.push({
                            ...qResult,
                            correct: qResult.correct || 0,
                            attempts: qResult.correct || 1,
                            date: Date.now()
                        })
                    }

                    let log: CourseQuestionLog = {
                        attempts: qResult.attempts || 0,
                        correct: qResult.correct || 0,
                        lastAttempt: qResult.date,
                        questionId: qResult.id,
                        flagged: false
                    };

                    return log;
                });

                let courseSectionRecord: CourseSectionRecord = {
                    course: course || '',
                    courseSection,
                    courseTaker: claims?.courseTaker,
                    questionLogs: logs,
                    flagged: false
                }
                if (!courseSection) { throw 'no courseSection' }
                console.log(courseSectionRecord);
                await dispatch(set_course_section_records({
                    userId: claims?.user,
                    courseTaker: claims.courseTaker,
                    courseId: course,
                    courseSectionId: courseSection,
                    courseSectionRecord,
                    flagged: flaggedQuestions
                }));
                await dispatch(update_xp({
                    userId: claims?.user,
                    courseTaker: claims.courseTaker,
                }))
                setEduResults(eduResults);
            },
            pop: () => {
                let new_stack = [...stack];
                let off = new_stack.pop();
                if (new_stack[new_stack.length - 1]) {
                    setCurrentView(new_stack[new_stack.length - 1]);
                    setState(new_stack);
                }
                else {
                    Logger.log('nothing to show');
                }
            },
            push: (view) => {
                setCurrentView(view);
            }
        }}>
            {children}
        </ViewContext.Provider>
    );
};

export const useView = (): ViewContextValue => {
    const context = useContext(ViewContext);
    if (context === undefined) {
        throw new Error('useView must be used within a ViewProvider');
    }
    return context;
};


export function getLogoUrl(url: string) {
    return `${url ? `${location.origin}/${url}` : ''}`
}

export function getPublishedCourseKey(publishedCourse: PublishedCourse) {
    return `pckey-${publishedCourse.id}`
}