import { createAsyncThunk } from "@reduxjs/toolkit";
import VirtualBusinessPartners from "./virtual-business-partners-model";
import { delete_virtualBusinessPartners, load_virtualBusinessPartnerss, store_virtualBusinessPartners } from "./virtualBusinessPartners-service";

export const LoadVirtualBusinessPartnerssAsync = createAsyncThunk<any, any>("composer/load/virtualBusinessPartners", async (params: { uid: string }) => {
  return await load_virtualBusinessPartnerss(params);
});

export const CreateVirtualBusinessPartnersAsync = createAsyncThunk<any, any>(
  "composer/create/virtualBusinessPartners",
  async (params: VirtualBusinessPartners) => {
    return await store_virtualBusinessPartners(params);
  }
);

export const DeleteVirtualBusinessPartnersAsync = createAsyncThunk<any, any>("composer/delete/virtualBusinessPartners", async (params) => {
  return await delete_virtualBusinessPartners(params);
});
