import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import CourseLoad from "./course-load-model";
import { CourseLoadProperties, CourseLoadMetaProperties } from "./courseLoad-property-definitions";

export async function load_courseLoads(args: { uid: string }): Promise<CourseLoad[]> {
  throw "Not implemented";
}

export async function delete_courseLoad(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_courseLoad(args: CourseLoad): Promise<CourseLoad> {
  throw "Not implemented";
}
