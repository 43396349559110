import MoneySuployHistory from "./money-suploy-history-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateMoneySuployHistoryAsync, DeleteMoneySuployHistoryAsync, LoadMoneySuployHistorysAsync } from "./moneySuployHistory-thunk";
const initialState: moneySuployHistorySliceState = {
  moneySuployHistory: [],
  selected_moneySuployHistory: "",
  is_loading_moneySuployHistory: false,
  is_creating_moneySuployHistory: false,
  is_deleting_moneySuployHistory: false
};
export interface moneySuployHistorySliceState {
  moneySuployHistory: MoneySuployHistory[];
  selected_moneySuployHistory?: string;
  is_loading_moneySuployHistory: boolean;
  is_creating_moneySuployHistory: boolean;
  is_deleting_moneySuployHistory: boolean;
}
export const moneySuployHistorySlice = createSlice({
  name: "moneySuployHistory",
  initialState,
  reducers: {
    setSelectedmoneySuployHistory: (state, action) => {
      state.selected_moneySuployHistory = action.payload;
    },
    clearStatemoneySuployHistory: (state, action) => {
      state.moneySuployHistory = [];
      state.is_loading_moneySuployHistory = false;
      state.is_creating_moneySuployHistory = false;
      state.is_deleting_moneySuployHistory = false;
    },
    mergemoneySuployHistory: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_moneySuployHistory = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.moneySuployHistory = merge(state.moneySuployHistory, payload) as any;
        }
      }
    },

    add_moneySuployHistory: (state, action) => {
      if (action.payload) {
        state.moneySuployHistory = [...state.moneySuployHistory, action.payload];
      }
    },
    set_moneySuployHistory: (state, action) => {
      let { key, value, id } = action.payload;
      state.moneySuployHistory = state.moneySuployHistory.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadMoneySuployHistorysAsync.pending, (state) => {
      state.is_loading_moneySuployHistory = true;
    });
    builder.addCase(LoadMoneySuployHistorysAsync.fulfilled, (state, action) => {
      state.is_loading_moneySuployHistory = false;
      if (action.payload) {
        state.moneySuployHistory = action.payload;
      }
    });
    builder.addCase(LoadMoneySuployHistorysAsync.rejected, (state, action) => {
      state.is_loading_moneySuployHistory = false;
    });
    builder.addCase(CreateMoneySuployHistoryAsync.pending, (state) => {
      state.is_creating_moneySuployHistory = true;
    });
    builder.addCase(CreateMoneySuployHistoryAsync.fulfilled, (state) => {
      state.is_creating_moneySuployHistory = false;
    });
    builder.addCase(CreateMoneySuployHistoryAsync.rejected, (state) => {
      state.is_creating_moneySuployHistory = false;
    });
    builder.addCase(DeleteMoneySuployHistoryAsync.pending, (state) => {
      state.is_deleting_moneySuployHistory = true;
    });
    builder.addCase(DeleteMoneySuployHistoryAsync.fulfilled, (state, action) => {
      state.is_deleting_moneySuployHistory = false;
      if (state.moneySuployHistory) {
        state.moneySuployHistory = state.moneySuployHistory.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteMoneySuployHistoryAsync.rejected, (state, action) => {
      state.is_deleting_moneySuployHistory = false;
    });
  }
});

function handleResult(action: any, state: moneySuployHistorySliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_moneySuployHistory = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.moneySuployHistory = merge(state.moneySuployHistory, payload) as any;
    }
  }
}

export const { clearStatemoneySuployHistory, setSelectedmoneySuployHistory, set_moneySuployHistory, mergemoneySuployHistory, add_moneySuployHistory } =
  moneySuployHistorySlice.actions;

export const get_moneySuployHistory =
  (id?: string) =>
  (state: {
    moneySuployHistory: {
      moneySuployHistory: MoneySuployHistory[];
    };
  }): MoneySuployHistory | null => {
    let temp = state?.moneySuployHistory?.moneySuployHistory?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.moneySuployHistory?.moneySuployHistory[0] || null;
  };
export const get_selected_moneySuployHistory = (state: { moneySuployHistory: moneySuployHistorySliceState }): MoneySuployHistory | null => {
  let id = state?.moneySuployHistory?.selected_moneySuployHistory;
  let temp = state?.moneySuployHistory?.moneySuployHistory?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_moneySuployHistory = (state: {
  moneySuployHistory: {
    moneySuployHistory: MoneySuployHistory[];
  };
}): MoneySuployHistory[] => {
  let temp = state?.moneySuployHistory?.moneySuployHistory;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_moneySuployHistory_busy = (state: { moneySuployHistory: moneySuployHistorySliceState }) => {
  return (
    state?.moneySuployHistory.is_deleting_moneySuployHistory ||
    state?.moneySuployHistory.is_loading_moneySuployHistory ||
    state?.moneySuployHistory.is_creating_moneySuployHistory
  );
};
export const is_deleting_moneySuployHistory_busy = (state: { moneySuployHistory: moneySuployHistorySliceState }) => {
  return state?.moneySuployHistory.is_deleting_moneySuployHistory;
};
export const is_loading_moneySuployHistory_busy = (state: { moneySuployHistory: moneySuployHistorySliceState }) => {
  return state?.moneySuployHistory.is_loading_moneySuployHistory;
};
export const is_creating_moneySuployHistory_busy = (state: { moneySuployHistory: moneySuployHistorySliceState }) => {
  return state?.moneySuployHistory.is_creating_moneySuployHistory;
};

export default moneySuployHistorySlice.reducer;
