import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import BusinessLoan from "./business-loan-model";
import { BusinessLoanProperties, BusinessLoanMetaProperties } from "./businessLoan-property-definitions";

export async function load_businessLoans(args: { uid: string }): Promise<BusinessLoan[]> {
  throw "Not implemented";
}

export async function delete_businessLoan(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_businessLoan(args: BusinessLoan): Promise<BusinessLoan> {
  throw "Not implemented";
}
