import VirtualBusinessPartners from "./virtual-business-partners-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateVirtualBusinessPartnersAsync, DeleteVirtualBusinessPartnersAsync, LoadVirtualBusinessPartnerssAsync } from "./virtualBusinessPartners-thunk";
const initialState: virtualBusinessPartnersSliceState = {
  virtualBusinessPartners: [],
  selected_virtualBusinessPartners: "",
  is_loading_virtualBusinessPartners: false,
  is_creating_virtualBusinessPartners: false,
  is_deleting_virtualBusinessPartners: false
};
export interface virtualBusinessPartnersSliceState {
  virtualBusinessPartners: VirtualBusinessPartners[];
  selected_virtualBusinessPartners?: string;
  is_loading_virtualBusinessPartners: boolean;
  is_creating_virtualBusinessPartners: boolean;
  is_deleting_virtualBusinessPartners: boolean;
}
export const virtualBusinessPartnersSlice = createSlice({
  name: "virtualBusinessPartners",
  initialState,
  reducers: {
    setSelectedvirtualBusinessPartners: (state, action) => {
      state.selected_virtualBusinessPartners = action.payload;
    },
    clearStatevirtualBusinessPartners: (state, action) => {
      state.virtualBusinessPartners = [];
      state.is_loading_virtualBusinessPartners = false;
      state.is_creating_virtualBusinessPartners = false;
      state.is_deleting_virtualBusinessPartners = false;
    },
    mergevirtualBusinessPartners: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_virtualBusinessPartners = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.virtualBusinessPartners = merge(state.virtualBusinessPartners, payload) as any;
        }
      }
    },

    add_virtualBusinessPartners: (state, action) => {
      if (action.payload) {
        state.virtualBusinessPartners = [...state.virtualBusinessPartners, action.payload];
      }
    },
    set_virtualBusinessPartners: (state, action) => {
      let { key, value, id } = action.payload;
      state.virtualBusinessPartners = state.virtualBusinessPartners.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadVirtualBusinessPartnerssAsync.pending, (state) => {
      state.is_loading_virtualBusinessPartners = true;
    });
    builder.addCase(LoadVirtualBusinessPartnerssAsync.fulfilled, (state, action) => {
      state.is_loading_virtualBusinessPartners = false;
      if (action.payload) {
        state.virtualBusinessPartners = action.payload;
      }
    });
    builder.addCase(LoadVirtualBusinessPartnerssAsync.rejected, (state, action) => {
      state.is_loading_virtualBusinessPartners = false;
    });
    builder.addCase(CreateVirtualBusinessPartnersAsync.pending, (state) => {
      state.is_creating_virtualBusinessPartners = true;
    });
    builder.addCase(CreateVirtualBusinessPartnersAsync.fulfilled, (state) => {
      state.is_creating_virtualBusinessPartners = false;
    });
    builder.addCase(CreateVirtualBusinessPartnersAsync.rejected, (state) => {
      state.is_creating_virtualBusinessPartners = false;
    });
    builder.addCase(DeleteVirtualBusinessPartnersAsync.pending, (state) => {
      state.is_deleting_virtualBusinessPartners = true;
    });
    builder.addCase(DeleteVirtualBusinessPartnersAsync.fulfilled, (state, action) => {
      state.is_deleting_virtualBusinessPartners = false;
      if (state.virtualBusinessPartners) {
        state.virtualBusinessPartners = state.virtualBusinessPartners.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteVirtualBusinessPartnersAsync.rejected, (state, action) => {
      state.is_deleting_virtualBusinessPartners = false;
    });
  }
});

function handleResult(action: any, state: virtualBusinessPartnersSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_virtualBusinessPartners = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.virtualBusinessPartners = merge(state.virtualBusinessPartners, payload) as any;
    }
  }
}

export const {
  clearStatevirtualBusinessPartners,
  setSelectedvirtualBusinessPartners,
  set_virtualBusinessPartners,
  mergevirtualBusinessPartners,
  add_virtualBusinessPartners
} = virtualBusinessPartnersSlice.actions;

export const get_virtualBusinessPartners =
  (id?: string) =>
  (state: {
    virtualBusinessPartners: {
      virtualBusinessPartners: VirtualBusinessPartners[];
    };
  }): VirtualBusinessPartners | null => {
    let temp = state?.virtualBusinessPartners?.virtualBusinessPartners?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.virtualBusinessPartners?.virtualBusinessPartners[0] || null;
  };
export const get_selected_virtualBusinessPartners = (state: { virtualBusinessPartners: virtualBusinessPartnersSliceState }): VirtualBusinessPartners | null => {
  let id = state?.virtualBusinessPartners?.selected_virtualBusinessPartners;
  let temp = state?.virtualBusinessPartners?.virtualBusinessPartners?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_virtualBusinessPartners = (state: {
  virtualBusinessPartners: {
    virtualBusinessPartners: VirtualBusinessPartners[];
  };
}): VirtualBusinessPartners[] => {
  let temp = state?.virtualBusinessPartners?.virtualBusinessPartners;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_virtualBusinessPartners_busy = (state: { virtualBusinessPartners: virtualBusinessPartnersSliceState }) => {
  return (
    state?.virtualBusinessPartners.is_deleting_virtualBusinessPartners ||
    state?.virtualBusinessPartners.is_loading_virtualBusinessPartners ||
    state?.virtualBusinessPartners.is_creating_virtualBusinessPartners
  );
};
export const is_deleting_virtualBusinessPartners_busy = (state: { virtualBusinessPartners: virtualBusinessPartnersSliceState }) => {
  return state?.virtualBusinessPartners.is_deleting_virtualBusinessPartners;
};
export const is_loading_virtualBusinessPartners_busy = (state: { virtualBusinessPartners: virtualBusinessPartnersSliceState }) => {
  return state?.virtualBusinessPartners.is_loading_virtualBusinessPartners;
};
export const is_creating_virtualBusinessPartners_busy = (state: { virtualBusinessPartners: virtualBusinessPartnersSliceState }) => {
  return state?.virtualBusinessPartners.is_creating_virtualBusinessPartners;
};

export default virtualBusinessPartnersSlice.reducer;
