import { createAsyncThunk } from "@reduxjs/toolkit";
import VirtualBusinessInvestorReference from "./virtual-business-investor-reference-model";
import {
  delete_virtualBusinessInvestorReference,
  load_virtualBusinessInvestorReferences,
  store_virtualBusinessInvestorReference
} from "./virtualBusinessInvestorReference-service";

export const LoadVirtualBusinessInvestorReferencesAsync = createAsyncThunk<any, any>(
  "composer/load/virtualBusinessInvestorReference",
  async (params: { uid: string }) => {
    return await load_virtualBusinessInvestorReferences(params);
  }
);

export const CreateVirtualBusinessInvestorReferenceAsync = createAsyncThunk<any, any>(
  "composer/create/virtualBusinessInvestorReference",
  async (params: VirtualBusinessInvestorReference) => {
    return await store_virtualBusinessInvestorReference(params);
  }
);

export const DeleteVirtualBusinessInvestorReferenceAsync = createAsyncThunk<any, any>("composer/delete/virtualBusinessInvestorReference", async (params) => {
  return await delete_virtualBusinessInvestorReference(params);
});
