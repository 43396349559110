import Currency from "./currency-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateCurrencyAsync, DeleteCurrencyAsync, LoadCurrencysAsync } from "./currency-thunk";
const initialState: currencySliceState = {
  currency: [],
  selected_currency: "",
  is_loading_currency: false,
  is_creating_currency: false,
  is_deleting_currency: false
};
export interface currencySliceState {
  currency: Currency[];
  selected_currency?: string;
  is_loading_currency: boolean;
  is_creating_currency: boolean;
  is_deleting_currency: boolean;
}
export const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    setSelectedcurrency: (state, action) => {
      state.selected_currency = action.payload;
    },
    clearStatecurrency: (state, action) => {
      state.currency = [];
      state.is_loading_currency = false;
      state.is_creating_currency = false;
      state.is_deleting_currency = false;
    },
    mergecurrency: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_currency = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.currency = merge(state.currency, payload) as any;
        }
      }
    },

    add_currency: (state, action) => {
      if (action.payload) {
        state.currency = [...state.currency, action.payload];
      }
    },
    set_currency: (state, action) => {
      let { key, value, id } = action.payload;
      state.currency = state.currency.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadCurrencysAsync.pending, (state) => {
      state.is_loading_currency = true;
    });
    builder.addCase(LoadCurrencysAsync.fulfilled, (state, action) => {
      state.is_loading_currency = false;
      if (action.payload) {
        state.currency = action.payload;
      }
    });
    builder.addCase(LoadCurrencysAsync.rejected, (state, action) => {
      state.is_loading_currency = false;
    });
    builder.addCase(CreateCurrencyAsync.pending, (state) => {
      state.is_creating_currency = true;
    });
    builder.addCase(CreateCurrencyAsync.fulfilled, (state) => {
      state.is_creating_currency = false;
    });
    builder.addCase(CreateCurrencyAsync.rejected, (state) => {
      state.is_creating_currency = false;
    });
    builder.addCase(DeleteCurrencyAsync.pending, (state) => {
      state.is_deleting_currency = true;
    });
    builder.addCase(DeleteCurrencyAsync.fulfilled, (state, action) => {
      state.is_deleting_currency = false;
      if (state.currency) {
        state.currency = state.currency.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteCurrencyAsync.rejected, (state, action) => {
      state.is_deleting_currency = false;
    });
  }
});

function handleResult(action: any, state: currencySliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_currency = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.currency = merge(state.currency, payload) as any;
    }
  }
}

export const { clearStatecurrency, setSelectedcurrency, set_currency, mergecurrency, add_currency } = currencySlice.actions;

export const get_currency =
  (id?: string) =>
  (state: {
    currency: {
      currency: Currency[];
    };
  }): Currency | null => {
    let temp = state?.currency?.currency?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.currency?.currency[0] || null;
  };
export const get_selected_currency = (state: { currency: currencySliceState }): Currency | null => {
  let id = state?.currency?.selected_currency;
  let temp = state?.currency?.currency?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_currency = (state: {
  currency: {
    currency: Currency[];
  };
}): Currency[] => {
  let temp = state?.currency?.currency;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_currency_busy = (state: { currency: currencySliceState }) => {
  return state?.currency.is_deleting_currency || state?.currency.is_loading_currency || state?.currency.is_creating_currency;
};
export const is_deleting_currency_busy = (state: { currency: currencySliceState }) => {
  return state?.currency.is_deleting_currency;
};
export const is_loading_currency_busy = (state: { currency: currencySliceState }) => {
  return state?.currency.is_loading_currency;
};
export const is_creating_currency_busy = (state: { currency: currencySliceState }) => {
  return state?.currency.is_creating_currency;
};

export default currencySlice.reducer;
