import { createAsyncThunk } from "@reduxjs/toolkit";
import InvestmentRequest from "./investment-request-model";
import { delete_investmentRequest, load_investmentRequests, store_investmentRequest } from "./investmentRequest-service";

export const LoadInvestmentRequestsAsync = createAsyncThunk<any, any>("composer/load/investmentRequest", async (params: { uid: string }) => {
  return await load_investmentRequests(params);
});

export const CreateInvestmentRequestAsync = createAsyncThunk<any, any>("composer/create/investmentRequest", async (params: InvestmentRequest) => {
  return await store_investmentRequest(params);
});

export const DeleteInvestmentRequestAsync = createAsyncThunk<any, any>("composer/delete/investmentRequest", async (params) => {
  return await delete_investmentRequest(params);
});
