import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import BusinessPlanSubmission from "./business-plan-submission-model";
import { BusinessPlanSubmissionProperties, BusinessPlanSubmissionMetaProperties } from "./businessPlanSubmission-property-definitions";

export async function load_businessPlanSubmissions(args: { uid: string }): Promise<BusinessPlanSubmission[]> {
  throw "Not implemented";
}

export async function delete_businessPlanSubmission(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_businessPlanSubmission(args: BusinessPlanSubmission): Promise<BusinessPlanSubmission> {
  throw "Not implemented";
}
