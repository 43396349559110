import { createAsyncThunk } from "@reduxjs/toolkit";
import BankBusiness from "./bank-business-model";
import { delete_bankBusiness, load_bankBusinesss, store_bankBusiness } from "./bankBusiness-service";

export const LoadBankBusinesssAsync = createAsyncThunk<any, any>("composer/load/bankBusiness", async (params: { uid: string }) => {
  return await load_bankBusinesss(params);
});

export const CreateBankBusinessAsync = createAsyncThunk<any, any>("composer/create/bankBusiness", async (params: BankBusiness) => {
  return await store_bankBusiness(params);
});

export const DeleteBankBusinessAsync = createAsyncThunk<any, any>("composer/delete/bankBusiness", async (params) => {
  return await delete_bankBusiness(params);
});
