import { createAsyncThunk } from "@reduxjs/toolkit";
import ImageResourceStats from "./image-resource-stats-model";
import { delete_imageResourceStats, load_imageResourceStatss, store_imageResourceStats } from "./imageResourceStats-service";

export const LoadImageResourceStatssAsync = createAsyncThunk<any, any>("composer/load/imageResourceStats", async (params: { uid: string }) => {
  return await load_imageResourceStatss(params);
});

export const CreateImageResourceStatsAsync = createAsyncThunk<any, any>("composer/create/imageResourceStats", async (params: ImageResourceStats) => {
  return await store_imageResourceStats(params);
});

export const DeleteImageResourceStatsAsync = createAsyncThunk<any, any>("composer/delete/imageResourceStats", async (params) => {
  return await delete_imageResourceStats(params);
});
