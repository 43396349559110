import { useEffect, useState } from "react";
import { hash } from "./util";
import { Course } from "models";
import useLogo from "hooks/useLogo";

export default function TileLogo(props: { style?: any, course: Course }) {
    const logo = useLogo(props.course);

    return (
        <img
            alt="BTC"
            style={props.style}
            src={logo}
        />
    )
}