import { createAsyncThunk } from "@reduxjs/toolkit";
import User from "./user-model";
import { delete_user, load_users, store_user } from "./user-service";

export const LoadUsersAsync = createAsyncThunk<any, any>("composer/load/user", async (params: { uid: string }) => {
  return await load_users(params);
});

export const CreateUserAsync = createAsyncThunk<any, any>("composer/create/user", async (params: User) => {
  return await store_user(params);
});

export const DeleteUserAsync = createAsyncThunk<any, any>("composer/delete/user", async (params) => {
  return await delete_user(params);
});
