import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import BankerRole from "./banker-role-model";
import { BankerRoleProperties, BankerRoleMetaProperties } from "./bankerRole-property-definitions";

export async function load_bankerRoles(args: { uid: string }): Promise<BankerRole[]> {
  throw "Not implemented";
}

export async function delete_bankerRole(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_bankerRole(args: BankerRole): Promise<BankerRole> {
  throw "Not implemented";
}
