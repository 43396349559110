import { createAsyncThunk } from "@reduxjs/toolkit";
import VotingBallot from "./voting-ballot-model";
import { delete_votingBallot, load_votingBallots, store_votingBallot } from "./votingBallot-service";

export const LoadVotingBallotsAsync = createAsyncThunk<any, any>("composer/load/votingBallot", async (params: { uid: string }) => {
  return await load_votingBallots(params);
});

export const CreateVotingBallotAsync = createAsyncThunk<any, any>("composer/create/votingBallot", async (params: VotingBallot) => {
  return await store_votingBallot(params);
});

export const DeleteVotingBallotAsync = createAsyncThunk<any, any>("composer/delete/votingBallot", async (params) => {
  return await delete_votingBallot(params);
});
