import { createAsyncThunk } from "@reduxjs/toolkit";
import Transaction from "./transaction-model";
import { delete_transaction, load_transactions, store_transaction } from "./transaction-service";

export const LoadTransactionsAsync = createAsyncThunk<any, any>("composer/load/transaction", async (params: { uid: string }) => {
  return await load_transactions(params);
});

export const CreateTransactionAsync = createAsyncThunk<any, any>("composer/create/transaction", async (params: Transaction) => {
  return await store_transaction(params);
});

export const DeleteTransactionAsync = createAsyncThunk<any, any>("composer/delete/transaction", async (params) => {
  return await delete_transaction(params);
});
