import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import SendEmailRequest from "./send-email-request-model";
import { SendEmailRequestProperties, SendEmailRequestMetaProperties } from "./sendEmailRequest-property-definitions";

export async function load_sendEmailRequests(args: { uid: string }): Promise<SendEmailRequest[]> {
  throw "Not implemented";
}

export async function delete_sendEmailRequest(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_sendEmailRequest(args: SendEmailRequest): Promise<SendEmailRequest> {
  throw "Not implemented";
}
