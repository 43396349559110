import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResolveUrl, UpdateClaims } from "features/firebase-app";
import {
  Course,
  User,
  CourseProperties,
  CourseSection,
  CourseQuestionProperties,
  CourseSectionProperties,
  PublishCourseJob,
  PublishCourseJobProperties,
  CourseTopicSummary,
  CourseTopicSummaryProperties
} from "models";
import { getBearerToken } from "../../application/application-slice";

export const update_course_as_director = createAsyncThunk<any, any, any>("auto/update/course/as/director", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/update/course/as/director"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const create_random_section = createAsyncThunk<any, any, any>("auto/create/random/section/as/director", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/create/random/section/as/director"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const add_view_block_expansion_job = createAsyncThunk<any, any, any>(
  "auto/add/view/block/expansion/job",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/add/view/block/expansion/job"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_course_topic_summaries = createAsyncThunk<any, any, any>("auto/get/course/topic/summaries", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/course/topic/summaries"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});
