import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import MoneySuployHistory from "./money-suploy-history-model";
import { MoneySuployHistoryProperties, MoneySuployHistoryMetaProperties } from "./moneySuployHistory-property-definitions";

export async function load_moneySuployHistorys(args: { uid: string }): Promise<MoneySuployHistory[]> {
  throw "Not implemented";
}

export async function delete_moneySuployHistory(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_moneySuployHistory(args: MoneySuployHistory): Promise<MoneySuployHistory> {
  throw "Not implemented";
}
