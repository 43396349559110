import BankSettings from "./bank-settings-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateBankSettingsAsync, DeleteBankSettingsAsync, LoadBankSettingssAsync } from "./bankSettings-thunk";
const initialState: bankSettingsSliceState = {
  bankSettings: [],
  selected_bankSettings: "",
  is_loading_bankSettings: false,
  is_creating_bankSettings: false,
  is_deleting_bankSettings: false
};
export interface bankSettingsSliceState {
  bankSettings: BankSettings[];
  selected_bankSettings?: string;
  is_loading_bankSettings: boolean;
  is_creating_bankSettings: boolean;
  is_deleting_bankSettings: boolean;
}
export const bankSettingsSlice = createSlice({
  name: "bankSettings",
  initialState,
  reducers: {
    setSelectedbankSettings: (state, action) => {
      state.selected_bankSettings = action.payload;
    },
    clearStatebankSettings: (state, action) => {
      state.bankSettings = [];
      state.is_loading_bankSettings = false;
      state.is_creating_bankSettings = false;
      state.is_deleting_bankSettings = false;
    },
    mergebankSettings: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_bankSettings = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.bankSettings = merge(state.bankSettings, payload) as any;
        }
      }
    },

    add_bankSettings: (state, action) => {
      if (action.payload) {
        state.bankSettings = [...state.bankSettings, action.payload];
      }
    },
    set_bankSettings: (state, action) => {
      let { key, value, id } = action.payload;
      state.bankSettings = state.bankSettings.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadBankSettingssAsync.pending, (state) => {
      state.is_loading_bankSettings = true;
    });
    builder.addCase(LoadBankSettingssAsync.fulfilled, (state, action) => {
      state.is_loading_bankSettings = false;
      if (action.payload) {
        state.bankSettings = action.payload;
      }
    });
    builder.addCase(LoadBankSettingssAsync.rejected, (state, action) => {
      state.is_loading_bankSettings = false;
    });
    builder.addCase(CreateBankSettingsAsync.pending, (state) => {
      state.is_creating_bankSettings = true;
    });
    builder.addCase(CreateBankSettingsAsync.fulfilled, (state) => {
      state.is_creating_bankSettings = false;
    });
    builder.addCase(CreateBankSettingsAsync.rejected, (state) => {
      state.is_creating_bankSettings = false;
    });
    builder.addCase(DeleteBankSettingsAsync.pending, (state) => {
      state.is_deleting_bankSettings = true;
    });
    builder.addCase(DeleteBankSettingsAsync.fulfilled, (state, action) => {
      state.is_deleting_bankSettings = false;
      if (state.bankSettings) {
        state.bankSettings = state.bankSettings.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteBankSettingsAsync.rejected, (state, action) => {
      state.is_deleting_bankSettings = false;
    });

    builder.addCase(autoThunks.get_my_bank_settings.pending, (state) => {
      state.is_loading_bankSettings = true;
    });
    builder.addCase(autoThunks.get_my_bank_settings.fulfilled, (state, action) => {
      state.is_loading_bankSettings = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_my_bank_settings.rejected, (state, action) => {
      state.is_loading_bankSettings = false;
    });

    builder.addCase(autoThunks.update_bank_interest_rate.pending, (state) => {
      state.is_loading_bankSettings = true;
    });
    builder.addCase(autoThunks.update_bank_interest_rate.fulfilled, (state, action) => {
      state.is_loading_bankSettings = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.update_bank_interest_rate.rejected, (state, action) => {
      state.is_loading_bankSettings = false;
    });

    builder.addCase(autoThunks.update_bank_attendance_wage.pending, (state) => {
      state.is_loading_bankSettings = true;
    });
    builder.addCase(autoThunks.update_bank_attendance_wage.fulfilled, (state, action) => {
      state.is_loading_bankSettings = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.update_bank_attendance_wage.rejected, (state, action) => {
      state.is_loading_bankSettings = false;
    });

    builder.addCase(autoThunks.update_bank_test_wage.pending, (state) => {
      state.is_loading_bankSettings = true;
    });
    builder.addCase(autoThunks.update_bank_test_wage.fulfilled, (state, action) => {
      state.is_loading_bankSettings = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.update_bank_test_wage.rejected, (state, action) => {
      state.is_loading_bankSettings = false;
    });

    builder.addCase(autoThunks.update_bank_assignment_wage.pending, (state) => {
      state.is_loading_bankSettings = true;
    });
    builder.addCase(autoThunks.update_bank_assignment_wage.fulfilled, (state, action) => {
      state.is_loading_bankSettings = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.update_bank_assignment_wage.rejected, (state, action) => {
      state.is_loading_bankSettings = false;
    });

    builder.addCase(autoThunks.get_bank_settings.pending, (state) => {
      state.is_loading_bankSettings = true;
    });
    builder.addCase(autoThunks.get_bank_settings.fulfilled, (state, action) => {
      state.is_loading_bankSettings = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_bank_settings.rejected, (state, action) => {
      state.is_loading_bankSettings = false;
    });

    builder.addCase(autoThunks.update_bank_property_value.pending, (state) => {
      state.is_loading_bankSettings = true;
    });
    builder.addCase(autoThunks.update_bank_property_value.fulfilled, (state, action) => {
      state.is_loading_bankSettings = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.update_bank_property_value.rejected, (state, action) => {
      state.is_loading_bankSettings = false;
    });

    builder.addCase(autoThunks.get_bank_settings_for_teacher.pending, (state) => {
      state.is_loading_bankSettings = true;
    });
    builder.addCase(autoThunks.get_bank_settings_for_teacher.fulfilled, (state, action) => {
      state.is_loading_bankSettings = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_bank_settings_for_teacher.rejected, (state, action) => {
      state.is_loading_bankSettings = false;
    });
  }
});

function handleResult(action: any, state: bankSettingsSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_bankSettings = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.bankSettings = merge(state.bankSettings, payload) as any;
    }
  }
}

export const { clearStatebankSettings, setSelectedbankSettings, set_bankSettings, mergebankSettings, add_bankSettings } = bankSettingsSlice.actions;

export const get_bankSettings =
  (id?: string) =>
  (state: {
    bankSettings: {
      bankSettings: BankSettings[];
    };
  }): BankSettings | null => {
    let temp = state?.bankSettings?.bankSettings?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.bankSettings?.bankSettings[0] || null;
  };
export const get_selected_bankSettings = (state: { bankSettings: bankSettingsSliceState }): BankSettings | null => {
  let id = state?.bankSettings?.selected_bankSettings;
  let temp = state?.bankSettings?.bankSettings?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_bankSettings = (state: {
  bankSettings: {
    bankSettings: BankSettings[];
  };
}): BankSettings[] => {
  let temp = state?.bankSettings?.bankSettings;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_bankSettings_busy = (state: { bankSettings: bankSettingsSliceState }) => {
  return state?.bankSettings.is_deleting_bankSettings || state?.bankSettings.is_loading_bankSettings || state?.bankSettings.is_creating_bankSettings;
};
export const is_deleting_bankSettings_busy = (state: { bankSettings: bankSettingsSliceState }) => {
  return state?.bankSettings.is_deleting_bankSettings;
};
export const is_loading_bankSettings_busy = (state: { bankSettings: bankSettingsSliceState }) => {
  return state?.bankSettings.is_loading_bankSettings;
};
export const is_creating_bankSettings_busy = (state: { bankSettings: bankSettingsSliceState }) => {
  return state?.bankSettings.is_creating_bankSettings;
};

export default bankSettingsSlice.reducer;
