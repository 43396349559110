import { createAsyncThunk } from "@reduxjs/toolkit";
import RoomAgent from "./room-agent-model";
import { delete_roomAgent, load_roomAgents, store_roomAgent } from "./roomAgent-service";

export const LoadRoomAgentsAsync = createAsyncThunk<any, any>("composer/load/roomAgent", async (params: { uid: string }) => {
  return await load_roomAgents(params);
});

export const CreateRoomAgentAsync = createAsyncThunk<any, any>("composer/create/roomAgent", async (params: RoomAgent) => {
  return await store_roomAgent(params);
});

export const DeleteRoomAgentAsync = createAsyncThunk<any, any>("composer/delete/roomAgent", async (params) => {
  return await delete_roomAgent(params);
});
