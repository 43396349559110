import { createAsyncThunk } from "@reduxjs/toolkit";
import SendEmailRequest from "./send-email-request-model";
import { delete_sendEmailRequest, load_sendEmailRequests, store_sendEmailRequest } from "./sendEmailRequest-service";

export const LoadSendEmailRequestsAsync = createAsyncThunk<any, any>("composer/load/sendEmailRequest", async (params: { uid: string }) => {
  return await load_sendEmailRequests(params);
});

export const CreateSendEmailRequestAsync = createAsyncThunk<any, any>("composer/create/sendEmailRequest", async (params: SendEmailRequest) => {
  return await store_sendEmailRequest(params);
});

export const DeleteSendEmailRequestAsync = createAsyncThunk<any, any>("composer/delete/sendEmailRequest", async (params) => {
  return await delete_sendEmailRequest(params);
});
