import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import Customer from "./customer-model";
import { CustomerProperties, CustomerMetaProperties } from "./customer-property-definitions";

export async function load_customers(args: { uid: string }): Promise<Customer[]> {
  throw "Not implemented";
}

export async function delete_customer(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_customer(args: Customer): Promise<Customer> {
  throw "Not implemented";
}
