import { createAsyncThunk } from "@reduxjs/toolkit";
import Enrollment from "./enrollment-model";
import { delete_enrollment, load_enrollments, store_enrollment } from "./enrollment-service";

export const LoadEnrollmentsAsync = createAsyncThunk<any, any>("composer/load/enrollment", async (params: { uid: string }) => {
  return await load_enrollments(params);
});

export const CreateEnrollmentAsync = createAsyncThunk<any, any>("composer/create/enrollment", async (params: Enrollment) => {
  return await store_enrollment(params);
});

export const DeleteEnrollmentAsync = createAsyncThunk<any, any>("composer/delete/enrollment", async (params) => {
  return await delete_enrollment(params);
});
