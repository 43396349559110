import { createAsyncThunk } from "@reduxjs/toolkit";
import BusinessPlan from "./business-plan-model";
import { delete_businessPlan, load_businessPlans, store_businessPlan } from "./businessPlan-service";

export const LoadBusinessPlansAsync = createAsyncThunk<any, any>("composer/load/businessPlan", async (params: { uid: string }) => {
  return await load_businessPlans(params);
});

export const CreateBusinessPlanAsync = createAsyncThunk<any, any>("composer/create/businessPlan", async (params: BusinessPlan) => {
  return await store_businessPlan(params);
});

export const DeleteBusinessPlanAsync = createAsyncThunk<any, any>("composer/delete/businessPlan", async (params) => {
  return await delete_businessPlan(params);
});
