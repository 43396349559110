export function uuid() {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}

export function merge(items: { id?: string }[], ritems: { id?: string }[]) {
    let res = items.map((x) => {
        let replacement = ritems.find((v) => {
            return v.id === x.id;
        });
        if (replacement) {
            ritems.splice(ritems.indexOf(replacement), 1);
            return replacement;
        }
        return x;
    });
    res = [...res, ...ritems];
    return res;
}
export function getRandomItems<T>(array: T[], count: number): T[] {
    const shuffled = array.slice(); // Create a copy of the original array
    const result: T[] = [];

    // Fisher-Yates algorithm for shuffling
    for (let i = shuffled.length - 1; i >= 0; i--) {
        const randomIndex = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[randomIndex]] = [shuffled[randomIndex], shuffled[i]];
    }

    // Select the first 'count' items from the shuffled array
    for (let i = 0; i < count; i++) {
        if (i < shuffled.length) {
            result.push(shuffled[i]);
        } else {
            break; // Stop if the number of items requested exceeds the array length
        }
    }

    return result;
}

export const makeCancelable = (promise: any) => {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            val => hasCanceled_ ? reject({ isCanceled: true }) : resolve(val),
            error => hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
};

export function hash(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const charCode = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + charCode;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}



export function centsToDollars(cents: string | number): string {
    const parsedCents = typeof cents === 'string' ? parseFloat(cents) : cents;
    const dollars = parsedCents / 100;
    return dollars.toLocaleString("en-US", { style: "currency", currency: "USD" });
}
