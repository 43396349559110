import { createAsyncThunk } from "@reduxjs/toolkit";
import StockInformation from "./stock-information-model";
import { delete_stockInformation, load_stockInformations, store_stockInformation } from "./stockInformation-service";

export const LoadStockInformationsAsync = createAsyncThunk<any, any>("composer/load/stockInformation", async (params: { uid: string }) => {
  return await load_stockInformations(params);
});

export const CreateStockInformationAsync = createAsyncThunk<any, any>("composer/create/stockInformation", async (params: StockInformation) => {
  return await store_stockInformation(params);
});

export const DeleteStockInformationAsync = createAsyncThunk<any, any>("composer/delete/stockInformation", async (params) => {
  return await delete_stockInformation(params);
});
