import VirtualBusinessPartnerInvitation from "./virtual-business-partner-invitation-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import {
  CreateVirtualBusinessPartnerInvitationAsync,
  DeleteVirtualBusinessPartnerInvitationAsync,
  LoadVirtualBusinessPartnerInvitationsAsync
} from "./virtualBusinessPartnerInvitation-thunk";
const initialState: virtualBusinessPartnerInvitationSliceState = {
  virtualBusinessPartnerInvitation: [],
  selected_virtualBusinessPartnerInvitation: "",
  is_loading_virtualBusinessPartnerInvitation: false,
  is_creating_virtualBusinessPartnerInvitation: false,
  is_deleting_virtualBusinessPartnerInvitation: false
};
export interface virtualBusinessPartnerInvitationSliceState {
  virtualBusinessPartnerInvitation: VirtualBusinessPartnerInvitation[];
  selected_virtualBusinessPartnerInvitation?: string;
  is_loading_virtualBusinessPartnerInvitation: boolean;
  is_creating_virtualBusinessPartnerInvitation: boolean;
  is_deleting_virtualBusinessPartnerInvitation: boolean;
}
export const virtualBusinessPartnerInvitationSlice = createSlice({
  name: "virtualBusinessPartnerInvitation",
  initialState,
  reducers: {
    setSelectedvirtualBusinessPartnerInvitation: (state, action) => {
      state.selected_virtualBusinessPartnerInvitation = action.payload;
    },
    clearStatevirtualBusinessPartnerInvitation: (state, action) => {
      state.virtualBusinessPartnerInvitation = [];
      state.is_loading_virtualBusinessPartnerInvitation = false;
      state.is_creating_virtualBusinessPartnerInvitation = false;
      state.is_deleting_virtualBusinessPartnerInvitation = false;
    },
    mergevirtualBusinessPartnerInvitation: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_virtualBusinessPartnerInvitation = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.virtualBusinessPartnerInvitation = merge(state.virtualBusinessPartnerInvitation, payload) as any;
        }
      }
    },

    add_virtualBusinessPartnerInvitation: (state, action) => {
      if (action.payload) {
        state.virtualBusinessPartnerInvitation = [...state.virtualBusinessPartnerInvitation, action.payload];
      }
    },
    set_virtualBusinessPartnerInvitation: (state, action) => {
      let { key, value, id } = action.payload;
      state.virtualBusinessPartnerInvitation = state.virtualBusinessPartnerInvitation.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadVirtualBusinessPartnerInvitationsAsync.pending, (state) => {
      state.is_loading_virtualBusinessPartnerInvitation = true;
    });
    builder.addCase(LoadVirtualBusinessPartnerInvitationsAsync.fulfilled, (state, action) => {
      state.is_loading_virtualBusinessPartnerInvitation = false;
      if (action.payload) {
        state.virtualBusinessPartnerInvitation = action.payload;
      }
    });
    builder.addCase(LoadVirtualBusinessPartnerInvitationsAsync.rejected, (state, action) => {
      state.is_loading_virtualBusinessPartnerInvitation = false;
    });
    builder.addCase(CreateVirtualBusinessPartnerInvitationAsync.pending, (state) => {
      state.is_creating_virtualBusinessPartnerInvitation = true;
    });
    builder.addCase(CreateVirtualBusinessPartnerInvitationAsync.fulfilled, (state) => {
      state.is_creating_virtualBusinessPartnerInvitation = false;
    });
    builder.addCase(CreateVirtualBusinessPartnerInvitationAsync.rejected, (state) => {
      state.is_creating_virtualBusinessPartnerInvitation = false;
    });
    builder.addCase(DeleteVirtualBusinessPartnerInvitationAsync.pending, (state) => {
      state.is_deleting_virtualBusinessPartnerInvitation = true;
    });
    builder.addCase(DeleteVirtualBusinessPartnerInvitationAsync.fulfilled, (state, action) => {
      state.is_deleting_virtualBusinessPartnerInvitation = false;
      if (state.virtualBusinessPartnerInvitation) {
        state.virtualBusinessPartnerInvitation = state.virtualBusinessPartnerInvitation.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteVirtualBusinessPartnerInvitationAsync.rejected, (state, action) => {
      state.is_deleting_virtualBusinessPartnerInvitation = false;
    });

    builder.addCase(autoThunks.send_partnership_invitation.pending, (state) => {
      state.is_loading_virtualBusinessPartnerInvitation = true;
    });
    builder.addCase(autoThunks.send_partnership_invitation.fulfilled, (state, action) => {
      state.is_loading_virtualBusinessPartnerInvitation = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.send_partnership_invitation.rejected, (state, action) => {
      state.is_loading_virtualBusinessPartnerInvitation = false;
    });

    builder.addCase(autoThunks.respond_to_partnership_invitation.pending, (state) => {
      state.is_loading_virtualBusinessPartnerInvitation = true;
    });
    builder.addCase(autoThunks.respond_to_partnership_invitation.fulfilled, (state, action) => {
      state.is_loading_virtualBusinessPartnerInvitation = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.respond_to_partnership_invitation.rejected, (state, action) => {
      state.is_loading_virtualBusinessPartnerInvitation = false;
    });

    builder.addCase(autoThunks.fetch_partnership_invitations.pending, (state) => {
      state.is_loading_virtualBusinessPartnerInvitation = true;
    });
    builder.addCase(autoThunks.fetch_partnership_invitations.fulfilled, (state, action) => {
      state.is_loading_virtualBusinessPartnerInvitation = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.fetch_partnership_invitations.rejected, (state, action) => {
      state.is_loading_virtualBusinessPartnerInvitation = false;
    });

    builder.addCase(autoThunks.fetch_virtual_business_partnership_invitations.pending, (state) => {
      state.is_loading_virtualBusinessPartnerInvitation = true;
    });
    builder.addCase(autoThunks.fetch_virtual_business_partnership_invitations.fulfilled, (state, action) => {
      state.is_loading_virtualBusinessPartnerInvitation = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.fetch_virtual_business_partnership_invitations.rejected, (state, action) => {
      state.is_loading_virtualBusinessPartnerInvitation = false;
    });

    builder.addCase(autoThunks.leave_partnership.pending, (state) => {
      state.is_loading_virtualBusinessPartnerInvitation = true;
    });
    builder.addCase(autoThunks.leave_partnership.fulfilled, (state, action) => {
      state.is_loading_virtualBusinessPartnerInvitation = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.leave_partnership.rejected, (state, action) => {
      state.is_loading_virtualBusinessPartnerInvitation = false;
    });
  }
});

function handleResult(action: any, state: virtualBusinessPartnerInvitationSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_virtualBusinessPartnerInvitation = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.virtualBusinessPartnerInvitation = merge(state.virtualBusinessPartnerInvitation, payload) as any;
    }
  }
}

export const {
  clearStatevirtualBusinessPartnerInvitation,
  setSelectedvirtualBusinessPartnerInvitation,
  set_virtualBusinessPartnerInvitation,
  mergevirtualBusinessPartnerInvitation,
  add_virtualBusinessPartnerInvitation
} = virtualBusinessPartnerInvitationSlice.actions;

export const get_virtualBusinessPartnerInvitation =
  (id?: string) =>
  (state: {
    virtualBusinessPartnerInvitation: {
      virtualBusinessPartnerInvitation: VirtualBusinessPartnerInvitation[];
    };
  }): VirtualBusinessPartnerInvitation | null => {
    let temp = state?.virtualBusinessPartnerInvitation?.virtualBusinessPartnerInvitation?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.virtualBusinessPartnerInvitation?.virtualBusinessPartnerInvitation[0] || null;
  };
export const get_selected_virtualBusinessPartnerInvitation = (state: {
  virtualBusinessPartnerInvitation: virtualBusinessPartnerInvitationSliceState;
}): VirtualBusinessPartnerInvitation | null => {
  let id = state?.virtualBusinessPartnerInvitation?.selected_virtualBusinessPartnerInvitation;
  let temp = state?.virtualBusinessPartnerInvitation?.virtualBusinessPartnerInvitation?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_virtualBusinessPartnerInvitation = (state: {
  virtualBusinessPartnerInvitation: {
    virtualBusinessPartnerInvitation: VirtualBusinessPartnerInvitation[];
  };
}): VirtualBusinessPartnerInvitation[] => {
  let temp = state?.virtualBusinessPartnerInvitation?.virtualBusinessPartnerInvitation;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_virtualBusinessPartnerInvitation_busy = (state: { virtualBusinessPartnerInvitation: virtualBusinessPartnerInvitationSliceState }) => {
  return (
    state?.virtualBusinessPartnerInvitation.is_deleting_virtualBusinessPartnerInvitation ||
    state?.virtualBusinessPartnerInvitation.is_loading_virtualBusinessPartnerInvitation ||
    state?.virtualBusinessPartnerInvitation.is_creating_virtualBusinessPartnerInvitation
  );
};
export const is_deleting_virtualBusinessPartnerInvitation_busy = (state: { virtualBusinessPartnerInvitation: virtualBusinessPartnerInvitationSliceState }) => {
  return state?.virtualBusinessPartnerInvitation.is_deleting_virtualBusinessPartnerInvitation;
};
export const is_loading_virtualBusinessPartnerInvitation_busy = (state: { virtualBusinessPartnerInvitation: virtualBusinessPartnerInvitationSliceState }) => {
  return state?.virtualBusinessPartnerInvitation.is_loading_virtualBusinessPartnerInvitation;
};
export const is_creating_virtualBusinessPartnerInvitation_busy = (state: { virtualBusinessPartnerInvitation: virtualBusinessPartnerInvitationSliceState }) => {
  return state?.virtualBusinessPartnerInvitation.is_creating_virtualBusinessPartnerInvitation;
};

export default virtualBusinessPartnerInvitationSlice.reducer;
