export enum AccountType {
  Savings = "Savings",
  Checking = "Checking"
}
export enum Views {
  Bank = "Bank",
  Accounts = "Accounts",
  MyAccount = "MyAccount",
  AddBank = "AddBank",
  UpdateBank = "UpdateBank",
  EnrollCustomers = "EnrollCustomers",
  Auction = "Auction",
  BankOwners = "BankOwners",
  DigitalAssetManagement = "DigitalAssetManagement",
  ImageAssetManagement = "ImageAssetManagement",
  PrivacyPolicy = "PrivacyPolicy",
  Contact = "Contact",
  Math = "Math",
  TeacherBanks = "TeacherBanks",
  Player = "Player"
}
export enum BankerPermissions {
  PullMoney = "PullMoney"
}
export enum BerryLayout {
  MainLayout = "MainLayout",
  NetflixLayout = "NetflixLayout",
  MinimalLayout = "MinimalLayout"
}
export enum TransactionType {
  InterestPayment = "InterestPayment",
  AttendancePayment = "AttendancePayment",
  AssignmentPayment = "AssignmentPayment",
  TestPayment = "TestPayment",
  MonthlyRent = "MonthlyRent",
  AuctionWithdrawal = "AuctionWithdrawal",
  StockPurchase = "StockPurchase",
  StockSell = "StockSell",
  FundsTransferToInvestmentAccount = "FundsTransferToInvestmentAccount",
  SalesOrder = "SalesOrder",
  BusinessPlanEvaluation = "BusinessPlanEvaluation",
  VirtualBusinessInvestment = "VirtualBusinessInvestment",
  VirtualBusinessDivestment = "VirtualBusinessDivestment",
  VirtualBusinessTransaction = "VirtualBusinessTransaction",
  BusinessPlanEvaluationReceived = "BusinessPlanEvaluationReceived",
  VirtualBusinessPartnershipInvestment = "VirtualBusinessPartnershipInvestment",
  VirtualBusinessPartnershipInvestmentRefund = "VirtualBusinessPartnershipInvestmentRefund",
  VirtualShareSales = "VirtualShareSales",
  CorrectTriviaResponse = "CorrectTriviaResponse",
  DividendPayment = "DividendPayment",
  Tax = "Tax",
  CoporateTax = "CoporateTax",
  SetBalance = "SetBalance"
}
export enum AuctionState {
  Open = "Open",
  Closed = "Closed",
  Initial = "Initial",
  Processed = "Processed"
}
export enum DigitalAssetTypes {
  InterestRaiser = "InterestRaiser",
  AttendanceBonus = "AttendanceBonus",
  AssignmentBonus = "AssignmentBonus",
  TestBonus = "TestBonus",
  RentDiscount = "RentDiscount",
  VirtualBusinessInvestmentBonus = "VirtualBusinessInvestmentBonus",
  VirtualBusinessDivestmentBonus = "VirtualBusinessDivestmentBonus",
  VirtualBusinessPartnershipInvestmentBonus = "VirtualBusinessPartnershipInvestmentBonus",
  CorporatePositionBonus = "CorporatePositionBonus",
  Tax = "Tax",
  TaxFederal = "TaxFederal",
  TaxState = "TaxState",
  TaxLocal = "TaxLocal",
  CityAsset = "CityAsset"
}
export enum InvestmentOrderState {
  Pending = "Pending",
  Fulfilled = "Fulfilled",
  Canceled = "Canceled",
  Closed = "Closed",
  Processing = "Processing"
}
export enum AuctionType {
  Open = "Open",
  Closed = "Closed",
  OpenAnonymous = "OpenAnonymous"
}
export enum Seasons {
  Summer = "Summer",
  Spring = "Spring",
  Winter = "Winter",
  Fall = "Fall"
}
export enum TargetAgeGroup {
  Children = "Children",
  YoungAdults = "YoungAdults",
  Adults = "Adults",
  SeniorCitizens = "SeniorCitizens"
}
export enum TimeToMarket {
  Immediate = "Immediate",
  OneMonth = "OneMonth",
  ThreeMonths = "ThreeMonths",
  SixMonths = "SixMonths",
  OverAYear = "OverAYear"
}
export enum InvestmentRequestOfferType {
  NoRiskResidual = "NoRiskResidual",
  RiskOwnershipNonVoting = "RiskOwnershipNonVoting"
}
export enum RejectionCauseType {
  InconsistentEvaluation = "InconsistentEvaluation"
}
export enum SalesOrderType {
  DigitalAssetSale = "DigitalAssetSale"
}
export enum ResourceType {
  DigitalAsset = "DigitalAsset",
  Currency = "Currency",
  StartBusiness = "StartBusiness",
  EarnExtra = "EarnExtra",
  UserAvatars = "UserAvatars",
  DigitalAuctionBanner = "DigitalAuctionBanner",
  VirtualBusinessLogo = "VirtualBusinessLogo",
  InterestLogo = "InterestLogo",
  AttendanceLogo = "AttendanceLogo",
  AssignmentLogo = "AssignmentLogo",
  DivestmentLogo = "DivestmentLogo",
  TestLogo = "TestLogo",
  RentLogo = "RentLogo",
  InvestmentLogo = "InvestmentLogo",
  ClosedAuctionLogo = "ClosedAuctionLogo",
  MyBusinessLogo = "MyBusinessLogo",
  TransactionLogo = "TransactionLogo",
  DigitalAssetLogo = "DigitalAssetLogo",
  StartBusinessLogo = "StartBusinessLogo",
  BankLogo = "BankLogo",
  Transactions = "Transactions",
  AuctionLogo = "AuctionLogo",
  PartnerInvitation = "PartnerInvitation",
  AccountantsAvatars = "AccountantsAvatars",
  BusinessPositionAvatars = "BusinessPositionAvatars",
  SharesLogo = "SharesLogo",
  EscrowLogo = "EscrowLogo",
  TemporaryCategory = "TemporaryCategory",
  ProfileImages = "ProfileImages",
  DividendPayment = "DividendPayment",
  TriviaLogo = "TriviaLogo",
  RefundLogo = "RefundLogo",
  SaleLogo = "SaleLogo",
  TaxLogo = "TaxLogo",
  CorporateTax = "CorporateTax",
  VotingLogo = "VotingLogo"
}
export enum TargetPopulationDensity {
  Urban = "Urban",
  Suburban = "Suburban",
  Rural = "Rural"
}
export enum TargetRegion {
  EastCoast = "EastCoast",
  MidWest = "MidWest",
  WestCoast = "WestCoast",
  Other = "Other"
}
export enum TargetCustomerWealth {
  LowIncome = "LowIncome",
  MiddleIncome = "MiddleIncome",
  UpperIncome = "UpperIncome",
  UltraIncome = "UltraIncome"
}
export enum ProductExpense {
  Low = "Low",
  Medium = "Medium",
  High = "High"
}
export enum RelativeAmountType {
  Low = "Low",
  Medium = "Medium",
  High = "High"
}
export enum Rating {
  Meh = "Meh",
  Ok = "Ok",
  Good = "Good",
  Great = "Great",
  Awesome = "Awesome"
}
export enum EmployeeCount {
  Zero = "Zero",
  OneToTen = "OneToTen",
  TenToHundred = "TenToHundred",
  HundredToThousand = "HundredToThousand",
  ThousandPlus = "ThousandPlus"
}
export enum YesMaybeNo {
  Yes = "Yes",
  Maybe = "Maybe",
  No = "No"
}
export enum VirtualInvestmentType {
  Investment = "Investment",
  Divestment = "Divestment"
}
export enum BusinessType {
  SoleProprietorship = "SoleProprietorship",
  Partnership = "Partnership",
  CCorporation = "CCorporation",
  SCorporation = "SCorporation",
  LimitedLiabilityCorporation = "LimitedLiabilityCorporation",
  LimitedLiabilityPartnership = "LimitedLiabilityPartnership",
  SeriesLLC = "SeriesLLC",
  NonprofitCorporation = "NonprofitCorporation",
  BenefitCorporation = "BenefitCorporation",
  LowProfitLimitedLiabilityCorporation = "LowProfitLimitedLiabilityCorporation"
}
export enum BusinessPositions {
  ChiefComplianceOfficer = "ChiefComplianceOfficer",
  ChiefHumanResourceManager = "ChiefHumanResourceManager",
  ChiefSecurityOfficer = "ChiefSecurityOfficer",
  ChiefGreenOfficer = "ChiefGreenOfficer",
  ChiefAnalyticsOfficer = "ChiefAnalyticsOfficer",
  ChiefMarketingOfficer = "ChiefMarketingOfficer",
  ChiefDataOfficer = "ChiefDataOfficer",
  ChiefExecutiveOfficer = "ChiefExecutiveOfficer",
  ChiefFinancialOfficer = "ChiefFinancialOfficer",
  ChiefInformationOfficer = "ChiefInformationOfficer",
  ChiefOperatingOfficer = "ChiefOperatingOfficer",
  President = "President",
  VicePresident = "VicePresident",
  Treasurer = "Treasurer",
  Secretary = "Secretary",
  GeneralPartner = "GeneralPartner",
  Partner = "Partner"
}
export enum VirtualBusinessPartnerInvitationState {
  Pending = "Pending",
  OfferSubmitted = "OfferSubmitted",
  Rejected = "Rejected",
  Accepted = "Accepted",
  PositionFilled = "PositionFilled",
  InsufficientInvestmentFunds = "InsufficientInvestmentFunds",
  InsufficientShares = "InsufficientShares",
  Completed = "Completed",
  ProcessingTransaction = "ProcessingTransaction",
  ProcessedTransaction = "ProcessedTransaction",
  Sent = "Sent"
}
export enum VirtualShareEscroType {
  ExactPriceOnly = "ExactPriceOnly",
  OpenOffer = "OpenOffer",
  Auction = "Auction"
}
export enum VirtualShareEscroState {
  Accepted = "Accepted",
  Completed = "Completed",
  Processing = "Processing",
  InsufficentFunds = "InsufficentFunds",
  Ready = "Ready"
}
export enum VirtualShareEscroOfferState {
  Accepted = "Accepted",
  Processed = "Processed",
  Initial = "Initial",
  Completed = "Completed",
  InsufficientFunds = "InsufficientFunds",
  Processing = "Processing"
}
export enum VirtualBusinessPetitionPartnershipDissolutionState {
  Pending = "Pending",
  Processing = "Processing",
  Complete = "Complete"
}
export enum VoteOptions {
  Disagree = "Disagree",
  Agree = "Agree"
}
export enum Category {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
  H = "H",
  I = "I",
  J = "J",
  K = "K",
  L = "L",
  M = "M",
  N = "N",
  O = "O",
  P = "P",
  Q = "Q",
  R = "R"
}
export enum SendEmailRequestType {
  ConnectCodes = "ConnectCodes",
  WelcomeEmail = "WelcomeEmail"
}
export enum VirtualEmployeeCacheType {
  Employed = "Employed",
  Unmployed = "Unmployed"
}
export enum LoanType {
  Personal = "Personal",
  Business = "Business"
}
export enum LoanState {
  Initial = "Initial",
  ReadyForConsideration = "ReadyForConsideration",
  Accepted = "Accepted",
  Rejected = "Rejected",
  Active = "Active",
  InDefault = "InDefault"
}
export enum AccountLogCommandType {
  ClearAccountsLog = "ClearAccountsLog"
}
export enum PublicStatus {
  Open = "Open",
  Closed = "Closed"
}
export enum CityAsset {
  CityApartment = "CityApartment",
  Store = "Store"
}
export enum VotingIssueType {
  WindfallTax = "WindfallTax"
}
export enum VoteType {
  InFavor = "InFavor",
  Reject = "Reject"
}
export enum AssetResourceType {
  Education = "Education",
  Port = "Port",
  Business = "Business"
}
export enum BusinessCategory {
  Food = "Food",
  Housing = "Housing",
  Insurance = "Insurance",
  Utilities = "Utilities",
  Government = "Government"
}
export enum PushNotificationTarget {
  Individual = "Individual",
  Bank = "Bank"
}
export enum PushNotificationCategory {
  "RECEIVED_PAYMENT" = `RECEIVED_PAYMENT`
}
export enum CourseState {
  Processed = "Processed",
  Initial = "Initial",
  Processing = "Processing",
  ReadyToPublish = "ReadyToPublish",
  Published = "Published",
  Publishing = "Publishing",
  Unpublished = "Unpublished"
}
export enum GradLevel {
  Undergraduate = "Undergraduate"
}
export enum CourseTakerSettingKeys {
  CurrentCourse = "CurrentCourse"
}
export enum JobType {
  ViewBlockExpansion = "ViewBlockExpansion",
  PublishJob = "PublishJob",
  ResourceInitialProcessing = "ResourceInitialProcessing",
  ProcessCourses = "ProcessCourses"
}
