import { ListItem, Box, Typography } from "@mui/material";
import { formatDistance, subDays } from "date-fns";
import useLogo from "hooks/useLogo";
import useLogos from "hooks/useLogos";
import { Course } from "models";
import TileLogo from "./TileLogo";
import TitleService from "title-service";
import { useTheme, styled } from '@mui/material/styles';
import { useState } from "react";
import { useView } from "hooks/view_context";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { get_all_coursePay } from "features/coursePay/coursePay-slice";
import { useSelector } from "react-redux";
import { centsToDollars } from "./util";
export default function CourseListItem(props: { hideprice?: boolean, onClick?: () => void, course: Course }) {
    const theme = useTheme();
    const [over, setOver] = useState(false);
    const all_course_pays = useSelector(get_all_coursePay);
    const viewContext = useView();
    const { course } = props;
    const selected = course?.id && course?.id === viewContext?.course;
    const font_size = `33px`;
    const priceStyle: any = { display: 'inline-block', fontSize: font_size, lineHeight: font_size, fontWeight: 'bold' }
    const coursePay = all_course_pays.find(x => x.course === props?.course.id && x.id === props?.course?.coursePay)
    return (<ListItem
        selected={selected}
        onClick={() => {
            if (props.onClick) {
                props.onClick();
            }
        }}
        sx={{
            cursor: 'pointer',
            ':hover': {
                color: theme.palette.grey[500],
                bgcolor: theme.palette.grey[50]
            },
            p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' }
        }}
    >
        <Box flex="1">
            <Box display="flex" justifyContent="start" alignItems={'center'}>
                <TileLogo course={course} style={{ marginRight: 10, height: 40 }} />
                <Typography sx={{ fontWeight: 'bold' }}>
                    {TitleService(`${course.name}`)}
                </Typography>
            </Box>
            <Typography
                component="span"
                variant="body2"
                color="text.secondary"
            >
                {' '}
                {TitleService(`${course.institution || ''}`)}
            </Typography>
        </Box>
        {!props.hideprice && course.coursePay && coursePay && <Box display={'flex'} flexDirection={'row'}>
            <span style={priceStyle}>{centsToDollars(coursePay.priceInCents)}</span>
        </Box>}
    </ListItem>
    )
}