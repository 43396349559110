import StockPrice from "./stock-price-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateStockPriceAsync, DeleteStockPriceAsync, LoadStockPricesAsync } from "./stockPrice-thunk";
const initialState: stockPriceSliceState = {
  stockPrice: [],
  selected_stockPrice: "",
  is_loading_stockPrice: false,
  is_creating_stockPrice: false,
  is_deleting_stockPrice: false
};
export interface stockPriceSliceState {
  stockPrice: StockPrice[];
  selected_stockPrice?: string;
  is_loading_stockPrice: boolean;
  is_creating_stockPrice: boolean;
  is_deleting_stockPrice: boolean;
}
export const stockPriceSlice = createSlice({
  name: "stockPrice",
  initialState,
  reducers: {
    setSelectedstockPrice: (state, action) => {
      state.selected_stockPrice = action.payload;
    },
    clearStatestockPrice: (state, action) => {
      state.stockPrice = [];
      state.is_loading_stockPrice = false;
      state.is_creating_stockPrice = false;
      state.is_deleting_stockPrice = false;
    },
    mergestockPrice: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_stockPrice = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.stockPrice = merge(state.stockPrice, payload) as any;
        }
      }
    },

    add_stockPrice: (state, action) => {
      if (action.payload) {
        state.stockPrice = [...state.stockPrice, action.payload];
      }
    },
    set_stockPrice: (state, action) => {
      let { key, value, id } = action.payload;
      state.stockPrice = state.stockPrice.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadStockPricesAsync.pending, (state) => {
      state.is_loading_stockPrice = true;
    });
    builder.addCase(LoadStockPricesAsync.fulfilled, (state, action) => {
      state.is_loading_stockPrice = false;
      if (action.payload) {
        state.stockPrice = action.payload;
      }
    });
    builder.addCase(LoadStockPricesAsync.rejected, (state, action) => {
      state.is_loading_stockPrice = false;
    });
    builder.addCase(CreateStockPriceAsync.pending, (state) => {
      state.is_creating_stockPrice = true;
    });
    builder.addCase(CreateStockPriceAsync.fulfilled, (state) => {
      state.is_creating_stockPrice = false;
    });
    builder.addCase(CreateStockPriceAsync.rejected, (state) => {
      state.is_creating_stockPrice = false;
    });
    builder.addCase(DeleteStockPriceAsync.pending, (state) => {
      state.is_deleting_stockPrice = true;
    });
    builder.addCase(DeleteStockPriceAsync.fulfilled, (state, action) => {
      state.is_deleting_stockPrice = false;
      if (state.stockPrice) {
        state.stockPrice = state.stockPrice.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteStockPriceAsync.rejected, (state, action) => {
      state.is_deleting_stockPrice = false;
    });
  }
});

function handleResult(action: any, state: stockPriceSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_stockPrice = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.stockPrice = merge(state.stockPrice, payload) as any;
    }
  }
}

export const { clearStatestockPrice, setSelectedstockPrice, set_stockPrice, mergestockPrice, add_stockPrice } = stockPriceSlice.actions;

export const get_stockPrice =
  (id?: string) =>
  (state: {
    stockPrice: {
      stockPrice: StockPrice[];
    };
  }): StockPrice | null => {
    let temp = state?.stockPrice?.stockPrice?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.stockPrice?.stockPrice[0] || null;
  };
export const get_selected_stockPrice = (state: { stockPrice: stockPriceSliceState }): StockPrice | null => {
  let id = state?.stockPrice?.selected_stockPrice;
  let temp = state?.stockPrice?.stockPrice?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_stockPrice = (state: {
  stockPrice: {
    stockPrice: StockPrice[];
  };
}): StockPrice[] => {
  let temp = state?.stockPrice?.stockPrice;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_stockPrice_busy = (state: { stockPrice: stockPriceSliceState }) => {
  return state?.stockPrice.is_deleting_stockPrice || state?.stockPrice.is_loading_stockPrice || state?.stockPrice.is_creating_stockPrice;
};
export const is_deleting_stockPrice_busy = (state: { stockPrice: stockPriceSliceState }) => {
  return state?.stockPrice.is_deleting_stockPrice;
};
export const is_loading_stockPrice_busy = (state: { stockPrice: stockPriceSliceState }) => {
  return state?.stockPrice.is_loading_stockPrice;
};
export const is_creating_stockPrice_busy = (state: { stockPrice: stockPriceSliceState }) => {
  return state?.stockPrice.is_creating_stockPrice;
};

export default stockPriceSlice.reducer;
