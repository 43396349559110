import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import ResourceUserAvatarList from "./resource-user-avatar-list-model";
import { ResourceUserAvatarListProperties, ResourceUserAvatarListMetaProperties } from "./resourceUserAvatarList-property-definitions";

export async function load_resourceUserAvatarLists(args: { uid: string }): Promise<ResourceUserAvatarList[]> {
  throw "Not implemented";
}

export async function delete_resourceUserAvatarList(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_resourceUserAvatarList(args: ResourceUserAvatarList): Promise<ResourceUserAvatarList> {
  throw "Not implemented";
}
