import InvestmentOrder from "./investment-order-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateInvestmentOrderAsync, DeleteInvestmentOrderAsync, LoadInvestmentOrdersAsync } from "./investmentOrder-thunk";
const initialState: investmentOrderSliceState = {
  investmentOrder: [],
  selected_investmentOrder: "",
  is_loading_investmentOrder: false,
  is_creating_investmentOrder: false,
  is_deleting_investmentOrder: false
};
export interface investmentOrderSliceState {
  investmentOrder: InvestmentOrder[];
  selected_investmentOrder?: string;
  is_loading_investmentOrder: boolean;
  is_creating_investmentOrder: boolean;
  is_deleting_investmentOrder: boolean;
}
export const investmentOrderSlice = createSlice({
  name: "investmentOrder",
  initialState,
  reducers: {
    setSelectedinvestmentOrder: (state, action) => {
      state.selected_investmentOrder = action.payload;
    },
    clearStateinvestmentOrder: (state, action) => {
      state.investmentOrder = [];
      state.is_loading_investmentOrder = false;
      state.is_creating_investmentOrder = false;
      state.is_deleting_investmentOrder = false;
    },
    mergeinvestmentOrder: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_investmentOrder = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.investmentOrder = merge(state.investmentOrder, payload) as any;
        }
      }
    },

    add_investmentOrder: (state, action) => {
      if (action.payload) {
        state.investmentOrder = [...state.investmentOrder, action.payload];
      }
    },
    set_investmentOrder: (state, action) => {
      let { key, value, id } = action.payload;
      state.investmentOrder = state.investmentOrder.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadInvestmentOrdersAsync.pending, (state) => {
      state.is_loading_investmentOrder = true;
    });
    builder.addCase(LoadInvestmentOrdersAsync.fulfilled, (state, action) => {
      state.is_loading_investmentOrder = false;
      if (action.payload) {
        state.investmentOrder = action.payload;
      }
    });
    builder.addCase(LoadInvestmentOrdersAsync.rejected, (state, action) => {
      state.is_loading_investmentOrder = false;
    });
    builder.addCase(CreateInvestmentOrderAsync.pending, (state) => {
      state.is_creating_investmentOrder = true;
    });
    builder.addCase(CreateInvestmentOrderAsync.fulfilled, (state) => {
      state.is_creating_investmentOrder = false;
    });
    builder.addCase(CreateInvestmentOrderAsync.rejected, (state) => {
      state.is_creating_investmentOrder = false;
    });
    builder.addCase(DeleteInvestmentOrderAsync.pending, (state) => {
      state.is_deleting_investmentOrder = true;
    });
    builder.addCase(DeleteInvestmentOrderAsync.fulfilled, (state, action) => {
      state.is_deleting_investmentOrder = false;
      if (state.investmentOrder) {
        state.investmentOrder = state.investmentOrder.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteInvestmentOrderAsync.rejected, (state, action) => {
      state.is_deleting_investmentOrder = false;
    });

    builder.addCase(autoThunks.make_stock_purchase_request.pending, (state) => {
      state.is_loading_investmentOrder = true;
    });
    builder.addCase(autoThunks.make_stock_purchase_request.fulfilled, (state, action) => {
      state.is_loading_investmentOrder = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.make_stock_purchase_request.rejected, (state, action) => {
      state.is_loading_investmentOrder = false;
    });

    builder.addCase(autoThunks.get_customer_investment_orders.pending, (state) => {
      state.is_loading_investmentOrder = true;
    });
    builder.addCase(autoThunks.get_customer_investment_orders.fulfilled, (state, action) => {
      state.is_loading_investmentOrder = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_customer_investment_orders.rejected, (state, action) => {
      state.is_loading_investmentOrder = false;
    });
  }
});

function handleResult(action: any, state: investmentOrderSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_investmentOrder = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.investmentOrder = merge(state.investmentOrder, payload) as any;
    }
  }
}

export const { clearStateinvestmentOrder, setSelectedinvestmentOrder, set_investmentOrder, mergeinvestmentOrder, add_investmentOrder } =
  investmentOrderSlice.actions;

export const get_investmentOrder =
  (id?: string) =>
  (state: {
    investmentOrder: {
      investmentOrder: InvestmentOrder[];
    };
  }): InvestmentOrder | null => {
    let temp = state?.investmentOrder?.investmentOrder?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.investmentOrder?.investmentOrder[0] || null;
  };
export const get_selected_investmentOrder = (state: { investmentOrder: investmentOrderSliceState }): InvestmentOrder | null => {
  let id = state?.investmentOrder?.selected_investmentOrder;
  let temp = state?.investmentOrder?.investmentOrder?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_investmentOrder = (state: {
  investmentOrder: {
    investmentOrder: InvestmentOrder[];
  };
}): InvestmentOrder[] => {
  let temp = state?.investmentOrder?.investmentOrder;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_investmentOrder_busy = (state: { investmentOrder: investmentOrderSliceState }) => {
  return (
    state?.investmentOrder.is_deleting_investmentOrder ||
    state?.investmentOrder.is_loading_investmentOrder ||
    state?.investmentOrder.is_creating_investmentOrder
  );
};
export const is_deleting_investmentOrder_busy = (state: { investmentOrder: investmentOrderSliceState }) => {
  return state?.investmentOrder.is_deleting_investmentOrder;
};
export const is_loading_investmentOrder_busy = (state: { investmentOrder: investmentOrderSliceState }) => {
  return state?.investmentOrder.is_loading_investmentOrder;
};
export const is_creating_investmentOrder_busy = (state: { investmentOrder: investmentOrderSliceState }) => {
  return state?.investmentOrder.is_creating_investmentOrder;
};

export default investmentOrderSlice.reducer;
