import { createAsyncThunk } from "@reduxjs/toolkit";
import VirtualEmployeeCache from "./virtual-employee-cache-model";
import { delete_virtualEmployeeCache, load_virtualEmployeeCaches, store_virtualEmployeeCache } from "./virtualEmployeeCache-service";

export const LoadVirtualEmployeeCachesAsync = createAsyncThunk<any, any>("composer/load/virtualEmployeeCache", async (params: { uid: string }) => {
  return await load_virtualEmployeeCaches(params);
});

export const CreateVirtualEmployeeCacheAsync = createAsyncThunk<any, any>("composer/create/virtualEmployeeCache", async (params: VirtualEmployeeCache) => {
  return await store_virtualEmployeeCache(params);
});

export const DeleteVirtualEmployeeCacheAsync = createAsyncThunk<any, any>("composer/delete/virtualEmployeeCache", async (params) => {
  return await delete_virtualEmployeeCache(params);
});
