import { createAsyncThunk } from "@reduxjs/toolkit";
import CourseResourceBlock from "./course-resource-block-model";
import { delete_courseResourceBlock, load_courseResourceBlocks, store_courseResourceBlock } from "./courseResourceBlock-service";

export const LoadCourseResourceBlocksAsync = createAsyncThunk<any, any>("composer/load/courseResourceBlock", async (params: { uid: string }) => {
  return await load_courseResourceBlocks(params);
});

export const CreateCourseResourceBlockAsync = createAsyncThunk<any, any>("composer/create/courseResourceBlock", async (params: CourseResourceBlock) => {
  return await store_courseResourceBlock(params);
});

export const DeleteCourseResourceBlockAsync = createAsyncThunk<any, any>("composer/delete/courseResourceBlock", async (params) => {
  return await delete_courseResourceBlock(params);
});
