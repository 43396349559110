import BusinessPlanEvaluation from "./business-plan-evaluation-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateBusinessPlanEvaluationAsync, DeleteBusinessPlanEvaluationAsync, LoadBusinessPlanEvaluationsAsync } from "./businessPlanEvaluation-thunk";
const initialState: businessPlanEvaluationSliceState = {
  businessPlanEvaluation: [],
  selected_businessPlanEvaluation: "",
  is_loading_businessPlanEvaluation: false,
  is_creating_businessPlanEvaluation: false,
  is_deleting_businessPlanEvaluation: false
};
export interface businessPlanEvaluationSliceState {
  businessPlanEvaluation: BusinessPlanEvaluation[];
  selected_businessPlanEvaluation?: string;
  is_loading_businessPlanEvaluation: boolean;
  is_creating_businessPlanEvaluation: boolean;
  is_deleting_businessPlanEvaluation: boolean;
}
export const businessPlanEvaluationSlice = createSlice({
  name: "businessPlanEvaluation",
  initialState,
  reducers: {
    setSelectedbusinessPlanEvaluation: (state, action) => {
      state.selected_businessPlanEvaluation = action.payload;
    },
    clearStatebusinessPlanEvaluation: (state, action) => {
      state.businessPlanEvaluation = [];
      state.is_loading_businessPlanEvaluation = false;
      state.is_creating_businessPlanEvaluation = false;
      state.is_deleting_businessPlanEvaluation = false;
    },
    mergebusinessPlanEvaluation: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_businessPlanEvaluation = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.businessPlanEvaluation = merge(state.businessPlanEvaluation, payload) as any;
        }
      }
    },

    add_businessPlanEvaluation: (state, action) => {
      if (action.payload) {
        state.businessPlanEvaluation = [...state.businessPlanEvaluation, action.payload];
      }
    },
    set_businessPlanEvaluation: (state, action) => {
      let { key, value, id } = action.payload;
      state.businessPlanEvaluation = state.businessPlanEvaluation.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadBusinessPlanEvaluationsAsync.pending, (state) => {
      state.is_loading_businessPlanEvaluation = true;
    });
    builder.addCase(LoadBusinessPlanEvaluationsAsync.fulfilled, (state, action) => {
      state.is_loading_businessPlanEvaluation = false;
      if (action.payload) {
        state.businessPlanEvaluation = action.payload;
      }
    });
    builder.addCase(LoadBusinessPlanEvaluationsAsync.rejected, (state, action) => {
      state.is_loading_businessPlanEvaluation = false;
    });
    builder.addCase(CreateBusinessPlanEvaluationAsync.pending, (state) => {
      state.is_creating_businessPlanEvaluation = true;
    });
    builder.addCase(CreateBusinessPlanEvaluationAsync.fulfilled, (state) => {
      state.is_creating_businessPlanEvaluation = false;
    });
    builder.addCase(CreateBusinessPlanEvaluationAsync.rejected, (state) => {
      state.is_creating_businessPlanEvaluation = false;
    });
    builder.addCase(DeleteBusinessPlanEvaluationAsync.pending, (state) => {
      state.is_deleting_businessPlanEvaluation = true;
    });
    builder.addCase(DeleteBusinessPlanEvaluationAsync.fulfilled, (state, action) => {
      state.is_deleting_businessPlanEvaluation = false;
      if (state.businessPlanEvaluation) {
        state.businessPlanEvaluation = state.businessPlanEvaluation.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteBusinessPlanEvaluationAsync.rejected, (state, action) => {
      state.is_deleting_businessPlanEvaluation = false;
    });

    builder.addCase(autoThunks.store_business_plan_evaluation.pending, (state) => {
      state.is_loading_businessPlanEvaluation = true;
    });
    builder.addCase(autoThunks.store_business_plan_evaluation.fulfilled, (state, action) => {
      state.is_loading_businessPlanEvaluation = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.store_business_plan_evaluation.rejected, (state, action) => {
      state.is_loading_businessPlanEvaluation = false;
    });

    builder.addCase(autoThunks.get_my_business_plan_evaluation.pending, (state) => {
      state.is_loading_businessPlanEvaluation = true;
    });
    builder.addCase(autoThunks.get_my_business_plan_evaluation.fulfilled, (state, action) => {
      state.is_loading_businessPlanEvaluation = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_my_business_plan_evaluation.rejected, (state, action) => {
      state.is_loading_businessPlanEvaluation = false;
    });

    builder.addCase(autoThunks.get_business_plan_evaluations.pending, (state) => {
      state.is_loading_businessPlanEvaluation = true;
    });
    builder.addCase(autoThunks.get_business_plan_evaluations.fulfilled, (state, action) => {
      state.is_loading_businessPlanEvaluation = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_business_plan_evaluations.rejected, (state, action) => {
      state.is_loading_businessPlanEvaluation = false;
    });

    builder.addCase(autoThunks.get_virtual_businessplan_evaluations_for_banks.pending, (state) => {
      state.is_loading_businessPlanEvaluation = true;
    });
    builder.addCase(autoThunks.get_virtual_businessplan_evaluations_for_banks.fulfilled, (state, action) => {
      state.is_loading_businessPlanEvaluation = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_virtual_businessplan_evaluations_for_banks.rejected, (state, action) => {
      state.is_loading_businessPlanEvaluation = false;
    });
  }
});

function handleResult(action: any, state: businessPlanEvaluationSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_businessPlanEvaluation = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.businessPlanEvaluation = merge(state.businessPlanEvaluation, payload) as any;
    }
  }
}

export const {
  clearStatebusinessPlanEvaluation,
  setSelectedbusinessPlanEvaluation,
  set_businessPlanEvaluation,
  mergebusinessPlanEvaluation,
  add_businessPlanEvaluation
} = businessPlanEvaluationSlice.actions;

export const get_businessPlanEvaluation =
  (id?: string) =>
  (state: {
    businessPlanEvaluation: {
      businessPlanEvaluation: BusinessPlanEvaluation[];
    };
  }): BusinessPlanEvaluation | null => {
    let temp = state?.businessPlanEvaluation?.businessPlanEvaluation?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.businessPlanEvaluation?.businessPlanEvaluation[0] || null;
  };
export const get_selected_businessPlanEvaluation = (state: { businessPlanEvaluation: businessPlanEvaluationSliceState }): BusinessPlanEvaluation | null => {
  let id = state?.businessPlanEvaluation?.selected_businessPlanEvaluation;
  let temp = state?.businessPlanEvaluation?.businessPlanEvaluation?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_businessPlanEvaluation = (state: {
  businessPlanEvaluation: {
    businessPlanEvaluation: BusinessPlanEvaluation[];
  };
}): BusinessPlanEvaluation[] => {
  let temp = state?.businessPlanEvaluation?.businessPlanEvaluation;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_businessPlanEvaluation_busy = (state: { businessPlanEvaluation: businessPlanEvaluationSliceState }) => {
  return (
    state?.businessPlanEvaluation.is_deleting_businessPlanEvaluation ||
    state?.businessPlanEvaluation.is_loading_businessPlanEvaluation ||
    state?.businessPlanEvaluation.is_creating_businessPlanEvaluation
  );
};
export const is_deleting_businessPlanEvaluation_busy = (state: { businessPlanEvaluation: businessPlanEvaluationSliceState }) => {
  return state?.businessPlanEvaluation.is_deleting_businessPlanEvaluation;
};
export const is_loading_businessPlanEvaluation_busy = (state: { businessPlanEvaluation: businessPlanEvaluationSliceState }) => {
  return state?.businessPlanEvaluation.is_loading_businessPlanEvaluation;
};
export const is_creating_businessPlanEvaluation_busy = (state: { businessPlanEvaluation: businessPlanEvaluationSliceState }) => {
  return state?.businessPlanEvaluation.is_creating_businessPlanEvaluation;
};

export default businessPlanEvaluationSlice.reducer;
