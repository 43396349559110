import SendEmailRequest from "./send-email-request-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateSendEmailRequestAsync, DeleteSendEmailRequestAsync, LoadSendEmailRequestsAsync } from "./sendEmailRequest-thunk";
const initialState: sendEmailRequestSliceState = {
  sendEmailRequest: [],
  selected_sendEmailRequest: "",
  is_loading_sendEmailRequest: false,
  is_creating_sendEmailRequest: false,
  is_deleting_sendEmailRequest: false
};
export interface sendEmailRequestSliceState {
  sendEmailRequest: SendEmailRequest[];
  selected_sendEmailRequest?: string;
  is_loading_sendEmailRequest: boolean;
  is_creating_sendEmailRequest: boolean;
  is_deleting_sendEmailRequest: boolean;
}
export const sendEmailRequestSlice = createSlice({
  name: "sendEmailRequest",
  initialState,
  reducers: {
    setSelectedsendEmailRequest: (state, action) => {
      state.selected_sendEmailRequest = action.payload;
    },
    clearStatesendEmailRequest: (state, action) => {
      state.sendEmailRequest = [];
      state.is_loading_sendEmailRequest = false;
      state.is_creating_sendEmailRequest = false;
      state.is_deleting_sendEmailRequest = false;
    },
    mergesendEmailRequest: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_sendEmailRequest = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.sendEmailRequest = merge(state.sendEmailRequest, payload) as any;
        }
      }
    },

    add_sendEmailRequest: (state, action) => {
      if (action.payload) {
        state.sendEmailRequest = [...state.sendEmailRequest, action.payload];
      }
    },
    set_sendEmailRequest: (state, action) => {
      let { key, value, id } = action.payload;
      state.sendEmailRequest = state.sendEmailRequest.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadSendEmailRequestsAsync.pending, (state) => {
      state.is_loading_sendEmailRequest = true;
    });
    builder.addCase(LoadSendEmailRequestsAsync.fulfilled, (state, action) => {
      state.is_loading_sendEmailRequest = false;
      if (action.payload) {
        state.sendEmailRequest = action.payload;
      }
    });
    builder.addCase(LoadSendEmailRequestsAsync.rejected, (state, action) => {
      state.is_loading_sendEmailRequest = false;
    });
    builder.addCase(CreateSendEmailRequestAsync.pending, (state) => {
      state.is_creating_sendEmailRequest = true;
    });
    builder.addCase(CreateSendEmailRequestAsync.fulfilled, (state) => {
      state.is_creating_sendEmailRequest = false;
    });
    builder.addCase(CreateSendEmailRequestAsync.rejected, (state) => {
      state.is_creating_sendEmailRequest = false;
    });
    builder.addCase(DeleteSendEmailRequestAsync.pending, (state) => {
      state.is_deleting_sendEmailRequest = true;
    });
    builder.addCase(DeleteSendEmailRequestAsync.fulfilled, (state, action) => {
      state.is_deleting_sendEmailRequest = false;
      if (state.sendEmailRequest) {
        state.sendEmailRequest = state.sendEmailRequest.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteSendEmailRequestAsync.rejected, (state, action) => {
      state.is_deleting_sendEmailRequest = false;
    });

    builder.addCase(autoThunks.send_connect_email_to_email.pending, (state) => {
      state.is_loading_sendEmailRequest = true;
    });
    builder.addCase(autoThunks.send_connect_email_to_email.fulfilled, (state, action) => {
      state.is_loading_sendEmailRequest = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.send_connect_email_to_email.rejected, (state, action) => {
      state.is_loading_sendEmailRequest = false;
    });

    builder.addCase(autoThunks.retrieve_connecting_email.pending, (state) => {
      state.is_loading_sendEmailRequest = true;
    });
    builder.addCase(autoThunks.retrieve_connecting_email.fulfilled, (state, action) => {
      state.is_loading_sendEmailRequest = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.retrieve_connecting_email.rejected, (state, action) => {
      state.is_loading_sendEmailRequest = false;
    });

    builder.addCase(autoThunks.send_welcome_email.pending, (state) => {
      state.is_loading_sendEmailRequest = true;
    });
    builder.addCase(autoThunks.send_welcome_email.fulfilled, (state, action) => {
      state.is_loading_sendEmailRequest = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.send_welcome_email.rejected, (state, action) => {
      state.is_loading_sendEmailRequest = false;
    });

    builder.addCase(autoThunks.send_connect_email.pending, (state) => {
      state.is_loading_sendEmailRequest = true;
    });
    builder.addCase(autoThunks.send_connect_email.fulfilled, (state, action) => {
      state.is_loading_sendEmailRequest = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.send_connect_email.rejected, (state, action) => {
      state.is_loading_sendEmailRequest = false;
    });

    builder.addCase(autoThunks.send_connect_email_to_bank_accounts.pending, (state) => {
      state.is_loading_sendEmailRequest = true;
    });
    builder.addCase(autoThunks.send_connect_email_to_bank_accounts.fulfilled, (state, action) => {
      state.is_loading_sendEmailRequest = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.send_connect_email_to_bank_accounts.rejected, (state, action) => {
      state.is_loading_sendEmailRequest = false;
    });

    builder.addCase(autoThunks.send_welcome_email_to_bank_accounts.pending, (state) => {
      state.is_loading_sendEmailRequest = true;
    });
    builder.addCase(autoThunks.send_welcome_email_to_bank_accounts.fulfilled, (state, action) => {
      state.is_loading_sendEmailRequest = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.send_welcome_email_to_bank_accounts.rejected, (state, action) => {
      state.is_loading_sendEmailRequest = false;
    });
  }
});

function handleResult(action: any, state: sendEmailRequestSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_sendEmailRequest = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.sendEmailRequest = merge(state.sendEmailRequest, payload) as any;
    }
  }
}

export const { clearStatesendEmailRequest, setSelectedsendEmailRequest, set_sendEmailRequest, mergesendEmailRequest, add_sendEmailRequest } =
  sendEmailRequestSlice.actions;

export const get_sendEmailRequest =
  (id?: string) =>
  (state: {
    sendEmailRequest: {
      sendEmailRequest: SendEmailRequest[];
    };
  }): SendEmailRequest | null => {
    let temp = state?.sendEmailRequest?.sendEmailRequest?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.sendEmailRequest?.sendEmailRequest[0] || null;
  };
export const get_selected_sendEmailRequest = (state: { sendEmailRequest: sendEmailRequestSliceState }): SendEmailRequest | null => {
  let id = state?.sendEmailRequest?.selected_sendEmailRequest;
  let temp = state?.sendEmailRequest?.sendEmailRequest?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_sendEmailRequest = (state: {
  sendEmailRequest: {
    sendEmailRequest: SendEmailRequest[];
  };
}): SendEmailRequest[] => {
  let temp = state?.sendEmailRequest?.sendEmailRequest;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_sendEmailRequest_busy = (state: { sendEmailRequest: sendEmailRequestSliceState }) => {
  return (
    state?.sendEmailRequest.is_deleting_sendEmailRequest ||
    state?.sendEmailRequest.is_loading_sendEmailRequest ||
    state?.sendEmailRequest.is_creating_sendEmailRequest
  );
};
export const is_deleting_sendEmailRequest_busy = (state: { sendEmailRequest: sendEmailRequestSliceState }) => {
  return state?.sendEmailRequest.is_deleting_sendEmailRequest;
};
export const is_loading_sendEmailRequest_busy = (state: { sendEmailRequest: sendEmailRequestSliceState }) => {
  return state?.sendEmailRequest.is_loading_sendEmailRequest;
};
export const is_creating_sendEmailRequest_busy = (state: { sendEmailRequest: sendEmailRequestSliceState }) => {
  return state?.sendEmailRequest.is_creating_sendEmailRequest;
};

export default sendEmailRequestSlice.reducer;
