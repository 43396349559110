import { createAsyncThunk } from "@reduxjs/toolkit";
import AccountLog from "./account-log-model";
import { delete_accountLog, load_accountLogs, store_accountLog } from "./accountLog-service";

export const LoadAccountLogsAsync = createAsyncThunk<any, any>("composer/load/accountLog", async (params: { uid: string }) => {
  return await load_accountLogs(params);
});

export const CreateAccountLogAsync = createAsyncThunk<any, any>("composer/create/accountLog", async (params: AccountLog) => {
  return await store_accountLog(params);
});

export const DeleteAccountLogAsync = createAsyncThunk<any, any>("composer/delete/accountLog", async (params) => {
  return await delete_accountLog(params);
});
