import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResolveUrl, UpdateClaims } from "features/firebase-app";
import {
  VirtualInvestment,
  User,
  TransactionProperties,
  AccountProperties,
  VirtualInvestmentProperties,
  VirtualBusinessProperties,
  VirtualShare,
  BusinessPlanProperties,
  VirtualShareProperties,
  VirtualBusinessRequirement,
  VirtualBusinessRequirementProperties,
  Account,
  Customer,
  Transaction,
  ImageResource,
  ImageResourceProperties,
  AccountBalances,
  AccountBalancesProperties,
  VirtualBusinessPartnerInvitation,
  VirtualBusinessPartnerInvitationProperties,
  VirtualBusinessPartnersProperties,
  VirtualBusinessDivestmentRequest,
  VirtualBusinessDivestmentRequestProperties,
  VirtualBusiness,
  VirtualShareEscroProperties,
  VirtualBusinessPetitionPartnershipDissolution,
  VirtualBusinessPetitionPartnershipDissolutionProperties,
  VirtualBusinessPetitionPartnershipDissolutionMessageProperties,
  ResourceRegistration,
  ResourceRegistrationProperties,
  VirtualEmployee,
  VirtualEmployeeProperties,
  QuestionAndAnswers,
  QuestionAndAnswersProperties,
  AccountCurriculumRecordProperties,
  CurriculumRecordItemProperties,
  CurriculumRecordItem,
  VirtualEmploymentRecord,
  VirtualEmploymentRecordProperties,
  BankBusiness,
  BankProperties,
  VirtualEmployeeCache,
  Loan,
  LoanProperties
} from "models";
import { getBearerToken } from "../../application/application-slice";

export const invest_virtual_business_for_account = createAsyncThunk<any, any, any>(
  "auto/invest/virtual/single/business/for/account",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/invest/virtual/single/business/for/account"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_virtual_business_shares = createAsyncThunk<any, any, any>("auto/get/virtual/business/shares", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/virtual/business/shares"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_virtual_businss_requirements = createAsyncThunk<any, any, any>(
  "auto/virtual/business/requirements",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/virtual/business/requirements"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }
        await UpdateClaims();
        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_virtual_business_accounts = createAsyncThunk<any, any, any>(
  "auto/get/virtual/business/accounts",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/get/virtual/business/accounts"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_virtual_business_customers = createAsyncThunk<any, any, any>(
  "auto/get/virtual/business/customers",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/get/virtual/business/customers"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_virtual_business_transactions = createAsyncThunk<any, any, any>(
  "auto/get/virtual/business/transactions",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/get/virtual/business/transactions"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_image_resources_for_mobile = createAsyncThunk<any, any, any>(
  "auto/get/image/resources/for/mobile",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/get/image/resources/for/mobile"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }
        await UpdateClaims();
        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_bank_Account_balances = createAsyncThunk<any, any, any>("auto/get/bank/account/balances", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/bank/account/balances"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const send_partnership_invitation = createAsyncThunk<any, any, any>("auto/send/partnership/invitation", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/send/partnership/invitation"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const respond_to_partnership_invitation = createAsyncThunk<any, any, any>(
  "auto/respond/to/partnership/invitation",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/respond/to/partnership/invitation"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const fetch_partnership_invitations = createAsyncThunk<any, any, any>(
  "auto/fetch/partnership/invitations",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/fetch/partnership/invitations"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const fetch_divestment_requests = createAsyncThunk<any, any, any>("auto/fetch/divestment/requests", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/fetch/divestment/requests"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const respond_to_divestment_requests = createAsyncThunk<any, any, any>(
  "auto/respond/to/divestment/requests",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/respond/to/divestment/requests"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const request_partnership_divestment = createAsyncThunk<any, any, any>(
  "auto/request/partnership/divestment",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/request/partnership/divestment"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_virtual_business_by_id = createAsyncThunk<any, any, any>("auto/get/virtual/business/by/id", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/virtual/business/by/id"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_account_by_id = createAsyncThunk<any, any, any>("auto/get/account/by/id", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/account/by/id"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const fetch_virtual_business_partnership_invitations = createAsyncThunk<any, any, any>(
  "auto/fetch/virtual/business/partnership/invitations",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/fetch/virtual/business/partnership/invitations"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const petition_partnership_dissolution = createAsyncThunk<any, any, any>(
  "auto/petition/partnership/dissolution",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/petition/partnership/dissolution"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const fetch_partnership_dissolution_requests = createAsyncThunk<any, any, any>(
  "auto/fetch/partnership/dissolution/requests",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/fetch/partnership/dissolution/requests"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const fetch_my_shares = createAsyncThunk<any, any, any>("auto/fetch/my/shares", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/fetch/my/shares"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const fetch_my_virtual_business_interests = createAsyncThunk<any, any, any>(
  "auto/fetch/my/virtual/business/interests",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/fetch/my/virtual/business/interests"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const leave_partnership = createAsyncThunk<any, any, any>("auto/leave/partnership", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/leave/partnership"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const update_account_profile_image = createAsyncThunk<any, any, any>(
  "auto/update/account/profile/image",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/update/account/profile/image"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const fetch_resource_registrations = createAsyncThunk<any, any, any>("auto/fetch/resource/registration", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/fetch/resource/registration"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_virtual_employees = createAsyncThunk<any, any, any>("auto/get/virtual/employees", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/virtual/employees"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_question_for_account = createAsyncThunk<any, any, any>("auto/get/question/for/account", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/question/for/account"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const answer_question_for_account = createAsyncThunk<any, any, any>("auto/answer/question/for/account", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/answer/question/for/account"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_virtual_employee_record = createAsyncThunk<any, any, any>("auto/get/virtual/employee/record", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/virtual/employee/record"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_virtual_employee_records = createAsyncThunk<any, any, any>(
  "auto/get/virtual/employee/records",
  async (params, apiThunk: { getState: any }) => {
    let { getState } = apiThunk;
    let state = getState();
    let bearToken = getBearerToken(state);
    if (bearToken) {
      try {
        let response = await fetch(ResolveUrl("auto/get/virtual/employee/records"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearToken}`,
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(params)
        });
        let res = await response.json();
        if (params && params.callback) {
          params.callback(true, res);
        }

        return res;
      } catch (e) {
        if (params && params.callback) {
          params.callback(false, e);
        }
      }
    } else {
      if (params && params.callback) {
        params.callback(false);
      }
      throw "not signed in";
    }
  }
);

export const get_bank_business = createAsyncThunk<any, any, any>("auto/get/bank/business", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/bank/business"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_virtual_employee_cache = createAsyncThunk<any, any, any>("auto/get/virtual/employees/cache", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/virtual/employees/cache"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_personal_loans = createAsyncThunk<any, any, any>("auto/get/personal/loans", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/personal/loans"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const get_business_loans = createAsyncThunk<any, any, any>("auto/get/business/loans", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/get/business/loans"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const request_business_loans = createAsyncThunk<any, any, any>("auto/request/business/loans", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/request/business/loans"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});

export const reply_business_loan_offer = createAsyncThunk<any, any, any>("auto/reply/business/loan/offer", async (params, apiThunk: { getState: any }) => {
  let { getState } = apiThunk;
  let state = getState();
  let bearToken = getBearerToken(state);
  if (bearToken) {
    try {
      let response = await fetch(ResolveUrl("auto/reply/business/loan/offer"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearToken}`,
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(params)
      });
      let res = await response.json();
      if (params && params.callback) {
        params.callback(true, res);
      }

      return res;
    } catch (e) {
      if (params && params.callback) {
        params.callback(false, e);
      }
    }
  } else {
    if (params && params.callback) {
      params.callback(false);
    }
    throw "not signed in";
  }
});
