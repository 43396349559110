export interface QuestionResult {
    id: string;
    sectionId: string;
    courseId: string;
    date: number;
    difficulty: string;
    attempts?: number;
    correct?: number;
}

export enum GradLevel {
    HighSchool = "High School",
    Elementary = "Elementary",
    College = "College",
    Undergraduate = "College",
    Graduate = "Graduate"
}
export interface Question {
    "question": string,
    "answer": string,
    explanation?: string,
    "options": string[],
    "difficulty": GradLevel,
    courseSection: string
}

export interface QuestionData {
    id: string;
    line: Question[],
    topic: string,
    subTopic: string
}

export interface QuestionResults {
    results: QuestionResult[]
}

export interface QuestionResult {
    id: string;
    date: number;
    flagged: boolean;
    difficulty: string;
    attempts?: number;
    correct?: number;
}


export function groupBy(array, callback) {
    return array.reduce((groups, item) => {
        const key = callback(item);
        if (!groups.hasOwnProperty(key)) {
            groups[key] = [];
        }
        groups[key].push(item);
        return groups;
    }, {});
}


export const Logger = {
    log: console.log
}

interface StyleObject {
    [key: string]: string;
}

export function translateStyleStringToStyleObject(styleString: string): StyleObject {
    const styleObject: StyleObject = {};

    if (!styleString) {
        return styleObject;
    }

    const stylePairs: string[] = styleString.split(';');

    stylePairs.forEach((pair: string) => {
        const [property, value]: string[] = pair.split(':');

        if (property && value) {
            const formattedProperty: string = property.trim();
            const formattedValue: string = value.trim();

            styleObject[formattedProperty] = formattedValue;
        }
    });

    return styleObject;
}

export function styleMerge(...objects) {
    let res = objects.reduce((mergedObj, obj) => {
        return { ...mergedObj, ...obj };
    }, {});

    return res;
}

export function calculatePercentage(correct, total) {
    if (total <= 0) {
        return '0%'; // Prevent division by zero
    }
    const percentage = Math.round((correct / total) * 100);
    return `${percentage}%`;
}

export function formatDuration(seconds) {
    if (seconds < 0) {
        return ''; // Negative numbers are not supported
    } else if (seconds === 0) {
        return '0 seconds'; // Special case for zero
    }

    const date = new Date(null);
    date.setSeconds(seconds); // specify value for SECONDS here
    const result = date.toISOString().slice(14, 19);
    return result;
}




export function shuffleArray(array) {
    const shuffledArray = [...array]; // Create a shallow copy of the input array

    for (let i = shuffledArray.length - 1; i > 0; i--) {
        // Generate a random index
        const randomIndex = Math.floor(Math.random() * (i + 1));

        // Swap the elements at i and randomIndex
        const temp = shuffledArray[i];
        shuffledArray[i] = shuffledArray[randomIndex];
        shuffledArray[randomIndex] = temp;
    }

    return shuffledArray;
}

export function lerpColor(color1, color2, t) {
    // Convert color strings to RGB values
    const r1 = parseInt(color1.slice(1, 3), 16);
    const g1 = parseInt(color1.slice(3, 5), 16);
    const b1 = parseInt(color1.slice(5, 7), 16);

    const r2 = parseInt(color2.slice(1, 3), 16);
    const g2 = parseInt(color2.slice(3, 5), 16);
    const b2 = parseInt(color2.slice(5, 7), 16);

    // Perform linear interpolation
    const r = Math.round(r1 + (r2 - r1) * t);
    const g = Math.round(g1 + (g2 - g1) * t);
    const b = Math.round(b1 + (b2 - b1) * t);

    // Convert RGB values back to a color string
    const interpolatedColor = `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;

    return interpolatedColor;
}
export function lerp(start, end, t) {
    return start + (end - start) * t;
}
