import { createAsyncThunk } from "@reduxjs/toolkit";
import SalesOrder from "./sales-order-model";
import { delete_salesOrder, load_salesOrders, store_salesOrder } from "./salesOrder-service";

export const LoadSalesOrdersAsync = createAsyncThunk<any, any>("composer/load/salesOrder", async (params: { uid: string }) => {
  return await load_salesOrders(params);
});

export const CreateSalesOrderAsync = createAsyncThunk<any, any>("composer/create/salesOrder", async (params: SalesOrder) => {
  return await store_salesOrder(params);
});

export const DeleteSalesOrderAsync = createAsyncThunk<any, any>("composer/delete/salesOrder", async (params) => {
  return await delete_salesOrder(params);
});
