import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import VirtualBusinessDivestmentRequest from "./virtual-business-divestment-request-model";
import {
  VirtualBusinessDivestmentRequestProperties,
  VirtualBusinessDivestmentRequestMetaProperties
} from "./virtualBusinessDivestmentRequest-property-definitions";

export async function load_virtualBusinessDivestmentRequests(args: { uid: string }): Promise<VirtualBusinessDivestmentRequest[]> {
  throw "Not implemented";
}

export async function delete_virtualBusinessDivestmentRequest(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_virtualBusinessDivestmentRequest(args: VirtualBusinessDivestmentRequest): Promise<VirtualBusinessDivestmentRequest> {
  throw "Not implemented";
}
