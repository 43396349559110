import { hash } from "components/util";
import Course from "features/course/course-model";
import { useEffect, useState } from "react";

export default function useLogo(course: Course) {
    let [logo, setLogo] = useState('');
    useEffect(() => {
        let logoIndex = Math.abs(hash(course?.id)) % 17;
        setLogo(course?.logo || `/images/logos/logo${logoIndex + 1}.png`);
    }, [course?.id]);

    return logo;
}