import VirtualEmployeeCache from "./virtual-employee-cache-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateVirtualEmployeeCacheAsync, DeleteVirtualEmployeeCacheAsync, LoadVirtualEmployeeCachesAsync } from "./virtualEmployeeCache-thunk";
const initialState: virtualEmployeeCacheSliceState = {
  virtualEmployeeCache: [],
  selected_virtualEmployeeCache: "",
  is_loading_virtualEmployeeCache: false,
  is_creating_virtualEmployeeCache: false,
  is_deleting_virtualEmployeeCache: false
};
export interface virtualEmployeeCacheSliceState {
  virtualEmployeeCache: VirtualEmployeeCache[];
  selected_virtualEmployeeCache?: string;
  is_loading_virtualEmployeeCache: boolean;
  is_creating_virtualEmployeeCache: boolean;
  is_deleting_virtualEmployeeCache: boolean;
}
export const virtualEmployeeCacheSlice = createSlice({
  name: "virtualEmployeeCache",
  initialState,
  reducers: {
    setSelectedvirtualEmployeeCache: (state, action) => {
      state.selected_virtualEmployeeCache = action.payload;
    },
    clearStatevirtualEmployeeCache: (state, action) => {
      state.virtualEmployeeCache = [];
      state.is_loading_virtualEmployeeCache = false;
      state.is_creating_virtualEmployeeCache = false;
      state.is_deleting_virtualEmployeeCache = false;
    },
    mergevirtualEmployeeCache: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_virtualEmployeeCache = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.virtualEmployeeCache = merge(state.virtualEmployeeCache, payload) as any;
        }
      }
    },

    add_virtualEmployeeCache: (state, action) => {
      if (action.payload) {
        state.virtualEmployeeCache = [...state.virtualEmployeeCache, action.payload];
      }
    },
    set_virtualEmployeeCache: (state, action) => {
      let { key, value, id } = action.payload;
      state.virtualEmployeeCache = state.virtualEmployeeCache.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadVirtualEmployeeCachesAsync.pending, (state) => {
      state.is_loading_virtualEmployeeCache = true;
    });
    builder.addCase(LoadVirtualEmployeeCachesAsync.fulfilled, (state, action) => {
      state.is_loading_virtualEmployeeCache = false;
      if (action.payload) {
        state.virtualEmployeeCache = action.payload;
      }
    });
    builder.addCase(LoadVirtualEmployeeCachesAsync.rejected, (state, action) => {
      state.is_loading_virtualEmployeeCache = false;
    });
    builder.addCase(CreateVirtualEmployeeCacheAsync.pending, (state) => {
      state.is_creating_virtualEmployeeCache = true;
    });
    builder.addCase(CreateVirtualEmployeeCacheAsync.fulfilled, (state) => {
      state.is_creating_virtualEmployeeCache = false;
    });
    builder.addCase(CreateVirtualEmployeeCacheAsync.rejected, (state) => {
      state.is_creating_virtualEmployeeCache = false;
    });
    builder.addCase(DeleteVirtualEmployeeCacheAsync.pending, (state) => {
      state.is_deleting_virtualEmployeeCache = true;
    });
    builder.addCase(DeleteVirtualEmployeeCacheAsync.fulfilled, (state, action) => {
      state.is_deleting_virtualEmployeeCache = false;
      if (state.virtualEmployeeCache) {
        state.virtualEmployeeCache = state.virtualEmployeeCache.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteVirtualEmployeeCacheAsync.rejected, (state, action) => {
      state.is_deleting_virtualEmployeeCache = false;
    });

    builder.addCase(autoThunks.get_virtual_employee_cache.pending, (state) => {
      state.is_loading_virtualEmployeeCache = true;
    });
    builder.addCase(autoThunks.get_virtual_employee_cache.fulfilled, (state, action) => {
      state.is_loading_virtualEmployeeCache = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_virtual_employee_cache.rejected, (state, action) => {
      state.is_loading_virtualEmployeeCache = false;
    });
  }
});

function handleResult(action: any, state: virtualEmployeeCacheSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_virtualEmployeeCache = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.virtualEmployeeCache = merge(state.virtualEmployeeCache, payload) as any;
    }
  }
}

export const {
  clearStatevirtualEmployeeCache,
  setSelectedvirtualEmployeeCache,
  set_virtualEmployeeCache,
  mergevirtualEmployeeCache,
  add_virtualEmployeeCache
} = virtualEmployeeCacheSlice.actions;

export const get_virtualEmployeeCache =
  (id?: string) =>
  (state: {
    virtualEmployeeCache: {
      virtualEmployeeCache: VirtualEmployeeCache[];
    };
  }): VirtualEmployeeCache | null => {
    let temp = state?.virtualEmployeeCache?.virtualEmployeeCache?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.virtualEmployeeCache?.virtualEmployeeCache[0] || null;
  };
export const get_selected_virtualEmployeeCache = (state: { virtualEmployeeCache: virtualEmployeeCacheSliceState }): VirtualEmployeeCache | null => {
  let id = state?.virtualEmployeeCache?.selected_virtualEmployeeCache;
  let temp = state?.virtualEmployeeCache?.virtualEmployeeCache?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_virtualEmployeeCache = (state: {
  virtualEmployeeCache: {
    virtualEmployeeCache: VirtualEmployeeCache[];
  };
}): VirtualEmployeeCache[] => {
  let temp = state?.virtualEmployeeCache?.virtualEmployeeCache;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_virtualEmployeeCache_busy = (state: { virtualEmployeeCache: virtualEmployeeCacheSliceState }) => {
  return (
    state?.virtualEmployeeCache.is_deleting_virtualEmployeeCache ||
    state?.virtualEmployeeCache.is_loading_virtualEmployeeCache ||
    state?.virtualEmployeeCache.is_creating_virtualEmployeeCache
  );
};
export const is_deleting_virtualEmployeeCache_busy = (state: { virtualEmployeeCache: virtualEmployeeCacheSliceState }) => {
  return state?.virtualEmployeeCache.is_deleting_virtualEmployeeCache;
};
export const is_loading_virtualEmployeeCache_busy = (state: { virtualEmployeeCache: virtualEmployeeCacheSliceState }) => {
  return state?.virtualEmployeeCache.is_loading_virtualEmployeeCache;
};
export const is_creating_virtualEmployeeCache_busy = (state: { virtualEmployeeCache: virtualEmployeeCacheSliceState }) => {
  return state?.virtualEmployeeCache.is_creating_virtualEmployeeCache;
};

export default virtualEmployeeCacheSlice.reducer;
