import CityLand from "./city-land-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateCityLandAsync, DeleteCityLandAsync, LoadCityLandsAsync } from "./cityLand-thunk";
const initialState: cityLandSliceState = {
  cityLand: [],
  selected_cityLand: "",
  is_loading_cityLand: false,
  is_creating_cityLand: false,
  is_deleting_cityLand: false
};
export interface cityLandSliceState {
  cityLand: CityLand[];
  selected_cityLand?: string;
  is_loading_cityLand: boolean;
  is_creating_cityLand: boolean;
  is_deleting_cityLand: boolean;
}
export const cityLandSlice = createSlice({
  name: "cityLand",
  initialState,
  reducers: {
    setSelectedcityLand: (state, action) => {
      state.selected_cityLand = action.payload;
    },
    clearStatecityLand: (state, action) => {
      state.cityLand = [];
      state.is_loading_cityLand = false;
      state.is_creating_cityLand = false;
      state.is_deleting_cityLand = false;
    },
    mergecityLand: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_cityLand = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.cityLand = merge(state.cityLand, payload) as any;
        }
      }
    },

    add_cityLand: (state, action) => {
      if (action.payload) {
        state.cityLand = [...state.cityLand, action.payload];
      }
    },
    set_cityLand: (state, action) => {
      let { key, value, id } = action.payload;
      state.cityLand = state.cityLand.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadCityLandsAsync.pending, (state) => {
      state.is_loading_cityLand = true;
    });
    builder.addCase(LoadCityLandsAsync.fulfilled, (state, action) => {
      state.is_loading_cityLand = false;
      if (action.payload) {
        state.cityLand = action.payload;
      }
    });
    builder.addCase(LoadCityLandsAsync.rejected, (state, action) => {
      state.is_loading_cityLand = false;
    });
    builder.addCase(CreateCityLandAsync.pending, (state) => {
      state.is_creating_cityLand = true;
    });
    builder.addCase(CreateCityLandAsync.fulfilled, (state) => {
      state.is_creating_cityLand = false;
    });
    builder.addCase(CreateCityLandAsync.rejected, (state) => {
      state.is_creating_cityLand = false;
    });
    builder.addCase(DeleteCityLandAsync.pending, (state) => {
      state.is_deleting_cityLand = true;
    });
    builder.addCase(DeleteCityLandAsync.fulfilled, (state, action) => {
      state.is_deleting_cityLand = false;
      if (state.cityLand) {
        state.cityLand = state.cityLand.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteCityLandAsync.rejected, (state, action) => {
      state.is_deleting_cityLand = false;
    });
  }
});

function handleResult(action: any, state: cityLandSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_cityLand = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.cityLand = merge(state.cityLand, payload) as any;
    }
  }
}

export const { clearStatecityLand, setSelectedcityLand, set_cityLand, mergecityLand, add_cityLand } = cityLandSlice.actions;

export const get_cityLand =
  (id?: string) =>
  (state: {
    cityLand: {
      cityLand: CityLand[];
    };
  }): CityLand | null => {
    let temp = state?.cityLand?.cityLand?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.cityLand?.cityLand[0] || null;
  };
export const get_selected_cityLand = (state: { cityLand: cityLandSliceState }): CityLand | null => {
  let id = state?.cityLand?.selected_cityLand;
  let temp = state?.cityLand?.cityLand?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_cityLand = (state: {
  cityLand: {
    cityLand: CityLand[];
  };
}): CityLand[] => {
  let temp = state?.cityLand?.cityLand;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_cityLand_busy = (state: { cityLand: cityLandSliceState }) => {
  return state?.cityLand.is_deleting_cityLand || state?.cityLand.is_loading_cityLand || state?.cityLand.is_creating_cityLand;
};
export const is_deleting_cityLand_busy = (state: { cityLand: cityLandSliceState }) => {
  return state?.cityLand.is_deleting_cityLand;
};
export const is_loading_cityLand_busy = (state: { cityLand: cityLandSliceState }) => {
  return state?.cityLand.is_loading_cityLand;
};
export const is_creating_cityLand_busy = (state: { cityLand: cityLandSliceState }) => {
  return state?.cityLand.is_creating_cityLand;
};

export default cityLandSlice.reducer;
