import { createAsyncThunk } from "@reduxjs/toolkit";
import BusinessLoanRequest from "./business-loan-request-model";
import { delete_businessLoanRequest, load_businessLoanRequests, store_businessLoanRequest } from "./businessLoanRequest-service";

export const LoadBusinessLoanRequestsAsync = createAsyncThunk<any, any>("composer/load/businessLoanRequest", async (params: { uid: string }) => {
  return await load_businessLoanRequests(params);
});

export const CreateBusinessLoanRequestAsync = createAsyncThunk<any, any>("composer/create/businessLoanRequest", async (params: BusinessLoanRequest) => {
  return await store_businessLoanRequest(params);
});

export const DeleteBusinessLoanRequestAsync = createAsyncThunk<any, any>("composer/delete/businessLoanRequest", async (params) => {
  return await delete_businessLoanRequest(params);
});
