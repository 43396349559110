import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import CourseTakerSettings from "./course-taker-settings-model";
import { CourseTakerSettingsProperties, CourseTakerSettingsMetaProperties } from "./courseTakerSettings-property-definitions";

export async function load_courseTakerSettingss(args: { uid: string }): Promise<CourseTakerSettings[]> {
  throw "Not implemented";
}

export async function delete_courseTakerSettings(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_courseTakerSettings(args: CourseTakerSettings): Promise<CourseTakerSettings> {
  throw "Not implemented";
}
