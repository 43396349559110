import ImageResourceStats from "./image-resource-stats-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateImageResourceStatsAsync, DeleteImageResourceStatsAsync, LoadImageResourceStatssAsync } from "./imageResourceStats-thunk";
const initialState: imageResourceStatsSliceState = {
  imageResourceStats: [],
  selected_imageResourceStats: "",
  is_loading_imageResourceStats: false,
  is_creating_imageResourceStats: false,
  is_deleting_imageResourceStats: false
};
export interface imageResourceStatsSliceState {
  imageResourceStats: ImageResourceStats[];
  selected_imageResourceStats?: string;
  is_loading_imageResourceStats: boolean;
  is_creating_imageResourceStats: boolean;
  is_deleting_imageResourceStats: boolean;
}
export const imageResourceStatsSlice = createSlice({
  name: "imageResourceStats",
  initialState,
  reducers: {
    setSelectedimageResourceStats: (state, action) => {
      state.selected_imageResourceStats = action.payload;
    },
    clearStateimageResourceStats: (state, action) => {
      state.imageResourceStats = [];
      state.is_loading_imageResourceStats = false;
      state.is_creating_imageResourceStats = false;
      state.is_deleting_imageResourceStats = false;
    },
    mergeimageResourceStats: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_imageResourceStats = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.imageResourceStats = merge(state.imageResourceStats, payload) as any;
        }
      }
    },

    add_imageResourceStats: (state, action) => {
      if (action.payload) {
        state.imageResourceStats = [...state.imageResourceStats, action.payload];
      }
    },
    set_imageResourceStats: (state, action) => {
      let { key, value, id } = action.payload;
      state.imageResourceStats = state.imageResourceStats.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadImageResourceStatssAsync.pending, (state) => {
      state.is_loading_imageResourceStats = true;
    });
    builder.addCase(LoadImageResourceStatssAsync.fulfilled, (state, action) => {
      state.is_loading_imageResourceStats = false;
      if (action.payload) {
        state.imageResourceStats = action.payload;
      }
    });
    builder.addCase(LoadImageResourceStatssAsync.rejected, (state, action) => {
      state.is_loading_imageResourceStats = false;
    });
    builder.addCase(CreateImageResourceStatsAsync.pending, (state) => {
      state.is_creating_imageResourceStats = true;
    });
    builder.addCase(CreateImageResourceStatsAsync.fulfilled, (state) => {
      state.is_creating_imageResourceStats = false;
    });
    builder.addCase(CreateImageResourceStatsAsync.rejected, (state) => {
      state.is_creating_imageResourceStats = false;
    });
    builder.addCase(DeleteImageResourceStatsAsync.pending, (state) => {
      state.is_deleting_imageResourceStats = true;
    });
    builder.addCase(DeleteImageResourceStatsAsync.fulfilled, (state, action) => {
      state.is_deleting_imageResourceStats = false;
      if (state.imageResourceStats) {
        state.imageResourceStats = state.imageResourceStats.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteImageResourceStatsAsync.rejected, (state, action) => {
      state.is_deleting_imageResourceStats = false;
    });
  }
});

function handleResult(action: any, state: imageResourceStatsSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_imageResourceStats = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.imageResourceStats = merge(state.imageResourceStats, payload) as any;
    }
  }
}

export const { clearStateimageResourceStats, setSelectedimageResourceStats, set_imageResourceStats, mergeimageResourceStats, add_imageResourceStats } =
  imageResourceStatsSlice.actions;

export const get_imageResourceStats =
  (id?: string) =>
  (state: {
    imageResourceStats: {
      imageResourceStats: ImageResourceStats[];
    };
  }): ImageResourceStats | null => {
    let temp = state?.imageResourceStats?.imageResourceStats?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.imageResourceStats?.imageResourceStats[0] || null;
  };
export const get_selected_imageResourceStats = (state: { imageResourceStats: imageResourceStatsSliceState }): ImageResourceStats | null => {
  let id = state?.imageResourceStats?.selected_imageResourceStats;
  let temp = state?.imageResourceStats?.imageResourceStats?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_imageResourceStats = (state: {
  imageResourceStats: {
    imageResourceStats: ImageResourceStats[];
  };
}): ImageResourceStats[] => {
  let temp = state?.imageResourceStats?.imageResourceStats;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_imageResourceStats_busy = (state: { imageResourceStats: imageResourceStatsSliceState }) => {
  return (
    state?.imageResourceStats.is_deleting_imageResourceStats ||
    state?.imageResourceStats.is_loading_imageResourceStats ||
    state?.imageResourceStats.is_creating_imageResourceStats
  );
};
export const is_deleting_imageResourceStats_busy = (state: { imageResourceStats: imageResourceStatsSliceState }) => {
  return state?.imageResourceStats.is_deleting_imageResourceStats;
};
export const is_loading_imageResourceStats_busy = (state: { imageResourceStats: imageResourceStatsSliceState }) => {
  return state?.imageResourceStats.is_loading_imageResourceStats;
};
export const is_creating_imageResourceStats_busy = (state: { imageResourceStats: imageResourceStatsSliceState }) => {
  return state?.imageResourceStats.is_creating_imageResourceStats;
};

export default imageResourceStatsSlice.reducer;
