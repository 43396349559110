import VotingBallot from "./voting-ballot-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateVotingBallotAsync, DeleteVotingBallotAsync, LoadVotingBallotsAsync } from "./votingBallot-thunk";
const initialState: votingBallotSliceState = {
  votingBallot: [],
  selected_votingBallot: "",
  is_loading_votingBallot: false,
  is_creating_votingBallot: false,
  is_deleting_votingBallot: false
};
export interface votingBallotSliceState {
  votingBallot: VotingBallot[];
  selected_votingBallot?: string;
  is_loading_votingBallot: boolean;
  is_creating_votingBallot: boolean;
  is_deleting_votingBallot: boolean;
}
export const votingBallotSlice = createSlice({
  name: "votingBallot",
  initialState,
  reducers: {
    setSelectedvotingBallot: (state, action) => {
      state.selected_votingBallot = action.payload;
    },
    clearStatevotingBallot: (state, action) => {
      state.votingBallot = [];
      state.is_loading_votingBallot = false;
      state.is_creating_votingBallot = false;
      state.is_deleting_votingBallot = false;
    },
    mergevotingBallot: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_votingBallot = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.votingBallot = merge(state.votingBallot, payload) as any;
        }
      }
    },

    add_votingBallot: (state, action) => {
      if (action.payload) {
        state.votingBallot = [...state.votingBallot, action.payload];
      }
    },
    set_votingBallot: (state, action) => {
      let { key, value, id } = action.payload;
      state.votingBallot = state.votingBallot.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadVotingBallotsAsync.pending, (state) => {
      state.is_loading_votingBallot = true;
    });
    builder.addCase(LoadVotingBallotsAsync.fulfilled, (state, action) => {
      state.is_loading_votingBallot = false;
      if (action.payload) {
        state.votingBallot = action.payload;
      }
    });
    builder.addCase(LoadVotingBallotsAsync.rejected, (state, action) => {
      state.is_loading_votingBallot = false;
    });
    builder.addCase(CreateVotingBallotAsync.pending, (state) => {
      state.is_creating_votingBallot = true;
    });
    builder.addCase(CreateVotingBallotAsync.fulfilled, (state) => {
      state.is_creating_votingBallot = false;
    });
    builder.addCase(CreateVotingBallotAsync.rejected, (state) => {
      state.is_creating_votingBallot = false;
    });
    builder.addCase(DeleteVotingBallotAsync.pending, (state) => {
      state.is_deleting_votingBallot = true;
    });
    builder.addCase(DeleteVotingBallotAsync.fulfilled, (state, action) => {
      state.is_deleting_votingBallot = false;
      if (state.votingBallot) {
        state.votingBallot = state.votingBallot.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteVotingBallotAsync.rejected, (state, action) => {
      state.is_deleting_votingBallot = false;
    });

    builder.addCase(autoThunks.get_voting_issue_ballots.pending, (state) => {
      state.is_loading_votingBallot = true;
    });
    builder.addCase(autoThunks.get_voting_issue_ballots.fulfilled, (state, action) => {
      state.is_loading_votingBallot = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_voting_issue_ballots.rejected, (state, action) => {
      state.is_loading_votingBallot = false;
    });

    builder.addCase(autoThunks.apply_vote_ballot_to_issue.pending, (state) => {
      state.is_loading_votingBallot = true;
    });
    builder.addCase(autoThunks.apply_vote_ballot_to_issue.fulfilled, (state, action) => {
      state.is_loading_votingBallot = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.apply_vote_ballot_to_issue.rejected, (state, action) => {
      state.is_loading_votingBallot = false;
    });
  }
});

function handleResult(action: any, state: votingBallotSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_votingBallot = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.votingBallot = merge(state.votingBallot, payload) as any;
    }
  }
}

export const { clearStatevotingBallot, setSelectedvotingBallot, set_votingBallot, mergevotingBallot, add_votingBallot } = votingBallotSlice.actions;

export const get_votingBallot =
  (id?: string) =>
  (state: {
    votingBallot: {
      votingBallot: VotingBallot[];
    };
  }): VotingBallot | null => {
    let temp = state?.votingBallot?.votingBallot?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.votingBallot?.votingBallot[0] || null;
  };
export const get_selected_votingBallot = (state: { votingBallot: votingBallotSliceState }): VotingBallot | null => {
  let id = state?.votingBallot?.selected_votingBallot;
  let temp = state?.votingBallot?.votingBallot?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_votingBallot = (state: {
  votingBallot: {
    votingBallot: VotingBallot[];
  };
}): VotingBallot[] => {
  let temp = state?.votingBallot?.votingBallot;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_votingBallot_busy = (state: { votingBallot: votingBallotSliceState }) => {
  return state?.votingBallot.is_deleting_votingBallot || state?.votingBallot.is_loading_votingBallot || state?.votingBallot.is_creating_votingBallot;
};
export const is_deleting_votingBallot_busy = (state: { votingBallot: votingBallotSliceState }) => {
  return state?.votingBallot.is_deleting_votingBallot;
};
export const is_loading_votingBallot_busy = (state: { votingBallot: votingBallotSliceState }) => {
  return state?.votingBallot.is_loading_votingBallot;
};
export const is_creating_votingBallot_busy = (state: { votingBallot: votingBallotSliceState }) => {
  return state?.votingBallot.is_creating_votingBallot;
};

export default votingBallotSlice.reducer;
