import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import ImageResource from "./image-resource-model";
import { ImageResourceProperties, ImageResourceMetaProperties } from "./imageResource-property-definitions";

export async function load_imageResources(args: { uid: string }): Promise<ImageResource[]> {
  throw "Not implemented";
}

export async function delete_imageResource(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_imageResource(args: ImageResource): Promise<ImageResource> {
  throw "Not implemented";
}
