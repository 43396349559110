import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import MoneySupply from "./money-supply-model";
import { MoneySupplyProperties, MoneySupplyMetaProperties } from "./moneySupply-property-definitions";

export async function load_moneySupplys(args: { uid: string }): Promise<MoneySupply[]> {
  throw "Not implemented";
}

export async function delete_moneySupply(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_moneySupply(args: MoneySupply): Promise<MoneySupply> {
  throw "Not implemented";
}
