import VotingIssue from "./voting-issue-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateVotingIssueAsync, DeleteVotingIssueAsync, LoadVotingIssuesAsync } from "./votingIssue-thunk";
const initialState: votingIssueSliceState = {
  votingIssue: [],
  selected_votingIssue: "",
  is_loading_votingIssue: false,
  is_creating_votingIssue: false,
  is_deleting_votingIssue: false
};
export interface votingIssueSliceState {
  votingIssue: VotingIssue[];
  selected_votingIssue?: string;
  is_loading_votingIssue: boolean;
  is_creating_votingIssue: boolean;
  is_deleting_votingIssue: boolean;
}
export const votingIssueSlice = createSlice({
  name: "votingIssue",
  initialState,
  reducers: {
    setSelectedvotingIssue: (state, action) => {
      state.selected_votingIssue = action.payload;
    },
    clearStatevotingIssue: (state, action) => {
      state.votingIssue = [];
      state.is_loading_votingIssue = false;
      state.is_creating_votingIssue = false;
      state.is_deleting_votingIssue = false;
    },
    mergevotingIssue: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_votingIssue = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.votingIssue = merge(state.votingIssue, payload) as any;
        }
      }
    },

    add_votingIssue: (state, action) => {
      if (action.payload) {
        state.votingIssue = [...state.votingIssue, action.payload];
      }
    },
    set_votingIssue: (state, action) => {
      let { key, value, id } = action.payload;
      state.votingIssue = state.votingIssue.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadVotingIssuesAsync.pending, (state) => {
      state.is_loading_votingIssue = true;
    });
    builder.addCase(LoadVotingIssuesAsync.fulfilled, (state, action) => {
      state.is_loading_votingIssue = false;
      if (action.payload) {
        state.votingIssue = action.payload;
      }
    });
    builder.addCase(LoadVotingIssuesAsync.rejected, (state, action) => {
      state.is_loading_votingIssue = false;
    });
    builder.addCase(CreateVotingIssueAsync.pending, (state) => {
      state.is_creating_votingIssue = true;
    });
    builder.addCase(CreateVotingIssueAsync.fulfilled, (state) => {
      state.is_creating_votingIssue = false;
    });
    builder.addCase(CreateVotingIssueAsync.rejected, (state) => {
      state.is_creating_votingIssue = false;
    });
    builder.addCase(DeleteVotingIssueAsync.pending, (state) => {
      state.is_deleting_votingIssue = true;
    });
    builder.addCase(DeleteVotingIssueAsync.fulfilled, (state, action) => {
      state.is_deleting_votingIssue = false;
      if (state.votingIssue) {
        state.votingIssue = state.votingIssue.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteVotingIssueAsync.rejected, (state, action) => {
      state.is_deleting_votingIssue = false;
    });

    builder.addCase(autoThunks.get_voting_issues.pending, (state) => {
      state.is_loading_votingIssue = true;
    });
    builder.addCase(autoThunks.get_voting_issues.fulfilled, (state, action) => {
      state.is_loading_votingIssue = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_voting_issues.rejected, (state, action) => {
      state.is_loading_votingIssue = false;
    });
  }
});

function handleResult(action: any, state: votingIssueSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_votingIssue = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.votingIssue = merge(state.votingIssue, payload) as any;
    }
  }
}

export const { clearStatevotingIssue, setSelectedvotingIssue, set_votingIssue, mergevotingIssue, add_votingIssue } = votingIssueSlice.actions;

export const get_votingIssue =
  (id?: string) =>
  (state: {
    votingIssue: {
      votingIssue: VotingIssue[];
    };
  }): VotingIssue | null => {
    let temp = state?.votingIssue?.votingIssue?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.votingIssue?.votingIssue[0] || null;
  };
export const get_selected_votingIssue = (state: { votingIssue: votingIssueSliceState }): VotingIssue | null => {
  let id = state?.votingIssue?.selected_votingIssue;
  let temp = state?.votingIssue?.votingIssue?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_votingIssue = (state: {
  votingIssue: {
    votingIssue: VotingIssue[];
  };
}): VotingIssue[] => {
  let temp = state?.votingIssue?.votingIssue;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_votingIssue_busy = (state: { votingIssue: votingIssueSliceState }) => {
  return state?.votingIssue.is_deleting_votingIssue || state?.votingIssue.is_loading_votingIssue || state?.votingIssue.is_creating_votingIssue;
};
export const is_deleting_votingIssue_busy = (state: { votingIssue: votingIssueSliceState }) => {
  return state?.votingIssue.is_deleting_votingIssue;
};
export const is_loading_votingIssue_busy = (state: { votingIssue: votingIssueSliceState }) => {
  return state?.votingIssue.is_loading_votingIssue;
};
export const is_creating_votingIssue_busy = (state: { votingIssue: votingIssueSliceState }) => {
  return state?.votingIssue.is_creating_votingIssue;
};

export default votingIssueSlice.reducer;
