import DigitalAsset from "./digital-asset-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateDigitalAssetAsync, DeleteDigitalAssetAsync, LoadDigitalAssetsAsync } from "./digitalAsset-thunk";
const initialState: digitalAssetSliceState = {
  digitalAsset: [],
  selected_digitalAsset: "",
  is_loading_digitalAsset: false,
  is_creating_digitalAsset: false,
  is_deleting_digitalAsset: false
};
export interface digitalAssetSliceState {
  digitalAsset: DigitalAsset[];
  selected_digitalAsset?: string;
  is_loading_digitalAsset: boolean;
  is_creating_digitalAsset: boolean;
  is_deleting_digitalAsset: boolean;
}
export const digitalAssetSlice = createSlice({
  name: "digitalAsset",
  initialState,
  reducers: {
    setSelecteddigitalAsset: (state, action) => {
      state.selected_digitalAsset = action.payload;
    },
    clearStatedigitalAsset: (state, action) => {
      state.digitalAsset = [];
      state.is_loading_digitalAsset = false;
      state.is_creating_digitalAsset = false;
      state.is_deleting_digitalAsset = false;
    },
    mergedigitalAsset: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_digitalAsset = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.digitalAsset = merge(state.digitalAsset, payload) as any;
        }
      }
    },

    add_digitalAsset: (state, action) => {
      if (action.payload) {
        state.digitalAsset = [...state.digitalAsset, action.payload];
      }
    },
    set_digitalAsset: (state, action) => {
      let { key, value, id } = action.payload;
      state.digitalAsset = state.digitalAsset.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadDigitalAssetsAsync.pending, (state) => {
      state.is_loading_digitalAsset = true;
    });
    builder.addCase(LoadDigitalAssetsAsync.fulfilled, (state, action) => {
      state.is_loading_digitalAsset = false;
      if (action.payload) {
        state.digitalAsset = action.payload;
      }
    });
    builder.addCase(LoadDigitalAssetsAsync.rejected, (state, action) => {
      state.is_loading_digitalAsset = false;
    });
    builder.addCase(CreateDigitalAssetAsync.pending, (state) => {
      state.is_creating_digitalAsset = true;
    });
    builder.addCase(CreateDigitalAssetAsync.fulfilled, (state) => {
      state.is_creating_digitalAsset = false;
    });
    builder.addCase(CreateDigitalAssetAsync.rejected, (state) => {
      state.is_creating_digitalAsset = false;
    });
    builder.addCase(DeleteDigitalAssetAsync.pending, (state) => {
      state.is_deleting_digitalAsset = true;
    });
    builder.addCase(DeleteDigitalAssetAsync.fulfilled, (state, action) => {
      state.is_deleting_digitalAsset = false;
      if (state.digitalAsset) {
        state.digitalAsset = state.digitalAsset.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteDigitalAssetAsync.rejected, (state, action) => {
      state.is_deleting_digitalAsset = false;
    });

    builder.addCase(autoThunks.get_digital_asset_definition.pending, (state) => {
      state.is_loading_digitalAsset = true;
    });
    builder.addCase(autoThunks.get_digital_asset_definition.fulfilled, (state, action) => {
      state.is_loading_digitalAsset = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_digital_asset_definition.rejected, (state, action) => {
      state.is_loading_digitalAsset = false;
    });

    builder.addCase(autoThunks.save_digital_asset.pending, (state) => {
      state.is_loading_digitalAsset = true;
    });
    builder.addCase(autoThunks.save_digital_asset.fulfilled, (state, action) => {
      state.is_loading_digitalAsset = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.save_digital_asset.rejected, (state, action) => {
      state.is_loading_digitalAsset = false;
    });

    builder.addCase(autoThunks.load_digital_assets.pending, (state) => {
      state.is_loading_digitalAsset = true;
    });
    builder.addCase(autoThunks.load_digital_assets.fulfilled, (state, action) => {
      state.is_loading_digitalAsset = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.load_digital_assets.rejected, (state, action) => {
      state.is_loading_digitalAsset = false;
    });
  }
});

function handleResult(action: any, state: digitalAssetSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_digitalAsset = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.digitalAsset = merge(state.digitalAsset, payload) as any;
    }
  }
}

export const { clearStatedigitalAsset, setSelecteddigitalAsset, set_digitalAsset, mergedigitalAsset, add_digitalAsset } = digitalAssetSlice.actions;

export const get_digitalAsset =
  (id?: string) =>
  (state: {
    digitalAsset: {
      digitalAsset: DigitalAsset[];
    };
  }): DigitalAsset | null => {
    let temp = state?.digitalAsset?.digitalAsset?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.digitalAsset?.digitalAsset[0] || null;
  };
export const get_selected_digitalAsset = (state: { digitalAsset: digitalAssetSliceState }): DigitalAsset | null => {
  let id = state?.digitalAsset?.selected_digitalAsset;
  let temp = state?.digitalAsset?.digitalAsset?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_digitalAsset = (state: {
  digitalAsset: {
    digitalAsset: DigitalAsset[];
  };
}): DigitalAsset[] => {
  let temp = state?.digitalAsset?.digitalAsset;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_digitalAsset_busy = (state: { digitalAsset: digitalAssetSliceState }) => {
  return state?.digitalAsset.is_deleting_digitalAsset || state?.digitalAsset.is_loading_digitalAsset || state?.digitalAsset.is_creating_digitalAsset;
};
export const is_deleting_digitalAsset_busy = (state: { digitalAsset: digitalAssetSliceState }) => {
  return state?.digitalAsset.is_deleting_digitalAsset;
};
export const is_loading_digitalAsset_busy = (state: { digitalAsset: digitalAssetSliceState }) => {
  return state?.digitalAsset.is_loading_digitalAsset;
};
export const is_creating_digitalAsset_busy = (state: { digitalAsset: digitalAssetSliceState }) => {
  return state?.digitalAsset.is_creating_digitalAsset;
};

export default digitalAssetSlice.reducer;
