import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import BankRegistry from "./bank-registry-model";
import { BankRegistryProperties, BankRegistryMetaProperties } from "./bankRegistry-property-definitions";

export async function load_bankRegistrys(args: { uid: string }): Promise<BankRegistry[]> {
  throw "Not implemented";
}

export async function delete_bankRegistry(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_bankRegistry(args: BankRegistry): Promise<BankRegistry> {
  throw "Not implemented";
}
