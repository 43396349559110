import { createAsyncThunk } from "@reduxjs/toolkit";
import MoneySupply from "./money-supply-model";
import { delete_moneySupply, load_moneySupplys, store_moneySupply } from "./moneySupply-service";

export const LoadMoneySupplysAsync = createAsyncThunk<any, any>("composer/load/moneySupply", async (params: { uid: string }) => {
  return await load_moneySupplys(params);
});

export const CreateMoneySupplyAsync = createAsyncThunk<any, any>("composer/create/moneySupply", async (params: MoneySupply) => {
  return await store_moneySupply(params);
});

export const DeleteMoneySupplyAsync = createAsyncThunk<any, any>("composer/delete/moneySupply", async (params) => {
  return await delete_moneySupply(params);
});
