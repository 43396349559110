import { getUserClaims } from "features/application/application-slice";
import { get_course_section_records, load_course } from "features/auto-thunks";
import Course from "features/course/course-model";
import CourseLoad from "features/courseLoad/course-load-model";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { QuestionData, groupBy } from "interfaces";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "store";

export default function useCourseDocument(current_course: Course) {
    const [allQuestions, setAllQuestions] = useState<QuestionData[]>([]);
    const [topics, setTopics] = useState<string[]>([]);
    const [loadingCourse, setLoadingCourse] = useState(false);
    const [categorized, setCategorized] = useState<{ [topic: string]: { [subTopics: string]: QuestionData[] } }>({});
    const claims = useSelector(getUserClaims);

    useEffect(() => {
        Promise.resolve().then(async () => {
            if (claims?.user)
                if (current_course?.version && current_course?.courseUrl) {
                    const storage = getStorage();
                    const _storage_location_ = current_course.courseLocation;
                    const storageRef = ref(storage, _storage_location_);
                    setLoadingCourse(true);
                    let url = await getDownloadURL(storageRef)
                        .then((url) => {
                            return url;
                        })
                        .catch((error) => {
                            // Handle any errors
                            return false;
                        });
                    if (url) {
                        let response = await fetch(url as string, {
                            method: 'GET'
                        });

                        let text = await response.text();
                        let qData = text.split('\n').filter(x => x).map(t => {
                            try {
                                return JSON.parse(t);
                            }
                            catch (e) {
                                return null;
                            }
                        }).filter(x => x) as QuestionData[]
                        setAllQuestions(qData);
                    }
                    setLoadingCourse(false);
                }
        })
    }, [claims?.user, current_course?.id, current_course?.version]);

    useEffect(() => {
        if (claims?.courseTaker) {
            try {
                Promise.resolve().then(async () => {

                    if (current_course?.id) {
                        try {
                            console.log('get_course_section_records: ' + claims?.courseTaker);
                            await dispatch(get_course_section_records({
                                courseTaker: claims?.courseTaker,
                                courseId: current_course?.id,
                                userId: claims?.user
                            }));
                        } catch (e) {
                            console.error('failed to get results');
                        }
                    }
                });
            } catch (e) {
            }
        }
        return () => {
        };
    }, [current_course?.version, , current_course?.id, claims?.courseTaker]);

    useEffect(() => {
        try {
            if (allQuestions) {
                let categorized: any = {};
                let topics = groupBy(allQuestions, (item: any) => {
                    categorized[item.topic] = categorized[item.topic] || {};
                    return `${item.topic}`
                });
                groupBy(allQuestions, (item: any) => {
                    categorized[item.topic][item.subTopic] = categorized[item.topic][item.subTopic] || [];
                    categorized[item.topic][item.subTopic].push(item);
                    return `${item.topic} ${item.subTopic}`
                });
                setTopics(Object.keys(topics));
                setCategorized(categorized);
            }
        } catch (e) { }
    }, [allQuestions]);

    return [topics, categorized, loadingCourse, allQuestions];
}