import CourseConnection from "./course-connection-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateCourseConnectionAsync, DeleteCourseConnectionAsync, LoadCourseConnectionsAsync } from "./courseConnection-thunk";
const initialState: courseConnectionSliceState = {
  courseConnection: [],
  selected_courseConnection: "",
  is_loading_courseConnection: false,
  is_creating_courseConnection: false,
  is_deleting_courseConnection: false
};
export interface courseConnectionSliceState {
  courseConnection: CourseConnection[];
  selected_courseConnection?: string;
  is_loading_courseConnection: boolean;
  is_creating_courseConnection: boolean;
  is_deleting_courseConnection: boolean;
}
export const courseConnectionSlice = createSlice({
  name: "courseConnection",
  initialState,
  reducers: {
    setSelectedcourseConnection: (state, action) => {
      state.selected_courseConnection = action.payload;
    },
    clearStatecourseConnection: (state, action) => {
      state.courseConnection = [];
      state.is_loading_courseConnection = false;
      state.is_creating_courseConnection = false;
      state.is_deleting_courseConnection = false;
    },
    mergecourseConnection: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_courseConnection = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.courseConnection = merge(state.courseConnection, payload) as any;
        }
      }
    },

    add_courseConnection: (state, action) => {
      if (action.payload) {
        state.courseConnection = [...state.courseConnection, action.payload];
      }
    },
    set_courseConnection: (state, action) => {
      let { key, value, id } = action.payload;
      state.courseConnection = state.courseConnection.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadCourseConnectionsAsync.pending, (state) => {
      state.is_loading_courseConnection = true;
    });
    builder.addCase(LoadCourseConnectionsAsync.fulfilled, (state, action) => {
      state.is_loading_courseConnection = false;
      if (action.payload) {
        state.courseConnection = action.payload;
      }
    });
    builder.addCase(LoadCourseConnectionsAsync.rejected, (state, action) => {
      state.is_loading_courseConnection = false;
    });
    builder.addCase(CreateCourseConnectionAsync.pending, (state) => {
      state.is_creating_courseConnection = true;
    });
    builder.addCase(CreateCourseConnectionAsync.fulfilled, (state) => {
      state.is_creating_courseConnection = false;
    });
    builder.addCase(CreateCourseConnectionAsync.rejected, (state) => {
      state.is_creating_courseConnection = false;
    });
    builder.addCase(DeleteCourseConnectionAsync.pending, (state) => {
      state.is_deleting_courseConnection = true;
    });
    builder.addCase(DeleteCourseConnectionAsync.fulfilled, (state, action) => {
      state.is_deleting_courseConnection = false;
      if (state.courseConnection) {
        state.courseConnection = state.courseConnection.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteCourseConnectionAsync.rejected, (state, action) => {
      state.is_deleting_courseConnection = false;
    });

    builder.addCase(autoThunks.get_my_professor_courses.pending, (state) => {
      state.is_loading_courseConnection = true;
    });
    builder.addCase(autoThunks.get_my_professor_courses.fulfilled, (state, action) => {
      state.is_loading_courseConnection = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_my_professor_courses.rejected, (state, action) => {
      state.is_loading_courseConnection = false;
    });
  }
});

function handleResult(action: any, state: courseConnectionSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_courseConnection = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.courseConnection = merge(state.courseConnection, payload) as any;
    }
  }
}

export const { clearStatecourseConnection, setSelectedcourseConnection, set_courseConnection, mergecourseConnection, add_courseConnection } =
  courseConnectionSlice.actions;

export const get_courseConnection =
  (id?: string) =>
  (state: {
    courseConnection: {
      courseConnection: CourseConnection[];
    };
  }): CourseConnection | null => {
    let temp = state?.courseConnection?.courseConnection?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.courseConnection?.courseConnection[0] || null;
  };
export const get_selected_courseConnection = (state: { courseConnection: courseConnectionSliceState }): CourseConnection | null => {
  let id = state?.courseConnection?.selected_courseConnection;
  let temp = state?.courseConnection?.courseConnection?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_courseConnection = (state: {
  courseConnection: {
    courseConnection: CourseConnection[];
  };
}): CourseConnection[] => {
  let temp = state?.courseConnection?.courseConnection;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_courseConnection_busy = (state: { courseConnection: courseConnectionSliceState }) => {
  return (
    state?.courseConnection.is_deleting_courseConnection ||
    state?.courseConnection.is_loading_courseConnection ||
    state?.courseConnection.is_creating_courseConnection
  );
};
export const is_deleting_courseConnection_busy = (state: { courseConnection: courseConnectionSliceState }) => {
  return state?.courseConnection.is_deleting_courseConnection;
};
export const is_loading_courseConnection_busy = (state: { courseConnection: courseConnectionSliceState }) => {
  return state?.courseConnection.is_loading_courseConnection;
};
export const is_creating_courseConnection_busy = (state: { courseConnection: courseConnectionSliceState }) => {
  return state?.courseConnection.is_creating_courseConnection;
};

export default courseConnectionSlice.reducer;
