import { createAsyncThunk } from "@reduxjs/toolkit";
import BankMetrics from "./bank-metrics-model";
import { delete_bankMetrics, load_bankMetricss, store_bankMetrics } from "./bankMetrics-service";

export const LoadBankMetricssAsync = createAsyncThunk<any, any>("composer/load/bankMetrics", async (params: { uid: string }) => {
  return await load_bankMetricss(params);
});

export const CreateBankMetricsAsync = createAsyncThunk<any, any>("composer/create/bankMetrics", async (params: BankMetrics) => {
  return await store_bankMetrics(params);
});

export const DeleteBankMetricsAsync = createAsyncThunk<any, any>("composer/delete/bankMetrics", async (params) => {
  return await delete_bankMetrics(params);
});
