import { createAsyncThunk } from "@reduxjs/toolkit";
import ClaimsCache from "./claims-cache-model";
import { delete_claimsCache, load_claimsCaches, store_claimsCache } from "./claimsCache-service";

export const LoadClaimsCachesAsync = createAsyncThunk<any, any>("composer/load/claimsCache", async (params: { uid: string }) => {
  return await load_claimsCaches(params);
});

export const CreateClaimsCacheAsync = createAsyncThunk<any, any>("composer/create/claimsCache", async (params: ClaimsCache) => {
  return await store_claimsCache(params);
});

export const DeleteClaimsCacheAsync = createAsyncThunk<any, any>("composer/delete/claimsCache", async (params) => {
  return await delete_claimsCache(params);
});
