import { createAsyncThunk } from "@reduxjs/toolkit";
import IndividualInvestmentHolding from "./individual-investment-holding-model";
import {
  delete_individualInvestmentHolding,
  load_individualInvestmentHoldings,
  store_individualInvestmentHolding
} from "./individualInvestmentHolding-service";

export const LoadIndividualInvestmentHoldingsAsync = createAsyncThunk<any, any>(
  "composer/load/individualInvestmentHolding",
  async (params: { uid: string }) => {
    return await load_individualInvestmentHoldings(params);
  }
);

export const CreateIndividualInvestmentHoldingAsync = createAsyncThunk<any, any>(
  "composer/create/individualInvestmentHolding",
  async (params: IndividualInvestmentHolding) => {
    return await store_individualInvestmentHolding(params);
  }
);

export const DeleteIndividualInvestmentHoldingAsync = createAsyncThunk<any, any>("composer/delete/individualInvestmentHolding", async (params) => {
  return await delete_individualInvestmentHolding(params);
});
