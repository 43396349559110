import CourseSorting from "./course-sorting-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateCourseSortingAsync, DeleteCourseSortingAsync, LoadCourseSortingsAsync } from "./courseSorting-thunk";
const initialState: courseSortingSliceState = {
  courseSorting: [],
  selected_courseSorting: "",
  is_loading_courseSorting: false,
  is_creating_courseSorting: false,
  is_deleting_courseSorting: false
};
export interface courseSortingSliceState {
  courseSorting: CourseSorting[];
  selected_courseSorting?: string;
  is_loading_courseSorting: boolean;
  is_creating_courseSorting: boolean;
  is_deleting_courseSorting: boolean;
}
export const courseSortingSlice = createSlice({
  name: "courseSorting",
  initialState,
  reducers: {
    setSelectedcourseSorting: (state, action) => {
      state.selected_courseSorting = action.payload;
    },
    clearStatecourseSorting: (state, action) => {
      state.courseSorting = [];
      state.is_loading_courseSorting = false;
      state.is_creating_courseSorting = false;
      state.is_deleting_courseSorting = false;
    },
    mergecourseSorting: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_courseSorting = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.courseSorting = merge(state.courseSorting, payload) as any;
        }
      }
    },

    add_courseSorting: (state, action) => {
      if (action.payload) {
        state.courseSorting = [...state.courseSorting, action.payload];
      }
    },
    set_courseSorting: (state, action) => {
      let { key, value, id } = action.payload;
      state.courseSorting = state.courseSorting.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadCourseSortingsAsync.pending, (state) => {
      state.is_loading_courseSorting = true;
    });
    builder.addCase(LoadCourseSortingsAsync.fulfilled, (state, action) => {
      state.is_loading_courseSorting = false;
      if (action.payload) {
        state.courseSorting = action.payload;
      }
    });
    builder.addCase(LoadCourseSortingsAsync.rejected, (state, action) => {
      state.is_loading_courseSorting = false;
    });
    builder.addCase(CreateCourseSortingAsync.pending, (state) => {
      state.is_creating_courseSorting = true;
    });
    builder.addCase(CreateCourseSortingAsync.fulfilled, (state) => {
      state.is_creating_courseSorting = false;
    });
    builder.addCase(CreateCourseSortingAsync.rejected, (state) => {
      state.is_creating_courseSorting = false;
    });
    builder.addCase(DeleteCourseSortingAsync.pending, (state) => {
      state.is_deleting_courseSorting = true;
    });
    builder.addCase(DeleteCourseSortingAsync.fulfilled, (state, action) => {
      state.is_deleting_courseSorting = false;
      if (state.courseSorting) {
        state.courseSorting = state.courseSorting.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteCourseSortingAsync.rejected, (state, action) => {
      state.is_deleting_courseSorting = false;
    });

    builder.addCase(autoThunks.get_course_sorting.pending, (state) => {
      state.is_loading_courseSorting = true;
    });
    builder.addCase(autoThunks.get_course_sorting.fulfilled, (state, action) => {
      state.is_loading_courseSorting = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.get_course_sorting.rejected, (state, action) => {
      state.is_loading_courseSorting = false;
    });

    builder.addCase(autoThunks.set_course_sorting.pending, (state) => {
      state.is_loading_courseSorting = true;
    });
    builder.addCase(autoThunks.set_course_sorting.fulfilled, (state, action) => {
      state.is_loading_courseSorting = false;
      handleResult(action, state);
    });
    builder.addCase(autoThunks.set_course_sorting.rejected, (state, action) => {
      state.is_loading_courseSorting = false;
    });
  }
});

function handleResult(action: any, state: courseSortingSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_courseSorting = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.courseSorting = merge(state.courseSorting, payload) as any;
    }
  }
}

export const { clearStatecourseSorting, setSelectedcourseSorting, set_courseSorting, mergecourseSorting, add_courseSorting } = courseSortingSlice.actions;

export const get_courseSorting =
  (id?: string) =>
  (state: {
    courseSorting: {
      courseSorting: CourseSorting[];
    };
  }): CourseSorting | null => {
    let temp = state?.courseSorting?.courseSorting?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.courseSorting?.courseSorting[0] || null;
  };
export const get_selected_courseSorting = (state: { courseSorting: courseSortingSliceState }): CourseSorting | null => {
  let id = state?.courseSorting?.selected_courseSorting;
  let temp = state?.courseSorting?.courseSorting?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_courseSorting = (state: {
  courseSorting: {
    courseSorting: CourseSorting[];
  };
}): CourseSorting[] => {
  let temp = state?.courseSorting?.courseSorting;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_courseSorting_busy = (state: { courseSorting: courseSortingSliceState }) => {
  return state?.courseSorting.is_deleting_courseSorting || state?.courseSorting.is_loading_courseSorting || state?.courseSorting.is_creating_courseSorting;
};
export const is_deleting_courseSorting_busy = (state: { courseSorting: courseSortingSliceState }) => {
  return state?.courseSorting.is_deleting_courseSorting;
};
export const is_loading_courseSorting_busy = (state: { courseSorting: courseSortingSliceState }) => {
  return state?.courseSorting.is_loading_courseSorting;
};
export const is_creating_courseSorting_busy = (state: { courseSorting: courseSortingSliceState }) => {
  return state?.courseSorting.is_creating_courseSorting;
};

export default courseSortingSlice.reducer;
