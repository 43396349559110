import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import EductionAssetOutputConfiguration from "./eduction-asset-output-configuration-model";
import {
  EductionAssetOutputConfigurationProperties,
  EductionAssetOutputConfigurationMetaProperties
} from "./eductionAssetOutputConfiguration-property-definitions";

export async function load_eductionAssetOutputConfigurations(args: { uid: string }): Promise<EductionAssetOutputConfiguration[]> {
  throw "Not implemented";
}

export async function delete_eductionAssetOutputConfiguration(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_eductionAssetOutputConfiguration(args: EductionAssetOutputConfiguration): Promise<EductionAssetOutputConfiguration> {
  throw "Not implemented";
}
