import { createAsyncThunk } from "@reduxjs/toolkit";
import Currency from "./currency-model";
import { delete_currency, load_currencys, store_currency } from "./currency-service";

export const LoadCurrencysAsync = createAsyncThunk<any, any>("composer/load/currency", async (params: { uid: string }) => {
  return await load_currencys(params);
});

export const CreateCurrencyAsync = createAsyncThunk<any, any>("composer/create/currency", async (params: Currency) => {
  return await store_currency(params);
});

export const DeleteCurrencyAsync = createAsyncThunk<any, any>("composer/delete/currency", async (params) => {
  return await delete_currency(params);
});
