import { createAsyncThunk } from "@reduxjs/toolkit";
import FakeModel from "./fake-model-model";
import { delete_fakeModel, load_fakeModels, store_fakeModel } from "./fakeModel-service";

export const LoadFakeModelsAsync = createAsyncThunk<any, any>("composer/load/fakeModel", async (params: { uid: string }) => {
  return await load_fakeModels(params);
});

export const CreateFakeModelAsync = createAsyncThunk<any, any>("composer/create/fakeModel", async (params: FakeModel) => {
  return await store_fakeModel(params);
});

export const DeleteFakeModelAsync = createAsyncThunk<any, any>("composer/delete/fakeModel", async (params) => {
  return await delete_fakeModel(params);
});
