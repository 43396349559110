import { createAsyncThunk } from "@reduxjs/toolkit";
import CoursePay from "./course-pay-model";
import { delete_coursePay, load_coursePays, store_coursePay } from "./coursePay-service";

export const LoadCoursePaysAsync = createAsyncThunk<any, any>("composer/load/coursePay", async (params: { uid: string }) => {
  return await load_coursePays(params);
});

export const CreateCoursePayAsync = createAsyncThunk<any, any>("composer/create/coursePay", async (params: CoursePay) => {
  return await store_coursePay(params);
});

export const DeleteCoursePayAsync = createAsyncThunk<any, any>("composer/delete/coursePay", async (params) => {
  return await delete_coursePay(params);
});
