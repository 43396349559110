import MarketingCampaign from "./marketing-campaign-model";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "features/thunks";
import { merge } from "components/util";
import * as autoThunks from "features/auto-thunks";
import { CreateMarketingCampaignAsync, DeleteMarketingCampaignAsync, LoadMarketingCampaignsAsync } from "./marketingCampaign-thunk";
const initialState: marketingCampaignSliceState = {
  marketingCampaign: [],
  selected_marketingCampaign: "",
  is_loading_marketingCampaign: false,
  is_creating_marketingCampaign: false,
  is_deleting_marketingCampaign: false
};
export interface marketingCampaignSliceState {
  marketingCampaign: MarketingCampaign[];
  selected_marketingCampaign?: string;
  is_loading_marketingCampaign: boolean;
  is_creating_marketingCampaign: boolean;
  is_deleting_marketingCampaign: boolean;
}
export const marketingCampaignSlice = createSlice({
  name: "marketingCampaign",
  initialState,
  reducers: {
    setSelectedmarketingCampaign: (state, action) => {
      state.selected_marketingCampaign = action.payload;
    },
    clearStatemarketingCampaign: (state, action) => {
      state.marketingCampaign = [];
      state.is_loading_marketingCampaign = false;
      state.is_creating_marketingCampaign = false;
      state.is_deleting_marketingCampaign = false;
    },
    mergemarketingCampaign: (state, action) => {
      if (action.payload) {
        let payload: any = action.payload;
        if (payload?.data) {
          if (!Array.isArray(payload.data)) {
            state.selected_marketingCampaign = payload?.data?.id;
            payload = [payload.data];
          } else {
            payload = payload.data;
          }
          state.marketingCampaign = merge(state.marketingCampaign, payload) as any;
        }
      }
    },

    add_marketingCampaign: (state, action) => {
      if (action.payload) {
        state.marketingCampaign = [...state.marketingCampaign, action.payload];
      }
    },
    set_marketingCampaign: (state, action) => {
      let { key, value, id } = action.payload;
      state.marketingCampaign = state.marketingCampaign.map((item) => {
        if (item && item.id === id) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    });

    builder.addCase(LoadMarketingCampaignsAsync.pending, (state) => {
      state.is_loading_marketingCampaign = true;
    });
    builder.addCase(LoadMarketingCampaignsAsync.fulfilled, (state, action) => {
      state.is_loading_marketingCampaign = false;
      if (action.payload) {
        state.marketingCampaign = action.payload;
      }
    });
    builder.addCase(LoadMarketingCampaignsAsync.rejected, (state, action) => {
      state.is_loading_marketingCampaign = false;
    });
    builder.addCase(CreateMarketingCampaignAsync.pending, (state) => {
      state.is_creating_marketingCampaign = true;
    });
    builder.addCase(CreateMarketingCampaignAsync.fulfilled, (state) => {
      state.is_creating_marketingCampaign = false;
    });
    builder.addCase(CreateMarketingCampaignAsync.rejected, (state) => {
      state.is_creating_marketingCampaign = false;
    });
    builder.addCase(DeleteMarketingCampaignAsync.pending, (state) => {
      state.is_deleting_marketingCampaign = true;
    });
    builder.addCase(DeleteMarketingCampaignAsync.fulfilled, (state, action) => {
      state.is_deleting_marketingCampaign = false;
      if (state.marketingCampaign) {
        state.marketingCampaign = state.marketingCampaign.filter((x) => action.payload.id !== x.id);
      }
    });
    builder.addCase(DeleteMarketingCampaignAsync.rejected, (state, action) => {
      state.is_deleting_marketingCampaign = false;
    });
  }
});

function handleResult(action: any, state: marketingCampaignSliceState) {
  if (action.payload) {
    let payload: any = action.payload;
    if (payload?.data) {
      if (!Array.isArray(payload.data)) {
        state.selected_marketingCampaign = payload?.data?.id;
        payload = [payload.data];
      } else {
        payload = payload.data;
      }
      state.marketingCampaign = merge(state.marketingCampaign, payload) as any;
    }
  }
}

export const { clearStatemarketingCampaign, setSelectedmarketingCampaign, set_marketingCampaign, mergemarketingCampaign, add_marketingCampaign } =
  marketingCampaignSlice.actions;

export const get_marketingCampaign =
  (id?: string) =>
  (state: {
    marketingCampaign: {
      marketingCampaign: MarketingCampaign[];
    };
  }): MarketingCampaign | null => {
    let temp = state?.marketingCampaign?.marketingCampaign?.find((v) => v.id === id);
    if (temp) {
      return temp;
    }
    return state?.marketingCampaign?.marketingCampaign[0] || null;
  };
export const get_selected_marketingCampaign = (state: { marketingCampaign: marketingCampaignSliceState }): MarketingCampaign | null => {
  let id = state?.marketingCampaign?.selected_marketingCampaign;
  let temp = state?.marketingCampaign?.marketingCampaign?.find((v) => v.id === id);
  if (temp) {
    return temp;
  }
  return null;
};
export const get_all_marketingCampaign = (state: {
  marketingCampaign: {
    marketingCampaign: MarketingCampaign[];
  };
}): MarketingCampaign[] => {
  let temp = state?.marketingCampaign?.marketingCampaign;
  if (temp) {
    return temp.filter((x) => !x.deleted);
  }
  return [];
};
export const is_marketingCampaign_busy = (state: { marketingCampaign: marketingCampaignSliceState }) => {
  return (
    state?.marketingCampaign.is_deleting_marketingCampaign ||
    state?.marketingCampaign.is_loading_marketingCampaign ||
    state?.marketingCampaign.is_creating_marketingCampaign
  );
};
export const is_deleting_marketingCampaign_busy = (state: { marketingCampaign: marketingCampaignSliceState }) => {
  return state?.marketingCampaign.is_deleting_marketingCampaign;
};
export const is_loading_marketingCampaign_busy = (state: { marketingCampaign: marketingCampaignSliceState }) => {
  return state?.marketingCampaign.is_loading_marketingCampaign;
};
export const is_creating_marketingCampaign_busy = (state: { marketingCampaign: marketingCampaignSliceState }) => {
  return state?.marketingCampaign.is_creating_marketingCampaign;
};

export default marketingCampaignSlice.reducer;
