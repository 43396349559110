import { getReqs, setupCreateUpdateClocks } from "features/firebase-app";
import * as firestore from "firebase/firestore";
// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";
import { updateDoc, addDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import BusinessPlan from "./business-plan-model";
import { BusinessPlanProperties, BusinessPlanMetaProperties } from "./businessPlan-property-definitions";

export async function load_businessPlans(args: { uid: string }): Promise<BusinessPlan[]> {
  throw "Not implemented";
}

export async function delete_businessPlan(args: { uid: string; id: string }): Promise<boolean> {
  throw "Not implemented";
}

export async function store_businessPlan(args: BusinessPlan): Promise<BusinessPlan> {
  throw "Not implemented";
}
