import { createAsyncThunk } from "@reduxjs/toolkit";
import Device from "./device-model";
import { delete_device, load_devices, store_device } from "./device-service";

export const LoadDevicesAsync = createAsyncThunk<any, any>("composer/load/device", async (params: { uid: string }) => {
  return await load_devices(params);
});

export const CreateDeviceAsync = createAsyncThunk<any, any>("composer/create/device", async (params: Device) => {
  return await store_device(params);
});

export const DeleteDeviceAsync = createAsyncThunk<any, any>("composer/delete/device", async (params) => {
  return await delete_device(params);
});
