import { createAsyncThunk } from "@reduxjs/toolkit";
import ImageResource from "./image-resource-model";
import { delete_imageResource, load_imageResources, store_imageResource } from "./imageResource-service";

export const LoadImageResourcesAsync = createAsyncThunk<any, any>("composer/load/imageResource", async (params: { uid: string }) => {
  return await load_imageResources(params);
});

export const CreateImageResourceAsync = createAsyncThunk<any, any>("composer/create/imageResource", async (params: ImageResource) => {
  return await store_imageResource(params);
});

export const DeleteImageResourceAsync = createAsyncThunk<any, any>("composer/delete/imageResource", async (params) => {
  return await delete_imageResource(params);
});
